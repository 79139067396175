import {
  SliderFilledTrack,
  SliderProps,
  SliderThumb,
  SliderTrack,
  Slider,
} from "@chakra-ui/react";

const StyledSlider = (props: SliderProps) => (
  <Slider {...props}>
    <SliderTrack height={"2px"} border={"none"} background={"grayFactor.2"}>
      <SliderFilledTrack border={"none"} background={"brand.800"} />
    </SliderTrack>
    <SliderThumb background="brand.800" zIndex={0} />
  </Slider>
);

export default StyledSlider;
