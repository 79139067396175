import { ReactNode } from "react";

import { useDisclosure } from "@chakra-ui/react";

import ConfirmationAlert, {
  ConfirmationAlertProps,
} from "components/ConfirmationAlert";

const useConfirmationAlert = (
  props: ConfirmationAlertProps
): {
  alert: ReactNode;
  onOpen: () => void;
  onClose: () => void;
  isOpen: boolean;
} => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return {
    alert: <ConfirmationAlert isOpen={isOpen} onClose={onClose} {...props} />,
    onOpen,
    onClose,
    isOpen,
  };
};

export default useConfirmationAlert;
