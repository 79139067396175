import {
  Box,
  Stack,
  StackDirection,
  useBreakpointValue,
} from "@chakra-ui/react";
import { MoneyInput } from "components/inputs";
import TextHeading from "components/TextHeading";
import { DailyPricingFields } from "pages/dashboard/DailyPricing/DailyPricingPage";
import { useFieldArray } from "react-hook-form";
import FuturesInput from "../FuturesInput";
import { RegionDto } from "api";
import { mobileStyleBreakpoint } from "utils/styleHelpers";
import useGetFutures from "hooks/futures/useGetFutures";

const DAILY_PRICING_STACK_DIRECTION = mobileStyleBreakpoint<StackDirection>(
  "column",
  "row"
);

const DAILY_PRICING_FORM_INPUT_WIDTH = mobileStyleBreakpoint("unset", "33%");

const DailyPricingFormInputs = ({
  regions,
}: {
  regions: RegionDto[] | undefined;
}) => {
  const { data, isLoading, isError } = useGetFutures();

  const { fields } = useFieldArray<DailyPricingFields>({
    name: "dailyPricing",
  });

  const dailyPricingStackDirection = useBreakpointValue(
    DAILY_PRICING_STACK_DIRECTION
  );

  return (
    <Stack w="100%">
      {fields.map((field, index) => (
        <Stack key={field.id} spacing={6}>
          <TextHeading headingType="h5" color="greyFactor.4">
            {regions?.find((region) => region.id === field.regionId)?.name}
          </TextHeading>
          <Stack direction={dailyPricingStackDirection} spacing={4} w="100%">
            <Box w={DAILY_PRICING_FORM_INPUT_WIDTH}>
              <FuturesInput
                props={{
                  id: `dailyPricing.${index}.oldCropFutures`,
                  label: "Old Crop Futures",
                  placeholder: "Select",
                  isRequired: true,
                }}
                data={data}
                isLoading={isLoading}
                isError={isError}
              />
            </Box>
            <Box w={DAILY_PRICING_FORM_INPUT_WIDTH}>
              <MoneyInput
                id={`dailyPricing.${index}.oldCropBasis`}
                label="Old Crop Basis"
                allowNegative={true}
                isRequired
                defaultEmptyValueToZero={false}
              />
            </Box>
            <Box w={DAILY_PRICING_FORM_INPUT_WIDTH}>
              <MoneyInput
                id={`dailyPricing.${index}.newCropBasis`}
                label="New Crop Basis"
                allowNegative={true}
                isRequired
                defaultEmptyValueToZero={false}
              />
            </Box>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};

export default DailyPricingFormInputs;
