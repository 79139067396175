import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useBreakpointValue,
} from "@chakra-ui/react";
import WithSpinner from "components/WithSpinner";
import { CARD_MAX_WIDTH, mobileOnly } from "utils/styleHelpers";
import { ReactElement } from "react";

type ModalWithSlideoutProps = {
  modalHeader: ReactElement;
  modalBody: ReactElement;
  modalFooter: ReactElement | null;
  modalSlideout: ReactElement | null;
  isOpen: boolean;
  onClose: () => void;
  isLoading: boolean;
};

const ModalWithSlideout = ({
  modalHeader,
  modalBody,
  modalFooter,
  modalSlideout,
  isOpen,
  onClose,
  isLoading,
}: ModalWithSlideoutProps) => {
  const isMobile = useBreakpointValue(mobileOnly);
  const PADDING = isMobile ? 6 : "72px";

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior={"outside"}
      isCentered={isLoading}
      data-testid={"modal-with-slideout"}
    >
      <ModalOverlay />
      <ModalContent
        px={0}
        py={0}
        maxW={CARD_MAX_WIDTH}
        borderRadius={"16px 16px"}
      >
        <WithSpinner isLoading={isLoading} centerProps={{ minH: "400px" }}>
          <>
            <ModalHeader px={PADDING} pt={PADDING} pb={0}>
              {modalHeader}
            </ModalHeader>
            <ModalBody px={PADDING}>{modalBody}</ModalBody>
            <ModalFooter zIndex={1} p={0}>
              <Flex direction={"column"} w={"100%"}>
                <Box
                  px={PADDING}
                  pb={modalFooter ? PADDING : "50px"}
                  boxShadow={"0px 3px 4px rgba(45, 55, 72, 0.15);"}
                  borderRadius={"0 0 16px 16px"}
                  zIndex={1}
                >
                  {modalFooter}
                </Box>
                {modalSlideout && (
                  <Box
                    w={"100%"}
                    backgroundColor={"grayFactor.0"}
                    borderRadius={"0 0 16px 16px"}
                    py={10}
                    px={PADDING}
                  >
                    {modalSlideout}
                  </Box>
                )}
              </Flex>
            </ModalFooter>
          </>
        </WithSpinner>
      </ModalContent>
    </Modal>
  );
};

export default ModalWithSlideout;
