import {
  ManureYearApi,
  ManureYearFarmDto,
  FarmListDto,
  FarmDocumentDto,
  ManureYearApiAssignFarmsToManureYearRequest,
  ManureYearApiListManureYearsRequest,
  ManureYearApiGetManureYearRequest,
} from "api";
import { ManureYearResponse } from "api/models/manure-year-response";
import {
  extractResponseData,
  extractResponseDataWithMeta,
  PaginationMeta,
  processResponse,
  QueryParams,
  transformQueryParamsForRequest,
} from "./apiHelpers";
import { constructApiControllerInstance } from "./customApiConfig";

export type GetManureYearResponse = {
  data: ManureYearResponse[];
  meta: PaginationMeta;
};

export type GetManureYearFarmsResponse = {
  data: ManureYearFarmDto[];
  meta: PaginationMeta;
};
export type GetManureYearAddableFarmsResponse = {
  data: ManureYearFarmDto[];
  meta: PaginationMeta;
};

export type GetManureYearFarmDocumentsResponse = {
  data: FarmDocumentDto[];
  meta: PaginationMeta;
};

class ManureYearService {
  manureYearController!: ManureYearApi;
  constructor() {
    this.manureYearController = constructApiControllerInstance(ManureYearApi);
  }

  async getManureYears(
    request: ManureYearApiListManureYearsRequest
  ): Promise<GetManureYearResponse> {
    const response = await this.manureYearController.listManureYears(request);

    return extractResponseDataWithMeta(response);
  }

  async getManureYear(
    request: ManureYearApiGetManureYearRequest
  ): Promise<ManureYearResponse> {
    return this.manureYearController
      .getManureYear(request)
      .then(extractResponseData);
  }

  async getManureYearFarms(
    id: string,
    queryParams?: QueryParams
  ): Promise<GetManureYearFarmsResponse> {
    const response = await this.manureYearController.listManureYearFarms({
      id,
      ...transformQueryParamsForRequest(queryParams),
    });

    return extractResponseDataWithMeta(response);
  }

  async getAddableFarmsForManureYear(
    id: string,
    queryParams?: QueryParams
  ): Promise<FarmListDto[]> {
    const response =
      await this.manureYearController.listFarmsNotAssignedToManureYear({
        id,
        ...transformQueryParamsForRequest(queryParams),
      });

    return extractResponseData(response);
  }

  async getManureYearFarmDocuments(
    manureYearId: string,
    farmId: string,
    queryParams?: QueryParams
  ): Promise<GetManureYearFarmDocumentsResponse> {
    const response =
      await this.manureYearController.getManureYearFarmDocumentsPage({
        id: manureYearId,
        farmId,
        farmId2: farmId,
        ...transformQueryParamsForRequest(queryParams),
      });

    return extractResponseDataWithMeta(response);
  }

  async assignFarmToManureYear(
    request: ManureYearApiAssignFarmsToManureYearRequest
  ): Promise<string> {
    return await this.manureYearController
      .assignFarmsToManureYear(request)
      .then(processResponse);
  }
}

export default ManureYearService;
