import { RouteObject } from "react-router";
import { Navigate } from "react-router-dom";

import farmPaths from "./farmPaths";

import ProtectedRoute from "components/route-wrappers/ProtectedRoute";

import FormLayout from "layouts/FormLayout";
import { AddFarmPage, ApproveFarmPage, EditFarmPage } from "pages/farm-detail";
import adminPaths from "routes/admin/adminPaths";
import FarmsDetailsTabContent from "pages/farm-detail/tabs/FarmDetails/FarmDetailsTabContent";
import FarmDocumentsTabContent from "pages/farm-detail/tabs/FarmDocuments";

const farmRoutes: RouteObject = {
  path: farmPaths.basePath,
  element: (
    <ProtectedRoute>
      <FormLayout />
    </ProtectedRoute>
  ),
  children: [
    {
      path: `${farmPaths.children.approve}/:id`,
      element: <ApproveFarmPage />,
    },
    {
      path: ":id",
      element: <EditFarmPage />,
      children: [
        {
          path: farmPaths.children.documents,
          element: <FarmDocumentsTabContent />,
        },
        {
          path: farmPaths.children.edit,
          element: <FarmsDetailsTabContent />,
        },
      ],
    },

    {
      path: farmPaths.children.new,
      element: <AddFarmPage />,
    },
    {
      index: true,
      element: <Navigate to={`./${adminPaths.children.farms}`} />,
    },
    {
      path: "*",
      element: <Navigate to={`./${adminPaths.children.farms}`} />,
    },
  ],
};

export default farmRoutes;
