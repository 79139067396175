import { ReactElement, useState } from "react";

import { Button, Hide, HStack, Show, Stack } from "@chakra-ui/react";
import {
  PageHeader,
  HEADING_SPACING,
  PageHeaderStack,
} from "components/page-components";
import { SearchInput } from "components/inputs";
import { mobileStyleBreakpoint } from "utils/styleHelpers";
import FarmsListTable from "./FarmsListTable";
import { UploadIcon } from "icons";
import { Link } from "react-router-dom";
import csvPaths from "routes/csv-uploads/csvPaths";
import { CSV_UPLOAD } from "constants/featureFlags";
import { useHasFeatureFlag } from "hooks/useFeatureFlags";
import useUserRoleFlags from "hooks/auth/useUserRoleFlags";

const SEARCH_WIDTH = mobileStyleBreakpoint("100%", "328px");

const FarmsListPage = (): ReactElement => {
  const hasFarmDocumentsCsvUploadFeature = useHasFeatureFlag(
    CSV_UPLOAD.FARM_DOCUMENTS
  );
  const { isAdmin } = useUserRoleFlags();

  const displayDocumentLinkUploadConditions =
    isAdmin && hasFarmDocumentsCsvUploadFeature;

  const [searchTerm, setSearchTerm] = useState("");

  const search = (
    <SearchInput
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      onClear={() => setSearchTerm("")}
      w={SEARCH_WIDTH}
      clearVisible={!!searchTerm}
    />
  );

  return (
    <Stack spacing={HEADING_SPACING} h="100%">
      <PageHeaderStack>
        <PageHeader heading="Farms">
          <HStack spacing={4}>
            <Show above="md" ssr={false}>
              {search}
              {displayDocumentLinkUploadConditions && (
                <Button
                  as={Link}
                  iconSpacing={3}
                  pr={4}
                  rightIcon={<UploadIcon textColor="white" />}
                  to={`/${csvPaths.basePath}/${csvPaths.children.farmDocuments.basePath}`}
                  w="100%"
                  h={10}
                >
                  Upload Documents
                </Button>
              )}
            </Show>
          </HStack>
        </PageHeader>
        <Hide above="md" ssr={false}>
          {search}
        </Hide>
      </PageHeaderStack>
      <FarmsListTable searchTerm={searchTerm} />
    </Stack>
  );
};

export default FarmsListPage;
