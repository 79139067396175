import { useQuery } from "@tanstack/react-query";
import { useFarmService } from "contexts/ServiceProvider";

const useGetFarmDocumentsHeaders = () => {
  const farmService = useFarmService();

  return useQuery({
    queryKey: ["farm-csv-upload-headers"],
    queryFn: () => farmService.getFarmDocumentsImportHeaders(),
  });
};

export default useGetFarmDocumentsHeaders;
