const farmPaths = {
  basePath: "farm",
  children: {
    new: "new",
    approve: "approve",
    documents: "documents",
    edit: "edit",
  },
};

export default farmPaths;
