import { CSVUploadApi, CSVUploadJobResponse } from "api";
import { constructApiControllerInstance } from "services/customApiConfig";
import { extractResponseData } from "services/apiHelpers";

class CsvService {
  csvController!: CSVUploadApi;
  constructor() {
    this.csvController = constructApiControllerInstance(CSVUploadApi);
  }

  getJob(jobId: string): Promise<CSVUploadJobResponse> {
    return this.csvController
      .getCSVUploadJobById({ id: jobId })
      .then(extractResponseData);
  }
}

export default CsvService;
