import { PropsWithChildren } from "react";
import * as CSS from "csstype";

import {
  Stack,
  StackDirection,
  StackProps,
  useBreakpointValue,
} from "@chakra-ui/react";
import { mobileStyleBreakpoint } from "utils/styleHelpers";

const BUTTON_GROUP_FLEX = mobileStyleBreakpoint<CSS.Property.Flex>(1, "unset");
const BUTTON_GROUP_FLEX_DIRECTION = mobileStyleBreakpoint<StackDirection>(
  "column-reverse",
  "row"
);
const BUTTON_GROUP_JUSTIFY = mobileStyleBreakpoint<CSS.Property.JustifyContent>(
  "flex-start",
  "flex-end"
);

const BUTTON_GROUP_TOP_PADDING = mobileStyleBreakpoint(6, 0);
const FormButtonGroup = ({
  children,
  ...rest
}: PropsWithChildren<StackProps>) => {
  const buttonGroupDirection = useBreakpointValue(BUTTON_GROUP_FLEX_DIRECTION);

  return (
    <Stack
      pt={BUTTON_GROUP_TOP_PADDING}
      direction={buttonGroupDirection}
      flex={BUTTON_GROUP_FLEX}
      minW="200px" // Arbitrary amount to get the button group to wrap to the next line on mobile
      justifyContent={BUTTON_GROUP_JUSTIFY}
      {...rest}
    >
      {children}
    </Stack>
  );
};

export default FormButtonGroup;
