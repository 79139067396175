const REFRESH_TOKEN_STORAGE_KEY = "refreshToken";
const AUTH_FAILED_STORAGE_KEY = "auth-failed";

export const storeRefreshToken = (token: string): void => {
  localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, token);
};

export const clearRefreshToken = (): void => {
  localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
};

export const getRefreshToken = (): string | undefined => {
  return localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY) ?? undefined;
};

export const storeAuthFailure = (): void => {
  localStorage.setItem(AUTH_FAILED_STORAGE_KEY, "true");
};

export const clearAuthFailure = (): void => {
  localStorage.removeItem(AUTH_FAILED_STORAGE_KEY);
};

export const getAuthFailure = (): string | undefined => {
  return localStorage.getItem(AUTH_FAILED_STORAGE_KEY) ?? undefined;
};
