export function truncate(target: string, length: number): string {
  return target.length < length ? target : target.substring(0, length) + "...";
}

export const capitalize = (value: string): string =>
  (typeof value as unknown) === "string"
    ? `${value.charAt(0).toUpperCase()}${value.slice(1)}`
    : "";

export const formatPhoneNumber = (value: string | undefined = ""): string => {
  const areaCode = value.length > 0 ? `(${value.slice(0, 3)}` : "";
  const phoneNumberPartA = value.length > 3 ? `) ${value.slice(3, 6)}` : "";
  const phoneNumberPartB = value.length > 6 ? `-${value.slice(6)}` : "";

  return `${areaCode}${phoneNumberPartA}${phoneNumberPartB}`;
};

export const capitalizeAndSpaceSnakeCase = (value: string): string =>
  value
    .replaceAll("_", " ")
    .toLocaleLowerCase()
    .split(" ")
    .map(capitalize)
    .join(" ");

export const addDigitSeparators = (value: number): string =>
  Number(value).toLocaleString("en-US");

export const formatUSD = (value: number): string =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(value);

export const formatNumberWithDecimals = (
  value: number,
  decimalPlaces = 2,
  useGrouping = true
): string =>
  new Intl.NumberFormat("en-US", {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
    useGrouping,
  }).format(value);

export const numberFormat = (value: number) =>
  new Intl.NumberFormat().format(value);

export const formatPercentForDisplay = (percent: number) => `${percent}%`;

export const parsePercentForState = (percent: string) =>
  percent.replaceAll("%", "");
