import { Configuration } from "api";
import { BASE_PATH, BaseAPI } from "api/base";
import axios, { AxiosInstance } from "axios";

const customApiConfig = new Configuration({
  basePath: process.env.REACT_APP_API_HOST
    ? `${process.env.REACT_APP_API_HOST}/api`
    : BASE_PATH,
});

export const customAxiosInstance = axios.create();

// TODO: Add interceptor for 401/403 to refetch token and update AUTH_TOKENS_QUERY_KEY query key

// https://www.typescriptlang.org/docs/handbook/2/generics.html#using-class-types-in-generics
// Creates an api controller instance from generated API and passes in the correct config.
// type must be a class Name e.g. const api = constructApiControllerInstance(UserApi);
export const constructApiControllerInstance = <T extends BaseAPI>(
  className: new (a?: Configuration, b?: string, c?: AxiosInstance) => T
): T => {
  return new className(
    customApiConfig,
    customApiConfig.basePath,
    customAxiosInstance
  );
};
