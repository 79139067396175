import { Text, Tooltip } from "@chakra-ui/react";
import { ColumnProps, TableData } from "components/SortableTable";
import { FieldsListSortColumns } from "services/FieldService";
import { FieldListItem } from "hooks/field/useFieldsList";
import { formatNumberWithDecimals } from "utils/formatFunctions";

export const fieldListItemMapper = ({
  id,
  fieldNumber,
  totalAcres,
  tonsPerAcre,
  dairy,
  legalDescription,
  commonName,
  isPreHarvest,
  isEmployee,
}: { isPreHarvest?: boolean; isEmployee?: boolean } & Pick<
  FieldListItem,
  | "id"
  | "fieldNumber"
  | "totalAcres"
  | "tonsPerAcre"
  | "dairy"
  | "legalDescription"
  | "commonName"
>): TableData => {
  const baseColumns = {
    id,
    fieldNumber: <Text fontWeight="bold">{fieldNumber}</Text>,
    totalAcres: <Text fontWeight="bold">{totalAcres}</Text>,
    legalDescription: (
      <Tooltip hasArrow label={legalDescription}>
        <Text variant="truncated">{legalDescription ?? ""}</Text>
      </Tooltip>
    ),
    commonName: (
      <Tooltip hasArrow label={commonName}>
        <Text variant="truncated">{commonName}</Text>
      </Tooltip>
    ),
  };

  const dairyColumn = (
    <Tooltip hasArrow label={dairy}>
      <Text variant="truncated">{dairy ?? ""}</Text>
    </Tooltip>
  );

  const tonsPerAcreColumn = (
    <Tooltip hasArrow label={tonsPerAcre}>
      <Text variant="truncated">
        {formatNumberWithDecimals(tonsPerAcre ?? 0) ?? ""}
      </Text>
    </Tooltip>
  );

  if (isEmployee && isPreHarvest) {
    return {
      ...baseColumns,
      dairy: dairyColumn,
    };
  } else if (isEmployee && !isPreHarvest) {
    return {
      ...baseColumns,
      tonsPerAcre: tonsPerAcreColumn,
      dairy: dairyColumn,
    };
  } else {
    return {
      ...baseColumns,
      tonsPerAcre: tonsPerAcreColumn,
    };
  }
};

export const fieldsListTableColumns = ({
  data,
  isPreHarvest = true,
  isEmployee,
}: {
  data: FieldListItem[];
  isPreHarvest?: boolean;
  isEmployee?: boolean;
}): TableData[] =>
  data.map(
    ({
      id,
      fieldNumber,
      totalAcres,
      tonsPerAcre,
      dairy,
      legalDescription,
      commonName,
    }) =>
      fieldListItemMapper({
        id,
        fieldNumber,
        totalAcres,
        tonsPerAcre,
        dairy,
        legalDescription,
        commonName,
        isPreHarvest,
        isEmployee,
      })
  );

type ColumnList = ColumnProps<FieldsListSortColumns>[];

export const baseFieldsListColumns: ColumnList = [
  { heading: "Field#", id: "fieldNumber", sortKey: "fieldNumber" },
  { heading: "Total Acres", id: "totalAcres" },
];

const baseDesktopFieldsListColumns: ColumnList = [
  { heading: "Dairy", id: "dairy", sortKey: "dairy.name" },
  { heading: "Field Common Name", id: "commonName", sortKey: "commonName" },
  {
    heading: "Legal Description",
    id: "legalDescription",
    sortKey: "legalDescription",
  },
];
export const desktopRVFieldsListColumnsPreHarvest: ColumnList = [
  ...baseFieldsListColumns,
  ...baseDesktopFieldsListColumns,
];
export const desktopRVFieldsListColumnsPostHarvest: ColumnList = [
  ...baseFieldsListColumns,
  { heading: "Tons per Acre", id: "tonsPerAcre", sortKey: "tonsPerAcre" },
  ...baseDesktopFieldsListColumns,
];

export const desktopFarmAdminFieldsListColumnsPreHarvest: ColumnList = [
  ...baseFieldsListColumns,
  baseDesktopFieldsListColumns[2],
  baseDesktopFieldsListColumns[1],
];

export const desktopFarmAdminFieldsListColumnsPostHarvest: ColumnList = [
  ...baseFieldsListColumns,
  { heading: "Tons per Acre", id: "tonsPerAcre", sortKey: "tonsPerAcre" },
  baseDesktopFieldsListColumns[2],
  baseDesktopFieldsListColumns[1],
];
