import { RouteObject, Navigate } from "react-router";

import adminPaths from "./adminPaths";
import ProtectedRoute from "components/route-wrappers/ProtectedRoute";
import TableLayout from "layouts/TableLayout";
import FarmsListPage from "pages/admin/farms/FarmsListPage";

export const adminRoutes: RouteObject = {
  path: adminPaths.basePath,
  children: [
    {
      path: "*",
      element: (
        <ProtectedRoute>
          <TableLayout />
        </ProtectedRoute>
      ),
      children: [
        {
          path: adminPaths.children.farms,
          element: <FarmsListPage />,
        },
        {
          index: true,
          element: <Navigate to={`./${adminPaths.children.farms}`} />,
        },
        {
          path: "*",
          element: <Navigate to={`./${adminPaths.children.farms}`} />,
        },
      ],
    },
  ],
};

export default adminRoutes;
