import { HStack, Text, Button } from "@chakra-ui/react";
import { QueryObserverResult } from "@tanstack/react-query";
import {
  DelayedContractCurrentPricingResponse,
  ForwardContractCurrentPricingResponse,
} from "api";
import { AlertCircle } from "icons";
import { BaseApiError } from "services/apiHelpers";

const FuturesPriceTimeoutError = ({
  refetchCurrentPricing,
}: {
  refetchCurrentPricing: () => Promise<
    QueryObserverResult<
      | DelayedContractCurrentPricingResponse
      | ForwardContractCurrentPricingResponse,
      BaseApiError
    >
  >;
}) => {
  return (
    <>
      <HStack
        bgColor="errorBoxRed"
        height="51px"
        pl="8px"
        spacing={3}
        borderRadius="3.164px"
      >
        <AlertCircle color="errorRed" />
        <Text
          id="futures-timeout-error"
          noOfLines={2}
          color="inRouteRed"
          maxW="70%"
          fontSize="11.075px"
        >
          Futures and Basis prices have timed out. Select Refresh Pricing to see
          updates.
        </Text>
      </HStack>
      <Button size="md" onClick={() => refetchCurrentPricing()} w="100%">
        Refresh Pricing
      </Button>
    </>
  );
};

export default FuturesPriceTimeoutError;
