import { IconButton } from "@chakra-ui/react";

import { CloseIcon, Search } from "icons";
import { StandardInput, StandardInputProps } from "components/inputs";

const SearchInput = ({
  onClear,
  clearVisible = false,
  ...props
}: StandardInputProps & { onClear?: () => void; clearVisible?: boolean }) => (
  <StandardInput
    hideAddonBackground={true}
    leftAddon={<Search />}
    rightAddon={
      clearVisible ? (
        <IconButton
          icon={<CloseIcon />}
          onClick={onClear}
          aria-label="Clear Search"
          variant="link"
        />
      ) : undefined
    }
    placeholder="Search"
    {...props}
  />
);

export default SearchInput;
