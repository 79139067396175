import { ForwardedRef, ReactElement } from "react";
import { useFormContext } from "react-hook-form";
import { InputProps } from "@chakra-ui/react";

import { StandardInputProps } from "./StandardInput";
import InputWrapper, { InputWrapperProps } from "./InputWrapper";
import { SelectInputProps } from "./SelectInput";
import { ReactDatePickerProps } from "react-datepicker";

type InputTypeProps = InputProps &
  StandardInputProps & {
    datePickerProps?: Omit<ReactDatePickerProps, "onChange">;
  };
type NonRegisterInputTypeProps = InputWrapperProps | SelectInputProps;

export type FormInputProps = InputWrapperProps &
  (InputTypeProps | NonRegisterInputTypeProps) & {
    id: string;
    shouldRegister?: boolean;
    component: (
      props:
        | (InputTypeProps & { ref?: ForwardedRef<any> })
        | NonRegisterInputTypeProps
    ) => ReactElement | null;
    "data-testid"?: string;
  };

const FormInput = ({
  id,
  component: Component,
  shouldRegister = true,
  ...rest
}: FormInputProps): ReactElement => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const {
    label,
    variant,
    isDisabled,
    isRequired,
    isSuccess,
    isInvalid,
    successMsg,
    hasInfo,
    infoMsg,
    errorMsg,
    ...componentProps
  } = rest;

  return shouldRegister ? (
    <InputWrapper
      key={id}
      isInvalid={isInvalid ?? !!errors[id]}
      errorMsg={errorMsg ?? (errors[id]?.message as string | undefined)}
      label={label}
      variant={variant}
      isDisabled={isDisabled}
      isRequired={isRequired}
      isSuccess={isSuccess}
      successMsg={successMsg}
      hasInfo={hasInfo}
      infoMsg={infoMsg}
    >
      <Component
        {...componentProps}
        isRequired={isRequired}
        variant={variant}
        {...register(id)}
        onWheel={(e) => e.currentTarget.blur()}
      />
    </InputWrapper>
  ) : (
    <Component key={id} id={id} {...rest} />
  );
};

const FormInputs = ({ inputs }: { inputs: FormInputProps[] }): ReactElement => (
  <>{inputs.map(FormInput)}</>
);

export default FormInputs;
