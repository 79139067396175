import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useAuthService } from "contexts/ServiceProvider";
import AuthService from "services/AuthService";
import {
  MutationHookResult,
  MutationHook,
  MutationOnSuccessFn,
} from "../hookTypeHelpers";
import { AUTH_TOKENS_QUERY_KEY } from "./useAuthTokens";

type LoginMutationResult = MutationHookResult<
  Awaited<ReturnType<AuthService["login"]>>,
  {
    username: string;
    password: string;
  }
>;

const useLoginMutation = (
  onSuccess: MutationOnSuccessFn<LoginMutationResult>
): LoginMutationResult => {
  const authService = useAuthService();

  return useMutation({
    mutationKey: ["login"],
    mutationFn: ({ username, password }) =>
      authService.login(username, password),
    onSuccess,
  });
};

const useLogin = (): MutationHook<LoginMutationResult, "onLogin"> => {
  const queryClient = useQueryClient();
  const {
    mutate: onLogin,
    mutateAsync: onLoginAsync,
    ...rest
  } = useLoginMutation((data) => {
    // In v4 this will no longer work as expected and will no longer call the onSuccess of useAuthTokens.
    // https://tanstack.com/query/v4/docs/guides/migrating-to-react-query-4#onsuccess-is-no-longer-called-from-setquerydata
    queryClient.setQueryData([AUTH_TOKENS_QUERY_KEY], data);
  });

  return { onLogin, onLoginAsync, ...rest };
};

export default useLogin;
