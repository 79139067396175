import { forwardRef, ReactElement } from "react";

import InputMask from "react-input-mask";
import { Input, InputProps } from "@chakra-ui/react";

export const EMPTY_PHONE = "(___) ___-____";

const PhoneInput = forwardRef<HTMLInputElement, InputProps>(
  ({ value, onChange, isRequired, ...rest }, ref): ReactElement => (
    <Input
      as={InputMask}
      onChange={onChange}
      value={value}
      mask="(999) 999-9999"
      bg="white"
      {...rest}
      ref={ref}
      aria-required={isRequired ?? undefined}
    />
  )
);

PhoneInput.displayName = "PhoneInput";

export default PhoneInput;
