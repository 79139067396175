import { ReactElement } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";

import TextHeading from "components/TextHeading";
import { CARD_MAX_WIDTH, desktopOnly } from "utils/styleHelpers";

const ModalPrimaryContent = ({
  programType,
  onClose,
  onConfirm,
  isLoading,
}: {
  programType: string;
  onClose: () => void;
  onConfirm: () => void;
  isLoading: boolean;
}) => (
  <Stack w="100%">
    <Text pb={4}>
      This action will add farms to {programType} and will grant these Farms
      access to {programType} Documents. Do you want to add farms to the{" "}
      {programType} program?
    </Text>
    <Button
      variant="primary"
      onClick={onConfirm}
      aria-label="confirmation-add-btn"
      isDisabled={isLoading}
      isLoading={isLoading}
    >
      Yes
    </Button>
    <Button onClick={onClose} variant="outline">
      Cancel
    </Button>
  </Stack>
);

const AddFarmsToYearModal = ({
  programType,
  year,
  isOpen,
  onClose,
  onConfirm,
  isLoading,
}: {
  programType: string;
  year: number | undefined;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isLoading: boolean;
}): ReactElement => {
  const headingText = `Add Farms to ${programType} ${year}?`;
  const isCentered = useBreakpointValue(desktopOnly);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered={isCentered}>
      <ModalOverlay />
      <ModalContent maxW={CARD_MAX_WIDTH}>
        <ModalHeader>
          <TextHeading textStyle="h3">{headingText}</TextHeading>
        </ModalHeader>
        <ModalBody>
          <ModalPrimaryContent
            programType={programType}
            isLoading={isLoading}
            onConfirm={onConfirm}
            onClose={onClose}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddFarmsToYearModal;
