import { ReactElement } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import TextHeading from "components/TextHeading";
import { useCropYearContext } from "contexts/CropYearContext";
import AddFarmForm from "forms/admin/farms/AddFarmForm";
import { CARD_MAX_WIDTH } from "utils/styleHelpers";

const AddFarmModal = (): ReactElement => {
  const { cropYear } = useCropYearContext();

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button variant="primary" size="md" px={4} minW="unset" onClick={onOpen}>
        Add Farm
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW={CARD_MAX_WIDTH}>
          <ModalHeader>
            <TextHeading headingType="h3">
              Add Farm for {cropYear?.year}
            </TextHeading>
          </ModalHeader>
          <ModalBody pt={4}>
            <Stack w="100%">
              <AddFarmForm onClose={onClose} />
              <Button onClick={onClose} variant="outline">
                Cancel
              </Button>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddFarmModal;
