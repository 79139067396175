import { ComponentProps, ReactElement, ReactNode } from "react";

import {
  Menu as ChakraMenu,
  MenuButton,
  MenuList,
  MenuListProps,
  MenuProps,
} from "@chakra-ui/react";

const Menu = ({
  button,
  children,
  menuListStyles,
  menuButtonProps,
  menuStyles,
}: {
  button: ReactNode | string;
  children: ReactNode;
  menuListStyles?: MenuListProps;
  menuButtonProps?: ComponentProps<typeof MenuButton>;
  menuStyles?: Omit<MenuProps, "children">;
}): ReactElement => (
  <ChakraMenu {...menuStyles}>
    <MenuButton aria-label="menu button" {...menuButtonProps}>
      {button}
    </MenuButton>
    <MenuList
      {...{
        // Some element is applying a not(:focus-visible) style that unsets the box-shadow. Important is needed to preserve it.
        // If we can target the specific rule in the theme, this can be removed in favor of that.
        boxShadow: "0px 10px 20px rgba(45, 55, 72, 0.15) !important",
        ...menuListStyles,
      }}
    >
      {children}
    </MenuList>
  </ChakraMenu>
);

export default Menu;
