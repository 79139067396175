/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface NotificationDto
 */
export interface NotificationDto {
  /**
   *
   * @type {string}
   * @memberof NotificationDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof NotificationDto
   */
  type: NotificationDtoTypeEnum;
  /**
   *
   * @type {string}
   * @memberof NotificationDto
   */
  data: string;
}

/**
 * @export
 * @enum {string}
 */
export enum NotificationDtoTypeEnum {
  FarmAdminCropYearStarted = "FARM_ADMIN_CROP_YEAR_STARTED",
}
