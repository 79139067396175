import { Colors } from "@chakra-ui/react";

export const grey = {
  50: "#F7FAFC",
  100: "#EDF2F7",
  200: "#E2E8F0",
  300: "#CBD5E0",
  400: "#A0AEC0",
  500: "#718096",
  600: "#4A5568",
  700: "#2D3748",
  800: "#1A202C",
  900: "#171923",
};
const blue = {
  50: "#E0F3FF",
  100: "#B8D7F9",
  200: "#8EBCF0",
  300: "#63A1E7",
  400: "#4A90E2",
  500: "#206CC6",
  600: "#15549B",
  700: "#0B3C70",
  800: "#022446",
  900: "#000D1D",
};

const yellow = {
  50: "#FFF8DC",
  100: "#FCE9B0",
  200: "#FADA83",
  300: "#F6CB53",
  400: "#F4BD27",
  500: "#DAA30B",
  600: "#AA7F05",
  700: "#795A01",
  800: "#4A3600",
  900: "#1B1200",
};

const navy = {
  50: "#E7F5FD",
  100: "#C8DDE9",
  200: "#A7C6D9",
  300: "#84AEC8",
  400: "#6398B9",
  500: "#4A7E9F",
  600: "#39637D",
  700: "#284759",
  800: "#152935",
  900: "#010F16",
};

const orange = {
  50: "#FFEFD0",
  100: "#FED7D7",
  200: "#F9BA85",
  300: "#F69E55",
  400: "#F27F20",
  500: "#D9690D",
  600: "#AA5208",
  700: "#793904",
  800: "#4A2200",
  900: "#1E0900",
};

const green = {
  50: "#F0FFF4",
  100: "#C6F6D5",
  200: "#9AE6B4",
  300: "#68D391",
  400: "#48BB78",
  500: "#38A169",
  600: "#2F855A",
  700: "#276749",
  800: "#22543D",
  900: "#1C4532",
};

const red = {
  50: "#FFF5F5",
  100: "#FED7D7",
  200: "#FEB2B2",
  300: "#FC8181",
  400: "#F56565",
  500: "#E53E3E",
  600: "#C53030",
  700: "#9B2C2C",
  800: "#822727",
  900: "#63171B",
};

const riverviewGreen = {
  50: "#EBF5EC",
  100: "#D0E5CF",
  200: "#B3D4B1",
  300: "#97C493",
  400: "#83B87D",
  500: "#70AC68",
  600: "#669D5E",
  700: "#598B52",
  800: "#407D41", // primary
  900: "#3A5D35",
};

const error = {
  50: "#FAEBEE",
  100: "#F4CCD3",
  200: "#DE999D",
  300: "#D17278",
  400: "#D8545A", // primary
  500: "#DC4545",
  600: "#CD3C42",
  700: "#BC323B",
  800: "#B02B34",
  900: "#A22029",
};

const grayFactor = {
  0: "#F6F8FB",
  1: grey["100"],
  "1.5": "#E7EEF5",
  2: "#BDC1C8",
  3: "#8E9099",
  4: "#5E5F69",
};

const steelGray = "#8896AB";
const charcoal = "#2E2E3A";

const colors: Colors = {
  black: charcoal,
  grey,
  gray: grey,
  blue,
  yellow,
  navy,
  orange,
  green,
  red,
  error,
  brand: riverviewGreen,
  errorRed: "#E84855",
  grayFactor,
  greyFactor: grayFactor,
  link: blue["400"],
  charcoal,
  steelGray,
  steelGrey: steelGray,
  shadowBlue: steelGray,
  warningOrange: orange["400"],
  dashboardBannerBackground: "#FBFDFD",
  infoGreen: "#006A30",
  inRouteRed: "#EF4623",
  errorBoxRed: "#FFEBE6",
  graySmoke: "#5C6370",
};

export default colors;
