import {
  Divider,
  Stack,
  StackDirection,
  useBreakpointValue,
} from "@chakra-ui/react";

import { FarmDetailFields } from "pages/farm-detail/FarmFormContext";
import useUserRoleFlags from "hooks/auth/useUserRoleFlags";
import useRegionOptions from "hooks/region/useRegionOptions";
import {
  FormInputs,
  PhoneInput,
  SelectInput,
  StandardInput,
  FarmUsersSelectLabeledInput,
  ZipInput,
} from "components/inputs";
import { LabelTextWithHelper } from "components/inputs/InputLabel";
import { mobileStyleBreakpoint } from "utils/styleHelpers";
import {
  organizationStateSelectOptions,
  stateSelectOptions,
} from "constants/stateSelectOptions";
import LienHolderFields from "./LienHolderFields";

const ADDRESS_STACK_DIRECTION = mobileStyleBreakpoint<StackDirection>(
  "column",
  "row"
);

const FarmDetailFormInputs = ({
  isDisabled = false,
  disableContactFields = false,
  farmId,
  isEdit,
}: {
  isDisabled?: boolean;
  disableContactFields?: boolean;
  farmId?: string;
  isEdit?: boolean;
}) => {
  const {
    data: regions,
    isLoading: regionsIsLoading,
    isError,
    error,
  } = useRegionOptions();
  const { isEmployee } = useUserRoleFlags();
  const addressStackDirection = useBreakpointValue(ADDRESS_STACK_DIRECTION);

  return (
    <Stack spacing={2}>
      {(isEmployee || isEdit) && (
        <>
          <FormInputs
            inputs={[
              {
                id: "region",
                label: "Region",
                isRequired: true,
                component: SelectInput,
                options: regions?.map((region) => ({
                  value: region.id,
                  label: region.name,
                })),
                shouldRegister: false,
                isError,
                errorMsg: error?.message,
                isDisabled: regionsIsLoading || isDisabled || isEdit,
                isLoading: regionsIsLoading,
              },
            ]}
          />
          {isEmployee && (
            <FormInputs
              inputs={[
                {
                  id: "entityId",
                  label: "Entity ID",
                  component: StandardInput,
                  isDisabled: true,
                  hasInfo: true,
                  infoMsg:
                    "Entity ID will be created once Region has been assigned and Farm Business has been Approved",
                },
              ]}
            />
          )}
          <Divider />
        </>
      )}
      <FormInputs
        inputs={[
          {
            id: "doingBusinessAs",
            label: "Doing Business As",
            isRequired: true,
            component: StandardInput,
            isDisabled,
          },
          {
            id: "phoneNumber",
            label: "Farm Primary Phone Number",
            isRequired: true,
            component: PhoneInput,
            isDisabled,
          },
        ]}
      />
      <FarmUsersSelectLabeledInput<FarmDetailFields>
        id="mainContact"
        label="Main Contact"
        farmId={farmId}
        isDisabled={disableContactFields || isDisabled}
      />
      <FormInputs
        inputs={[
          {
            id: "email",
            label: "Primary Email Address",
            isRequired: true,
            component: StandardInput,
            isDisabled: disableContactFields || isDisabled,
          },
          {
            id: "streetAddress",
            label: "Mailing Address",
            isRequired: true,
            component: StandardInput,
            placeholder: "Address Line 1",
            isDisabled,
          },
        ]}
      />
      <Stack spacing={2} direction={addressStackDirection}>
        <FormInputs
          inputs={[
            {
              id: "city",
              isRequired: true,
              component: StandardInput,
              placeholder: "City",
              isDisabled,
            },
            {
              id: "state",
              isRequired: true,
              component: SelectInput,
              shouldRegister: false,
              options: stateSelectOptions,
              placeholder: "State",
              isDisabled,
            },
            {
              id: "zip",
              isRequired: true,
              component: ZipInput,
              placeholder: "Zip Code",
              isDisabled,
            },
          ]}
        />
      </Stack>
      <FormInputs
        inputs={[
          {
            id: "orgState",
            label: (
              <LabelTextWithHelper
                label="State of Organization"
                helper="as filed with the Secretary of State"
              />
            ),
            isRequired: true,
            component: SelectInput,
            shouldRegister: false,
            options: organizationStateSelectOptions,
            isDisabled,
          },
        ]}
      />
      <LienHolderFields isDisabled={isDisabled} />
    </Stack>
  );
};

export default FarmDetailFormInputs;
