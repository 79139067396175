import { ReactElement } from "react";
import { Box, Button, Center, Spinner, Stack, Text } from "@chakra-ui/react";

import { AddableFarmsSortColumns } from "./addFarmsTableColumns";
import AddableFarmListTable from "components/AddFarmsToYear/AddableFarmListTable";
import { DesktopOnly, MobileOnly } from "components/BreakpointVisibility";
import FormButtonGroup from "components/FormButtonGroup";
import { PageHeader, PageHeaderStack } from "components/page-components";
import { Sort, UseSortReturnType } from "hooks/useSort";
import { ColumnList } from "pages/dashboard/tabs/PricingListTab/pricingTableColumnDefinitions";

type AddFarmsPageLayoutProps = {
  defaultSort: Sort<AddableFarmsSortColumns>;
  children: ReactElement;
  programType: string;
  year: number | undefined;
  navigateToAllFarmsList: () => void;
  selectedIds: string[];
  isLoading: boolean;
  tableData: Record<string, any>[];
  onToggleId: (id?: string | undefined) => void;
  selectFarmsListTableColumns: Record<string, ColumnList>;
} & UseSortReturnType<AddableFarmsSortColumns>;

const AddFarmsPageLayout = ({
  defaultSort,
  children,
  programType,
  year,
  navigateToAllFarmsList,
  selectedIds,
  isLoading,
  tableData,
  onToggleId,
  selectFarmsListTableColumns,
  ...useSortProps
}: AddFarmsPageLayoutProps) => {
  return (
    <Stack spacing={4} h="100%">
      <PageHeaderStack>
        <PageHeader heading="Select Farms">
          <MobileOnly>
            <Box pt={2}>
              <Text>
                Select Farms to add to {programType} {year}
              </Text>
            </Box>
          </MobileOnly>
          <FormButtonGroup>
            <Button
              minW="228px"
              h={10}
              variant="outline"
              onClick={navigateToAllFarmsList}
            >
              Cancel
            </Button>
            {children}
          </FormButtonGroup>
        </PageHeader>
      </PageHeaderStack>
      <DesktopOnly>
        <Text>
          Select Farms to add to {programType} {year}
        </Text>
      </DesktopOnly>
      {isLoading ? (
        <Center h="100%">
          <Spinner />
        </Center>
      ) : (
        <AddableFarmListTable
          defaultSort={defaultSort}
          columns={selectFarmsListTableColumns}
          data={tableData}
          selectedRows={selectedIds}
          onSelectRow={onToggleId}
          programType={programType}
          {...useSortProps}
        />
      )}
    </Stack>
  );
};

export default AddFarmsPageLayout;
