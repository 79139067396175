import { HStack, StackProps } from "@chakra-ui/react";
import { ReactElement, ReactNode } from "react";

const NavigationBadgeContainer = ({
  children,
  ...props
}: {
  children: ReactNode;
} & StackProps): ReactElement => (
  <HStack
    px={6}
    py={4}
    h="79px"
    align="center"
    justify="space-between"
    {...props}
  >
    {children}
  </HStack>
);

export default NavigationBadgeContainer;
