import { ReactElement } from "react";
import { Skeleton, VisuallyHidden } from "@chakra-ui/react";
import { FORWARD_PRICING } from "constants/featureFlags";
import useUserRoleFlags from "hooks/auth/useUserRoleFlags";
import useCropYearDetail from "hooks/crop-year/useCropYearDetails";
import useFarmDetail from "hooks/farm/farm-detail/useFarmDetail";
import { useHasFeatureFlag } from "hooks/useFeatureFlags";
import useSelectedCropYearId from "hooks/useSelectedCropYearId";
import useSelectedFarmId from "hooks/useSelectedFarmId";
import PricingMenu from "./PricingMenu";
import AveragePricingMenuItem from "./PricingMenuItems/AveragePricingMenuItem";
import DelayedPricingMenuItem from "./PricingMenuItems/DelayedPricingMenuItem";
import ForwardPricingMenuItem from "./PricingMenuItems/ForwardPricingMenuItem";
import ManualForwardPricingMenuItem from "./PricingMenuItems/ManualForwardPricingMenuItem";

const FarmPricingMenu = ({
  openAveragePricingContract,
}: {
  openAveragePricingContract: (id: string) => void;
}): ReactElement | null => {
  const hasForwardPricingFeature = useHasFeatureFlag(
    FORWARD_PRICING.FORWARD_PRICING_BUTTON
  );
  const { isFarmAdmin, isEmployeeAdmin } = useUserRoleFlags();
  const [cropYearId] = useSelectedCropYearId();
  const [farmId] = useSelectedFarmId();
  const { data: cropYearDetailData, isLoading } = useCropYearDetail(cropYearId);
  const { data, isLoading: isFarmDetailLoading } = useFarmDetail(farmId);
  const isCropYearOnFarm =
    cropYearDetailData?.id && data?.cropYears.includes(cropYearDetailData.id);

  if (isLoading || isFarmDetailLoading) {
    return (
      <Skeleton h="40px" minW="144px">
        <VisuallyHidden>Loading...</VisuallyHidden>
      </Skeleton>
    );
  }

  return (
    <PricingMenu
      buttonLabel="Add Pricing"
      menuButtonMinWidth="144px"
      menuListWidth="100%"
    >
      {isFarmAdmin && isCropYearOnFarm && (
        <AveragePricingMenuItem
          cropYearDetail={cropYearDetailData}
          openAveragePricingContract={openAveragePricingContract}
        />
      )}
      {(isEmployeeAdmin || isFarmAdmin) && hasForwardPricingFeature && (
        <ForwardPricingMenuItem cropYearDetail={cropYearDetailData} />
      )}
      {isEmployeeAdmin && cropYearDetailData?.timeOfYear === "PRE_HARVEST" && (
        <ManualForwardPricingMenuItem />
      )}
      <DelayedPricingMenuItem cropYearDetail={cropYearDetailData} />
    </PricingMenu>
  );
};

export default FarmPricingMenu;
