import {
  CreateFarmRequest,
  FarmApi,
  FarmApiGetFarmDetailRequest,
  FarmDetailResponse,
  FarmDocumentDto,
  FarmDocumentImportResponse,
  FarmListDto,
  MinimumFarmListDto,
  UpdateFarmRequest,
} from "api";
import {
  extractResponseData,
  processResponse,
  extractResponseDataWithMeta,
  PaginationMeta,
  QueryParams,
  transformQueryParamsForRequest,
} from "./apiHelpers";
import { constructApiControllerInstance } from "./customApiConfig";

export type FarmListSortColumns =
  | "doingBusinessAs"
  | "externalEntityId"
  | "contact.firstName"
  | "region"
  | "status";

export type AdminDashboardFarmListSortColumns =
  | "doingBusinessAs"
  | "totalAcres"
  | "fieldCount"
  | "dairy";

class FarmService {
  farmController!: FarmApi;
  constructor() {
    this.farmController = constructApiControllerInstance(FarmApi);
  }
  getFarms(
    queryParams?: QueryParams
  ): Promise<{ data: FarmListDto[]; meta: PaginationMeta }> {
    return this.farmController
      .getFarmsPage(transformQueryParamsForRequest(queryParams))
      .then(extractResponseDataWithMeta);
  }

  getFarmsInCropYear(
    cropYearId: string,
    queryParams?: QueryParams
  ): Promise<{ data: MinimumFarmListDto[]; meta: PaginationMeta }> {
    return this.farmController
      .getFarmPageByCropYear({
        cropYearId,
        ...transformQueryParamsForRequest(queryParams),
      })
      .then(extractResponseDataWithMeta);
  }

  getFarmDetail(
    request: FarmApiGetFarmDetailRequest
  ): Promise<FarmDetailResponse> {
    return this.farmController.getFarmDetail(request).then(extractResponseData);
  }

  createFarm(farmInfo: CreateFarmRequest): Promise<FarmDetailResponse> {
    return this.farmController
      .createFarm({ createFarmRequest: farmInfo })
      .then(extractResponseData);
  }

  rejectFarm(farmId: string): Promise<string> {
    return this.farmController
      .rejectFarm({ farmRequest: { farmId } })
      .then(processResponse);
  }

  updateFarm(
    farmId: string,
    farmData: UpdateFarmRequest,
    setFarmIdHeader: boolean
  ): Promise<FarmDetailResponse> {
    return this.farmController
      .update({
        updateFarmRequest: farmData,
        farmId: setFarmIdHeader ? farmId : undefined,
        id: farmId,
      })
      .then(extractResponseData);
  }

  acknowledgeFarm(farmId: string): Promise<string> {
    return this.farmController
      .setFarmToActive({ id: farmId })
      .then(processResponse);
  }

  removeUsersFromFarm(farmId: string, userIds: string[]): Promise<string> {
    return this.farmController
      .removeUsersFromFarm({
        farmId,
        removeUsersFromFarmRequest: { ids: userIds },
      })
      .then(processResponse);
  }

  uploadFarmDocumentsCsv(csvFile: File): Promise<FarmDocumentImportResponse> {
    return this.farmController
      .uploadFarmDocuments({ file: csvFile })
      .then(extractResponseData);
  }

  getFarmDocumentsImportHeaders(): Promise<string[]> {
    return this.farmController
      .getFarmDocumentsImportHeaders()
      .then(extractResponseData);
  }

  getFarmDocumentsPage(
    farmId: string,
    queryParams?: QueryParams
  ): Promise<{ data: FarmDocumentDto[]; meta: PaginationMeta }> {
    return this.farmController
      .getFarmDocumentsPage({
        id: farmId,
        farmId,
        ...transformQueryParamsForRequest(queryParams),
      })
      .then(extractResponseDataWithMeta);
  }

  deleteFarmDocuments(
    documentIds: Array<string>,
    farmId: string
  ): Promise<string> {
    return this.farmController
      .deleteFarmDocuments({ id: farmId, requestBody: documentIds, farmId })
      .then(processResponse);
  }
}

export default FarmService;
