const manurePaths = {
  basePath: "manure",
  children: {
    allFarms: "all-farms",
    farmDocuments: "documents",
    addFarms: "add-farms",
  },
};

export default manurePaths;
