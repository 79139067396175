import { forwardRef } from "react";

import StandardInput, { StandardInputProps } from "../StandardInput";
import { preventNonNumericCharacters } from "utils/inputUtils";

const TotalAcresInput = forwardRef<HTMLInputElement, StandardInputProps>(
  (props, ref) => {
    return (
      <StandardInput
        {...props}
        type="number"
        hideAddonBackground={true}
        onWheel={(e) => e.currentTarget.blur()}
        onKeyDown={preventNonNumericCharacters}
        ref={ref}
      />
    );
  }
);

TotalAcresInput.displayName = "TotalAcresInput";

export default TotalAcresInput;
