import { useMemo } from "react";
import { FieldValues } from "react-hook-form";

const useFormValidation = <T extends FieldValues>(fields: T) => {
  return useMemo(
    () => Object.entries(fields).some(([, value]) => !value),
    [fields]
  );
};

export default useFormValidation;
