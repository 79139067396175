import { MenuItem } from "@chakra-ui/react";
import { CSV_DOWNLOAD, MANUAL_DELAYED_PRICING } from "constants/featureFlags";
import { useHasFeatureFlag } from "hooks/useFeatureFlags";
import MonthlyAveragePricingButton from "../MonthlyAveragePricingButton";
import PricingMenu from "./PricingMenu";
import { DownloadPricingDataMenuItem } from "./PricingMenuItems";
import ManualDelayedPricingMenuItem from "./PricingMenuItems/ManualDelayedPricingMenuItem";

const AllPricingMenu = () => {
  const hasDownloadPricingDataFeature = useHasFeatureFlag(
    CSV_DOWNLOAD.PRICING_DATA
  );
  const hasManualDelayedPricingFeature = useHasFeatureFlag(
    MANUAL_DELAYED_PRICING.MANUAL_DELAYED_PRICING_BUTTON
  );

  return (
    <PricingMenu
      buttonLabel="Pricing Data"
      menuButtonMinWidth="182px"
      menuListWidth="286px"
    >
      <MenuItem>
        <MonthlyAveragePricingButton />
      </MenuItem>
      {hasDownloadPricingDataFeature ? <DownloadPricingDataMenuItem /> : null}
      {hasManualDelayedPricingFeature ? <ManualDelayedPricingMenuItem /> : null}
    </PricingMenu>
  );
};

export default AllPricingMenu;
