/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface AddressResponse
 */
export interface AddressResponse {
  /**
   *
   * @type {string}
   * @memberof AddressResponse
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof AddressResponse
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof AddressResponse
   */
  city: string;
  /**
   *
   * @type {string}
   * @memberof AddressResponse
   */
  state: AddressResponseStateEnum;
  /**
   *
   * @type {string}
   * @memberof AddressResponse
   */
  zipCode: string;
}

/**
 * @export
 * @enum {string}
 */
export enum AddressResponseStateEnum {
  Alabama = "ALABAMA",
  Alaska = "ALASKA",
  Arizona = "ARIZONA",
  Arkansas = "ARKANSAS",
  California = "CALIFORNIA",
  Colorado = "COLORADO",
  Connecticut = "CONNECTICUT",
  Delaware = "DELAWARE",
  DistrictOfColumbia = "DISTRICT_OF_COLUMBIA",
  Florida = "FLORIDA",
  Georgia = "GEORGIA",
  Hawaii = "HAWAII",
  Idaho = "IDAHO",
  Illinois = "ILLINOIS",
  Indiana = "INDIANA",
  Iowa = "IOWA",
  Kansas = "KANSAS",
  Kentucky = "KENTUCKY",
  Louisiana = "LOUISIANA",
  Maine = "MAINE",
  Maryland = "MARYLAND",
  Massachusetts = "MASSACHUSETTS",
  Michigan = "MICHIGAN",
  Minnesota = "MINNESOTA",
  Mississippi = "MISSISSIPPI",
  Missouri = "MISSOURI",
  Montana = "MONTANA",
  Nebraska = "NEBRASKA",
  Nevada = "NEVADA",
  NewHampshire = "NEW_HAMPSHIRE",
  NewJersey = "NEW_JERSEY",
  NewMexico = "NEW_MEXICO",
  NewYork = "NEW_YORK",
  NorthCarolina = "NORTH_CAROLINA",
  NorthDakota = "NORTH_DAKOTA",
  Ohio = "OHIO",
  Oklahoma = "OKLAHOMA",
  Oregon = "OREGON",
  Pennsylvania = "PENNSYLVANIA",
  PuertoRico = "PUERTO_RICO",
  RhodeIsland = "RHODE_ISLAND",
  SouthCarolina = "SOUTH_CAROLINA",
  SouthDakota = "SOUTH_DAKOTA",
  Tennessee = "TENNESSEE",
  Texas = "TEXAS",
  Utah = "UTAH",
  Vermont = "VERMONT",
  VirginIslands = "VIRGIN_ISLANDS",
  Virginia = "VIRGINIA",
  Washington = "WASHINGTON",
  WestVirginia = "WEST_VIRGINIA",
  Wisconsin = "WISCONSIN",
  Wyoming = "WYOMING",
}
