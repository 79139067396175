import { VStack, Divider } from "@chakra-ui/react";

import {
  CashPrice,
  BasePrice,
  Multiplier,
} from "components/PricingStats/PricingStats";

const ForwardPricingContractStats = ({
  cashPrice,
  futuresFriendlySymbol,
  futuresPrice,
  basis,
  isManuallyCreated,
  multiplier,
  basePrice,
}: {
  cashPrice: number;
  futuresFriendlySymbol: string;
  futuresPrice: string | number;
  basis: number;
  isManuallyCreated: boolean;
  multiplier: number;
  basePrice: number;
}) => {
  return (
    <VStack align="left" spacing={4}>
      <CashPrice
        cashPrice={cashPrice}
        futuresFriendlySymbol={futuresFriendlySymbol}
        futuresPrice={futuresPrice}
        basis={basis}
        isManuallyCreated={isManuallyCreated}
      />
      <Multiplier multiplier={multiplier} />
      <BasePrice basePrice={basePrice} />
      <Divider color="grayFactor.2" opacity={1} borderWidth="1px" />
    </VStack>
  );
};

export default ForwardPricingContractStats;
