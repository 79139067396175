import { DairyApi, DairyDto } from "api";
import { extractResponseData } from "./apiHelpers";
import { constructApiControllerInstance } from "./customApiConfig";

class DairyService {
  dairyController!: DairyApi;
  constructor() {
    this.dairyController = constructApiControllerInstance(DairyApi);
  }

  getDairies(regionId: string): Promise<DairyDto[]> {
    return this.dairyController
      .getAllDairies({ regionId })
      .then(extractResponseData);
  }
}

export default DairyService;
