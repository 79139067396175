import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useUserService } from "contexts/ServiceProvider";
import UserService from "services/UserService";
import { MutationHookResult, MutationHook } from "../hookTypeHelpers";
import { InviteUserRequestRoleEnum } from "api";
import { GET_USERS_QUERY_KEY } from "./useUsersList";

type InviteNewUserMutationResult = MutationHookResult<
  Awaited<ReturnType<UserService["createNewUserInvite"]>>,
  Omit<NewUserInfo, "role"> & {
    role: InviteUserRequestRoleEnum;
    farmId?: string;
  }
>;

const useInviteNewUserMutation = (): InviteNewUserMutationResult => {
  const userService = useUserService();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["invite-new-user"],
    mutationFn: ({ firstName, lastName, emailAddress, role, farmId }) =>
      userService.createNewUserInvite({
        inviteUserRequest: {
          firstName,
          lastName,
          emailAddress,
          role,
        },
        farmId,
      }),
    onSuccess: () =>
      queryClient.refetchQueries({
        queryKey: [GET_USERS_QUERY_KEY],
      }),
  });
};

const useInviteNewUser = (): MutationHook<
  InviteNewUserMutationResult,
  "onInviteNewUser"
> => {
  const {
    mutate: onInviteNewUser,
    mutateAsync: onInviteNewUserAsync,
    ...rest
  } = useInviteNewUserMutation();

  return { onInviteNewUser, onInviteNewUserAsync, ...rest };
};

export default useInviteNewUser;
