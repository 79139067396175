import { ReactElement } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import dashboardPaths from "routes/dashboard/dashboardPaths";

const RedirectToDashboard = (): ReactElement => {
  const [searchParams] = useSearchParams();

  return <Navigate to={`/${dashboardPaths.basePath}?${searchParams}`} />;
};

export default RedirectToDashboard;
