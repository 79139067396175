import { Center, CenterProps, Spinner, SpinnerProps } from "@chakra-ui/react";
import { ReactElement } from "react";

type WithSpinnerProps = {
  isLoading: boolean;
  children: ReactElement;
  centerProps?: CenterProps;
  spinnerProps?: SpinnerProps;
};

const WithSpinner = ({
  isLoading,
  children,
  centerProps,
  spinnerProps,
}: WithSpinnerProps) => {
  return isLoading ? (
    <Center h="100%" {...centerProps}>
      <Spinner {...spinnerProps} />
    </Center>
  ) : (
    children
  );
};

export default WithSpinner;
