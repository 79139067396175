import { ProfilePictureApi, ProfilePictureResponse } from "api";
import { extractResponseData } from "./apiHelpers";
import { constructApiControllerInstance } from "./customApiConfig";

class ProfilePictureService {
  private profilePictureController!: ProfilePictureApi;
  constructor() {
    this.profilePictureController =
      constructApiControllerInstance(ProfilePictureApi);
  }
  setRegistrationProfilePicture(
    token: string,
    image: File,
    xOffset = 0,
    yOffset = 0
  ): Promise<ProfilePictureResponse> {
    return this.profilePictureController
      .setProfilePictureDuringRegistration({
        token,
        picture: image,
        xoffset: xOffset,
        yoffset: yOffset,
      })
      .then(extractResponseData);
  }
}

export default ProfilePictureService;
