import { ReactElement } from "react";
import { Navigate, useParams } from "react-router-dom";

import { UserStatusDtoValueEnum } from "api";
import FormSkeletonLoadingState from "components/FormSkeletonLoadingState";
import usersPaths from "routes/user/list/usersPaths";
import useSelectedFarmId from "hooks/useSelectedFarmId";
import useUserDetails from "hooks/user/useUserDetails";
import UserDetailsFormContext from "./components/UserDetailsFormContext";
import UserDetailsForm from "./components/UserDetailsForm";

const UserDetailsPage = (): ReactElement => {
  const { id } = useParams();
  const [farmId] = useSelectedFarmId();
  const { data, isLoading } = useUserDetails(id, farmId);

  if (isLoading) {
    return <FormSkeletonLoadingState rowCount={5} />;
  }

  if (!data || !id) {
    // TODO: Follow up on best error handling, if the user fails to load
    return <Navigate to={`/${usersPaths.basePath}`} />;
  }

  return (
    <UserDetailsFormContext userData={data}>
      <UserDetailsForm
        userId={id}
        isPending={data.status.value === UserStatusDtoValueEnum.Pending}
        farmClaims={data.farms}
        isEmployee={data.isEmployee}
      />
    </UserDetailsFormContext>
  );
};

export default UserDetailsPage;
