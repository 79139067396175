import { ReactElement, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { Center, Spinner, Stack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";

import { UserInviteResponse } from "api";
import SubmitButton from "components/SubmitButton";
import { useRegistrationForm } from "contexts/RegistrationFormContext";
import useFormValidation from "hooks/useFormValidation";
import useNavigateToErrorPage from "hooks/useNavigateToErrorPage";
import useInviteDetails from "hooks/user/useInviteDetails";
import { userRegistrationErrorStates } from "pages/user-registration/userRegistrationErrorStates";
import userRegistrationPaths from "routes/user-registration/userRegistrationPaths";
import { BaseApiError, extractResponseErrorMessage } from "services/apiHelpers";
import { basicUserInfoFormSchema } from "../schemas/basicUserInfoFormSchema";
import BasicUserInfoInputs from "./BasicUserInfoInputs";

const BasicUserInfoForm = ({
  data,
  isPending,
  isError,
  error,
  onSetBasicUserInfo,
  onSubmitSuccess,
}: {
  data: UserInviteResponse | undefined;
  isPending: boolean;
  isError: boolean;
  error: BaseApiError | null;
  onSetBasicUserInfo: (info: BasicUserInfo) => void;
  onSubmitSuccess?: () => void;
}): ReactElement => {
  const newUserInfo = useMemo(
    () => ({
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: data?.emailAddress,
      password: undefined,
      confirmPassword: undefined,
    }),

    [data?.emailAddress, data?.firstName, data?.lastName]
  );

  const methods = useForm<BasicUserInfo>({
    resolver: yupResolver(basicUserInfoFormSchema),
    defaultValues: newUserInfo,
  });
  const requiredFieldsNotFilled = useFormValidation(methods.watch());

  const navigate = useNavigate();
  const navigateToErrorPage = useNavigateToErrorPage(
    userRegistrationErrorStates,
    userRegistrationPaths.children.error
  );

  useEffect(() => {
    if (isError) {
      const errorMsg = extractResponseErrorMessage(error?.response);
      if (userRegistrationErrorStates.USER_REGISTERED.match?.test(errorMsg)) {
        // During this stage, if the user is already registered, redirect to the login automatically.
        navigate(userRegistrationErrorStates.USER_REGISTERED.linkTo ?? "", {
          replace: true,
        });
      } else {
        navigateToErrorPage(errorMsg);
      }
    }
  }, [error?.response, isError, navigate, navigateToErrorPage]);

  return (
    <FormProvider {...methods}>
      <Stack
        as="form"
        w="100%"
        onSubmit={methods.handleSubmit((data) => {
          onSetBasicUserInfo(data);
          onSubmitSuccess?.();
        })}
      >
        <BasicUserInfoInputs isLoading={isPending} />
        <SubmitButton
          w="100%"
          size="lg"
          submitDisabled={requiredFieldsNotFilled}
          isSubmitting={methods.formState.isSubmitting}
          buttonText="Continue"
        />
      </Stack>
    </FormProvider>
  );
};

const BasicUserInfoFormWrapper = ({
  onSubmitSuccess,
}: {
  onSubmitSuccess?: () => void;
}) => {
  const { inviteToken, onSetBasicUserInfo } = useRegistrationForm();
  const { data, isPending, isError, error } = useInviteDetails(inviteToken);

  if (isPending) {
    return (
      <Center h="488px" w="100%">
        <Spinner />
      </Center>
    );
  }

  return (
    <BasicUserInfoForm
      data={data}
      isPending={isPending}
      isError={isError}
      error={error}
      onSetBasicUserInfo={onSetBasicUserInfo}
      onSubmitSuccess={onSubmitSuccess}
    />
  );
};

export default BasicUserInfoFormWrapper;
