import { PropsWithChildren } from "react";

import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  CropYearDetailResponse,
  CropYearDetailResponseTimeOfYearEnum,
} from "api";
import { cropYearDetailFormSchema } from "forms/crop-year-detail";
import { parseDate } from "utils/dateUtils";
import { formatNumberWithDecimals } from "utils/formatFunctions";

export type CropYearDetailFields = {
  year: number;
  timeOfYear: CropYearDetailResponseTimeOfYearEnum | undefined;
  multiplier: number | undefined;
  distanceAdjRate: string | undefined;
  growerLetterName: string | undefined;
  growerLetterLink: string | undefined;
  defermentDateStart: Date | undefined;
  firstPaymentDateWithoutDeferral: Date | undefined;
  averagePricingLockInDate: Date | undefined;
  delayedPricingFinalDate: Date;
  regionDetails: {
    regionId: string;
    multiplier: number;
    estimatedTonsPerAcre: number;
  }[];
  storageFeeStartDate: Date | undefined;
  storageFeeRate: string | undefined;
};

const NEXT_YEAR = new Date().getFullYear() + 1;

const CropYearFormContext = ({
  children,
  data,
}: PropsWithChildren<{
  data: Partial<CropYearDetailResponse>;
}>) => {
  const methods = useForm<CropYearDetailFields>({
    resolver: yupResolver(cropYearDetailFormSchema()),
    defaultValues: {
      timeOfYear:
        data.timeOfYear ?? CropYearDetailResponseTimeOfYearEnum.PreHarvest,
      distanceAdjRate: formatNumberWithDecimals(
        data.distanceAdjustmentRate ?? 0
      ),
      year: data.year ?? NEXT_YEAR,
      growerLetterName: data.growerLetterName,
      growerLetterLink: data.growerLetterLink,
      defermentDateStart: data.defermentDateStart
        ? parseDate(data.defermentDateStart)
        : undefined,
      firstPaymentDateWithoutDeferral: data.firstPaymentDateWithoutDeferral
        ? parseDate(data.firstPaymentDateWithoutDeferral)
        : undefined,
      averagePricingLockInDate: data.averagePricingLockInDate
        ? parseDate(data.averagePricingLockInDate)
        : undefined,
      delayedPricingFinalDate: data.delayedPricingFinalDate
        ? parseDate(data.delayedPricingFinalDate)
        : undefined,
      regionDetails: (data.regions ?? []).map((estimate) => ({
        regionId: estimate.regionId,
        estimatedTonsPerAcre: estimate.estimatedTonsPerAcre,
        multiplier: estimate.multiplier,
      })),
      storageFeeStartDate: data.storageFeeStartDate
        ? parseDate(data.storageFeeStartDate)
        : undefined,
      storageFeeRate: formatNumberWithDecimals(data.storageFeeRate ?? 0),
    },
  });

  return <FormProvider {...methods}>{children}</FormProvider>;
};

export default CropYearFormContext;
