import { PropsWithChildren } from "react";
import {
  Navigate,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";

import RedirectToDashboard from "components/redirects/RedirectToDashboard";
import FormSkeletonLoadingState from "components/FormSkeletonLoadingState";
import useFarmDetail from "hooks/farm/farm-detail/useFarmDetail";
import useUserRoleFlags from "hooks/auth/useUserRoleFlags";
import farmPaths from "routes/farm/farmPaths";
import adminPaths from "routes/admin/adminPaths";
import { isFarmPending } from "utils/farmStatusUtils";
import FarmFormContext from "../FarmFormContext";

const FarmDataWrapper = ({ children }: PropsWithChildren) => {
  const { isEmployee } = useUserRoleFlags();
  const [searchParams] = useSearchParams();
  const { id } = useParams<{ id: string }>();
  const { data: farmData, isLoading: farmIsLoading } = useFarmDetail(id);

  const { pathname } = useLocation();

  if (farmIsLoading) {
    return <FormSkeletonLoadingState rowCount={8} />;
  }

  if (!farmData) {
    // TODO: Follow up on best error handling, if the farm or user fails to load
    return isEmployee ? (
      <Navigate to={`${adminPaths.basePath}/${adminPaths.children.farms}`} />
    ) : (
      <RedirectToDashboard />
    );
  }
  const pendingFarm = isFarmPending(farmData.status.value);

  const correctPendingPath = `/${farmPaths.basePath}/${farmPaths.children.approve}/${id}`;
  const correctDefaultPaths = [
    `/${farmPaths.basePath}/${id}/${farmPaths.children.edit}`,
    `/${farmPaths.basePath}/${id}/${farmPaths.children.documents}`,
  ];

  // Navigate to approval page if needs approval but on edit page, and vice versa.
  if (pendingFarm && correctPendingPath !== pathname) {
    return <Navigate to={`${correctPendingPath}?${searchParams}`} />;
  } else if (!pendingFarm && !correctDefaultPaths.includes(pathname)) {
    return <Navigate to={`${correctDefaultPaths[0]}?${searchParams}`} />;
  }

  return <FarmFormContext farmData={farmData}>{children}</FarmFormContext>;
};
export default FarmDataWrapper;
