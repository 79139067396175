import { useMemo } from "react";

import { Box, Button, HStack, Text, VStack } from "@chakra-ui/react";
import {
  Controller,
  useFormContext,
  useFormState,
  useWatch,
} from "react-hook-form";

import { MinimumFarmListDto } from "api";
import { PercentageInput } from "components/inputs";
import InputWrapper from "components/inputs/InputWrapper";
import { RemoveIcon } from "icons";
import SplitFarmSelect from "./SplitFarmSelect";
import { SingleFieldSplit, SplitFormProps } from "../SplitsDialog";
import { formatNumberWithDecimals } from "utils/formatFunctions";

export type SingleSplitProps = {
  index: number;
  fieldSplitDefault?: SingleFieldSplit;
  selectableFarms: MinimumFarmListDto[];
  fieldInfo: {
    totalAcres: number;
  };
  isRemoveSplitDisabled: boolean;
  onRemove: () => void;
};

const SingleSplit = ({
  index,
  fieldSplitDefault,
  selectableFarms,
  fieldInfo: { totalAcres },
  isRemoveSplitDisabled,
  onRemove,
}: SingleSplitProps) => {
  const { trigger, control } = useFormContext<SplitFormProps>();
  const { errors, isSubmitted } = useFormState();
  const splitsError = errors.splits?.message?.toString();

  const splitPercent = useWatch<SplitFormProps>({
    control,
    name: `splits.${index}.split`,
  }) as number;

  const estimateAcres = useMemo(() => {
    return formatNumberWithDecimals(
      Math.max(0, (splitPercent / 100) * totalAcres)
    );
  }, [totalAcres, splitPercent]);

  return (
    <Box
      border="1px solid"
      borderColor="greyFactor.2"
      borderRadius={2}
      background="greyFactor.0"
      padding={8}
      position="relative"
    >
      <VStack spacing={4} align="start">
        <Box w="100%">
          <Box py={2}>
            <SplitFarmSelect
              id={`splits.${index}.farm`}
              selectableFarms={selectableFarms}
              defaultValue={fieldSplitDefault?.farm}
            />
          </Box>
        </Box>
        <Box w="100%">
          <Controller
            control={control}
            defaultValue={fieldSplitDefault?.split}
            name={`splits.${index}.split`}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <InputWrapper
                label="Split Percentage"
                isRequired
                errorMsg={error?.message || splitsError}
                isInvalid={!!error || !!splitsError}
              >
                <Box py={2}>
                  <PercentageInput
                    id={`splits.${index}.split`}
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                      if (isSubmitted) {
                        // if form has been submitted, trigger re-validation of splits array when an individual split changes
                        trigger("splits");
                      }
                    }}
                  />
                </Box>
              </InputWrapper>
            )}
          />
        </Box>
        <HStack w="100%" justify="space-between">
          <Text fontWeight="bold">Estimated Acres</Text>
          <Text>{estimateAcres}</Text>
        </HStack>
      </VStack>
      {!isRemoveSplitDisabled && (
        <Button
          rightIcon={<RemoveIcon h="18px" w="18px" />}
          size="md"
          position="absolute"
          top={9}
          right={9}
          variant="link"
          colorScheme="error"
          onClick={onRemove}
        >
          Remove
        </Button>
      )}
    </Box>
  );
};
export default SingleSplit;
