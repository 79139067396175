import {
  Badge,
  Button,
  Center,
  Divider,
  HStack,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { addDays } from "date-fns";
import { useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { NavLink, useSearchParams } from "react-router-dom";

import { baseForwardContractSchema } from "../schemas/baseForwardContractSchema";
import {
  AgreedByRecordStamp,
  ContractCreatedRecordStamp,
  PaymentDate,
  PercentageToSellInput,
} from "./components";
import {
  CropYearDetailResponse,
  CropYearDetailResponseTimeOfYearEnum,
  CropYearFarmSummaryDto,
  FarmDetailResponse,
  PricingOptionResponse,
  PricingOptionStatusDtoStatusEnum,
} from "api";
import { DesktopOnly, MobileOnly } from "components/BreakpointVisibility";
import ContentWrapper from "components/ContentWrapper";
import { DeferPaymentInputs } from "components/inputs/DeferPaymentInputs";
import {
  DEFER_PAYMENT_OPTION_NO,
  DEFER_PAYMENT_OPTION_YES,
} from "components/inputs/DeferPaymentSelectInput";
import { PageHeader } from "components/page-components";
import DisplayLabelValue from "components/PricingStats/DisplayLabelValue";
import {
  BasePrice,
  CashPrice,
  DistanceAdjustment,
  Multiplier,
  NetPrice,
  NetTotal,
  PercentageToSell,
  StorageFees,
} from "components/PricingStats/PricingStats";
import { SelectOption } from "components/Select";
import SubmitButton from "components/SubmitButton";
import SummaryStatCard from "components/SummaryStatCard";
import { FieldsStatIcon } from "components/SummaryStatCard/summaryStats/SummaryStatIcons";
import { useCropYearContext } from "contexts/CropYearContext";
import useUserRoleFlags from "hooks/auth/useUserRoleFlags";
import useUpdatePendingManualForwardContract from "hooks/pricing-option/useUpdatePendingManualForwardContract";
import useUpdateForwardContractDeferment from "hooks/pricing-option/useUpdateForwardContractDeferment";
import useUpdatePendingStandardForwardContract from "hooks/pricing-option/useUpdatePendingStandardForwardContract";
import useCustomToast from "hooks/useCustomToast";
import { FORM_CONTENT_PX, FormStack } from "layouts/FormLayout";
import dashboardPaths from "routes/dashboard/dashboardPaths";
import {
  formatDate,
  ISODateWithoutTime,
  parseDate,
  parseIsoDate,
} from "utils/dateUtils";
import { defaultDeferPayStartDate } from "utils/defaultDeferPayStartDate";
import { formatNumberWithDecimals } from "utils/formatFunctions";
import { getIsDeferPayment } from "utils/getIsDeferPayment";
import { getStatusObjectForPricingOption } from "utils/getStatusObjectForPricingOption";
import { mobileStyleBreakpoint } from "utils/styleHelpers";

export type EditForwardPricingFormData = {
  percentageToSell: number;
  deferPayment: SelectOption;
  defermentDate: Date;
};

const formDataStackStyle = {
  align: "left",
  maxWidth: { base: "100%", md: "40%" },
};

const PAGE_HEADER_MAX_HEIGHT = mobileStyleBreakpoint("unset", "54px");

const EditForwardPricingForm = ({
  pricingOption,
  cropYear,
  farm,
  farmCropYearSummary,
}: {
  pricingOption: PricingOptionResponse;
  cropYear: CropYearDetailResponse;
  farm: FarmDetailResponse;
  farmCropYearSummary: CropYearFarmSummaryDto;
}) => {
  const [searchParams] = useSearchParams();
  const { isEmployeeAdmin, isFarmer } = useUserRoleFlags();
  const { onSuccessToast, onErrorToast } = useCustomToast();
  const { cropYearDetail } = useCropYearContext();
  const { mutateAsync: mutateUpdateManualContract } =
    useUpdatePendingManualForwardContract(
      pricingOption.id,
      cropYear.id,
      farm.id
    );
  const { mutateAsync: mutateUpdateDeferment } =
    useUpdateForwardContractDeferment(pricingOption.id, cropYear.id, farm.id);
  const { mutateAsync: mutateUpdateStandardContract } =
    useUpdatePendingStandardForwardContract(
      pricingOption.id,
      cropYear.id,
      farm.id
    );

  const isPostHarvest =
    cropYearDetail?.timeOfYear ===
    CropYearDetailResponseTimeOfYearEnum.PostHarvest;

  const statusObject = getStatusObjectForPricingOption(
    pricingOption.status.status,
    isFarmer,
    pricingOption.strictType
  );

  const tomorrowsDate = addDays(new Date(), 1);
  const defaultDefermentDate = defaultDeferPayStartDate({
    cropYearDefermentDate: parseIsoDate(
      cropYearDetail?.defermentDateStart as string
    ),
    tomorrowsDate,
  });

  const methods = useForm<EditForwardPricingFormData>({
    resolver: yupResolver(
      baseForwardContractSchema(
        defaultDefermentDate,
        100 -
          (farmCropYearSummary.percentageOfTonsPriced ?? 0) +
          (pricingOption.percentageToSell ?? 0)
      )
    ),
    defaultValues: {
      percentageToSell: pricingOption.percentageToSell,
      deferPayment: pricingOption.deferPayment
        ? DEFER_PAYMENT_OPTION_YES
        : DEFER_PAYMENT_OPTION_NO,
      defermentDate: pricingOption.defermentDate
        ? parseDate(pricingOption.defermentDate)
        : defaultDefermentDate,
    },
  });

  const { isDirty, isSubmitting } = methods.formState;

  const onSubmit = useCallback(
    (data: EditForwardPricingFormData) => {
      const deferPayment = getIsDeferPayment(data.deferPayment.value);
      const defermentDate =
        deferPayment && data.defermentDate
          ? ISODateWithoutTime(data.defermentDate)
          : undefined;
      const percentageToSell = data.percentageToSell;

      const onSuccess = () => {
        onSuccessToast({
          message: "Forward pricing contract updated successfully",
        });
      };
      const onError = () => {
        onErrorToast({
          message: `Failed to update forward pricing contract`,
        });
      };

      if (
        pricingOption.status.status === PricingOptionStatusDtoStatusEnum.Active
      ) {
        return mutateUpdateDeferment({ defermentDate, deferPayment })
          .then(onSuccess)
          .catch(onError);
      } else if (pricingOption.isManuallyCreated) {
        return mutateUpdateManualContract({
          defermentDate,
          deferPayment,
          percentageToSell,
          cashPrice: pricingOption.cashPrice,
          dateOfContractCreation: pricingOption.contractCreationDate,
        })
          .then(onSuccess)
          .catch(onError);
      } else {
        return mutateUpdateStandardContract({
          defermentDate,
          deferPayment,
          percentageToSell,
        })
          .then(onSuccess)
          .catch(onError);
      }
    },
    [
      mutateUpdateDeferment,
      mutateUpdateManualContract,
      mutateUpdateStandardContract,
      onErrorToast,
      onSuccessToast,
      pricingOption,
    ]
  );

  const isDeferPayment = getIsDeferPayment(methods.watch("deferPayment").value);
  const isAnyFieldEditable = isEmployeeAdmin;

  return (
    <Center w="100%" px={FORM_CONTENT_PX} position="relative">
      <ContentWrapper>
        <FormProvider {...methods}>
          <FormStack onSubmit={methods.handleSubmit(onSubmit)}>
            <PageHeader
              heading="Pricing Information"
              maxH={PAGE_HEADER_MAX_HEIGHT}
            >
              <DesktopOnly>
                {isAnyFieldEditable && (
                  <SubmitButton
                    submitDisabled={!isDirty || isSubmitting}
                    isSubmitting={isSubmitting}
                    buttonText="Save"
                    width="152px"
                  />
                )}
              </DesktopOnly>
            </PageHeader>

            <Text color="grayFactor.4" fontSize="lg" fontWeight="bold">
              {farm.doingBusinessAs}
            </Text>

            <Stack
              align="start"
              direction={{ base: "column", md: "row" }}
              justify={{ base: "flex-start", md: "space-between" }}
            >
              <HStack>
                <Text fontSize="2xl" fontWeight="bold">
                  Forward Pricing
                </Text>
                <Badge variant={statusObject?.value} mb={4}>
                  {statusObject?.displayName}
                </Badge>
              </HStack>

              <DesktopOnly>
                <Stack align="end" direction="column" spacing={4}>
                  <ContractCreatedRecordStamp
                    createdBy={pricingOption.createdBy?.name}
                    createdAt={pricingOption.contractCreationDate}
                  />
                  {pricingOption.auditLog.length > 0 && (
                    <AgreedByRecordStamp
                      auditItem={pricingOption.auditLog[0]}
                    />
                  )}
                </Stack>
              </DesktopOnly>
            </Stack>

            {isPostHarvest && (
              <SummaryStatCard
                icon={<FieldsStatIcon />}
                label="Tons to sell"
                value={pricingOption.totalTons ?? 0}
                formatter={() =>
                  formatNumberWithDecimals(pricingOption.totalTons ?? 0, 8)
                }
                maxW="342px"
                statNumberColor="greyFactor.4"
                statLabelColor="greyFactor.4"
              />
            )}

            <MobileOnly>
              <ContractCreatedRecordStamp
                createdBy={pricingOption.createdBy?.name}
                createdAt={pricingOption.contractCreationDate}
              />
              {pricingOption.auditLog.length > 0 && (
                <AgreedByRecordStamp auditItem={pricingOption.auditLog[0]} />
              )}
            </MobileOnly>

            <Divider />

            <VStack {...formDataStackStyle} spacing={8}>
              {isEmployeeAdmin &&
              pricingOption.status.status ===
                PricingOptionStatusDtoStatusEnum.Pending ? (
                <PercentageToSellInput />
              ) : (
                <PercentageToSell
                  isAgreed={isPostHarvest}
                  percentageToSell={pricingOption.percentageToSell}
                />
              )}

              {isEmployeeAdmin ? (
                <DeferPaymentInputs
                  isDeferPayment={isDeferPayment}
                  minDate={defaultDefermentDate}
                />
              ) : (
                <>
                  <DisplayLabelValue
                    label="Defer Payment"
                    value={
                      pricingOption.deferPayment
                        ? DEFER_PAYMENT_OPTION_YES.label
                        : DEFER_PAYMENT_OPTION_NO.label
                    }
                  />
                  {isDeferPayment && (
                    <DisplayLabelValue
                      label="Deferment Date"
                      value={
                        pricingOption.defermentDate
                          ? formatDate(parseDate(pricingOption.defermentDate))
                          : "-"
                      }
                    />
                  )}
                </>
              )}

              {!isDeferPayment &&
                !!cropYearDetail?.firstPaymentDateWithoutDeferral && (
                  <PaymentDate
                    firstPaymentDateWithoutDeferral={parseDate(
                      cropYearDetail.firstPaymentDateWithoutDeferral
                    )}
                  />
                )}

              <CashPrice
                cashPrice={pricingOption.cashPrice}
                futuresFriendlySymbol={pricingOption.futuresFriendlySymbol}
                futuresPrice={pricingOption.futuresPrice}
                basis={pricingOption.basis}
                isManuallyCreated={pricingOption.isManuallyCreated}
              />

              <Multiplier multiplier={pricingOption.multiplier} />

              <BasePrice basePrice={pricingOption.basePrice} />
            </VStack>

            <Divider />

            {!isPostHarvest ? (
              <>
                <Text color="charcoal">
                  Final Distance Adjustment and Net Price to be calculated at
                  Post-Harvest.
                </Text>

                <Divider />
              </>
            ) : (
              <>
                <VStack {...formDataStackStyle} spacing={8}>
                  <DistanceAdjustment
                    distanceAdjustment={pricingOption.distanceAdjustment}
                  />

                  <StorageFees
                    storageFees={pricingOption.storageFeeAdjustment}
                    storageFeeRate={cropYear.storageFeeRate}
                    storageFeeStartDate={cropYear.storageFeeStartDate}
                  />
                  <NetPrice netPrice={pricingOption.contractPrice} />
                </VStack>

                <Divider />

                <HStack
                  maxWidth={{ base: "100%", md: "40%" }}
                  justify="space-between"
                >
                  <Text fontWeight="bold" color="charcoal">
                    Net Total
                  </Text>
                  <NetTotal netTotal={pricingOption.contractTotal} />
                </HStack>
              </>
            )}

            <MobileOnly>
              {isAnyFieldEditable && (
                <SubmitButton
                  submitDisabled={!isDirty || isSubmitting}
                  isSubmitting={isSubmitting}
                  buttonText="Save"
                  width="100%"
                />
              )}

              <Button
                variant="outline"
                size="md"
                w="100%"
                as={NavLink}
                to={`/${dashboardPaths.basePath}/${
                  dashboardPaths.children.pricing
                }?${searchParams.toString()}`}
              >
                Close
              </Button>
            </MobileOnly>
          </FormStack>
        </FormProvider>
      </ContentWrapper>
    </Center>
  );
};

export default EditForwardPricingForm;
