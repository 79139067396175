import { ReactElement } from "react";
import DecimalNumberInput, {
  DecimalNumberInputProps,
} from "../DecimalNumberInput";

export const MoneyInput = (props: DecimalNumberInputProps): ReactElement => {
  return (
    <DecimalNumberInput
      {...props}
      defaultEmptyValueToZero={props.defaultEmptyValueToZero ?? true}
      leftAddon="$"
      clearZeroValue={true}
    />
  );
};

export default MoneyInput;
