import { Button } from "@chakra-ui/react";
import { MdOutlineEmail } from "react-icons/md";
import { ReactElement } from "react";

const ResendInviteButton = ({
  resendDisabled,
  onClick,
}: {
  resendDisabled: boolean;
  onClick: () => void;
}): ReactElement => {
  return (
    <Button
      leftIcon={<MdOutlineEmail size={20} />}
      aria-label="Email"
      variant="tableButton"
      isDisabled={resendDisabled}
      onClick={onClick}
    >
      Resend Invite
    </Button>
  );
};

export default ResendInviteButton;
