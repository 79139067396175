import { useQuery } from "@tanstack/react-query";
import { PricingOptionResponse } from "api";
import { usePricingOptionService } from "contexts/ServiceProvider";
import { useEffect } from "react";
import { BaseApiError } from "services/apiHelpers";

export const GET_PRICING_OPTION_DETAILS_QUERY_KEY =
  "get-pricing-option-details";

const useGetPricingOptionDetails = (
  pricingOptionId?: string,
  farmId?: string,
  onSuccess?: (data?: PricingOptionResponse) => void
) => {
  const pricingOptionService = usePricingOptionService();

  const query = useQuery<PricingOptionResponse, BaseApiError>({
    queryKey: [
      GET_PRICING_OPTION_DETAILS_QUERY_KEY,
      { id: pricingOptionId, farmId },
    ],
    queryFn: () =>
      pricingOptionId && farmId
        ? pricingOptionService.getPricingOptionDetails({
            pricingOptionId,
            farmId,
          })
        : Promise.reject(new Error("Missing pricing option ID or farm ID")),
    enabled: !!pricingOptionId && !!farmId,
  });

  useEffect(() => {
    if (query.isSuccess) {
      onSuccess?.(query.data);
    }
  }, [onSuccess, query.data, query.isSuccess]);

  return query;
};

export default useGetPricingOptionDetails;
