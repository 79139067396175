import { Flex, Text } from "@chakra-ui/react";
import { formatDate, parseDate } from "utils/dateUtils";
import { PricingOptionResponse } from "api";

export type BaseSlideoutProps = {
  data?: PricingOptionResponse;
};

const BaseSlideout = ({ data }: BaseSlideoutProps) => {
  return (
    <Flex justifyContent={"space-between"}>
      <Text fontWeight={"bold"}>Deferment Date</Text>
      <Text>
        {data?.defermentDate && data.deferPayment
          ? formatDate(parseDate(data.defermentDate))
          : "No"}
      </Text>
    </Flex>
  );
};

export default BaseSlideout;
