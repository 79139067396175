import { Dispatch, forwardRef, ReactElement, SetStateAction } from "react";
import {
  chakraComponents,
  OptionBase,
  Props as useChakraSelectPropsType,
  Select as ChakraReactSelect,
  useChakraSelectProps,
} from "chakra-react-select";
import { IoCaretDownOutline } from "react-icons/io5";
import { Icon } from "@chakra-ui/react";
import { ControllerRenderProps } from "react-hook-form";

export interface SelectOption extends OptionBase {
  label: string;
  secondLabel?: string;
  value: string;
}

export type SelectProps = useChakraSelectPropsType<SelectOption>;

const customComponents: SelectProps["components"] = {
  DropdownIndicator: (props) => (
    <chakraComponents.DropdownIndicator {...props}>
      <Icon as={IoCaretDownOutline} h={2} />
    </chakraComponents.DropdownIndicator>
  ),
  LoadingIndicator: (props) => (
    <chakraComponents.LoadingIndicator
      color="brand.800"
      spinnerSize="lg"
      {...props}
    />
  ),
};

export const selectCustomStyles: SelectProps["chakraStyles"] = {
  container: (provider) => ({
    ...provider,
    h: 10,
    bgColor: "white",
  }),
  indicatorSeparator: (provider) => ({
    ...provider,
    display: "none",
  }),
  dropdownIndicator: (provider) => ({
    ...provider,
    bgColor: "white",
  }),
  placeholder: (provider) => ({
    ...provider,
    color: "chakra-placeholder-color",
  }),
  menu: (provider) => ({
    ...provider,
    mt: 1,
    mb: 0,
    boxShadow: "0px 10px 20px rgba(45, 55, 72, 0.15)",
    // This is to allow the menu to overlay on top of our input addon elements with z-index 2
    zIndex: 3,
  }),
  option: (provided, state) => {
    const focusedBackground = state.isFocused ? "grayFactor.1" : "unset";

    return {
      ...provided,
      background: state.isSelected ? "brand.50" : focusedBackground,
      color: state.isFocused || state.isSelected ? "charcoal" : "unset",
      _hover: {
        background: "grayFactor.1",
        color: state.isSelected ? "charcoal" : "unset",
      },
    };
  },
};

type SelectInputProps = ControllerRenderProps<
  Record<string, SelectOption> | Record<string, any>
> &
  SelectProps;

const FormSelect = forwardRef<
  any,
  {
    options: SelectOption[];
    placeholder?: string;
    isLoading?: boolean;
    isError?: boolean;
    errorMsg?: string;
    components?: SelectProps["components"];
  } & Omit<SelectInputProps, "onBlur">
>(
  (
    {
      options,
      placeholder = "Select One",
      isLoading,
      isError,
      errorMsg = "Failed to load options",
      components = {},
      ...props
    },
    ref
  ): ReactElement => {
    const selectProps = useChakraSelectProps({
      options: options,
      components: { ...customComponents, ...components },
      chakraStyles: selectCustomStyles,
      errorBorderColor: "errorRed",
      focusBorderColor: isError ? "errorRed" : "brand.800",
    });

    return (
      <ChakraReactSelect
        {...selectProps}
        {...props}
        ref={ref}
        placeholder={isError ? errorMsg : placeholder}
        menuPlacement="auto"
        isLoading={isLoading}
        isInvalid={isError}
      />
    );
  }
);

export const SingleSelect = ({
  options,
  placeholder = "Select One",
  selectedOption,
  setSelectedOption,
  ...props
}: {
  options: SelectOption[];
  placeholder?: string;
  selectedOption?: SelectOption;
  setSelectedOption: Dispatch<SetStateAction<SelectOption | undefined>>;
} & SelectProps): ReactElement => {
  const selectProps = useChakraSelectProps({
    isMulti: false,
    options: options,
    components: customComponents,
    chakraStyles: selectCustomStyles,
    value: selectedOption,
    onChange: (option) =>
      option && setSelectedOption(option as SelectOption | undefined),
  });

  return (
    <ChakraReactSelect
      {...selectProps}
      {...props}
      menuPlacement="auto"
      placeholder={placeholder}
    />
  );
};

FormSelect.displayName = "Select";

export default FormSelect;
