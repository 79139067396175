import { useMutation } from "@tanstack/react-query";

import { PricingOptionApiApproveForwardContractRequest } from "api";
import { usePricingOptionService } from "contexts/ServiceProvider";
import { MutationHookResult } from "hooks/hookTypeHelpers";
import PricingOptionService from "services/PricingOptionService";
import useDashboardRefetchQueries from "hooks/crop-year/useDashboardRefetchQueries";

type ApproveForwardContractMutationResult = MutationHookResult<
  Awaited<ReturnType<PricingOptionService["approveForwardContract"]>>,
  PricingOptionApiApproveForwardContractRequest
>;

export const APPROVE_FORWARD_CONTRACT_QUERY_KEY =
  "approve-forward-contract-query-key";

const useApproveForwardContract = (): ApproveForwardContractMutationResult => {
  const pricingOptionService = usePricingOptionService();
  const { refetch } = useDashboardRefetchQueries();

  return useMutation({
    mutationFn: ({ forwardContractId, cropYearId, farmId }) => {
      if (cropYearId && forwardContractId && farmId) {
        return pricingOptionService.approveForwardContract({
          cropYearId,
          forwardContractId,
          farmId,
        });
      } else {
        return Promise.reject(
          "Missing crop year id, forward contract id, or farm id."
        );
      }
    },
    mutationKey: [APPROVE_FORWARD_CONTRACT_QUERY_KEY],
    onSuccess: async () => {
      await refetch();
    },
  });
};

export default useApproveForwardContract;
