import { ReactNode } from "react";

import { Center, Stack } from "@chakra-ui/react";
import SiteHeader from "components/SiteHeader";
import { mobileStyleBreakpoint } from "utils/styleHelpers";

export const PAGE_BACKGROUND_COLOR = mobileStyleBreakpoint("white", "unset");
export const PAGE_ALIGN = mobileStyleBreakpoint("flex-start", "center");

const Layout = ({ children }: { children: ReactNode }) => {
  return (
    <Stack minH="100vh" spacing={0} bg={PAGE_BACKGROUND_COLOR}>
      <SiteHeader />
      <Center flex={1} alignItems={PAGE_ALIGN}>
        {children}
      </Center>
    </Stack>
  );
};

export default Layout;
