import { ComponentStyleConfig } from "@chakra-ui/react";

const Popover: ComponentStyleConfig = {
  baseStyle: {
    content: {
      borderRadius: "xl",
      border: "none",
      boxShadow: "0px 10px 20px rgba(45, 55, 72, 0.15)",
      py: 5,
    },
    body: {
      p: 0,
    },
  },
};

export default Popover;
