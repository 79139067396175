import {
  FormLabel,
  InputProps,
  Text,
  TextProps,
  FormLabelProps,
} from "@chakra-ui/react";
import { PropsWithChildren, ReactElement } from "react";

export type InputLabelProps = {
  label?: string | JSX.Element;
  formLabelProps?: FormLabelProps;
} & Pick<InputProps, "isDisabled" | "isRequired">;

const LabelText = ({ children, ...rest }: PropsWithChildren<TextProps>) => (
  <Text as="span" {...rest}>
    {children}
  </Text>
);

export const LabelTextWithHelper = ({
  label,
  helper,
  primaryLabelStyle,
}: {
  label: string;
  helper: string;
  primaryLabelStyle?: string;
}): ReactElement => (
  <>
    <LabelText mr={1} textStyle={primaryLabelStyle}>
      {label}
    </LabelText>
    <LabelText textStyle="caption" fontWeight="normal">
      ({helper})
    </LabelText>
  </>
);

const InputLabel = ({
  label,
  isRequired,
  formLabelProps,
}: InputLabelProps): ReactElement | null => {
  if (!label) {
    return null;
  }

  return (
    <FormLabel display="inline-block" {...formLabelProps}>
      <LabelText fontWeight="bold">{label}</LabelText>
      {isRequired && (
        <LabelText ml={1} color="errorRed">
          *
        </LabelText>
      )}
    </FormLabel>
  );
};

export default InputLabel;
