import { ReactElement, useCallback, useState } from "react";

import { RedirectToPhoneNumberPath } from "routes/user-registration/RedirectToPhoneNumberPath";
import { useRegistrationForm } from "contexts/RegistrationFormContext";
import UploadProfilePictureCard from "./UploadProfilePictureCard";
import ConfirmProfilePictureCard from "./ConfirmProfilePictureCard";
import NoProfilePictureCard from "./NoProfilePictureCard";

const ProfilePictureCards = (): ReactElement => {
  const { basicInfoComplete, phoneNumber } = useRegistrationForm();
  const [showUpload, setShowUpload] = useState(false);
  const { profileImage, onClearProfileImage } = useRegistrationForm();

  const onShowUpload = useCallback(() => {
    setShowUpload(true);
    onClearProfileImage();
  }, [onClearProfileImage]);
  const onCancelUpload = useCallback(() => {
    setShowUpload(false);
    onClearProfileImage();
  }, [onClearProfileImage]);

  if (!(basicInfoComplete && phoneNumber)) {
    return <RedirectToPhoneNumberPath />;
  }

  if (showUpload) {
    return (
      <UploadProfilePictureCard
        onCancelUpload={onCancelUpload}
        onConfirmUpload={() => setShowUpload(false)}
      />
    );
  }

  return profileImage ? (
    <ConfirmProfilePictureCard onShowUpload={onShowUpload} />
  ) : (
    <NoProfilePictureCard onShowUpload={onShowUpload} />
  );
};

export default ProfilePictureCards;
