import { skipToken, useInfiniteQuery } from "@tanstack/react-query";

import { CropYearFarmDto } from "api";
import { useCropYearService } from "contexts/ServiceProvider";
import { getNextPage, PaginationMeta, QueryParams } from "services/apiHelpers";
import { CropYearFarmsSortColumns } from "services/CropYearService";

export type GetCropYearFarmsResponse = {
  cropYearFarms: CropYearFarmDto[];
  meta: PaginationMeta;
};

export const USE_CROP_YEAR_FARMS_QUERY_KEY = "list-crop-year-farms";

const useCropYearFarms = (
  cropYearId?: string,
  queryParams?: Omit<QueryParams<CropYearFarmsSortColumns>, "page">
) => {
  const cropYearService = useCropYearService();

  return useInfiniteQuery({
    queryKey: [USE_CROP_YEAR_FARMS_QUERY_KEY, cropYearId, queryParams],
    queryFn: cropYearId
      ? ({ pageParam = 0 }) =>
          cropYearService
            .getCropYearFarms(cropYearId, {
              ...queryParams,
              page: pageParam,
            })
            .then(({ data, meta }) => ({
              cropYearFarms: data,
              meta,
            }))
      : skipToken,
    initialPageParam: 0,
    getNextPageParam: (lastPage) => getNextPage(lastPage),
  });
};

export default useCropYearFarms;
