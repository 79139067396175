import { ReactElement, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Center,
  HStack,
  Icon,
  MenuItem,
  Spinner,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { IoCaretDown } from "react-icons/io5";

import { AddIcon } from "icons";
import Menu from "components/Menu";
import FarmMenuOption from "components/navigation/FarmMenuOption";
import TextHeading from "components/TextHeading";
import NavigationBadgeContainer from "components/navigation/NavigationBadgeContainer";
import { FARM_ID_QUERY_STRING_KEY } from "hooks/useSelectedFarmId";
import useSelectedFarmDetail from "hooks/useSelectedFarmDetail";
import { formatPhoneNumber } from "utils/formatFunctions";
import { isFarmPending } from "utils/farmStatusUtils";
import farmPaths from "routes/farm/farmPaths";
import dashboardPaths from "routes/dashboard/dashboardPaths";
import { useUserInfo } from "contexts/UserInfoProvider";
import { FarmStatusDtoValueEnum } from "api";
import { mobileStyleBreakpoint } from "utils/styleHelpers";

const FARM_MENU_LIST_MAX_HEIGHT = mobileStyleBreakpoint("64vh", "76vh");

const PENDING_APPROVAL = "Pending Approval";
const NEW_FARM = "Add New Farm Business";

const FarmMenu = (): ReactElement => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const navigate = useNavigate();
  const { farms } = useUserInfo();
  const { data: selectedFarm, isLoading } = useSelectedFarmDetail();
  const location = useLocation();

  const title = useMemo(() => {
    if (
      location.pathname === `/${farmPaths.basePath}/${farmPaths.children.new}`
    ) {
      return NEW_FARM;
    }

    if (
      location.pathname ===
        `/${dashboardPaths.basePath}/${dashboardPaths.children.noFarms}` ||
      selectedFarm?.status.value === FarmStatusDtoValueEnum.NeedsReview
    ) {
      return selectedFarm?.doingBusinessAs ?? "No Farms";
    }

    return selectedFarm?.doingBusinessAs;
  }, [location, selectedFarm]);

  const isDisabled = farms.length === 0 && title === NEW_FARM;

  return (
    <Menu
      menuButtonProps={{
        onClick: () => setTooltipOpen(false),
        onMouseEnter: () => setTooltipOpen(true),
        onMouseLeave: () => setTooltipOpen(false),
      }}
      button={
        <NavigationBadgeContainer>
          {farms.length > 0 && isLoading ? (
            <Center w="100%">
              <Spinner size="sm" />
            </Center>
          ) : (
            <HStack w="100%" justify="space-between">
              <Tooltip label={title} isOpen={tooltipOpen} hasArrow>
                <Stack color="white" spacing={0}>
                  <TextHeading
                    headingType="h6"
                    maxWidth={"17ch"}
                    color="inherit"
                    isTruncated
                    title={title}
                  >
                    {title}
                  </TextHeading>
                  {selectedFarm && (
                    <Text fontSize="14px" color="inherit" lineHeight="21px">
                      {isFarmPending(selectedFarm?.status.value)
                        ? PENDING_APPROVAL
                        : formatPhoneNumber(
                            selectedFarm?.farmPrimaryPhoneNumber
                          )}
                    </Text>
                  )}
                </Stack>
              </Tooltip>
              {!isDisabled && <Icon as={IoCaretDown} color="white" />}
            </HStack>
          )}
        </NavigationBadgeContainer>
      }
      menuListStyles={{
        w: "288px",
        maxH: FARM_MENU_LIST_MAX_HEIGHT,
        overflowY: "auto",
      }}
      menuStyles={{
        placement: "bottom",
        offset: [0, 0],
        variant: isDisabled ? "farmMenuDisabled" : "farmMenu",
      }}
    >
      {farms.map((farm) => (
        <MenuItem
          key={farm.farmId}
          onClick={() => {
            navigate(
              isFarmPending(farm.status.value)
                ? `/${farmPaths.basePath}/${farmPaths.children.approve}/${farm.farmId}?${FARM_ID_QUERY_STRING_KEY}=${farm.farmId}`
                : `/${dashboardPaths.basePath}?${FARM_ID_QUERY_STRING_KEY}=${farm.farmId}`
            );
          }}
        >
          <FarmMenuOption
            name={farm.farmName}
            secondaryInfo={
              isFarmPending(farm.status.value)
                ? PENDING_APPROVAL
                : farm.phoneNumber
            }
          />
        </MenuItem>
      ))}
      {title !== NEW_FARM && (
        <MenuItem
          onClick={() => {
            navigate(`/${farmPaths.basePath}/${farmPaths.children.new}`);
          }}
        >
          <HStack color="brand.800" spacing="10px">
            <Icon as={AddIcon} />
            <Text color="inherit" fontWeight="bold">
              {NEW_FARM}
            </Text>
          </HStack>
        </MenuItem>
      )}
    </Menu>
  );
};

export default FarmMenu;
