import { ReactElement } from "react";
import { Navigate, useSearchParams, RouteObject } from "react-router-dom";

import userPaths from "./userPaths";
import ProtectedRoute from "components/route-wrappers/ProtectedRoute";
import FormLayout from "layouts/FormLayout";
import UserDetailsPage from "pages/admin/users/UserDetailsPage";
import usersPaths from "routes/user/list/usersPaths";

const RedirectToUsersList = (): ReactElement => {
  const [searchParams] = useSearchParams();

  return <Navigate to={`/${usersPaths.basePath}?${searchParams}`} />;
};

const userRoutes: RouteObject = {
  path: userPaths.basePath,
  element: (
    <ProtectedRoute>
      <FormLayout />
    </ProtectedRoute>
  ),
  children: [
    {
      path: ":id",
      element: <UserDetailsPage />,
    },
    {
      index: true,
      element: <RedirectToUsersList />,
    },
    {
      path: "*",
      element: <RedirectToUsersList />,
    },
  ],
};

export default userRoutes;
