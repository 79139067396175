import { PropsWithChildren, ReactElement } from "react";

import {
  Button,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Stack,
  Text,
} from "@chakra-ui/react";
import { SubmitHandler, useFormContext } from "react-hook-form";

import { AddIcon } from "icons";
import { CARD_MAX_WIDTH, mobileStyleBreakpoint } from "utils/styleHelpers";
import TextHeading from "components/TextHeading";
import { DesktopOnly } from "components/BreakpointVisibility";
import SubmitButton from "components/SubmitButton";
import { SplitFormProps } from "./SplitsDialog";

const SUMMARY_STATS_SPACING = mobileStyleBreakpoint(2, 6);
const FOOTER_SPACING = mobileStyleBreakpoint(4, 10);
const EST_REMAINING_TEXT_STYLE = mobileStyleBreakpoint("h5", "h3");

const SplitModal = ({
  onSave = () => {
    return;
  },
  children,
  isOpen,
  onClose,
  isAddSplitDisabled,
  handleAddSplit,
  remainingSplitPercent,
  estimatedRemainingAcres,
  isSubmitDisabled = false,
}: PropsWithChildren<
  {
    onSave: SubmitHandler<SplitFormProps>;
    isAddSplitDisabled: boolean;
    handleAddSplit: () => void;
    remainingSplitPercent: number;
    estimatedRemainingAcres: number;
    isSubmitDisabled: boolean;
  } & ModalProps
>): ReactElement => {
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useFormContext<SplitFormProps>();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW={CARD_MAX_WIDTH}>
        <form
          onSubmit={(e) => {
            // Presently this is a nested form
            // TODO: Restructure field info page and split summary to decouple the modal from the field info page's true
            e.preventDefault();
            e.stopPropagation();

            return handleSubmit(onSave)();
          }}
        >
          <ModalHeader pb={6}>
            <TextHeading headingType="h3">Split</TextHeading>
            <Text textStyle="body" fontWeight="normal" mt={6}>
              To create a split, select the farms involved in the split and add
              the split percentage.
            </Text>
          </ModalHeader>
          <ModalBody>{children}</ModalBody>
          <ModalFooter px={0} pt={FOOTER_SPACING} pb={0}>
            <Stack w="100%" spacing={FOOTER_SPACING}>
              {!isAddSplitDisabled && (
                <Button
                  leftIcon={<AddIcon h={"16px"} w={"16px"} />}
                  alignSelf={"start"}
                  size="md"
                  variant={"link"}
                  onClick={handleAddSplit}
                >
                  Add Farm
                </Button>
              )}
              <DesktopOnly>
                <Divider color={"grayFactor.2"} />
              </DesktopOnly>
              <Stack spacing={SUMMARY_STATS_SPACING}>
                <Flex justify={"space-between"}>
                  <Text fontWeight="bold">Remaining Split Percentage</Text>
                  <Text data-testid="remaining-split-percent-value">
                    {`${
                      remainingSplitPercent < 0
                        ? `(${Math.abs(remainingSplitPercent).toFixed(2)})`
                        : remainingSplitPercent.toFixed(2)
                    }%`}
                  </Text>
                </Flex>
                <Flex justify={"space-between"} align={"center"}>
                  <Text fontWeight="bold">Estimated Remaining Acres</Text>
                  <Text
                    textStyle={EST_REMAINING_TEXT_STYLE}
                    data-testid="estimated-remaining-acres-value"
                  >
                    {estimatedRemainingAcres < 0
                      ? `(${Math.abs(estimatedRemainingAcres).toFixed(2)})`
                      : estimatedRemainingAcres.toFixed(2)}
                  </Text>
                </Flex>
              </Stack>
              <Stack>
                <SubmitButton
                  size="lg"
                  submitDisabled={isSubmitDisabled}
                  isSubmitting={isSubmitting}
                  buttonText="Save"
                />
                <Button onClick={onClose} w="100%" variant="outline">
                  Cancel
                </Button>
              </Stack>
            </Stack>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default SplitModal;
