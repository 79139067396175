import { ReactElement, useMemo } from "react";

import { Path } from "react-hook-form";
import { FieldValues } from "react-hook-form/dist/types/fields";

import { useGetRolesForUser } from "hooks/user/useGetRoles";
import useSelectedFarmId from "hooks/useSelectedFarmId";
import RoleSelectInput from "./RoleSelectInput";

const UserRoleSelect = <T extends FieldValues>({
  id,
  userId,
  isDisabled,
}: {
  id: Path<T>;
  userId: string;
  isDisabled?: boolean;
}): ReactElement => {
  const [farmId] = useSelectedFarmId();
  const { data, isLoading, isError, error } = useGetRolesForUser(
    userId,
    farmId
  );
  const roleOptions = useMemo(
    () =>
      data?.roles.map((role) => ({
        label: role.displayName,
        value: role.value,
      })) ?? [],
    [data]
  );

  return (
    <RoleSelectInput<T>
      id={id}
      isDisabled={isLoading || isDisabled}
      options={roleOptions}
      isLoading={isLoading}
      isError={isError}
      errorMsg={error?.message}
    />
  );
};

export default UserRoleSelect;
