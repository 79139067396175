import { useQuery } from "@tanstack/react-query";
import { DelayedContractCurrentPricingResponse } from "api";
import { usePricingOptionService } from "contexts/ServiceProvider";
import { BaseApiError } from "services/apiHelpers";

const GET_CURRENT_DELAYED_PRICING_QUERY_KEY = "get-current-delayed-pricing";

const TEN_MINUTE_STALE_TIME_IN_MILLISECONDS = 600000;

const useGetDelayedPricingLockedInData = (
  cropYearId: string | undefined,
  farmId: string | undefined
) => {
  const pricingOptionService = usePricingOptionService();

  return useQuery<DelayedContractCurrentPricingResponse, BaseApiError>({
    queryKey: [GET_CURRENT_DELAYED_PRICING_QUERY_KEY, { cropYearId, farmId }],
    queryFn: () =>
      cropYearId && farmId
        ? pricingOptionService.getLockedInDelayedPricingContract({
            cropYearId,
            farmId,
          })
        : Promise.reject(
            new Error("Missing pricing cropYear ID and/or farm ID")
          ),
    enabled: !!cropYearId && !!farmId,
    gcTime: 0,
    staleTime: TEN_MINUTE_STALE_TIME_IN_MILLISECONDS,
    refetchOnWindowFocus: false,
  });
};

export default useGetDelayedPricingLockedInData;
