import { useFormContext } from "react-hook-form";
import { Stack, Textarea } from "@chakra-ui/react";
import { LabelTextWithHelper } from "components/inputs/InputLabel";
import { InputWrapper } from "components/inputs";
import { VoidDelayedPricingFormData } from "../VoidContractAlertModal/VoidContractAlertModal";

const VoidContractCommentsBox = ({ comments }: { comments?: string }) => {
  const methods = useFormContext<VoidDelayedPricingFormData>();
  const {
    register,
    formState: { errors },
  } = methods;

  return (
    <Stack direction="column" py={6}>
      <InputWrapper
        label={
          <LabelTextWithHelper
            label="Comments"
            helper="comments are visible to all users"
            primaryLabelStyle="h6"
          />
        }
        isInvalid={!!errors?.voidComments?.message}
        errorMsg={errors?.voidComments?.message}
        isRequired={true}
      >
        <Textarea {...register("voidComments")} value={comments} w="384px" />
      </InputWrapper>
    </Stack>
  );
};

export default VoidContractCommentsBox;
