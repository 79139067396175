import { HStack, Spacer, Text } from "@chakra-ui/react";
import { formatNumberWithDecimals } from "utils/formatFunctions";

const TotalPrice = ({ label, value }: { label: string; value: number }) => {
  return (
    <HStack marginTop={4}>
      <Text fontSize="md" fontWeight="bold">
        {label}
      </Text>
      <Spacer />
      <Text fontWeight="bold" fontSize="2xl" fontFamily="heading">
        <Text as="sup">$</Text>
        {formatNumberWithDecimals(value)}
      </Text>
    </HStack>
  );
};

export default TotalPrice;
