import {
  Badge,
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Stack,
  Text,
  useBoolean,
  VStack,
} from "@chakra-ui/react";

import { PaymentDate } from "../components";
import DisplayLabelValue from "components/PricingStats/DisplayLabelValue";
import useCropYearDetail from "hooks/crop-year/useCropYearDetails";
import useApproveForwardContract from "hooks/pricing-option/useApproveForwardContract";
import useGetPricingOptionDetails from "hooks/pricing-option/useGetPricingOptionDetails";
import useCustomToast from "hooks/useCustomToast";
import useNavigateWithQuery from "hooks/useNavigateWithQuery";
import useSelectedCropYearId from "hooks/useSelectedCropYearId";
import useSelectedFarmId from "hooks/useSelectedFarmId";
import ForwardPricingContractStats from "pages/pricing/ForwardPricing/ForwardPricingContractStats";
import PricingContractPageLayout from "pages/pricing/PricingContractPageLayout/PricingContractPageLayout";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import dashboardPaths from "routes/dashboard/dashboardPaths";
import { formatDate, parseDate } from "utils/dateUtils";
import { formatNumberWithDecimalsOrDisplayHyphen } from "utils/numberUtils";
import { CALCULATED_AT_PRE_HARVEST_MESSAGE } from "../NewForwardPricingContractForm";

const NEEDS_REVIEW_BADGE = <Badge variant="needs_review">Needs review</Badge>;

const ReviewForwardPricingContractApprove = () => {
  const { id: pricingOptionId } = useParams();
  const [cropYearId] = useSelectedCropYearId();
  const [farmId] = useSelectedFarmId();
  const {
    data: pricingOptionDetailsData,
    isPending: isPricingOptionDetailsLoading,
  } = useGetPricingOptionDetails(pricingOptionId, farmId);
  const { data: cropYearDetail, isPending: isLoadingCropYearDetails } =
    useCropYearDetail(cropYearId);

  const navigate = useNavigateWithQuery();
  const navigateToFarmView = useCallback(
    () =>
      navigate(
        `/${dashboardPaths.basePath}/${dashboardPaths.children.pricing}`
      ),
    [navigate]
  );
  const [agreedToTerms, { toggle: toggleAgreeToTerms }] = useBoolean(false);
  const { mutateAsync: approveForwardContract, isPending: isApproveLoading } =
    useApproveForwardContract();
  const { onSuccessToast, onErrorToast } = useCustomToast();

  const onApprove = () => {
    if (cropYearId && farmId && pricingOptionDetailsData) {
      approveForwardContract({
        forwardContractId: pricingOptionDetailsData.id,
        farmId,
        cropYearId,
      })
        .then(() => {
          onSuccessToast({ message: "The forward contract was approved." });
          navigateToFarmView();
        })
        .catch(() => {
          onErrorToast({ message: "The forward contract failed to update." });
        });
    }
  };

  return (
    <PricingContractPageLayout
      contractHeader="Forward Contract"
      badge={NEEDS_REVIEW_BADGE}
      isLoading={
        isPricingOptionDetailsLoading ||
        isApproveLoading ||
        isLoadingCropYearDetails
      }
    >
      <VStack align="left" w="100%" spacing={4}>
        <Box pt={2}>
          <DisplayLabelValue
            label="Percentage to Sell"
            value={formatNumberWithDecimalsOrDisplayHyphen(
              pricingOptionDetailsData?.percentageToSell,
              0
            )}
            statSuffix="%"
          />
        </Box>
        <Box py={2}>
          <DisplayLabelValue
            label="Defer Payment"
            value={pricingOptionDetailsData?.deferPayment ? "Yes" : "No"}
          />
        </Box>
        {pricingOptionDetailsData?.deferPayment && (
          <Box py={2}>
            <DisplayLabelValue
              label="Deferment Date"
              value={
                pricingOptionDetailsData.defermentDate &&
                formatDate(parseDate(pricingOptionDetailsData.defermentDate))
              }
            />
          </Box>
        )}

        {!pricingOptionDetailsData?.deferPayment &&
          !!cropYearDetail?.firstPaymentDateWithoutDeferral && (
            <PaymentDate
              firstPaymentDateWithoutDeferral={parseDate(
                cropYearDetail.firstPaymentDateWithoutDeferral
              )}
            />
          )}

        <ForwardPricingContractStats
          cashPrice={pricingOptionDetailsData?.cashPrice ?? 0}
          futuresFriendlySymbol={
            pricingOptionDetailsData?.futuresFriendlySymbol ?? ""
          }
          futuresPrice={pricingOptionDetailsData?.futuresPrice ?? 0}
          basis={pricingOptionDetailsData?.basis ?? 0}
          isManuallyCreated={
            pricingOptionDetailsData?.isManuallyCreated ?? true
          }
          multiplier={pricingOptionDetailsData?.multiplier ?? 0}
          basePrice={pricingOptionDetailsData?.basePrice ?? 0}
        />
        <Text>{CALCULATED_AT_PRE_HARVEST_MESSAGE}</Text>
        <Divider color="grayFactor.2" opacity={1} borderWidth="1px" />
        <Stack spacing={6} my={8}>
          <Flex alignContent="center">
            <Checkbox onChange={toggleAgreeToTerms} isChecked={agreedToTerms}>
              I agree to the terms of the above contract.
            </Checkbox>
          </Flex>
          <Text color="brand.900" fontSize="xs" mt={0}>
            If there are errors in details for this Forward Contract, please
            call Riverview before approving.
          </Text>
          <Flex direction="column" fontSize="xs">
            <Text fontWeight="bold">Riverview phone</Text>
            <Text>320-392-6713</Text>
          </Flex>
        </Stack>
        <VStack w="100%" py={4}>
          <Button w="100%" isDisabled={!agreedToTerms} onClick={onApprove}>
            Submit
          </Button>
          <Button w="100%" variant="outline" onClick={navigateToFarmView}>
            Cancel
          </Button>
        </VStack>
      </VStack>
    </PricingContractPageLayout>
  );
};

export default ReviewForwardPricingContractApprove;
