/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosRequestConfig,
} from "axios";
import { Configuration } from "../configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "../base";
// @ts-ignore
import { CreateFieldRequest } from "../models";
// @ts-ignore
import { DataEstimatedTonsPerAcreResponse } from "../models";
// @ts-ignore
import { DataFieldImportResponse } from "../models";
// @ts-ignore
import { DataFieldResponse } from "../models";
// @ts-ignore
import { DataListMinimumFarmListDto } from "../models";
// @ts-ignore
import { DataListString } from "../models";
// @ts-ignore
import { FieldMutationErrorResponse } from "../models";
// @ts-ignore
import { PageResponseFieldListDto } from "../models";
/**
 * FieldApi - axios parameter creator
 * @export
 */
export const FieldApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {CreateFieldRequest} createFieldRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createField: async (
      createFieldRequest: CreateFieldRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createFieldRequest' is not null or undefined
      assertParamExists(
        "createField",
        "createFieldRequest",
        createFieldRequest
      );
      const localVarPath = `/field`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createFieldRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteField: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("deleteField", "id", id);
      const localVarPath = `/field/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getActualTonsImportHeaders: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/field/import/actual-tons/headers`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} farmId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEstimatedTonsPerAcreForFarm: async (
      cropYearId: string,
      farmId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cropYearId' is not null or undefined
      assertParamExists(
        "getEstimatedTonsPerAcreForFarm",
        "cropYearId",
        cropYearId
      );
      // verify required parameter 'farmId' is not null or undefined
      assertParamExists("getEstimatedTonsPerAcreForFarm", "farmId", farmId);
      const localVarPath = `/field/estimated-tons-per-acre`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (cropYearId !== undefined) {
        localVarQueryParameter["cropYearId"] = cropYearId;
      }

      if (farmId !== undefined) {
        localVarQueryParameter["farmId"] = farmId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} farmId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFieldAddableFarms: async (
      farmId: string,
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'farmId' is not null or undefined
      assertParamExists("getFieldAddableFarms", "farmId", farmId);
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getFieldAddableFarms", "id", id);
      const localVarPath = `/field/{id}/addable-farms`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (farmId !== undefined && farmId !== null) {
        localVarHeaderParameter["farm-id"] = String(farmId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} farmId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFieldDetail: async (
      farmId: string,
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'farmId' is not null or undefined
      assertParamExists("getFieldDetail", "farmId", farmId);
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getFieldDetail", "id", id);
      const localVarPath = `/field/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (farmId !== undefined && farmId !== null) {
        localVarHeaderParameter["farm-id"] = String(farmId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFieldDetailImportHeaders: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/field/import/field-details/headers`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} [farmId]
     * @param {string} [search]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFields: async (
      cropYearId: string,
      farmId?: string,
      search?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cropYearId' is not null or undefined
      assertParamExists("getFields", "cropYearId", cropYearId);
      const localVarPath = `/fields/{crop-year-id}`.replace(
        `{${"crop-year-id"}}`,
        encodeURIComponent(String(cropYearId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (search !== undefined) {
        localVarQueryParameter["search"] = search;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sort) {
        localVarQueryParameter["sort"] = sort;
      }

      if (farmId !== undefined && farmId !== null) {
        localVarHeaderParameter["farm-id"] = String(farmId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importActualTons: async (
      file: any,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'file' is not null or undefined
      assertParamExists("importActualTons", "file", file);
      const localVarPath = `/field/import/actual-tons`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (file !== undefined) {
        localVarFormParams.append("file", file as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importFieldDetails: async (
      file: any,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'file' is not null or undefined
      assertParamExists("importFieldDetails", "file", file);
      const localVarPath = `/field/import/field-details`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (file !== undefined) {
        localVarFormParams.append("file", file as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {CreateFieldRequest} createFieldRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateField: async (
      id: string,
      createFieldRequest: CreateFieldRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("updateField", "id", id);
      // verify required parameter 'createFieldRequest' is not null or undefined
      assertParamExists(
        "updateField",
        "createFieldRequest",
        createFieldRequest
      );
      const localVarPath = `/field/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createFieldRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FieldApi - functional programming interface
 * @export
 */
export const FieldApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FieldApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {CreateFieldRequest} createFieldRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createField(
      createFieldRequest: CreateFieldRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataFieldResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createField(
        createFieldRequest,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteField(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteField(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getActualTonsImportHeaders(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataListString>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getActualTonsImportHeaders(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} farmId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEstimatedTonsPerAcreForFarm(
      cropYearId: string,
      farmId: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataEstimatedTonsPerAcreResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getEstimatedTonsPerAcreForFarm(
          cropYearId,
          farmId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} farmId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFieldAddableFarms(
      farmId: string,
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataListMinimumFarmListDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getFieldAddableFarms(
          farmId,
          id,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} farmId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFieldDetail(
      farmId: string,
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataFieldResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFieldDetail(
        farmId,
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFieldDetailImportHeaders(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataListString>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getFieldDetailImportHeaders(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} [farmId]
     * @param {string} [search]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFields(
      cropYearId: string,
      farmId?: string,
      search?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PageResponseFieldListDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFields(
        cropYearId,
        farmId,
        search,
        page,
        size,
        sort,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async importActualTons(
      file: any,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataFieldImportResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.importActualTons(file, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async importFieldDetails(
      file: any,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataFieldImportResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.importFieldDetails(file, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {CreateFieldRequest} createFieldRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateField(
      id: string,
      createFieldRequest: CreateFieldRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataFieldResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateField(
        id,
        createFieldRequest,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * FieldApi - factory interface
 * @export
 */
export const FieldApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = FieldApiFp(configuration);
  return {
    /**
     *
     * @param {CreateFieldRequest} createFieldRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createField(
      createFieldRequest: CreateFieldRequest,
      options?: any
    ): AxiosPromise<DataFieldResponse> {
      return localVarFp
        .createField(createFieldRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteField(id: string, options?: any): AxiosPromise<string> {
      return localVarFp
        .deleteField(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getActualTonsImportHeaders(options?: any): AxiosPromise<DataListString> {
      return localVarFp
        .getActualTonsImportHeaders(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} farmId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEstimatedTonsPerAcreForFarm(
      cropYearId: string,
      farmId: string,
      options?: any
    ): AxiosPromise<DataEstimatedTonsPerAcreResponse> {
      return localVarFp
        .getEstimatedTonsPerAcreForFarm(cropYearId, farmId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} farmId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFieldAddableFarms(
      farmId: string,
      id: string,
      options?: any
    ): AxiosPromise<DataListMinimumFarmListDto> {
      return localVarFp
        .getFieldAddableFarms(farmId, id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} farmId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFieldDetail(
      farmId: string,
      id: string,
      options?: any
    ): AxiosPromise<DataFieldResponse> {
      return localVarFp
        .getFieldDetail(farmId, id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFieldDetailImportHeaders(options?: any): AxiosPromise<DataListString> {
      return localVarFp
        .getFieldDetailImportHeaders(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} [farmId]
     * @param {string} [search]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFields(
      cropYearId: string,
      farmId?: string,
      search?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any
    ): AxiosPromise<PageResponseFieldListDto> {
      return localVarFp
        .getFields(cropYearId, farmId, search, page, size, sort, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importActualTons(
      file: any,
      options?: any
    ): AxiosPromise<DataFieldImportResponse> {
      return localVarFp
        .importActualTons(file, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importFieldDetails(
      file: any,
      options?: any
    ): AxiosPromise<DataFieldImportResponse> {
      return localVarFp
        .importFieldDetails(file, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {CreateFieldRequest} createFieldRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateField(
      id: string,
      createFieldRequest: CreateFieldRequest,
      options?: any
    ): AxiosPromise<DataFieldResponse> {
      return localVarFp
        .updateField(id, createFieldRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createField operation in FieldApi.
 * @export
 * @interface FieldApiCreateFieldRequest
 */
export interface FieldApiCreateFieldRequest {
  /**
   *
   * @type {CreateFieldRequest}
   * @memberof FieldApiCreateField
   */
  readonly createFieldRequest: CreateFieldRequest;
}

/**
 * Request parameters for deleteField operation in FieldApi.
 * @export
 * @interface FieldApiDeleteFieldRequest
 */
export interface FieldApiDeleteFieldRequest {
  /**
   *
   * @type {string}
   * @memberof FieldApiDeleteField
   */
  readonly id: string;
}

/**
 * Request parameters for getEstimatedTonsPerAcreForFarm operation in FieldApi.
 * @export
 * @interface FieldApiGetEstimatedTonsPerAcreForFarmRequest
 */
export interface FieldApiGetEstimatedTonsPerAcreForFarmRequest {
  /**
   *
   * @type {string}
   * @memberof FieldApiGetEstimatedTonsPerAcreForFarm
   */
  readonly cropYearId: string;

  /**
   *
   * @type {string}
   * @memberof FieldApiGetEstimatedTonsPerAcreForFarm
   */
  readonly farmId: string;
}

/**
 * Request parameters for getFieldAddableFarms operation in FieldApi.
 * @export
 * @interface FieldApiGetFieldAddableFarmsRequest
 */
export interface FieldApiGetFieldAddableFarmsRequest {
  /**
   *
   * @type {string}
   * @memberof FieldApiGetFieldAddableFarms
   */
  readonly farmId: string;

  /**
   *
   * @type {string}
   * @memberof FieldApiGetFieldAddableFarms
   */
  readonly id: string;
}

/**
 * Request parameters for getFieldDetail operation in FieldApi.
 * @export
 * @interface FieldApiGetFieldDetailRequest
 */
export interface FieldApiGetFieldDetailRequest {
  /**
   *
   * @type {string}
   * @memberof FieldApiGetFieldDetail
   */
  readonly farmId: string;

  /**
   *
   * @type {string}
   * @memberof FieldApiGetFieldDetail
   */
  readonly id: string;
}

/**
 * Request parameters for getFields operation in FieldApi.
 * @export
 * @interface FieldApiGetFieldsRequest
 */
export interface FieldApiGetFieldsRequest {
  /**
   *
   * @type {string}
   * @memberof FieldApiGetFields
   */
  readonly cropYearId: string;

  /**
   *
   * @type {string}
   * @memberof FieldApiGetFields
   */
  readonly farmId?: string;

  /**
   *
   * @type {string}
   * @memberof FieldApiGetFields
   */
  readonly search?: string;

  /**
   * Zero-based page index (0..N)
   * @type {number}
   * @memberof FieldApiGetFields
   */
  readonly page?: number;

  /**
   * The size of the page to be returned
   * @type {number}
   * @memberof FieldApiGetFields
   */
  readonly size?: number;

  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @type {Array<string>}
   * @memberof FieldApiGetFields
   */
  readonly sort?: Array<string>;
}

/**
 * Request parameters for importActualTons operation in FieldApi.
 * @export
 * @interface FieldApiImportActualTonsRequest
 */
export interface FieldApiImportActualTonsRequest {
  /**
   *
   * @type {any}
   * @memberof FieldApiImportActualTons
   */
  readonly file: any;
}

/**
 * Request parameters for importFieldDetails operation in FieldApi.
 * @export
 * @interface FieldApiImportFieldDetailsRequest
 */
export interface FieldApiImportFieldDetailsRequest {
  /**
   *
   * @type {any}
   * @memberof FieldApiImportFieldDetails
   */
  readonly file: any;
}

/**
 * Request parameters for updateField operation in FieldApi.
 * @export
 * @interface FieldApiUpdateFieldRequest
 */
export interface FieldApiUpdateFieldRequest {
  /**
   *
   * @type {string}
   * @memberof FieldApiUpdateField
   */
  readonly id: string;

  /**
   *
   * @type {CreateFieldRequest}
   * @memberof FieldApiUpdateField
   */
  readonly createFieldRequest: CreateFieldRequest;
}

/**
 * FieldApi - object-oriented interface
 * @export
 * @class FieldApi
 * @extends {BaseAPI}
 */
export class FieldApi extends BaseAPI {
  /**
   *
   * @param {FieldApiCreateFieldRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FieldApi
   */
  public createField(
    requestParameters: FieldApiCreateFieldRequest,
    options?: AxiosRequestConfig
  ) {
    return FieldApiFp(this.configuration)
      .createField(requestParameters.createFieldRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {FieldApiDeleteFieldRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FieldApi
   */
  public deleteField(
    requestParameters: FieldApiDeleteFieldRequest,
    options?: AxiosRequestConfig
  ) {
    return FieldApiFp(this.configuration)
      .deleteField(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FieldApi
   */
  public getActualTonsImportHeaders(options?: AxiosRequestConfig) {
    return FieldApiFp(this.configuration)
      .getActualTonsImportHeaders(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {FieldApiGetEstimatedTonsPerAcreForFarmRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FieldApi
   */
  public getEstimatedTonsPerAcreForFarm(
    requestParameters: FieldApiGetEstimatedTonsPerAcreForFarmRequest,
    options?: AxiosRequestConfig
  ) {
    return FieldApiFp(this.configuration)
      .getEstimatedTonsPerAcreForFarm(
        requestParameters.cropYearId,
        requestParameters.farmId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {FieldApiGetFieldAddableFarmsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FieldApi
   */
  public getFieldAddableFarms(
    requestParameters: FieldApiGetFieldAddableFarmsRequest,
    options?: AxiosRequestConfig
  ) {
    return FieldApiFp(this.configuration)
      .getFieldAddableFarms(
        requestParameters.farmId,
        requestParameters.id,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {FieldApiGetFieldDetailRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FieldApi
   */
  public getFieldDetail(
    requestParameters: FieldApiGetFieldDetailRequest,
    options?: AxiosRequestConfig
  ) {
    return FieldApiFp(this.configuration)
      .getFieldDetail(requestParameters.farmId, requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FieldApi
   */
  public getFieldDetailImportHeaders(options?: AxiosRequestConfig) {
    return FieldApiFp(this.configuration)
      .getFieldDetailImportHeaders(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {FieldApiGetFieldsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FieldApi
   */
  public getFields(
    requestParameters: FieldApiGetFieldsRequest,
    options?: AxiosRequestConfig
  ) {
    return FieldApiFp(this.configuration)
      .getFields(
        requestParameters.cropYearId,
        requestParameters.farmId,
        requestParameters.search,
        requestParameters.page,
        requestParameters.size,
        requestParameters.sort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {FieldApiImportActualTonsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FieldApi
   */
  public importActualTons(
    requestParameters: FieldApiImportActualTonsRequest,
    options?: AxiosRequestConfig
  ) {
    return FieldApiFp(this.configuration)
      .importActualTons(requestParameters.file, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {FieldApiImportFieldDetailsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FieldApi
   */
  public importFieldDetails(
    requestParameters: FieldApiImportFieldDetailsRequest,
    options?: AxiosRequestConfig
  ) {
    return FieldApiFp(this.configuration)
      .importFieldDetails(requestParameters.file, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {FieldApiUpdateFieldRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FieldApi
   */
  public updateField(
    requestParameters: FieldApiUpdateFieldRequest,
    options?: AxiosRequestConfig
  ) {
    return FieldApiFp(this.configuration)
      .updateField(
        requestParameters.id,
        requestParameters.createFieldRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
