import { useTheme, useToken } from "@chakra-ui/react";
import { Global } from "@emotion/react";

import "react-datepicker/dist/react-datepicker.css";

const ReactDatePickerStyleOverrides = () => {
  const [greyFactor3, modalOverlay, brandGreen] = useToken("colors", [
    "greyFactor.3",
    "blackAlpha.600",
    "brand.800",
  ]);
  const theme = useTheme();
  const captionTextStyle = theme["textStyles"].caption;
  const pickerShadow = theme["shadows"].large;
  const [captionFontSize] = useToken("fontSizes", [captionTextStyle.fontSize]);
  const [captionLineHeight] = useToken("lineHeights", [
    captionTextStyle.lineHeight,
  ]);

  return (
    <Global
      styles={{
        ".react-datepicker__day-name": {
          color: greyFactor3,
          ...captionTextStyle,
          fontSize: captionFontSize,
          lineHeight: captionLineHeight,
        },
        ".react-datepicker__day--outside-month": {
          color: greyFactor3,
        },
        ".react-datepicker__day--keyboard-selected, .react-datepicker__day--selected, .react-datepicker__day--selected:hover":
          {
            color: "white",
            backgroundColor: brandGreen,
          },
        ".react-datepicker": {
          border: "none",
          padding: "12px",
          boxShadow: pickerShadow,
        },
        ".react-datepicker__header": {
          backgroundColor: "white",
          borderBottom: "none",
        },
        ".react-datepicker__portal": {
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: modalOverlay,
          ".react-datepicker": {
            border: "none",
            borderRadius: "unset",
          },
          ".react-datepicker__month-container": {
            float: "unset",
            width: "100vw",
            maxWidth: "414px",
          },
          ".react-datepicker__day, .react-datepicker__day-name": {
            width: `calc(${(1 / 7) * 100}% - 6px)`, // 6px margin on both sides
          },
        },
      }}
    />
  );
};

export default ReactDatePickerStyleOverrides;
