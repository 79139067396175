import { SelectOption } from "components/Select";
import { optionalSelectOptionSchema } from "./selectOptionSchema";
import * as yup from "yup";
import { getIsDeferPayment } from "utils/getIsDeferPayment";

export const editDelayedContractSchema = yup.object({
  deferPayment: optionalSelectOptionSchema,
  defermentDate: yup.date().when("deferPayment", {
    is: (deferPayment: SelectOption) => getIsDeferPayment(deferPayment.value),
    then: (schema) => schema.required("Defer payment date is required"),
  }),
  reviewed: yup.boolean(),
});
