import { skipToken, useInfiniteQuery } from "@tanstack/react-query";
import { useManureYearService } from "contexts/ServiceProvider";
import { getNextPage } from "services/apiHelpers";

export const MANURE_YEAR_FARM_DOCUMENT_LINK_QUERY_KEY =
  "manure-year-farm-document-link";

const useGetManureYearFarmDocuments = (
  manureYearId: string | undefined,
  farmId: string | undefined
) => {
  const manureService = useManureYearService();

  return useInfiniteQuery({
    queryKey: [
      MANURE_YEAR_FARM_DOCUMENT_LINK_QUERY_KEY,
      { manureYearId, farmId },
    ],
    queryFn:
      farmId && manureYearId
        ? ({ pageParam = 0 }) =>
            manureService.getManureYearFarmDocuments(manureYearId, farmId, {
              page: pageParam,
              size: 10,
            })
        : skipToken,
    initialPageParam: 0,
    getNextPageParam: getNextPage,
  });
};

export default useGetManureYearFarmDocuments;
