import { ReactElement } from "react";

import ManureFarmListTable from "./ManureFarmListTable";
import ProgramListPageLayout from "components/ProgramListPageLayout/ProgramListPageLayout";
import ManureYearSelect from "components/ManureYearSelect";
import useSelectedManureYearId, {
  MANURE_YEAR_ID_QUERY_STRING_KEY,
} from "hooks/useSelectedManureYearId";
import manurePaths from "routes/manure";

const ManureFarmListPage = (): ReactElement => {
  const [selectedManureYearId] = useSelectedManureYearId();

  return (
    <ProgramListPageLayout
      heading="Manure"
      pageTitle="All Farms"
      path={`/${manurePaths.basePath}/${manurePaths.children.addFarms}?${MANURE_YEAR_ID_QUERY_STRING_KEY}=${selectedManureYearId}`}
      yearSelect={<ManureYearSelect />}
    >
      <ManureFarmListTable />
    </ProgramListPageLayout>
  );
};

export default ManureFarmListPage;
