import { ReactElement } from "react";
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";

import { useBreakpointValue } from "@chakra-ui/react";
import { Controller, useFormContext } from "react-hook-form";

import { CalendarIcon } from "icons";
import { mobileOnly } from "utils/styleHelpers";
import {
  InputWrapper,
  InputWrapperProps,
  StandardInput,
  StandardInputProps,
} from "components/inputs";
import ReactDatePickerStyleOverrides from "./ReactDatePickerStyleOverrides";
import CalendarHeader from "./CalendarHeader";

// Helpers to prevent click through portal overlay
// See https://github.com/Hacker0x01/react-datepicker/issues/2524#issuecomment-932964119
const handleTouchStart = (e: Event) => e.stopPropagation();
const handleCalendarOpen = () => {
  document.addEventListener("touchstart", handleTouchStart, true);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const [element] =
    document.getElementsByClassName("react-datepicker__day") || [];
  if (element) element.focus();
};
const handleCalendarClose = () =>
  document.removeEventListener("touchstart", handleTouchStart, true);

const DateInput = ({
  label,
  id,
  isDisabled,
  datePickerProps,
}: InputWrapperProps &
  StandardInputProps & {
    datePickerProps?: Omit<ReactDatePickerProps, "onChange">;
  }): ReactElement => {
  const { control } = useFormContext();
  const showAsPortal = useBreakpointValue(mobileOnly);

  return (
    <>
      <ReactDatePickerStyleOverrides />
      <Controller
        name={id as string}
        control={control}
        render={({
          fieldState: { error },
          field: { name, onChange, ref, value, onBlur },
        }) => (
          <InputWrapper
            isRequired
            isDisabled={isDisabled}
            label={label}
            isInvalid={!!error}
            errorMsg={error?.message as string}
          >
            <ReactDatePicker
              selected={value}
              onChange={onChange}
              onBlur={onBlur}
              dateFormat="MMMM dd, yyyy"
              onCalendarOpen={handleCalendarOpen}
              onCalendarClose={handleCalendarClose}
              disabled={isDisabled}
              customInput={
                <StandardInput
                  id={name}
                  onChange={onChange}
                  value={value}
                  ref={ref}
                  rightAddon={<CalendarIcon />}
                  hideAddonBackground
                  isDisabled={isDisabled}
                />
              }
              showPopperArrow={false}
              withPortal={showAsPortal}
              popperModifiers={[
                {
                  name: "offset",
                  options: {
                    offset: [0, -10], // 10 is trial and error to get it close to the input
                  },
                },
              ]}
              renderCustomHeader={CalendarHeader}
              {...datePickerProps}
            />
          </InputWrapper>
        )}
      />
    </>
  );
};

export default DateInput;
