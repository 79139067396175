import { ReactElement } from "react";
import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  Box,
  PopoverHeader,
  PopoverBody,
  Text,
  useDisclosure,
  Flex,
} from "@chakra-ui/react";
import { CloseIconCircle, HelpIcon } from "icons";

// TODO Update with the correct phone number.
const NavigationHelp = (): ReactElement => {
  const { isOpen, onToggle, onClose } = useDisclosure();

  return (
    <Popover
      returnFocusOnClose={false}
      isOpen={isOpen}
      onClose={onClose}
      placement="top-end"
      closeOnBlur
    >
      <PopoverTrigger>
        <Flex p={6} borderRadius={2} overflow={"hidden"}>
          <Button
            variant={"link"}
            color={"brand.800"}
            leftIcon={isOpen ? <CloseIconCircle /> : <HelpIcon />}
            onClick={onToggle}
          >
            {isOpen ? "Cancel" : "Help"}
          </Button>
        </Flex>
      </PopoverTrigger>
      <PopoverContent padding={0}>
        <PopoverArrow />
        <PopoverHeader py={2} px={6}>
          <Text fontWeight={"bold"}>Need Support?</Text>
        </PopoverHeader>
        <PopoverBody>
          <Box px={6} py={3}>
            <Text>Contact Riverview at</Text>
            <Text>320-392-6713</Text>
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default NavigationHelp;
