import { ScaleFade, Stack, Divider, Button } from "@chakra-ui/react";
import { MonthlyAverageListResponseDto } from "api";
import { formatUSD, formatNumberWithDecimals } from "utils/formatFunctions";
import PrimaryTextItem from "../PrimaryTextItem";
import TotalPrice from "../TotalPrice";

const MonthlyAverageModalContent = ({
  monthData,
  onClose,
}: {
  monthData: MonthlyAverageListResponseDto;
  onClose: () => void;
}) => {
  return (
    <ScaleFade initialScale={0.9} in={true}>
      <Stack spacing={8}>
        <PrimaryTextItem
          label="Tons to be sold"
          value={formatNumberWithDecimals(monthData.tons)}
        />
        <PrimaryTextItem
          label="Cash Price"
          value={formatUSD(monthData.cashPrice)}
        />
        <PrimaryTextItem
          label="Multiplier"
          value={monthData.multiplier}
          valueSuffix="x"
        />
        <PrimaryTextItem
          label="Distance Adjustment"
          value={`(${formatUSD(monthData.distanceAdjustment)}/ton)`}
        />
        <Divider />
        <TotalPrice label="Contract price" value={monthData.contractPrice} />
        <Button w={"100%"} variant={"outline"} onClick={onClose}>
          Back
        </Button>
      </Stack>
    </ScaleFade>
  );
};

export default MonthlyAverageModalContent;
