import { useCallback } from "react";
import { useDisclosure } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  CropYearDetailResponse,
  FarmDetailResponse,
  PricingOptionResponse,
  UpdateDelayedContractRequest,
} from "api";
import { useForm, FormProvider } from "react-hook-form";

import ConfirmationAlert from "components/ConfirmationAlert";
import VoidContractButton from "../VoidContractButton/VoidContractButton";
import { voidDelayedContractSchema } from "forms/schemas/voidDelayedContractSchema";
import useUpdateDelayedPricingContract from "hooks/pricing-option/useUpdateDelayedPricingContract";
import useCustomToast from "hooks/useCustomToast";
import VoidContractCommentsBox from "../VoidContractCommentsBox";

const VOID_DELAYED_PRICING_CONTRACT_MSG = `Voided contracts will be excluded from summary calculations
and pricing data download. Tons on this contract will be released
and available to price. This action can not be undone. Do you wish to continue?`;

export type VoidDelayedPricingFormData = {
  voidComments: string;
};

export type VoidContractAlertModalProps = {
  isEmployeeAdmin: boolean;
  voidedContract: boolean;
  pricingOption?: PricingOptionResponse;
  cropYear?: CropYearDetailResponse;
  farm?: FarmDetailResponse;
  completedReview: boolean;
};

const transformVoidDelayedContractData = (
  data: RemoveUndefined<VoidDelayedPricingFormData>,
  reviewed: boolean
): UpdateDelayedContractRequest => ({
  deferPayment: false,
  defermentDate: "",
  reviewed: reviewed,
  voided: true,
  comments: data.voidComments,
});

const VoidContractAlertModal = ({
  isEmployeeAdmin,
  voidedContract,
  pricingOption,
  cropYear,
  farm,
  completedReview,
}: VoidContractAlertModalProps) => {
  const { onSuccessToast, onErrorToast } = useCustomToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutateAsync } = useUpdateDelayedPricingContract(
    pricingOption?.id,
    cropYear?.id,
    farm?.id
  );

  const methods = useForm<VoidDelayedPricingFormData>({
    resolver: yupResolver(voidDelayedContractSchema),
  });

  const { handleSubmit, watch, reset } = methods;
  const comments = watch("voidComments");

  const onSubmit = useCallback(() => {
    const formData = watch() as RemoveUndefined<VoidDelayedPricingFormData>;
    const data = transformVoidDelayedContractData(formData, completedReview);

    return mutateAsync(data)
      .then(() => {
        onSuccessToast({
          message: "Contract successfully voided.",
        });
        onClose();
      })
      .catch(() => {
        onErrorToast({
          title: "Error voiding contract",
          message: `Please try again`,
        });
      });
  }, [
    completedReview,
    mutateAsync,
    onClose,
    onErrorToast,
    onSuccessToast,
    watch,
  ]);

  const handleClose = () => {
    reset({ voidComments: "" });
    onClose();
  };

  return (
    <FormProvider {...methods}>
      <VoidContractButton
        isEmployeeAdmin={isEmployeeAdmin}
        voidedContract={voidedContract}
        onOpen={onOpen}
      />
      <ConfirmationAlert
        isOpen={isOpen}
        onClose={handleClose}
        msg={VOID_DELAYED_PRICING_CONTRACT_MSG}
        body={<VoidContractCommentsBox comments={comments} />}
        title="Void contract?"
        confirmButtonText="Yes"
        confirmButtonVariant="destructive"
        secondaryButtonText="Cancel"
        onConfirmAsync={handleSubmit(onSubmit)}
      />
    </FormProvider>
  );
};

export default VoidContractAlertModal;
