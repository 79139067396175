import { useCallback } from "react";
import CatastrophicErrorState from "./CatastrophicErrorState";
import useUploadFieldsCsv from "hooks/csv-upload/useUploadFieldCsv";
import dashboardPaths from "routes/dashboard/dashboardPaths";

const FieldsCatastrophicErrorState = () => {
  const { mutateAsync, isPending: isLoading } = useUploadFieldsCsv();

  const onRetryUploadCsvFile = useCallback(
    (file: File) => mutateAsync({ csvFile: file }),
    [mutateAsync]
  );

  return (
    <CatastrophicErrorState
      isLoading={isLoading}
      onRetry={onRetryUploadCsvFile}
      linkText="Back to Fields List"
      linkTo={`/${dashboardPaths.basePath}/${dashboardPaths.children.allFields}`}
    />
  );
};

export default FieldsCatastrophicErrorState;
