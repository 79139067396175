import { ReactElement, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Flex, HStack, Hide, Text } from "@chakra-ui/react";
import { NavigationDrawer } from "components/navigation";
import { RiverviewLogo } from "components/Logo";
import useAccessToken from "hooks/auth/useAccessToken";
import userRegistrationPaths from "routes/user-registration/userRegistrationPaths";
import UserMenu from "./UserMenu";

export const SITE_HEADER_HEIGHT = "74px";

const SiteHeader = (): ReactElement => {
  const { accessToken } = useAccessToken();

  const showUatBanner = !!process.env.REACT_APP_SHOW_UAT_BANNER;

  const location = useLocation();
  const isOnUserRegistrationPath = useMemo(
    () => location.pathname.includes(userRegistrationPaths.basePath),
    [location.pathname]
  );

  return (
    <Flex
      h={SITE_HEADER_HEIGHT}
      px={8}
      py={6}
      bg={showUatBanner ? "hotpink" : "white"}
      position="sticky"
      top={0}
      shadow="xsmall"
      zIndex="sticky"
    >
      <HStack w="100%" justify="space-between">
        <HStack spacing={8}>
          <Hide above="md" ssr={false}>
            {isOnUserRegistrationPath ? null : <NavigationDrawer />}
          </Hide>
          {showUatBanner ? (
            <HStack spacing={2}>
              <RiverviewLogo h="26px" />
              <Text fontWeight="bold">UAT Testing</Text>
            </HStack>
          ) : (
            <RiverviewLogo h="26px" />
          )}
        </HStack>
        {accessToken && <UserMenu />}
      </HStack>
    </Flex>
  );
};

export default SiteHeader;
