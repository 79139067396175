import { ReactElement } from "react";

import { useDisclosure } from "@chakra-ui/react";

import { PricingOptionDto } from "api";
import DeleteButton from "components/DeleteButton";
import DeleteConfirmationModal from "components/DeleteConfirmationModal";
import useCustomToast from "hooks/useCustomToast";
import useDeleteForwardContracts from "hooks/pricing-option/useDeleteForwardContract";
import useDeleteAveragePricingContract from "hooks/pricing-option/useDeleteAveragePricingContract";
import {
  isForwardContract,
  isAveragePricingContract,
} from "utils/contractType";

const DeletePricingOptionModal = ({
  cropYearId,
  pricingOptionsData,
  isDisabled,
  onSuccess,
  farmId,
}: {
  cropYearId?: string;
  pricingOptionsData: PricingOptionDto[];
  isDisabled: boolean;
  onSuccess(): void;
  farmId?: string;
}): ReactElement => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { onSuccessToast, onErrorToast } = useCustomToast();

  const { mutateAsync: onDeleteForwardContractsAsync } =
    useDeleteForwardContracts();
  const { mutateAsync: onDeleteAveragePricinContractAsync } =
    useDeleteAveragePricingContract({ cropYearId, farmId });

  const onDelete = () => {
    const allAreForwardContracts = pricingOptionsData.every(
      (pricingOptionsData) => isForwardContract(pricingOptionsData.type)
    );
    const allAreAveragPricingContracts = pricingOptionsData.every(
      (pricingOptionsData) => isAveragePricingContract(pricingOptionsData.type)
    );

    if (allAreForwardContracts) {
      onDeleteForwardContractsAsync({
        cropYearId: cropYearId,
        pricingOptionIds: pricingOptionsData.map(
          (pricingOption) => pricingOption.id
        ),
      })
        .then(() => {
          onSuccess();
          onSuccessToast({
            message: "Successfully deleted",
          });
          onClose();
        })
        .catch(() => {
          onErrorToast({ message: "Failed to delete" });
          onClose();
        });
    } else if (
      pricingOptionsData.length === 1 &&
      allAreAveragPricingContracts
    ) {
      onDeleteAveragePricinContractAsync({
        averagePricingContractId: pricingOptionsData[0].id,
      })
        .then(() => {
          onSuccess();
          onSuccessToast({
            message: "Contract deleted successfully",
          });
          onClose();
        })
        .catch(() => {
          onErrorToast({ message: "Failed to delete average prcing contract" });
          onClose();
        });
    } else {
      console.error(
        "You cannot delete forward contracts and average pricing contracts at the same time"
      );
    }
  };

  return (
    <>
      <DeleteButton onClick={onOpen} isDisabled={isDisabled} />
      <DeleteConfirmationModal
        isOpen={isOpen}
        onClose={onClose}
        headingText="Delete Pricing Option"
        bodyText="This will permanently remove this contract. Do you want to continue?"
        onDelete={onDelete}
      />
    </>
  );
};

export default DeletePricingOptionModal;
