import { useFieldArray, useFormContext } from "react-hook-form";
import { Button, ButtonProps, Flex, HStack, Text } from "@chakra-ui/react";

import { AddIcon, RemoveIcon } from "icons";
import { InputWrapper, StandardInput } from "components/inputs";
import { LabelTextWithHelper } from "components/inputs/InputLabel";
import { DesktopOnly, MobileOnly } from "components/BreakpointVisibility";
import { FarmDetailFields } from "pages/farm-detail/FarmFormContext";

const buttonHover = {
  borderRadius: "47px",
  bg: "greyFactor.1.5",
};

const RemoveButton = (props: ButtonProps) => (
  <Button
    variant="ghost"
    colorScheme="error"
    _hover={!props.isDisabled ? buttonHover : undefined}
    _active={buttonHover}
    rightIcon={<RemoveIcon />}
    {...props}
  >
    Remove
  </Button>
);

const AddRowButton = (props: ButtonProps) => (
  <Button variant="link" colorScheme="brand" {...props}>
    <HStack spacing={1}>
      <AddIcon />
      <Text color="inherit" textStyle="label" fontWeight="bold">
        Add Additional Lien Holder
      </Text>
    </HStack>
  </Button>
);

export const LIEN_HOLDERS_FIELD_NAME = "lienHolders";
const LienHolderFields = ({ isDisabled = false }: { isDisabled?: boolean }) => {
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext<FarmDetailFields>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: LIEN_HOLDERS_FIELD_NAME,
  });

  return (
    <>
      {fields.map((field, index) => {
        const showRemove = fields.length > 1;
        const errorMsg =
          errors[LIEN_HOLDERS_FIELD_NAME]?.[index]?.value?.message;

        return (
          <InputWrapper
            key={field.id}
            label={
              <LabelTextWithHelper
                label="Lien Holder Name"
                helper='type "none" if there are none'
              />
            }
            isInvalid={!!errorMsg}
            errorMsg={errorMsg as string | undefined}
            isDisabled={isDisabled}
            isRequired={true}
          >
            <HStack>
              <StandardInput
                {...register(`${LIEN_HOLDERS_FIELD_NAME}.${index}.value`)}
                isRequired={true}
                rightAddon={
                  showRemove ? (
                    <MobileOnly>
                      <RemoveButton
                        variant="link"
                        size={"xs"}
                        fontWeight={"light"}
                        _hover={undefined}
                        rightIcon={undefined}
                        mr={7}
                        isDisabled={isDisabled}
                        onClick={() => remove(index)}
                      />
                    </MobileOnly>
                  ) : undefined
                }
                hideAddonBackground
              />
              {showRemove && (
                <DesktopOnly>
                  <RemoveButton
                    onClick={() => remove(index)}
                    isDisabled={isDisabled}
                  />
                </DesktopOnly>
              )}
            </HStack>
          </InputWrapper>
        );
      })}
      <Flex>
        <AddRowButton
          onClick={() => append({ value: "", id: undefined })}
          isDisabled={isDisabled}
        />
      </Flex>
    </>
  );
};

export default LienHolderFields;
