import { ButtonProps, HStack, Text } from "@chakra-ui/react";
import { useNavigationRoute } from "hooks/useNavigationRoute";
import { DashboardIcon } from "icons";
import { ReactElement } from "react";
import NavigationItem from "./NavigationItem";

export const SilageDashboardNavigationItem = (
  props: ButtonProps
): ReactElement => {
  const { dashboardRoute } = useNavigationRoute();

  return (
    <NavigationItem route={dashboardRoute} {...props}>
      <HStack spacing={2} w="100%">
        <DashboardIcon color="inherit" />
        <Text color="inherit">Silage</Text>
      </HStack>
    </NavigationItem>
  );
};
