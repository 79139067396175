import { Box } from "@chakra-ui/react";
import { ProgressCircle } from "components/SummaryStatCard";

const outerProgressSizePixels = "164px";
const innerProgressSize = 140;
const innerProgressOffset = Math.ceil(140 / 12);

const UploadProgressSpinner = ({
  isLoading = true,
}: {
  isLoading?: boolean;
}) => (
  <Box
    position="relative"
    w={outerProgressSizePixels}
    h={outerProgressSizePixels}
  >
    <ProgressCircle
      size={outerProgressSizePixels}
      value={100}
      isIndeterminate={isLoading}
    />
    <ProgressCircle
      left={`${innerProgressOffset}px`}
      top={`${innerProgressOffset}px`}
      size={`${innerProgressSize}px`}
      value={100}
      isIndeterminate={isLoading}
    />
  </Box>
);

export default UploadProgressSpinner;
