import { ReactElement, useMemo } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Stack,
  Text,
  useBreakpointValue,
  ScaleFade,
} from "@chakra-ui/react";

import TextHeading from "components/TextHeading";
import { CARD_MAX_WIDTH, desktopOnly } from "utils/styleHelpers";

const ModalPrimaryContent = ({
  onClose,
  onDelete,
}: {
  onClose: () => void;
  onDelete: () => void;
}) => (
  <Stack w="100%">
    <Text pb={4}>
      This Farm (including Field and Pricing information) will be removed for
      this year. Do you wish to continue?
    </Text>
    <Button
      variant="destructive"
      onClick={onDelete}
      aria-label="confirmation-delete-btn"
    >
      Delete
    </Button>
    <Button onClick={onClose} variant="outline">
      Cancel
    </Button>
  </Stack>
);

const ModalErrorContent = ({
  onClose,
  fieldList,
}: {
  onClose: () => void;
  fieldList: Array<string>;
}) => (
  <ScaleFade initialScale={0.9} in={fieldList.length > 0}>
    <Stack w="100%">
      <Text pb={4}>
        A field(s) has split(s) associated with it and can&apos;t be removed.
        Please remove all splits associated with fields on this farm before
        removing it from the crop year.
      </Text>
      <Text pb={4} fontSize={12} color="errorRed">
        The following Fields include splits
      </Text>
      {fieldList.map((field) => (
        <Text pb={4} key={field}>
          {field}
        </Text>
      ))}
      <Button variant="primary" onClick={onClose} aria-label="confirmation-btn">
        Okay
      </Button>
    </Stack>
  </ScaleFade>
);

const DeleteFarmsModal = ({
  isOpen,
  onClose,
  onDelete,
  onCloseErrorModal,
  fieldList,
}: {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
  onCloseErrorModal: () => void;
  fieldList: Array<string>;
}): ReactElement => {
  const hasFields = useMemo(() => fieldList.length > 0, [fieldList]);
  const headingText = hasFields
    ? "Remove Farm with Split"
    : "Are you sure you want to delete this farm";
  const isCentered = useBreakpointValue(desktopOnly);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered={isCentered || !hasFields}
    >
      <ModalOverlay />
      <ModalContent maxW={CARD_MAX_WIDTH}>
        <ModalHeader>
          <TextHeading textStyle="h3">{headingText}</TextHeading>
        </ModalHeader>
        <ModalBody>
          {hasFields ? (
            <ModalErrorContent
              onClose={onCloseErrorModal}
              fieldList={fieldList}
            />
          ) : (
            <ModalPrimaryContent onDelete={onDelete} onClose={onClose} />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DeleteFarmsModal;
