import {
  FieldApi,
  FieldListDto,
  FieldResponse,
  MinimumFarmListDto,
  CreateFieldRequest,
  FieldImportResponse,
} from "api";
import {
  extractResponseData,
  extractResponseDataWithMeta,
  PaginationMeta,
  processResponse,
  QueryParams,
  transformQueryParamsForRequest,
} from "./apiHelpers";
import { constructApiControllerInstance } from "./customApiConfig";

export type FieldsListSortColumns =
  | "fieldNumber"
  | "totalAcres"
  | "tonsPerAcre"
  | "dairy.name"
  | "legalDescription"
  | "commonName"
  | "splits.cropYearFarm.farm.region.name";

class FieldService {
  fieldController!: FieldApi;
  constructor() {
    this.fieldController = constructApiControllerInstance(FieldApi);
  }

  createField(fieldInfo: CreateFieldRequest): Promise<FieldResponse> {
    return this.fieldController
      .createField({
        createFieldRequest: {
          ...fieldInfo,
        },
      })
      .then(extractResponseData);
  }

  getFields({
    cropYearId,
    queryParams,
    farmId,
  }: {
    cropYearId: string;
    queryParams?: QueryParams;
    farmId?: string;
  }): Promise<{ data: FieldListDto[]; meta: PaginationMeta }> {
    return this.fieldController
      .getFields({
        cropYearId,
        ...transformQueryParamsForRequest(queryParams),
        farmId,
      })
      .then(extractResponseDataWithMeta);
  }

  getFieldAddableFarms({
    fieldId,
    farmId,
  }: {
    fieldId: string;
    farmId: string;
  }): Promise<MinimumFarmListDto[]> {
    return this.fieldController
      .getFieldAddableFarms({ id: fieldId, farmId })
      .then(extractResponseData);
  }

  getFieldDetail(fieldId: string, farmId: string): Promise<FieldResponse> {
    return this.fieldController
      .getFieldDetail({ id: fieldId, farmId })
      .then(extractResponseData);
  }

  updateField(
    fieldId: string,
    fieldData: CreateFieldRequest
  ): Promise<FieldResponse> {
    return this.fieldController
      .updateField({ id: fieldId, createFieldRequest: fieldData })
      .then(extractResponseData);
  }

  deleteField(fieldId: string): Promise<string> {
    return this.fieldController
      .deleteField({ id: fieldId })
      .then(processResponse);
  }

  uploadFieldsCsv(csvFile: File): Promise<FieldImportResponse> {
    return this.fieldController
      .importFieldDetails({ file: csvFile })
      .then(extractResponseData);
  }
  getFieldCsvUploadGuideRequirements(): Promise<string[]> {
    return this.fieldController
      .getFieldDetailImportHeaders()
      .then(extractResponseData);
  }

  uploadActualTonsCsv(csvFile: File): Promise<FieldImportResponse> {
    return this.fieldController
      .importActualTons({ file: csvFile })
      .then(extractResponseData);
  }
}

export default FieldService;
