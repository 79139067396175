import { createBrowserRouter } from "react-router-dom";

import RedirectToDashboard from "components/redirects/RedirectToDashboard";
import adminRoutes from "./admin";
import authRoutes from "./auth";
import csvRoutes from "./csv-uploads";
import dashboardRoutes from "./dashboard";
import farmRoutes from "./farm";
import fieldRoutes from "./field";
import forgotPasswordRoutes from "./forgot-password/";
import userRegistrationRoutes from "./user-registration/";
import { userRoutes, usersRoutes } from "./user";
import haylageRoutes from "./haylage/haylageRoutes";
import manureRoutes from "./manure/manureRoutes";

export const routes = [
  {
    path: "/",
    element: <RedirectToDashboard />,
  },
  adminRoutes, // TODO: Update the admin routes directory to be employee
  csvRoutes,
  usersRoutes,
  userRoutes,
  authRoutes,
  farmRoutes,
  dashboardRoutes,
  fieldRoutes,
  forgotPasswordRoutes,
  userRegistrationRoutes,
  manureRoutes,
  haylageRoutes,
];

export const appRouter = createBrowserRouter(routes);
