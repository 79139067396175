import { ReactElement, useCallback, useMemo, useState } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  UseDisclosureProps,
} from "@chakra-ui/react";
import FormSkeletonLoadingState from "components/FormSkeletonLoadingState";
import InputLabel from "components/inputs/InputLabel";
import { SelectOption, SingleSelect } from "components/Select";
import TextHeading from "components/TextHeading";
import { useCropYearContext } from "contexts/CropYearContext";
import useGetFarmListByCropYear from "hooks/farm/useGetFarmListByCropYear";
import useSelectedCropYearId from "hooks/useSelectedCropYearId";
import dashboardPaths from "routes/dashboard/dashboardPaths";
import { CARD_MAX_WIDTH } from "utils/styleHelpers";

const SelectFarmForm = ({
  onClose = () => {
    return;
  },
  options,
}: { options: Array<SelectOption> } & UseDisclosureProps): ReactElement => {
  const [farm, setFarm] = useState<SelectOption | undefined>();
  const navigate = useNavigate();
  const [selectedCropYearId] = useSelectedCropYearId();

  const navigateToManualDelayedPricingPage = useCallback(
    () =>
      navigate({
        pathname: `/${dashboardPaths.basePath}/${dashboardPaths.children.newManualDelayedPricing}`,
        search: createSearchParams({
          farmId: farm?.value ?? "",
          cropYearId: selectedCropYearId ?? "",
        }).toString(),
      }),
    [farm?.value, navigate, selectedCropYearId]
  );

  return (
    <>
      <Stack spacing={0}>
        <InputLabel label="Farm" isRequired />
        <SingleSelect
          aria-label="Select Farm"
          id="select-farm"
          placeholder="Select Farm"
          options={options}
          selectedOption={farm}
          setSelectedOption={setFarm}
        />
      </Stack>
      <Stack spacing={2}>
        <Button
          onClick={navigateToManualDelayedPricingPage}
          variant="primary"
          isDisabled={!farm}
        >
          Continue
        </Button>
        <Button onClick={onClose} variant="outline">
          Cancel
        </Button>
      </Stack>
    </>
  );
};

const SelectFarmModal = ({
  isOpen = false,
  onClose = () => {
    return;
  },
}: UseDisclosureProps): ReactElement => {
  const { cropYear } = useCropYearContext();
  const { data, isPending } = useGetFarmListByCropYear(cropYear?.id);

  const farmList = data?.pages.flatMap((page) => page.farmList);

  const farmOptions: SelectOption[] = useMemo(
    () =>
      farmList?.map((farm) => ({
        value: farm.id,
        label: farm.doingBusinessAs,
      })) ?? [],
    [farmList]
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxW={CARD_MAX_WIDTH}>
        <ModalHeader>
          <TextHeading headingType="h3">Select Farm</TextHeading>
        </ModalHeader>
        <ModalBody>
          <Stack spacing={4} w="100%">
            <Text>
              Only farms that have been added to the crop year are available to
              be selected for Delayed Pricing.
            </Text>
            {isPending ? (
              <FormSkeletonLoadingState rowCount={1} />
            ) : (
              <SelectFarmForm options={farmOptions} onClose={onClose} />
            )}
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SelectFarmModal;
