/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface MonthlyAverageContractMinResponseDto
 */
export interface MonthlyAverageContractMinResponseDto {
  /**
   *
   * @type {string}
   * @memberof MonthlyAverageContractMinResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof MonthlyAverageContractMinResponseDto
   */
  month: MonthlyAverageContractMinResponseDtoMonthEnum;
  /**
   *
   * @type {number}
   * @memberof MonthlyAverageContractMinResponseDto
   */
  contractPrice: number;
}

/**
 * @export
 * @enum {string}
 */
export enum MonthlyAverageContractMinResponseDtoMonthEnum {
  January = "JANUARY",
  February = "FEBRUARY",
  March = "MARCH",
  April = "APRIL",
  May = "MAY",
  June = "JUNE",
  July = "JULY",
  August = "AUGUST",
  September = "SEPTEMBER",
  October = "OCTOBER",
  November = "NOVEMBER",
  December = "DECEMBER",
}
