import { Card, Text } from "@chakra-ui/react";

const FarmPendingApprovalBanner = () => (
  <Card bg="grayFactor.1.5" borderRadius={8} minH="64px" w="100%" p={5}>
    <Text color="brand.900" fontWeight="bold" textAlign="center">
      This farm is pending approval. Farm validation could take up to 48 hours.
      You will be notified when your farm has been approved.
    </Text>
  </Card>
);
export default FarmPendingApprovalBanner;
