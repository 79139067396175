import { skipToken, useInfiniteQuery } from "@tanstack/react-query";
import { useCropYearService } from "contexts/ServiceProvider";
import { getNextPage } from "services/apiHelpers";

export const SILAGE_YEAR_FARM_DOCUMENT_LINK_QUERY_KEY =
  "silage-year-farm-document-link";

const useSilageYearFarmDocuments = (
  silageYearId: string | undefined,
  farmId: string | undefined
) => {
  const cropYearService = useCropYearService();

  return useInfiniteQuery({
    queryKey: [
      SILAGE_YEAR_FARM_DOCUMENT_LINK_QUERY_KEY,
      { silageYearId, farmId },
    ],
    queryFn:
      silageYearId && farmId
        ? ({ pageParam = 0 }) =>
            cropYearService.getSilageYearFarmDocuments(silageYearId, farmId, {
              page: pageParam,
              size: 10,
            })
        : skipToken,
    initialPageParam: 0,
    getNextPageParam: getNextPage,
  });
};

export default useSilageYearFarmDocuments;
