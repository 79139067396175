import { ChangeEvent, ReactElement } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Flex } from "@chakra-ui/react";

import { InputWrapper } from "../index";
import StandardInput from "../StandardInput";
import { exceedsMaxDecimalDigits } from "utils/numberUtils";
import { preventNonNumericCharacters } from "utils/inputUtils";

export type DecimalNumberInputProps = {
  id: string;
  label: string | JSX.Element;
  isRequired?: boolean;
  isDisabled?: boolean;
  maxLength?: number;
  maxDecimalNumbers?: number;
  leftAddon?: ReactElement | string;
  rightAddon?: ReactElement;
  allowNegative?: boolean;
  clearZeroValue?: boolean;
  defaultEmptyValueToZero?: boolean;
};

export const DecimalNumberInput = ({
  allowNegative = false,
  id,
  label,
  isRequired,
  isDisabled,
  maxLength = 5,
  maxDecimalNumbers = 2,
  leftAddon,
  rightAddon,
  clearZeroValue,
  defaultEmptyValueToZero,
}: DecimalNumberInputProps): ReactElement => {
  const { control } = useFormContext();
  const handleOnChange = (
    e: ChangeEvent<HTMLInputElement>,
    onChange: (e: ChangeEvent<HTMLInputElement>) => void
  ) => {
    if (exceedsMaxDecimalDigits(e.target.value, maxDecimalNumbers)) {
      return;
    }
    onChange(e);
  };

  return (
    <Controller
      name={id}
      control={control}
      render={({ fieldState: { error }, field: { value, onChange } }) => (
        <Flex direction="column">
          <InputWrapper
            label={label}
            isRequired={isRequired}
            errorMsg={error?.message}
            isInvalid={!!error?.message}
            isDisabled={isDisabled}
          >
            <StandardInput
              name={id}
              value={value}
              type="number"
              maxLength={maxLength}
              onChange={(e) => handleOnChange(e, onChange)}
              onKeyDown={(e) =>
                preventNonNumericCharacters(e, allowNegative ? ["-"] : [])
              }
              onWheel={(e) => e.currentTarget.blur()}
              step=".01"
              leftAddon={leftAddon}
              rightAddon={rightAddon}
              hideAddonBackground
              isDisabled={isDisabled}
              onFocus={
                clearZeroValue
                  ? () => (value === "0.00" ? onChange("") : null)
                  : undefined
              }
              onBlur={
                defaultEmptyValueToZero
                  ? () => (value === "" ? onChange("0.00") : null)
                  : undefined
              }
            />
          </InputWrapper>
        </Flex>
      )}
    />
  );
};

DecimalNumberInput.displayName = "DecimalNumberInput";

export default DecimalNumberInput;
