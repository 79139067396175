import { ChangeEvent, useCallback, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Stack, Text } from "@chakra-ui/react";

import { CsvStep, CsvUploadBox } from "components/CsvUpload";
import TextErrorMessage from "components/TextErrorMessage";
import {
  CSV_FILE_SIZE_REQUIREMENT,
  CSV_FILE_TYPE_REQUIREMENT,
  MAX_CSV_FILE_SIZE_MB,
} from "constants/csvRequirements";
import { csvChildPaths } from "routes/csv-uploads/csvPaths";
import { extractResponseErrorMessage } from "services/apiHelpers";
import { FieldImportResponse } from "api";
import { FileRequirementsProps } from "../farm-documents/FarmDocumentsFileSelect/FarmDocumentsFileSelect";

const MAX_CSV_FILE_SIZE_BYTES = MAX_CSV_FILE_SIZE_MB * 1024 * 1024;

const CsvFileSelect = ({
  fileRequirements = [],
  onUploadCsvFile,
  isLoading = false,
  linkText,
  linkTo,
  totalSteps = 3,
}: {
  fileRequirements?: FileRequirementsProps;
  onUploadCsvFile?: (file: File) => Promise<FieldImportResponse>;
  isLoading?: boolean;
  linkText?: string;
  linkTo?: string;
  totalSteps?: number;
}) => {
  const [, setFile] = useOutletContext<[File, (file: File) => void]>();
  const navigate = useNavigate();
  const [uploadErrors, setUploadErrors] = useState<string[]>([]);
  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];

      if (!(file && onUploadCsvFile)) {
        setUploadErrors(["No file selected"]);

        return;
      }
      if (file.size > MAX_CSV_FILE_SIZE_BYTES) {
        setUploadErrors([CSV_FILE_SIZE_REQUIREMENT]);
      } else if (file.type.indexOf("csv") < 0) {
        setUploadErrors([CSV_FILE_TYPE_REQUIREMENT]);
      } else {
        onUploadCsvFile(file)
          .then((data) => {
            setFile(file);
            if (data.jobId) {
              navigate(`../${csvChildPaths.progress}/${data.jobId}`);
            }
          })
          .catch((err) => {
            setUploadErrors(
              err.response?.data.data?.validationErrors ?? [
                `Failed to upload CSV file. ${extractResponseErrorMessage(
                  err
                )}`,
              ]
            );
          });
      }
    },
    [navigate, onUploadCsvFile, setFile]
  );

  return (
    <>
      <CsvStep
        currentStep={1}
        totalSteps={totalSteps}
        description="Select file for upload"
        linkText={linkText}
        linkTo={linkTo}
      />
      {uploadErrors.length > 0 ? (
        <Stack spacing="10px" mb={3}>
          <Text fontWeight="bold" color="errorRed">
            Error
          </Text>
          <Stack spacing="6px">
            {uploadErrors.map((uploadError) => (
              <TextErrorMessage key={uploadError}>
                {uploadError}
              </TextErrorMessage>
            ))}
          </Stack>
        </Stack>
      ) : null}
      <CsvUploadBox
        fileRequirements={fileRequirements}
        isLoading={isLoading}
        onChange={onChange}
      />
    </>
  );
};

export default CsvFileSelect;
