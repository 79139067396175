import { ReactElement } from "react";
import TextHeading from "components/TextHeading";
import NavigationBadgeContainer from "components/navigation/NavigationBadgeContainer";

const RiverviewEmployeeCard = (): ReactElement => (
  <NavigationBadgeContainer bgColor="brand.800" borderRadius="lg">
    <TextHeading headingType="h6" color="white">
      Riverview
    </TextHeading>
  </NavigationBadgeContainer>
);

export default RiverviewEmployeeCard;
