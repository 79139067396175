/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosRequestConfig,
} from "axios";
import { Configuration } from "../configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "../base";
// @ts-ignore
import { DataHaylageYearResponse } from "../models";
// @ts-ignore
import { DataListFarmListDto } from "../models";
// @ts-ignore
import { PageResponseFarmDocumentDto } from "../models";
// @ts-ignore
import { PageResponseHaylageYearFarmResponse } from "../models";
// @ts-ignore
import { PageResponseHaylageYearResponse } from "../models";
/**
 * HaylageYearApi - axios parameter creator
 * @export
 */
export const HaylageYearApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {string} id
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    assignFarmsToHaylageYear: async (
      id: string,
      requestBody: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("assignFarmsToHaylageYear", "id", id);
      // verify required parameter 'requestBody' is not null or undefined
      assertParamExists("assignFarmsToHaylageYear", "requestBody", requestBody);
      const localVarPath = `/haylage-year/{id}/farm`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        requestBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHaylageYear: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getHaylageYear", "id", id);
      const localVarPath = `/haylage-year/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {string} farmId
     * @param {string} [farmId2]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHaylageYearFarmDocumentsPage: async (
      id: string,
      farmId: string,
      farmId2?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getHaylageYearFarmDocumentsPage", "id", id);
      // verify required parameter 'farmId' is not null or undefined
      assertParamExists("getHaylageYearFarmDocumentsPage", "farmId", farmId);
      const localVarPath = `/haylage-year/{id}/farm/{farm-id}/documents`
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"farm-id"}}`, encodeURIComponent(String(farmId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sort) {
        localVarQueryParameter["sort"] = sort;
      }

      if (farmId2 !== undefined && farmId2 !== null) {
        localVarHeaderParameter["farm-id"] = String(farmId2);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listAddableFarms: async (
      id: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("listAddableFarms", "id", id);
      const localVarPath = `/haylage-year/{id}/addable-farms`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sort) {
        localVarQueryParameter["sort"] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listHaylageYearFarms: async (
      id: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("listHaylageYearFarms", "id", id);
      const localVarPath = `/haylage-year/{id}/farm`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sort) {
        localVarQueryParameter["sort"] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listHaylageYears: async (
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/haylage-year`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sort) {
        localVarQueryParameter["sort"] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * HaylageYearApi - functional programming interface
 * @export
 */
export const HaylageYearApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    HaylageYearApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} id
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async assignFarmsToHaylageYear(
      id: string,
      requestBody: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.assignFarmsToHaylageYear(
          id,
          requestBody,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getHaylageYear(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataHaylageYearResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getHaylageYear(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {string} farmId
     * @param {string} [farmId2]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getHaylageYearFarmDocumentsPage(
      id: string,
      farmId: string,
      farmId2?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PageResponseFarmDocumentDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getHaylageYearFarmDocumentsPage(
          id,
          farmId,
          farmId2,
          page,
          size,
          sort,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listAddableFarms(
      id: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataListFarmListDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listAddableFarms(
          id,
          page,
          size,
          sort,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listHaylageYearFarms(
      id: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PageResponseHaylageYearFarmResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listHaylageYearFarms(
          id,
          page,
          size,
          sort,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listHaylageYears(
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PageResponseHaylageYearResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listHaylageYears(
          page,
          size,
          sort,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * HaylageYearApi - factory interface
 * @export
 */
export const HaylageYearApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = HaylageYearApiFp(configuration);
  return {
    /**
     *
     * @param {string} id
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    assignFarmsToHaylageYear(
      id: string,
      requestBody: Array<string>,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .assignFarmsToHaylageYear(id, requestBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHaylageYear(
      id: string,
      options?: any
    ): AxiosPromise<DataHaylageYearResponse> {
      return localVarFp
        .getHaylageYear(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {string} farmId
     * @param {string} [farmId2]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHaylageYearFarmDocumentsPage(
      id: string,
      farmId: string,
      farmId2?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any
    ): AxiosPromise<PageResponseFarmDocumentDto> {
      return localVarFp
        .getHaylageYearFarmDocumentsPage(
          id,
          farmId,
          farmId2,
          page,
          size,
          sort,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listAddableFarms(
      id: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any
    ): AxiosPromise<DataListFarmListDto> {
      return localVarFp
        .listAddableFarms(id, page, size, sort, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listHaylageYearFarms(
      id: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any
    ): AxiosPromise<PageResponseHaylageYearFarmResponse> {
      return localVarFp
        .listHaylageYearFarms(id, page, size, sort, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listHaylageYears(
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any
    ): AxiosPromise<PageResponseHaylageYearResponse> {
      return localVarFp
        .listHaylageYears(page, size, sort, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for assignFarmsToHaylageYear operation in HaylageYearApi.
 * @export
 * @interface HaylageYearApiAssignFarmsToHaylageYearRequest
 */
export interface HaylageYearApiAssignFarmsToHaylageYearRequest {
  /**
   *
   * @type {string}
   * @memberof HaylageYearApiAssignFarmsToHaylageYear
   */
  readonly id: string;

  /**
   *
   * @type {Array<string>}
   * @memberof HaylageYearApiAssignFarmsToHaylageYear
   */
  readonly requestBody: Array<string>;
}

/**
 * Request parameters for getHaylageYear operation in HaylageYearApi.
 * @export
 * @interface HaylageYearApiGetHaylageYearRequest
 */
export interface HaylageYearApiGetHaylageYearRequest {
  /**
   *
   * @type {string}
   * @memberof HaylageYearApiGetHaylageYear
   */
  readonly id: string;
}

/**
 * Request parameters for getHaylageYearFarmDocumentsPage operation in HaylageYearApi.
 * @export
 * @interface HaylageYearApiGetHaylageYearFarmDocumentsPageRequest
 */
export interface HaylageYearApiGetHaylageYearFarmDocumentsPageRequest {
  /**
   *
   * @type {string}
   * @memberof HaylageYearApiGetHaylageYearFarmDocumentsPage
   */
  readonly id: string;

  /**
   *
   * @type {string}
   * @memberof HaylageYearApiGetHaylageYearFarmDocumentsPage
   */
  readonly farmId: string;

  /**
   *
   * @type {string}
   * @memberof HaylageYearApiGetHaylageYearFarmDocumentsPage
   */
  readonly farmId2?: string;

  /**
   * Zero-based page index (0..N)
   * @type {number}
   * @memberof HaylageYearApiGetHaylageYearFarmDocumentsPage
   */
  readonly page?: number;

  /**
   * The size of the page to be returned
   * @type {number}
   * @memberof HaylageYearApiGetHaylageYearFarmDocumentsPage
   */
  readonly size?: number;

  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @type {Array<string>}
   * @memberof HaylageYearApiGetHaylageYearFarmDocumentsPage
   */
  readonly sort?: Array<string>;
}

/**
 * Request parameters for listAddableFarms operation in HaylageYearApi.
 * @export
 * @interface HaylageYearApiListAddableFarmsRequest
 */
export interface HaylageYearApiListAddableFarmsRequest {
  /**
   *
   * @type {string}
   * @memberof HaylageYearApiListAddableFarms
   */
  readonly id: string;

  /**
   * Zero-based page index (0..N)
   * @type {number}
   * @memberof HaylageYearApiListAddableFarms
   */
  readonly page?: number;

  /**
   * The size of the page to be returned
   * @type {number}
   * @memberof HaylageYearApiListAddableFarms
   */
  readonly size?: number;

  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @type {Array<string>}
   * @memberof HaylageYearApiListAddableFarms
   */
  readonly sort?: Array<string>;
}

/**
 * Request parameters for listHaylageYearFarms operation in HaylageYearApi.
 * @export
 * @interface HaylageYearApiListHaylageYearFarmsRequest
 */
export interface HaylageYearApiListHaylageYearFarmsRequest {
  /**
   *
   * @type {string}
   * @memberof HaylageYearApiListHaylageYearFarms
   */
  readonly id: string;

  /**
   * Zero-based page index (0..N)
   * @type {number}
   * @memberof HaylageYearApiListHaylageYearFarms
   */
  readonly page?: number;

  /**
   * The size of the page to be returned
   * @type {number}
   * @memberof HaylageYearApiListHaylageYearFarms
   */
  readonly size?: number;

  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @type {Array<string>}
   * @memberof HaylageYearApiListHaylageYearFarms
   */
  readonly sort?: Array<string>;
}

/**
 * Request parameters for listHaylageYears operation in HaylageYearApi.
 * @export
 * @interface HaylageYearApiListHaylageYearsRequest
 */
export interface HaylageYearApiListHaylageYearsRequest {
  /**
   * Zero-based page index (0..N)
   * @type {number}
   * @memberof HaylageYearApiListHaylageYears
   */
  readonly page?: number;

  /**
   * The size of the page to be returned
   * @type {number}
   * @memberof HaylageYearApiListHaylageYears
   */
  readonly size?: number;

  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @type {Array<string>}
   * @memberof HaylageYearApiListHaylageYears
   */
  readonly sort?: Array<string>;
}

/**
 * HaylageYearApi - object-oriented interface
 * @export
 * @class HaylageYearApi
 * @extends {BaseAPI}
 */
export class HaylageYearApi extends BaseAPI {
  /**
   *
   * @param {HaylageYearApiAssignFarmsToHaylageYearRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HaylageYearApi
   */
  public assignFarmsToHaylageYear(
    requestParameters: HaylageYearApiAssignFarmsToHaylageYearRequest,
    options?: AxiosRequestConfig
  ) {
    return HaylageYearApiFp(this.configuration)
      .assignFarmsToHaylageYear(
        requestParameters.id,
        requestParameters.requestBody,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {HaylageYearApiGetHaylageYearRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HaylageYearApi
   */
  public getHaylageYear(
    requestParameters: HaylageYearApiGetHaylageYearRequest,
    options?: AxiosRequestConfig
  ) {
    return HaylageYearApiFp(this.configuration)
      .getHaylageYear(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {HaylageYearApiGetHaylageYearFarmDocumentsPageRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HaylageYearApi
   */
  public getHaylageYearFarmDocumentsPage(
    requestParameters: HaylageYearApiGetHaylageYearFarmDocumentsPageRequest,
    options?: AxiosRequestConfig
  ) {
    return HaylageYearApiFp(this.configuration)
      .getHaylageYearFarmDocumentsPage(
        requestParameters.id,
        requestParameters.farmId,
        requestParameters.farmId2,
        requestParameters.page,
        requestParameters.size,
        requestParameters.sort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {HaylageYearApiListAddableFarmsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HaylageYearApi
   */
  public listAddableFarms(
    requestParameters: HaylageYearApiListAddableFarmsRequest,
    options?: AxiosRequestConfig
  ) {
    return HaylageYearApiFp(this.configuration)
      .listAddableFarms(
        requestParameters.id,
        requestParameters.page,
        requestParameters.size,
        requestParameters.sort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {HaylageYearApiListHaylageYearFarmsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HaylageYearApi
   */
  public listHaylageYearFarms(
    requestParameters: HaylageYearApiListHaylageYearFarmsRequest,
    options?: AxiosRequestConfig
  ) {
    return HaylageYearApiFp(this.configuration)
      .listHaylageYearFarms(
        requestParameters.id,
        requestParameters.page,
        requestParameters.size,
        requestParameters.sort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {HaylageYearApiListHaylageYearsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HaylageYearApi
   */
  public listHaylageYears(
    requestParameters: HaylageYearApiListHaylageYearsRequest = {},
    options?: AxiosRequestConfig
  ) {
    return HaylageYearApiFp(this.configuration)
      .listHaylageYears(
        requestParameters.page,
        requestParameters.size,
        requestParameters.sort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
