import { Stack, Text, Tooltip } from "@chakra-ui/react";

import { formatDate, parseIsoDate } from "utils/dateUtils";
import { mobileStyleBreakpoint } from "utils/styleHelpers";

const RECORD_STAMP_MIN_WIDTH = mobileStyleBreakpoint("100px", "230px");
export const PricingInfoRecordStamp = ({
  initiatedBy,
  dateInitiated,
  label,
  labelTextColor,
  valueTextColor,
}: {
  initiatedBy: string | undefined;
  dateInitiated: string | undefined;
  label: string;
  labelTextColor: string;
  valueTextColor?: string;
}) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      minW={RECORD_STAMP_MIN_WIDTH}
    >
      <Text color={labelTextColor} fontWeight="bold">
        {label}
      </Text>
      <Tooltip label={initiatedBy} hasArrow>
        <Stack
          alignItems="flex-end"
          direction={{ base: "column", md: "row" }}
          spacing={0}
          minW={RECORD_STAMP_MIN_WIDTH}
        >
          <Text color={valueTextColor}>
            {dateInitiated ? formatDate(parseIsoDate(dateInitiated)) : "-"} -
          </Text>
          <Text color={valueTextColor} isTruncated variant="truncated">
            &nbsp;{initiatedBy}
          </Text>
        </Stack>
      </Tooltip>
    </Stack>
  );
};
