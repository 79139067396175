/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface CSVUploadJobResponse
 */
export interface CSVUploadJobResponse {
  /**
   *
   * @type {string}
   * @memberof CSVUploadJobResponse
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof CSVUploadJobResponse
   */
  status: CSVUploadJobResponseStatusEnum;
  /**
   *
   * @type {number}
   * @memberof CSVUploadJobResponse
   */
  progress: number;
  /**
   *
   * @type {number}
   * @memberof CSVUploadJobResponse
   */
  totalItems?: number;
  /**
   *
   * @type {number}
   * @memberof CSVUploadJobResponse
   */
  itemsProcessedSuccessfully?: number;
  /**
   *
   * @type {number}
   * @memberof CSVUploadJobResponse
   */
  itemsWithErrors?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof CSVUploadJobResponse
   */
  validationErrors: Array<string>;
  /**
   *
   * @type {{ [key: string]: { [key: string]: Array<string>; }; }}
   * @memberof CSVUploadJobResponse
   */
  rowErrorsByHeader: { [key: string]: { [key: string]: Array<string> } };
  /**
   *
   * @type {{ [key: string]: Array<string>; }}
   * @memberof CSVUploadJobResponse
   */
  allRowErrors: { [key: string]: Array<string> };
  /**
   *
   * @type {{ [key: string]: { [key: string]: string; }; }}
   * @memberof CSVUploadJobResponse
   */
  invalidRowData: { [key: string]: { [key: string]: string } };
}

/**
 * @export
 * @enum {string}
 */
export enum CSVUploadJobResponseStatusEnum {
  InProgress = "IN_PROGRESS",
  Success = "SUCCESS",
  Failed = "FAILED",
}
