import { PropsWithChildren } from "react";

import { chakra, Stack, StackProps, Text } from "@chakra-ui/react";

import SunnyField from "assets/sunnyField.svg";
import { mobileStyleBreakpoint } from "utils/styleHelpers";

const SUNNY_FIELD_WIDTH = mobileStyleBreakpoint("300px", "unset");

const EmptyState = ({ children, ...rest }: PropsWithChildren<StackProps>) => (
  <Stack spacing={6} align="center" pt={12} {...rest}>
    <chakra.img w={SUNNY_FIELD_WIDTH} src={SunnyField} />
    <Text as="span" fontWeight="bold" color="steelGray" align="center">
      {children}
    </Text>
  </Stack>
);

export default EmptyState;
