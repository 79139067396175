/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface UpdateUserStatusRequest
 */
export interface UpdateUserStatusRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateUserStatusRequest
   */
  userIdList: Array<string>;
  /**
   *
   * @type {string}
   * @memberof UpdateUserStatusRequest
   */
  status: UpdateUserStatusRequestStatusEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum UpdateUserStatusRequestStatusEnum {
  Pending = "PENDING",
  Active = "ACTIVE",
  Inactive = "INACTIVE",
}
