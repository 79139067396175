import {
  To,
  useNavigate,
  useSearchParams,
  NavigateFunction,
  NavigateOptions,
} from "react-router-dom";

const useNavigateWithQuery = (): NavigateFunction => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  return (to: To | number, options?: NavigateOptions): void => {
    navigate(
      (typeof to === "string" ? (`${to}?${searchParams}` as To) : to) as To,
      options
    );
  };
};

export default useNavigateWithQuery;
