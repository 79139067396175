import { ReactElement } from "react";

import { HStack, Spacer, Text } from "@chakra-ui/react";

import { FormInputs } from "components/inputs";
import DeferPaymentSelectInput from "components/inputs/DeferPaymentSelectInput";
import PercentageToSellInput from "components/inputs/PercentageToSellInput";
import DeferPaymentDateInput from "components/inputs/DeferPaymentDateInput";

const AveragePricingInputs = ({
  defaultDefermentDate,
  readOnlyPercentageToSell = false,
  deferPayment,
  firstPaymentDateWithoutReferral,
  percentageToSell = 0,
}: {
  defaultDefermentDate?: Date;
  readOnlyPercentageToSell?: boolean;
  deferPayment: boolean;
  firstPaymentDateWithoutReferral?: Date;
  percentageToSell?: number;
}): ReactElement => (
  <>
    {readOnlyPercentageToSell ? (
      <HStack
        pb={
          2 /* Since this is not a form input, it doesn't have the spacing the error message would consume so add extra pb */
        }
      >
        <Text fontWeight="bold">Percentage To Sell</Text>
        <Spacer />
        <Text>{`${percentageToSell}%`}</Text>
      </HStack>
    ) : (
      <PercentageToSellInput />
    )}
    <FormInputs
      inputs={[
        {
          id: "deferPayment",
          label: "Defer Payment",
          component: DeferPaymentSelectInput,
          shouldRegister: false,
          isRequired: true,
        },
      ]}
    />
    <DeferPaymentDateInput
      deferPayment={deferPayment}
      defaultDefermentDate={defaultDefermentDate}
      firstPaymentDateWithoutReferral={firstPaymentDateWithoutReferral}
    />
  </>
);

export default AveragePricingInputs;
