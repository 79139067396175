import { ButtonProps, HStack, Text } from "@chakra-ui/react";
import { useNavigationRoute } from "hooks/useNavigationRoute";
import { UsersIcon } from "icons";
import { ReactElement } from "react";
import NavigationItem from "./NavigationItem";

export const UsersNavigationItem = (props: ButtonProps): ReactElement => {
  const { usersRoute } = useNavigationRoute();

  return (
    <NavigationItem route={usersRoute} {...props}>
      <HStack spacing={2} w="100%">
        <UsersIcon color="inherit" />
        <Text color="inherit">Users</Text>
      </HStack>
    </NavigationItem>
  );
};
