import { skipToken, useInfiniteQuery } from "@tanstack/react-query";

import { FieldListDto } from "api";
import { useFieldService } from "contexts/ServiceProvider";
import { getNextPage, QueryParams } from "services/apiHelpers";
import { FieldsListSortColumns } from "services/FieldService";

export type FieldListItem = Pick<
  FieldListDto,
  | "id"
  | "fieldNumber"
  | "totalAcres"
  | "tonsPerAcre"
  | "dairy"
  | "legalDescription"
  | "commonName"
  | "farms"
  | "region"
>;

export const GET_FIELDS_QUERY_KEY = "get-fields-list";

const useFieldsList = ({
  cropYearId,
  farmId,
  queryParams,
}: {
  cropYearId?: string;
  farmId?: string;
  queryParams?: Omit<QueryParams<FieldsListSortColumns>, "page">;
}) => {
  const fieldService = useFieldService();

  return useInfiniteQuery({
    queryKey: [GET_FIELDS_QUERY_KEY, { cropYearId, farmId }, queryParams],
    queryFn:
      cropYearId && farmId
        ? ({ pageParam = 0 }) =>
            fieldService
              .getFields({
                cropYearId,
                queryParams: { ...queryParams, page: pageParam },
                farmId,
              })
              .then(({ data, meta }) => ({
                fields: data,
                meta,
              }))
        : skipToken,
    initialPageParam: 0,
    getNextPageParam: (lastPage) => getNextPage(lastPage),
  });
};

export default useFieldsList;
