import { Button, ButtonProps, Center } from "@chakra-ui/react";
import { ReactElement } from "react";
import { useNavigate } from "react-router-dom";

const BackButton = ({
  route,
  label = "Back",
  callback,
  variant = "ghost",
  ...rest
}: {
  route: string;
  label?: string;
  callback?: any;
} & ButtonProps): ReactElement => {
  const navigate = useNavigate();

  return (
    <Center w="100%">
      <Button
        onClick={() => {
          callback?.();
          navigate(`../${route}`);
        }}
        variant={variant}
        {...rest}
      >
        {label}
      </Button>
    </Center>
  );
};

export default BackButton;
