import { ChakraTheme } from "@chakra-ui/react";
import breakpoints from "./breakpoints";
import colors from "./colors";
import typography from "./typography";
import shadows from "./shadows";

const foundations: Partial<ChakraTheme> = {
  breakpoints,
  colors,
  shadows,
  ...typography,
};

export default foundations;
