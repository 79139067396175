import { useMutation } from "@tanstack/react-query";

import { AveragePricingContractRequest } from "api";
import { usePricingOptionService } from "contexts/ServiceProvider";
import { MutationHookResult } from "hooks/hookTypeHelpers";
import PricingOptionService from "services/PricingOptionService";
import useDashboardRefetchQueries from "hooks/crop-year/useDashboardRefetchQueries";

type UpdateAveragePricingContractMutationResult = MutationHookResult<
  Awaited<ReturnType<PricingOptionService["updateAveragePricingContract"]>>,
  AveragePricingContractRequest
>;

export const UPDATE_AVERAGE_PRICING_CONTRACT_QUERY_KEY =
  "update-average-pricing-contract";

const useUpdateAveragePricingContract = (
  averagePricingContractId: string | undefined,
  cropYearId: string | undefined,
  farmId: string | undefined
): UpdateAveragePricingContractMutationResult => {
  const pricingOptionService = usePricingOptionService();
  const { refetch } = useDashboardRefetchQueries();

  return useMutation({
    mutationFn: (averagePricingContract) => {
      if (cropYearId && farmId && averagePricingContractId) {
        return averagePricingContract
          ? pricingOptionService.updateAveragePricingContract({
              averagePricingContractId,
              cropYearId,
              farmId,
              averagePricingContract,
            })
          : Promise.reject(
              "Must provide valid average pricing contract information"
            );
      } else {
        return Promise.reject(
          "Missing crop year ID, average pricing contract ID, or farm ID"
        );
      }
    },
    mutationKey: [UPDATE_AVERAGE_PRICING_CONTRACT_QUERY_KEY],
    onSuccess: refetch,
  });
};

export default useUpdateAveragePricingContract;
