import { skipToken, useInfiniteQuery } from "@tanstack/react-query";
import { useHaylageYearService } from "contexts/ServiceProvider";
import { getNextPage } from "services/apiHelpers";

export const HAYLAGE_YEAR_FARM_DOCUMENT_LINK_QUERY_KEY =
  "haylage-year-farm-document-link";

const useGetHaylageYearFarmDocuments = (
  haylageYearId: string | undefined,
  farmId: string | undefined
) => {
  const haylageService = useHaylageYearService();

  return useInfiniteQuery({
    queryKey: [
      HAYLAGE_YEAR_FARM_DOCUMENT_LINK_QUERY_KEY,
      { haylageYearId, farmId },
    ],
    queryFn:
      haylageYearId && farmId
        ? ({ pageParam = 0 }) =>
            haylageService.getHaylageYearFarmDocuments(haylageYearId, farmId, {
              page: pageParam,
              size: 10,
            })
        : skipToken,
    initialPageParam: 0,
    getNextPageParam: getNextPage,
  });
};

export default useGetHaylageYearFarmDocuments;
