import { MenuItem, HStack, Text } from "@chakra-ui/react";
import useNavigateWithQuery from "hooks/useNavigateWithQuery";
import { useCallback } from "react";
import dashboardPaths from "routes/dashboard/dashboardPaths";

const ManualForwardPricingMenuItem = () => {
  const navigate = useNavigateWithQuery();

  const navigateToManualForwardPricingContractPage = useCallback(
    () =>
      navigate(
        `/${dashboardPaths.basePath}/${dashboardPaths.children.newManualForwardPricing}`
      ),
    [navigate]
  );

  return (
    <MenuItem
      onClick={navigateToManualForwardPricingContractPage}
      aria-label="manual-forward-pricing-menu-item"
    >
      <HStack justifyContent="space-between" w="100%" spacing={4}>
        <Text fontWeight="normal">Manual Forward Pricing</Text>
        <Text fontSize="xs">Available for Pre-Harvest</Text>
      </HStack>
    </MenuItem>
  );
};

export default ManualForwardPricingMenuItem;
