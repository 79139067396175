import {
  Box,
  CircularProgress,
  CircularProgressProps,
  Text,
} from "@chakra-ui/react";

import { SummaryStatVariant } from "../SummaryStatCard";
import { addDigitSeparators } from "utils/formatFunctions";

export const ProgressCircle = (props: CircularProgressProps) => (
  <CircularProgress
    position="absolute"
    thickness="2px"
    min={0}
    max={100}
    color="brand.800"
    {...props}
  />
);

export const percentOfTonsPriced: SummaryStatVariant = {
  icon: (value: number) => (
    <Box position="relative" w={14} h={14}>
      <ProgressCircle size={14} value={value} />
      <ProgressCircle left="4px" top="4px" size={12} value={value} />
    </Box>
  ),
  label: "Percentage of Tons Priced",
  formatter: (value) => (
    <Text>
      {addDigitSeparators(value)}
      <Text as="sup">%</Text>
    </Text>
  ),
};
