/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosRequestConfig,
} from "axios";
import { Configuration } from "../configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "../base";
// @ts-ignore
import { CreateFarmRequest } from "../models";
// @ts-ignore
import { DataFarmDetailResponse } from "../models";
// @ts-ignore
import { DataFarmDocumentImportResponse } from "../models";
// @ts-ignore
import { DataListString } from "../models";
// @ts-ignore
import { FarmRequest } from "../models";
// @ts-ignore
import { PageResponseFarmDocumentDto } from "../models";
// @ts-ignore
import { PageResponseFarmListDto } from "../models";
// @ts-ignore
import { PageResponseMinimumFarmListDto } from "../models";
// @ts-ignore
import { RemoveUsersFromFarmRequest } from "../models";
// @ts-ignore
import { UpdateFarmRequest } from "../models";
/**
 * FarmApi - axios parameter creator
 * @export
 */
export const FarmApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {CreateFarmRequest} createFarmRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFarm: async (
      createFarmRequest: CreateFarmRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createFarmRequest' is not null or undefined
      assertParamExists("createFarm", "createFarmRequest", createFarmRequest);
      const localVarPath = `/farm`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createFarmRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {Array<string>} requestBody
     * @param {string} [farmId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFarmDocuments: async (
      id: string,
      requestBody: Array<string>,
      farmId?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("deleteFarmDocuments", "id", id);
      // verify required parameter 'requestBody' is not null or undefined
      assertParamExists("deleteFarmDocuments", "requestBody", requestBody);
      const localVarPath = `/farm/{id}/documents`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (farmId !== undefined && farmId !== null) {
        localVarHeaderParameter["farm-id"] = String(farmId);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        requestBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFarmDetail: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getFarmDetail", "id", id);
      const localVarPath = `/farm/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFarmDocumentsImportHeaders: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/farm/documents/headers`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {string} [farmId]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFarmDocumentsPage: async (
      id: string,
      farmId?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getFarmDocumentsPage", "id", id);
      const localVarPath = `/farm/{id}/documents`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sort) {
        localVarQueryParameter["sort"] = sort;
      }

      if (farmId !== undefined && farmId !== null) {
        localVarHeaderParameter["farm-id"] = String(farmId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cropYearId
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFarmPageByCropYear: async (
      cropYearId: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cropYearId' is not null or undefined
      assertParamExists("getFarmPageByCropYear", "cropYearId", cropYearId);
      const localVarPath = `/farms/{crop-year-id}`.replace(
        `{${"crop-year-id"}}`,
        encodeURIComponent(String(cropYearId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sort) {
        localVarQueryParameter["sort"] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [search]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFarmsPage: async (
      search?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/farms`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (search !== undefined) {
        localVarQueryParameter["search"] = search;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sort) {
        localVarQueryParameter["sort"] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {FarmRequest} farmRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rejectFarm: async (
      farmRequest: FarmRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'farmRequest' is not null or undefined
      assertParamExists("rejectFarm", "farmRequest", farmRequest);
      const localVarPath = `/farm/reject`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        farmRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} farmId
     * @param {RemoveUsersFromFarmRequest} removeUsersFromFarmRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeUsersFromFarm: async (
      farmId: string,
      removeUsersFromFarmRequest: RemoveUsersFromFarmRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'farmId' is not null or undefined
      assertParamExists("removeUsersFromFarm", "farmId", farmId);
      // verify required parameter 'removeUsersFromFarmRequest' is not null or undefined
      assertParamExists(
        "removeUsersFromFarm",
        "removeUsersFromFarmRequest",
        removeUsersFromFarmRequest
      );
      const localVarPath = `/farm/users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (farmId !== undefined && farmId !== null) {
        localVarHeaderParameter["farm-id"] = String(farmId);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        removeUsersFromFarmRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setFarmToActive: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("setFarmToActive", "id", id);
      const localVarPath = `/farm/{id}/acknowledge`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {UpdateFarmRequest} updateFarmRequest
     * @param {string} [farmId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update: async (
      id: string,
      updateFarmRequest: UpdateFarmRequest,
      farmId?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("update", "id", id);
      // verify required parameter 'updateFarmRequest' is not null or undefined
      assertParamExists("update", "updateFarmRequest", updateFarmRequest);
      const localVarPath = `/farm/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (farmId !== undefined && farmId !== null) {
        localVarHeaderParameter["farm-id"] = String(farmId);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateFarmRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadFarmDocuments: async (
      file: any,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'file' is not null or undefined
      assertParamExists("uploadFarmDocuments", "file", file);
      const localVarPath = `/farm/documents`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (file !== undefined) {
        localVarFormParams.append("file", file as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FarmApi - functional programming interface
 * @export
 */
export const FarmApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FarmApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {CreateFarmRequest} createFarmRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createFarm(
      createFarmRequest: CreateFarmRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataFarmDetailResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createFarm(
        createFarmRequest,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {Array<string>} requestBody
     * @param {string} [farmId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteFarmDocuments(
      id: string,
      requestBody: Array<string>,
      farmId?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteFarmDocuments(
          id,
          requestBody,
          farmId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFarmDetail(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataFarmDetailResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFarmDetail(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFarmDocumentsImportHeaders(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataListString>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getFarmDocumentsImportHeaders(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {string} [farmId]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFarmDocumentsPage(
      id: string,
      farmId?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PageResponseFarmDocumentDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getFarmDocumentsPage(
          id,
          farmId,
          page,
          size,
          sort,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} cropYearId
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFarmPageByCropYear(
      cropYearId: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PageResponseMinimumFarmListDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getFarmPageByCropYear(
          cropYearId,
          page,
          size,
          sort,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} [search]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFarmsPage(
      search?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PageResponseFarmListDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFarmsPage(
        search,
        page,
        size,
        sort,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {FarmRequest} farmRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async rejectFarm(
      farmRequest: FarmRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.rejectFarm(
        farmRequest,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} farmId
     * @param {RemoveUsersFromFarmRequest} removeUsersFromFarmRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async removeUsersFromFarm(
      farmId: string,
      removeUsersFromFarmRequest: RemoveUsersFromFarmRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.removeUsersFromFarm(
          farmId,
          removeUsersFromFarmRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setFarmToActive(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.setFarmToActive(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {UpdateFarmRequest} updateFarmRequest
     * @param {string} [farmId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async update(
      id: string,
      updateFarmRequest: UpdateFarmRequest,
      farmId?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataFarmDetailResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.update(
        id,
        updateFarmRequest,
        farmId,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadFarmDocuments(
      file: any,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataFarmDocumentImportResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.uploadFarmDocuments(file, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * FarmApi - factory interface
 * @export
 */
export const FarmApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = FarmApiFp(configuration);
  return {
    /**
     *
     * @param {CreateFarmRequest} createFarmRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFarm(
      createFarmRequest: CreateFarmRequest,
      options?: any
    ): AxiosPromise<DataFarmDetailResponse> {
      return localVarFp
        .createFarm(createFarmRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {Array<string>} requestBody
     * @param {string} [farmId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFarmDocuments(
      id: string,
      requestBody: Array<string>,
      farmId?: string,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .deleteFarmDocuments(id, requestBody, farmId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFarmDetail(
      id: string,
      options?: any
    ): AxiosPromise<DataFarmDetailResponse> {
      return localVarFp
        .getFarmDetail(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFarmDocumentsImportHeaders(options?: any): AxiosPromise<DataListString> {
      return localVarFp
        .getFarmDocumentsImportHeaders(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {string} [farmId]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFarmDocumentsPage(
      id: string,
      farmId?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any
    ): AxiosPromise<PageResponseFarmDocumentDto> {
      return localVarFp
        .getFarmDocumentsPage(id, farmId, page, size, sort, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} cropYearId
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFarmPageByCropYear(
      cropYearId: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any
    ): AxiosPromise<PageResponseMinimumFarmListDto> {
      return localVarFp
        .getFarmPageByCropYear(cropYearId, page, size, sort, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [search]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFarmsPage(
      search?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any
    ): AxiosPromise<PageResponseFarmListDto> {
      return localVarFp
        .getFarmsPage(search, page, size, sort, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {FarmRequest} farmRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rejectFarm(farmRequest: FarmRequest, options?: any): AxiosPromise<string> {
      return localVarFp
        .rejectFarm(farmRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} farmId
     * @param {RemoveUsersFromFarmRequest} removeUsersFromFarmRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeUsersFromFarm(
      farmId: string,
      removeUsersFromFarmRequest: RemoveUsersFromFarmRequest,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .removeUsersFromFarm(farmId, removeUsersFromFarmRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setFarmToActive(id: string, options?: any): AxiosPromise<string> {
      return localVarFp
        .setFarmToActive(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {UpdateFarmRequest} updateFarmRequest
     * @param {string} [farmId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(
      id: string,
      updateFarmRequest: UpdateFarmRequest,
      farmId?: string,
      options?: any
    ): AxiosPromise<DataFarmDetailResponse> {
      return localVarFp
        .update(id, updateFarmRequest, farmId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadFarmDocuments(
      file: any,
      options?: any
    ): AxiosPromise<DataFarmDocumentImportResponse> {
      return localVarFp
        .uploadFarmDocuments(file, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createFarm operation in FarmApi.
 * @export
 * @interface FarmApiCreateFarmRequest
 */
export interface FarmApiCreateFarmRequest {
  /**
   *
   * @type {CreateFarmRequest}
   * @memberof FarmApiCreateFarm
   */
  readonly createFarmRequest: CreateFarmRequest;
}

/**
 * Request parameters for deleteFarmDocuments operation in FarmApi.
 * @export
 * @interface FarmApiDeleteFarmDocumentsRequest
 */
export interface FarmApiDeleteFarmDocumentsRequest {
  /**
   *
   * @type {string}
   * @memberof FarmApiDeleteFarmDocuments
   */
  readonly id: string;

  /**
   *
   * @type {Array<string>}
   * @memberof FarmApiDeleteFarmDocuments
   */
  readonly requestBody: Array<string>;

  /**
   *
   * @type {string}
   * @memberof FarmApiDeleteFarmDocuments
   */
  readonly farmId?: string;
}

/**
 * Request parameters for getFarmDetail operation in FarmApi.
 * @export
 * @interface FarmApiGetFarmDetailRequest
 */
export interface FarmApiGetFarmDetailRequest {
  /**
   *
   * @type {string}
   * @memberof FarmApiGetFarmDetail
   */
  readonly id: string;
}

/**
 * Request parameters for getFarmDocumentsPage operation in FarmApi.
 * @export
 * @interface FarmApiGetFarmDocumentsPageRequest
 */
export interface FarmApiGetFarmDocumentsPageRequest {
  /**
   *
   * @type {string}
   * @memberof FarmApiGetFarmDocumentsPage
   */
  readonly id: string;

  /**
   *
   * @type {string}
   * @memberof FarmApiGetFarmDocumentsPage
   */
  readonly farmId?: string;

  /**
   * Zero-based page index (0..N)
   * @type {number}
   * @memberof FarmApiGetFarmDocumentsPage
   */
  readonly page?: number;

  /**
   * The size of the page to be returned
   * @type {number}
   * @memberof FarmApiGetFarmDocumentsPage
   */
  readonly size?: number;

  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @type {Array<string>}
   * @memberof FarmApiGetFarmDocumentsPage
   */
  readonly sort?: Array<string>;
}

/**
 * Request parameters for getFarmPageByCropYear operation in FarmApi.
 * @export
 * @interface FarmApiGetFarmPageByCropYearRequest
 */
export interface FarmApiGetFarmPageByCropYearRequest {
  /**
   *
   * @type {string}
   * @memberof FarmApiGetFarmPageByCropYear
   */
  readonly cropYearId: string;

  /**
   * Zero-based page index (0..N)
   * @type {number}
   * @memberof FarmApiGetFarmPageByCropYear
   */
  readonly page?: number;

  /**
   * The size of the page to be returned
   * @type {number}
   * @memberof FarmApiGetFarmPageByCropYear
   */
  readonly size?: number;

  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @type {Array<string>}
   * @memberof FarmApiGetFarmPageByCropYear
   */
  readonly sort?: Array<string>;
}

/**
 * Request parameters for getFarmsPage operation in FarmApi.
 * @export
 * @interface FarmApiGetFarmsPageRequest
 */
export interface FarmApiGetFarmsPageRequest {
  /**
   *
   * @type {string}
   * @memberof FarmApiGetFarmsPage
   */
  readonly search?: string;

  /**
   * Zero-based page index (0..N)
   * @type {number}
   * @memberof FarmApiGetFarmsPage
   */
  readonly page?: number;

  /**
   * The size of the page to be returned
   * @type {number}
   * @memberof FarmApiGetFarmsPage
   */
  readonly size?: number;

  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @type {Array<string>}
   * @memberof FarmApiGetFarmsPage
   */
  readonly sort?: Array<string>;
}

/**
 * Request parameters for rejectFarm operation in FarmApi.
 * @export
 * @interface FarmApiRejectFarmRequest
 */
export interface FarmApiRejectFarmRequest {
  /**
   *
   * @type {FarmRequest}
   * @memberof FarmApiRejectFarm
   */
  readonly farmRequest: FarmRequest;
}

/**
 * Request parameters for removeUsersFromFarm operation in FarmApi.
 * @export
 * @interface FarmApiRemoveUsersFromFarmRequest
 */
export interface FarmApiRemoveUsersFromFarmRequest {
  /**
   *
   * @type {string}
   * @memberof FarmApiRemoveUsersFromFarm
   */
  readonly farmId: string;

  /**
   *
   * @type {RemoveUsersFromFarmRequest}
   * @memberof FarmApiRemoveUsersFromFarm
   */
  readonly removeUsersFromFarmRequest: RemoveUsersFromFarmRequest;
}

/**
 * Request parameters for setFarmToActive operation in FarmApi.
 * @export
 * @interface FarmApiSetFarmToActiveRequest
 */
export interface FarmApiSetFarmToActiveRequest {
  /**
   *
   * @type {string}
   * @memberof FarmApiSetFarmToActive
   */
  readonly id: string;
}

/**
 * Request parameters for update operation in FarmApi.
 * @export
 * @interface FarmApiUpdateRequest
 */
export interface FarmApiUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof FarmApiUpdate
   */
  readonly id: string;

  /**
   *
   * @type {UpdateFarmRequest}
   * @memberof FarmApiUpdate
   */
  readonly updateFarmRequest: UpdateFarmRequest;

  /**
   *
   * @type {string}
   * @memberof FarmApiUpdate
   */
  readonly farmId?: string;
}

/**
 * Request parameters for uploadFarmDocuments operation in FarmApi.
 * @export
 * @interface FarmApiUploadFarmDocumentsRequest
 */
export interface FarmApiUploadFarmDocumentsRequest {
  /**
   *
   * @type {any}
   * @memberof FarmApiUploadFarmDocuments
   */
  readonly file: any;
}

/**
 * FarmApi - object-oriented interface
 * @export
 * @class FarmApi
 * @extends {BaseAPI}
 */
export class FarmApi extends BaseAPI {
  /**
   *
   * @param {FarmApiCreateFarmRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FarmApi
   */
  public createFarm(
    requestParameters: FarmApiCreateFarmRequest,
    options?: AxiosRequestConfig
  ) {
    return FarmApiFp(this.configuration)
      .createFarm(requestParameters.createFarmRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {FarmApiDeleteFarmDocumentsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FarmApi
   */
  public deleteFarmDocuments(
    requestParameters: FarmApiDeleteFarmDocumentsRequest,
    options?: AxiosRequestConfig
  ) {
    return FarmApiFp(this.configuration)
      .deleteFarmDocuments(
        requestParameters.id,
        requestParameters.requestBody,
        requestParameters.farmId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {FarmApiGetFarmDetailRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FarmApi
   */
  public getFarmDetail(
    requestParameters: FarmApiGetFarmDetailRequest,
    options?: AxiosRequestConfig
  ) {
    return FarmApiFp(this.configuration)
      .getFarmDetail(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FarmApi
   */
  public getFarmDocumentsImportHeaders(options?: AxiosRequestConfig) {
    return FarmApiFp(this.configuration)
      .getFarmDocumentsImportHeaders(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {FarmApiGetFarmDocumentsPageRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FarmApi
   */
  public getFarmDocumentsPage(
    requestParameters: FarmApiGetFarmDocumentsPageRequest,
    options?: AxiosRequestConfig
  ) {
    return FarmApiFp(this.configuration)
      .getFarmDocumentsPage(
        requestParameters.id,
        requestParameters.farmId,
        requestParameters.page,
        requestParameters.size,
        requestParameters.sort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {FarmApiGetFarmPageByCropYearRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FarmApi
   */
  public getFarmPageByCropYear(
    requestParameters: FarmApiGetFarmPageByCropYearRequest,
    options?: AxiosRequestConfig
  ) {
    return FarmApiFp(this.configuration)
      .getFarmPageByCropYear(
        requestParameters.cropYearId,
        requestParameters.page,
        requestParameters.size,
        requestParameters.sort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {FarmApiGetFarmsPageRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FarmApi
   */
  public getFarmsPage(
    requestParameters: FarmApiGetFarmsPageRequest = {},
    options?: AxiosRequestConfig
  ) {
    return FarmApiFp(this.configuration)
      .getFarmsPage(
        requestParameters.search,
        requestParameters.page,
        requestParameters.size,
        requestParameters.sort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {FarmApiRejectFarmRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FarmApi
   */
  public rejectFarm(
    requestParameters: FarmApiRejectFarmRequest,
    options?: AxiosRequestConfig
  ) {
    return FarmApiFp(this.configuration)
      .rejectFarm(requestParameters.farmRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {FarmApiRemoveUsersFromFarmRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FarmApi
   */
  public removeUsersFromFarm(
    requestParameters: FarmApiRemoveUsersFromFarmRequest,
    options?: AxiosRequestConfig
  ) {
    return FarmApiFp(this.configuration)
      .removeUsersFromFarm(
        requestParameters.farmId,
        requestParameters.removeUsersFromFarmRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {FarmApiSetFarmToActiveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FarmApi
   */
  public setFarmToActive(
    requestParameters: FarmApiSetFarmToActiveRequest,
    options?: AxiosRequestConfig
  ) {
    return FarmApiFp(this.configuration)
      .setFarmToActive(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {FarmApiUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FarmApi
   */
  public update(
    requestParameters: FarmApiUpdateRequest,
    options?: AxiosRequestConfig
  ) {
    return FarmApiFp(this.configuration)
      .update(
        requestParameters.id,
        requestParameters.updateFarmRequest,
        requestParameters.farmId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {FarmApiUploadFarmDocumentsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FarmApi
   */
  public uploadFarmDocuments(
    requestParameters: FarmApiUploadFarmDocumentsRequest,
    options?: AxiosRequestConfig
  ) {
    return FarmApiFp(this.configuration)
      .uploadFarmDocuments(requestParameters.file, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
