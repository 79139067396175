import { FarmListDto } from "api";
import { NameWithPhoneColumn } from "components/table-columns";
import { ColumnProps, TableData } from "components/SortableTable";
import { Text, Tooltip } from "@chakra-ui/react";

export type AddableFarmsSortColumns = "doingBusinessAs" | "region.name";

export const addFarmsTableColumns = (data: FarmListDto[]): TableData[] =>
  data.map((row) => ({
    id: row.id,
    farm: (
      <NameWithPhoneColumn
        name={row.farm.name}
        phoneNumber={row.farm.phoneNumber}
        showAvatar={false}
      />
    ),
    region: (
      <Tooltip hasArrow label={row?.region?.name}>
        <Text variant="truncated">{row.region?.name ?? ""}</Text>
      </Tooltip>
    ),
  }));

type ColumnList = ColumnProps<AddableFarmsSortColumns>[];

export const baseSelectFarmsListColumns: ColumnList = [
  { heading: "Farm", id: "farm", sortKey: "doingBusinessAs" },
  { heading: "Region", id: "region", sortKey: "region.name" },
];

export const desktopFarmsListColumns: ColumnList = [
  ...baseSelectFarmsListColumns,
];
