import { ComponentStyleConfig } from "@chakra-ui/react";

const Badge: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: "24px",
    px: 2,
    py: 0,
    textStyle: "label",
    color: "white",
  },
  variants: {
    pending: {
      bg: "warningOrange",
    },
    needs_review: {
      bg: "errorRed",
    },
    active: {
      bg: "brand.800",
    },
    inactive: {
      color: "steelGray",
      bg: "greyFactor.1.5",
    },
    error: {
      bg: "errorRed",
    },
    void: {
      bg: "grayFactor.4",
    },
  },
};

export default Badge;
