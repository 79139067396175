import { ReactElement, useMemo } from "react";

import { HStack, Skeleton } from "@chakra-ui/react";

import BaseSummaryCards from "../BaseSummaryCards";
import SummaryStatCard, {
  estDollarsCommitted,
  percentOfTonsPriced,
  unpricedTons,
} from "components/SummaryStatCard";
import useCropYearDetail from "hooks/crop-year/useCropYearDetails";
import useCropYearSummary from "hooks/crop-year/useCropYearSummary";
import { baseStyle } from "../SummaryCards";
import { cropYearIsInPreHarvest } from "utils/cropYearUtils";
import { formatNumberWithDecimals } from "utils/formatFunctions";

const AllFarmsSummaryCards = ({
  cropYearId,
}: {
  cropYearId?: string;
}): ReactElement => {
  const { data: cropYearSummaryData, isLoading: cropYearSummaryIsLoading } =
    useCropYearSummary(cropYearId);
  const { data: cropYearDetail, isLoading: cropYearDetailIsLoding } =
    useCropYearDetail(cropYearId);

  const isPreHarvest = useMemo(
    () => cropYearIsInPreHarvest(cropYearDetail?.timeOfYear),
    [cropYearDetail?.timeOfYear]
  );

  const dataIsLoading =
    !cropYearId || cropYearSummaryIsLoading || cropYearDetailIsLoding;

  return (
    <HStack
      w={"100%"}
      spacing={6}
      justifyContent={"space-between"}
      flexWrap={"nowrap"}
    >
      <BaseSummaryCards
        isLoading={dataIsLoading}
        _totalAcres={cropYearSummaryData?.totalAcres}
        _totalFields={cropYearSummaryData?.totalFields}
      />
      {isPreHarvest && (
        <Skeleton isLoaded={!dataIsLoading} w="100%">
          <SummaryStatCard
            {...baseStyle}
            {...percentOfTonsPriced}
            value={cropYearSummaryData?.percentageOfTonsPriced ?? 0}
          />
        </Skeleton>
      )}
      {!isPreHarvest && (
        <Skeleton isLoaded={!dataIsLoading} w="100%">
          <SummaryStatCard
            {...baseStyle}
            {...unpricedTons}
            formatter={() =>
              formatNumberWithDecimals(
                cropYearSummaryData?.unpricedTons ?? 0,
                8
              )
            }
            value={cropYearSummaryData?.unpricedTons ?? 0}
          />
        </Skeleton>
      )}
      {!isPreHarvest && (
        <Skeleton isLoaded={!dataIsLoading} w="100%">
          <SummaryStatCard
            {...baseStyle}
            {...estDollarsCommitted}
            value={cropYearSummaryData?.estDollarsCommitted ?? 0}
          />
        </Skeleton>
      )}
    </HStack>
  );
};

export default AllFarmsSummaryCards;
