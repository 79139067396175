import { useFieldService } from "contexts/ServiceProvider";
import { useMutation } from "@tanstack/react-query";
import useRefetchFieldQueries from "./useRefetchFieldQueries";

const DELETE_FIELD_MUTATION_KEY = "delete-fields";

const useDeleteField = () => {
  const fieldService = useFieldService();
  const { refetch } = useRefetchFieldQueries();

  return useMutation({
    mutationKey: [DELETE_FIELD_MUTATION_KEY],
    mutationFn: (fieldId?: string) => {
      if (!fieldId) {
        throw new Error("Missing fieldId");
      }

      return fieldService.deleteField(fieldId);
    },
    onSuccess: () => refetch(),
  });
};

export default useDeleteField;
