import { useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Center,
  Spinner,
  Stack,
  useBreakpointValue,
} from "@chakra-ui/react";

import EmptyState from "components/EmptyState";
import FetchMoreWhenInView from "components/FetchMoreWhenInView";
import TextHeading from "components/TextHeading";
import SortableTable, {
  TABLE_MARGIN_TOP,
  TableData,
} from "components/SortableTable";
import {
  farmsListTableDataMapper,
  baseFarmsListColumns,
  desktopFarmsListColumns,
} from "./farmsListTableColumns";
import useUserRoleFlags from "hooks/auth/useUserRoleFlags";
import useAcknowledgeFarm from "hooks/farm/farm-detail/useAcknowledgeFarm";
import useFarmsList from "hooks/farm/useFarmsList";
import useCustomToast from "hooks/useCustomToast";
import useSort, {
  addSecondarySort,
  ColumnsWithSecondarySort,
  DEFAULT_SORT_DIRECTION,
  Sort,
} from "hooks/useSort";
import farmPaths from "routes/farm/farmPaths";
import { FarmListSortColumns } from "services/FarmService";
import { isFarmPending } from "utils/farmStatusUtils";
import { mobileStyleBreakpoint } from "utils/styleHelpers";

const adminFarmsListTableColumns = mobileStyleBreakpoint(
  baseFarmsListColumns,
  desktopFarmsListColumns,
  "md"
);
const columnsWithSecondarySort: ColumnsWithSecondarySort<FarmListSortColumns> =
  {
    status: { key: "doingBusinessAs", direction: DEFAULT_SORT_DIRECTION },
    "contact.firstName": {
      key: "doingBusinessAs",
      direction: DEFAULT_SORT_DIRECTION,
    },
    region: { key: "doingBusinessAs", direction: DEFAULT_SORT_DIRECTION },
  };

const defaultSort: Sort<FarmListSortColumns> = {
  key: "status",
  direction: DEFAULT_SORT_DIRECTION,
};

const FarmsListTable = ({ searchTerm }: { searchTerm: string }) => {
  const { isEmployeeAdmin } = useUserRoleFlags();
  const columns = useBreakpointValue(adminFarmsListTableColumns);
  const navigate = useNavigate();
  const { onSuccessToast, onErrorToast } = useCustomToast();
  const { onAcknowledgeAsync } = useAcknowledgeFarm();
  const { sort, toggleSort, setSort } =
    useSort<FarmListSortColumns>(defaultSort);
  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useFarmsList({
      search: searchTerm,
      sort: addSecondarySort(sort, columnsWithSecondarySort),
    });

  const farms = useMemo(
    () => data?.pages?.map(({ farms }) => farms).flat() ?? [],
    [data]
  );

  const tableData = useMemo(() => farmsListTableDataMapper(farms), [farms]);

  useEffect(() => {
    setSort(defaultSort);
  }, [setSort, searchTerm]);

  const emptyState =
    searchTerm.length === 0 ? (
      <EmptyState>There aren&apos;t any farms added yet.</EmptyState>
    ) : (
      <Center>
        <TextHeading headingType="h5">No Results Found</TextHeading>
      </Center>
    );

  const acknowledgeFarm = useCallback(
    (id: string) =>
      onAcknowledgeAsync({ farmId: id })
        .then(() => {
          onSuccessToast({ message: "Farm updates acknowledged" });
        })
        .catch(() => {
          onErrorToast({ message: "Failed to acknowledge farm updates" });
        }),
    [onAcknowledgeAsync, onErrorToast, onSuccessToast]
  );

  const onClickRow = useCallback(
    async ({ id, statusValue, regionId }: TableData) => {
      if (isEmployeeAdmin && isFarmPending(statusValue) && regionId) {
        await acknowledgeFarm(id);
      }
      navigate(`/${farmPaths.basePath}/${id}`);
    },
    [acknowledgeFarm, isEmployeeAdmin, navigate]
  );

  return (
    <Box h="100%">
      {isLoading ? (
        <Center h="100%">
          <Spinner />
        </Center>
      ) : (
        <Stack spacing={TABLE_MARGIN_TOP} w="100%">
          <SortableTable
            columns={columns}
            data={tableData}
            onClickRow={onClickRow}
            sort={sort}
            toggleSort={toggleSort}
          />
          {tableData.length === 0 && emptyState}
          {tableData.length > 0 && (
            <FetchMoreWhenInView
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
              key={`${data?.pages.length}`}
            />
          )}
        </Stack>
      )}
    </Box>
  );
};

export default FarmsListTable;
