import {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { basicUserInfoFormSchema } from "forms/user-registration/schemas/basicUserInfoFormSchema";
import { useSearchParams } from "react-router-dom";

type RegistrationFormContextValue = {
  basicUserInfo: BasicUserInfo;
  basicInfoComplete: boolean;
  onSetBasicUserInfo: (info: BasicUserInfo) => void;
  phoneNumber: PhoneNumber;
  onSetPhoneNumber: (phoneNumber: PhoneNumber) => void;
  profileImage: File | undefined;
  imagePreviewUrl: string | undefined;
  onSetProfileImage: (file: File) => void;
  onClearProfileImage: () => void;
  inviteToken: string | undefined;
};

const RegistrationFromContext = createContext<RegistrationFormContextValue>({
  basicUserInfo: {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  },
  basicInfoComplete: false,
  onSetBasicUserInfo: () => {
    return;
  },
  phoneNumber: "",
  onSetPhoneNumber: () => {
    return;
  },
  profileImage: undefined,
  imagePreviewUrl: undefined,
  onSetProfileImage: () => {
    return;
  },
  onClearProfileImage: () => {
    return;
  },
  inviteToken: undefined,
});

const RegistrationFormProvider = ({
  children,
  firstName,
  lastName,
  email,
  phoneNumber: _phoneNumber,
  basicInfoComplete: _basicInfoComplete = false,
  inviteToken: _inviteToken,
}: {
  children: ReactNode;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: PhoneNumber;
  basicInfoComplete?: boolean;
  inviteToken?: string;
}): ReactElement => {
  const [params] = useSearchParams();
  const [inviteToken] = useState<string | undefined>(
    _inviteToken ?? params.get("token") ?? undefined
  );
  const [basicUserInfo, setBasicUserInfo] = useState<BasicUserInfo>({
    firstName,
    lastName,
    email,
    password: undefined,
    confirmPassword: undefined,
  });
  const [basicInfoComplete, setBasicInfoComplete] =
    useState(_basicInfoComplete);
  const onSetBasicUserInfo = useCallback((info: BasicUserInfo) => {
    setBasicInfoComplete(basicUserInfoFormSchema.isValidSync(info));
    setBasicUserInfo(info);
  }, []);

  const [phoneNumber, setPhoneNumber] = useState<PhoneNumber>(_phoneNumber);

  const [profileImage, setProfileImage] = useState<File>();
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string>();
  const onSetProfileImage = useCallback((file: File) => {
    setProfileImage(file);
    setImagePreviewUrl(URL.createObjectURL(file));
  }, []);
  const onClearProfileImage = useCallback(() => {
    setProfileImage(undefined);
    setImagePreviewUrl(undefined);
  }, []);

  const value = useMemo<RegistrationFormContextValue>(
    () => ({
      basicUserInfo,
      basicInfoComplete,
      onSetBasicUserInfo,
      phoneNumber,
      onSetPhoneNumber: setPhoneNumber,
      profileImage,
      imagePreviewUrl,
      onSetProfileImage,
      onClearProfileImage,
      inviteToken,
    }),
    [
      basicUserInfo,
      basicInfoComplete,
      onSetBasicUserInfo,
      phoneNumber,
      profileImage,
      imagePreviewUrl,
      onSetProfileImage,
      onClearProfileImage,
      inviteToken,
    ]
  );

  return (
    <RegistrationFromContext.Provider value={value}>
      {children}
    </RegistrationFromContext.Provider>
  );
};

export default RegistrationFormProvider;

export const useRegistrationForm = (): RegistrationFormContextValue => {
  const registrationContext = useContext(RegistrationFromContext);
  if (!registrationContext) {
    throw new Error("Registration context provider is not mounted");
  }

  return registrationContext;
};
