import { ReactElement } from "react";
import { Outlet } from "react-router-dom";

import RegistrationFormProvider from "contexts/RegistrationFormContext";
import Layout from "./Layout";

const ParentProvider = (): ReactElement => (
  <Layout>
    <RegistrationFormProvider>
      <Outlet />
    </RegistrationFormProvider>
  </Layout>
);

export default ParentProvider;
