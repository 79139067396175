import { useEffect } from "react";

export const useScrollIntoView = (
  condition: boolean,
  elementIdentifier: string
) => {
  useEffect(() => {
    if (condition) {
      document.querySelector(elementIdentifier)?.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [condition, elementIdentifier]);
};
