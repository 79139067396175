import { forwardRef } from "react";
import { Text } from "@chakra-ui/react";

import StandardInput, { StandardInputProps } from "../StandardInput";
import { preventNonNumericCharacters } from "utils/inputUtils";

export const MilesAddon = () => (
  <Text color="steelGray" mr="8px">
    miles
  </Text>
);
const DistanceToSiteInput = forwardRef<HTMLInputElement, StandardInputProps>(
  (props, ref) => {
    return (
      <StandardInput
        {...props}
        type="number"
        hideAddonBackground={true}
        rightAddon={<MilesAddon />}
        onWheel={(e) => e.currentTarget.blur()}
        onKeyDown={preventNonNumericCharacters}
        ref={ref}
      />
    );
  }
);

DistanceToSiteInput.displayName = "DistanceToSiteInput";

export default DistanceToSiteInput;
