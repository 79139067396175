import { containsNonNumericCharacters } from "./stringUtils";

const allowedKeys = ["Backspace", "ArrowLeft", "ArrowRight", "Tab"];

export const preventNonNumericCharacters = (
  e: React.KeyboardEvent<HTMLInputElement>,
  extraAllowedKeys: string[] = []
) => {
  if (
    ![...allowedKeys, ...extraAllowedKeys].includes(e.key) &&
    containsNonNumericCharacters(e.key)
  ) {
    e.preventDefault();
  }
};
export const onlyWholeNumbers = (
  e: React.KeyboardEvent<HTMLInputElement>,
  extraAllowedKeys: string[] = []
) => {
  if (
    ![...allowedKeys, ...extraAllowedKeys].includes(e.key) &&
    containsNonNumericCharacters(e.key, ["."])
  ) {
    e.preventDefault();
  }
};
