import { ReactElement } from "react";

import { useDisclosure } from "@chakra-ui/react";

import useAssignFarmToManureYear from "hooks/api/manure-year/useAssignFarmToManureYear";
import useSelectedManureYearId from "hooks/useSelectedManureYearId";
import useCustomToast from "hooks/useCustomToast";
import useNavigateWithQuery from "hooks/useNavigateWithQuery";
import manurePaths from "routes/manure";
import { AddFarmsToYearButton } from "components/AddFarmsToYear";

const AddFarmsToManureYearButton = ({
  year,
  farmIds,
  isDisabled,
  onSuccess,
}: {
  year: number | undefined;
  farmIds: Array<string>;
  isDisabled: boolean;
  onSuccess(): void;
}): ReactElement => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedManureYearId] = useSelectedManureYearId();
  const { onSuccessToast, onErrorToast } = useCustomToast();
  const navigate = useNavigateWithQuery();

  const { mutateAsync, isPending } =
    useAssignFarmToManureYear(selectedManureYearId);

  const onAddFarms = () => {
    if (farmIds.length > 0) {
      mutateAsync(farmIds)
        .then(() => {
          onSuccess();
          onSuccessToast({
            title: "Farms Added Successfully",
            message: `Selected farms have been added to Manure ${year}`,
          });
          onClose();
          navigate(`/${manurePaths.basePath}/${manurePaths.children.allFarms}`);
        })
        .catch(() => {
          onErrorToast({
            title: "Error",
            message: `Selected farms were not added to Manure ${year} Program, please try again`,
          });
          onClose();
        });
    }
  };

  return (
    <AddFarmsToYearButton
      programType="Manure"
      year={year}
      isDisabled={isDisabled}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      onAddFarms={onAddFarms}
      isLoading={isPending}
    />
  );
};

export default AddFarmsToManureYearButton;
