import * as yup from "yup";
import {
  MIN_PASSWORD_LENGTH,
  REGEX_PASSWORD_CRITERIA,
} from "constants/registrationForm";

export const basicUserInfoFormSchema = yup
  .object({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    email: yup
      .string()
      .email("Must be a valid email address")
      .required("Email is required"),
    password: yup
      .string()
      .required("Password is required")
      .min(MIN_PASSWORD_LENGTH, "Password must be at least 12 characters long")
      .matches(
        REGEX_PASSWORD_CRITERIA.CONTAINS_LOWERCASE_LETTER,
        "Password must contain lowercase letter"
      )
      .matches(
        REGEX_PASSWORD_CRITERIA.CONTAINS_UPPERCASE_LETTER,
        "Password must contain uppercase letter"
      )
      .matches(
        REGEX_PASSWORD_CRITERIA.CONTAINS_NUMBER,
        "Password must contain a number"
      )
      .matches(
        REGEX_PASSWORD_CRITERIA.CONTAINS_SPECIAL_CHARACTER,
        "Password must contain a special character"
      ),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password")], "Passwords don't match")
      .required("Confirm password is required"),
  })
  .required();
