import NewForwardPricingContractForm from "forms/forward-pricing/NewForwardPricingContractForm";
import useCropYearDetail from "hooks/crop-year/useCropYearDetails";
import useFuturesPriceError from "hooks/futures/useFuturesPriceError";
import useGetForwardPricingLockedInData from "hooks/pricing-option/useGetForwardPricingLockedInData";
import useSelectedCropYearId from "hooks/useSelectedCropYearId";
import useSelectedFarmId from "hooks/useSelectedFarmId";
import PricingContractPageLayout from "../PricingContractPageLayout/PricingContractPageLayout";

const ForwardPricingContractPage = () => {
  const [selectedCropYearId] = useSelectedCropYearId();
  const [selectedFarmId] = useSelectedFarmId();
  const { data: cropYearDetail, isLoading: isCropYearDetailLoading } =
    useCropYearDetail(selectedCropYearId);
  const {
    data: currentForwardPricingData,
    isLoading: isCurrentForwardPricingLoading,
    isRefetching: currentFowardPricingDataIsRefetching,
    isStale: isCurrentForwardPricingStale,
    refetch: refetchCurrentForwardPricing,
    isError,
  } = useGetForwardPricingLockedInData(selectedCropYearId, selectedFarmId);
  useFuturesPriceError(isError);

  return (
    <PricingContractPageLayout
      contractHeader="Forward Contract"
      isLoading={
        isCurrentForwardPricingLoading ||
        isCropYearDetailLoading ||
        currentFowardPricingDataIsRefetching
      }
      loadingStateText={"Fetching Prices..."}
    >
      <NewForwardPricingContractForm
        cropYearDetail={cropYearDetail}
        currentForwardPricingData={currentForwardPricingData}
        cropYearId={selectedCropYearId}
        farmId={selectedFarmId}
        isCurrentForwardPricingStale={isCurrentForwardPricingStale}
        refetchCurrentForwardPricing={refetchCurrentForwardPricing}
      />
    </PricingContractPageLayout>
  );
};

export default ForwardPricingContractPage;
