import { StandardInput, PasswordInput, FormInputs } from "components/inputs";
import PasswordPopover from "components/PasswordPopover";

const BasicUserInfoInputs = ({
  isLoading = false,
}: {
  isLoading?: boolean;
}) => (
  <FormInputs
    inputs={[
      {
        id: "firstName",
        label: "First Name",
        component: StandardInput,
        isRequired: true,
        isDisabled: isLoading,
      },
      {
        id: "lastName",
        label: "Last Name",
        component: StandardInput,
        isRequired: true,
        isDisabled: isLoading,
      },
      {
        id: "email",
        label: "Email",
        component: StandardInput,
        isDisabled: true,
        isRequired: true,
      },
      {
        id: "password",
        label: "Password",
        component: PasswordPopover,
        isRequired: true,
      },
      {
        id: "confirmPassword",
        label: "Confirm Password",
        component: PasswordInput,
        isRequired: true,
      },
    ]}
  />
);

export default BasicUserInfoInputs;
