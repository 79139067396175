import { ReactElement, useCallback, useMemo } from "react";

import { Stack, UseDisclosureProps } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { AxiosError } from "axios";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { FieldResponse, MinimumFarmListDto } from "api";
import TextHeading from "components/TextHeading";
import { SelectOption } from "components/Select";
import { useCropYearContext } from "contexts/CropYearContext";
import SingleSplit from "forms/field-detail/SplitsDialog/SingleSplit/SingleSplit";
import { createSplitsSchema } from "forms/schemas/splitsSchema";
import { getAffectedContractsErrorMessage } from "../FieldDetailForm";
import useConfirmationAlert from "hooks/alerts/useConfirmationAlert";
import { AlertProps } from "hooks/alerts/useInfoAlert";
import useUpdateField from "hooks/field/useUpdateField";
import useCustomToast from "hooks/useCustomToast";
import useSelectedFarmId, {
  FARM_ID_QUERY_STRING_KEY,
} from "hooks/useSelectedFarmId";
import fieldPaths from "routes/field/fieldPaths";
import SplitModal from "./SplitModal";
import { formatNumberWithDecimals } from "utils/formatFunctions";

type AddSplitProps = {
  field: FieldResponse;
  allFarms?: MinimumFarmListDto[];
  onOpenErrorMessageModal: (props: AlertProps) => void;
};

type PartialSplit = {
  farmId: string;
  split: number;
};

export type SingleFieldSplit = {
  farm: Partial<SelectOption> | undefined;
  split: string;
};

export type SplitFormProps = {
  splits: SingleFieldSplit[];
};

const EMPTY_SPLIT: SingleFieldSplit = {
  farm: { value: undefined, label: undefined },
  split: "0",
};

const SplitsDialog = ({
  field,
  isOpen = false,
  allFarms = [],
  onClose = () => {
    return;
  },
  onOpenErrorMessageModal,
}: AddSplitProps & Pick<UseDisclosureProps, "isOpen" | "onClose">) => {
  const { cropYearDetail, isPreHarvest } = useCropYearContext();
  const [selectedFarmId] = useSelectedFarmId();
  const otherFieldSplits =
    field.splits.filter(({ farmId }) => farmId !== selectedFarmId) ?? []; // Do not include the select farm in the splits

  const methods = useForm<SplitFormProps>({
    resolver: yupResolver(createSplitsSchema(isPreHarvest)),
    defaultValues: {
      splits: otherFieldSplits.length
        ? otherFieldSplits.map(({ farmId, split }) => {
            const farm = allFarms.find((farm) => farm.id === farmId);

            return {
              farm: farm
                ? {
                    value: farm.id,
                    label: farm.doingBusinessAs,
                  }
                : undefined,
              split: formatNumberWithDecimals(split),
            };
          })
        : [EMPTY_SPLIT],
    },
  });

  const splits = methods.watch("splits");
  const {
    formState: { isDirty },
  } = methods;

  /*
   The farm is selectable if it's not in the list of farms
   that have been selected.
   */
  const alreadyUsedFarms = [
    ...(splits?.map(({ farm }) => farm?.value) ?? []),
    selectedFarmId,
  ];

  const selectableFarms =
    allFarms?.filter((farm) => !alreadyUsedFarms.includes(farm.id)) ?? [];

  const remainingSplitPercent =
    splits?.reduce(
      (difference, { split: splitPercent }) => {
        const splitAsNumber = Number(splitPercent);
        if (splitAsNumber < 0) {
          return difference;
        }

        return difference - Number(splitPercent);
      },

      100
    ) ?? 100;

  const estimatedRemainingAcres = useMemo(
    () => (remainingSplitPercent / 100) * (field?.totalAcres ?? 0),
    [field, remainingSplitPercent]
  );

  const isAddSplitDisabled =
    (splits?.some(({ farm }) => !farm?.value) ?? true) || splits.length >= 9;

  const { mutateAsync: updateField } = useUpdateField(field?.id);

  const { onSuccessToast, onErrorToast } = useCustomToast();

  const navigate = useNavigate();

  const onSave = useCallback(() => {
    if (selectedFarmId && cropYearDetail && field) {
      const validSplits = [
        ...splits
          .filter(({ farm, split }) => !!farm?.value && parseFloat(split) !== 0)
          .map((split) => ({
            farmId: split.farm?.value,
            split: parseFloat(split.split),
          })),
        ...(remainingSplitPercent > 0
          ? [
              {
                split: parseFloat(remainingSplitPercent.toFixed(2)),
                farmId: selectedFarmId,
              },
            ]
          : []),
      ];

      return updateField({
        ...field,
        dairyId: field.dairy?.id,
        splits: validSplits as PartialSplit[],
        cropYearId: cropYearDetail.id,
        fieldNumber: field.fieldNumber ?? "",
        totalAcres: field.totalAcres ?? 0,
      })
        .then(({ splits }) => {
          if (splits.length === 1 && splits?.[0].split === 100) {
            navigate(
              `/${fieldPaths.basePath}/${field.id}/?${FARM_ID_QUERY_STRING_KEY}=${splits?.[0].farmId}&cropYearId=${cropYearDetail.id}`
            );
          }
          onSuccessToast({ message: "Splits saved successfully" });
          onClose();
        })
        .catch(
          (
            error: AxiosError<
              Record<"error" | "affectedFarms", any> | undefined
            >
          ) => {
            if (error.response?.status === 409) {
              const affectedFarmsList = error.response?.data?.affectedFarms;
              const affectedContractsErrorMessage =
                getAffectedContractsErrorMessage(affectedFarmsList);

              onOpenErrorMessageModal({
                msg: affectedContractsErrorMessage,
              });

              onClose();
            }

            onErrorToast({
              message: "Failed to update splits",
            });
          }
        );
    }

    return Promise.resolve();
  }, [
    selectedFarmId,
    cropYearDetail,
    field,
    splits,
    remainingSplitPercent,
    updateField,
    onSuccessToast,
    onClose,
    navigate,
    onErrorToast,
    onOpenErrorMessageModal,
  ]);
  const { fields, append, remove, update } = useFieldArray({
    name: "splits",
    control: methods.control,
  });

  const displayConfirmationAlertTitle =
    estimatedRemainingAcres === 0
      ? `Transfer field Ownership?`
      : `Save with split`;

  const displayConfirmationAlertMessage =
    estimatedRemainingAcres === 0
      ? `This action will transfer 100% of this field to another farm. Are you sure you want to continue?`
      : `This field is split with another farm(s). Saving will automatically create a new field for the farms included in the split. Do you wish to continue?`;

  const {
    onOpen,
    alert,
    isOpen: isConfirmationAlertOpen,
  } = useConfirmationAlert({
    title: displayConfirmationAlertTitle,
    msg: displayConfirmationAlertMessage,
    confirmButtonText: "Save and Continue",
    onConfirmAsync: onSave,
  });

  return (
    <>
      {isConfirmationAlertOpen ? (
        alert
      ) : (
        <FormProvider {...methods}>
          <SplitModal
            onSave={onOpen}
            isOpen={isOpen}
            onClose={onClose}
            isAddSplitDisabled={isAddSplitDisabled}
            handleAddSplit={() => append(EMPTY_SPLIT)}
            remainingSplitPercent={remainingSplitPercent}
            estimatedRemainingAcres={estimatedRemainingAcres}
            isSubmitDisabled={!isDirty}
          >
            <Stack spacing={6}>
              <TextHeading headingType={"h5"}>Split with</TextHeading>
              {fields.map(
                (fieldSplit, index): ReactElement => (
                  <SingleSplit
                    key={fieldSplit.id}
                    fieldSplitDefault={fieldSplit}
                    index={index}
                    selectableFarms={selectableFarms}
                    onRemove={() => {
                      if (fields.length === 1) {
                        update(0, EMPTY_SPLIT);
                      } else {
                        remove(index);
                      }
                    }}
                    fieldInfo={{
                      totalAcres: field?.totalAcres ?? 0,
                    }}
                    isRemoveSplitDisabled={
                      fields.length === 1 && !fields[0].farm?.value
                    }
                  />
                )
              )}
            </Stack>
          </SplitModal>
        </FormProvider>
      )}
    </>
  );
};

export default SplitsDialog;
