import {
  CreateUserRequest,
  DataListMinimumUserDto,
  UpdateUserRequest,
  UserApi,
  UserApiAssignableRolesRequest,
  UserApiInviteRequest,
  UserInviteResponse,
  UserResponse,
} from "api";
import { RoleListResponse } from "api/models/role-list-response";
import { extractNumbers } from "utils/stringUtils";
import {
  PaginationMeta,
  QueryParams,
  extractResponseData,
  extractResponseDataWithMeta,
  transformQueryParamsForRequest,
  processResponse,
} from "./apiHelpers";
import { constructApiControllerInstance } from "./customApiConfig";

export type UserListSortColumns = "status" | "firstName" | "email";

class UserService {
  userController!: UserApi;
  constructor() {
    this.userController = constructApiControllerInstance(UserApi);
  }
  getInvite(token: string): Promise<UserInviteResponse> {
    return this.userController
      .getUserInviteDetails({
        token,
      })
      .then(extractResponseData);
  }

  getRoles(request: UserApiAssignableRolesRequest): Promise<RoleListResponse> {
    return this.userController
      .assignableRoles(request)
      .then(extractResponseData);
  }

  getRolesForUser(userId: string, farmId?: string): Promise<RoleListResponse> {
    return this.userController
      .assignableRolesForUser({ id: userId, farmId })
      .then(extractResponseData);
  }

  createNewUserInvite(newUserInfo: UserApiInviteRequest): Promise<string> {
    return this.userController.invite(newUserInfo).then(processResponse);
  }

  createUser(userInfo: CreateUserRequest): Promise<UserResponse> {
    return this.userController
      .createUser({
        createUserRequest: {
          ...userInfo,
          phoneNumber: extractNumbers(userInfo.phoneNumber),
        },
      })
      .then(extractResponseData);
  }

  getUsers(
    queryParams?: QueryParams & { farmId?: string }
  ): Promise<{ data: UserResponse[]; meta: PaginationMeta }> {
    return this.userController
      .getUsers({
        ...transformQueryParamsForRequest(queryParams),
        farmId: queryParams?.farmId,
      })
      .then(extractResponseDataWithMeta);
  }
  getFarmAdminUsers(farmId: string): Promise<DataListMinimumUserDto> {
    return this.userController
      .getFarmAdminUsers({
        farmId,
        id: farmId,
      })
      .then(processResponse);
  }

  getUserDetails(id: string, farmId?: string): Promise<UserResponse> {
    return this.userController
      .getUser({ id, farmId })
      .then(extractResponseData);
  }

  resendInvites(userIds: string[], farmId?: string): Promise<string> {
    return this.userController
      .resendInvites({ resendUserInvitesRequest: { userIds }, farmId })
      .then(processResponse);
  }

  updateUser(id: string, data: UpdateUserRequest): Promise<UserResponse> {
    return this.userController
      .updateUser({ updateUserRequest: data, id })
      .then(extractResponseData);
  }
}

export default UserService;
