import { ReactElement, useEffect, useMemo } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Divider, Stack, Text } from "@chakra-ui/react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import {
  AveragePricingContractRequest,
  CropYearDetailResponse,
  CropYearRegionDto,
  PricingOptionResponse,
} from "api";

import SubmitButton from "components/SubmitButton";
import { SelectOption } from "components/Select";
import {
  DEFER_PAYMENT_OPTION_NO,
  DEFER_PAYMENT_OPTION_YES,
} from "components/inputs/DeferPaymentSelectInput";
import { AgreedByLineItem } from "forms/pricing/average-pricing/AveragePricingForm/AgreedBy";
import Multiplier from "forms/pricing/labeled-value/Multiplier";
import useUserRoleFlags from "hooks/auth/useUserRoleFlags";
import useFormValidation from "hooks/useFormValidation";
import AveragePricingFormInputs from "./AveragePricingInputs";
import { averagePricingSchema } from "./averagePricingSchema";
import { parseDate } from "utils/dateUtils";
import { getIsDeferPayment } from "utils/getIsDeferPayment";

export type AveragePricingFormData = {
  percentageToSell: number;
  deferPayment: SelectOption;
  paymentDate?: Date;
};

type AveragePricingFormProps = {
  cropYearDetail: Pick<
    CropYearDetailResponse,
    "defermentDateStart" | "firstPaymentDateWithoutDeferral" | "id"
  >;
  remainingPercent?: number;
  onCancel?: () => void;
  onSave: SubmitHandler<AveragePricingContractRequest>;
  pricingData?: PricingOptionResponse;
  regionDetails: CropYearRegionDto;
};

const AveragePricingForm = ({
  cropYearDetail: { defermentDateStart, firstPaymentDateWithoutDeferral },
  remainingPercent = 0,
  onCancel = () => {
    return;
  },
  onSave,
  pricingData,
  regionDetails,
}: AveragePricingFormProps): ReactElement => {
  const { isFarmAdmin } = useUserRoleFlags();
  const defaultDefermentDate = useMemo(
    () => (defermentDateStart ? parseDate(defermentDateStart) : undefined),
    [defermentDateStart]
  );

  const defaultFirstPaymentDateWithoutDeferral = useMemo(
    () =>
      firstPaymentDateWithoutDeferral
        ? parseDate(firstPaymentDateWithoutDeferral)
        : undefined,
    [firstPaymentDateWithoutDeferral]
  );

  const defaultValues: AveragePricingFormData = useMemo(() => {
    let deferPayment = DEFER_PAYMENT_OPTION_YES;
    if (pricingData) {
      deferPayment = pricingData.deferPayment
        ? DEFER_PAYMENT_OPTION_YES
        : DEFER_PAYMENT_OPTION_NO;
    }

    return {
      percentageToSell: pricingData?.percentageToSell ?? 0,
      deferPayment: deferPayment,
      paymentDate: pricingData?.defermentDate
        ? parseDate(pricingData.defermentDate)
        : defaultDefermentDate,
    };
  }, [pricingData, defaultDefermentDate]);

  const methods = useForm<AveragePricingFormData>({
    resolver: yupResolver(
      averagePricingSchema(remainingPercent, defaultDefermentDate)
    ),
    defaultValues,
  });

  const {
    setError,
    clearErrors,
    watch,
    getValues,
    handleSubmit,
    formState: { isDirty, isSubmitting },
  } = methods;

  const percentageToSell = watch("percentageToSell");

  useEffect(() => {
    if (percentageToSell > 100) {
      setError("percentageToSell", {
        message: "Percentage exceeds available percent to price",
        type: "validate",
      });
    } else if (percentageToSell > remainingPercent) {
      setError("percentageToSell", {
        message: `${remainingPercent}% available`,
        type: "max",
      });
    } else {
      clearErrors();
    }
  }, [percentageToSell, setError, remainingPercent, clearErrors]);

  const requiredFieldsNotFilled = useFormValidation(watch());

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit((data) =>
          onSave({
            defermentDate: getIsDeferPayment(data.deferPayment.value)
              ? data.paymentDate?.toISOString()
              : undefined,
            deferPayment: getIsDeferPayment(data.deferPayment.value),
            percentageToSell: data.percentageToSell,
          })
        )}
      >
        <Stack spacing={6}>
          <Stack spacing={4}>
            <>
              <AveragePricingFormInputs
                readOnlyPercentageToSell={!isFarmAdmin}
                defaultDefermentDate={defaultDefermentDate}
                deferPayment={getIsDeferPayment(
                  getValues("deferPayment").value
                )}
                firstPaymentDateWithoutReferral={
                  defaultFirstPaymentDateWithoutDeferral
                }
                percentageToSell={percentageToSell}
              />
              <Multiplier value={regionDetails.multiplier} />
            </>
          </Stack>
          {pricingData?.auditLog && pricingData.auditLog.length > 0 && (
            <>
              <Divider />
              <Stack spacing={4}>
                <Text alignSelf={"flex-start"} fontWeight={"bold"}>
                  Last Updated
                </Text>
                {/* Need to pick the latest item here which its ordered by latest first */}
                <AgreedByLineItem entry={pricingData.auditLog[0]} />
              </Stack>
            </>
          )}
          <Stack spacing={4}>
            <SubmitButton
              h={12}
              submitDisabled={requiredFieldsNotFilled || !isDirty}
              isSubmitting={isSubmitting}
              buttonText="Submit"
              w="100%"
            />
            <Button variant="outline" onClick={onCancel}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default AveragePricingForm;
