import { SetStateAction, useCallback, useMemo } from "react";

import { SelectOption } from "components/Select";
import YearSelect from "components/YearSelect";
import useGetHaylageYear from "hooks/api/haylage-year/useGetHaylageYear";
import useGetHaylageYears from "hooks/api/haylage-year/useGetHaylageYears";
import useSelectedHaylageYearId from "hooks/useSelectedHaylageYearId";

const HaylageYearSelect = () => {
  const { data, isLoading } = useGetHaylageYears();
  const [selectedHaylageYearId, setSelectedHaylageYearId] =
    useSelectedHaylageYearId();
  const { data: haylageYearData, isLoading: isHaylageYearDataLoading } =
    useGetHaylageYear(selectedHaylageYearId);

  const options: SelectOption[] = useMemo(
    () =>
      data?.haylageYears.map((haylageYear) => ({
        label: `${haylageYear.year}`,
        value: haylageYear.id,
      })) ?? [],
    [data?.haylageYears]
  );

  const onSelectOption = useCallback(
    (option: SetStateAction<SelectOption | undefined>) => {
      const nextHaylageYear = data?.haylageYears.find(
        (_haylageYear) =>
          _haylageYear.id === (option as SelectOption | undefined)?.value
      );
      if (nextHaylageYear) {
        setSelectedHaylageYearId(nextHaylageYear.id);
      }
    },
    [data?.haylageYears, setSelectedHaylageYearId]
  );

  return (
    <YearSelect
      options={options}
      yearId={haylageYearData?.id}
      name="haylageYearSelect"
      isLoading={isLoading || isHaylageYearDataLoading}
      setSelectedOption={onSelectOption}
    />
  );
};

export default HaylageYearSelect;
