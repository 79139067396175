import { useCallback } from "react";

import { useQueryClient } from "@tanstack/react-query";

import { GET_CROP_YEAR_ADDABLE_FARMS_QUERY_KEY } from "hooks/crop-year/useAddableFarms";
import { USE_CROP_YEAR_FARMS_QUERY_KEY } from "hooks/crop-year/useCropYearFarms";
import { GET_CROP_YEAR_SUMMARY_QUERY_KEY } from "hooks/crop-year/useCropYearSummary";
import { GET_FARM_CROP_YEAR_SUMMARY_QUERY_KEY } from "hooks/crop-year/useFarmCropYearSummary";
import { GET_FARM_CROP_YEAR_PRICING_OPTIONS } from "hooks/pricing-option/useFarmCropYearPricingOptions";
import { GET_FIELDS_QUERY_KEY } from "hooks/field/useFieldsList";
import { GET_FIELD_ADDABLE_FARMS_QUERY_KEY } from "hooks/field/useGetFieldAddableFarms";
import { GET_PRICING_OPTION_DETAILS_QUERY_KEY } from "../pricing-option/useGetPricingOptionDetails";
import { GET_EXISTING_AVERAGE_PRICING_QUERY_KEY } from "../pricing-option/useGetExistingAveragePricing";
import { GET_CROP_YEAR_PRICING_OPTIONS } from "hooks/pricing-option/useCropYearPricingOptions";

const useDashboardRefetchQueries = () => {
  const queryClient = useQueryClient();
  const refetch = useCallback(
    () =>
      Promise.all([
        queryClient.refetchQueries({
          queryKey: [GET_CROP_YEAR_ADDABLE_FARMS_QUERY_KEY],
        }),
        queryClient.refetchQueries({
          queryKey: [GET_FIELD_ADDABLE_FARMS_QUERY_KEY],
        }),
        queryClient.refetchQueries({
          queryKey: [USE_CROP_YEAR_FARMS_QUERY_KEY],
        }),
        queryClient.refetchQueries({
          queryKey: [GET_CROP_YEAR_SUMMARY_QUERY_KEY],
        }),
        queryClient.refetchQueries({
          queryKey: [GET_FARM_CROP_YEAR_PRICING_OPTIONS],
        }),
        queryClient.refetchQueries({
          queryKey: [GET_FARM_CROP_YEAR_SUMMARY_QUERY_KEY],
        }),
        queryClient.refetchQueries({
          queryKey: [GET_FIELDS_QUERY_KEY],
        }),
        queryClient.refetchQueries({
          queryKey: [GET_PRICING_OPTION_DETAILS_QUERY_KEY],
        }),
        queryClient.refetchQueries({
          queryKey: [GET_EXISTING_AVERAGE_PRICING_QUERY_KEY],
        }),
        queryClient.refetchQueries({
          queryKey: [GET_CROP_YEAR_PRICING_OPTIONS],
        }),
      ]),
    [queryClient]
  );

  return { refetch };
};

export default useDashboardRefetchQueries;
