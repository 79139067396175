import * as yup from "yup";

import { baseForwardContractSchema } from "forms/schemas/baseForwardContractSchema";

export const manualForwardContractSchema = (
  defaultDefermentDate: Date,
  remainingPercentAvailable: number
) =>
  baseForwardContractSchema(
    defaultDefermentDate,
    remainingPercentAvailable
  ).shape({
    cashPrice: yup
      .number()
      .moreThan(0, "Cash price must be more than 0")
      .lessThan(100, "Cash price must be less than 100")
      .typeError("Cash price must be a number")
      .required("Cash price is required"),
    dateOfContractCreation: yup
      .date()
      .required("Date of contract creation is required"),
  });
