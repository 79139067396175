import { useParams } from "react-router-dom";
import { Controller } from "react-hook-form";
import {
  HStack,
  Stack,
  Text,
  useDisclosure,
  useRadioGroup,
} from "@chakra-ui/react";

import {
  CropYearDetailResponse,
  CropYearDetailResponseTimeOfYearEnum,
} from "api";
import FormSkeletonLoadingState from "components/FormSkeletonLoadingState";
import TimeOfYearRadio from "forms/crop-year-detail/TimeOfYearRadio";
import useCropYearDetail from "hooks/crop-year/useCropYearDetails";
import { cropYearIsInPreHarvest } from "utils/cropYearUtils";
import PostHarvestConfirmationModal from "../PostHarvestConfirmationModal";

const TimeOfYearToggleInputs = ({
  value,
  isDisabled,
  isPostHarvestDisabled = false,
  onChange,
}: {
  value: string;
  isDisabled: boolean;
  isPostHarvestDisabled?: boolean;
  onChange: (value: CropYearDetailResponseTimeOfYearEnum) => void;
}) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "time-of-year",
    value,
    onChange,
    isDisabled,
  });

  return (
    <HStack spacing={4} {...getRootProps()}>
      <TimeOfYearRadio
        {...getRadioProps({
          value: CropYearDetailResponseTimeOfYearEnum.PreHarvest,
        })}
        text="Pre-Harvest"
        isDisabled={
          isDisabled ||
          value === CropYearDetailResponseTimeOfYearEnum.PostHarvest
        }
      />
      <TimeOfYearRadio
        {...getRadioProps({
          value: CropYearDetailResponseTimeOfYearEnum.PostHarvest,
        })}
        text="Post-Harvest"
        isDisabled={isDisabled || isPostHarvestDisabled}
      />
    </HStack>
  );
};

const TimeOfYearForm = ({
  cropYearDetail,
  isDisabled,
}: {
  cropYearDetail?: CropYearDetailResponse;
  isDisabled: boolean;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      {isOpen && (
        <PostHarvestConfirmationModal
          cropYearId={cropYearDetail?.id}
          isOpen={isOpen}
          onCancel={onClose}
          onClose={onClose}
        />
      )}
      <Controller
        name="timeOfYear"
        render={({ field }) => (
          <TimeOfYearToggleInputs
            value={field.value}
            isDisabled={isDisabled}
            onChange={(value) => {
              !cropYearIsInPreHarvest(value) ? onOpen() : field.onChange(value);
            }}
            isPostHarvestDisabled={
              !cropYearDetail || !!process.env.REACT_APP_DISABLE_POST_HARVEST
            }
          />
        )}
      />
    </>
  );
};

const TimeOfYearToggle = ({ isDisabled = false }: { isDisabled: boolean }) => {
  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useCropYearDetail(id);

  if (isLoading) {
    return <FormSkeletonLoadingState rowCount={1} />;
  }

  return (
    <Stack spacing={3}>
      <HStack>
        <Text fontWeight="bold">Time of Year</Text>
        <Text ml={1} color="errorRed">
          *
        </Text>
      </HStack>
      <Text fontSize="14px">Switch to</Text>
      <TimeOfYearForm cropYearDetail={data} isDisabled={isDisabled} />
    </Stack>
  );
};

export default TimeOfYearToggle;
