import {
  CreateCropYearRequest,
  CropYearApi,
  CropYearApiAssignFarmToCropYearRequest,
  CropYearApiGetCropYearDetailRequest,
  CropYearApiGetFarmsNotAssignedToCropYearRequest,
  CropYearApiGetMonthlyAverageListByMonthRequest,
  CropYearApiListCropYearsRequest,
  CropYearDetailResponse,
  CropYearFarmDto,
  CropYearFarmSummaryDto,
  CropYearListDto,
  CropYearSummaryDto,
  FarmDocumentDto,
  MinimumFarmListDto,
  NextCropYearDetailResponse,
  SetMonthlyAveragePriceRequest,
  UpdateCropYearRequest,
} from "api";
import {
  extractResponseData,
  extractResponseDataWithMeta,
  PaginationMeta,
  processResponse,
  QueryParams,
  transformQueryParamsForRequest,
} from "./apiHelpers";
import { constructApiControllerInstance } from "./customApiConfig";

export type GetCropYearsResponse = {
  data: CropYearListDto[];
  meta: PaginationMeta;
};

export type GetCropYearFarmsResponse = {
  data: CropYearFarmDto[];
  meta: PaginationMeta;
};

export type CropYearFarmsSortColumns =
  | "farm.doingBusinessAs"
  | "numberOfFields"
  | "totalAcres"
  | "farm.region.name";

class CropYearService {
  cropYearController!: CropYearApi;
  constructor() {
    this.cropYearController = constructApiControllerInstance(CropYearApi);
  }

  getCropYears(
    request: CropYearApiListCropYearsRequest
  ): Promise<GetCropYearsResponse> {
    return this.cropYearController
      .listCropYears(request)
      .then(extractResponseDataWithMeta);
  }

  getCropYearDetail(
    request: CropYearApiGetCropYearDetailRequest
  ): Promise<CropYearDetailResponse> {
    return this.cropYearController
      .getCropYearDetail(request)
      .then(extractResponseData);
  }
  getCropYearSummary(id: string): Promise<CropYearSummaryDto> {
    return this.cropYearController
      .getCropYearSummary({ id })
      .then(extractResponseData);
  }

  getFarmCropYearSummary(
    cropYearId: string,
    farmId: string
  ): Promise<CropYearFarmSummaryDto> {
    return this.cropYearController
      .getFarmSummary({ farmId, id: cropYearId, pathFarmId: farmId })
      .then(extractResponseData);
  }

  getCropYearFarms(
    id: string,
    queryParams?: QueryParams
  ): Promise<GetCropYearFarmsResponse> {
    return this.cropYearController
      .listCropYearFarms({ id, ...transformQueryParamsForRequest(queryParams) })
      .then(extractResponseDataWithMeta);
  }

  getAddableFarms(
    request: CropYearApiGetFarmsNotAssignedToCropYearRequest
  ): Promise<MinimumFarmListDto[]> {
    return this.cropYearController
      .getFarmsNotAssignedToCropYear(request)
      .then(extractResponseData);
  }

  getMonthlyAverageListByMonth(
    request: CropYearApiGetMonthlyAverageListByMonthRequest
  ) {
    return this.cropYearController
      .getMonthlyAverageListByMonth(request)
      .then(extractResponseData);
  }

  assignFarmToCropYear(
    request: CropYearApiAssignFarmToCropYearRequest
  ): Promise<string> {
    return this.cropYearController
      .assignFarmToCropYear(request)
      .then(processResponse);
  }

  createCropYear(
    cropYearInfo: CreateCropYearRequest
  ): Promise<CropYearDetailResponse> {
    return this.cropYearController
      .createCropYear({ createCropYearRequest: cropYearInfo })
      .then(extractResponseData);
  }

  updateCropYear(
    id: string,
    cropYearInfo: UpdateCropYearRequest
  ): Promise<CropYearDetailResponse> {
    return this.cropYearController
      .updateCropYear({ id, updateCropYearRequest: cropYearInfo })
      .then(extractResponseData);
  }

  deleteFarmsFromCropYear(id: string, farmIds: Array<string>): Promise<string> {
    return this.cropYearController
      .removeMultipleFarmsFromCropYear({
        id,
        removeFarmsFromCropYearRequest: { ids: [...farmIds] },
      })
      .then(processResponse);
  }

  triggerPostHarvest(id: string): Promise<string> {
    return this.cropYearController
      .triggerPostHarvest({ id })
      .then(processResponse);
  }

  setMonthlyAverage(
    id: string,
    month: string,
    monthlyAverageInfo: SetMonthlyAveragePriceRequest
  ) {
    return this.cropYearController
      .setMonthlyAverage({
        id,
        month,
        setMonthlyAveragePriceRequest: monthlyAverageInfo,
      })
      .then(extractResponseData);
  }

  getNextCropYear(): Promise<NextCropYearDetailResponse> {
    return this.cropYearController
      .getNextCropYearDetails()
      .then(extractResponseData);
  }

  getSilageYearFarmDocuments(
    silageYearId: string,
    farmId: string,
    queryParams?: QueryParams
  ): Promise<{ data: FarmDocumentDto[]; meta: PaginationMeta }> {
    return this.cropYearController
      .listSilageYearFarmDocuments({
        id: silageYearId,
        farmId,
        farmId2: farmId,
        ...transformQueryParamsForRequest(queryParams),
      })
      .then(extractResponseDataWithMeta);
  }
}

export default CropYearService;
