import { ReactElement } from "react";

import { Avatar, Flex, HStack, Text, Tooltip } from "@chakra-ui/react";

import { PricingOptionAuditLogDto } from "api";
import { formatDate, parseIsoDate } from "utils/dateUtils";

const AgreedByLineItem = ({
  entry,
}: {
  entry: PricingOptionAuditLogDto;
}): ReactElement => (
  <Flex alignItems={"center"} mt={4} mb={1} gap={10}>
    <HStack flex={1} textOverflow={"ellipsis"}>
      <Avatar
        size={"xs"}
        name={entry.firstName}
        bg={entry.profilePicture?.url ? "grayFactor.0" : "brand.800"}
        src={entry.profilePicture?.url}
      />
      <Tooltip hasArrow label={`${entry.firstName} ${entry.lastName}`}>
        <Text
          noOfLines={1}
          wordBreak={"break-all"}
        >{`${entry.firstName} ${entry.lastName}`}</Text>
      </Tooltip>
    </HStack>
    <Text>
      {entry?.lastUpdated && formatDate(parseIsoDate(entry.lastUpdated))}
    </Text>
  </Flex>
);

export default AgreedByLineItem;
