/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosRequestConfig,
} from "axios";
import { Configuration } from "../configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "../base";
// @ts-ignore
import { DataCSVUploadJobResponse } from "../models";
// @ts-ignore
import { PageResponseCSVUploadJobResponse } from "../models";
/**
 * CSVUploadApi - axios parameter creator
 * @export
 */
export const CSVUploadApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCSVUploadJobById: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getCSVUploadJobById", "id", id);
      const localVarPath = `/csv-upload-job/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCSVUploadJobPageByUser: async (
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/csv-upload-job`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sort) {
        localVarQueryParameter["sort"] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CSVUploadApi - functional programming interface
 * @export
 */
export const CSVUploadApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    CSVUploadApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCSVUploadJobById(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataCSVUploadJobResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getCSVUploadJobById(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCSVUploadJobPageByUser(
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PageResponseCSVUploadJobResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getCSVUploadJobPageByUser(
          page,
          size,
          sort,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * CSVUploadApi - factory interface
 * @export
 */
export const CSVUploadApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = CSVUploadApiFp(configuration);
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCSVUploadJobById(
      id: string,
      options?: any
    ): AxiosPromise<DataCSVUploadJobResponse> {
      return localVarFp
        .getCSVUploadJobById(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCSVUploadJobPageByUser(
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any
    ): AxiosPromise<PageResponseCSVUploadJobResponse> {
      return localVarFp
        .getCSVUploadJobPageByUser(page, size, sort, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getCSVUploadJobById operation in CSVUploadApi.
 * @export
 * @interface CSVUploadApiGetCSVUploadJobByIdRequest
 */
export interface CSVUploadApiGetCSVUploadJobByIdRequest {
  /**
   *
   * @type {string}
   * @memberof CSVUploadApiGetCSVUploadJobById
   */
  readonly id: string;
}

/**
 * Request parameters for getCSVUploadJobPageByUser operation in CSVUploadApi.
 * @export
 * @interface CSVUploadApiGetCSVUploadJobPageByUserRequest
 */
export interface CSVUploadApiGetCSVUploadJobPageByUserRequest {
  /**
   * Zero-based page index (0..N)
   * @type {number}
   * @memberof CSVUploadApiGetCSVUploadJobPageByUser
   */
  readonly page?: number;

  /**
   * The size of the page to be returned
   * @type {number}
   * @memberof CSVUploadApiGetCSVUploadJobPageByUser
   */
  readonly size?: number;

  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @type {Array<string>}
   * @memberof CSVUploadApiGetCSVUploadJobPageByUser
   */
  readonly sort?: Array<string>;
}

/**
 * CSVUploadApi - object-oriented interface
 * @export
 * @class CSVUploadApi
 * @extends {BaseAPI}
 */
export class CSVUploadApi extends BaseAPI {
  /**
   *
   * @param {CSVUploadApiGetCSVUploadJobByIdRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CSVUploadApi
   */
  public getCSVUploadJobById(
    requestParameters: CSVUploadApiGetCSVUploadJobByIdRequest,
    options?: AxiosRequestConfig
  ) {
    return CSVUploadApiFp(this.configuration)
      .getCSVUploadJobById(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CSVUploadApiGetCSVUploadJobPageByUserRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CSVUploadApi
   */
  public getCSVUploadJobPageByUser(
    requestParameters: CSVUploadApiGetCSVUploadJobPageByUserRequest = {},
    options?: AxiosRequestConfig
  ) {
    return CSVUploadApiFp(this.configuration)
      .getCSVUploadJobPageByUser(
        requestParameters.page,
        requestParameters.size,
        requestParameters.sort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
