import { Navigate, useSearchParams } from "react-router-dom";
import { defaultErrorCardState } from "components/ErrorCard";

const RedirectToErrorPage = ({
  relativeErrorPath = "error",
  errorState = defaultErrorCardState,
  appendSearchParams = true,
}: {
  errorState: ErrorState;
  relativeErrorPath?: string;
  appendSearchParams?: boolean;
}) => {
  const [searchParams] = useSearchParams();

  return (
    <Navigate
      to={`../${relativeErrorPath}`}
      state={{
        msg: errorState.msg,
        title: errorState.title,
        linkText: errorState.linkText,
        linkTo: appendSearchParams
          ? `${errorState.linkTo}?${searchParams}`
          : errorState.linkTo,
      }}
      replace={false}
    />
  );
};

export default RedirectToErrorPage;
