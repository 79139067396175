import { PropsWithChildren, ReactElement } from "react";

import { Box, BoxProps, Center } from "@chakra-ui/react";

import { SITE_HEADER_HEIGHT } from "components/SiteHeader";

export const CONTENT_AREA_PADDING = 10;

export const contentAreaWidthProps = {
  w: "100%",
  maxW: "1440px",
};

const ContentWrapper = ({
  children,
  ...rest
}: PropsWithChildren<BoxProps>): ReactElement => (
  <Center
    alignItems="flex-start"
    flex={1}
    h={`calc(100vh - ${SITE_HEADER_HEIGHT})`}
    overflowY="auto"
  >
    <Box
      {...contentAreaWidthProps}
      py={CONTENT_AREA_PADDING}
      {...rest}
      h="100%"
    >
      {children}
    </Box>
  </Center>
);

export default ContentWrapper;
