import { FeatureFlagApi } from "api";
import { extractResponseData } from "./apiHelpers";
import { constructApiControllerInstance } from "./customApiConfig";

class FeatureFlagService {
  featureFlagController!: FeatureFlagApi;
  constructor() {
    this.featureFlagController = constructApiControllerInstance(FeatureFlagApi);
  }
  getFeatureFlags() {
    return this.featureFlagController.listFlags().then(extractResponseData);
  }
}

export default FeatureFlagService;
