import { useMemo } from "react";
import { Skeleton, Stack, VisuallyHidden } from "@chakra-ui/react";
import { HEADING_SPACING } from "./page-components";

const FormSkeletonLoadingState = ({ rowCount }: { rowCount: number }) => {
  const rows = useMemo(() => {
    const _rows: JSX.Element[] = [];
    for (let i = 0; i < rowCount; i++) {
      // width and height are approximations from design
      _rows.push(
        <Stack key={`skeleton-labeled-input-${i}`} spacing={4}>
          <Skeleton w="100px" h="14px" />
          <Skeleton w="100%" h="27px" />
        </Stack>
      );
    }

    return _rows;
  }, [rowCount]);

  // Render "Loading..." hidden to allow existing tests to still be valid
  // TODO: rework tests to not searching for loading state, but instead look for loaded

  return (
    <Stack spacing={HEADING_SPACING}>
      <VisuallyHidden>Loading...</VisuallyHidden>
      {rows}
    </Stack>
  );
};

export default FormSkeletonLoadingState;
