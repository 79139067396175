import { useMutation } from "@tanstack/react-query";

import { useAuthService } from "contexts/ServiceProvider";
import AuthService from "services/AuthService";
import { MutationHookResult, MutationHook } from "hooks/hookTypeHelpers";

type ForgotPasswordMutationResult = MutationHookResult<
  Awaited<ReturnType<AuthService["forgotPassword"]>>,
  string
>;

const useForgotPasswordMutation = (): ForgotPasswordMutationResult => {
  const authService = useAuthService();

  return useMutation({
    mutationKey: ["forgot-password"],
    mutationFn: (email) => authService.forgotPassword(email),
  });
};

const useForgotPassword = (): MutationHook<
  ForgotPasswordMutationResult,
  "onSubmit"
> => {
  const {
    mutate: onSubmit,
    mutateAsync: onSubmitAsync,
    ...rest
  } = useForgotPasswordMutation();

  return { onSubmit, onSubmitAsync, ...rest };
};

export default useForgotPassword;
