import { RouteObject } from "react-router";

import UnprotectedRoute from "components/route-wrappers/UnprotectedRoute";
import AuthLayout from "pages/auth/AuthLayout";
import authPaths from "./authPaths";

const authRoutes: RouteObject = {
  path: authPaths.basePath,
  element: (
    <UnprotectedRoute>
      <AuthLayout />
    </UnprotectedRoute>
  ),
};

export default authRoutes;
