import { useMutation } from "@tanstack/react-query";
import { useFieldService } from "contexts/ServiceProvider";

const useUploadActualTonsCsv = () => {
  const fieldService = useFieldService();

  return useMutation({
    mutationKey: ["upload-actual-tons-csv"],
    mutationFn: ({ csvFile }: { csvFile: File }) =>
      fieldService.uploadActualTonsCsv(csvFile),
  });
};

export default useUploadActualTonsCsv;
