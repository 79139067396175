import { FormInputs } from "components/inputs";
import DateInput from "components/inputs/DateInput";
import DeferPaymentSelectInput from "components/inputs/DeferPaymentSelectInput";

const DeferPaymentInputs = ({
  isDeferPayment,
  minDate,
}: {
  isDeferPayment: boolean;
  minDate?: Date;
}) => {
  return (
    <>
      <FormInputs
        inputs={[
          {
            id: "deferPayment",
            label: "Defer Payment",
            component: DeferPaymentSelectInput,
            shouldRegister: false,
            isRequired: true,
          },
        ]}
      />
      {isDeferPayment ? (
        <FormInputs
          inputs={[
            {
              id: "defermentDate",
              label: "Deferment Date",
              component: DateInput,
              shouldRegister: false,
              isRequired: isDeferPayment,
              datePickerProps: { minDate },
            },
          ]}
        />
      ) : null}
    </>
  );
};

export default DeferPaymentInputs;
