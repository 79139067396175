const forgotPasswordPaths = {
  basePath: "auth",
  children: {
    forgotPassword: "forgot-password",
    resetPassword: "reset-password",
    error: "error",
  },
};

export default forgotPasswordPaths;
