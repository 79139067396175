import { ReactElement, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Center, HStack, Spinner, Stack } from "@chakra-ui/react";

import AddFarmModal from "components/AddFarmModal";
import DeleteFarmButton from "./DeleteFarmsModal";
import FetchMoreWhenInView from "components/FetchMoreWhenInView";
import { TABLE_MARGIN_TOP } from "components/SortableTable";
import { useCropYearContext } from "contexts/CropYearContext";
import { PageHeaderStack } from "components/page-components";
import {
  baseFarmsListColumns,
  cropYearFarmsTableColumns,
  desktopFarmsListColumns,
} from "./CropYearFarmsTable";
import useCropYearFarms from "hooks/crop-year/useCropYearFarms";
import useSelectionIds from "hooks/useSelectionIds";
import useUserRoleFlags from "hooks/auth/useUserRoleFlags";
import useSort, {
  addSecondarySort,
  ColumnsWithSecondarySort,
  DEFAULT_SORT_DIRECTION,
  Sort,
} from "hooks/useSort";
import { FARM_ID_QUERY_STRING_KEY } from "hooks/useSelectedFarmId";
import SilageDashboardTabLayout from "layouts/SilageDashboardTabLayout";
import DashboardListTable from "pages/dashboard/components/DashboardListTable";
import { CropYearFarmsSortColumns } from "services/CropYearService";
import dashboardPaths from "routes/dashboard/dashboardPaths";
import { mobileStyleBreakpoint } from "utils/styleHelpers";

const adminFarmsListTableColumns = mobileStyleBreakpoint(
  baseFarmsListColumns,
  desktopFarmsListColumns,
  "md"
);

const columnsWithSecondarySort: ColumnsWithSecondarySort<CropYearFarmsSortColumns> =
  {
    numberOfFields: {
      key: "farm.doingBusinessAs",
      direction: DEFAULT_SORT_DIRECTION,
    },
    totalAcres: {
      key: "farm.doingBusinessAs",
      direction: DEFAULT_SORT_DIRECTION,
    },
    "farm.region.name": {
      key: "farm.doingBusinessAs",
      direction: DEFAULT_SORT_DIRECTION,
    },
  };

const defaultSort: Sort<CropYearFarmsSortColumns> = {
  key: "farm.region.name",
  direction: DEFAULT_SORT_DIRECTION,
};

const FarmsListTab = (): ReactElement => {
  const { cropYear, isLoading: cropYearIsLoading } = useCropYearContext();
  const [searchTerm, setSearchTerm] = useState("");
  const { sort, toggleSort, setSort } =
    useSort<CropYearFarmsSortColumns>(defaultSort);
  const { isEmployeeOnly } = useUserRoleFlags();
  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useCropYearFarms(cropYear?.id, {
      search: searchTerm,
      sort: addSecondarySort(sort, columnsWithSecondarySort),
    });

  const tableData = useMemo(
    () =>
      cropYearFarmsTableColumns(
        data?.pages?.flatMap((response) => response.cropYearFarms) ?? []
      ),
    [data]
  );

  const { selectedIds, onToggleId, clearSelectedIds } = useSelectionIds(
    tableData.map((field) => field.id)
  );

  useEffect(() => {
    setSort(defaultSort);
  }, [setSort, searchTerm]);

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const handleClickRow = (farmId: string): void => {
    navigate(
      `/${dashboardPaths.basePath}/${
        isEmployeeOnly
          ? dashboardPaths.children.fields
          : dashboardPaths.children.pricing
      }?${FARM_ID_QUERY_STRING_KEY}=${farmId}&${searchParams}`,
      { replace: false }
    );
  };

  return (
    <SilageDashboardTabLayout
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      heading="All Farms"
      addButton={<AddFarmModal />}
    >
      {isLoading || cropYearIsLoading ? (
        <Center h="100%">
          <Spinner />
        </Center>
      ) : (
        <Stack spacing={TABLE_MARGIN_TOP} w="100%">
          <PageHeaderStack>
            <HStack>
              <DeleteFarmButton
                isDisabled={selectedIds.length === 0}
                farmIds={selectedIds}
                onSuccess={clearSelectedIds}
                cropYear={cropYear}
              />
            </HStack>
          </PageHeaderStack>

          <DashboardListTable
            data={tableData}
            sort={sort}
            toggleSort={toggleSort}
            columns={adminFarmsListTableColumns}
            emptyTableMsg={
              searchTerm.length === 0
                ? "No farms added yet."
                : "No Results Found"
            }
            selectedRows={selectedIds}
            onSelectRow={onToggleId}
            onClickRow={handleClickRow}
          />
          {tableData.length > 0 && (
            <FetchMoreWhenInView
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
              key={`${data?.pages.length}`}
            />
          )}
        </Stack>
      )}
    </SilageDashboardTabLayout>
  );
};

export default FarmsListTab;
