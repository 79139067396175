import { useMemo, useCallback, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  useDisclosure,
  Image,
  Text,
  Box,
  Center,
  Spinner,
} from "@chakra-ui/react";

import TextHeading from "components/TextHeading";
import useDeleteNotification from "hooks/notifications/useDeleteNotification";
import useGetNotificationList from "hooks/notifications/useGetNotificationList";
import { NewCropYearNotification } from "services/NotificationService";
import { NotificationDtoTypeEnum } from "api";

const NewCropYearNotificationModal = ({
  farmNotification,
  isOpen,
  onClose,
}: {
  farmNotification: NewCropYearNotification | undefined;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const { mutateAsync: markNotificationAsRead } = useDeleteNotification();

  const newCropYearAvailableMessage = `A new crop year has been started and your new default Silage crop year is ${farmNotification?.data.year}. 
  If you have access to prior years, you can change views with the crop year selector as seen below.`;

  const onDelete = useCallback(() => {
    return markNotificationAsRead(farmNotification?.id).then(() => {
      onClose();
    });
  }, [farmNotification?.id, markNotificationAsRead, onClose]);

  return (
    <Modal
      aria-label="notifications-modal"
      closeOnOverlayClick={false}
      closeOnEsc={false}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent maxW="534px" minH="704px">
        <ModalHeader>
          <TextHeading headingType="h3">
            Additional Crop Years Available
          </TextHeading>
        </ModalHeader>
        <ModalBody>
          <Text pb={4}>{newCropYearAvailableMessage}</Text>
          <Box boxSize="100%" py={4}>
            {!isImageLoaded && (
              <Center h="100%" mt={12} pt={12}>
                <Spinner />
              </Center>
            )}
            <Image
              src="/crop-year-selector-example.png"
              alt="crop-year-selector-example"
              onLoad={() => setIsImageLoaded(true)}
              loading="eager"
              display={isImageLoaded ? "block" : "none"}
            />
          </Box>
        </ModalBody>
        <ModalFooter pt={4} px={0} pb={0}>
          <Button
            aria-label="mark-notification-as-read"
            onClick={onDelete}
            w="100%"
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const NotificationHandler = ({
  selectedFarmId,
}: {
  selectedFarmId: string | undefined;
}) => {
  const {
    data: notificationListData,
    isLoading: isNotificationListDataLoading,
  } = useGetNotificationList();

  const {
    isOpen: isNotificationModalOpen,
    onOpen: onOpenNotificationModal,
    onClose: onCloseNotificationModal,
  } = useDisclosure();

  const newCropYearFarmNotification = useMemo(() => {
    const result = notificationListData?.find(
      (notification) =>
        notification.type ===
          NotificationDtoTypeEnum.FarmAdminCropYearStarted &&
        notification.data.farmId === selectedFarmId
    );
    if (result) {
      onOpenNotificationModal();
    }

    return result;
  }, [notificationListData, onOpenNotificationModal, selectedFarmId]);

  return (
    <>
      {!isNotificationListDataLoading && newCropYearFarmNotification && (
        <NewCropYearNotificationModal
          farmNotification={newCropYearFarmNotification}
          isOpen={isNotificationModalOpen}
          onClose={onCloseNotificationModal}
        />
      )}
    </>
  );
};

export default NotificationHandler;
