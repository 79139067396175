import { ReactElement } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Spinner, Stack } from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CARD_MIN_WIDTH } from "utils/styleHelpers";
import authPaths from "routes/auth/authPaths";
import forgotPasswordPaths from "routes/forgot-password/forgotPasswordPaths";
import useResetPassword, {
  ResetPasswordRequestProps,
} from "hooks/auth/forgot-password/useResetPassword";
import useValidateResetPasswordToken from "hooks/auth/forgot-password/useValidateResetPasswordToken";
import useNavigateToErrorPage from "hooks/useNavigateToErrorPage";
import useFormValidation from "hooks/useFormValidation";
import {
  CardBody,
  CardFooter,
  CardHeader,
  ResponsiveCard,
} from "components/Card";
import { DesktopOnly } from "components/BreakpointVisibility";
import SubmitButton from "components/SubmitButton";
import BackToLogin from "components/BackToLogin";
import ChangePasswordForm, {
  changePasswordSchema,
} from "forms/forgot-password/ChangePasswordForm";
import { forgotPasswordErrorStates } from "pages/forgot-password/forgotPasswordErrorStates";
import { extractResponseErrorMessage } from "services/apiHelpers";
import RedirectToErrorPage from "components/redirects/RedirectToErrorPage";

const ResetPasswordCard = ({ token }: { token?: string }): ReactElement => {
  const methods = useForm<ResetPasswordRequestProps>({
    resolver: yupResolver(changePasswordSchema),
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });
  const requiredFieldsNotFilled = useFormValidation(methods.watch());

  const [params] = useSearchParams();
  const resetPasswordToken = params.get("token") ?? token ?? "";

  const { isLoading, isError } =
    useValidateResetPasswordToken(resetPasswordToken);
  const { onResetPassword } = useResetPassword();

  const navigate = useNavigate();
  const navigateToErrorPage = useNavigateToErrorPage(
    forgotPasswordErrorStates,
    forgotPasswordPaths.children.error
  );

  if (!resetPasswordToken || isError) {
    return (
      <RedirectToErrorPage
        relativeErrorPath={forgotPasswordPaths.children.error}
        errorState={forgotPasswordErrorStates.INVALID_TOKEN}
        appendSearchParams={false}
      />
    );
  }

  if (isLoading) {
    return <Spinner />;
  }

  const onSubmitResetPassword = (data: ResetPasswordRequestProps) => {
    onResetPassword(
      { ...data, token: resetPasswordToken },
      {
        onSuccess: () => navigate(`/${authPaths.basePath}`),
        onError: (error) => {
          const errorMsg = extractResponseErrorMessage(error?.response);
          navigateToErrorPage(errorMsg);
        },
      }
    );
  };

  return (
    <FormProvider {...methods}>
      <Stack as="form" onSubmit={methods.handleSubmit(onSubmitResetPassword)}>
        <ResponsiveCard minW={CARD_MIN_WIDTH}>
          <CardHeader>Enter new password</CardHeader>
          <CardBody>
            <Stack w="100%">
              <ChangePasswordForm />
            </Stack>
          </CardBody>
          <CardFooter>
            <SubmitButton
              size="lg"
              w="100%"
              submitDisabled={requiredFieldsNotFilled}
              isSubmitting={methods.formState.isSubmitting}
              buttonText="Continue"
            />
          </CardFooter>
        </ResponsiveCard>
        <DesktopOnly>
          <BackToLogin />
        </DesktopOnly>
      </Stack>
    </FormProvider>
  );
};

export default ResetPasswordCard;
