import { skipToken, useInfiniteQuery } from "@tanstack/react-query";

import { useFarmService } from "contexts/ServiceProvider";
import { getNextPage } from "services/apiHelpers";

export const FARM_DOCUMENT_LINK_QUERY_KEY = "farm-document-link";

const useFarmDocumentList = (farmId: string | undefined) => {
  const farmService = useFarmService();

  return useInfiniteQuery({
    queryKey: [FARM_DOCUMENT_LINK_QUERY_KEY, { farmId }],
    queryFn: farmId
      ? ({ pageParam = 0 }) =>
          farmService.getFarmDocumentsPage(farmId, {
            page: pageParam,
            size: 10,
          })
      : skipToken,
    initialPageParam: 0,
    getNextPageParam: getNextPage,
    enabled: !!farmId,
    gcTime: 0,
  });
};

export default useFarmDocumentList;
