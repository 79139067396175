import * as yup from "yup";
import { optionalSelectOptionSchema } from "forms/schemas";

const sumSplits = (singleSplits?: { split: number }[]) =>
  singleSplits?.reduce((sum, singleSplit) => sum + singleSplit.split, 0);

const splitPercentageMoreThanValue = 0;
const splitPercentageMoreThanMessage = "Split Percentage must be more than 0";

export const createSplitsSchema = (isPreHarvest = false) =>
  yup
    .object({
      splits: yup
        .array(
          yup.object({
            farm: optionalSelectOptionSchema
              .test(
                "is-required-if-more-than-one",
                "Farm is required",
                function (value) {
                  // Borrowed from https://stackoverflow.com/a/70590535. The from property does not exist in the type, but is defined.
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  const [, , parentValue] = this.from; // [selectOptionProp, selectOption, parentValue]
                  const {
                    value: { splits },
                  } = parentValue;

                  return splits.length === 1 || !!value;
                }
              )
              .nullable(),
            split: yup
              .number()
              .transform((value) => (isNaN(value) ? undefined : value))
              .required()
              .when("farm.value", {
                is: (farmId: string) => farmId !== undefined,
                then: (schema) =>
                  isPreHarvest
                    ? schema
                        .moreThan(
                          splitPercentageMoreThanValue,
                          splitPercentageMoreThanMessage
                        )
                        .lessThan(100, "Split Percentage must be less than 100")
                    : schema
                        .moreThan(
                          splitPercentageMoreThanValue,
                          splitPercentageMoreThanMessage
                        )
                        .max(
                          100,
                          "Individual Split Percentage must not exceed 100"
                        ),
              }),
          })
        )
        .min(1)
        .max(9)
        .test(
          "maximum-split-exceeded-pre-harvest",
          "The remaining split percentage may not equal 0",
          (singleSplits) => {
            // This is only a validation rule in pre-harvest.
            if (!isPreHarvest) {
              return true;
            }
            const sumOfSplits = sumSplits(singleSplits);

            return sumOfSplits === undefined || sumOfSplits !== 100;
          }
        )
        .test(
          "over-allocated-split",
          "Total split percentage exceeds 100%",
          (singleSplits) => {
            const sumOfSplits = sumSplits(singleSplits);

            return sumOfSplits === undefined || sumOfSplits <= 100;
          }
        )
        .required(),
    })
    .required();
