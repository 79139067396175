import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { DelayedContractDetailsResponse } from "api";

export const useCustomValidation = (
  delayedPricingCalculatedData: DelayedContractDetailsResponse | undefined
) => {
  const { watch, setError, clearErrors } = useFormContext();
  const tonsToSell = watch("tonsToSell");

  useEffect(() => {
    if (delayedPricingCalculatedData && tonsToSell) {
      if (delayedPricingCalculatedData?.netTotal <= 0) {
        setError("tonsToSell", {
          type: "custom",
          message: "Must enter more tons to sell for valid Net Total",
        });
      } else {
        clearErrors("tonsToSell");
      }
    }
  }, [clearErrors, delayedPricingCalculatedData, setError, tonsToSell]);
};
