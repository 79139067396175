import { forwardRef, useState } from "react";
import {
  Popover,
  PopoverContent,
  PopoverBody,
  Stack,
  CheckboxGroup,
  InputProps,
  useBreakpointValue,
  PlacementWithLogical,
  PopoverAnchor,
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";

import ValidationCheckbox from "components/ValidationCheckbox";
import { MIN_PASSWORD_LENGTH } from "constants/registrationForm";
import {
  isRequiredLength,
  containsLowercaseLetter,
  containsUppercaseLetter,
  containsNumber,
  containsSpecialCharacter,
} from "utils/stringUtils";
import { mobileStyleBreakpoint } from "utils/styleHelpers";
import { PasswordInput } from "components/inputs";

const DEFAULT_INPUT_ID = "password";
const POPOVER_PLACEMENT = mobileStyleBreakpoint<PlacementWithLogical>(
  "bottom-start",
  "end-start"
);
const POPOVER_OFFSET = mobileStyleBreakpoint<[number, number] | undefined>(
  undefined,
  [-30, 0]
);
const POPOVER_PADDING_LEFT = mobileStyleBreakpoint(5, 9);
const POPOVER_PADDING_RIGHT = mobileStyleBreakpoint(4, 8);
const POPOVER_WIDTH = mobileStyleBreakpoint(240, 268);
export const CHECKBOX_IDS = {
  LENGTH: "12-characters-requirement",
  CONTAINS_ONE_LOWERCASE_LETTER: "one-lowercase-requirement",
  CONTAINS_ONE_UPPERCASE_LETTER: "one-uppercase-requirement",
  CONTAINS_ONE_NUMBER: "one-number-requirement",
  CONTAINS_ONE_SPECIAL_CHARACTER: "one-special-character-requirement",
};

const PasswordPopover = forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    const [isOpen, setIsOpen] = useState(false);

    const { watch } = useFormContext();
    const password = watch(props.id ?? DEFAULT_INPUT_ID, "");

    const placement = useBreakpointValue(POPOVER_PLACEMENT);
    const offset = useBreakpointValue(POPOVER_OFFSET);

    return (
      <Popover
        isOpen={isOpen}
        placement={placement}
        offset={offset}
        autoFocus={false}
        returnFocusOnClose={false}
      >
        <PopoverAnchor>
          <PasswordInput
            {...props}
            ref={ref}
            onFocus={() => setIsOpen(true)}
            onBlur={() => {
              setIsOpen(false);
            }}
            data-testid="password-input"
          />
        </PopoverAnchor>
        <PopoverContent
          pl={POPOVER_PADDING_LEFT}
          pr={POPOVER_PADDING_RIGHT}
          w={POPOVER_WIDTH}
        >
          <PopoverBody>
            <CheckboxGroup>
              <Stack spacing={4}>
                <ValidationCheckbox
                  label="12 characters"
                  id={CHECKBOX_IDS.LENGTH}
                  isChecked={isRequiredLength(password, MIN_PASSWORD_LENGTH)}
                  data-testid={CHECKBOX_IDS.LENGTH}
                  isInvalid={false}
                />
                <ValidationCheckbox
                  label="1 lower case character"
                  id={CHECKBOX_IDS.CONTAINS_ONE_LOWERCASE_LETTER}
                  isChecked={containsLowercaseLetter(password)}
                  data-testid={CHECKBOX_IDS.CONTAINS_ONE_LOWERCASE_LETTER}
                  isInvalid={false}
                />
                <ValidationCheckbox
                  label="1 upper case character"
                  id={CHECKBOX_IDS.CONTAINS_ONE_UPPERCASE_LETTER}
                  isChecked={containsUppercaseLetter(password)}
                  data-testid={CHECKBOX_IDS.CONTAINS_ONE_UPPERCASE_LETTER}
                  isInvalid={false}
                />
                <ValidationCheckbox
                  label="1 number"
                  id={CHECKBOX_IDS.CONTAINS_ONE_NUMBER}
                  isChecked={containsNumber(password)}
                  data-testid={CHECKBOX_IDS.CONTAINS_ONE_NUMBER}
                  isInvalid={false}
                />
                <ValidationCheckbox
                  label="1 special character"
                  id={CHECKBOX_IDS.CONTAINS_ONE_SPECIAL_CHARACTER}
                  isChecked={containsSpecialCharacter(password)}
                  data-testid={CHECKBOX_IDS.CONTAINS_ONE_SPECIAL_CHARACTER}
                  isInvalid={false}
                />
              </Stack>
            </CheckboxGroup>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  }
);

PasswordPopover.displayName = "PasswordPopover";

export default PasswordPopover;
