import { REGEX_PASSWORD_CRITERIA } from "constants/registrationForm";

const REGEX_USD_MONEY = /^\d+(\.\d{0,2})?$/;

export const isRequiredLength = (value: string, length: number): boolean =>
  value?.length >= length;

export const containsLowercaseLetter = (value: string): boolean =>
  REGEX_PASSWORD_CRITERIA.CONTAINS_LOWERCASE_LETTER.test(value);

export const containsUppercaseLetter = (value: string): boolean =>
  REGEX_PASSWORD_CRITERIA.CONTAINS_UPPERCASE_LETTER.test(value);

export const containsNumber = (value: string): boolean =>
  REGEX_PASSWORD_CRITERIA.CONTAINS_NUMBER.test(value);

export const containsSpecialCharacter = (value: string): boolean =>
  REGEX_PASSWORD_CRITERIA.CONTAINS_SPECIAL_CHARACTER.test(value);

export const extractNumbers = (maskedPhoneNumber: string): string =>
  maskedPhoneNumber.replace(/\D/gi, "");

export const isUSDollarFormat = (value: number): boolean =>
  REGEX_USD_MONEY.test(`${value}`);

export const onlyContainsDigitsAndDecimal = (value: string) => {
  return /^\d*\.?\d*$/.test(value);
};

export const containsNonNumericCharacters = (
  eventKey: string,
  extraChar: string[] = []
) => {
  return (
    ["e", "E", "+", "-", ...extraChar].includes(eventKey) ||
    !onlyContainsDigitsAndDecimal(eventKey)
  );
};
