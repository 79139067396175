import { ReactNode } from "react";
import { Center, Flex, Stack } from "@chakra-ui/react";
import { CardFooter, ResponsiveCard } from "components/Card";
import { CARD_MIN_WIDTH } from "utils/styleHelpers";

export const ProfilePictureCardFooter = ({
  children,
}: {
  children: ReactNode;
}) => (
  <CardFooter mt={8}>
    <Stack w="100%" spacing={2}>
      {children}
    </Stack>
  </CardFooter>
);

const ProfilePictureCard = ({ children }: { children: ReactNode }) => (
  <Center>
    <Flex>
      <ResponsiveCard minW={CARD_MIN_WIDTH} maxW="534px">
        {children}
      </ResponsiveCard>
    </Flex>
  </Center>
);

export default ProfilePictureCard;
