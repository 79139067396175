import { ReactElement, useCallback, useMemo, useState } from "react";

import {
  Box,
  Center,
  Hide,
  HStack,
  Show,
  Spinner,
  Stack,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";

import { RoleDtoValueEnum, UserStatusDtoValueEnum } from "api";
import useUserRoleFlags from "hooks/auth/useUserRoleFlags";
import useResendInvites from "hooks/user/useResendInvite";
import useSelectedFarmId from "hooks/useSelectedFarmId";
import useNavigateWithQuery from "hooks/useNavigateWithQuery";
import useUsersList from "hooks/user/useUsersList";
import useSelectionIds from "hooks/useSelectionIds";
import useSort, {
  addSecondarySort,
  ColumnsWithSecondarySort,
  DEFAULT_SORT_DIRECTION,
  Sort,
} from "hooks/useSort";
import useCustomToast from "hooks/useCustomToast";
import {
  RESEND_MULTIPLE_INVITE_FAILED_MESSAGE,
  RESEND_MULTIPLE_INVITES_SUCCESS_MESSAGE,
  RESEND_SINGLE_INVITE_FAILED_MESSAGE,
  RESEND_SINGLE_INVITEE_SUCCESS_MESSAGE,
} from "constants/messages";
import { SearchInput } from "components/inputs";
import FetchMoreWhenInView from "components/FetchMoreWhenInView";
import TextHeading from "components/TextHeading";
import SortableTable, { TABLE_MARGIN_TOP } from "components/SortableTable";
import {
  PageHeader,
  HEADING_SPACING,
  PageHeaderStack,
} from "components/page-components";
import userPaths from "routes/user/form/userPaths";
import { UserListSortColumns } from "services/UserService";
import { mobileStyleBreakpoint } from "utils/styleHelpers";
import {
  adminUserListTableDataMapper,
  baseAdminUsersListColumns,
  desktopAdminUsersListColumns,
} from "./AdminUserListTable";
import InviteNewUserModal from "./InviteNewUserModal";
import RemoveFarmAccessButton from "./RemoveFarmAccessButton";
import ResendInviteButton from "./ResendInviteButton";
import { useUserInfo } from "contexts/UserInfoProvider";
import TextErrorMessage from "components/TextErrorMessage";
import useDefaultSelectedFarm from "hooks/farm/useDefaultSelectedFarm";

const SEARCH_WIDTH = mobileStyleBreakpoint("100%", "328px");

const adminUserListTableColumns = mobileStyleBreakpoint(
  baseAdminUsersListColumns,
  desktopAdminUsersListColumns,
  "md"
);

const defaultSort: Sort<UserListSortColumns> = {
  key: "status",
  direction: DEFAULT_SORT_DIRECTION,
};

const columnsWithSecondarySort: ColumnsWithSecondarySort<UserListSortColumns> =
  {
    status: { key: "firstName", direction: DEFAULT_SORT_DIRECTION },
    email: { key: "firstName", direction: DEFAULT_SORT_DIRECTION },
  };

const UsersListPage = (): ReactElement => {
  const columns = useBreakpointValue(adminUserListTableColumns);
  const [searchTerm, setSearchTerm] = useState("");
  const { sort, toggleSort, setSort } =
    useSort<UserListSortColumns>(defaultSort);
  const [farmId] = useSelectedFarmId();
  useDefaultSelectedFarm();

  const navigateWithSearch = useNavigateWithQuery();

  const { isEmployee, isEmployeeOnly } = useUserRoleFlags();
  const {
    data: userListData,
    isPending: userListIsLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useUsersList({
    search: searchTerm,
    sort: addSecondarySort(sort, columnsWithSecondarySort),
    farmId,
  });
  const { onResendInvites } = useResendInvites();
  const { id: userId } = useUserInfo();

  const users = useMemo(
    () => userListData?.pages?.map(({ users }) => users).flat() ?? [],
    [userListData]
  );

  const tableData = useMemo(() => adminUserListTableDataMapper(users), [users]);

  const { selectedIds, onToggleId, clearSelectedIds } = useSelectionIds(
    users.map((user) => user.id)
  );
  const { onSuccessToast, onErrorToast } = useCustomToast();

  const resendDisabled = useMemo(() => {
    const onlyFarmAdminsSelected = selectedIds.every(
      (selectedId) =>
        users.find((user) => user.id === selectedId)?.roles[0].role.value ===
        RoleDtoValueEnum.FarmAdmin
    );

    const onlyPendingUsersSelected = selectedIds.every(
      (id) =>
        users.find(({ id: userId }) => id === userId)?.status.value ===
        UserStatusDtoValueEnum.Pending
    );

    switch (true) {
      case selectedIds.length === 0:
        return { status: true };

      case !onlyPendingUsersSelected:
        return { status: true };

      case !onlyFarmAdminsSelected && isEmployeeOnly:
        return {
          status: true,
          message:
            "You can only resend invites to Farm Admins. De-select other users to proceed.",
        };

      default:
        return { status: false };
    }
  }, [selectedIds, users, isEmployeeOnly]);

  const removeButtonIsDisabled =
    !!selectedIds.find((id) => id === userId) || selectedIds.length === 0;

  const onClickResendInvites = () => {
    onResendInvites(
      { userIds: selectedIds, farmId },
      {
        onSuccess: () => {
          onSuccessToast({
            message:
              selectedIds.length > 1
                ? RESEND_MULTIPLE_INVITES_SUCCESS_MESSAGE
                : RESEND_SINGLE_INVITEE_SUCCESS_MESSAGE,
          });
          clearSelectedIds();
        },
        onError: () => {
          onErrorToast({
            message:
              selectedIds.length > 1
                ? RESEND_MULTIPLE_INVITE_FAILED_MESSAGE
                : RESEND_SINGLE_INVITE_FAILED_MESSAGE,
          });
        },
      }
    );
  };

  const onChangeSearch = useCallback(
    (value = "") => {
      setSearchTerm(value);
      setSort(defaultSort);
    },
    [setSort]
  );

  const search = (
    <SearchInput
      value={searchTerm}
      onChange={(e) => onChangeSearch(e.target.value)}
      onClear={() => onChangeSearch()}
      w={SEARCH_WIDTH}
      clearVisible={!!searchTerm}
    />
  );

  return (
    <Stack spacing={HEADING_SPACING} h="100%">
      <PageHeaderStack>
        <PageHeader heading="Users">
          <HStack spacing={4}>
            <Show above="md">{search}</Show>
            <InviteNewUserModal />
          </HStack>
        </PageHeader>
        <Hide above="md" ssr={false}>
          {search}
        </Hide>
        <HStack spacing={5}>
          <VStack alignItems={"flex-start"}>
            <ResendInviteButton
              resendDisabled={resendDisabled.status}
              onClick={onClickResendInvites}
            />
            {resendDisabled.message && (
              <TextErrorMessage>{resendDisabled.message}</TextErrorMessage>
            )}
          </VStack>
          {!isEmployee && (
            <RemoveFarmAccessButton
              buttonProps={{ isDisabled: removeButtonIsDisabled }}
              clearSelectedIds={clearSelectedIds}
              farmId={farmId}
              selectedIds={selectedIds}
            />
          )}
        </HStack>
      </PageHeaderStack>
      <Box h="100%">
        {userListIsLoading ? (
          <Center h="100%">
            <Spinner />
          </Center>
        ) : (
          <Stack spacing={TABLE_MARGIN_TOP} w="100%">
            <SortableTable
              columns={columns}
              data={tableData}
              onClickRow={({ id }) =>
                navigateWithSearch(`/${userPaths.basePath}/${id}`)
              }
              sort={sort}
              toggleSort={toggleSort}
              selectedRows={selectedIds}
              onSelectRow={onToggleId}
            />
            {tableData.length === 0 && (
              <Center>
                <TextHeading headingType="h5">No Results Found</TextHeading>
              </Center>
            )}
            <FetchMoreWhenInView
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
              key={`${userListData?.pages.length}`}
            />
          </Stack>
        )}
      </Box>
    </Stack>
  );
};

export default UsersListPage;
