import { ReactElement } from "react";

import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import TextHeading from "components/TextHeading";
import InviteNewUserForm from "forms/admin/users/InviteNewUserForm";
import { CARD_MAX_WIDTH } from "utils/styleHelpers";

const InviteNewUserModal = (): ReactElement => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button variant="primary" size="md" px={4} minW="unset" onClick={onOpen}>
        Invite New
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW={CARD_MAX_WIDTH}>
          <ModalHeader>
            <TextHeading headingType="h3">Invite New User</TextHeading>
          </ModalHeader>
          <ModalBody>
            <Stack w="100%">
              <Text pb={4}>Please enter their user information.</Text>
              <InviteNewUserForm onClose={onClose} />
              <Button onClick={onClose} variant="outline">
                Cancel
              </Button>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default InviteNewUserModal;
