import { ReactElement } from "react";

import { Path, useController, useFormContext } from "react-hook-form";
import { FieldValues } from "react-hook-form/dist/types/fields";

import Select, { SelectOption } from "components/Select";
import InputWrapper from "../InputWrapper";

const RoleSelectInput = <T extends FieldValues>({
  id,
  isDisabled,
  options,
  isLoading,
  isError,
  errorMsg,
}: {
  id: Path<T>;
  isDisabled?: boolean;
  options: SelectOption[];
  isLoading?: boolean;
  isError?: boolean;
  errorMsg?: string;
}): ReactElement => {
  const { control } = useFormContext<T>();

  const {
    field,
    fieldState: { error },
  } = useController<T>({
    name: id,
    control: control,
  });

  return (
    <InputWrapper
      isRequired
      isDisabled={isDisabled}
      label="Role"
      isInvalid={!!error}
      errorMsg={error?.message}
    >
      <Select
        options={options}
        {...field}
        isLoading={isLoading}
        isError={isError}
        errorMsg={errorMsg}
      />
    </InputWrapper>
  );
};

export default RoleSelectInput;
