import { ReactElement, ReactNode } from "react";
import { useLocation } from "react-router-dom";

import { Center, Spinner } from "@chakra-ui/react";

import useAccessToken from "hooks/auth/useAccessToken";
import RedirectToLogin from "components/redirects/RedirectToLogin";
import UserInfoProvider from "contexts/UserInfoProvider";

const ProtectedRoute = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const { accessToken, isLoading } = useAccessToken();

  const path = useLocation();

  if (isLoading) {
    return (
      <Center minH="100vh">
        <Spinner />
      </Center>
    );
  }

  if (!accessToken) {
    return <RedirectToLogin redirectTo={path} />;
  }

  return <UserInfoProvider>{children}</UserInfoProvider>;
};

export default ProtectedRoute;
