import { useMutation } from "@tanstack/react-query";

import { CreateFieldRequest } from "api";
import { useFieldService } from "contexts/ServiceProvider";
import { MutationHookResult } from "hooks/hookTypeHelpers";
import FieldService from "services/FieldService";
import useRefetchFieldQueries from "./useRefetchFieldQueries";

type UpdateFieldMutationResult = MutationHookResult<
  Awaited<ReturnType<FieldService["updateField"]>>,
  CreateFieldRequest
>;

const useUpdateField = (id?: string): UpdateFieldMutationResult => {
  const fieldService = useFieldService();
  const { refetch } = useRefetchFieldQueries();

  return useMutation({
    mutationKey: ["update-field", { id }],
    mutationFn: (fieldInfo) =>
      id
        ? fieldService.updateField(id, fieldInfo)
        : Promise.reject("Missing field ID"),
    onSuccess: refetch,
  });
};

export default useUpdateField;
