import { isAfter } from "date-fns";

export const defaultDeferPayStartDate = ({
  cropYearDefermentDate,
  tomorrowsDate,
}: {
  cropYearDefermentDate: Date;
  tomorrowsDate: Date;
}) =>
  isAfter(cropYearDefermentDate, new Date())
    ? cropYearDefermentDate
    : tomorrowsDate;
