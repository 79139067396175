import { useEffect, useMemo } from "react";
import { Box, Center, Spinner, Stack } from "@chakra-ui/react";
import { FarmsSortColumns } from "./listTableDataMapper";
import EmptyState from "components/EmptyState";
import FetchMoreWhenInView from "components/FetchMoreWhenInView";
import SortableTable, {
  ColumnProps,
  TableData,
  TABLE_MARGIN_TOP,
} from "components/SortableTable";
import { Sort, UseSortReturnType } from "hooks/useSort";

type FarmListTableProps = {
  programType: string;
  isLoading: boolean;
  columns: Array<ColumnProps<FarmsSortColumns>> | undefined;
  tableData: Array<Record<string, any>>;
  defaultSort: Sort<FarmsSortColumns>;
  onClickRow: ({ farmId }: TableData) => Promise<void>;
  fetchNextPage: any;
  hasNextPage: boolean | undefined;
  isFetchingNextPage: boolean;
} & UseSortReturnType<FarmsSortColumns>;

const FarmListTable = ({
  programType,
  isLoading,
  columns,
  tableData,
  sort,
  defaultSort,
  onClickRow,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  toggleSort,
  setSort,
}: FarmListTableProps) => {
  const emptyState = useMemo(
    () => <EmptyState>No Farms added to {programType} program.</EmptyState>,
    [programType]
  );

  useEffect(() => {
    setSort(defaultSort);
  }, [defaultSort, setSort]);

  return (
    <Box h="100%">
      {isLoading ? (
        <Center h="100%">
          <Spinner />
        </Center>
      ) : (
        <Stack spacing={TABLE_MARGIN_TOP} w="100%">
          <SortableTable
            columns={columns}
            data={tableData}
            sort={sort}
            toggleSort={toggleSort}
            onClickRow={onClickRow}
          />
          {tableData.length === 0 && emptyState}
          {tableData && tableData.length > 0 && (
            <FetchMoreWhenInView
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
              key={`${tableData?.length}`}
            />
          )}
        </Stack>
      )}
    </Box>
  );
};

export default FarmListTable;
