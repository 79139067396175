import { HStack, Text, VStack } from "@chakra-ui/react";
import { FaDollarSign } from "react-icons/fa";

import DisplayLabelValue from "./DisplayLabelValue";
import { formatDate, parseDate } from "utils/dateUtils";
import { formatNumberWithDecimals } from "utils/formatFunctions";
import { formatNumberWithDecimalsOrDisplayHyphen } from "utils/numberUtils";

export const CashPrice = ({
  cashPrice,
  futuresFriendlySymbol,
  futuresPrice,
  basis,
  isManuallyCreated,
}: {
  cashPrice: number | undefined;
  futuresFriendlySymbol: string | undefined;
  futuresPrice: string | number | undefined;
  basis: number | undefined;
  isManuallyCreated?: boolean;
}) => {
  const parsedFuturesDate = futuresFriendlySymbol
    ? parseDate(futuresFriendlySymbol, "MMMM yyyy")
    : undefined;

  const displayFuturesDate =
    parsedFuturesDate !== undefined
      ? formatDate(parsedFuturesDate, "MMM yyyy")
      : "-";

  return (
    <VStack align="left" spacing={1}>
      <DisplayLabelValue
        label="Cash Price"
        value={formatNumberWithDecimalsOrDisplayHyphen(cashPrice)}
        statTextProps={{ fontSize: "2xl", fontWeight: "bold" }}
        statPrefix="$"
        statSuffix="/bu"
      />
      {!isManuallyCreated ? (
        <>
          <HStack>
            <Text fontSize="xs">
              Futures Price:&nbsp;{displayFuturesDate}&nbsp;$
              {futuresPrice ?? "-"}
            </Text>
          </HStack>
          <Text fontSize="xs">
            Basis:&nbsp;$
            {formatNumberWithDecimalsOrDisplayHyphen(basis)}
          </Text>
        </>
      ) : null}
    </VStack>
  );
};

export const Multiplier = ({
  multiplier,
}: {
  multiplier: number | undefined;
}) => (
  <DisplayLabelValue label="Multiplier" value={multiplier} statSuffix="x" />
);

export const BasePrice = ({ basePrice }: { basePrice: number | undefined }) => (
  <DisplayLabelValue
    label="Base Price"
    value={formatNumberWithDecimalsOrDisplayHyphen(basePrice)}
    statTextProps={{ fontSize: "2xl", fontWeight: "bold" }}
    statPrefix="$"
    statSuffix="/ton"
  />
);

export const DistanceAdjustment = ({
  distanceAdjustment,
}: {
  distanceAdjustment: number | undefined;
}) => (
  <VStack align="left" spacing={1} width="100%">
    <DisplayLabelValue
      label="Distance adjustment"
      value={formatNumberWithDecimalsOrDisplayHyphen(distanceAdjustment)}
      parentheses={true}
      statSuffix="/ton"
      statPrefix="$"
    />
    <Text fontSize="xs">Pro-rated average on all Harvested tons</Text>
  </VStack>
);

export const StorageFees = ({
  storageFees,
  storageFeeRate,
  storageFeeStartDate,
}: {
  storageFees: number | undefined;
  storageFeeRate: number | undefined;
  storageFeeStartDate: string | undefined;
}) => {
  const parsedStorageFeeStartDate = storageFeeStartDate
    ? parseDate(storageFeeStartDate)
    : undefined;

  const displayCropYearDetailStorageFeeStartDate = parsedStorageFeeStartDate
    ? formatDate(parsedStorageFeeStartDate ?? new Date())
    : "-";

  return (
    <VStack align="left" spacing={1} width="100%">
      <DisplayLabelValue
        label="Storage Fees"
        value={formatNumberWithDecimalsOrDisplayHyphen(storageFees)}
        parentheses={true}
        statPrefix="$"
        statSuffix="/ton"
      />
      <Text fontSize="xs">
        $&nbsp;
        {formatNumberWithDecimalsOrDisplayHyphen(storageFeeRate)}
        /&nbsp;ton/&nbsp;day-&nbsp;Starting&nbsp;
        {displayCropYearDetailStorageFeeStartDate}
      </Text>
    </VStack>
  );
};

export const NetPrice = ({ netPrice }: { netPrice: number | undefined }) => (
  <VStack align="left" spacing={0} width="100%">
    <DisplayLabelValue
      label="Net Price"
      value={formatNumberWithDecimalsOrDisplayHyphen(netPrice)}
      statTextProps={{ fontSize: "2xl", fontWeight: "bold" }}
      statSuffix="/ton"
      statPrefix="$"
    />
    <Text fontSize="xs">Base price including adjustments</Text>
  </VStack>
);

export const NetTotal = ({ netTotal }: { netTotal: number | undefined }) => (
  <HStack
    spacing={-1.5}
    maxW={"75%"}
    title={`$${formatNumberWithDecimals(netTotal ?? 0)}`}
  >
    <Text as="sup" fontSize="xl" mt={2} mr={0.5}>
      <FaDollarSign />
    </Text>
    <Text
      fontFamily="heading"
      fontSize="3xl"
      fontWeight="bold"
      letterSpacing="tighter"
      isTruncated
    >
      {formatNumberWithDecimals(netTotal ?? 0)}
    </Text>
  </HStack>
);

export const PercentageToSell = ({
  percentageToSell,
  isAgreed,
}: {
  percentageToSell: number;
  isAgreed: boolean;
}) => (
  <DisplayLabelValue
    label={isAgreed ? "Agreed Percentage to Sell" : "Percentage to Sell"}
    value={`${percentageToSell}`}
    statSuffix="%"
  />
);
