import { ToastPosition, useBreakpointValue, useToast } from "@chakra-ui/react";
import { ErrorToast, SuccessToast } from "components/Toast";
import { ToastContentProps } from "components/Toast/Toast";
import { mobileStyleBreakpoint } from "utils/styleHelpers";

const TOAST_POSITION = mobileStyleBreakpoint("bottom", "top-right");
const TOAST_MARGINS = mobileStyleBreakpoint(
  { margin: 6, marginTop: 0 },
  { margin: 4, marginBottom: 0 }
);

const useCustomToast = () => {
  const toastPosition = useBreakpointValue(TOAST_POSITION) as ToastPosition;
  const toastMargins = useBreakpointValue(TOAST_MARGINS);
  const toast = useToast({
    position: toastPosition,
    containerStyle: { ...toastMargins },
  });

  const onSuccessToast = ({ message, title }: ToastContentProps) => {
    toast({
      render: ({ onClose }) => SuccessToast({ message, title, onClose }),
    });
  };

  const onErrorToast = ({ message, title }: ToastContentProps) => {
    toast({
      render: ({ onClose }) => ErrorToast({ message, title, onClose }),
    });
  };

  return {
    onSuccessToast,
    onErrorToast,
  };
};

export default useCustomToast;
