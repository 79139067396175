import { ReactElement } from "react";

import {
  FieldValues,
  useFormContext,
  useController,
  Path,
} from "react-hook-form";

import Select from "components/Select";
import InputWrapper, { InputWrapperProps } from "../InputWrapper";
import { SelectInputProps } from "../SelectInput";

export enum DEFER_PAYMENT_VALUES {
  YES = "yes",
  NO = "no",
}

export const DEFER_PAYMENT_OPTION_YES = {
  label: "Yes",
  value: DEFER_PAYMENT_VALUES.YES,
};
export const DEFER_PAYMENT_OPTION_NO = {
  label: "No",
  value: DEFER_PAYMENT_VALUES.NO,
};

const DeferPaymentSelectInput = <T extends FieldValues>({
  id,
  isDisabled,
  ...rest
}: SelectInputProps & InputWrapperProps): ReactElement => {
  const { control } = useFormContext<T>();

  const {
    field,
    fieldState: { error },
  } = useController<T>({
    name: id as Path<T>,
    control: control,
  });

  return (
    <InputWrapper
      {...rest}
      isDisabled={isDisabled}
      isInvalid={!!error}
      errorMsg={error?.message}
    >
      <Select
        options={[DEFER_PAYMENT_OPTION_YES, DEFER_PAYMENT_OPTION_NO]}
        {...field}
        defaultValue={DEFER_PAYMENT_OPTION_YES}
      />
    </InputWrapper>
  );
};

export default DeferPaymentSelectInput;
