import { Flex, HStack, Text } from "@chakra-ui/react";

import { MonthlyAverageListResponseDto } from "api";
import { ArrowRight } from "icons";
import { capitalize, formatNumberWithDecimals } from "utils/formatFunctions";

const MonthlyAverageRowItem = ({
  index,
  monthAverage,
  onClick,
}: {
  index: number;
  monthAverage: MonthlyAverageListResponseDto;
  onClick: () => void;
}) => {
  const calculatedCashPrice = monthAverage.multiplier * monthAverage.cashPrice;

  return (
    <Flex
      bgColor={index % 2 == 0 ? "grayFactor.0" : "gray.100"}
      borderRadius="8px"
      cursor="pointer"
      h={"61px"}
      justifyContent={"space-between"}
      onClick={onClick}
      px={"16px"}
      py="8px"
    >
      <Flex flexDirection="column" justifyContent={"space-between"}>
        <Text fontSize="md" fontWeight={"bold"}>
          {capitalize(monthAverage.month.toLowerCase())}
        </Text>
        <Text fontSize="xs">
          $
          {monthAverage.cashPrice
            ? formatNumberWithDecimals(calculatedCashPrice)
            : "0.00"}
          /ton
        </Text>
      </Flex>
      <HStack spacing={3}>
        <Text fontSize="md">
          $
          {monthAverage.contractPrice
            ? formatNumberWithDecimals(monthAverage.contractPrice)
            : "0.00"}
        </Text>
        <ArrowRight fontSize="14px" />
      </HStack>
    </Flex>
  );
};

export default MonthlyAverageRowItem;
