/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AddressDto } from "./address-dto";
import { LienHolderDto } from "./lien-holder-dto";

/**
 *
 * @export
 * @interface UpdateFarmRequest
 */
export interface UpdateFarmRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateFarmRequest
   */
  doingBusinessAs: string;
  /**
   *
   * @type {AddressDto}
   * @memberof UpdateFarmRequest
   */
  address: AddressDto;
  /**
   *
   * @type {string}
   * @memberof UpdateFarmRequest
   */
  phoneNumber: string;
  /**
   *
   * @type {string}
   * @memberof UpdateFarmRequest
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof UpdateFarmRequest
   */
  stateOfOrganization: UpdateFarmRequestStateOfOrganizationEnum;
  /**
   *
   * @type {Array<LienHolderDto>}
   * @memberof UpdateFarmRequest
   */
  lienHolders: Array<LienHolderDto>;
  /**
   *
   * @type {string}
   * @memberof UpdateFarmRequest
   */
  regionId: string;
  /**
   *
   * @type {string}
   * @memberof UpdateFarmRequest
   */
  contactId: string;
}

/**
 * @export
 * @enum {string}
 */
export enum UpdateFarmRequestStateOfOrganizationEnum {
  Arizona = "ARIZONA",
  Minnesota = "MINNESOTA",
  Nebraska = "NEBRASKA",
  NewMexico = "NEW_MEXICO",
  NorthDakota = "NORTH_DAKOTA",
  SouthDakota = "SOUTH_DAKOTA",
}
