import { PropsWithChildren } from "react";
import { HStack, Stack, Text } from "@chakra-ui/react";
import { AlertCircleOutline } from "icons";

const CsvResultsErrorMessage = ({ children }: PropsWithChildren) => (
  <HStack spacing={2} align="flex-start">
    <AlertCircleOutline color="errorRed" />
    <Stack spacing="10px" mb={3}>
      <Text fontWeight="bold" color="errorRed">
        ERROR
      </Text>
      <Text color="errorRed">{children}</Text>
    </Stack>
  </HStack>
);

export default CsvResultsErrorMessage;
