import { ReactElement } from "react";
import { useDisclosure } from "@chakra-ui/react";

import DeleteConfirmationModal from "components/DeleteConfirmationModal";
import useCustomToast from "hooks/useCustomToast";

import DeleteButton from "components/DeleteButton";
import useDeleteFarmDocuments from "hooks/farm/useDeleteFarmDocuments";

const headingText = `Are you sure you want to Delete?`;
const bodyText = `This action will permanently delete the selected link(s) and cannot be undone.`;

const DeleteFarmDocuments = ({
  documentIds,
  farmId,
  onSuccess,
}: {
  documentIds: Array<string>;
  farmId: string | undefined;
  onSuccess(): void;
}): ReactElement => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { onSuccessToast, onErrorToast } = useCustomToast();
  const { mutateAsync: deleteFarmDocuments } = useDeleteFarmDocuments(farmId);

  const onDelete = () => {
    deleteFarmDocuments(documentIds)
      .then(() => {
        onSuccess();
        onSuccessToast({ message: "Successfully Deleted" });
      })
      .catch(() => {
        onErrorToast({
          title: "Error deleting links",
          message: "Please try again",
        });
      });
  };

  return (
    <>
      <DeleteButton onClick={onOpen} isDisabled={documentIds.length === 0} />
      <DeleteConfirmationModal
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        onDelete={onDelete}
        headingText={headingText}
        bodyText={bodyText}
      />
    </>
  );
};

export default DeleteFarmDocuments;
