import { Button, ButtonProps } from "@chakra-ui/react";

import useConfirmationAlert from "hooks/alerts/useConfirmationAlert";

const RejectFarmButton = ({
  title,
  msg,
  onReject,
  ...rest
}: {
  onReject: () => void;
  title: string;
  msg: string;
} & ButtonProps) => {
  const { alert, onOpen } = useConfirmationAlert({
    title: title,
    msg: msg,
    onConfirm: onReject,
    confirmButtonText: "Reject",
    confirmButtonVariant: "destructive",
  });

  return (
    <>
      <Button
        size="md"
        variant="outline"
        color="errorRed"
        colorScheme="error"
        onClick={onOpen}
        {...rest}
      >
        Reject
      </Button>
      {alert}
    </>
  );
};

export default RejectFarmButton;
