import { PropsWithChildren } from "react";

import { Button, ButtonProps, LinkBox, LinkOverlay } from "@chakra-ui/react";

import userRegistrationPaths from "routes/user-registration/userRegistrationPaths";
import LinkWithQuery from "components/LinkWithQuery";

const GoToTermsButton = ({
  children,
  ...rest
}: PropsWithChildren<ButtonProps>) => (
  <LinkBox>
    <LinkOverlay
      as={LinkWithQuery}
      to={`../${userRegistrationPaths.children.termsAndConditions}`}
    >
      <Button variant="primary" w="100%" {...rest}>
        {children}
      </Button>
    </LinkOverlay>
  </LinkBox>
);

export default GoToTermsButton;
