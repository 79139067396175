import { useCallback, useMemo } from "react";

import {
  AddableFarmsSortColumns,
  addFarmsTableColumns,
  baseSelectFarmsListColumns,
  desktopFarmsListColumns,
} from "components/AddFarmsToYear/addFarmsTableColumns";
import AddFarmsToManureYearButton from "./AddFarmsToManureYearButton";
import AddFarmsPageLayout from "components/AddFarmsToYear/AddFarmsToYearPageLayout";
import useGetAddableFarmsForManureYear from "hooks/api/manure-year/useGetAddableFarmsForManureYear";
import useGetManureYear from "hooks/api/manure-year/useGetManureYear";
import useSelectedManureYearId from "hooks/useSelectedManureYearId";
import useNavigateWithQuery from "hooks/useNavigateWithQuery";
import useSelectionIds from "hooks/useSelectionIds";
import useSort, {
  addSecondarySort,
  ColumnsWithSecondarySort,
  DEFAULT_SORT_DIRECTION,
  Sort,
} from "hooks/useSort";
import manurePaths from "routes/manure";
import { mobileStyleBreakpoint } from "utils/styleHelpers";

const selectFarmsListTableColumns = mobileStyleBreakpoint(
  baseSelectFarmsListColumns,
  desktopFarmsListColumns,
  "md"
);

const columnsWithSecondarySort: ColumnsWithSecondarySort<AddableFarmsSortColumns> =
  {
    "region.name": {
      key: "doingBusinessAs",
      direction: DEFAULT_SORT_DIRECTION,
    },
  };

const defaultSort: Sort<AddableFarmsSortColumns> = {
  key: "region.name",
  direction: DEFAULT_SORT_DIRECTION,
};

const AddManureFarmsPage = () => {
  const [selectedManureYearId] = useSelectedManureYearId();
  const { data: manureYearData, isLoading: isManureYearDataLoading } =
    useGetManureYear(selectedManureYearId);
  const useSortProps = useSort<AddableFarmsSortColumns>(defaultSort);
  const { data, isLoading } = useGetAddableFarmsForManureYear(
    selectedManureYearId,
    {
      sort: addSecondarySort(useSortProps.sort, columnsWithSecondarySort),
    }
  );

  const navigate = useNavigateWithQuery();
  const navigateToManureAllFarmsList = useCallback(
    () => navigate(`/${manurePaths.basePath}/${manurePaths.children.allFarms}`),
    [navigate]
  );

  const tableData = useMemo(
    () => addFarmsTableColumns(data?.flatMap((response) => response) ?? []),
    [data]
  );

  const { selectedIds, onToggleId, clearSelectedIds } = useSelectionIds(
    tableData.map((field) => field.id)
  );

  return (
    <AddFarmsPageLayout
      defaultSort={defaultSort}
      programType="Manure"
      year={manureYearData?.year}
      navigateToAllFarmsList={navigateToManureAllFarmsList}
      selectedIds={selectedIds}
      isLoading={isLoading || isManureYearDataLoading}
      tableData={tableData}
      onToggleId={onToggleId}
      selectFarmsListTableColumns={selectFarmsListTableColumns}
      {...useSortProps}
    >
      <AddFarmsToManureYearButton
        year={manureYearData?.year}
        farmIds={selectedIds}
        isDisabled={selectedIds?.length === 0}
        onSuccess={clearSelectedIds}
      />
    </AddFarmsPageLayout>
  );
};

export default AddManureFarmsPage;
