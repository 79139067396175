import { useCallback, useMemo } from "react";
import { MenuItem, HStack, Text, Box } from "@chakra-ui/react";
import { CropYearDetailResponse } from "api";
import dashboardPaths from "routes/dashboard/dashboardPaths";
import { formatDate, parseDate } from "utils/dateUtils";
import useNavigateWithQuery from "hooks/useNavigateWithQuery";

const DelayedPricingMenuItem = ({
  cropYearDetail,
}: {
  cropYearDetail?: CropYearDetailResponse;
}) => {
  const isPreHarvest = cropYearDetail?.timeOfYear === "PRE_HARVEST";
  const navigate = useNavigateWithQuery();

  const navigateToDelayedPricingPage = useCallback(
    () =>
      navigate(
        `/${dashboardPaths.basePath}/${dashboardPaths.children.newDelayedPricing}`
      ),
    [navigate]
  );

  const convertDelayedPricingStringToDate = useMemo(() => {
    return cropYearDetail
      ? parseDate(cropYearDetail?.delayedPricingFinalDate)
      : undefined;
  }, [cropYearDetail]);

  const isDelayedPricingUnavailable = useMemo(() => {
    return (
      isPreHarvest ||
      (convertDelayedPricingStringToDate &&
        convertDelayedPricingStringToDate <= new Date())
    );
  }, [convertDelayedPricingStringToDate, isPreHarvest]);

  const formattedDelayedPricingFinalDate = convertDelayedPricingStringToDate
    ? formatDate(convertDelayedPricingStringToDate, "MM/d/yy")
    : "";

  return (
    <MenuItem
      isDisabled={isDelayedPricingUnavailable}
      onClick={navigateToDelayedPricingPage}
    >
      <HStack justifyContent="space-between" w="100%">
        <Text fontWeight="normal">Delayed Pricing</Text>
        <Box alignItems="flex-end">
          <Text fontSize="xs">
            {isPreHarvest
              ? `Avaiable for Post-Harvest`
              : `Available until ${formattedDelayedPricingFinalDate}`}
          </Text>
        </Box>
      </HStack>
    </MenuItem>
  );
};

export default DelayedPricingMenuItem;
