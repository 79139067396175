import { ReactElement } from "react";

import { Flex } from "@chakra-ui/react";
import { Controller, useFormContext } from "react-hook-form";

import InputWrapper from "components/inputs/InputWrapper";
import PercentWithSliderInput from "components/inputs/PercentWithSliderInput";

const PercentageToSellInput = (): ReactElement => {
  const { control } = useFormContext();

  return (
    <Controller
      name="percentageToSell"
      control={control}
      render={({ fieldState: { error }, field: { onChange, value } }) => (
        <Flex direction="column">
          <InputWrapper
            label="Percentage to sell"
            isRequired={true}
            errorMsg={error?.message}
            isInvalid={!!error?.message}
          >
            <PercentWithSliderInput value={value} onChange={onChange} />
          </InputWrapper>
        </Flex>
      )}
    />
  );
};

export default PercentageToSellInput;
