import { useParams } from "react-router-dom";
import { useFormContext } from "react-hook-form";

import { UserStatusDtoValueEnum } from "api";
import FarmInfoPageHeader from "./components/FarmInfoPageHeader";
import FarmDataWrapper from "./components/FarmDataWrapper";
import FarmInfoTabGroup from "./components/FarmInfoTabGroup";
import FarmPendingApprovalBanner from "./components/FarmPendingApprovalBanner";
import FormButtonGroup from "components/FormButtonGroup";
import RejectFarmButton from "./components/RejectFarmButton";
import SubmitButton from "components/SubmitButton";
import useFarmMutations from "./components/useFarmMutations";
import { FarmDetailFields, verifyAllRequiredFields } from "./FarmFormContext";
import FarmDetailFormInputs from "forms/farm-detail";
import useUserRoleFlags from "hooks/auth/useUserRoleFlags";
import useFarmDetail from "hooks/farm/farm-detail/useFarmDetail";
import { FormStack } from "layouts/FormLayout";
import { isFarmPending } from "utils/farmStatusUtils";

export const ApproveFarmForm = () => {
  const { id: farmId } = useParams();
  const { isEmployeeAdmin, isFarmer } = useUserRoleFlags();

  const {
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useFormContext<FarmDetailFields>();

  const { onApprove, alert, onReject } = useFarmMutations({ farmId });
  const requiredFieldsNotFilled = verifyAllRequiredFields(watch());
  const { data: farmData } = useFarmDetail(farmId);
  const isSelectedFarmPending =
    farmData && isFarmPending(farmData.status.value);

  return (
    <>
      <FormStack onSubmit={handleSubmit(onApprove)}>
        <FarmInfoPageHeader
          farmName={farmData?.doingBusinessAs ?? ""}
          status={UserStatusDtoValueEnum.Pending}
          displayStatusBadge={isFarmer && isSelectedFarmPending}
        >
          {!isFarmer && (
            <FormButtonGroup>
              <RejectFarmButton
                title="Are you sure you want to reject this farm?"
                msg="This action will remove this farm."
                onReject={onReject}
                isDisabled={!isEmployeeAdmin || isSubmitting}
              />
              <SubmitButton
                submitDisabled={!requiredFieldsNotFilled || !isEmployeeAdmin}
                isSubmitting={isSubmitting}
                buttonText="Approve"
              />
            </FormButtonGroup>
          )}
        </FarmInfoPageHeader>
        {isFarmer && isSelectedFarmPending && <FarmPendingApprovalBanner />}
        <FarmInfoTabGroup disableDocuments={true} />
        <FarmDetailFormInputs
          isDisabled={!isEmployeeAdmin}
          disableContactFields={true}
        />
      </FormStack>
      {alert}
    </>
  );
};

const ApproveFarmPage = () => (
  <FarmDataWrapper>
    <ApproveFarmForm />
  </FarmDataWrapper>
);

export default ApproveFarmPage;
