import { ReactElement } from "react";
import { Outlet } from "react-router-dom";

import { Center, HStack, Stack } from "@chakra-ui/react";
import ContentWrapper from "components/ContentWrapper";
import SiteHeader from "components/SiteHeader";

const CsvLayout = (): ReactElement => (
  <Stack h="100vh" overflow="hidden" spacing={0}>
    <SiteHeader />
    <HStack flex={1} spacing={0} align="stretch">
      <Center w="100%" overflow="hidden">
        <ContentWrapper maxW="100%" pt={8} pb={0}>
          <Stack spacing={8} h="100%">
            <Outlet />
          </Stack>
        </ContentWrapper>
      </Center>
    </HStack>
  </Stack>
);

export default CsvLayout;
