import { Stack } from "@chakra-ui/react";
import { FormInputs, PhoneInput, StandardInput } from "components/inputs";

const UserDetailFormInputs = ({
  isDisabled = false,
}: {
  isDisabled?: boolean;
}) => {
  return (
    <Stack spacing={2}>
      <FormInputs
        inputs={[
          {
            id: "firstName",
            label: "First Name",
            isRequired: true,
            component: StandardInput,
            isDisabled,
          },
          {
            id: "lastName",
            label: "Last Name",
            isRequired: true,
            component: StandardInput,
            isDisabled,
          },
          {
            id: "email",
            label: "Primary Email Address",
            isRequired: true,
            component: StandardInput,
            isDisabled: true, // TODO: For v1 email cannot be editing, change in v2 with this story https://bushel.atlassian.net/browse/RV-1209
          },
          {
            id: "phoneNumber",
            label: "Primary Phone Number",
            isRequired: true,
            component: PhoneInput,
            isDisabled,
          },
        ]}
      />
    </Stack>
  );
};

export default UserDetailFormInputs;
