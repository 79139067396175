import { SetStateAction, useCallback, useMemo } from "react";

import { SelectOption } from "components/Select";
import YearSelect from "components/YearSelect";
import useGetManureYear from "hooks/api/manure-year/useGetManureYear";
import useGetManureYears from "hooks/api/manure-year/useGetManureYears";
import useSelectedManureYearId from "hooks/useSelectedManureYearId";

const ManureYearSelect = () => {
  const { data, isLoading } = useGetManureYears();
  const [selectedManureYearId, setSelectedManureYearId] =
    useSelectedManureYearId();
  const { data: manureYearData, isLoading: isManureYearDataLoading } =
    useGetManureYear(selectedManureYearId);

  const options: SelectOption[] = useMemo(
    () =>
      data?.manureYears.map((manureYear) => ({
        label: `${manureYear.year}`,
        value: manureYear.id,
      })) ?? [],
    [data?.manureYears]
  );

  const onSelectOption = useCallback(
    (option: SetStateAction<SelectOption | undefined>) => {
      const nextManureYear = data?.manureYears.find(
        (_manureYear) =>
          _manureYear.id === (option as SelectOption | undefined)?.value
      );
      if (nextManureYear) {
        setSelectedManureYearId(nextManureYear.id);
      }
    },
    [data?.manureYears, setSelectedManureYearId]
  );

  return (
    <YearSelect
      options={options}
      yearId={manureYearData?.id}
      name="manureYearSelect"
      isLoading={isLoading || isManureYearDataLoading}
      setSelectedOption={onSelectOption}
    />
  );
};

export default ManureYearSelect;
