import { NotificationDto } from "api";
import { NotificationApi } from "api/apis/notification-api";
import {
  extractResponseData,
  transformNotificationResponse,
} from "./apiHelpers";
import { constructApiControllerInstance } from "./customApiConfig";

export type NewCropYearNotification = Omit<NotificationDto, "data"> & {
  data: { year: number; farmId: string };
};

class NotificationService {
  notificationController!: NotificationApi;
  constructor() {
    this.notificationController =
      constructApiControllerInstance(NotificationApi);
  }
  getNotification(notificationId: string): Promise<NotificationDto> {
    return this.notificationController
      .getNotificationById({ id: notificationId })
      .then(extractResponseData);
  }

  getNotificationList(): Promise<NewCropYearNotification[]> {
    return this.notificationController
      .listNotifications()
      .then(extractResponseData)
      .then((response) => response.map(transformNotificationResponse));
  }

  deleteNotification(notificationId: string): Promise<object> {
    return this.notificationController
      .deleteNotification({ id: notificationId })
      .then((response) => response.data);
  }
}

export default NotificationService;
