import { ReactElement } from "react";
import { IoCheckmarkCircle } from "react-icons/io5";
import Toast, {
  ToastCloseButton,
  ToastContent,
  ToastContentProps,
} from "./Toast";

export const SuccessToast = ({
  message,
  title,
  onClose,
}: ToastContentProps & {
  onClose: () => void;
}): ReactElement => {
  return (
    <Toast variant="success">
      <ToastContent message={message} title={title} icon={IoCheckmarkCircle} />
      <ToastCloseButton aria-label="Close button" onClick={() => onClose()} />
    </Toast>
  );
};
