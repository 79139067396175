import {
  DailyPricingApi,
  DailyPricingApiCreateDailyPricingRequest,
  DailyPricingResponse,
} from "api";
import { extractResponseData } from "./apiHelpers";
import { constructApiControllerInstance } from "./customApiConfig";

class DailyPricingService {
  dailyPricingController!: DailyPricingApi;
  constructor() {
    this.dailyPricingController =
      constructApiControllerInstance(DailyPricingApi);
  }

  getDailyPricing(): Promise<DailyPricingResponse> {
    return this.dailyPricingController
      .getDailyPricing()
      .then(extractResponseData);
  }

  createDailyPricing(
    requestBody: DailyPricingApiCreateDailyPricingRequest
  ): Promise<DailyPricingResponse> {
    return this.dailyPricingController
      .createDailyPricing(requestBody)
      .then(extractResponseData);
  }
}

export default DailyPricingService;
