import { Navigate, RouteObject, useSearchParams } from "react-router-dom";

import ErrorCard from "components/ErrorCard";
import UnprotectedRoute from "components/route-wrappers/UnprotectedRoute";
import BasicUserInfoCard from "pages/user-registration/BasicUserInfoCard";
import PhoneNumberFormCard from "pages/user-registration/PhoneNumberFormCard";
import TermsOfUseCard from "pages/user-registration/TermsOfUseCard";
import ParentProvider from "pages/user-registration/ParentProvider";
import ProfilePictureCards from "pages/user-registration/ProfilePicture";
import userRegistrationPaths from "./userRegistrationPaths";

export const RedirectToBasicInfoPath = () => {
  const [searchParams] = useSearchParams();

  return (
    <Navigate
      to={`./${userRegistrationPaths.children.basicUserInfoPath}?${searchParams}`}
      replace={true}
    />
  );
};

const userRegistrationRoutes: RouteObject = {
  path: userRegistrationPaths.basePath,
  element: (
    <UnprotectedRoute>
      <ParentProvider />
    </UnprotectedRoute>
  ),
  children: [
    {
      path: userRegistrationPaths.children.basicUserInfoPath,
      element: <BasicUserInfoCard />,
    },
    {
      path: userRegistrationPaths.children.phoneNumberPath,
      element: <PhoneNumberFormCard />,
    },
    {
      path: userRegistrationPaths.children.profilePicture,
      element: <ProfilePictureCards />,
    },
    {
      path: userRegistrationPaths.children.termsAndConditions,
      element: <TermsOfUseCard />,
    },
    {
      path: userRegistrationPaths.children.error,
      element: <ErrorCard />,
    },
    {
      index: true,
      element: <RedirectToBasicInfoPath />,
    },
    {
      path: "*",
      element: <RedirectToBasicInfoPath />,
    },
  ],
};

export default userRegistrationRoutes;
