import { Text, Tooltip } from "@chakra-ui/react";

import {
  NameWithPhoneColumn,
  UserStatusColumn,
} from "components/table-columns";
import { ColumnProps, TableData } from "components/SortableTable";
import { FarmListItem } from "hooks/farm/useFarmsList";
import { FarmListSortColumns } from "services/FarmService";

export const farmsListTableDataMapper = (data: FarmListItem[]): TableData[] =>
  data.map(
    ({
      id,
      farm: { name: farmName, phoneNumber: farmPhoneNumber, entityId },
      region,
      mainContact: {
        name: mainContactName,
        phoneNumber: mainContactPhoneNumber,
        profilePicture: mainContactProfilePicture,
      },
      status,
    }) => ({
      id,
      statusValue: status.value,
      regionId: region?.id,
      farm: (
        <NameWithPhoneColumn
          name={farmName}
          phoneNumber={farmPhoneNumber}
          showAvatar={false}
        />
      ),
      entityId: (
        <Tooltip hasArrow label={entityId ?? ""}>
          <Text fontWeight="bold" variant="truncated">
            {entityId}
          </Text>
        </Tooltip>
      ),
      mainContact: (
        <NameWithPhoneColumn
          name={mainContactName}
          phoneNumber={mainContactPhoneNumber}
          avatarImageSrc={mainContactProfilePicture?.src}
        />
      ),
      region: (
        <Tooltip hasArrow label={region?.name}>
          <Text variant={"truncated"}>{region?.name ?? ""}</Text>
        </Tooltip>
      ),
      status: <UserStatusColumn status={status} />,
    })
  );

type ColumnList = ColumnProps<FarmListSortColumns>[];

export const baseFarmsListColumns: ColumnList = [
  { heading: "Farm", id: "farm", sortKey: "doingBusinessAs" },
  { heading: "Status", id: "status", sortKey: "status" },
];

export const desktopOnlyFarmsListColumns: ColumnList = [
  { heading: "Entity Id", id: "entityId", sortKey: "externalEntityId" },
  {
    heading: "Main Contact",
    id: "mainContact",
    sortKey: "contact.firstName",
  },
  { heading: "Region", id: "region", sortKey: "region" },
];

export const desktopFarmsListColumns: ColumnList = [
  baseFarmsListColumns[0],
  ...desktopOnlyFarmsListColumns,
  baseFarmsListColumns[1],
];
