import { Checkbox, CheckboxProps } from "@chakra-ui/react";
import { ReactElement } from "react";

const ValidationCheckbox = ({
  label,
  ...props
}: {
  label: string;
} & CheckboxProps): ReactElement => (
  <Checkbox {...props} variant="validation" isReadOnly>
    {label}
  </Checkbox>
);

export default ValidationCheckbox;
