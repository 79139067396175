import { PropsWithChildren, ReactElement, useEffect } from "react";

import { Center, Spinner } from "@chakra-ui/react";

import useAccessToken from "hooks/auth/useAccessToken";
import useGetManureYears from "hooks/api/manure-year/useGetManureYears";
import useSelectedManureYearId from "hooks/useSelectedManureYearId";

const ManureYearProvider = ({ children }: PropsWithChildren): ReactElement => {
  const { accessToken } = useAccessToken();
  const [manureYearId, setManureYearId] = useSelectedManureYearId();

  const { data, isLoading } = useGetManureYears(!!accessToken);
  useEffect(() => {
    const manureYears = data?.manureYears;
    if (
      (!manureYearId ||
        (manureYearId &&
          !manureYears?.find(
            (manureYear) => manureYear.id === manureYearId
          ))) &&
      manureYears?.[0]
    ) {
      setManureYearId(manureYears[0].id);
    }
  }, [manureYearId, data?.manureYears, setManureYearId]);

  if (isLoading) {
    return (
      <Center minH="100vh">
        <Spinner />
      </Center>
    );
  }

  return <>{children}</>;
};

export default ManureYearProvider;
