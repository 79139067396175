import { ReactElement } from "react";
import { Flex, Text } from "@chakra-ui/react";
import { chakraComponents } from "chakra-react-select";
import { InputWrapperProps, SelectInput } from "components/inputs";
import { SelectInputProps } from "components/inputs/SelectInput";
import { SelectProps } from "components/Select/Select";
import { SELECT_MAX_HEIGHT } from "components/YearSelect";
import { formatDate, parseDate } from "utils/dateUtils";
import { FuturesPriceDto } from "api";

export const FuturesOptionText = ({
  friendlySymbol,
  symbol,
}: {
  friendlySymbol: string;
  symbol: string | undefined;
}) => {
  const parseCropFuturesDate = parseDate(friendlySymbol, "MMMM yyyy");
  const cropFuturesDateFormatted = formatDate(parseCropFuturesDate, "MMM yyyy");

  return (
    <Flex justify="space-between" w="100%">
      <Text>{cropFuturesDateFormatted}</Text>
      <Text>{symbol}</Text>
    </Flex>
  );
};

const customComponents: SelectProps["components"] = {
  Option: (props) => {
    const { label, secondLabel } = props.data;

    return (
      <chakraComponents.Option {...props}>
        <FuturesOptionText friendlySymbol={label} symbol={secondLabel} />
      </chakraComponents.Option>
    );
  },
  SingleValue: (props) => {
    const { label, secondLabel } = props.data;

    return (
      <chakraComponents.SingleValue {...props}>
        <FuturesOptionText friendlySymbol={label} symbol={secondLabel} />
      </chakraComponents.SingleValue>
    );
  },
  MenuList: (props) => {
    return (
      <chakraComponents.MenuList {...props} maxHeight={SELECT_MAX_HEIGHT} />
    );
  },
};

export const FuturesInput = ({
  data,
  isLoading,
  isError,
  props,
}: {
  data: FuturesPriceDto[] | undefined;
  isLoading: boolean;
  isError: boolean;
  props: SelectInputProps & InputWrapperProps;
}): ReactElement => (
  <SelectInput
    options={data?.map((future) => ({
      value: future.symbol,
      label: `${future.friendlySymbol}`,
      secondLabel: `(${future.symbol})`,
    }))}
    isLoading={isLoading}
    isError={isError}
    reactSelectProps={{
      components: customComponents,
      getOptionLabel: (options) => `${options.label} ${options.secondLabel}`,
      noOptionsMessage: () => "Failed to load options",
    }}
    {...props}
  />
);

export default FuturesInput;
