import { CropYearApiListCropYearsRequest, CropYearListDto } from "api";
import { useCropYearService } from "contexts/ServiceProvider";
import { useQuery } from "@tanstack/react-query";
import { BaseApiError, PaginationMeta } from "services/apiHelpers";
import { GetCropYearsResponse } from "services/CropYearService";

export const GET_CROP_YEARS_QUERY_KEY = "crop-years";

export type CropYearListItem = {
  id: string;
  year: number;
};

export const transformCropYearResponse = (
  cropYear: CropYearListDto
): CropYearListItem => {
  return {
    id: cropYear.id,
    year: cropYear.year,
  };
};

export type GetCropYearsListItemResponse = {
  cropYears: CropYearListItem[];
  meta: PaginationMeta;
};

const useCropYears = (queryParams?: CropYearApiListCropYearsRequest) => {
  const cropYearService = useCropYearService();

  return useQuery<
    GetCropYearsResponse,
    BaseApiError,
    GetCropYearsListItemResponse
  >({
    queryKey: [
      GET_CROP_YEARS_QUERY_KEY,
      queryParams,
      { page: queryParams?.page },
    ],
    queryFn: () =>
      cropYearService.getCropYears({
        ...queryParams,
        page: queryParams?.page ?? 0,
      }),
    select: ({ data, meta }) => ({
      cropYears: data.map(transformCropYearResponse),
      meta,
    }),
  });
};

export default useCropYears;
