import { ReactElement } from "react";

import { Flex, Text } from "@chakra-ui/react";

import InputLabel from "components/inputs/InputLabel";

const Multiplier = ({ value }: { value: number }): ReactElement => (
  <Flex justify="space-between">
    <InputLabel label="Multiplier" formLabelProps={{ m: 0 }} />
    <Text>{`${value}x`}</Text>
  </Flex>
);

export default Multiplier;
