import { ReactNode } from "react";
import { ChakraProvider } from "@chakra-ui/react";
import ReactQueryProvider from "contexts/ReactQueryProvider";
import theme from "../theme";
import { QueryClient } from "@tanstack/react-query";

const AppContextProviders = ({
  children,
  queryClient,
}: {
  children: ReactNode;
  queryClient?: QueryClient;
}) => (
  <ReactQueryProvider queryClient={queryClient}>
    <ChakraProvider theme={theme}>{children}</ChakraProvider>
  </ReactQueryProvider>
);

export default AppContextProviders;
