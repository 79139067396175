import { PropsWithChildren } from "react";
import { HStack, Stack, Text } from "@chakra-ui/react";
import { CheckmarkThin } from "icons";

const CsvResultsSuccessMessage = ({ children }: PropsWithChildren) => (
  <HStack spacing={2} align="flex-start">
    <CheckmarkThin fontWeight="normal" boxSize="24px" />
    <Stack spacing="10px" mb={3}>
      <Text fontWeight="bold" color="brand.800">
        Success
      </Text>
      <Text color="brand.800">{children}</Text>
    </Stack>
  </HStack>
);

export default CsvResultsSuccessMessage;
