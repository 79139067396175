import { GuideListItemType } from "../ErrorGuideList";

export const fieldsInfoErrorGuideData: Array<GuideListItemType> = [
  {
    headingText: "Field ID",
    listItems: [
      {
        text: `Field ID is required and must be entered for all Fields in the .csv file.`,
      },
      {
        text: `Duplicate Field ID: there is more than one 
                Field with this Field number in the Grower Portal.`,
      },
      {
        text: `Field IDs must be unique in the crop year.`,
      },
      {
        text: `Field ID must be 16-characters or less.`,
      },
    ],
  },
  {
    headingText: "Region",
    listItems: [
      {
        text: "Region is required and must be entered for all Fields in the .csv file.",
      },
      {
        text: `Region abbreviation does not match the regions 
          mapped in the Grower Portal. Accepted region abbreviations are:`,
        listItems: [
          {
            text: "LNR - Lake Norden",
          },
          {
            text: "MAD - Marshall",
          },
          {
            text: "RR - Red River",
          },
          {
            text: "STV - Stevens",
          },
          {
            text: "SWF - Swift",
          },
          {
            text: "WKN - Waukon",
          },
        ],
      },
      {
        text: `The Region and Entity ID combination does not match what is saved in the
            Grower Portal Verify that the Farm is assigned the right Region on the Farm
            Information page in the Grower Portal and the Field is assigned to the
            correct Entity ID in the .csv file.`,
      },
    ],
  },
  {
    headingText: "Entity ID",
    listItems: [
      {
        text: `Entity ID is required and must be entered for all Fields in the .csv
          file.`,
      },
      {
        text: "Entity ID does not match a Farm Business in the Grower Portal.",
      },
      {
        text: `The Farm associated to this Entity ID is not assigned to the selected
          crop year.`,
      },
    ],
  },
  {
    headingText: "Field Common Name",
    listItems: [
      {
        text: "Field Common Name must be 255-characters or less.",
      },
    ],
  },
  {
    headingText: "Tillable Acres",
    listItems: [
      {
        text: `Tillable Acres is required and must be entered for all Fields in the
          .csv file.`,
      },
      {
        text: "Tillable Acres must be 6-characters or less.",
      },
      {
        text: "Tillable Acres cannot have more than 2-decimal places.",
      },
      {
        text: "Tillable Acres must be a number.",
      },
    ],
  },
  {
    headingText: "Forage Dairy",
    listItems: [
      {
        text: "Forage Dairy must be 46-characters or less.",
      },
      {
        text: `The Dairy and Field ID combination does not match what is saved in the
          Grower Portal. Verify that the Dairy site is assigned to the right
          Field on the Field Information page and in the .csv file.`,
      },
      {
        text: `Dairy abbreviation does not match the dairies mapped in the Grower
          Portal. Accepted dairy abbreviations are:`,
        listItems: [
          {
            text: "Lake Norden",
            listItems: [
              {
                text: "Clark: CLD",
              },
              {
                text: "Washington: WSD",
              },
              {
                text: "Garfield: GAD",
              },
            ],
          },
          {
            text: "Marshall",
            listItems: [
              {
                text: "Marshall: MAD",
              },
            ],
          },
          {
            text: "Red River",
            listItems: [
              {
                text: "Campbell: CAD",
              },
              {
                text: "North Ottawa: NOD",
              },
              {
                text: "Dollymount: DMD",
              },
            ],
          },
          {
            text: "Stevens",
            listItems: [
              {
                text: "West River: WRD",
              },
              {
                text: "Riverview: RVD",
              },
              {
                text: "District 45: D45",
              },
              {
                text: "Moore Calves: MOC",
              },
            ],
          },
          {
            text: "Swift",
            listItems: [
              {
                text: "Swenoda: SWD",
              },
              {
                text: "West Dublin: WDD",
              },
              {
                text: "Louriston: LOD",
              },
              {
                text: "East Dublin: EDD",
              },
              {
                text: "Meadow Star: MSD",
              },
              {
                text: "Hawk Creek Calves: HCC",
              },
            ],
          },
          {
            text: "Waukon",
            listItems: [
              {
                text: "Waukon: WAD",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    headingText: "Legal Description",
    listItems: [
      {
        text: "Legal description must be 100-characters or less.",
      },
    ],
  },
  {
    headingText: "Grower Distance",
    listItems: [
      {
        text: "Grower distance must be 4-characters or less",
      },
      {
        text: "Grower distance cannot have more than 2-decimal places.",
      },
    ],
  },
];
