/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosRequestConfig,
} from "axios";
import { Configuration } from "../configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "../base";
// @ts-ignore
import { DataProfilePictureResponse } from "../models";
/**
 * ProfilePictureApi - axios parameter creator
 * @export
 */
export const ProfilePictureApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProfilePicture: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/profile-picture`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProfilePictureDuringRegistration: async (
      token: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      assertParamExists(
        "deleteProfilePictureDuringRegistration",
        "token",
        token
      );
      const localVarPath = `/profile-picture/registration`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (token !== undefined) {
        localVarQueryParameter["token"] = token;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {any} picture
     * @param {number} xoffset
     * @param {number} yoffset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setProfilePicture: async (
      picture: any,
      xoffset: number,
      yoffset: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'picture' is not null or undefined
      assertParamExists("setProfilePicture", "picture", picture);
      // verify required parameter 'xoffset' is not null or undefined
      assertParamExists("setProfilePicture", "xoffset", xoffset);
      // verify required parameter 'yoffset' is not null or undefined
      assertParamExists("setProfilePicture", "yoffset", yoffset);
      const localVarPath = `/profile-picture`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (picture !== undefined) {
        localVarFormParams.append("picture", picture as any);
      }

      if (xoffset !== undefined) {
        localVarFormParams.append("xoffset", xoffset as any);
      }

      if (yoffset !== undefined) {
        localVarFormParams.append("yoffset", yoffset as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} token
     * @param {any} picture
     * @param {number} xoffset
     * @param {number} yoffset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setProfilePictureDuringRegistration: async (
      token: string,
      picture: any,
      xoffset: number,
      yoffset: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      assertParamExists("setProfilePictureDuringRegistration", "token", token);
      // verify required parameter 'picture' is not null or undefined
      assertParamExists(
        "setProfilePictureDuringRegistration",
        "picture",
        picture
      );
      // verify required parameter 'xoffset' is not null or undefined
      assertParamExists(
        "setProfilePictureDuringRegistration",
        "xoffset",
        xoffset
      );
      // verify required parameter 'yoffset' is not null or undefined
      assertParamExists(
        "setProfilePictureDuringRegistration",
        "yoffset",
        yoffset
      );
      const localVarPath = `/profile-picture/registration`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      if (token !== undefined) {
        localVarFormParams.append("token", token as any);
      }

      if (picture !== undefined) {
        localVarFormParams.append("picture", picture as any);
      }

      if (xoffset !== undefined) {
        localVarFormParams.append("xoffset", xoffset as any);
      }

      if (yoffset !== undefined) {
        localVarFormParams.append("yoffset", yoffset as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ProfilePictureApi - functional programming interface
 * @export
 */
export const ProfilePictureApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    ProfilePictureApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteProfilePicture(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteProfilePicture(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteProfilePictureDuringRegistration(
      token: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteProfilePictureDuringRegistration(
          token,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {any} picture
     * @param {number} xoffset
     * @param {number} yoffset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setProfilePicture(
      picture: any,
      xoffset: number,
      yoffset: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataProfilePictureResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.setProfilePicture(
          picture,
          xoffset,
          yoffset,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} token
     * @param {any} picture
     * @param {number} xoffset
     * @param {number} yoffset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setProfilePictureDuringRegistration(
      token: string,
      picture: any,
      xoffset: number,
      yoffset: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataProfilePictureResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.setProfilePictureDuringRegistration(
          token,
          picture,
          xoffset,
          yoffset,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * ProfilePictureApi - factory interface
 * @export
 */
export const ProfilePictureApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ProfilePictureApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProfilePicture(options?: any): AxiosPromise<string> {
      return localVarFp
        .deleteProfilePicture(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProfilePictureDuringRegistration(
      token: string,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .deleteProfilePictureDuringRegistration(token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {any} picture
     * @param {number} xoffset
     * @param {number} yoffset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setProfilePicture(
      picture: any,
      xoffset: number,
      yoffset: number,
      options?: any
    ): AxiosPromise<DataProfilePictureResponse> {
      return localVarFp
        .setProfilePicture(picture, xoffset, yoffset, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} token
     * @param {any} picture
     * @param {number} xoffset
     * @param {number} yoffset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setProfilePictureDuringRegistration(
      token: string,
      picture: any,
      xoffset: number,
      yoffset: number,
      options?: any
    ): AxiosPromise<DataProfilePictureResponse> {
      return localVarFp
        .setProfilePictureDuringRegistration(
          token,
          picture,
          xoffset,
          yoffset,
          options
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for deleteProfilePictureDuringRegistration operation in ProfilePictureApi.
 * @export
 * @interface ProfilePictureApiDeleteProfilePictureDuringRegistrationRequest
 */
export interface ProfilePictureApiDeleteProfilePictureDuringRegistrationRequest {
  /**
   *
   * @type {string}
   * @memberof ProfilePictureApiDeleteProfilePictureDuringRegistration
   */
  readonly token: string;
}

/**
 * Request parameters for setProfilePicture operation in ProfilePictureApi.
 * @export
 * @interface ProfilePictureApiSetProfilePictureRequest
 */
export interface ProfilePictureApiSetProfilePictureRequest {
  /**
   *
   * @type {any}
   * @memberof ProfilePictureApiSetProfilePicture
   */
  readonly picture: any;

  /**
   *
   * @type {number}
   * @memberof ProfilePictureApiSetProfilePicture
   */
  readonly xoffset: number;

  /**
   *
   * @type {number}
   * @memberof ProfilePictureApiSetProfilePicture
   */
  readonly yoffset: number;
}

/**
 * Request parameters for setProfilePictureDuringRegistration operation in ProfilePictureApi.
 * @export
 * @interface ProfilePictureApiSetProfilePictureDuringRegistrationRequest
 */
export interface ProfilePictureApiSetProfilePictureDuringRegistrationRequest {
  /**
   *
   * @type {string}
   * @memberof ProfilePictureApiSetProfilePictureDuringRegistration
   */
  readonly token: string;

  /**
   *
   * @type {any}
   * @memberof ProfilePictureApiSetProfilePictureDuringRegistration
   */
  readonly picture: any;

  /**
   *
   * @type {number}
   * @memberof ProfilePictureApiSetProfilePictureDuringRegistration
   */
  readonly xoffset: number;

  /**
   *
   * @type {number}
   * @memberof ProfilePictureApiSetProfilePictureDuringRegistration
   */
  readonly yoffset: number;
}

/**
 * ProfilePictureApi - object-oriented interface
 * @export
 * @class ProfilePictureApi
 * @extends {BaseAPI}
 */
export class ProfilePictureApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProfilePictureApi
   */
  public deleteProfilePicture(options?: AxiosRequestConfig) {
    return ProfilePictureApiFp(this.configuration)
      .deleteProfilePicture(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ProfilePictureApiDeleteProfilePictureDuringRegistrationRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProfilePictureApi
   */
  public deleteProfilePictureDuringRegistration(
    requestParameters: ProfilePictureApiDeleteProfilePictureDuringRegistrationRequest,
    options?: AxiosRequestConfig
  ) {
    return ProfilePictureApiFp(this.configuration)
      .deleteProfilePictureDuringRegistration(requestParameters.token, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ProfilePictureApiSetProfilePictureRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProfilePictureApi
   */
  public setProfilePicture(
    requestParameters: ProfilePictureApiSetProfilePictureRequest,
    options?: AxiosRequestConfig
  ) {
    return ProfilePictureApiFp(this.configuration)
      .setProfilePicture(
        requestParameters.picture,
        requestParameters.xoffset,
        requestParameters.yoffset,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ProfilePictureApiSetProfilePictureDuringRegistrationRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProfilePictureApi
   */
  public setProfilePictureDuringRegistration(
    requestParameters: ProfilePictureApiSetProfilePictureDuringRegistrationRequest,
    options?: AxiosRequestConfig
  ) {
    return ProfilePictureApiFp(this.configuration)
      .setProfilePictureDuringRegistration(
        requestParameters.token,
        requestParameters.picture,
        requestParameters.xoffset,
        requestParameters.yoffset,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
