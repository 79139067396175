import { ComponentStyleConfig } from "@chakra-ui/react";

const Toast: ComponentStyleConfig = {
  parts: ["toast", "icon", "message", "close"],
  baseStyle: {
    toast: {
      borderRadius: "lg",
      py: "12px",
      pl: 4,
      pr: 3,
      flexDir: "row",
      display: "flex",
      alignItems: "center",
      position: "relative",
    },
    icon: {
      color: "white",
      mr: "14px",
    },
    message: {
      color: "white",
      pr: 5,
    },
    title: {
      fontWeight: "bold",
      color: "white",
      pr: 5,
    },
    close: {
      color: "white",
      position: "absolute",
      right: 1,
      top: 1,
      display: "flex",
      alignItems: "center",
      alignSelf: "flex-end",
    },
  },
  variants: {
    success: {
      toast: {
        bgColor: "brand.800",
        minW: "356px",
      },
    },
    error: {
      toast: {
        bgColor: "errorRed",
        w: "356px",
        py: "12px",
      },
      close: {
        bg: "errorRed",
        _active: { bg: "error.700" },
        _hover: { bg: "error.700" },
        _focusVisible: {
          bg: `error.700`,
          border: "2px solid",
          borderColor: "errorRed",
        },
      },
    },
  },
};

export default Toast;
