/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { FarmUserClaimRequestDto } from "./farm-user-claim-request-dto";

/**
 *
 * @export
 * @interface UpdateUserRequest
 */
export interface UpdateUserRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateUserRequest
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof UpdateUserRequest
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof UpdateUserRequest
   */
  phoneNumber?: string;
  /**
   *
   * @type {Array<FarmUserClaimRequestDto>}
   * @memberof UpdateUserRequest
   */
  farmUserClaims: Array<FarmUserClaimRequestDto>;
  /**
   *
   * @type {string}
   * @memberof UpdateUserRequest
   */
  role: UpdateUserRequestRoleEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum UpdateUserRequestRoleEnum {
  RiverviewAdmin = "RIVERVIEW_ADMIN",
  RiverviewEmployee = "RIVERVIEW_EMPLOYEE",
  FarmAdmin = "FARM_ADMIN",
  FarmViewer = "FARM_VIEWER",
}
