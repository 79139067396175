import {
  FormErrorMessage,
  FormHelperText,
  HStack,
  Stack,
  Text,
} from "@chakra-ui/react";
import { CheckmarkCircle } from "icons";
import TextErrorMessage from "components/TextErrorMessage";

export type InputStatusMessageProps = {
  isSuccess?: boolean;
  successMsg?: string;
  errorMsg?: string;
  hasInfo?: boolean;
  infoMsg?: string;
};

const InputStatusMessage = ({
  isSuccess,
  successMsg,
  errorMsg,
  hasInfo,
  infoMsg,
}: InputStatusMessageProps) => (
  <Stack spacing={2} mt={2}>
    {isSuccess && (
      <HStack spacing={2} mt={0}>
        <CheckmarkCircle />
        <Text textStyle="label" color="brand.800">
          {successMsg}
        </Text>
      </HStack>
    )}
    {errorMsg && (
      <FormErrorMessage mt={0}>
        <TextErrorMessage>{errorMsg ?? ""}</TextErrorMessage>
      </FormErrorMessage>
    )}
    {hasInfo && (
      <FormHelperText mt={0}>
        <Text textStyle="label" color="greyFactor.4">
          {infoMsg}
        </Text>
      </FormHelperText>
    )}
  </Stack>
);

export default InputStatusMessage;
