import { useCallback } from "react";

import {
  CSV_FILE_SIZE_REQUIREMENT,
  CSV_FILE_TYPE_REQUIREMENT,
} from "constants/csvRequirements";
import useUploadActualTonsCsv from "hooks/csv-upload/useUploadActualTonsCsv";
import CsvFileSelect from "pages/csv-uploads/CsvFileSelect";

const ACTUAL_TONS_CSV_REQUIRED_COLUMNS_REQUIREMENT =
  "File must contain at least Dairy Abbreviation, Field ID, and Tons @ 65 %";
const CSV_UPLOAD_OVERWRITE_ACTUAL_TONS_WARNING_REQUIREMENT =
  "This upload may overwrite existing Actual Tons data";

const actualTonsCsvFileRequirements = [
  { message: CSV_FILE_TYPE_REQUIREMENT },
  { message: CSV_FILE_SIZE_REQUIREMENT },
  { message: ACTUAL_TONS_CSV_REQUIRED_COLUMNS_REQUIREMENT },
  { message: CSV_UPLOAD_OVERWRITE_ACTUAL_TONS_WARNING_REQUIREMENT },
];

const ActualTonsFileSelect = () => {
  const { mutateAsync, isPending: isLoading } = useUploadActualTonsCsv();
  const onUploadCsvFile = useCallback(
    (file: File) => mutateAsync({ csvFile: file }),
    [mutateAsync]
  );

  return (
    <CsvFileSelect
      fileRequirements={actualTonsCsvFileRequirements}
      isLoading={isLoading}
      onUploadCsvFile={onUploadCsvFile}
    />
  );
};

export default ActualTonsFileSelect;
