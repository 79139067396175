import { ColorModeScript } from "@chakra-ui/react";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { App } from "App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import "focus-visible/dist/focus-visible";
import AppContextProviders from "contexts/AppContextProviders";

const prepare = () => {
  if (
    process.env.NODE_ENV === "development" &&
    process.env.REACT_APP_USE_MOCK_SERVER === "true"
  ) {
    return import("./mocks/browser").then(({ worker }) => worker.start());
  }

  return Promise.resolve();
};

prepare().then(() => {
  const rootElement = document.getElementById("root");
  if (rootElement) {
    createRoot(rootElement).render(
      <StrictMode>
        <ColorModeScript />
        <AppContextProviders>
          <App />
        </AppContextProviders>
      </StrictMode>
    );
  }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
