import ForgotPasswordFormProvider from "contexts/ForgotPasswordFormContext";
import Layout from "pages/user-registration/Layout";
import { ReactElement } from "react";
import { Outlet } from "react-router-dom";

const ParentProvider = (): ReactElement => (
  <Layout>
    <ForgotPasswordFormProvider>
      <Outlet />
    </ForgotPasswordFormProvider>
  </Layout>
);

export default ParentProvider;
