/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface MonthlyAverageListResponseDto
 */
export interface MonthlyAverageListResponseDto {
  /**
   *
   * @type {string}
   * @memberof MonthlyAverageListResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof MonthlyAverageListResponseDto
   */
  month: MonthlyAverageListResponseDtoMonthEnum;
  /**
   *
   * @type {number}
   * @memberof MonthlyAverageListResponseDto
   */
  tons: number;
  /**
   *
   * @type {number}
   * @memberof MonthlyAverageListResponseDto
   */
  cashPrice: number;
  /**
   *
   * @type {number}
   * @memberof MonthlyAverageListResponseDto
   */
  multiplier: number;
  /**
   *
   * @type {number}
   * @memberof MonthlyAverageListResponseDto
   */
  distanceAdjustment: number;
  /**
   *
   * @type {number}
   * @memberof MonthlyAverageListResponseDto
   */
  contractPrice: number;
}

/**
 * @export
 * @enum {string}
 */
export enum MonthlyAverageListResponseDtoMonthEnum {
  January = "JANUARY",
  February = "FEBRUARY",
  March = "MARCH",
  April = "APRIL",
  May = "MAY",
  June = "JUNE",
  July = "JULY",
  August = "AUGUST",
  September = "SEPTEMBER",
  October = "OCTOBER",
  November = "NOVEMBER",
  December = "DECEMBER",
}
