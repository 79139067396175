import { ReactElement } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

import { FarmUserClaimDto } from "api";
import SelectInput from "components/inputs/SelectInput";
import { InputWrapper, StandardInput } from "components/inputs";

export const FARM_CLAIMS_FIELD_NAME = "farmClaims";

const FarmClaimInputs = ({
  farmClaims,
  isDisabled = true,
}: {
  farmClaims: FarmUserClaimDto[];
  isDisabled?: boolean;
}): ReactElement => {
  const { control } = useFormContext();
  const { fields } = useFieldArray({
    control,
    name: FARM_CLAIMS_FIELD_NAME,
  });

  return fields.length > 0 ? (
    <>
      {fields.map((field, index) => (
        <SelectInput
          isDisabled={isDisabled}
          key={field.id}
          id={`${FARM_CLAIMS_FIELD_NAME}.${index}`}
          label={`${farmClaims[index]?.farmName} Role`}
          options={[
            {
              value: farmClaims[index].role.value, // TODO: Replace the only option with a server driven set of options for each user's farm claim
              label: farmClaims[index].role.displayName,
            },
          ]}
        />
      ))}
    </>
  ) : (
    <InputWrapper label="No farm has been added yet" isDisabled>
      <StandardInput />
    </InputWrapper>
  );
};

export default FarmClaimInputs;
