import { MenuItem, HStack, Text, useDisclosure } from "@chakra-ui/react";
import SelectFarmModal from "forms/delayed-pricing/components/SelectFarmModal";

const ManualDelayedPricingMenuItem = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <MenuItem onClick={onOpen}>
        <HStack>
          <HStack spacing={4}>
            <Text fontWeight="normal">Manual Delayed Pricing</Text>
          </HStack>
        </HStack>
      </MenuItem>
      {isOpen && <SelectFarmModal isOpen={isOpen} onClose={onClose} />}
    </>
  );
};

export default ManualDelayedPricingMenuItem;
