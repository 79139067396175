import { ComponentStyleConfig } from "@chakra-ui/react";

const baseFarmMenuButton = {
  textAlign: "left",
  bgColor: "brand.800",
  borderRadius: "lg",
  _active: {
    bgColor: "brand.900",
  },
  _hover: {
    bgColor: "brand.900",
  },
};

const Menu: ComponentStyleConfig = {
  baseStyle: {
    list: {
      border: "none",
    },
    item: {
      px: 4,
      py: 2,
      color: "charcoal",
    },
    button: {
      _hover: {
        bgColor: "grayFactor.1",
      },
      _active: {
        bgColor: "grayFactor.1",
      },
    },
  },
  variants: {
    userMenu: {
      button: {
        p: 4,
        borderRadius: "46px",
      },
    },
    farmMenu: {
      button: baseFarmMenuButton,
    },
    farmMenuDisabled: {
      button: {
        ...baseFarmMenuButton,
        pointerEvents: "none",
      },
    },
    cropYear: {
      button: {
        borderRadius: "full",
        h: 10,
        w: 10,
      },
    },
  },
};

export default Menu;
