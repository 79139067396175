import { skipToken, useInfiniteQuery } from "@tanstack/react-query";
import { usePricingOptionService } from "contexts/ServiceProvider";
import { getNextPage, QueryParams } from "services/apiHelpers";
import { FarmCropYearPricingOptionsSortColumns } from "services/PricingOptionService";

export const GET_FARM_CROP_YEAR_PRICING_OPTIONS =
  "get-farm-crop-year-pricing-options";

const useFarmCropYearPricingOptions = ({
  cropYearId,
  farmId,
  queryParams,
}: {
  cropYearId?: string;
  farmId?: string;
  queryParams?: QueryParams<FarmCropYearPricingOptionsSortColumns>;
}) => {
  const pricingOptionService = usePricingOptionService();

  return useInfiniteQuery({
    queryKey: [
      GET_FARM_CROP_YEAR_PRICING_OPTIONS,
      { cropYearId, farmId },
      queryParams,
    ],
    queryFn:
      cropYearId && farmId
        ? ({ pageParam = 0 }) =>
            pricingOptionService.getFarmCropYearPricingOptions({
              cropYearId,
              farmId,
              queryParams: {
                ...queryParams,
                page: pageParam,
              },
            })
        : skipToken,
    initialPageParam: 0,
    getNextPageParam: getNextPage,
    gcTime: 0,
  });
};

export default useFarmCropYearPricingOptions;
