import { ReactElement } from "react";

import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";

import NavigationContainer from "../NavigationContainer";
import { HamburgerIcon } from "icons";

const NavigationDrawer = (): ReactElement | null => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <IconButton
        aria-label="Open navigation"
        icon={<HamburgerIcon />}
        onClick={onOpen}
        variant="link"
        minW="unset"
      />
      <Drawer isOpen={isOpen} onClose={onClose} placement="left">
        <DrawerContent bg="grayFactor.1">
          <DrawerHeader p={4}>
            <DrawerCloseButton position="unset" variant="link" />
          </DrawerHeader>
          <DrawerBody p={0}>
            <NavigationContainer onClickItem={onClose} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default NavigationDrawer;
