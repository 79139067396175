import { RegionDto } from "api";
import { useRegionService } from "contexts/ServiceProvider";
import { useQuery } from "@tanstack/react-query";
import { BaseApiError } from "services/apiHelpers";
import useUserRoleFlags from "hooks/auth/useUserRoleFlags";

export const GET_REGIONS_QUERY_KEY = "get-regions";

const useRegionOptions = () => {
  const regionService = useRegionService();
  const { isEmployee } = useUserRoleFlags();

  return useQuery<RegionDto[], BaseApiError>({
    queryKey: [GET_REGIONS_QUERY_KEY],
    queryFn: () => regionService.getRegions(),
    retry: 1,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    enabled: isEmployee,
  });
};

export default useRegionOptions;
