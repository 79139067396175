import { ReactElement } from "react";
import { IconButton, IconButtonProps } from "@chakra-ui/react";

import { SortBoth, SortDown, SortUp } from "icons";
import { SortDirection } from "hooks/useSort";

const SortArrow = ({
  direction,
  ...rest
}: {
  direction: SortDirection;
} & Omit<IconButtonProps, "aria-label">): ReactElement => {
  switch (direction) {
    case SortDirection.ASC:
      return (
        <IconButton
          aria-label="Sort Ascending"
          icon={<SortDown />}
          variant="link"
          {...rest}
        />
      );
    case SortDirection.DESC:
      return (
        <IconButton
          aria-label="Sort Descending"
          icon={<SortUp />}
          variant="link"
          {...rest}
        />
      );
    case SortDirection.NEUTRAL:
    default:
      return (
        <IconButton
          aria-label="No sort"
          icon={<SortBoth />}
          variant="link"
          {...rest}
        />
      );
  }
};

export default SortArrow;
