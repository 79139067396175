import { Center, Stack } from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import BackToBasicInfoLink from "pages/user-registration/components/BackToBasicInfoLink";
import { PhoneInput, FormInputs } from "components/inputs";
import SubmitButton from "components/SubmitButton";
import { useRegistrationForm } from "contexts/RegistrationFormContext";
import { CardBody, CardFooter } from "components/Card";
import { extractNumbers } from "utils/stringUtils";
import { phoneNumberFormSchema } from "./schemas/phoneNumberFormSchema";

const PhoneNumberForm = ({
  onSubmitSuccess,
}: {
  onSubmitSuccess?: () => void;
}) => {
  const { phoneNumber, onSetPhoneNumber } = useRegistrationForm();

  const methods = useForm<{ phoneNumber: PhoneNumber }>({
    resolver: yupResolver(phoneNumberFormSchema),
    mode: "onSubmit",
    defaultValues: {
      phoneNumber: phoneNumber,
    },
  });

  const saveNumIf10Digits = () => {
    const _phoneNumber = methods.getValues().phoneNumber;
    if (extractNumbers(_phoneNumber ?? "").length === 10) {
      onSetPhoneNumber(_phoneNumber);
    }
  };

  const requiredFieldsFilled = !!methods.watch().phoneNumber;

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit((data) => {
          onSetPhoneNumber(data.phoneNumber);
          onSubmitSuccess?.();
        })}
        style={{ width: "100%" }}
      >
        <CardBody>
          <Stack spacing={4} w="100%">
            <FormInputs
              inputs={[
                {
                  id: "phoneNumber",
                  label: "Phone Number",
                  component: PhoneInput,
                  isRequired: true,
                },
              ]}
            />
          </Stack>
        </CardBody>
        <CardFooter mt={4}>
          <Stack spacing={8} w="100%">
            <SubmitButton
              submitDisabled={!requiredFieldsFilled}
              isSubmitting={methods.formState.isSubmitting}
              buttonText="Continue"
            />
            <Center w="100%">
              <BackToBasicInfoLink callback={saveNumIf10Digits} />
            </Center>
          </Stack>
        </CardFooter>
      </form>
    </FormProvider>
  );
};

export default PhoneNumberForm;
