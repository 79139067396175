import { Text } from "@chakra-ui/react";
import { TableData } from "components/SortableTable";
import { CropYearPricingOptionDto } from "api";
import { NameWithPhoneColumn } from "components/table-columns";
import DateOfContractWithContractType from "components/table-columns/DateOfContractWithContractTypeColumn";
import FarmNameWithDairy from "components/table-columns/FarmNameWithDairyColumn";
import StatusColumn from "components/table-columns/StatusColumn";
import { CONTRACT_TYPE } from "constants/contractTypeEnum";
import { ColumnList, priceColumn } from "./individualFarmPricingListColumns";
import { formatDate, parseDate } from "utils/dateUtils";
import { formatNumberWithDecimals, formatUSD } from "utils/formatFunctions";
import { getStatusObject } from "utils/getStatusObject";

export const riverviewPricingListTableColumns = ({
  data,
  isPreHarvest = true,
}: {
  data: CropYearPricingOptionDto[];
  isPreHarvest?: boolean;
}): TableData[] =>
  data.map(
    ({
      basePrice,
      dairies,
      dateOfContract,
      deferred,
      farm,
      monthlyAveragePrices,
      netTotal,
      percentage,
      region,
      status,
      tons,
      type,
    }) => {
      const baseColumns = {
        basePrice: priceColumn({
          price: basePrice,
          monthlyPrices: monthlyAveragePrices,
          type,
          unitOfMeasure: "ton",
        }),

        status: <StatusColumn status={getStatusObject(status.status, type)} />,
      };

      const baseColumnsPreHarvest = {
        ...baseColumns,
        farm: (
          <NameWithPhoneColumn
            name={farm.name}
            phoneNumber={farm.phoneNumber}
            showAvatar={false}
          />
        ),
        type: <Text fontWeight="bold">{type}</Text>,
        percentage: `${percentage}%`,
        region: region,
        dateOfContract: dateOfContract
          ? formatDate(parseDate(dateOfContract))
          : "",
      };

      const baseColumnsPostHarvest = {
        ...baseColumns,
        farm: <FarmNameWithDairy farmName={farm.name} dairies={dairies} />,
        tons: (
          <Text>
            {type === CONTRACT_TYPE.DELAYED_PRICING
              ? formatNumberWithDecimals(tons ?? 0, 8)
              : formatNumberWithDecimals(tons ?? 0)}
          </Text>
        ),
        deferred:
          deferred !== "No" ? formatDate(parseDate(deferred)) : deferred,
        netTotal: <Text>{formatUSD(netTotal ?? 0)}</Text>,
        dateOfContract: (
          <DateOfContractWithContractType
            contractType={type}
            dateOfContract={dateOfContract}
          />
        ),
      };

      if (isPreHarvest) {
        return baseColumnsPreHarvest;
      } else {
        return baseColumnsPostHarvest;
      }
    }
  );

export const allPricingBaseColumns: ColumnList = [
  { heading: "Farm", id: "farm", sortKey: "farm" },

  { heading: "Status", id: "status", sortKey: "status" },
];

const allPricingDesktopBaseColumns: ColumnList = [
  { heading: "Base Price", id: "basePrice", sortKey: "basePrice" },
  {
    heading: "Date of Contract",
    id: "dateOfContract",
    sortKey: "dateOfContract",
  },
];

const preHarvestAllPricingDesktopOnlyColumns: ColumnList = [
  { heading: "Type", id: "type", sortKey: "type" },
  { heading: "Percentage", id: "percentage", sortKey: "percentageToSell" },
  allPricingDesktopBaseColumns[0],
  { heading: "Region", id: "region", sortKey: "region" },
  allPricingDesktopBaseColumns[1],
];

const postHarvestAllPricingDesktopOnlyColumns: ColumnList = [
  { heading: "Tons", id: "tons", sortKey: "tons" },
  allPricingDesktopBaseColumns[0],
  {
    heading: "Deferred",
    id: "deferred",
    sortKey: "defermentDate",
  },
  { heading: "Net Total", id: "netTotal", sortKey: "contractTotal" },
  allPricingDesktopBaseColumns[1],
];

export const preHarvestAllPricingColumns: ColumnList = [
  allPricingBaseColumns[0],
  ...preHarvestAllPricingDesktopOnlyColumns,
  allPricingBaseColumns[1],
];

export const postHarvestAllPricingColumns: ColumnList = [
  allPricingBaseColumns[0],
  ...postHarvestAllPricingDesktopOnlyColumns,
  allPricingBaseColumns[1],
];
