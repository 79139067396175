import { useQuery } from "@tanstack/react-query";
import { ForwardContractCurrentPricingResponse } from "api";
import { usePricingOptionService } from "contexts/ServiceProvider";
import { BaseApiError } from "services/apiHelpers";

const GET_CURRENT_FORWARD_PRICING_QUERY_KEY = "get-current-forward-pricing";

const TEN_MINUTE_STALE_TIME_IN_MILLISECONDS = 600000;

const useGetForwardPricingLockedInData = (
  cropYearId: string | undefined,
  farmId: string | undefined
) => {
  const pricingOptionService = usePricingOptionService();

  return useQuery<ForwardContractCurrentPricingResponse, BaseApiError>({
    queryKey: [GET_CURRENT_FORWARD_PRICING_QUERY_KEY, { cropYearId, farmId }],
    queryFn: () =>
      cropYearId && farmId
        ? pricingOptionService.getLockedInForwardPricingContract({
            cropYearId,
            farmId,
          })
        : Promise.reject(
            new Error("Missing pricing cropYear ID and/or farm ID")
          ),
    enabled: !!cropYearId && !!farmId,
    gcTime: 0,
    staleTime: TEN_MINUTE_STALE_TIME_IN_MILLISECONDS,
    refetchOnWindowFocus: false,
  });
};

export default useGetForwardPricingLockedInData;
