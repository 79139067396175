import { PropsWithChildren, ReactElement, useMemo } from "react";

import {
  Box,
  BoxProps,
  Flex,
  FlexProps,
  ResponsiveArray,
  StylesProvider,
  Text,
  ThemingProps,
  useBreakpointValue,
  useMultiStyleConfig,
  useStyles,
} from "@chakra-ui/react";

export type CardProps = ThemingProps & BoxProps;

const validHeadingTypes = ["h1", "h2", "h3", "h4", "h5", "h6"];

export const CardHeader = ({
  children,
  ...rest
}: PropsWithChildren<FlexProps>): ReactElement => {
  const styles = useStyles();
  const headingType = (rest.textStyle ?? styles.header.textStyle) as
    | string
    | ResponsiveArray<string>;
  const asType = useMemo(
    () =>
      (typeof headingType === "string" &&
      validHeadingTypes.indexOf(headingType) >= 0
        ? headingType
        : "p") as any,
    [headingType]
  );

  return (
    <Flex __css={styles.header} {...rest}>
      <Text as={asType} textStyle={headingType}>
        {children}
      </Text>
    </Flex>
  );
};

export const CardFooter = ({
  children,
  ...rest
}: PropsWithChildren<FlexProps>): ReactElement => {
  const styles = useStyles();

  return (
    <Flex __css={styles.footer} {...rest}>
      {children}
    </Flex>
  );
};

export const CardBody = ({
  children,
  ...rest
}: PropsWithChildren<FlexProps>): ReactElement => {
  const styles = useStyles();

  return (
    <Flex __css={styles.body} {...rest}>
      {children}
    </Flex>
  );
};

const Card = ({
  variant,
  children,
  ...rest
}: PropsWithChildren<CardProps>): ReactElement => {
  const styles = useMultiStyleConfig("Card", { variant });

  return (
    <Box __css={styles.card} {...rest}>
      <StylesProvider value={styles}>{children}</StylesProvider>
    </Box>
  );
};

export default Card;

export const ResponsiveCard = ({
  variant = "shadowed",
  children,
  ...rest
}: PropsWithChildren<CardProps>): ReactElement => {
  const _variant = useBreakpointValue({ base: "mobile", md: variant });

  return (
    <Card variant={_variant} {...rest}>
      {children}
    </Card>
  );
};
