import { Flex, Link, Stack, Text } from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import {
  useSearchParams,
  Link as RLink,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { LOGIN_REDIRECT_QUERY_STRING_KEY } from "components/redirects/RedirectToLogin";
import { StandardInput, PasswordInput, FormInputs } from "components/inputs";
import SubmitButton from "components/SubmitButton";
import forgotPasswordPaths from "routes/forgot-password/forgotPasswordPaths";
import useLogin from "hooks/auth/useLogin";
import useFormValidation from "hooks/useFormValidation";
import { useAuthFailure } from "hooks/useAuthFailure";

const schema = yup
  .object({
    email: yup
      .string()
      .email("Must be a valid email address")
      .required("Email is required"),
    password: yup.string().required("Password is required"),
  })
  .required();

type LoginFormFields = { email: string; password: string };

const LoginForm = () => {
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const location = useLocation();
  useAuthFailure();

  const { onLogin, isPending, isError } = useLogin();

  const methods = useForm<LoginFormFields>({
    resolver: yupResolver(schema),
    defaultValues: { email: searchParams?.get("email") ?? "" },
  });

  const {
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  const requiredFieldsNotFilled = useFormValidation(watch());

  const onLoginSubmit = (data: LoginFormFields) => {
    return onLogin(
      { username: data.email, password: data.password },
      {
        onSuccess: () => {
          const queryString = new URLSearchParams(location.search);
          const path = queryString.get(LOGIN_REDIRECT_QUERY_STRING_KEY) ?? "/";
          queryString.delete(LOGIN_REDIRECT_QUERY_STRING_KEY);

          navigate(`${path}?${queryString}`, { state: location.state });
        },
      }
    );
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onLoginSubmit)}>
        <Stack spacing={4}>
          <FormInputs
            inputs={[
              {
                id: "email",
                label: "Email",
                isRequired: true,
                component: StandardInput,
              },
              {
                id: "password",
                label: "Password",
                isRequired: true,
                component: PasswordInput,
              },
            ]}
          />
          <Flex justify="flex-end">
            <Link
              as={RLink}
              to={`/${forgotPasswordPaths.basePath}/${forgotPasswordPaths.children.forgotPassword}`}
            >
              <Text fontWeight="bold" color="brand.800">
                Forgot Password
              </Text>
            </Link>
          </Flex>
          <Stack spacing={2}>
            <SubmitButton
              size="lg"
              w="100%"
              submitDisabled={requiredFieldsNotFilled}
              isSubmitting={isPending || isSubmitting}
              buttonText="Login"
            />
            {isError && (
              <Text color="errorRed" mt={2}>
                Invalid email or password.
              </Text>
            )}
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default LoginForm;
