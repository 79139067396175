import { ReactElement } from "react";
import { FormInputs } from "components/inputs";
import DeferPaymentSelectInput from "components/inputs/DeferPaymentSelectInput";
import DeferPaymentDateInput from "components/inputs/DeferPaymentDateInput";

const PartialAveragePricingFormInputs = ({
  defaultDefermentDate,
  deferPayment,
  firstPaymentDateWithoutReferral,
}: {
  defaultDefermentDate?: Date;
  deferPayment: boolean;
  firstPaymentDateWithoutReferral: Date | undefined;
}): ReactElement => {
  return (
    <>
      <FormInputs
        inputs={[
          {
            id: "deferPayment",
            label: "Defer Payment",
            component: DeferPaymentSelectInput,
            shouldRegister: false,
            isRequired: true,
          },
        ]}
      />
      <DeferPaymentDateInput
        deferPayment={deferPayment}
        defaultDefermentDate={defaultDefermentDate}
        firstPaymentDateWithoutReferral={firstPaymentDateWithoutReferral}
      />
    </>
  );
};

export default PartialAveragePricingFormInputs;
