import { CropYearFarmDto } from "api";
import { NameWithPhoneColumn } from "components/table-columns";
import { ColumnProps, TableData } from "components/SortableTable";
import { CropYearFarmsSortColumns } from "services/CropYearService";
import { Text, Tooltip } from "@chakra-ui/react";

export const cropYearFarmsTableColumns = (
  data: CropYearFarmDto[]
): TableData[] =>
  data.map((row) => ({
    id: row.farm.id,
    farm: (
      <NameWithPhoneColumn
        name={row.farm.doingBusinessAs}
        phoneNumber={row.farm.phoneNumber}
        showAvatar={false}
      />
    ),
    totalAcres: row.totalAcres,
    numberOfFields: row.numberOfFields,
    region: (
      <Tooltip hasArrow label={row.region?.name}>
        <Text variant="truncated">{row.region?.name ?? ""}</Text>
      </Tooltip>
    ),
  }));

type ColumnList = ColumnProps<CropYearFarmsSortColumns>[];

export const baseFarmsListColumns: ColumnList = [
  { heading: "Farm", id: "farm", sortKey: "farm.doingBusinessAs" },
  { heading: "Total Acres", id: "totalAcres", sortKey: "totalAcres" },
];

export const desktopOnlyFarmsListColumns: ColumnList = [
  {
    heading: "Number Of Fields",
    id: "numberOfFields",
    sortKey: "numberOfFields",
  },
  {
    heading: "Region",
    id: "region",
    sortKey: "farm.region.name",
  },
];

export const desktopFarmsListColumns: ColumnList = [
  ...baseFarmsListColumns,
  ...desktopOnlyFarmsListColumns,
];
