import { skipToken, useInfiniteQuery } from "@tanstack/react-query";
import { usePricingOptionService } from "contexts/ServiceProvider";
import { getNextPage, QueryParams } from "services/apiHelpers";
import { CropYearPricingOptionsSortColumns } from "services/PricingOptionService";

export const GET_CROP_YEAR_PRICING_OPTIONS = "get-crop-year-pricing-options";

const useCropYearPricingOptions = ({
  cropYearId,
  queryParams,
}: {
  cropYearId?: string;
  queryParams?: QueryParams<CropYearPricingOptionsSortColumns>;
}) => {
  const pricingOptionService = usePricingOptionService();

  return useInfiniteQuery({
    queryKey: [GET_CROP_YEAR_PRICING_OPTIONS, { cropYearId }, queryParams],
    queryFn: cropYearId
      ? ({ pageParam = 0 }) =>
          pricingOptionService.getCropYearPricingOptions({
            cropYearId,
            queryParams: {
              ...queryParams,
              page: pageParam,
            },
          })
      : skipToken,
    initialPageParam: 0,
    getNextPageParam: getNextPage,
  });
};

export default useCropYearPricingOptions;
