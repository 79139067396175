import { ReactElement } from "react";
import { HStack, Stack, Text, Tooltip } from "@chakra-ui/react";

import ProfilePictureAvatar from "components/ProfilePictureAvatar";
import { DesktopOnly } from "components/BreakpointVisibility";
import { formatPhoneNumber } from "utils/formatFunctions";

const NameWithPhoneColumn = ({
  name,
  phoneNumber,
  avatarImageSrc,
  showAvatar = true,
}: {
  name: string;
  phoneNumber?: string;
  avatarImageSrc?: string;
  showAvatar?: boolean;
}): ReactElement => (
  <HStack spacing={3}>
    <DesktopOnly>
      {showAvatar && (
        <ProfilePictureAvatar
          name={name}
          size="md"
          pb={0}
          src={avatarImageSrc}
        />
      )}
    </DesktopOnly>
    <Stack justify="space-around" spacing={0}>
      <Tooltip hasArrow label={name}>
        <Text variant={"truncated"} textStyle="caption" fontWeight="bold">
          {name}
        </Text>
      </Tooltip>
      {phoneNumber && (
        <Text textStyle="caption" color="steelGray">
          {formatPhoneNumber(phoneNumber)}
        </Text>
      )}
    </Stack>
  </HStack>
);

export default NameWithPhoneColumn;
