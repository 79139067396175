import { ReactElement } from "react";
import { Button } from "@chakra-ui/react";
import AddFarmsToYearModal from "./AddFarmsToYearModal";

type AddFarmsToYearButtonProps = {
  programType: string;
  year: number | undefined;
  isDisabled: boolean;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onAddFarms: () => void;
  isLoading: boolean;
};

const AddFarmsToYearButton = ({
  onOpen,
  isDisabled,
  programType,
  year,
  onAddFarms,
  ...props
}: AddFarmsToYearButtonProps): ReactElement => {
  return (
    <>
      <Button
        onClick={onOpen}
        fontSize="14px"
        minW="228px"
        h={10}
        variant="primary"
        isDisabled={isDisabled}
      >
        Add To {programType} {year}
      </Button>
      <AddFarmsToYearModal
        programType={programType}
        onConfirm={onAddFarms}
        year={year}
        {...props}
      />
    </>
  );
};

export default AddFarmsToYearButton;
