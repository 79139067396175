import { Link as RLink } from "react-router-dom";

import {
  Button,
  ButtonProps,
  HStack,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react";
import { csvChildPaths } from "routes/csv-uploads/csvPaths";
import dashboardPaths from "routes/dashboard/dashboardPaths";

export const CsvLinkButton = ({
  linkText,
  linkTo = "#",
  ...rest
}: {
  linkText: string;
  linkTo?: string;
} & ButtonProps) => (
  <Button variant="secondary" as={RLink} to={linkTo} {...rest}>
    {linkText}
  </Button>
);

const CsvStep = ({
  currentStep,
  totalSteps,
  description,
  backButtonHidden = false,
  showRetryButton = false,
  linkText = "Back to Fields List",
  linkTo = `/${dashboardPaths.basePath}/${dashboardPaths.children.allFields}`,
}: {
  currentStep: number;
  totalSteps: number;
  description: string;
  backButtonHidden?: boolean;
  showRetryButton?: boolean;
  linkText?: string;
  linkTo?: string;
}) => {
  return (
    <HStack>
      <Stack spacing={0}>
        <Text fontWeight="bold">{`Step ${currentStep} of ${totalSteps}`}</Text>
        <Text>{description}</Text>
      </Stack>
      <Spacer />
      <HStack spacing={4}>
        {!backButtonHidden && (
          <CsvLinkButton linkText={linkText} linkTo={linkTo} />
        )}
        {showRetryButton && (
          <CsvLinkButton
            linkText="Retry Upload"
            linkTo={`../${csvChildPaths.fileSelect}`}
            variant="primary"
          />
        )}
      </HStack>
    </HStack>
  );
};

export default CsvStep;
