import { ComponentStyleConfig } from "@chakra-ui/react";

const RadioButton: ComponentStyleConfig = {
  baseStyle: {},
  variants: {
    cropYear: () => {
      const _hover = {
        border: "2px solid",
        borderColor: "brand.800",
        cursor: "pointer",
      };

      return {
        bg: "brand.50",
        color: "brand.800",
        w: "102px",
        h: 8,
        borderRadius: "4px",
        fontWeight: "bold",
        _hover,
        _checked: _hover,
      };
    },
    timeOfYear: () => ({
      width: 40,
      height: 10,
      border: "2px solid",
      color: "brand.800",
      borderRadius: "8px",
      fontWeight: "bold",
      textStyle: "caption",
      cursor: "pointer",
      _checked: {
        bg: "brand.800",
        color: "white ",
        border: "unset",
      },
      _disabled: {
        cursor: "not-allowed",
        border: "brand.200",
        color: "brand.200",
        _checked: {
          bg: "brand.500",
        },
      },
    }),
  },
};

export default RadioButton;
