import { ErrorColumnProps } from "components/CsvUpload/ErrorTable/ErrorTable";
import { ResultsWithErrors } from "pages/csv-uploads";
import { fieldsInfoErrorGuideData } from "pages/csv-uploads/error-guide";

const errorTableColumns: ErrorColumnProps[] = [
  {
    id: "fieldId",
    csvHeading: "Field ID",
    heading: "Field ID",
  },
  {
    id: "region",
    csvHeading: "Region",
    heading: "Region",
  },
  {
    id: "entityId",
    csvHeading: "Entity ID",
    heading: "Entity ID",
  },
  {
    id: "commonName",
    csvHeading: "Name",
    heading: "Field Common Name",
  },
  {
    id: "tillableAcres",
    csvHeading: "Tillable Acres",
    heading: "Tillable Acres",
  },
  {
    id: "forageDairy",
    csvHeading: "Forage Dairy",
    heading: "Forage Dairy",
  },
  {
    id: "legalDescription",
    csvHeading: "Legal Description",
    heading: "Legal Description",
  },
  {
    id: "growerDistance",
    csvHeading: "Grower Distance",
    heading: "Grower Distance",
  },
];

const FieldsResultsWithErrors = () => (
  <ResultsWithErrors
    errorTableColumns={errorTableColumns}
    additionalErrorMessage="You will need to correct the errors in ArcGIS, export another .csv file, and re-try the upload."
    errorGuideList={fieldsInfoErrorGuideData}
  />
);

export default FieldsResultsWithErrors;
