import { Stack } from "@chakra-ui/react";
import { TABLE_MARGIN_TOP } from "components/SortableTable";
import FarmDetailFormInputs from "forms/farm-detail";
import useUserRoleFlags from "hooks/auth/useUserRoleFlags";
import { ReactElement } from "react";
import { useParams } from "react-router-dom";

export const FarmsDetailsTabContent = (): ReactElement => {
  const { id: farmId } = useParams();
  const { isAdmin, isFarmer } = useUserRoleFlags();

  return (
    <Stack spacing={TABLE_MARGIN_TOP} w="100%">
      <FarmDetailFormInputs
        isDisabled={!(isAdmin || isFarmer)}
        farmId={farmId}
        isEdit={true}
      />
    </Stack>
  );
};

export default FarmsDetailsTabContent;
