import * as yup from "yup";
import {
  organizationStateSelectOptions,
  stateSelectOptions,
} from "./stateSelectOptions";

export const stateSelectSchema = yup.object({
  value: yup.string().oneOf(
    stateSelectOptions.map((state) => state.value),
    "Invalid state."
  ),
  label: yup.string().required(),
});

export const organizationStateSchema = yup.object({
  value: yup.string().oneOf(
    organizationStateSelectOptions.map((state) => state.value),
    "Invalid state."
  ),
  label: yup.string().required(),
});
