import { VStack } from "@chakra-ui/react";

import { PricingOptionResponse } from "api";
import { PricingInfoRecordStamp } from "components/PricingInfoRecordStamp/PricingInfoRecordStamp";
import { mobileStyleBreakpoint } from "utils/styleHelpers";

type PricingInfoRecordStampSectionProps = {
  pricingOption: Pick<
    PricingOptionResponse,
    "createdBy" | "voided" | "voidedAt" | "voidedBy"
  >;
  createdAtLabel: string;
  voidedAtLabel: string;
  voidedContract: boolean;
};

const RECORD_STAMP_ALIGNMENT = mobileStyleBreakpoint("unset", "flex-start");
const STAMP_SECTION_PADDING_TOP = mobileStyleBreakpoint("24px", "unset");

export const getTextColorForDateOfContractStamp = (
  nonVoidStateTextColor: string,
  voidedContract: boolean
) => {
  return voidedContract ? "steelGray" : nonVoidStateTextColor;
};

const PricingInfoRecordStampSection = ({
  pricingOption,
  createdAtLabel,
  voidedAtLabel,
  voidedContract,
}: PricingInfoRecordStampSectionProps) => {
  return (
    <VStack
      alignItems={RECORD_STAMP_ALIGNMENT}
      spacing={6}
      pt={STAMP_SECTION_PADDING_TOP}
      w="100%"
      maxW="500px"
    >
      <PricingInfoRecordStamp
        initiatedBy={pricingOption?.createdBy?.name}
        dateInitiated={pricingOption?.createdBy?.createdAt}
        label={createdAtLabel}
        labelTextColor={getTextColorForDateOfContractStamp(
          "greyFactor.4",
          voidedContract
        )}
        valueTextColor={getTextColorForDateOfContractStamp(
          "charcoal",
          voidedContract
        )}
      />
      {voidedContract && (
        <PricingInfoRecordStamp
          initiatedBy={pricingOption?.voidedBy}
          dateInitiated={pricingOption?.voidedAt}
          label={voidedAtLabel}
          labelTextColor="greyFactor.4"
          valueTextColor="charcoal"
        />
      )}
    </VStack>
  );
};

export default PricingInfoRecordStampSection;
