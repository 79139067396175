import { RouteObject } from "react-router";

import manurePaths from "./manurePaths";
import ProtectedRoute from "components/route-wrappers/ProtectedRoute";
import ManureYearProvider from "contexts/ManureYearProvider";
import TableLayout from "layouts/TableLayout";
import AddManureFarmsPage from "pages/manure/AddFarmsToManureYear/AddManureFarmsPage";
import ManureYearFarmDocumentListPage from "pages/manure/farm-documents/ManureYearFarmDocumentListPage";
import ManureFarmListPage from "pages/manure/ManureFarmListPage";

const manureRoutes: RouteObject = {
  path: manurePaths.basePath,
  element: (
    <ProtectedRoute>
      <ManureYearProvider>
        <TableLayout />
      </ManureYearProvider>
    </ProtectedRoute>
  ),
  children: [
    {
      path: manurePaths.children.allFarms,
      element: <ManureFarmListPage />,
    },
    {
      path: manurePaths.children.addFarms,
      element: <AddManureFarmsPage />,
    },
    {
      path: manurePaths.children.farmDocuments,
      element: <ManureYearFarmDocumentListPage />,
    },
  ],
};

export default manureRoutes;
