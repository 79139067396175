import { HStack, StackProps, Text, TextProps } from "@chakra-ui/react";

type DisplayLabelValueProps = {
  label: string;
  value: string | number | undefined;
  valueStackProps?: StackProps;
  statTextProps?: TextProps;
  parentheses?: boolean;
  statPrefix?: string;
  statSuffix?: string;
};

type StatValueProps = Omit<
  DisplayLabelValueProps,
  "label" | "parentheses" | "valueStackProps"
>;

const StatValue = ({
  value,
  statTextProps,
  statPrefix,
  statSuffix,
}: StatValueProps) => (
  <>
    <Text {...statTextProps}>
      {statPrefix}
      {value}
    </Text>
    {statSuffix ? <Text>{statSuffix}</Text> : null}
  </>
);

const DisplayLabelValue = ({
  label,
  valueStackProps,
  parentheses,
  ...props
}: DisplayLabelValueProps) => (
  <HStack justify="space-between">
    <Text fontWeight="bold" color="charcoal">
      {label}
    </Text>
    <HStack spacing={0} {...valueStackProps}>
      {parentheses ? (
        <>
          (<StatValue {...props} />)
        </>
      ) : (
        <StatValue {...props} />
      )}
    </HStack>
  </HStack>
);

export default DisplayLabelValue;
