import * as yup from "yup";
import { phoneNumberSchema } from "forms/schemas";

export const userDetailFormSchema = yup
  .object({
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    email: yup
      .string()
      .email("Must be a valid email address")
      .required("Email is required"),
  })
  .required();

export const registeredUserDetailFormSchema = userDetailFormSchema.shape({
  phoneNumber: phoneNumberSchema,
});
