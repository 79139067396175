import { useCallback } from "react";

import { defaultErrorCardState } from "components/ErrorCard";
import useNavigateWithQuery from "./useNavigateWithQuery";

const useNavigateToErrorPage = (
  errorStates: Record<string, ErrorState>,
  errorPage = "error"
) => {
  const navigateWithQuery = useNavigateWithQuery();

  return useCallback(
    (errorMsg: string) => {
      const errorState =
        Object.values(errorStates).find((_errorState) =>
          _errorState.match?.test(errorMsg)
        ) ?? defaultErrorCardState;
      errorState &&
        navigateWithQuery(`../${errorPage}`, {
          state: {
            msg: errorState.msg,
            title: errorState.title,
            linkText: errorState.linkText,
            linkTo: errorState.linkTo,
          },
          replace: true,
        });
    },
    [errorPage, errorStates, navigateWithQuery]
  );
};

export default useNavigateToErrorPage;
