import { Stack, useBreakpointValue } from "@chakra-ui/react";

import EmptyState from "components/EmptyState";
import FetchMoreWhenInView from "components/FetchMoreWhenInView";
import SortableTable, {
  ColumnProps,
  TABLE_MARGIN_TOP,
} from "components/SortableTable";
import { Sort, UseSortReturnType } from "hooks/useSort";
import { useEffect } from "react";
import { AddableFarmsSortColumns } from "./addFarmsTableColumns";

type AddableFarmListTableProps = {
  defaultSort: Sort<AddableFarmsSortColumns>;
  programType: string;
  columns: Record<string, ColumnProps<any>[]>;
  data: Record<string, any>[];
  sort: Sort<any>;
  setSort: any;
  toggleSort: any;
  selectedRows?: string[];
  onSelectRow?: (id?: string) => void;
} & UseSortReturnType<AddableFarmsSortColumns>;

const AddableFarmListTable = ({
  defaultSort,
  programType,
  columns: _columns,
  data,
  sort,
  setSort,
  toggleSort,
  selectedRows,
  onSelectRow,
}: AddableFarmListTableProps) => {
  const columns = useBreakpointValue(_columns);

  const emptyState = (
    <EmptyState>No Farms available to add to {programType} program.</EmptyState>
  );

  useEffect(() => {
    setSort(defaultSort);
  }, [defaultSort, setSort]);

  return (
    <Stack spacing={TABLE_MARGIN_TOP} w="100%" pb={10}>
      <SortableTable
        columns={columns}
        data={data}
        sort={sort}
        toggleSort={toggleSort}
        selectedRows={selectedRows}
        onSelectRow={onSelectRow}
      />
      {data.length === 0 && emptyState}
      {data && data.length > 0 && (
        <FetchMoreWhenInView
          // The endpoint is not properly paginated in the BE, but the text
          // is to inform the user that there is no more data to load
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          fetchNextPage={() => {}}
          hasNextPage={false}
          isFetchingNextPage={false}
        />
      )}
    </Stack>
  );
};

export default AddableFarmListTable;
