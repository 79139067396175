/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosRequestConfig,
} from "axios";
import { Configuration } from "../configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "../base";
// @ts-ignore
import { AveragePricingContractRequest } from "../models";
// @ts-ignore
import { CalculateContractPriceRequest } from "../models";
// @ts-ignore
import { CreateDelayedPricingContractRequest } from "../models";
// @ts-ignore
import { CreateForwardContractRequest } from "../models";
// @ts-ignore
import { DataContractPriceResponse } from "../models";
// @ts-ignore
import { DataDelayedContractCurrentPricingResponse } from "../models";
// @ts-ignore
import { DataDelayedContractDetailsResponse } from "../models";
// @ts-ignore
import { DataForwardContractCurrentPricingResponse } from "../models";
// @ts-ignore
import { DataPricingOptionResponse } from "../models";
// @ts-ignore
import { DeleteForwardContractsRequest } from "../models";
// @ts-ignore
import { PageResponseCropYearPricingOptionDto } from "../models";
// @ts-ignore
import { PageResponsePricingOptionDto } from "../models";
// @ts-ignore
import { UpdateContractDefermentRequest } from "../models";
// @ts-ignore
import { UpdateDelayedContractRequest } from "../models";
// @ts-ignore
import { UpdatePendingStandardForwardContractRequest } from "../models";
/**
 * PricingOptionApi - axios parameter creator
 * @export
 */
export const PricingOptionApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {string} cropYearId
     * @param {string} forwardContractId
     * @param {string} farmId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    approveForwardContract: async (
      cropYearId: string,
      forwardContractId: string,
      farmId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cropYearId' is not null or undefined
      assertParamExists("approveForwardContract", "cropYearId", cropYearId);
      // verify required parameter 'forwardContractId' is not null or undefined
      assertParamExists(
        "approveForwardContract",
        "forwardContractId",
        forwardContractId
      );
      // verify required parameter 'farmId' is not null or undefined
      assertParamExists("approveForwardContract", "farmId", farmId);
      const localVarPath =
        `/pricing-options/{crop-year-id}/forward-contract/{forward-contract-id}/approve`
          .replace(
            `{${"crop-year-id"}}`,
            encodeURIComponent(String(cropYearId))
          )
          .replace(
            `{${"forward-contract-id"}}`,
            encodeURIComponent(String(forwardContractId))
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (farmId !== undefined && farmId !== null) {
        localVarHeaderParameter["farm-id"] = String(farmId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} farmId
     * @param {CalculateContractPriceRequest} calculateContractPriceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calculateContractPrice: async (
      cropYearId: string,
      farmId: string,
      calculateContractPriceRequest: CalculateContractPriceRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cropYearId' is not null or undefined
      assertParamExists("calculateContractPrice", "cropYearId", cropYearId);
      // verify required parameter 'farmId' is not null or undefined
      assertParamExists("calculateContractPrice", "farmId", farmId);
      // verify required parameter 'calculateContractPriceRequest' is not null or undefined
      assertParamExists(
        "calculateContractPrice",
        "calculateContractPriceRequest",
        calculateContractPriceRequest
      );
      const localVarPath =
        `/pricing-option/{crop-year-id}/calculate-contract-price`.replace(
          `{${"crop-year-id"}}`,
          encodeURIComponent(String(cropYearId))
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (farmId !== undefined && farmId !== null) {
        localVarHeaderParameter["farm-id"] = String(farmId);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        calculateContractPriceRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} farmId
     * @param {number} tons
     * @param {number} [futuresPrice]
     * @param {number} [basis]
     * @param {string} [dateOfContract]
     * @param {number} [cashPrice]
     * @param {boolean} [isManuallyCreated]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calculateDelayedPricingDetails: async (
      cropYearId: string,
      farmId: string,
      tons: number,
      futuresPrice?: number,
      basis?: number,
      dateOfContract?: string,
      cashPrice?: number,
      isManuallyCreated?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cropYearId' is not null or undefined
      assertParamExists(
        "calculateDelayedPricingDetails",
        "cropYearId",
        cropYearId
      );
      // verify required parameter 'farmId' is not null or undefined
      assertParamExists("calculateDelayedPricingDetails", "farmId", farmId);
      // verify required parameter 'tons' is not null or undefined
      assertParamExists("calculateDelayedPricingDetails", "tons", tons);
      const localVarPath =
        `/pricing-options/{crop-year-id}/delayed-pricing-contract/calculate`.replace(
          `{${"crop-year-id"}}`,
          encodeURIComponent(String(cropYearId))
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (tons !== undefined) {
        localVarQueryParameter["tons"] = tons;
      }

      if (futuresPrice !== undefined) {
        localVarQueryParameter["futuresPrice"] = futuresPrice;
      }

      if (basis !== undefined) {
        localVarQueryParameter["basis"] = basis;
      }

      if (dateOfContract !== undefined) {
        localVarQueryParameter["dateOfContract"] =
          (dateOfContract as any) instanceof Date
            ? (dateOfContract as any).toISOString().substr(0, 10)
            : dateOfContract;
      }

      if (cashPrice !== undefined) {
        localVarQueryParameter["cashPrice"] = cashPrice;
      }

      if (isManuallyCreated !== undefined) {
        localVarQueryParameter["isManuallyCreated"] = isManuallyCreated;
      }

      if (farmId !== undefined && farmId !== null) {
        localVarHeaderParameter["farm-id"] = String(farmId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} farmId
     * @param {AveragePricingContractRequest} averagePricingContractRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAveragePricingContract: async (
      cropYearId: string,
      farmId: string,
      averagePricingContractRequest: AveragePricingContractRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cropYearId' is not null or undefined
      assertParamExists(
        "createAveragePricingContract",
        "cropYearId",
        cropYearId
      );
      // verify required parameter 'farmId' is not null or undefined
      assertParamExists("createAveragePricingContract", "farmId", farmId);
      // verify required parameter 'averagePricingContractRequest' is not null or undefined
      assertParamExists(
        "createAveragePricingContract",
        "averagePricingContractRequest",
        averagePricingContractRequest
      );
      const localVarPath =
        `/pricing-options/{crop-year-id}/average-pricing-contract`.replace(
          `{${"crop-year-id"}}`,
          encodeURIComponent(String(cropYearId))
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (farmId !== undefined && farmId !== null) {
        localVarHeaderParameter["farm-id"] = String(farmId);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        averagePricingContractRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} farmId
     * @param {CreateDelayedPricingContractRequest} createDelayedPricingContractRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDelayedPricingContract: async (
      cropYearId: string,
      farmId: string,
      createDelayedPricingContractRequest: CreateDelayedPricingContractRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cropYearId' is not null or undefined
      assertParamExists(
        "createDelayedPricingContract",
        "cropYearId",
        cropYearId
      );
      // verify required parameter 'farmId' is not null or undefined
      assertParamExists("createDelayedPricingContract", "farmId", farmId);
      // verify required parameter 'createDelayedPricingContractRequest' is not null or undefined
      assertParamExists(
        "createDelayedPricingContract",
        "createDelayedPricingContractRequest",
        createDelayedPricingContractRequest
      );
      const localVarPath =
        `/pricing-options/{crop-year-id}/delayed-pricing-contract`.replace(
          `{${"crop-year-id"}}`,
          encodeURIComponent(String(cropYearId))
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (farmId !== undefined && farmId !== null) {
        localVarHeaderParameter["farm-id"] = String(farmId);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createDelayedPricingContractRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} farmId
     * @param {CreateForwardContractRequest} createForwardContractRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createForwardContract: async (
      cropYearId: string,
      farmId: string,
      createForwardContractRequest: CreateForwardContractRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cropYearId' is not null or undefined
      assertParamExists("createForwardContract", "cropYearId", cropYearId);
      // verify required parameter 'farmId' is not null or undefined
      assertParamExists("createForwardContract", "farmId", farmId);
      // verify required parameter 'createForwardContractRequest' is not null or undefined
      assertParamExists(
        "createForwardContract",
        "createForwardContractRequest",
        createForwardContractRequest
      );
      const localVarPath =
        `/pricing-options/{crop-year-id}/forward-contract`.replace(
          `{${"crop-year-id"}}`,
          encodeURIComponent(String(cropYearId))
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (farmId !== undefined && farmId !== null) {
        localVarHeaderParameter["farm-id"] = String(farmId);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createForwardContractRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} id
     * @param {string} farmId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAveragePricingContract: async (
      cropYearId: string,
      id: string,
      farmId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cropYearId' is not null or undefined
      assertParamExists(
        "deleteAveragePricingContract",
        "cropYearId",
        cropYearId
      );
      // verify required parameter 'id' is not null or undefined
      assertParamExists("deleteAveragePricingContract", "id", id);
      // verify required parameter 'farmId' is not null or undefined
      assertParamExists("deleteAveragePricingContract", "farmId", farmId);
      const localVarPath =
        `/pricing-options/{crop-year-id}/average-pricing-contract/{id}`
          .replace(
            `{${"crop-year-id"}}`,
            encodeURIComponent(String(cropYearId))
          )
          .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (farmId !== undefined && farmId !== null) {
        localVarHeaderParameter["farm-id"] = String(farmId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} id
     * @param {string} farmId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteForwardContract: async (
      cropYearId: string,
      id: string,
      farmId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cropYearId' is not null or undefined
      assertParamExists("deleteForwardContract", "cropYearId", cropYearId);
      // verify required parameter 'id' is not null or undefined
      assertParamExists("deleteForwardContract", "id", id);
      // verify required parameter 'farmId' is not null or undefined
      assertParamExists("deleteForwardContract", "farmId", farmId);
      const localVarPath =
        `/pricing-options/{crop-year-id}/forward-contract/{id}`
          .replace(
            `{${"crop-year-id"}}`,
            encodeURIComponent(String(cropYearId))
          )
          .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (farmId !== undefined && farmId !== null) {
        localVarHeaderParameter["farm-id"] = String(farmId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cropYearId
     * @param {DeleteForwardContractsRequest} deleteForwardContractsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteForwardContracts: async (
      cropYearId: string,
      deleteForwardContractsRequest: DeleteForwardContractsRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cropYearId' is not null or undefined
      assertParamExists("deleteForwardContracts", "cropYearId", cropYearId);
      // verify required parameter 'deleteForwardContractsRequest' is not null or undefined
      assertParamExists(
        "deleteForwardContracts",
        "deleteForwardContractsRequest",
        deleteForwardContractsRequest
      );
      const localVarPath =
        `/pricing-options/{crop-year-id}/forward-contract`.replace(
          `{${"crop-year-id"}}`,
          encodeURIComponent(String(cropYearId))
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        deleteForwardContractsRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cropYearId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadPricingDataExport: async (
      cropYearId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cropYearId' is not null or undefined
      assertParamExists("downloadPricingDataExport", "cropYearId", cropYearId);
      const localVarPath = `/pricing-options/{crop-year-id}/export`.replace(
        `{${"crop-year-id"}}`,
        encodeURIComponent(String(cropYearId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} farmId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAveragePricingContractForFarm: async (
      cropYearId: string,
      farmId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cropYearId' is not null or undefined
      assertParamExists(
        "getAveragePricingContractForFarm",
        "cropYearId",
        cropYearId
      );
      // verify required parameter 'farmId' is not null or undefined
      assertParamExists("getAveragePricingContractForFarm", "farmId", farmId);
      const localVarPath =
        `/pricing-options/{crop-year-id}/farm/average-pricing-contract`.replace(
          `{${"crop-year-id"}}`,
          encodeURIComponent(String(cropYearId))
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (farmId !== undefined && farmId !== null) {
        localVarHeaderParameter["farm-id"] = String(farmId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} [search]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCropYearPricingOptionsList: async (
      cropYearId: string,
      search?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cropYearId' is not null or undefined
      assertParamExists(
        "getCropYearPricingOptionsList",
        "cropYearId",
        cropYearId
      );
      const localVarPath = `/pricing-options/{crop-year-id}`.replace(
        `{${"crop-year-id"}}`,
        encodeURIComponent(String(cropYearId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (search !== undefined) {
        localVarQueryParameter["search"] = search;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sort) {
        localVarQueryParameter["sort"] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} farmId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCurrentPricesForDelayedPricing: async (
      cropYearId: string,
      farmId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cropYearId' is not null or undefined
      assertParamExists(
        "getCurrentPricesForDelayedPricing",
        "cropYearId",
        cropYearId
      );
      // verify required parameter 'farmId' is not null or undefined
      assertParamExists("getCurrentPricesForDelayedPricing", "farmId", farmId);
      const localVarPath =
        `/pricing-options/{crop-year-id}/delayed-pricing-contract/current-prices`.replace(
          `{${"crop-year-id"}}`,
          encodeURIComponent(String(cropYearId))
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (farmId !== undefined && farmId !== null) {
        localVarHeaderParameter["farm-id"] = String(farmId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} farmId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCurrentPricesForForwardPricing: async (
      cropYearId: string,
      farmId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cropYearId' is not null or undefined
      assertParamExists(
        "getCurrentPricesForForwardPricing",
        "cropYearId",
        cropYearId
      );
      // verify required parameter 'farmId' is not null or undefined
      assertParamExists("getCurrentPricesForForwardPricing", "farmId", farmId);
      const localVarPath =
        `/pricing-options/{crop-year-id}/forward-contract/current-prices`.replace(
          `{${"crop-year-id"}}`,
          encodeURIComponent(String(cropYearId))
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (farmId !== undefined && farmId !== null) {
        localVarHeaderParameter["farm-id"] = String(farmId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} farmId
     * @param {string} [search]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFarmPricingOptionsList: async (
      cropYearId: string,
      farmId: string,
      search?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cropYearId' is not null or undefined
      assertParamExists("getFarmPricingOptionsList", "cropYearId", cropYearId);
      // verify required parameter 'farmId' is not null or undefined
      assertParamExists("getFarmPricingOptionsList", "farmId", farmId);
      const localVarPath = `/pricing-options/{crop-year-id}/farm`.replace(
        `{${"crop-year-id"}}`,
        encodeURIComponent(String(cropYearId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (search !== undefined) {
        localVarQueryParameter["search"] = search;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sort) {
        localVarQueryParameter["sort"] = sort;
      }

      if (farmId !== undefined && farmId !== null) {
        localVarHeaderParameter["farm-id"] = String(farmId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {string} [farmId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPricingOptionDetails: async (
      id: string,
      farmId?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getPricingOptionDetails", "id", id);
      const localVarPath = `/pricing-option/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (farmId !== undefined && farmId !== null) {
        localVarHeaderParameter["farm-id"] = String(farmId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} id
     * @param {string} farmId
     * @param {AveragePricingContractRequest} averagePricingContractRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAveragePricingContract: async (
      cropYearId: string,
      id: string,
      farmId: string,
      averagePricingContractRequest: AveragePricingContractRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cropYearId' is not null or undefined
      assertParamExists(
        "updateAveragePricingContract",
        "cropYearId",
        cropYearId
      );
      // verify required parameter 'id' is not null or undefined
      assertParamExists("updateAveragePricingContract", "id", id);
      // verify required parameter 'farmId' is not null or undefined
      assertParamExists("updateAveragePricingContract", "farmId", farmId);
      // verify required parameter 'averagePricingContractRequest' is not null or undefined
      assertParamExists(
        "updateAveragePricingContract",
        "averagePricingContractRequest",
        averagePricingContractRequest
      );
      const localVarPath =
        `/pricing-options/{crop-year-id}/average-pricing-contract/{id}`
          .replace(
            `{${"crop-year-id"}}`,
            encodeURIComponent(String(cropYearId))
          )
          .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (farmId !== undefined && farmId !== null) {
        localVarHeaderParameter["farm-id"] = String(farmId);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        averagePricingContractRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} id
     * @param {string} farmId
     * @param {UpdateDelayedContractRequest} updateDelayedContractRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDelayedPricingContract: async (
      cropYearId: string,
      id: string,
      farmId: string,
      updateDelayedContractRequest: UpdateDelayedContractRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cropYearId' is not null or undefined
      assertParamExists(
        "updateDelayedPricingContract",
        "cropYearId",
        cropYearId
      );
      // verify required parameter 'id' is not null or undefined
      assertParamExists("updateDelayedPricingContract", "id", id);
      // verify required parameter 'farmId' is not null or undefined
      assertParamExists("updateDelayedPricingContract", "farmId", farmId);
      // verify required parameter 'updateDelayedContractRequest' is not null or undefined
      assertParamExists(
        "updateDelayedPricingContract",
        "updateDelayedContractRequest",
        updateDelayedContractRequest
      );
      const localVarPath =
        `/pricing-options/{crop-year-id}/delayed-pricing-contract/{id}`
          .replace(
            `{${"crop-year-id"}}`,
            encodeURIComponent(String(cropYearId))
          )
          .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (farmId !== undefined && farmId !== null) {
        localVarHeaderParameter["farm-id"] = String(farmId);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateDelayedContractRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} id
     * @param {string} farmId
     * @param {CreateForwardContractRequest} createForwardContractRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateForwardContract: async (
      cropYearId: string,
      id: string,
      farmId: string,
      createForwardContractRequest: CreateForwardContractRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cropYearId' is not null or undefined
      assertParamExists("updateForwardContract", "cropYearId", cropYearId);
      // verify required parameter 'id' is not null or undefined
      assertParamExists("updateForwardContract", "id", id);
      // verify required parameter 'farmId' is not null or undefined
      assertParamExists("updateForwardContract", "farmId", farmId);
      // verify required parameter 'createForwardContractRequest' is not null or undefined
      assertParamExists(
        "updateForwardContract",
        "createForwardContractRequest",
        createForwardContractRequest
      );
      const localVarPath =
        `/pricing-options/{crop-year-id}/forward-contract/{id}`
          .replace(
            `{${"crop-year-id"}}`,
            encodeURIComponent(String(cropYearId))
          )
          .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (farmId !== undefined && farmId !== null) {
        localVarHeaderParameter["farm-id"] = String(farmId);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createForwardContractRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} id
     * @param {string} farmId
     * @param {UpdateContractDefermentRequest} updateContractDefermentRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateForwardContractDeferment: async (
      cropYearId: string,
      id: string,
      farmId: string,
      updateContractDefermentRequest: UpdateContractDefermentRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cropYearId' is not null or undefined
      assertParamExists(
        "updateForwardContractDeferment",
        "cropYearId",
        cropYearId
      );
      // verify required parameter 'id' is not null or undefined
      assertParamExists("updateForwardContractDeferment", "id", id);
      // verify required parameter 'farmId' is not null or undefined
      assertParamExists("updateForwardContractDeferment", "farmId", farmId);
      // verify required parameter 'updateContractDefermentRequest' is not null or undefined
      assertParamExists(
        "updateForwardContractDeferment",
        "updateContractDefermentRequest",
        updateContractDefermentRequest
      );
      const localVarPath =
        `/pricing-options/{crop-year-id}/forward-contract/{id}/deferment`
          .replace(
            `{${"crop-year-id"}}`,
            encodeURIComponent(String(cropYearId))
          )
          .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (farmId !== undefined && farmId !== null) {
        localVarHeaderParameter["farm-id"] = String(farmId);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateContractDefermentRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} id
     * @param {string} farmId
     * @param {UpdatePendingStandardForwardContractRequest} updatePendingStandardForwardContractRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePendingStandardForwardContract: async (
      cropYearId: string,
      id: string,
      farmId: string,
      updatePendingStandardForwardContractRequest: UpdatePendingStandardForwardContractRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cropYearId' is not null or undefined
      assertParamExists(
        "updatePendingStandardForwardContract",
        "cropYearId",
        cropYearId
      );
      // verify required parameter 'id' is not null or undefined
      assertParamExists("updatePendingStandardForwardContract", "id", id);
      // verify required parameter 'farmId' is not null or undefined
      assertParamExists(
        "updatePendingStandardForwardContract",
        "farmId",
        farmId
      );
      // verify required parameter 'updatePendingStandardForwardContractRequest' is not null or undefined
      assertParamExists(
        "updatePendingStandardForwardContract",
        "updatePendingStandardForwardContractRequest",
        updatePendingStandardForwardContractRequest
      );
      const localVarPath =
        `/pricing-options/{crop-year-id}/standard-forward-contract/{id}`
          .replace(
            `{${"crop-year-id"}}`,
            encodeURIComponent(String(cropYearId))
          )
          .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (farmId !== undefined && farmId !== null) {
        localVarHeaderParameter["farm-id"] = String(farmId);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updatePendingStandardForwardContractRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PricingOptionApi - functional programming interface
 * @export
 */
export const PricingOptionApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    PricingOptionApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} cropYearId
     * @param {string} forwardContractId
     * @param {string} farmId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async approveForwardContract(
      cropYearId: string,
      forwardContractId: string,
      farmId: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.approveForwardContract(
          cropYearId,
          forwardContractId,
          farmId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} farmId
     * @param {CalculateContractPriceRequest} calculateContractPriceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async calculateContractPrice(
      cropYearId: string,
      farmId: string,
      calculateContractPriceRequest: CalculateContractPriceRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataContractPriceResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.calculateContractPrice(
          cropYearId,
          farmId,
          calculateContractPriceRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} farmId
     * @param {number} tons
     * @param {number} [futuresPrice]
     * @param {number} [basis]
     * @param {string} [dateOfContract]
     * @param {number} [cashPrice]
     * @param {boolean} [isManuallyCreated]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async calculateDelayedPricingDetails(
      cropYearId: string,
      farmId: string,
      tons: number,
      futuresPrice?: number,
      basis?: number,
      dateOfContract?: string,
      cashPrice?: number,
      isManuallyCreated?: boolean,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataDelayedContractDetailsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.calculateDelayedPricingDetails(
          cropYearId,
          farmId,
          tons,
          futuresPrice,
          basis,
          dateOfContract,
          cashPrice,
          isManuallyCreated,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} farmId
     * @param {AveragePricingContractRequest} averagePricingContractRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createAveragePricingContract(
      cropYearId: string,
      farmId: string,
      averagePricingContractRequest: AveragePricingContractRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataPricingOptionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createAveragePricingContract(
          cropYearId,
          farmId,
          averagePricingContractRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} farmId
     * @param {CreateDelayedPricingContractRequest} createDelayedPricingContractRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createDelayedPricingContract(
      cropYearId: string,
      farmId: string,
      createDelayedPricingContractRequest: CreateDelayedPricingContractRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataPricingOptionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createDelayedPricingContract(
          cropYearId,
          farmId,
          createDelayedPricingContractRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} farmId
     * @param {CreateForwardContractRequest} createForwardContractRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createForwardContract(
      cropYearId: string,
      farmId: string,
      createForwardContractRequest: CreateForwardContractRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataPricingOptionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createForwardContract(
          cropYearId,
          farmId,
          createForwardContractRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} id
     * @param {string} farmId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteAveragePricingContract(
      cropYearId: string,
      id: string,
      farmId: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteAveragePricingContract(
          cropYearId,
          id,
          farmId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} id
     * @param {string} farmId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteForwardContract(
      cropYearId: string,
      id: string,
      farmId: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteForwardContract(
          cropYearId,
          id,
          farmId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} cropYearId
     * @param {DeleteForwardContractsRequest} deleteForwardContractsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteForwardContracts(
      cropYearId: string,
      deleteForwardContractsRequest: DeleteForwardContractsRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteForwardContracts(
          cropYearId,
          deleteForwardContractsRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} cropYearId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async downloadPricingDataExport(
      cropYearId: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.downloadPricingDataExport(
          cropYearId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} farmId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAveragePricingContractForFarm(
      cropYearId: string,
      farmId: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataPricingOptionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAveragePricingContractForFarm(
          cropYearId,
          farmId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} [search]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCropYearPricingOptionsList(
      cropYearId: string,
      search?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PageResponseCropYearPricingOptionDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getCropYearPricingOptionsList(
          cropYearId,
          search,
          page,
          size,
          sort,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} farmId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCurrentPricesForDelayedPricing(
      cropYearId: string,
      farmId: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataDelayedContractCurrentPricingResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getCurrentPricesForDelayedPricing(
          cropYearId,
          farmId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} farmId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCurrentPricesForForwardPricing(
      cropYearId: string,
      farmId: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataForwardContractCurrentPricingResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getCurrentPricesForForwardPricing(
          cropYearId,
          farmId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} farmId
     * @param {string} [search]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFarmPricingOptionsList(
      cropYearId: string,
      farmId: string,
      search?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PageResponsePricingOptionDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getFarmPricingOptionsList(
          cropYearId,
          farmId,
          search,
          page,
          size,
          sort,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {string} [farmId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPricingOptionDetails(
      id: string,
      farmId?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataPricingOptionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPricingOptionDetails(
          id,
          farmId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} id
     * @param {string} farmId
     * @param {AveragePricingContractRequest} averagePricingContractRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateAveragePricingContract(
      cropYearId: string,
      id: string,
      farmId: string,
      averagePricingContractRequest: AveragePricingContractRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateAveragePricingContract(
          cropYearId,
          id,
          farmId,
          averagePricingContractRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} id
     * @param {string} farmId
     * @param {UpdateDelayedContractRequest} updateDelayedContractRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateDelayedPricingContract(
      cropYearId: string,
      id: string,
      farmId: string,
      updateDelayedContractRequest: UpdateDelayedContractRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataPricingOptionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateDelayedPricingContract(
          cropYearId,
          id,
          farmId,
          updateDelayedContractRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} id
     * @param {string} farmId
     * @param {CreateForwardContractRequest} createForwardContractRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateForwardContract(
      cropYearId: string,
      id: string,
      farmId: string,
      createForwardContractRequest: CreateForwardContractRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataPricingOptionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateForwardContract(
          cropYearId,
          id,
          farmId,
          createForwardContractRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} id
     * @param {string} farmId
     * @param {UpdateContractDefermentRequest} updateContractDefermentRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateForwardContractDeferment(
      cropYearId: string,
      id: string,
      farmId: string,
      updateContractDefermentRequest: UpdateContractDefermentRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateForwardContractDeferment(
          cropYearId,
          id,
          farmId,
          updateContractDefermentRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} id
     * @param {string} farmId
     * @param {UpdatePendingStandardForwardContractRequest} updatePendingStandardForwardContractRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updatePendingStandardForwardContract(
      cropYearId: string,
      id: string,
      farmId: string,
      updatePendingStandardForwardContractRequest: UpdatePendingStandardForwardContractRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataPricingOptionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updatePendingStandardForwardContract(
          cropYearId,
          id,
          farmId,
          updatePendingStandardForwardContractRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * PricingOptionApi - factory interface
 * @export
 */
export const PricingOptionApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = PricingOptionApiFp(configuration);
  return {
    /**
     *
     * @param {string} cropYearId
     * @param {string} forwardContractId
     * @param {string} farmId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    approveForwardContract(
      cropYearId: string,
      forwardContractId: string,
      farmId: string,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .approveForwardContract(cropYearId, forwardContractId, farmId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} farmId
     * @param {CalculateContractPriceRequest} calculateContractPriceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calculateContractPrice(
      cropYearId: string,
      farmId: string,
      calculateContractPriceRequest: CalculateContractPriceRequest,
      options?: any
    ): AxiosPromise<DataContractPriceResponse> {
      return localVarFp
        .calculateContractPrice(
          cropYearId,
          farmId,
          calculateContractPriceRequest,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} farmId
     * @param {number} tons
     * @param {number} [futuresPrice]
     * @param {number} [basis]
     * @param {string} [dateOfContract]
     * @param {number} [cashPrice]
     * @param {boolean} [isManuallyCreated]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calculateDelayedPricingDetails(
      cropYearId: string,
      farmId: string,
      tons: number,
      futuresPrice?: number,
      basis?: number,
      dateOfContract?: string,
      cashPrice?: number,
      isManuallyCreated?: boolean,
      options?: any
    ): AxiosPromise<DataDelayedContractDetailsResponse> {
      return localVarFp
        .calculateDelayedPricingDetails(
          cropYearId,
          farmId,
          tons,
          futuresPrice,
          basis,
          dateOfContract,
          cashPrice,
          isManuallyCreated,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} farmId
     * @param {AveragePricingContractRequest} averagePricingContractRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAveragePricingContract(
      cropYearId: string,
      farmId: string,
      averagePricingContractRequest: AveragePricingContractRequest,
      options?: any
    ): AxiosPromise<DataPricingOptionResponse> {
      return localVarFp
        .createAveragePricingContract(
          cropYearId,
          farmId,
          averagePricingContractRequest,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} farmId
     * @param {CreateDelayedPricingContractRequest} createDelayedPricingContractRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDelayedPricingContract(
      cropYearId: string,
      farmId: string,
      createDelayedPricingContractRequest: CreateDelayedPricingContractRequest,
      options?: any
    ): AxiosPromise<DataPricingOptionResponse> {
      return localVarFp
        .createDelayedPricingContract(
          cropYearId,
          farmId,
          createDelayedPricingContractRequest,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} farmId
     * @param {CreateForwardContractRequest} createForwardContractRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createForwardContract(
      cropYearId: string,
      farmId: string,
      createForwardContractRequest: CreateForwardContractRequest,
      options?: any
    ): AxiosPromise<DataPricingOptionResponse> {
      return localVarFp
        .createForwardContract(
          cropYearId,
          farmId,
          createForwardContractRequest,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} id
     * @param {string} farmId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAveragePricingContract(
      cropYearId: string,
      id: string,
      farmId: string,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .deleteAveragePricingContract(cropYearId, id, farmId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} id
     * @param {string} farmId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteForwardContract(
      cropYearId: string,
      id: string,
      farmId: string,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .deleteForwardContract(cropYearId, id, farmId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} cropYearId
     * @param {DeleteForwardContractsRequest} deleteForwardContractsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteForwardContracts(
      cropYearId: string,
      deleteForwardContractsRequest: DeleteForwardContractsRequest,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .deleteForwardContracts(
          cropYearId,
          deleteForwardContractsRequest,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} cropYearId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadPricingDataExport(
      cropYearId: string,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .downloadPricingDataExport(cropYearId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} farmId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAveragePricingContractForFarm(
      cropYearId: string,
      farmId: string,
      options?: any
    ): AxiosPromise<DataPricingOptionResponse> {
      return localVarFp
        .getAveragePricingContractForFarm(cropYearId, farmId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} [search]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCropYearPricingOptionsList(
      cropYearId: string,
      search?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any
    ): AxiosPromise<PageResponseCropYearPricingOptionDto> {
      return localVarFp
        .getCropYearPricingOptionsList(
          cropYearId,
          search,
          page,
          size,
          sort,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} farmId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCurrentPricesForDelayedPricing(
      cropYearId: string,
      farmId: string,
      options?: any
    ): AxiosPromise<DataDelayedContractCurrentPricingResponse> {
      return localVarFp
        .getCurrentPricesForDelayedPricing(cropYearId, farmId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} farmId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCurrentPricesForForwardPricing(
      cropYearId: string,
      farmId: string,
      options?: any
    ): AxiosPromise<DataForwardContractCurrentPricingResponse> {
      return localVarFp
        .getCurrentPricesForForwardPricing(cropYearId, farmId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} farmId
     * @param {string} [search]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFarmPricingOptionsList(
      cropYearId: string,
      farmId: string,
      search?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any
    ): AxiosPromise<PageResponsePricingOptionDto> {
      return localVarFp
        .getFarmPricingOptionsList(
          cropYearId,
          farmId,
          search,
          page,
          size,
          sort,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {string} [farmId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPricingOptionDetails(
      id: string,
      farmId?: string,
      options?: any
    ): AxiosPromise<DataPricingOptionResponse> {
      return localVarFp
        .getPricingOptionDetails(id, farmId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} id
     * @param {string} farmId
     * @param {AveragePricingContractRequest} averagePricingContractRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAveragePricingContract(
      cropYearId: string,
      id: string,
      farmId: string,
      averagePricingContractRequest: AveragePricingContractRequest,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .updateAveragePricingContract(
          cropYearId,
          id,
          farmId,
          averagePricingContractRequest,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} id
     * @param {string} farmId
     * @param {UpdateDelayedContractRequest} updateDelayedContractRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDelayedPricingContract(
      cropYearId: string,
      id: string,
      farmId: string,
      updateDelayedContractRequest: UpdateDelayedContractRequest,
      options?: any
    ): AxiosPromise<DataPricingOptionResponse> {
      return localVarFp
        .updateDelayedPricingContract(
          cropYearId,
          id,
          farmId,
          updateDelayedContractRequest,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} id
     * @param {string} farmId
     * @param {CreateForwardContractRequest} createForwardContractRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateForwardContract(
      cropYearId: string,
      id: string,
      farmId: string,
      createForwardContractRequest: CreateForwardContractRequest,
      options?: any
    ): AxiosPromise<DataPricingOptionResponse> {
      return localVarFp
        .updateForwardContract(
          cropYearId,
          id,
          farmId,
          createForwardContractRequest,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} id
     * @param {string} farmId
     * @param {UpdateContractDefermentRequest} updateContractDefermentRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateForwardContractDeferment(
      cropYearId: string,
      id: string,
      farmId: string,
      updateContractDefermentRequest: UpdateContractDefermentRequest,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .updateForwardContractDeferment(
          cropYearId,
          id,
          farmId,
          updateContractDefermentRequest,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} cropYearId
     * @param {string} id
     * @param {string} farmId
     * @param {UpdatePendingStandardForwardContractRequest} updatePendingStandardForwardContractRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePendingStandardForwardContract(
      cropYearId: string,
      id: string,
      farmId: string,
      updatePendingStandardForwardContractRequest: UpdatePendingStandardForwardContractRequest,
      options?: any
    ): AxiosPromise<DataPricingOptionResponse> {
      return localVarFp
        .updatePendingStandardForwardContract(
          cropYearId,
          id,
          farmId,
          updatePendingStandardForwardContractRequest,
          options
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for approveForwardContract operation in PricingOptionApi.
 * @export
 * @interface PricingOptionApiApproveForwardContractRequest
 */
export interface PricingOptionApiApproveForwardContractRequest {
  /**
   *
   * @type {string}
   * @memberof PricingOptionApiApproveForwardContract
   */
  readonly cropYearId: string;

  /**
   *
   * @type {string}
   * @memberof PricingOptionApiApproveForwardContract
   */
  readonly forwardContractId: string;

  /**
   *
   * @type {string}
   * @memberof PricingOptionApiApproveForwardContract
   */
  readonly farmId: string;
}

/**
 * Request parameters for calculateContractPrice operation in PricingOptionApi.
 * @export
 * @interface PricingOptionApiCalculateContractPriceRequest
 */
export interface PricingOptionApiCalculateContractPriceRequest {
  /**
   *
   * @type {string}
   * @memberof PricingOptionApiCalculateContractPrice
   */
  readonly cropYearId: string;

  /**
   *
   * @type {string}
   * @memberof PricingOptionApiCalculateContractPrice
   */
  readonly farmId: string;

  /**
   *
   * @type {CalculateContractPriceRequest}
   * @memberof PricingOptionApiCalculateContractPrice
   */
  readonly calculateContractPriceRequest: CalculateContractPriceRequest;
}

/**
 * Request parameters for calculateDelayedPricingDetails operation in PricingOptionApi.
 * @export
 * @interface PricingOptionApiCalculateDelayedPricingDetailsRequest
 */
export interface PricingOptionApiCalculateDelayedPricingDetailsRequest {
  /**
   *
   * @type {string}
   * @memberof PricingOptionApiCalculateDelayedPricingDetails
   */
  readonly cropYearId: string;

  /**
   *
   * @type {string}
   * @memberof PricingOptionApiCalculateDelayedPricingDetails
   */
  readonly farmId: string;

  /**
   *
   * @type {number}
   * @memberof PricingOptionApiCalculateDelayedPricingDetails
   */
  readonly tons: number;

  /**
   *
   * @type {number}
   * @memberof PricingOptionApiCalculateDelayedPricingDetails
   */
  readonly futuresPrice?: number;

  /**
   *
   * @type {number}
   * @memberof PricingOptionApiCalculateDelayedPricingDetails
   */
  readonly basis?: number;

  /**
   *
   * @type {string}
   * @memberof PricingOptionApiCalculateDelayedPricingDetails
   */
  readonly dateOfContract?: string;

  /**
   *
   * @type {number}
   * @memberof PricingOptionApiCalculateDelayedPricingDetails
   */
  readonly cashPrice?: number;

  /**
   *
   * @type {boolean}
   * @memberof PricingOptionApiCalculateDelayedPricingDetails
   */
  readonly isManuallyCreated?: boolean;
}

/**
 * Request parameters for createAveragePricingContract operation in PricingOptionApi.
 * @export
 * @interface PricingOptionApiCreateAveragePricingContractRequest
 */
export interface PricingOptionApiCreateAveragePricingContractRequest {
  /**
   *
   * @type {string}
   * @memberof PricingOptionApiCreateAveragePricingContract
   */
  readonly cropYearId: string;

  /**
   *
   * @type {string}
   * @memberof PricingOptionApiCreateAveragePricingContract
   */
  readonly farmId: string;

  /**
   *
   * @type {AveragePricingContractRequest}
   * @memberof PricingOptionApiCreateAveragePricingContract
   */
  readonly averagePricingContractRequest: AveragePricingContractRequest;
}

/**
 * Request parameters for createDelayedPricingContract operation in PricingOptionApi.
 * @export
 * @interface PricingOptionApiCreateDelayedPricingContractRequest
 */
export interface PricingOptionApiCreateDelayedPricingContractRequest {
  /**
   *
   * @type {string}
   * @memberof PricingOptionApiCreateDelayedPricingContract
   */
  readonly cropYearId: string;

  /**
   *
   * @type {string}
   * @memberof PricingOptionApiCreateDelayedPricingContract
   */
  readonly farmId: string;

  /**
   *
   * @type {CreateDelayedPricingContractRequest}
   * @memberof PricingOptionApiCreateDelayedPricingContract
   */
  readonly createDelayedPricingContractRequest: CreateDelayedPricingContractRequest;
}

/**
 * Request parameters for createForwardContract operation in PricingOptionApi.
 * @export
 * @interface PricingOptionApiCreateForwardContractRequest
 */
export interface PricingOptionApiCreateForwardContractRequest {
  /**
   *
   * @type {string}
   * @memberof PricingOptionApiCreateForwardContract
   */
  readonly cropYearId: string;

  /**
   *
   * @type {string}
   * @memberof PricingOptionApiCreateForwardContract
   */
  readonly farmId: string;

  /**
   *
   * @type {CreateForwardContractRequest}
   * @memberof PricingOptionApiCreateForwardContract
   */
  readonly createForwardContractRequest: CreateForwardContractRequest;
}

/**
 * Request parameters for deleteAveragePricingContract operation in PricingOptionApi.
 * @export
 * @interface PricingOptionApiDeleteAveragePricingContractRequest
 */
export interface PricingOptionApiDeleteAveragePricingContractRequest {
  /**
   *
   * @type {string}
   * @memberof PricingOptionApiDeleteAveragePricingContract
   */
  readonly cropYearId: string;

  /**
   *
   * @type {string}
   * @memberof PricingOptionApiDeleteAveragePricingContract
   */
  readonly id: string;

  /**
   *
   * @type {string}
   * @memberof PricingOptionApiDeleteAveragePricingContract
   */
  readonly farmId: string;
}

/**
 * Request parameters for deleteForwardContract operation in PricingOptionApi.
 * @export
 * @interface PricingOptionApiDeleteForwardContractRequest
 */
export interface PricingOptionApiDeleteForwardContractRequest {
  /**
   *
   * @type {string}
   * @memberof PricingOptionApiDeleteForwardContract
   */
  readonly cropYearId: string;

  /**
   *
   * @type {string}
   * @memberof PricingOptionApiDeleteForwardContract
   */
  readonly id: string;

  /**
   *
   * @type {string}
   * @memberof PricingOptionApiDeleteForwardContract
   */
  readonly farmId: string;
}

/**
 * Request parameters for deleteForwardContracts operation in PricingOptionApi.
 * @export
 * @interface PricingOptionApiDeleteForwardContractsRequest
 */
export interface PricingOptionApiDeleteForwardContractsRequest {
  /**
   *
   * @type {string}
   * @memberof PricingOptionApiDeleteForwardContracts
   */
  readonly cropYearId: string;

  /**
   *
   * @type {DeleteForwardContractsRequest}
   * @memberof PricingOptionApiDeleteForwardContracts
   */
  readonly deleteForwardContractsRequest: DeleteForwardContractsRequest;
}

/**
 * Request parameters for downloadPricingDataExport operation in PricingOptionApi.
 * @export
 * @interface PricingOptionApiDownloadPricingDataExportRequest
 */
export interface PricingOptionApiDownloadPricingDataExportRequest {
  /**
   *
   * @type {string}
   * @memberof PricingOptionApiDownloadPricingDataExport
   */
  readonly cropYearId: string;
}

/**
 * Request parameters for getAveragePricingContractForFarm operation in PricingOptionApi.
 * @export
 * @interface PricingOptionApiGetAveragePricingContractForFarmRequest
 */
export interface PricingOptionApiGetAveragePricingContractForFarmRequest {
  /**
   *
   * @type {string}
   * @memberof PricingOptionApiGetAveragePricingContractForFarm
   */
  readonly cropYearId: string;

  /**
   *
   * @type {string}
   * @memberof PricingOptionApiGetAveragePricingContractForFarm
   */
  readonly farmId: string;
}

/**
 * Request parameters for getCropYearPricingOptionsList operation in PricingOptionApi.
 * @export
 * @interface PricingOptionApiGetCropYearPricingOptionsListRequest
 */
export interface PricingOptionApiGetCropYearPricingOptionsListRequest {
  /**
   *
   * @type {string}
   * @memberof PricingOptionApiGetCropYearPricingOptionsList
   */
  readonly cropYearId: string;

  /**
   *
   * @type {string}
   * @memberof PricingOptionApiGetCropYearPricingOptionsList
   */
  readonly search?: string;

  /**
   * Zero-based page index (0..N)
   * @type {number}
   * @memberof PricingOptionApiGetCropYearPricingOptionsList
   */
  readonly page?: number;

  /**
   * The size of the page to be returned
   * @type {number}
   * @memberof PricingOptionApiGetCropYearPricingOptionsList
   */
  readonly size?: number;

  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @type {Array<string>}
   * @memberof PricingOptionApiGetCropYearPricingOptionsList
   */
  readonly sort?: Array<string>;
}

/**
 * Request parameters for getCurrentPricesForDelayedPricing operation in PricingOptionApi.
 * @export
 * @interface PricingOptionApiGetCurrentPricesForDelayedPricingRequest
 */
export interface PricingOptionApiGetCurrentPricesForDelayedPricingRequest {
  /**
   *
   * @type {string}
   * @memberof PricingOptionApiGetCurrentPricesForDelayedPricing
   */
  readonly cropYearId: string;

  /**
   *
   * @type {string}
   * @memberof PricingOptionApiGetCurrentPricesForDelayedPricing
   */
  readonly farmId: string;
}

/**
 * Request parameters for getCurrentPricesForForwardPricing operation in PricingOptionApi.
 * @export
 * @interface PricingOptionApiGetCurrentPricesForForwardPricingRequest
 */
export interface PricingOptionApiGetCurrentPricesForForwardPricingRequest {
  /**
   *
   * @type {string}
   * @memberof PricingOptionApiGetCurrentPricesForForwardPricing
   */
  readonly cropYearId: string;

  /**
   *
   * @type {string}
   * @memberof PricingOptionApiGetCurrentPricesForForwardPricing
   */
  readonly farmId: string;
}

/**
 * Request parameters for getFarmPricingOptionsList operation in PricingOptionApi.
 * @export
 * @interface PricingOptionApiGetFarmPricingOptionsListRequest
 */
export interface PricingOptionApiGetFarmPricingOptionsListRequest {
  /**
   *
   * @type {string}
   * @memberof PricingOptionApiGetFarmPricingOptionsList
   */
  readonly cropYearId: string;

  /**
   *
   * @type {string}
   * @memberof PricingOptionApiGetFarmPricingOptionsList
   */
  readonly farmId: string;

  /**
   *
   * @type {string}
   * @memberof PricingOptionApiGetFarmPricingOptionsList
   */
  readonly search?: string;

  /**
   * Zero-based page index (0..N)
   * @type {number}
   * @memberof PricingOptionApiGetFarmPricingOptionsList
   */
  readonly page?: number;

  /**
   * The size of the page to be returned
   * @type {number}
   * @memberof PricingOptionApiGetFarmPricingOptionsList
   */
  readonly size?: number;

  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @type {Array<string>}
   * @memberof PricingOptionApiGetFarmPricingOptionsList
   */
  readonly sort?: Array<string>;
}

/**
 * Request parameters for getPricingOptionDetails operation in PricingOptionApi.
 * @export
 * @interface PricingOptionApiGetPricingOptionDetailsRequest
 */
export interface PricingOptionApiGetPricingOptionDetailsRequest {
  /**
   *
   * @type {string}
   * @memberof PricingOptionApiGetPricingOptionDetails
   */
  readonly id: string;

  /**
   *
   * @type {string}
   * @memberof PricingOptionApiGetPricingOptionDetails
   */
  readonly farmId?: string;
}

/**
 * Request parameters for updateAveragePricingContract operation in PricingOptionApi.
 * @export
 * @interface PricingOptionApiUpdateAveragePricingContractRequest
 */
export interface PricingOptionApiUpdateAveragePricingContractRequest {
  /**
   *
   * @type {string}
   * @memberof PricingOptionApiUpdateAveragePricingContract
   */
  readonly cropYearId: string;

  /**
   *
   * @type {string}
   * @memberof PricingOptionApiUpdateAveragePricingContract
   */
  readonly id: string;

  /**
   *
   * @type {string}
   * @memberof PricingOptionApiUpdateAveragePricingContract
   */
  readonly farmId: string;

  /**
   *
   * @type {AveragePricingContractRequest}
   * @memberof PricingOptionApiUpdateAveragePricingContract
   */
  readonly averagePricingContractRequest: AveragePricingContractRequest;
}

/**
 * Request parameters for updateDelayedPricingContract operation in PricingOptionApi.
 * @export
 * @interface PricingOptionApiUpdateDelayedPricingContractRequest
 */
export interface PricingOptionApiUpdateDelayedPricingContractRequest {
  /**
   *
   * @type {string}
   * @memberof PricingOptionApiUpdateDelayedPricingContract
   */
  readonly cropYearId: string;

  /**
   *
   * @type {string}
   * @memberof PricingOptionApiUpdateDelayedPricingContract
   */
  readonly id: string;

  /**
   *
   * @type {string}
   * @memberof PricingOptionApiUpdateDelayedPricingContract
   */
  readonly farmId: string;

  /**
   *
   * @type {UpdateDelayedContractRequest}
   * @memberof PricingOptionApiUpdateDelayedPricingContract
   */
  readonly updateDelayedContractRequest: UpdateDelayedContractRequest;
}

/**
 * Request parameters for updateForwardContract operation in PricingOptionApi.
 * @export
 * @interface PricingOptionApiUpdateForwardContractRequest
 */
export interface PricingOptionApiUpdateForwardContractRequest {
  /**
   *
   * @type {string}
   * @memberof PricingOptionApiUpdateForwardContract
   */
  readonly cropYearId: string;

  /**
   *
   * @type {string}
   * @memberof PricingOptionApiUpdateForwardContract
   */
  readonly id: string;

  /**
   *
   * @type {string}
   * @memberof PricingOptionApiUpdateForwardContract
   */
  readonly farmId: string;

  /**
   *
   * @type {CreateForwardContractRequest}
   * @memberof PricingOptionApiUpdateForwardContract
   */
  readonly createForwardContractRequest: CreateForwardContractRequest;
}

/**
 * Request parameters for updateForwardContractDeferment operation in PricingOptionApi.
 * @export
 * @interface PricingOptionApiUpdateForwardContractDefermentRequest
 */
export interface PricingOptionApiUpdateForwardContractDefermentRequest {
  /**
   *
   * @type {string}
   * @memberof PricingOptionApiUpdateForwardContractDeferment
   */
  readonly cropYearId: string;

  /**
   *
   * @type {string}
   * @memberof PricingOptionApiUpdateForwardContractDeferment
   */
  readonly id: string;

  /**
   *
   * @type {string}
   * @memberof PricingOptionApiUpdateForwardContractDeferment
   */
  readonly farmId: string;

  /**
   *
   * @type {UpdateContractDefermentRequest}
   * @memberof PricingOptionApiUpdateForwardContractDeferment
   */
  readonly updateContractDefermentRequest: UpdateContractDefermentRequest;
}

/**
 * Request parameters for updatePendingStandardForwardContract operation in PricingOptionApi.
 * @export
 * @interface PricingOptionApiUpdatePendingStandardForwardContractRequest
 */
export interface PricingOptionApiUpdatePendingStandardForwardContractRequest {
  /**
   *
   * @type {string}
   * @memberof PricingOptionApiUpdatePendingStandardForwardContract
   */
  readonly cropYearId: string;

  /**
   *
   * @type {string}
   * @memberof PricingOptionApiUpdatePendingStandardForwardContract
   */
  readonly id: string;

  /**
   *
   * @type {string}
   * @memberof PricingOptionApiUpdatePendingStandardForwardContract
   */
  readonly farmId: string;

  /**
   *
   * @type {UpdatePendingStandardForwardContractRequest}
   * @memberof PricingOptionApiUpdatePendingStandardForwardContract
   */
  readonly updatePendingStandardForwardContractRequest: UpdatePendingStandardForwardContractRequest;
}

/**
 * PricingOptionApi - object-oriented interface
 * @export
 * @class PricingOptionApi
 * @extends {BaseAPI}
 */
export class PricingOptionApi extends BaseAPI {
  /**
   *
   * @param {PricingOptionApiApproveForwardContractRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PricingOptionApi
   */
  public approveForwardContract(
    requestParameters: PricingOptionApiApproveForwardContractRequest,
    options?: AxiosRequestConfig
  ) {
    return PricingOptionApiFp(this.configuration)
      .approveForwardContract(
        requestParameters.cropYearId,
        requestParameters.forwardContractId,
        requestParameters.farmId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {PricingOptionApiCalculateContractPriceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PricingOptionApi
   */
  public calculateContractPrice(
    requestParameters: PricingOptionApiCalculateContractPriceRequest,
    options?: AxiosRequestConfig
  ) {
    return PricingOptionApiFp(this.configuration)
      .calculateContractPrice(
        requestParameters.cropYearId,
        requestParameters.farmId,
        requestParameters.calculateContractPriceRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {PricingOptionApiCalculateDelayedPricingDetailsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PricingOptionApi
   */
  public calculateDelayedPricingDetails(
    requestParameters: PricingOptionApiCalculateDelayedPricingDetailsRequest,
    options?: AxiosRequestConfig
  ) {
    return PricingOptionApiFp(this.configuration)
      .calculateDelayedPricingDetails(
        requestParameters.cropYearId,
        requestParameters.farmId,
        requestParameters.tons,
        requestParameters.futuresPrice,
        requestParameters.basis,
        requestParameters.dateOfContract,
        requestParameters.cashPrice,
        requestParameters.isManuallyCreated,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {PricingOptionApiCreateAveragePricingContractRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PricingOptionApi
   */
  public createAveragePricingContract(
    requestParameters: PricingOptionApiCreateAveragePricingContractRequest,
    options?: AxiosRequestConfig
  ) {
    return PricingOptionApiFp(this.configuration)
      .createAveragePricingContract(
        requestParameters.cropYearId,
        requestParameters.farmId,
        requestParameters.averagePricingContractRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {PricingOptionApiCreateDelayedPricingContractRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PricingOptionApi
   */
  public createDelayedPricingContract(
    requestParameters: PricingOptionApiCreateDelayedPricingContractRequest,
    options?: AxiosRequestConfig
  ) {
    return PricingOptionApiFp(this.configuration)
      .createDelayedPricingContract(
        requestParameters.cropYearId,
        requestParameters.farmId,
        requestParameters.createDelayedPricingContractRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {PricingOptionApiCreateForwardContractRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PricingOptionApi
   */
  public createForwardContract(
    requestParameters: PricingOptionApiCreateForwardContractRequest,
    options?: AxiosRequestConfig
  ) {
    return PricingOptionApiFp(this.configuration)
      .createForwardContract(
        requestParameters.cropYearId,
        requestParameters.farmId,
        requestParameters.createForwardContractRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {PricingOptionApiDeleteAveragePricingContractRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PricingOptionApi
   */
  public deleteAveragePricingContract(
    requestParameters: PricingOptionApiDeleteAveragePricingContractRequest,
    options?: AxiosRequestConfig
  ) {
    return PricingOptionApiFp(this.configuration)
      .deleteAveragePricingContract(
        requestParameters.cropYearId,
        requestParameters.id,
        requestParameters.farmId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {PricingOptionApiDeleteForwardContractRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PricingOptionApi
   */
  public deleteForwardContract(
    requestParameters: PricingOptionApiDeleteForwardContractRequest,
    options?: AxiosRequestConfig
  ) {
    return PricingOptionApiFp(this.configuration)
      .deleteForwardContract(
        requestParameters.cropYearId,
        requestParameters.id,
        requestParameters.farmId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {PricingOptionApiDeleteForwardContractsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PricingOptionApi
   */
  public deleteForwardContracts(
    requestParameters: PricingOptionApiDeleteForwardContractsRequest,
    options?: AxiosRequestConfig
  ) {
    return PricingOptionApiFp(this.configuration)
      .deleteForwardContracts(
        requestParameters.cropYearId,
        requestParameters.deleteForwardContractsRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {PricingOptionApiDownloadPricingDataExportRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PricingOptionApi
   */
  public downloadPricingDataExport(
    requestParameters: PricingOptionApiDownloadPricingDataExportRequest,
    options?: AxiosRequestConfig
  ) {
    return PricingOptionApiFp(this.configuration)
      .downloadPricingDataExport(requestParameters.cropYearId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {PricingOptionApiGetAveragePricingContractForFarmRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PricingOptionApi
   */
  public getAveragePricingContractForFarm(
    requestParameters: PricingOptionApiGetAveragePricingContractForFarmRequest,
    options?: AxiosRequestConfig
  ) {
    return PricingOptionApiFp(this.configuration)
      .getAveragePricingContractForFarm(
        requestParameters.cropYearId,
        requestParameters.farmId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {PricingOptionApiGetCropYearPricingOptionsListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PricingOptionApi
   */
  public getCropYearPricingOptionsList(
    requestParameters: PricingOptionApiGetCropYearPricingOptionsListRequest,
    options?: AxiosRequestConfig
  ) {
    return PricingOptionApiFp(this.configuration)
      .getCropYearPricingOptionsList(
        requestParameters.cropYearId,
        requestParameters.search,
        requestParameters.page,
        requestParameters.size,
        requestParameters.sort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {PricingOptionApiGetCurrentPricesForDelayedPricingRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PricingOptionApi
   */
  public getCurrentPricesForDelayedPricing(
    requestParameters: PricingOptionApiGetCurrentPricesForDelayedPricingRequest,
    options?: AxiosRequestConfig
  ) {
    return PricingOptionApiFp(this.configuration)
      .getCurrentPricesForDelayedPricing(
        requestParameters.cropYearId,
        requestParameters.farmId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {PricingOptionApiGetCurrentPricesForForwardPricingRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PricingOptionApi
   */
  public getCurrentPricesForForwardPricing(
    requestParameters: PricingOptionApiGetCurrentPricesForForwardPricingRequest,
    options?: AxiosRequestConfig
  ) {
    return PricingOptionApiFp(this.configuration)
      .getCurrentPricesForForwardPricing(
        requestParameters.cropYearId,
        requestParameters.farmId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {PricingOptionApiGetFarmPricingOptionsListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PricingOptionApi
   */
  public getFarmPricingOptionsList(
    requestParameters: PricingOptionApiGetFarmPricingOptionsListRequest,
    options?: AxiosRequestConfig
  ) {
    return PricingOptionApiFp(this.configuration)
      .getFarmPricingOptionsList(
        requestParameters.cropYearId,
        requestParameters.farmId,
        requestParameters.search,
        requestParameters.page,
        requestParameters.size,
        requestParameters.sort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {PricingOptionApiGetPricingOptionDetailsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PricingOptionApi
   */
  public getPricingOptionDetails(
    requestParameters: PricingOptionApiGetPricingOptionDetailsRequest,
    options?: AxiosRequestConfig
  ) {
    return PricingOptionApiFp(this.configuration)
      .getPricingOptionDetails(
        requestParameters.id,
        requestParameters.farmId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {PricingOptionApiUpdateAveragePricingContractRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PricingOptionApi
   */
  public updateAveragePricingContract(
    requestParameters: PricingOptionApiUpdateAveragePricingContractRequest,
    options?: AxiosRequestConfig
  ) {
    return PricingOptionApiFp(this.configuration)
      .updateAveragePricingContract(
        requestParameters.cropYearId,
        requestParameters.id,
        requestParameters.farmId,
        requestParameters.averagePricingContractRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {PricingOptionApiUpdateDelayedPricingContractRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PricingOptionApi
   */
  public updateDelayedPricingContract(
    requestParameters: PricingOptionApiUpdateDelayedPricingContractRequest,
    options?: AxiosRequestConfig
  ) {
    return PricingOptionApiFp(this.configuration)
      .updateDelayedPricingContract(
        requestParameters.cropYearId,
        requestParameters.id,
        requestParameters.farmId,
        requestParameters.updateDelayedContractRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {PricingOptionApiUpdateForwardContractRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PricingOptionApi
   */
  public updateForwardContract(
    requestParameters: PricingOptionApiUpdateForwardContractRequest,
    options?: AxiosRequestConfig
  ) {
    return PricingOptionApiFp(this.configuration)
      .updateForwardContract(
        requestParameters.cropYearId,
        requestParameters.id,
        requestParameters.farmId,
        requestParameters.createForwardContractRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {PricingOptionApiUpdateForwardContractDefermentRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PricingOptionApi
   */
  public updateForwardContractDeferment(
    requestParameters: PricingOptionApiUpdateForwardContractDefermentRequest,
    options?: AxiosRequestConfig
  ) {
    return PricingOptionApiFp(this.configuration)
      .updateForwardContractDeferment(
        requestParameters.cropYearId,
        requestParameters.id,
        requestParameters.farmId,
        requestParameters.updateContractDefermentRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {PricingOptionApiUpdatePendingStandardForwardContractRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PricingOptionApi
   */
  public updatePendingStandardForwardContract(
    requestParameters: PricingOptionApiUpdatePendingStandardForwardContractRequest,
    options?: AxiosRequestConfig
  ) {
    return PricingOptionApiFp(this.configuration)
      .updatePendingStandardForwardContract(
        requestParameters.cropYearId,
        requestParameters.id,
        requestParameters.farmId,
        requestParameters.updatePendingStandardForwardContractRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
