import { skipToken, useInfiniteQuery } from "@tanstack/react-query";

import { FieldListDto } from "api";
import { useFieldService } from "contexts/ServiceProvider";
import { getNextPage, QueryParams } from "services/apiHelpers";
import { FieldsListSortColumns } from "services/FieldService";

export type AllFieldListItem = Pick<
  FieldListDto,
  | "id"
  | "fieldNumber"
  | "totalAcres"
  | "tonsPerAcre"
  | "dairy"
  | "legalDescription"
  | "commonName"
  | "farms"
  | "region"
>;

export const GET_ALL_FIELDS_QUERY_KEY = "get-all-fields-list";

const useAllFieldsList = ({
  cropYearId,
  queryParams,
}: {
  cropYearId?: string;
  queryParams?: Omit<QueryParams<FieldsListSortColumns>, "page">;
}) => {
  const fieldService = useFieldService();

  return useInfiniteQuery({
    queryKey: [GET_ALL_FIELDS_QUERY_KEY, { cropYearId }, queryParams],
    queryFn: cropYearId
      ? ({ pageParam = 0 }) =>
          fieldService
            .getFields({
              cropYearId,
              queryParams: { ...queryParams, page: pageParam },
            })
            .then(({ data, meta }) => ({
              fields: data,
              meta,
            }))
      : skipToken,
    initialPageParam: 0,
    getNextPageParam: (lastPage) => getNextPage(lastPage),
  });
};

export default useAllFieldsList;
