import { ReactNode } from "react";

import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { UserResponse, UserStatusDtoValueEnum } from "api";
import { formatPhoneNumber } from "utils/formatFunctions";
import {
  registeredUserDetailFormSchema,
  userDetailFormSchema,
} from "forms/user-detail";
import { SelectOption } from "components/Select";

export type UserDetailFields = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  role: SelectOption;
  farmClaims?: SelectOption[];
};

export type UserDetailsFormContextProps = {
  children?: ReactNode;
  userData?: UserResponse;
};

const UserDetailsFormContext = ({
  children,
  userData,
}: UserDetailsFormContextProps) => {
  const methods = useForm<UserDetailFields>({
    resolver: yupResolver(
      userData?.status.value === UserStatusDtoValueEnum.Active
        ? registeredUserDetailFormSchema
        : userDetailFormSchema
    ),
    defaultValues: {
      firstName: userData?.firstName,
      lastName: userData?.lastName,
      phoneNumber: formatPhoneNumber(userData?.phoneNumber),
      email: userData?.email,
      role: {
        value: userData?.role[0]?.role.value,
        label: userData?.role[0]?.role.displayName,
      },
      farmClaims: userData?.farms.map((farm) => ({
        value: farm.role.value,
        label: farm.role.displayName,
      })),
    },
  });

  return <FormProvider {...methods}>{children}</FormProvider>;
};

export default UserDetailsFormContext;
