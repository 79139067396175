import { Path, useController, useFormContext } from "react-hook-form";

import { MinimumFarmListDto } from "api";
import Select, { SelectOption } from "components/Select";
import { InputWrapper } from "components/inputs";
import { SplitFormProps } from "../SplitsDialog";

type FarmSelectProps = {
  id: Path<SplitFormProps>;
  selectableFarms: MinimumFarmListDto[];
  defaultValue?: Partial<SelectOption>;
};

const SplitFarmSelect = ({
  id,
  selectableFarms,
  defaultValue,
}: FarmSelectProps) => {
  const { control } = useFormContext<SplitFormProps>();

  const {
    field,
    fieldState: { error },
  } = useController({
    name: id,
    control: control,
    defaultValue:
      defaultValue?.value && defaultValue?.label ? defaultValue : undefined,
  });

  const options = selectableFarms.map(({ doingBusinessAs, id: _id }) => ({
    label: doingBusinessAs,
    value: _id,
  }));

  return (
    <InputWrapper
      isRequired
      label="Farm"
      isInvalid={!!error}
      errorMsg={error?.message}
    >
      <Select options={options} {...field} />
    </InputWrapper>
  );
};

export default SplitFarmSelect;
