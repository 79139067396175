import { ReactElement } from "react";
import { Outlet } from "react-router-dom";

import { Center, HStack, Show, Stack } from "@chakra-ui/react";

import ContentWrapper from "components/ContentWrapper";
import SiteHeader from "components/SiteHeader";
import NavigationContainer from "components/navigation/NavigationContainer";
import { DESKTOP_HEADER_PADDING } from "components/page-components";
import { mobileStyleBreakpoint } from "utils/styleHelpers";

const TABLE_CONTENT_PX = mobileStyleBreakpoint(0, DESKTOP_HEADER_PADDING);

const TableLayout = (): ReactElement => (
  <Stack h="100vh" overflow="hidden" spacing={0}>
    <SiteHeader />
    <HStack flex={1} spacing={0} align="stretch">
      <Show above="md" ssr={false}>
        <NavigationContainer />
      </Show>
      <Center px={TABLE_CONTENT_PX} w="100%" overflow="hidden">
        <ContentWrapper>
          <Outlet />
        </ContentWrapper>
      </Center>
    </HStack>
  </Stack>
);

export default TableLayout;
