import { HStack, Text, VStack } from "@chakra-ui/react";
import Card, { CardBody, CardHeader } from "components/Card";
import { AlertCircleOutline } from "icons";
import ErrorGuideList, { GuideListItemType } from "./ErrorGuideList";

const ErrorGuide = ({
  errorGuideListData,
}: {
  errorGuideListData: GuideListItemType[];
}) => {
  return (
    <Card h="373px" variant="innerContainer" w="100%">
      <CardHeader display="left" mb={4}>
        <HStack>
          <AlertCircleOutline color="errorRed" />
          <Text color="errorRed" textStyle="h5">
            Error Guide
          </Text>
        </HStack>
      </CardHeader>
      <CardBody
        border="4px"
        borderColor="grey.100"
        overflowY="auto"
        pt={4}
        pr={72}
        pb={0}
        pl={16}
      >
        <VStack align="left" h="235px" spacing={16} w="1463px">
          <ErrorGuideList errorGuideListData={errorGuideListData} />
        </VStack>
      </CardBody>
    </Card>
  );
};

export default ErrorGuide;
