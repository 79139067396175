import { useQuery } from "@tanstack/react-query";

import { ManureYearResponse } from "api/models/manure-year-response";
import { useManureYearService } from "contexts/ServiceProvider";

import { BaseApiError } from "services/apiHelpers";

export const GET_MANURE_YEAR_DETAIL_QUERY_KEY = "manure-year-detail";

const useGetManureYear = (manureYearId?: string) => {
  const manureYearService = useManureYearService();

  return useQuery<ManureYearResponse | undefined, BaseApiError>({
    queryKey: [GET_MANURE_YEAR_DETAIL_QUERY_KEY, manureYearId],
    queryFn: () =>
      manureYearId
        ? manureYearService.getManureYear({ id: manureYearId })
        : Promise.reject(new Error("manureYearId is undefined")),
    enabled: !!manureYearId,
  });
};

export default useGetManureYear;
