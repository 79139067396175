import * as yup from "yup";
import { selectOptionSchema } from "forms/schemas";
import { SelectOption } from "components/Select";
import { getIsDeferPayment } from "utils/getIsDeferPayment";

export const averagePricingSchema = (
  maxPercent: number,
  minDate: Date | undefined
) =>
  yup
    .object({
      percentageToSell: yup
        .number()
        .moreThan(0, "Percentage to sell must be more than 0")
        .max(maxPercent, `Percentage to sell must be ${maxPercent} or less`)
        .required(),
      deferPayment: selectOptionSchema,
      paymentDate: yup.date().when("deferPayment", {
        is: (value: SelectOption) => !getIsDeferPayment(value.value),
        then: (schema) => schema.min(minDate).required(),
      }),
    })
    .required();
