import { MinimumFarmListDto } from "api";
import { useFarmService } from "contexts/ServiceProvider";
import { skipToken, useInfiniteQuery } from "@tanstack/react-query";
import { getNextPage, PaginationMeta } from "services/apiHelpers";

export type GetFarmListByCropYearResponse = {
  farmList: MinimumFarmListDto[];
  meta: PaginationMeta;
};

export const GET_FARMS_IN_CROP_YEAR_QUERY_KEY = "get-farms-in-crop-year";

const useGetFarmListByCropYear = (cropYearId?: string) => {
  const farmService = useFarmService();

  return useInfiniteQuery({
    queryKey: [GET_FARMS_IN_CROP_YEAR_QUERY_KEY, { cropYearId }],
    queryFn: cropYearId
      ? ({ pageParam = 0 }) =>
          farmService
            .getFarmsInCropYear(cropYearId, {
              page: pageParam,
              size: 1000,
            })
            .then(({ data, meta }) => ({
              farmList: data,
              meta,
            }))
      : skipToken,
    initialPageParam: 0,
    getNextPageParam: (lastPage) => getNextPage(lastPage),
  });
};

export default useGetFarmListByCropYear;
