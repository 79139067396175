import {
  extendTheme,
  withDefaultColorScheme,
  ChakraTheme,
} from "@chakra-ui/react";

import foundations from "./foundations";
import components from "./components";
import textStyles, { desktopHeadings } from "./textStyles";

const overrides: Partial<ChakraTheme> = {
  ...foundations,
  textStyles,
  components,
  styles: {
    global: {
      body: {
        bg: "grayFactor.0",
      },
      ...desktopHeadings,
    },
  },
};
export default extendTheme(
  overrides,
  withDefaultColorScheme({ colorScheme: "brand" })
);
