import { ChangeEvent, useEffect, useRef } from "react";
import { Box, HStack } from "@chakra-ui/react";

import StandardInput from "components/inputs/StandardInput";
import StyledSlider from "components/Slider/Slider";
import {
  formatPercentForDisplay,
  parsePercentForState,
} from "utils/formatFunctions";

const PercentWithSliderInput = ({
  value = 0,
  onChange,
}: {
  value?: number;
  onChange?: (newValue: number) => void;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    /*
     Ensure the users selection is never automatically placed
     after the %.
     */
    inputRef.current?.setSelectionRange(
      value.toString().length,
      value.toString().length
    );
  }, [value]);

  const onChangeValue = (_value: number) => {
    onChange?.(_value);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const valueString = parsePercentForState(e.target.value);
    /*
      If the user moves their cursor after the percent sign,
      then presses delete, ensure the last digit is deleted.
       */
    const _value = Number(
      valueString === value.toString() ? valueString.slice(0, -1) : valueString
    );
    onChangeValue(isNaN(_value) ? 0 : _value);
  };

  return (
    <HStack>
      <StandardInput
        maxW={"70px"}
        inputGroupProps={{ maxW: "70px" }}
        value={formatPercentForDisplay(value)}
        onChange={handleInputChange}
        onBlur={() => onChangeValue(value !== undefined ? value : 0)}
        ref={inputRef}
      />
      <Box w={"100%"} pl={2}>
        <StyledSlider
          focusThumbOnChange={false}
          aria-label="slider-ex-1"
          min={0}
          max={100}
          onChange={onChangeValue}
          value={value}
        />
      </Box>
    </HStack>
  );
};

export default PercentWithSliderInput;
