import { ReactElement } from "react";
import { Flex, Stack } from "@chakra-ui/react";
import FarmMenu from "components/navigation/FarmMenu";
import RiverviewEmployeeCard from "components/navigation/RiverviewEmployeeCard";
import { HAYLAGE_PROGRAM, MANURE_PROGRAM } from "constants/featureFlags";
import { useUserInfo } from "contexts/UserInfoProvider";
import useUserRoleFlags from "hooks/auth/useUserRoleFlags";
import { useHasFeatureFlag } from "hooks/useFeatureFlags";
import useSelectedFarmDetail from "hooks/useSelectedFarmDetail";
import { isFarmPending } from "utils/farmStatusUtils";
import NavigationHelp from "./NavigationHelp/NavigationHelp";
import {
  FarmInfoNavigationItem,
  FarmsNavigationItem,
  HaylageNavigationItem,
  ManureNavigationItem,
  SilageDashboardNavigationItem,
  UsersNavigationItem,
} from "./NavigationItem";

const NavigationBadge = ({
  isRiverviewEmployee,
}: {
  isRiverviewEmployee: boolean;
}): ReactElement =>
  isRiverviewEmployee ? <RiverviewEmployeeCard /> : <FarmMenu />;

const NavigationContainer = ({
  onClickItem,
}: {
  onClickItem?: () => void;
}): ReactElement => {
  const hasManureProgramFeature = useHasFeatureFlag(
    MANURE_PROGRAM.MANURE_NAV_LINK
  );
  const hasHaylageProgramFeature = useHasFeatureFlag(
    HAYLAGE_PROGRAM.HAYLAGE_NAV_LINK
  );
  const { isEmployee, isFarmer } = useUserRoleFlags();
  const { data: selectedFarm } = useSelectedFarmDetail();
  const isSelectedFarmPending =
    selectedFarm && isFarmPending(selectedFarm.status.value);
  const { farms } = useUserInfo();

  const displayNavigationItemBaseConditions =
    isFarmer && !isSelectedFarmPending && farms.length !== 0;
  const displayManureNavigationItemForFarmer =
    displayNavigationItemBaseConditions &&
    selectedFarm?.manureYears &&
    selectedFarm?.manureYears?.length > 0;
  const displayHaylageNavigationItemForFarmer =
    displayNavigationItemBaseConditions &&
    selectedFarm?.haylageYears &&
    selectedFarm?.haylageYears?.length > 0;

  return (
    <Stack
      bg="grayFactor.1"
      w="320px"
      minW="320px"
      pt={1}
      spacing={0}
      height={"100%"}
    >
      <Stack p={4}>
        <NavigationBadge isRiverviewEmployee={isEmployee} />
        {(isEmployee ||
          (displayNavigationItemBaseConditions &&
            selectedFarm?.cropYears &&
            selectedFarm?.cropYears?.length > 0)) && (
          <SilageDashboardNavigationItem onClick={onClickItem} />
        )}
        {hasManureProgramFeature &&
          (isEmployee || displayManureNavigationItemForFarmer) && (
            <ManureNavigationItem onClick={onClickItem} />
          )}
        {hasHaylageProgramFeature &&
          (isEmployee || displayHaylageNavigationItemForFarmer) && (
            <HaylageNavigationItem onClick={onClickItem} />
          )}
        {isEmployee && <FarmsNavigationItem onClick={onClickItem} />}
        {(isEmployee || displayNavigationItemBaseConditions) && (
          <UsersNavigationItem onClick={onClickItem} />
        )}
        {isFarmer && <FarmInfoNavigationItem onClick={onClickItem} />}
      </Stack>
      <Flex
        px={4}
        height={"100%"}
        flexDirection={"column"}
        justifyContent={"flex-end"}
      >
        <NavigationHelp />
      </Flex>
    </Stack>
  );
};

export default NavigationContainer;
