import * as yup from "yup";
import { selectOptionSchema } from "forms/schemas";
import { formatDate } from "utils/dateUtils";
import { SelectOption } from "components/Select";
import { getIsDeferPayment } from "utils/getIsDeferPayment";

type params = {
  defaultDefermentDate: Date;
};

export const partialAveragePricingSchema = ({ defaultDefermentDate }: params) =>
  yup
    .object({
      deferPayment: selectOptionSchema,
      paymentDate: yup.date().when("deferPayment", {
        is: (value: SelectOption) => getIsDeferPayment(value.value),
        then: (schema) =>
          schema.test(
            "is-on-or-after-deferment-start",
            `Deferment date must be on or after ${formatDate(
              defaultDefermentDate
            )}`,
            (value) => {
              if (!value) {
                return false;
              }

              return new Date(value) >= defaultDefermentDate;
            }
          ),
      }),
    })
    .required();
