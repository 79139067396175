import { RouteObject } from "react-router";

import dashboardPaths from "./dashboardPaths";
import ProtectedRoute from "components/route-wrappers/ProtectedRoute";
import ReviewForwardPricingContractApprove from "forms/forward-pricing/ReviewForwardPricingContractApprove/ReviewForwardPricingContractApprove";
import {
  AllFieldsList,
  DocumentsList,
  FarmsList,
  FieldsList,
  PricingList,
  SilageDashboard,
  SilageDashboardLayout,
} from "pages/dashboard";
import NoFarms from "pages/dashboard/components/NoFarms";
import EditCropYearPage from "pages/crop-year-detail/EditCropYearPage";
import DailyPricingPage from "pages/dashboard/DailyPricing";
import {
  DelayedPricingPage,
  EditDelayedPricingPage,
} from "pages/dashboard/DelayedPricing";
import ForwardPricingContractPage from "pages/pricing/ForwardPricing";
import EditForwardPricingPage from "pages/dashboard/ForwardPricing/EditForwardPricingPage";
import ManualForwardPricingContractPage from "pages/pricing/ManualForwardPricingContract";

const dashboardRoutes: RouteObject = {
  path: dashboardPaths.basePath,
  element: (
    <ProtectedRoute>
      <SilageDashboardLayout />
    </ProtectedRoute>
  ),
  children: [
    {
      path: dashboardPaths.children.noFarms,
      element: <NoFarms />,
    },
    {
      path: `${dashboardPaths.children.newCropYear}`,
      element: <EditCropYearPage />,
    },
    {
      path: `${dashboardPaths.children.cropYear}/:id`,
      element: <EditCropYearPage />,
    },
    {
      path: dashboardPaths.children.dailyPricing,
      element: <DailyPricingPage />,
    },
    {
      path: `${dashboardPaths.children.newDelayedPricing}`,
      element: <DelayedPricingPage />,
    },
    {
      path: `${dashboardPaths.children.newForwardPricing}`,
      element: <ForwardPricingContractPage />,
    },
    {
      path: `${dashboardPaths.children.newManualForwardPricing}`,
      element: <ManualForwardPricingContractPage />,
    },
    {
      path: `${dashboardPaths.children.reviewForwardPricing}/:id`,
      element: <ReviewForwardPricingContractApprove />,
    },
    {
      path: `${dashboardPaths.children.forwardPricing}/:id`,
      element: <EditForwardPricingPage />,
    },
    {
      path: `${dashboardPaths.children.newManualDelayedPricing}`,
      element: <DelayedPricingPage isManuallyCreated={true} />,
    },
    {
      path: `${dashboardPaths.children.delayedPricing}/:id`,
      element: <EditDelayedPricingPage />,
    },
    {
      index: true,
      element: <SilageDashboard />,
    },
    {
      path: "*",
      element: <SilageDashboard />,
      children: [
        {
          path: dashboardPaths.children.farms,
          element: <FarmsList />,
        },
        {
          path: dashboardPaths.children.allFields,
          element: <AllFieldsList />,
        },
        {
          path: dashboardPaths.children.fields,
          element: <FieldsList />,
        },
        {
          path: dashboardPaths.children.pricing,
          element: <PricingList />,
        },
        {
          path: dashboardPaths.children.documents,
          element: <DocumentsList />,
        },
      ],
    },
  ],
};

export default dashboardRoutes;
