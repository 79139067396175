import { ReactElement } from "react";

import {
  Icon,
  Link,
  MenuItem,
  Stack,
  Text,
  HStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import {
  IoLogOutOutline,
  IoDocumentOutline,
  IoCaretDown,
} from "react-icons/io5";

import { MobileOnly } from "components/BreakpointVisibility";
import Menu from "components/Menu";
import ProfilePictureAvatar from "components/ProfilePictureAvatar";
import { useCropYearContext } from "contexts/CropYearContext";
import { useUserInfo } from "contexts/UserInfoProvider";
import useLogout from "hooks/auth/useLogout";
import { mobileStyleBreakpoint } from "utils/styleHelpers";

const MENU_LIST_YOFFSET = mobileStyleBreakpoint(20, 6);
const MENU_LIST_WIDTH = mobileStyleBreakpoint("100vw", "370px");

const UserMenu = (): ReactElement => {
  const { onLogout } = useLogout();
  const { profilePicture, firstName, lastName, role } = useUserInfo();
  const name = `${firstName} ${lastName}`;
  const { cropYear, cropYearDetail } = useCropYearContext();
  const menuYOffset = useBreakpointValue(MENU_LIST_YOFFSET) as number;

  return (
    <Menu
      button={
        <HStack spacing={4}>
          <ProfilePictureAvatar
            size="xs"
            name={name}
            src={profilePicture?.url}
            pb={0}
          />
          <Icon as={IoCaretDown} />
        </HStack>
      }
      menuListStyles={{ w: MENU_LIST_WIDTH }}
      menuStyles={{ offset: [0, menuYOffset], variant: "userMenu" }}
    >
      <Stack color="brand.800" px={4} pb={1} spacing={0}>
        <Text color="brand.800" fontWeight="bold">
          {name}
        </Text>
        <Text textStyle="caption" color="steelGray">
          {role.displayName}
        </Text>
      </Stack>
      <MenuItem
        key="log-out"
        onClick={() => {
          onLogout();
        }}
      >
        <Icon as={IoLogOutOutline} mr="10px" boxSize={5} />
        Log Out
      </MenuItem>
      {cropYear && (
        <MobileOnly>
          <MenuItem
            as={Link}
            isExternal
            href={cropYearDetail?.growerLetterLink}
            key={"grower-letter"}
          >
            <Icon as={IoDocumentOutline} mr="10px" boxSize={5} />
            {cropYear.year} Grower Letter
          </MenuItem>
        </MobileOnly>
      )}
    </Menu>
  );
};
export default UserMenu;
