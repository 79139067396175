import { useCallback, useEffect } from "react";
import useCustomToast from "hooks/useCustomToast";
import useNavigateWithQuery from "hooks/useNavigateWithQuery";
import dashboardPaths from "routes/dashboard/dashboardPaths";
import { useDebouncedCallback } from "use-debounce";

const useFuturesPriceError = (isError: boolean) => {
  const { onErrorToast } = useCustomToast();
  const navigate = useNavigateWithQuery();
  const navigateToFarmView = useCallback(
    () =>
      navigate(
        `/${dashboardPaths.basePath}/${dashboardPaths.children.pricing}`
      ),
    [navigate]
  );
  const handleError = useDebouncedCallback(() => {
    onErrorToast({
      title: `Error fetching futures data`,
      message: `Please try again. Contact Riverview if problem persists: 320-392-6713`,
    });
    navigateToFarmView();
  }, 5);

  useEffect(() => {
    if (isError) {
      handleError();
    }
  }, [isError, handleError]);
};

export default useFuturesPriceError;
