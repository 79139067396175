import { useMutation } from "@tanstack/react-query";

import { UpdatePendingStandardForwardContractRequest } from "api";
import { usePricingOptionService } from "contexts/ServiceProvider";
import useDashboardRefetchQueries from "hooks/crop-year/useDashboardRefetchQueries";
import { MutationHookResult } from "hooks/hookTypeHelpers";
import PricingOptionService from "services/PricingOptionService";

type UpdatePendingStandardForwardContractMutationResult = MutationHookResult<
  Awaited<
    ReturnType<PricingOptionService["updatePendingStandardForwardContract"]>
  >,
  UpdatePendingStandardForwardContractRequest
>;

export const UPDATE_PENDING_STANDARD_FORWARD_CONTRACT_QUERY_KEY =
  "update-pending-standard-forward-contract";

const useUpdatePendingStandardForwardContract = (
  contractId?: string,
  cropYearId?: string,
  farmId?: string
): UpdatePendingStandardForwardContractMutationResult => {
  const pricingOptionService = usePricingOptionService();
  const { refetch } = useDashboardRefetchQueries();

  return useMutation({
    mutationFn: (updateRequest) => {
      if (contractId && cropYearId && farmId) {
        return pricingOptionService.updatePendingStandardForwardContract({
          contractId,
          cropYearId,
          farmId,
          updateRequest,
        });
      } else {
        return Promise.reject(
          new Error("Missing contract id, crop year id, or farm id")
        );
      }
    },
    mutationKey: [UPDATE_PENDING_STANDARD_FORWARD_CONTRACT_QUERY_KEY],
    onSuccess: () => refetch(),
  });
};

export default useUpdatePendingStandardForwardContract;
