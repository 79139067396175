import { clearAuthFailure, getAuthFailure } from "utils/authLocalStorage";
import useCustomToast from "./useCustomToast";

export const useAuthFailure = () => {
  const { onErrorToast } = useCustomToast();

  const authFailed = getAuthFailure();

  if (authFailed) {
    onErrorToast({
      message: "Failed to re-authenticate. Please login.",
    });
    clearAuthFailure();
  }
};
