import { ComponentStyleConfig } from "@chakra-ui/react";

const Card: ComponentStyleConfig = {
  parts: ["card", "header", "body"],
  baseStyle: {
    card: {
      p: "72px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      bgColor: "white",
      borderRadius: 8,
    },
    header: {
      w: "100%",
      mb: 4,
      display: "flex",
      textStyle: "h3",
    },
    body: {
      w: "100%",
      display: "flex",
    },
    footer: {
      w: "100%",
      mt: 8,
      display: "flex",
    },
  },
  variants: {
    shadowed: {
      card: {
        boxShadow: "xlarge",
      },
    },
    shadowless: {
      card: {
        border: "1px solid",
        borderColor: "inherit",
      },
    },
    mobile: {
      card: {
        pt: 10,
        pb: 6,
        px: 6,
        w: "100vw",
        boxShadow: "none",
        borderRadius: "none",
      },
    },
    innerContainer: {
      card: {
        p: 16,
        boxShadow: "xlarge",
      },
      header: {
        mb: 10,
        justifyContent: "center",
      },
      footer: {
        mt: 10,
        justifyContent: "center",
      },
      body: {
        border: "1px solid",
        borderColor: "green.200",
        borderRadius: 8,
        p: 6,
        pt: 8,
      },
    },
  },
  defaultProps: {
    variant: "shadowed",
  },
};

export default Card;
