import { ReactElement } from "react";

import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";

import TextHeading from "components/TextHeading";
import { CARD_MAX_WIDTH } from "utils/styleHelpers";

const DeleteConfirmationModal = ({
  isOpen,
  onClose,
  headingText,
  bodyText,
  onDelete,
  isCentered = false,
}: {
  isOpen: boolean;
  onClose: () => void;
  headingText: string;
  bodyText: string;
  onDelete: () => void;
  isCentered?: boolean;
}): ReactElement => (
  <Modal isOpen={isOpen} onClose={onClose} isCentered={isCentered}>
    <ModalOverlay />
    <ModalContent maxW={CARD_MAX_WIDTH}>
      <ModalHeader>
        <TextHeading textStyle="h3">{headingText}</TextHeading>
      </ModalHeader>
      <ModalBody>
        <Stack w="100%">
          <Text pb={4}>{bodyText}</Text>
          <Button
            variant="destructive"
            onClick={() => {
              onDelete();
              onClose();
            }}
            aria-label="confirmation-delete-btn"
          >
            Delete
          </Button>
          <Button onClick={onClose} variant="outline">
            Cancel
          </Button>
        </Stack>
      </ModalBody>
    </ModalContent>
  </Modal>
);

export default DeleteConfirmationModal;
