import { ComponentStyleConfig } from "@chakra-ui/react";

const Checkbox: ComponentStyleConfig = {
  baseStyle: {
    label: {
      marginStart: 3,
    },
    control: {
      borderColor: "charcoal",
      _checked: {
        bg: "brand.800",
        borderColor: "brand.800",
        _hover: {
          bg: "brand.800",
          borderColor: "brand.800",
        },
        _focusVisible: {
          boxShadow: "none",
        },
      },
      _focusVisible: {
        boxShadow: "none",
      },
    },
    _focusVisible: {
      boxShadow: "none",
    },
  },
  variants: {
    validation: {
      label: {
        cursor: "default",
      },
      control: {
        cursor: "default",
      },
    },
    form: {
      container: {
        _checked: {
          _disabled: {
            cursor: "not-allowed",
          },
        },
      },
      label: {
        fontWeight: "bold",
        color: "graySmoke",
        _checked: {
          _disabled: {
            opacity: 1,
          },
        },
      },
      control: {
        borderColor: "steelGray",
        _checked: {
          _disabled: {
            opacity: 1,
            bg: "grayFactor.3",
            borderColor: "grayFactor.3",
          },
        },
      },
    },
  },
};

export default Checkbox;
