import { Text } from "@chakra-ui/react";
import { addDigitSeparators } from "utils/formatFunctions";
import { GrowingCropStatIcon } from "./SummaryStatIcons";
import { SummaryStatVariant } from "../SummaryStatCard";

export const estDollarsCommitted: SummaryStatVariant = {
  icon: <GrowingCropStatIcon />,
  label: "Est. Dollars Committed",
  formatter: (value) => (
    <Text>
      <Text as="sup">$</Text>
      {addDigitSeparators(Math.round(value))}
    </Text>
  ),
};
