import { ReactElement } from "react";
import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { headingToKey } from "components/SortableTable";

export type TableData<
  T extends Record<string, { value: any; isError: boolean }> = Record<
    string,
    any
  >
> = T;
export type ErrorColumnProps = {
  csvHeading: string;
  heading: string;
  id: string;
};

const ROWS_TO_SHOW = 6;
const HEADER_HEIGHT = 40; // Pulled from design
const ROW_HEIGHT = 37; // Pulled from design

const tableMaxHeight = `${ROWS_TO_SHOW * ROW_HEIGHT + HEADER_HEIGHT}px`;

const ErrorTable = ({
  columns = [],
  data = [],
}: {
  columns?: ErrorColumnProps[];
  data?: TableData[];
}): ReactElement => (
  <TableContainer w="100%" maxH={tableMaxHeight} overflowY="auto">
    <Table borderTop="0">
      {columns.length > 0 && (
        <Thead position="sticky" top={0} borderTop="0">
          <Tr>
            {columns.map(({ heading }, key) => (
              <Th
                key={headingToKey(heading ?? key)}
                w={1 / columns.length}
                data-testid={headingToKey(heading ?? key)}
                py="10px"
              >
                <Text textStyle="label">{heading ?? ""}</Text>
              </Th>
            ))}
          </Tr>
        </Thead>
      )}
      <Tbody>
        {data?.map((rowData, rowKey) => (
          <Tr key={`row-${rowKey}`}>
            {columns.map(({ id }) => (
              <Td
                key={`row-${rowKey}-${id}`}
                bgColor={rowData[id].isError ? "red.50" : undefined}
                py={2}
              >
                {rowData[id].value}
              </Td>
            ))}
          </Tr>
        ))}
      </Tbody>
    </Table>
  </TableContainer>
);

export default ErrorTable;
