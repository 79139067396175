import { ThemeComponents } from "@chakra-ui/react";

import Alert from "./alert";
import Avatar from "./avatar";
import Badge from "./badge";
import Button from "./button";
import Card from "./card";
import Checkbox from "./checkbox";
import Input from "./input";
import Menu from "./menu";
import Modal from "./modal";
import Popover from "./popover";
import RadioButton from "./radioButton";
import Spinner from "./spinner";
import Stat from "./stat";
import Table from "./table";
import Text from "./text";
import Toast from "./toast";
import Tooltip from "./tooltip";

const components: ThemeComponents = {
  Alert,
  Avatar,
  Badge,
  Button,
  Card,
  Checkbox,
  Input,
  Menu,
  Modal,
  Popover,
  RadioButton,
  Spinner,
  Stat,
  Table,
  Text,
  Toast,
  Tooltip,
};

export default components;
