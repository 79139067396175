import { useCallback, useState } from "react";

const useSelectionIds = (
  allIds: string[]
): {
  selectedIds: string[];
  onToggleId: (id?: string) => void;
  clearSelectedIds: () => void;
} => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const onToggleId = useCallback(
    (id?: string /* undefined implies all */) =>
      setSelectedIds((currentSelectedIds) => {
        if (id) {
          const currentRowIndex = currentSelectedIds.indexOf(id);

          return currentRowIndex < 0
            ? [...currentSelectedIds, id]
            : [
                ...currentSelectedIds.slice(0, currentRowIndex),
                ...currentSelectedIds.slice(currentRowIndex + 1),
              ];
        }

        // Select all / unselect all
        return currentSelectedIds.length === allIds.length ? [] : allIds;
      }),
    [allIds]
  );

  const clearSelectedIds = () => setSelectedIds([]);

  return {
    selectedIds,
    onToggleId,
    clearSelectedIds,
  };
};

export default useSelectionIds;
