import NewManualForwardPricingContractForm from "forms/forward-pricing/NewManualForwardPricingContractForm";
import useCropYearDetail from "hooks/crop-year/useCropYearDetails";
import useFarmCropYearSummary from "hooks/crop-year/useFarmCropYearSummary";
import useFarmsRegionDetails from "hooks/farm/useFarmsRegionDetails";
import useSelectedCropYearId from "hooks/useSelectedCropYearId";
import useSelectedFarmId from "hooks/useSelectedFarmId";
import PricingContractPageLayout from "../PricingContractPageLayout/PricingContractPageLayout";

const ManualForwardPricingContractPage = () => {
  const [selectedCropYearId] = useSelectedCropYearId();
  const [selectedFarmId] = useSelectedFarmId();
  const { data: cropYearDetail, isLoading: isCropYearDetailLoading } =
    useCropYearDetail(selectedCropYearId);
  const { regionDetails, isLoading: regionDetailsIsLoading } =
    useFarmsRegionDetails(selectedFarmId);
  const { data: farmCropYearSummary, isLoading: farmCropYearSummaryLoading } =
    useFarmCropYearSummary(selectedCropYearId, selectedFarmId);

  return (
    <PricingContractPageLayout
      contractHeader="Forward Contract"
      isLoading={
        isCropYearDetailLoading ||
        regionDetailsIsLoading ||
        farmCropYearSummaryLoading
      }
    >
      <NewManualForwardPricingContractForm
        regionDetails={regionDetails}
        farmCropYearSummary={farmCropYearSummary}
        cropYearDetail={cropYearDetail}
        cropYearId={selectedCropYearId}
        farmId={selectedFarmId}
      />
    </PricingContractPageLayout>
  );
};

export default ManualForwardPricingContractPage;
