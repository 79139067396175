import userRegistrationPaths from "routes/user-registration/userRegistrationPaths";
import authPaths from "routes/auth/authPaths";

export const userRegistrationErrorStates: Record<string, ErrorState> = {
  EXPIRED_INVITE: {
    match: /Invite expired/gi,
    msg: "Please contact Riverview or your farm administrator to resend.",
    title: "Invitation is no longer valid.",
    linkText: null,
    linkTo: null,
  },
  USER_REGISTERED: {
    match: /User Already Registered/gi,
    msg: "If you didn't already register this email, please contact Riverview.",
    title: "Oops! Looks like your email is already in the system.",
    linkText: "Go to login",
    linkTo: `/${authPaths.basePath}`,
  },
  SERVER_DOWN: {
    match: /[\s\S]*/gi,
    msg: "A server error occurred and we cannot complete your registration. Please try again or contact Riverview.",
    title: "Oops! Looks like the server is down",
    linkText: "Back to Start",
    linkTo: `../${userRegistrationPaths.children.basicUserInfoPath}`,
  },
};
