import { ReactElement } from "react";

import { FieldValues } from "react-hook-form/dist/types/fields";
import { Path, useController, useFormContext } from "react-hook-form";

import Select, { SelectOption, SelectProps } from "components/Select";
import InputWrapper, { InputWrapperProps } from "./InputWrapper";

export type SelectInputProps = {
  id?: string;
  options?: SelectOption[];
  placeholder?: string;
  isLoading?: boolean;
  errorMsg?: string;
  isError?: boolean;
  isRequired?: boolean;
  // Adding partial support for overriding things from the Select for components, getOptionLabel, and noOptionsMessage for now
  reactSelectProps?: Pick<
    SelectProps,
    "components" | "getOptionLabel" | "noOptionsMessage"
  >;
};

const SelectInput = <T extends FieldValues>({
  id,
  options = [],
  placeholder = "",
  isLoading,
  errorMsg,
  isError,
  reactSelectProps,
  ...rest
}: SelectInputProps & InputWrapperProps): ReactElement => {
  const { control } = useFormContext<T>();

  const {
    field,
    fieldState: { error },
  } = useController<T>({
    name: id as Path<T>,
    control: control,
  });

  return (
    <InputWrapper {...rest} isInvalid={!!error} errorMsg={error?.message}>
      <Select
        options={options}
        placeholder={placeholder}
        aria-label={placeholder}
        isLoading={isLoading}
        isError={isError}
        errorMsg={errorMsg}
        {...reactSelectProps}
        {...field}
      />
    </InputWrapper>
  );
};

export default SelectInput;
