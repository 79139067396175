import { useCallback } from "react";
import CatastrophicErrorState from "./CatastrophicErrorState";
import useUploadFarmDocumentsCsv from "hooks/csv-upload/useUploadFarmDocumentsCsv";
import adminPaths from "routes/admin/adminPaths";

const FarmDocumentsCatastrophicErrorState = () => {
  const { mutateAsync, isPending: isLoading } = useUploadFarmDocumentsCsv();

  const onRetryUploadCsvFile = useCallback(
    (file: File) => mutateAsync({ csvFile: file }),
    [mutateAsync]
  );

  return (
    <CatastrophicErrorState
      isLoading={isLoading}
      onRetry={onRetryUploadCsvFile}
      linkText="Back to Farms"
      linkTo={`/${adminPaths.basePath}/${adminPaths.children.farms}`}
    />
  );
};

export default FarmDocumentsCatastrophicErrorState;
