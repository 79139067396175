import { useNavigate } from "react-router-dom";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { getRefreshToken } from "utils/authLocalStorage";
import { useAuthService } from "contexts/ServiceProvider";
import AuthService from "services/AuthService";
import authPaths from "routes/auth/authPaths";
import { MutationHookResult, MutationOnSuccessFn } from "../hookTypeHelpers";
import { clearAuthData } from "utils/authUtils";

type LogoutMutationResult = MutationHookResult<
  Awaited<ReturnType<AuthService["logout"]>>
>;

const useLogoutMutation = (
  refreshToken: string,
  onSuccess: MutationOnSuccessFn<LogoutMutationResult>
): LogoutMutationResult => {
  const authService = useAuthService();

  return useMutation({
    mutationKey: ["logout"],
    mutationFn: () => authService.logout(refreshToken),
    onSuccess,
    onError: (error) => {
      const errorStatus = error?.response?.status ?? 0;
      if (errorStatus >= 400 && errorStatus < 500) {
        // A 4XX error implies we have been logged out by the server, so do cleanup
        onSuccess();
      }
    },
  });
};

const useLogout = (): Omit<LogoutMutationResult, "mutate" | "mutateAsync"> & {
  onLogout: () => void;
  onLogoutAsync: () => Promise<string>;
} => {
  const queryClient = useQueryClient();
  const refreshToken = getRefreshToken();
  const navigate = useNavigate();
  const {
    mutate: onLogout,
    mutateAsync: onLogoutAsync,
    ...rest
  } = useLogoutMutation(refreshToken ?? "", async () => {
    // In v4 this will no longer work as expected and will no longer call the onSuccess of useAuthTokens.
    // https://tanstack.com/query/v4/docs/guides/migrating-to-react-query-4#onsuccess-is-no-longer-called-from-setquerydata
    clearAuthData();
    queryClient.clear();
    navigate(`/${authPaths.basePath}`);
  });

  return {
    onLogout,
    onLogoutAsync,
    ...rest,
  };
};

export default useLogout;
