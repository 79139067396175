import { PropsWithChildren, ReactElement } from "react";

import { Button, ButtonProps } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";

const NavigationItem = ({
  children,
  route,
  ...rest
}: PropsWithChildren<ButtonProps> & {
  route: string;
}): ReactElement => (
  <NavLink to={route}>
    {({ isActive }) => (
      <Button {...rest} variant={isActive ? "activeNav" : "navigation"}>
        {children}
      </Button>
    )}
  </NavLink>
);

export default NavigationItem;
