import { ComponentStyleConfig } from "@chakra-ui/react";

const Table: ComponentStyleConfig = {
  baseStyle: {
    table: {
      border: "1px solid",
      borderColor: "grey.200",
      bg: "white",
    },
    thead: {
      bg: "greyFactor.0",
    },
    th: {
      textStyle: "label",
      fontWeight: "bold",
      py: "9.5px",
    },
    td: {
      textStyle: "caption",
      py: "14.5px",
    },
    tbody: {
      tr: {
        _hover: {
          bgColor: "grayFactor.0",
        },
      },
    },
  },
  variants: {
    simple: {
      th: {
        border: "none",
        py: "9.5px",
      },
      td: {
        border: "none",
        py: "14.5px",
      },
      tr: {
        borderTop: "1px solid",
        borderColor: "greyFactor.2",
      },
      thead: {
        tr: { borderTop: "none" },
        border: "1px solid",
        borderColor: "greyFactor.2",
      },
    },
  },
};

export default Table;
