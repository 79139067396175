import { ReactElement, useEffect, useMemo, useState } from "react";
import { Link as RLink } from "react-router-dom";

import { Button, Center, HStack, Show, Spinner, Stack } from "@chakra-ui/react";

import FetchMoreWhenInView from "components/FetchMoreWhenInView";
import { TABLE_MARGIN_TOP } from "components/SortableTable";
import { CSV_UPLOAD } from "constants/featureFlags";
import { useCropYearContext } from "contexts/CropYearContext";
import useAllFieldsList from "hooks/field/useAllFieldsList";
import { useHasFeatureFlag } from "hooks/useFeatureFlags";
import useUserRoleFlags from "hooks/auth/useUserRoleFlags";
import useSort, { DEFAULT_SORT_DIRECTION, Sort } from "hooks/useSort";
import SilageDashboardTabLayout from "layouts/SilageDashboardTabLayout";
import DashboardListTable from "pages/dashboard/components/DashboardListTable";
import fieldPaths from "routes/field/fieldPaths";
import csvPaths from "routes/csv-uploads/csvPaths";
import { FieldsListSortColumns } from "services/FieldService";
import { mobileStyleBreakpoint } from "utils/styleHelpers";
import {
  baseFieldsListColumns,
  desktopRVAllFieldsListColumnsPreHarvest,
  allFieldsListTableColumns,
  desktopRVAllFieldsListColumnsPostHarvest,
} from "./AllFieldsListTable";

const defaultSort: Sort<FieldsListSortColumns> = {
  key: "fieldNumber",
  direction: DEFAULT_SORT_DIRECTION,
};

const AllFieldsListTab = (): ReactElement => {
  const {
    cropYear,
    isPreHarvest,
    isLoading: cropYearIsLoading,
  } = useCropYearContext();
  const [searchTerm, setSearchTerm] = useState("");
  const { sort, toggleSort, setSort } =
    useSort<FieldsListSortColumns>(defaultSort);

  const hasFieldsCsvUploadFeature = useHasFeatureFlag(CSV_UPLOAD.FIELDS);
  const hasActualTonsUploadFeature = useHasFeatureFlag(CSV_UPLOAD.ACTUAL_TONS);
  const { isEmployee, isEmployeeAdmin } = useUserRoleFlags();

  const adminFarmsListTableColumns = mobileStyleBreakpoint(
    baseFieldsListColumns,
    isPreHarvest
      ? desktopRVAllFieldsListColumnsPreHarvest
      : desktopRVAllFieldsListColumnsPostHarvest,
    "md"
  );

  const showUploadFieldCsvButton =
    hasFieldsCsvUploadFeature && isEmployee && isPreHarvest;

  const showUploadActualTonsCsvButton =
    hasActualTonsUploadFeature && isEmployeeAdmin && isPreHarvest;

  const {
    data,
    isLoading: fieldListIsLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useAllFieldsList({
    cropYearId: cropYear?.id,
    queryParams: {
      search: searchTerm,
      sort: [sort],
    },
  });

  const tableData = useMemo(
    () =>
      allFieldsListTableColumns({
        data: data?.pages?.map(({ fields }) => fields).flat() ?? [],
        isPreHarvest,
        isEmployee,
      }),
    [data?.pages, isEmployee, isPreHarvest]
  );

  useEffect(() => {
    setSort(defaultSort);
  }, [setSort, searchTerm]);

  return (
    <SilageDashboardTabLayout
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      heading="All Fields"
      addButton={
        <HStack spacing={4}>
          {showUploadActualTonsCsvButton ? (
            <Show above="md">
              <Button
                size="md"
                variant="outline"
                minW="unset"
                as={RLink}
                to={`/${csvPaths.basePath}/${csvPaths.children.actualTons.basePath}`}
              >
                Upload Actual Tons
              </Button>
            </Show>
          ) : undefined}
          {showUploadFieldCsvButton ? (
            <Show above="md">
              <Button
                size="md"
                minW="unset"
                as={RLink}
                to={`/${csvPaths.basePath}/${csvPaths.children.fields.basePath}`}
              >
                Upload Field CSV
              </Button>
            </Show>
          ) : undefined}
        </HStack>
      }
    >
      {fieldListIsLoading || cropYearIsLoading ? (
        <Center h="100%">
          <Spinner />
        </Center>
      ) : (
        <Stack spacing={TABLE_MARGIN_TOP} w="100%">
          <DashboardListTable
            data={tableData}
            sort={sort}
            toggleSort={toggleSort}
            columns={adminFarmsListTableColumns}
            emptyTableMsg={
              searchTerm.length === 0
                ? "No fields added yet."
                : "No Results Found"
            }
            routeToBasePath={`${fieldPaths.basePath}`}
            onClickRow={() => {
              return;
            }}
          />
          {tableData.length > 0 && (
            <FetchMoreWhenInView
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
              key={`${data?.pages.length}`}
            />
          )}
        </Stack>
      )}
    </SilageDashboardTabLayout>
  );
};

export default AllFieldsListTab;
