export const csvChildPaths = {
  fileSelect: "file-select",
  progress: "progress",
  results: "results",
  resultsNoErrors: "finished",
  failed: "failed",
};

const csvPaths = {
  basePath: "csv",
  children: {
    error: "error",
    fields: {
      basePath: "fields",
      children: csvChildPaths,
    },
    actualTons: {
      basePath: "actual-tons",
      children: csvChildPaths,
    },
    farmDocuments: {
      basePath: "farm-documents",
      children: {
        fileSelect: csvChildPaths.fileSelect,
        progress: csvChildPaths.progress,
        failed: csvChildPaths.failed,
      },
    },
  },
};

export default csvPaths;
