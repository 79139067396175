import { FormControl, InputProps } from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import InputLabel, { InputLabelProps } from "./InputLabel";
import InputStatusMessage, {
  InputStatusMessageProps,
} from "./InputStatusMessage";

export type InputWrapperProps = Pick<
  InputProps,
  "variant" | "isDisabled" | "isRequired" | "isInvalid"
> &
  InputStatusMessageProps &
  Pick<InputLabelProps, "label">;
const InputWrapper = ({
  label,
  variant,
  isDisabled,
  isRequired,
  isSuccess,
  isInvalid,
  successMsg,
  hasInfo,
  infoMsg,
  errorMsg,
  children,
}: PropsWithChildren<InputWrapperProps>) => (
  <FormControl isInvalid={isInvalid} isDisabled={isDisabled} variant={variant}>
    <InputLabel label={label} isRequired={isRequired} />
    {children}
    <InputStatusMessage
      isSuccess={isSuccess}
      successMsg={successMsg}
      errorMsg={errorMsg}
      hasInfo={hasInfo}
      infoMsg={infoMsg}
    />
  </FormControl>
);

export default InputWrapper;
