import { ReactElement, ReactNode } from "react";

import { Center, Spinner } from "@chakra-ui/react";

import useAccessToken from "hooks/auth/useAccessToken";
import RedirectToDashboard from "components/redirects/RedirectToDashboard";

const UnprotectedRoute = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const { accessToken, isLoading } = useAccessToken();

  if (isLoading) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  if (accessToken) {
    return <RedirectToDashboard />;
  }

  return <>{children}</>;
};

export default UnprotectedRoute;
