import { useQuery } from "@tanstack/react-query";
import { useFarmService } from "contexts/ServiceProvider";
import { FarmDetailResponse } from "api";
import { BaseApiError } from "services/apiHelpers";

export const GET_FARM_INFO_QUERY_KEY = "get-farm-detail";

const useFarmDetail = (id?: string) => {
  const farmService = useFarmService();

  return useQuery<FarmDetailResponse | undefined, BaseApiError>({
    queryKey: [GET_FARM_INFO_QUERY_KEY, { id }],
    queryFn: () =>
      id ? farmService.getFarmDetail({ id }) : Promise.resolve(undefined),
    enabled: !!id,
    retry: 0,
  });
};

export default useFarmDetail;
