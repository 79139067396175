import { DocumentIcon, DollarIcon, FarmsLightIcon, FieldIcon } from "icons";
import { Tab } from "@chakra-ui/react";
import { PropsWithChildren } from "react";

const PricingIcon = (
  <DollarIcon aria-hidden boxSize={"18px"} color={"inherit"} />
);

const FieldsIcon = (
  <FieldIcon aria-hidden boxSize={"18px"} color={"inherit"} mr={1} />
);

const FarmLightIcon = (
  <FarmsLightIcon aria-hidden boxSize={"18px"} color={"inherit"} mr={1} />
);

const DocumentsIcon = (
  <DocumentIcon aria-hidden boxSize={"18px"} color={"inherit"} mr={1} />
);

const StyledTab = ({
  children,
  isDisabled,
}: { isDisabled?: boolean } & PropsWithChildren) => (
  <Tab fontWeight="bold" isDisabled={isDisabled} w="194px">
    {children}
  </Tab>
);

export const PricingTab = () => (
  <StyledTab>
    {PricingIcon}
    Pricing
  </StyledTab>
);

export const AllPricingTab = () => (
  <StyledTab>
    {PricingIcon}
    All Pricing
  </StyledTab>
);

export const FieldsTab = () => (
  <StyledTab>
    {FieldsIcon}
    Fields
  </StyledTab>
);

export const AllFieldsTab = () => (
  <StyledTab>
    {FieldsIcon}
    All Fields
  </StyledTab>
);

export const AllFarmsTab = () => (
  <StyledTab>
    {FarmLightIcon}
    All Farms
  </StyledTab>
);

export const DocumentsTab = () => (
  <StyledTab>
    {DocumentsIcon}
    Documents
  </StyledTab>
);
