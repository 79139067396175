import {
  Flex,
  FlexProps,
  HStack,
  Icon,
  IconButton,
  IconButtonProps,
  IconProps,
  StylesProvider,
  Text,
  TextProps,
  ThemingProps,
  VStack,
  useMultiStyleConfig,
  useStyles,
} from "@chakra-ui/react";
import { PropsWithChildren, ReactElement } from "react";
import { IconType } from "react-icons";
import { IoClose } from "react-icons/io5";

export type ToastProps = ThemingProps & FlexProps;

export type ToastContentProps = {
  message: string;
  title?: string;
};

export const ToastCloseButton = (props: IconButtonProps): ReactElement => {
  const styles = useStyles();

  return (
    <IconButton
      size="xs"
      icon={<IoClose size="16px" />}
      sx={styles.close}
      {...props}
    />
  );
};

export const ToastTitle = ({
  children,
  ...props
}: PropsWithChildren<TextProps>): ReactElement => {
  const styles = useStyles();

  return (
    <Text sx={styles.title} {...props}>
      {children}
    </Text>
  );
};

export const ToastMessage = ({
  children,
  ...props
}: PropsWithChildren<TextProps>): ReactElement => {
  const styles = useStyles();

  return (
    <Text sx={styles.message} {...props}>
      {children}
    </Text>
  );
};

export const ToastIcon = ({
  icon,
  ...props
}: IconProps & { icon: IconType }): ReactElement => {
  const styles = useStyles();

  return <Icon as={icon} __css={styles.icon} boxSize={5} {...props} />;
};

export const ToastContent = ({
  message,
  title,
  icon,
}: ToastContentProps & { icon: IconType }) => {
  return title ? (
    <VStack align="left" mr={8}>
      <HStack>
        <ToastIcon icon={icon} mr={0} />
        <ToastTitle>{title}</ToastTitle>
      </HStack>
      <ToastMessage pl={7}>{message}</ToastMessage>
    </VStack>
  ) : (
    <>
      <ToastIcon icon={icon} />
      <ToastTitle>{message}</ToastTitle>
    </>
  );
};

const Toast = ({
  variant,
  children,
  ...rest
}: PropsWithChildren<ToastProps>): ReactElement => {
  const styles = useMultiStyleConfig("Toast", { variant });

  return (
    <Flex __css={styles.toast} {...rest}>
      <StylesProvider value={styles}>{children}</StylesProvider>
    </Flex>
  );
};

export default Toast;
