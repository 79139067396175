import { useCallback, useMemo } from "react";

import AddFarmsToHaylageYearButton from "./AddFarmsToHaylageYearButton";
import {
  AddableFarmsSortColumns,
  addFarmsTableColumns,
  baseSelectFarmsListColumns,
  desktopFarmsListColumns,
} from "components/AddFarmsToYear/addFarmsTableColumns";
import AddFarmsPageLayout from "components/AddFarmsToYear/AddFarmsToYearPageLayout";
import useGetAddableFarmsForHaylageYear from "hooks/api/haylage-year/useGetAddableFarmsForHaylageYear";
import useGetHaylageYear from "hooks/api/haylage-year/useGetHaylageYear";
import useSelectedHaylageYearId from "hooks/useSelectedHaylageYearId";
import useNavigateWithQuery from "hooks/useNavigateWithQuery";
import useSelectionIds from "hooks/useSelectionIds";
import useSort, {
  addSecondarySort,
  ColumnsWithSecondarySort,
  DEFAULT_SORT_DIRECTION,
  Sort,
} from "hooks/useSort";
import haylagePaths from "routes/haylage";
import { mobileStyleBreakpoint } from "utils/styleHelpers";

const selectFarmsListTableColumns = mobileStyleBreakpoint(
  baseSelectFarmsListColumns,
  desktopFarmsListColumns,
  "md"
);

const columnsWithSecondarySort: ColumnsWithSecondarySort<AddableFarmsSortColumns> =
  {
    "region.name": {
      key: "doingBusinessAs",
      direction: DEFAULT_SORT_DIRECTION,
    },
  };

const defaultSort: Sort<AddableFarmsSortColumns> = {
  key: "region.name",
  direction: DEFAULT_SORT_DIRECTION,
};

const AddHaylageFarmsPage = () => {
  const [selectedHaylageYearId] = useSelectedHaylageYearId();
  const { data: haylageYearData, isLoading: isHaylageYearDataLoading } =
    useGetHaylageYear(selectedHaylageYearId);
  const useSortProps = useSort<AddableFarmsSortColumns>(defaultSort);
  const { data, isLoading } = useGetAddableFarmsForHaylageYear(
    selectedHaylageYearId,
    {
      sort: addSecondarySort(useSortProps.sort, columnsWithSecondarySort),
    }
  );

  const navigate = useNavigateWithQuery();
  const navigateToHaylageAllFarmsList = useCallback(
    () =>
      navigate(`/${haylagePaths.basePath}/${haylagePaths.children.allFarms}`),
    [navigate]
  );

  const tableData = useMemo(
    () => addFarmsTableColumns(data?.flatMap((response) => response) ?? []),
    [data]
  );
  const { selectedIds, onToggleId, clearSelectedIds } = useSelectionIds(
    tableData.map((field: Record<string, any>) => field.id)
  );

  return (
    <AddFarmsPageLayout
      defaultSort={defaultSort}
      programType="Haylage"
      year={haylageYearData?.year}
      navigateToAllFarmsList={navigateToHaylageAllFarmsList}
      selectedIds={selectedIds}
      isLoading={isLoading || isHaylageYearDataLoading}
      tableData={tableData}
      onToggleId={onToggleId}
      selectFarmsListTableColumns={selectFarmsListTableColumns}
      {...useSortProps}
    >
      <AddFarmsToHaylageYearButton
        year={haylageYearData?.year}
        farmIds={selectedIds}
        isDisabled={selectedIds?.length === 0}
        onSuccess={clearSelectedIds}
      />
    </AddFarmsPageLayout>
  );
};

export default AddHaylageFarmsPage;
