import { useMutation, useQueryClient } from "@tanstack/react-query";

import { UpdateFarmRequest } from "api";
import { useFarmService } from "contexts/ServiceProvider";
import { MutationHook, MutationHookResult } from "hooks/hookTypeHelpers";
import { GET_FARMS_QUERY_KEY } from "hooks/farm/useFarmsList";
import FarmService from "services/FarmService";
import { GET_FARM_INFO_QUERY_KEY } from "./useFarmDetail";

type EditFarmMutationResult = MutationHookResult<
  Awaited<ReturnType<FarmService["updateFarm"]>>,
  UpdateFarmRequest
>;

const useEditFarmMutation = (
  farmId?: string,
  setFarmIdHeader = true
): EditFarmMutationResult => {
  const farmService = useFarmService();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["edit-farm", { id: farmId }],
    mutationFn: (farmData) => {
      const allRequired = Object.values(farmData).every((value) => !!value);
      if (!allRequired) {
        throw new Error("Missing farm info");
      }

      return farmId
        ? farmService.updateFarm(farmId, farmData, setFarmIdHeader)
        : Promise.reject("Cannot edit a farm without an ID");
    },
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: [GET_FARMS_QUERY_KEY],
      });
      await queryClient.refetchQueries({
        queryKey: [GET_FARM_INFO_QUERY_KEY, { id: farmId }],
      });
    },
  });
};

const useEditFarm = (
  farmId?: string,
  setFarmIdHeader = true
): MutationHook<EditFarmMutationResult, "onEdit"> => {
  const {
    mutate: onEdit,
    mutateAsync: onEditAsync,
    ...rest
  } = useEditFarmMutation(farmId, setFarmIdHeader);

  return { onEdit, onEditAsync, ...rest };
};

export default useEditFarm;
