import { ReactElement, ReactNode } from "react";
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";

import { BaseApiError } from "services/apiHelpers";
import { clearAuthData } from "utils/authUtils";

const invalidAPIResponse = (status?: number) => {
  return status && status === 401;
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 300000, // 5 minutes to match cache time
      retry: 1,
    },
  },
  queryCache: new QueryCache({
    onError: async function (error) {
      const { response } = error as BaseApiError;
      if (invalidAPIResponse(response?.status)) {
        // Invalidate auth data
        clearAuthData(true);
        window.location.reload();
      }
    },
  }),
});

const ReactQueryProvider = ({
  children,
  queryClient: _queryClient,
}: {
  children: ReactNode;
  queryClient?: QueryClient;
}): ReactElement => (
  <QueryClientProvider client={_queryClient ?? queryClient}>
    {children}
  </QueryClientProvider>
);

export default ReactQueryProvider;
