import { useMemo } from "react";

import {
  Badge,
  Button,
  Flex,
  useDisclosure,
  Wrap,
  WrapItem,
  Text,
  Center,
  ButtonProps,
  Tooltip,
} from "@chakra-ui/react";

import { FieldResponse } from "api";
import SummaryStatCard, {
  percentOfTonsPriced as percentOf,
} from "components/SummaryStatCard";
import { totalAcres } from "components/SummaryStatCard/summaryStats";
import useUserRoleFlags from "hooks/auth/useUserRoleFlags";
import useInfoAlert from "hooks/alerts/useInfoAlert";
import useGetFieldAddableFarms from "hooks/field/useGetFieldAddableFarms";
import useSelectedFarmId from "hooks/useSelectedFarmId";
import { mobileStyleBreakpoint } from "utils/styleHelpers";
import SplitsDialog from "../SplitsDialog/SplitsDialog";

type SummaryCardProps = {
  field: FieldResponse;
  isDisabled?: boolean;
};

const SPLIT_BUTTON_WIDTH = mobileStyleBreakpoint("100%", "unset");
const SPLIT_BADGE_WIDTH = mobileStyleBreakpoint("124px", "154px");
const SPLIT_WRAP_WIDTH = mobileStyleBreakpoint("124px", "unset");

const SUMMARY_CARD_MIN_WIDTH = mobileStyleBreakpoint("310px", "700px");

const SplitsSummary = ({ field, isDisabled = false }: SummaryCardProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isEmployee } = useUserRoleFlags();
  const [farmId] = useSelectedFarmId();
  const {
    alert: errorMessageAlert,
    isOpen: isErrorMessageAlertOpen,
    onOpen: onOpenErrorMessageModal,
  } = useInfoAlert({
    title: "Unable to Update Field Information",
  });

  const otherFieldSplits = useMemo(
    () => field?.splits.filter((split) => split.farmId !== farmId) ?? [],
    [farmId, field]
  );
  const currentFarmsSplit = useMemo(
    () => field?.splits.find((split) => split.farmId === farmId),
    [farmId, field?.splits]
  );

  const splitButtonVisible = isEmployee && !!field;

  const { data: allFarms, isLoading: isAddableFarmsLoading } =
    useGetFieldAddableFarms(field?.id, farmId);

  const SPLIT_BUTTON_PROPS: ButtonProps = {
    size: "md",
    minW: "103px",
    onClick: onOpen,
    w: SPLIT_BUTTON_WIDTH,
    isDisabled: isAddableFarmsLoading || isDisabled,
    isLoading: isAddableFarmsLoading,
  };

  const isFullFieldTransfer = otherFieldSplits?.[0]?.split === 100;

  // Conditionally render the dialog to prevent unsaved form state from sticking around
  return (
    <>
      {isErrorMessageAlertOpen && errorMessageAlert}
      {isOpen && (
        <SplitsDialog
          field={field}
          isOpen={isOpen}
          onClose={onClose}
          allFarms={allFarms}
          onOpenErrorMessageModal={onOpenErrorMessageModal}
        />
      )}
      {otherFieldSplits.length && !isFullFieldTransfer ? (
        <Flex gap={6} overflow="auto" pb={3} px={2}>
          <SummaryStatCard
            value={currentFarmsSplit?.split ?? 0}
            {...percentOf}
            label="Field split"
            minW={SUMMARY_CARD_MIN_WIDTH}
            extraStatContent={
              <Center>
                <Wrap w={SPLIT_WRAP_WIDTH} maxW={SPLIT_WRAP_WIDTH}>
                  {otherFieldSplits.map((split) => (
                    <WrapItem key={split.farmId}>
                      <Tooltip
                        hasArrow
                        label={`${split.split}% ${split.doingBusinessAs}`}
                      >
                        <Badge
                          w={SPLIT_BADGE_WIDTH}
                          maxW={SPLIT_BADGE_WIDTH}
                          cursor={"default"}
                        >
                          <Text
                            overflow="hidden"
                            color="inherit"
                            textOverflow="ellipsis"
                          >
                            {`${split.split}% ${split.doingBusinessAs}`}
                          </Text>
                        </Badge>
                      </Tooltip>
                    </WrapItem>
                  ))}
                </Wrap>
              </Center>
            }
            button={
              splitButtonVisible ? (
                <Button {...SPLIT_BUTTON_PROPS}>Edit Split</Button>
              ) : undefined
            }
          />
          <SummaryStatCard
            value={currentFarmsSplit?.acres ?? 0}
            {...totalAcres}
            label="Total acres after split"
            minW={SUMMARY_CARD_MIN_WIDTH}
          />
        </Flex>
      ) : (
        <SummaryStatCard
          value={field?.totalAcres ?? 0}
          {...totalAcres}
          minW={SUMMARY_CARD_MIN_WIDTH}
          button={
            splitButtonVisible ? (
              <Button {...SPLIT_BUTTON_PROPS}>Add Split</Button>
            ) : undefined
          }
        />
      )}
    </>
  );
};

export default SplitsSummary;
