import { ReactElement } from "react";

import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Stack } from "@chakra-ui/react";

import { FormInputs, StandardInput } from "components/inputs";
import SubmitButton from "components/SubmitButton";
import { useForgotPasswordForm } from "contexts/ForgotPasswordFormContext";
import useForgotPassword from "hooks/auth/forgot-password/useForgotPassword";
import useNavigateToErrorPage from "hooks/useNavigateToErrorPage";
import { extractResponseErrorMessage } from "services/apiHelpers";
import { forgotPasswordErrorStates } from "pages/forgot-password/forgotPasswordErrorStates";
import forgotPasswordPaths from "routes/forgot-password/forgotPasswordPaths";

const buttonIsDisabled = (email: string | undefined): boolean => {
  return !email || email.length === 0;
};

const emailFormSchema = yup
  .object({
    email: yup
      .string()
      .email("Must be a valid email address")
      .required("Email is required"),
  })
  .required();

const ForgotPasswordForm = (): ReactElement => {
  const methods = useForm<{ email: string }>({
    resolver: yupResolver(emailFormSchema),
  });

  const { setEmail } = useForgotPasswordForm();

  const { onSubmit } = useForgotPassword();

  const navigateToErrorPage = useNavigateToErrorPage(
    forgotPasswordErrorStates,
    forgotPasswordPaths.children.error
  );

  return (
    <FormProvider {...methods}>
      <Stack
        as="form"
        w="100%"
        onSubmit={methods.handleSubmit((data) =>
          onSubmit(data.email, {
            onSuccess: () => setEmail(data.email),
            onError: (error) => {
              const errorMsg = extractResponseErrorMessage(error?.response);
              navigateToErrorPage(errorMsg);
            },
          })
        )}
      >
        <FormInputs
          inputs={[
            {
              id: "email",
              label: "Email",
              component: StandardInput,
              "data-testid": "email-input",
            },
          ]}
        />
        <SubmitButton
          w="100%"
          size="lg"
          submitDisabled={buttonIsDisabled(methods.watch().email)}
          isSubmitting={methods.formState.isSubmitting}
          buttonText="Next"
        />
      </Stack>
    </FormProvider>
  );
};

export default ForgotPasswordForm;
