import { AccessTokenResponse, AuthApi, MeResponse } from "api";
import { constructApiControllerInstance } from "./customApiConfig";
import { extractResponseData, processResponse } from "./apiHelpers";

class AuthService {
  authController!: AuthApi;
  constructor() {
    this.authController = constructApiControllerInstance(AuthApi);
  }
  login(username: string, password: string): Promise<AccessTokenResponse> {
    return this.authController
      .login({
        loginRequest: { email: username, password },
      })
      .then(extractResponseData);
  }

  // refreshToken is required by keycloak
  logout(refreshToken: string): Promise<string> {
    return this.authController
      .logout({
        logoutRequest: { refreshToken },
      })
      .then(processResponse);
  }
  refreshToken(refreshToken: string): Promise<AccessTokenResponse> {
    return this.authController
      .refreshToken({
        refreshTokenRequest: { refreshToken },
      })
      .then(extractResponseData);
  }

  forgotPassword(email: string): Promise<string> {
    return this.authController
      .forgotPassword({
        forgotPasswordRequest: { email },
      })
      .then(processResponse);
  }

  resetPassword(
    password: string,
    confirmPassword: string,
    token: string
  ): Promise<string> {
    return this.authController
      .resetPassword({
        resetPasswordRequest: { newPassword: password, confirmPassword, token },
      })
      .then(processResponse);
  }

  userInfo(): Promise<MeResponse> {
    return this.authController.me().then(extractResponseData);
  }

  validateResetPasswordToken(token: string): Promise<string> {
    return this.authController
      .validatePasswordResetToken({
        token,
      })
      .then(processResponse);
  }
}

export default AuthService;
