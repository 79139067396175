import { isUSDollarFormat } from "utils/stringUtils";
import { ValidationError, CreateErrorOptions } from "yup";

export const isUSDFormat = (
  value: number | undefined,
  inputLabel: string,
  errCallback: (options: CreateErrorOptions) => ValidationError
) => {
  if (value && !isUSDollarFormat(value)) {
    return errCallback({
      message: `${inputLabel} must not have more than 2 digits after the decimal point`,
    });
  }

  return true;
};
