import { ComponentStyleConfig } from "@chakra-ui/react";

export const DARK_MODE_TEXT_COLOR = "white";
export const LIGHT_MODE_TEXT_COLOR = "gray.900";

const Text: ComponentStyleConfig = {
  baseStyle: ({ colorMode }) => ({
    color: colorMode === "dark" ? DARK_MODE_TEXT_COLOR : LIGHT_MODE_TEXT_COLOR,
  }),
  defaultProps: {
    textStyle: "body",
  },
  variants: {
    truncated: {
      maxWidth: "25ch",
      noOfLines: 1,
      display: "inline-block",
      textOverflow: "ellipsis",
    },
  },
};

export default Text;
