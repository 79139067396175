import { Outlet, useParams } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import farmPaths from "routes/farm/farmPaths";
import useUserRoleFlags from "hooks/auth/useUserRoleFlags";
import SubmitButton from "components/SubmitButton";
import FormButtonGroup from "components/FormButtonGroup";
import { FormStack } from "layouts/FormLayout";
import useFarmMutations from "./components/useFarmMutations";
import FarmDataWrapper from "./components/FarmDataWrapper";
import { FarmDetailFields, verifyAllRequiredFields } from "./FarmFormContext";
import useFarmDetail from "hooks/farm/farm-detail/useFarmDetail";
import FarmInfoTabGroup, {
  useFarmInfoTabs,
} from "./components/FarmInfoTabGroup";
import FarmInfoPageHeader from "./components/FarmInfoPageHeader";
import { isFarmPending } from "utils/farmStatusUtils";
import { UserStatusDtoValueEnum } from "api";

export const EditFarmForm = () => {
  const { id: farmId } = useParams();
  const { isFarmer } = useUserRoleFlags();
  const { data: farmData } = useFarmDetail(farmId);
  const {
    handleSubmit,
    watch,
    formState: { isSubmitting, isDirty },
  } = useFormContext<FarmDetailFields>();
  const { onSave, alert, onCancel } = useFarmMutations({ farmId });
  const isOnDocumentsTab = location.href.includes(farmPaths.children.documents);
  const requiredFieldsNotFilled = verifyAllRequiredFields(watch(), isFarmer);
  const { tabIndex, handleTabsChange } = useFarmInfoTabs(farmId);
  const isSelectedFarmPending =
    farmData && isFarmPending(farmData.status.value);

  return (
    <>
      <FormStack onSubmit={handleSubmit(onSave)}>
        <FarmInfoPageHeader
          farmName={farmData?.doingBusinessAs ?? ""}
          displayStatusBadge={isFarmer && !isSelectedFarmPending}
          status={UserStatusDtoValueEnum.Active}
        >
          <FormButtonGroup>
            <Button size="md" variant="outline" onClick={onCancel}>
              Cancel
            </Button>
            <SubmitButton
              submitDisabled={
                !requiredFieldsNotFilled || !isDirty || isOnDocumentsTab
              }
              isSubmitting={isSubmitting}
              buttonText="Save"
            />
          </FormButtonGroup>
        </FarmInfoPageHeader>
        <FarmInfoTabGroup
          tabIndex={tabIndex}
          handleTabsChange={handleTabsChange}
        />
        <Outlet />
      </FormStack>
      {alert}
    </>
  );
};

const EditFarmPage = () => (
  <FarmDataWrapper>
    <EditFarmForm />
  </FarmDataWrapper>
);

export default EditFarmPage;
