/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AveragePricingDto } from "./average-pricing-dto";

/**
 *
 * @export
 * @interface MonthlyAverageResponseDto
 */
export interface MonthlyAverageResponseDto {
  /**
   *
   * @type {string}
   * @memberof MonthlyAverageResponseDto
   */
  month: MonthlyAverageResponseDtoMonthEnum;
  /**
   *
   * @type {Array<AveragePricingDto>}
   * @memberof MonthlyAverageResponseDto
   */
  regions: Array<AveragePricingDto>;
}

/**
 * @export
 * @enum {string}
 */
export enum MonthlyAverageResponseDtoMonthEnum {
  January = "JANUARY",
  February = "FEBRUARY",
  March = "MARCH",
  April = "APRIL",
  May = "MAY",
  June = "JUNE",
  July = "JULY",
  August = "AUGUST",
  September = "SEPTEMBER",
  October = "OCTOBER",
  November = "NOVEMBER",
  December = "DECEMBER",
}
