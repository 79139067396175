import * as yup from "yup";
import {
  organizationStateSchema,
  stateSelectSchema,
} from "constants/stateSelectOptions";
import {
  selectOptionSchema,
  phoneNumberSchema,
  zipCodeSchema,
} from "forms/schemas";

export const farmDetailFormSchema = yup
  .object({
    doingBusinessAs: yup
      .string()
      .max(46, "Doing Business As must be 46 characters or less")
      .required("Doing Business As is required"),
    mainContact: selectOptionSchema,
    phoneNumber: phoneNumberSchema,
    streetAddress: yup
      .string()
      .max(46, "Street Address must be 46 characters or less")
      .required("Street Address is required"),
    city: yup
      .string()
      .max(46, "City must be 46 characters or less")
      .required("City is required"),
    state: stateSelectSchema,
    zip: zipCodeSchema,
    email: yup
      .string()
      .email("Must be a valid email address")
      .required("Email is required"),
    orgState: organizationStateSchema,
    lienHolders: yup.array(
      yup.object({
        value: yup
          .string()
          .max(46, "Lien Holder Name must be 46 characters or less")
          .required(
            'Lien Holder Name is required, (type "none" if there are none)'
          ),
      })
    ),
  })
  .required();

export const employeeFarmDetailFormSchema = farmDetailFormSchema.shape({
  region: selectOptionSchema,
});
