import { ReactElement } from "react";
import { Button, ButtonProps } from "@chakra-ui/react";

const SubmitButton = ({
  submitDisabled,
  isSubmitting,
  buttonText,
  ...rest
}: {
  submitDisabled: boolean;
  isSubmitting: boolean;
  buttonText: string;
} & ButtonProps): ReactElement => (
  <Button
    size="md"
    isDisabled={submitDisabled || isSubmitting}
    isLoading={isSubmitting}
    type="submit"
    {...rest}
  >
    {buttonText}
  </Button>
);

export default SubmitButton;
