import { ChangeEvent, useCallback, useState } from "react";

import { Button, Stack, Text } from "@chakra-ui/react";

import { extractResponseErrorMessage } from "services/apiHelpers";
import { MAX_IMAGE_FILE_SIZE_MB } from "constants/registrationForm";
import { useRegistrationForm } from "contexts/RegistrationFormContext";
import { CardBody, CardHeader } from "components/Card";
import ImageUploadBox from "components/ImageUploadBox";
import ProfilePictureCard, {
  ProfilePictureCardFooter,
} from "./ProfilePictureCard";
import useUploadRegistrationProfilePicture from "hooks/profile-picture/useUploadRegistrationProfilePicture";

const acceptedImageTypes = ["png", "jpg", "jpeg"];
const MAX_IMAGE_FILE_SIZE_BYTES = MAX_IMAGE_FILE_SIZE_MB * 1024 * 1024;

const UploadProfilePictureCard = ({
  onCancelUpload,
  onConfirmUpload,
}: {
  onCancelUpload: () => void;
  onConfirmUpload: () => void;
}) => {
  const { profileImage, onSetProfileImage, imagePreviewUrl, inviteToken } =
    useRegistrationForm();

  const [uploadError, setUploadError] = useState<string>();

  const onUploadFile = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];

      if (file) {
        if (file.size > MAX_IMAGE_FILE_SIZE_BYTES) {
          setUploadError(
            `Image size too large. Maximum size ${MAX_IMAGE_FILE_SIZE_MB} MB.`
          );
        } else if (
          file.type.indexOf("image") !== 0 ||
          acceptedImageTypes.indexOf(
            file.type.slice(file.type.indexOf("/") + 1)
          ) < 0
        ) {
          setUploadError("Incorrect file type. Please upload a .jpg or .png.");
        } else {
          setUploadError(undefined);
          onSetProfileImage(file);
        }
      }
    },
    [onSetProfileImage]
  );

  const { onUploadAsync } = useUploadRegistrationProfilePicture(inviteToken);

  const onUploadImage = useCallback(() => {
    if (profileImage) {
      onUploadAsync({ file: profileImage })
        .then(onConfirmUpload)
        .catch((error) => {
          if (error?.code && error.code === "ERR_NETWORK") {
            setUploadError("Failed to save profile picture");

            return;
          }
          setUploadError(extractResponseErrorMessage(error?.response));
        });
    }
  }, [onUploadAsync, profileImage, onConfirmUpload]);

  return (
    <ProfilePictureCard>
      <CardHeader>Upload Image</CardHeader>
      <CardBody>
        <Stack spacing={4} w="100%" maxW="390px">
          {uploadError && <Text color="errorRed">{uploadError}</Text>}
          <ImageUploadBox
            previewUrl={imagePreviewUrl}
            onChange={onUploadFile}
          />
        </Stack>
      </CardBody>
      <ProfilePictureCardFooter>
        {profileImage && (
          <Button variant="primary" onClick={onUploadImage}>
            Save
          </Button>
        )}
        <Button variant="outline" onClick={onCancelUpload} mt={11}>
          Cancel
        </Button>
      </ProfilePictureCardFooter>
    </ProfilePictureCard>
  );
};

export default UploadProfilePictureCard;
