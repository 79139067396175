import { Outlet } from "react-router-dom";

import { HStack, Stack } from "@chakra-ui/react";

import { DesktopOnly } from "components/BreakpointVisibility";
import ContentWrapper from "components/ContentWrapper";
import SiteHeader from "components/SiteHeader";
import NavigationContainer from "components/navigation/NavigationContainer";
import { CropYearContextProvider } from "contexts/CropYearContext";

const SilageDashboardLayout = () => (
  <CropYearContextProvider>
    <Stack h="100vh" overflow="hidden" spacing={0}>
      <SiteHeader />
      <HStack flex={1} spacing={0} align="stretch">
        <DesktopOnly>
          <NavigationContainer />
        </DesktopOnly>
        <ContentWrapper minW="100%" w="unset" maxW="unset" pt={0}>
          <Outlet />
        </ContentWrapper>
      </HStack>
    </Stack>
  </CropYearContextProvider>
);

export default SilageDashboardLayout;
