import { useQuery } from "@tanstack/react-query";

import { useAuthService } from "contexts/ServiceProvider";
import AuthService from "services/AuthService";
import { BaseApiError } from "services/apiHelpers";

export const VALIDATE_RESET_PASSWORD_QUERY_KEY = "validate-reset-password";

type ValidateResetPasswordTokenResponse = Awaited<
  ReturnType<AuthService["validateResetPasswordToken"]>
>;

const useValidateResetPasswordToken = (token: string | undefined | null) => {
  const authService = useAuthService();

  return useQuery<ValidateResetPasswordTokenResponse, BaseApiError>({
    queryKey: [VALIDATE_RESET_PASSWORD_QUERY_KEY, token],
    queryFn: () =>
      token
        ? authService.validateResetPasswordToken(token)
        : Promise.reject("Token must be provided"),
    retry: 1,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};

export default useValidateResetPasswordToken;
