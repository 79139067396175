import { ReactElement } from "react";

import { HStack, Text, UseRadioProps } from "@chakra-ui/react";

import { Checkmark } from "icons";
import RadioButton from "components/RadioButton";

const TimeOfYearRadio = ({
  text,
  isDisabled = false,
  ...props
}: { text: string; isDisabled?: boolean } & UseRadioProps): ReactElement => (
  <RadioButton variant="timeOfYear" {...props} isDisabled={isDisabled}>
    <HStack spacing={3}>
      {props.isChecked && <Checkmark boxSize="12px" color="inherit" />}
      <Text color="inherit">{text}</Text>
    </HStack>
  </RadioButton>
);
export default TimeOfYearRadio;
