import authPaths from "routes/auth/authPaths";

export const forgotPasswordErrorStates: Record<string, ErrorState> = {
  INVALID_TOKEN: {
    match: /User Invite not found/gi,
    msg: "",
    title: "Looks like your Reset Password Link is no longer valid.",
    linkText: "Go To Login",
    linkTo: `/${authPaths.basePath}`,
  },
  SERVER_DOWN: {
    match: /[\s\S]*/gi,
    msg: "A server error occurred and we cannot complete your password reset. Please try again or contact Riverview.",
    title: "Oops! Looks like the server is down",
    linkText: "Go To Login",
    linkTo: `/${authPaths.basePath}`,
  },
};
