import { Stack, Text } from "@chakra-ui/react";
import { CardBody, CardHeader, ResponsiveCard } from "components/Card";
import LoginForm from "forms/auth/LoginForm";
import { mobileStyleBreakpoint } from "utils/styleHelpers";

const CARD_MIN_WIDTH = mobileStyleBreakpoint("100%", "570px");
const CARD_PADDING_TOP = mobileStyleBreakpoint(158, 0);

const LoginCard = () => (
  <ResponsiveCard
    display="flex"
    variant="shadowless"
    border="none"
    minW={CARD_MIN_WIDTH}
    pt={CARD_PADDING_TOP}
  >
    <CardHeader>Login</CardHeader>
    <CardBody>
      <Stack spacing={4} flex={1}>
        <Text>Welcome back! Please enter your details.</Text>
        <LoginForm />
      </Stack>
    </CardBody>
  </ResponsiveCard>
);

export default LoginCard;
