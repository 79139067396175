import { skipToken, useInfiniteQuery } from "@tanstack/react-query";

import { HaylageYearFarmResponse } from "api";
import { useHaylageYearService } from "contexts/ServiceProvider";
import { getNextPage, PaginationMeta, QueryParams } from "services/apiHelpers";
import { FarmsSortColumns } from "components/FarmListTable/listTableDataMapper";

export type GetHaylageYearFarmsResponse = {
  haylageYearFarms: HaylageYearFarmResponse[];
  meta: PaginationMeta;
};

export const HAYLAGE_YEAR_FARMS_QUERY_KEY = "list-haylage-year-farms";

const useGetHaylageYearFarms = (
  haylageYearId?: string,
  queryParams?: Omit<QueryParams<FarmsSortColumns>, "page">
) => {
  const haylageYearService = useHaylageYearService();

  return useInfiniteQuery({
    queryKey: [HAYLAGE_YEAR_FARMS_QUERY_KEY, haylageYearId, queryParams],
    queryFn: haylageYearId
      ? ({ pageParam = 0 }) =>
          haylageYearService
            .getHaylageYearFarms(haylageYearId, {
              ...queryParams,
              page: pageParam,
              size: 10,
            })
            .then(({ data, meta }) => ({
              haylageYearFarms: data,
              meta,
            }))
      : skipToken,
    initialPageParam: 0,
    getNextPageParam: (lastPage) => getNextPage(lastPage),
  });
};

export default useGetHaylageYearFarms;
