import { VStack } from "@chakra-ui/react";

import { CardBody, CardHeader, ResponsiveCard } from "components/Card";
import BasicUserInfoFormWrapper from "forms/user-registration/basic-user-info/";
import useNavigateWithQuery from "hooks/useNavigateWithQuery";
import userRegistrationPaths from "routes/user-registration/userRegistrationPaths";
import { CARD_MAX_WIDTH } from "utils/styleHelpers";

const BasicUserInfo = () => {
  const navigateWithQuery = useNavigateWithQuery();

  return (
    <VStack>
      <ResponsiveCard maxW={CARD_MAX_WIDTH}>
        <CardHeader>Let&apos;s finish setting up your account!</CardHeader>
        <CardBody>
          <BasicUserInfoFormWrapper
            onSubmitSuccess={() => {
              navigateWithQuery(
                `../${userRegistrationPaths.children.phoneNumberPath}`
              );
            }}
          />
        </CardBody>
      </ResponsiveCard>
    </VStack>
  );
};

export default BasicUserInfo;
