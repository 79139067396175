import { ReactElement } from "react";

import { Button, ButtonProps } from "@chakra-ui/react";

import { GarbageCanIcon } from "icons";

const DeleteButton = (props: ButtonProps): ReactElement => (
  <Button
    variant="tableButton"
    color="errorRed"
    leftIcon={<GarbageCanIcon boxSize={5} />}
    fontWeight="bold"
    iconSpacing={1}
    {...props}
  >
    Delete
  </Button>
);

export default DeleteButton;
