import { RouteObject } from "react-router";

import fieldPaths from "./fieldPaths";

import RedirectToDashboard from "components/redirects/RedirectToDashboard";
import ProtectedRoute from "components/route-wrappers/ProtectedRoute";
import { CropYearContextProvider } from "contexts/CropYearContext";
import FormLayout from "layouts/FormLayout";
import FieldInformationPage, {
  NewFieldInformationPage,
} from "pages/field-info/FieldInformationPage";

const fieldRoutes: RouteObject = {
  path: fieldPaths.basePath,
  element: (
    <CropYearContextProvider>
      <ProtectedRoute>
        <FormLayout />
      </ProtectedRoute>
    </CropYearContextProvider>
  ),
  children: [
    {
      path: ":id",
      element: <FieldInformationPage />,
    },
    {
      path: fieldPaths.children.new,
      element: <NewFieldInformationPage />,
    },
    {
      index: true,
      element: <RedirectToDashboard />,
    },
    {
      path: "*",
      element: <RedirectToDashboard />,
    },
  ],
};

export default fieldRoutes;
