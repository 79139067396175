import { Stack, Text } from "@chakra-ui/react";
import { ReactElement } from "react";
import { formatPhoneNumber } from "utils/formatFunctions";

const FarmMenuOption = ({
  name,
  secondaryInfo,
}: {
  name: string;
  secondaryInfo: string;
}): ReactElement => (
  <Stack spacing={0}>
    <Text overflowWrap="anywhere" fontWeight="bold">
      {name}
    </Text>
    <Text fontSize="xs">
      {parseInt(secondaryInfo)
        ? formatPhoneNumber(secondaryInfo)
        : secondaryInfo}
    </Text>
  </Stack>
);

export default FarmMenuOption;
