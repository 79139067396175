import { SelectOption } from "components/Select";

const activeOrganizationStates = [
  "ARIZONA",
  "MINNESOTA",
  "NEBRASKA",
  "NEW_MEXICO",
  "NORTH_DAKOTA",
  "SOUTH_DAKOTA",
];

export const stateSelectOptions: SelectOption[] = [
  { value: "ALABAMA", label: "Alabama" },
  { value: "ALASKA", label: "Alaska" },
  { value: "ARIZONA", label: "Arizona" },
  { value: "ARKANSAS", label: "Arkansas" },
  { value: "CALIFORNIA", label: "California" },
  { value: "COLORADO", label: "Colorado" },
  { value: "CONNECTICUT", label: "Connecticut" },
  { value: "DELAWARE", label: "Delaware" },
  { value: "DISTRICT_OF_COLUMBIA", label: "District Of Columbia" },
  { value: "FLORIDA", label: "Florida" },
  { value: "GEORGIA", label: "Georgia" },
  { value: "HAWAII", label: "Hawaii" },
  { value: "IDAHO", label: "Idaho" },
  { value: "ILLINOIS", label: "Illinois" },
  { value: "INDIANA", label: "Indiana" },
  { value: "IOWA", label: "Iowa" },
  { value: "KANSAS", label: "Kansas" },
  { value: "KENTUCKY", label: "Kentucky" },
  { value: "LOUISIANA", label: "Louisiana" },
  { value: "MAINE", label: "Maine" },
  { value: "MARYLAND", label: "Maryland" },
  { value: "MASSACHUSETTS", label: "Massachusetts" },
  { value: "MICHIGAN", label: "Michigan" },
  { value: "MINNESOTA", label: "Minnesota" },
  { value: "MISSISSIPPI", label: "Mississippi" },
  { value: "MISSOURI", label: "Missouri" },
  { value: "MONTANA", label: "Montana" },
  { value: "NEBRASKA", label: "Nebraska" },
  { value: "NEVADA", label: "Nevada" },
  { value: "NEW_HAMPSHIRE", label: "New Hampshire" },
  { value: "NEW_JERSEY", label: "New Jersey" },
  { value: "NEW_MEXICO", label: "New Mexico" },
  { value: "NEW_YORK", label: "New York" },
  { value: "NORTH_CAROLINA", label: "North Carolina" },
  { value: "NORTH_DAKOTA", label: "North Dakota" },
  { value: "OHIO", label: "Ohio" },
  { value: "OKLAHOMA", label: "Oklahoma" },
  { value: "OREGON", label: "Oregon" },
  { value: "PENNSYLVANIA", label: "Pennsylvania" },
  { value: "RHODE_ISLAND", label: "Rhode Island" },
  { value: "SOUTH_CAROLINA", label: "South Carolina" },
  { value: "SOUTH_DAKOTA", label: "South Dakota" },
  { value: "TENNESSEE", label: "Tennessee" },
  { value: "TEXAS", label: "Texas" },
  { value: "UTAH", label: "Utah" },
  { value: "VERMONT", label: "Vermont" },
  { value: "VIRGINIA", label: "Virginia" },
  { value: "WASHINGTON", label: "Washington" },
  { value: "WEST_VIRGINIA", label: "West Virginia" },
  { value: "WISCONSIN", label: "Wisconsin" },
  { value: "WYOMING", label: "Wyoming" },
];

export const organizationStateSelectOptions: SelectOption[] =
  stateSelectOptions.filter(
    ({ value }) => activeOrganizationStates.indexOf(value) >= 0
  );
