import { ReactElement, ReactNode } from "react";
import {
  Box,
  Center,
  HStack,
  Spinner,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";

import {
  CardBody,
  CardFooter,
  CardHeader,
  ResponsiveCard,
} from "components/Card";
import { ICELogo, CMELogo, PoweredByBushelLogo } from "components/Logo";
import { SITE_HEADER_HEIGHT } from "components/SiteHeader";

import {
  PAGE_ALIGN,
  PAGE_BACKGROUND_COLOR,
} from "pages/user-registration/Layout";
import { mobileStyleBreakpoint } from "utils/styleHelpers";

const PRICING_CONTRACT_HEADER_SIZE = mobileStyleBreakpoint("h4", "h3");
const PRICING_CONTRACT_CARD_PADDING = mobileStyleBreakpoint("12px", "72px");
const PRICING_CONTRACT_HEADER_PADDING = mobileStyleBreakpoint(2, 11);

const PRICING_CONTRACT_MARKET_DATA_DISCLAIMER = `Market data provided by Bushel. Information is provided 'as is'
and solely for informational purposes, not for trading
purposes or advice, and is delayed per exchange requirements.`;

type PricingContractPageLayoutProps = {
  contractHeader: string;
  isLoading?: boolean;
  children?: ReactNode;
  badge?: ReactElement<any, any>;
  loadingStateText?: string;
};

const PricingContractPageLayout = ({
  contractHeader,
  isLoading,
  children,
  badge,
  loadingStateText,
}: PricingContractPageLayoutProps) => {
  return (
    <Stack bg={PAGE_BACKGROUND_COLOR} minH="100vh" spacing={0} pt={6}>
      <Center alignItems={PAGE_ALIGN}>
        <ResponsiveCard
          justifyContent="center"
          pt={PRICING_CONTRACT_CARD_PADDING}
          w="534px"
          minH={`min(971px, calc(100vh - ${SITE_HEADER_HEIGHT}))`}
        >
          {isLoading ? (
            <VStack spacing={10}>
              <Spinner />
              {loadingStateText && (
                <Text fontWeight="bold">{loadingStateText}</Text>
              )}
            </VStack>
          ) : (
            <>
              {badge && (
                <Box pb={4} w="100%" justifyContent="flex-start">
                  {badge}
                </Box>
              )}
              <CardHeader
                pb={PRICING_CONTRACT_HEADER_PADDING}
                textStyle={PRICING_CONTRACT_HEADER_SIZE}
              >
                {contractHeader}
              </CardHeader>
              <CardBody>{children}</CardBody>
              <CardFooter mt={4}>
                <VStack pb={5} spacing={4}>
                  <Text color="gray.400" fontSize="12px">
                    {PRICING_CONTRACT_MARKET_DATA_DISCLAIMER}
                  </Text>
                  <HStack spacing={4} w="100%">
                    <ICELogo />
                    <CMELogo />
                    <PoweredByBushelLogo />
                  </HStack>
                </VStack>
              </CardFooter>
            </>
          )}
        </ResponsiveCard>
      </Center>
    </Stack>
  );
};

export default PricingContractPageLayout;
