import React, { PropsWithChildren, ReactElement } from "react";

import { HStack, Stack, StackProps } from "@chakra-ui/react";
import { DesktopOnly, MobileOnly } from "components/BreakpointVisibility";
import { PageHeader, PageHeaderStack } from "components/page-components";
import { SearchInput } from "components/inputs";

import { mobileStyleBreakpoint } from "utils/styleHelpers";

const SEARCH_WIDTH = mobileStyleBreakpoint("100%", "328px");

export const SilageDashboardTabStack = ({
  children,
  ...rest
}: PropsWithChildren & StackProps) => (
  <Stack spacing={{ base: "18px", md: 6 }} h="100%" {...rest}>
    {children}
  </Stack>
);

export const SilageDashboardTabHeader = ({
  children,
  ...rest
}: PropsWithChildren & React.ComponentProps<typeof PageHeader>) => (
  <PageHeader headingType="h4" {...rest}>
    {children}
  </PageHeader>
);

const SilageDashboardTabLayout = ({
  searchTerm,
  setSearchTerm,
  heading,
  addButton,
  children,
}: PropsWithChildren<{
  heading: string;
  searchTerm: string;
  addButton?: ReactElement | null;
  setSearchTerm: (newSearchTerm: string) => void;
}>): ReactElement => {
  const search = (
    <SearchInput
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      onClear={() => setSearchTerm("")}
      w={SEARCH_WIDTH}
      clearVisible={!!searchTerm}
    />
  );

  return (
    <SilageDashboardTabStack>
      <PageHeaderStack>
        <SilageDashboardTabHeader heading={heading}>
          <HStack spacing={4}>
            <DesktopOnly>{search}</DesktopOnly>
            {addButton}
          </HStack>
        </SilageDashboardTabHeader>
        <MobileOnly>{search}</MobileOnly>
      </PageHeaderStack>
      {children}
    </SilageDashboardTabStack>
  );
};

export default SilageDashboardTabLayout;
