import { Navigate, RouteObject } from "react-router";
import csvPaths from "./csvPaths";
import ProtectedRoute from "components/route-wrappers/ProtectedRoute";
import RedirectToDashboard from "components/redirects/RedirectToDashboard";
import RouteErrorBoundary from "components/RouteErrorBoundary";
import { CsvUploadPage } from "components/CsvUpload";
import CsvLayout from "layouts/CsvLayout";
import {
  ActualTonsCatastrophicErrorState,
  ActualTonsFileSelect,
  ActualTonsResultsWithErrors,
  ActualTonsResultsNoErrors,
  CsvUploadProgress,
  FarmDocumentsCatastrophicErrorState,
  FieldsCatastrophicErrorState,
  FieldsFileSelect,
  FieldsUploadResults,
  FieldsUploadResultsNoErrors,
} from "pages/csv-uploads";
import { CSV_UPLOAD } from "constants/featureFlags";
import FarmDocumentsFileSelect from "pages/csv-uploads/farm-documents";
import FarmDocumentsCsvUploadProgress from "pages/csv-uploads/farm-documents/FarmDocumentsCsvUploadProgress";
import { featureFlagLoaderCheck } from "routes/loaderUtils";
import dashboardPaths from "routes/dashboard/dashboardPaths";

const csvErrorElement = (
  <RouteErrorBoundary
    buttonText="Back to Fields List"
    buttonLink={`/${dashboardPaths.basePath}`}
  />
);

export const csvRoutes: RouteObject = {
  path: csvPaths.basePath,
  children: [
    {
      path: "*",
      element: (
        <ProtectedRoute>
          <CsvLayout />
        </ProtectedRoute>
      ),
      children: [
        {
          loader: featureFlagLoaderCheck(CSV_UPLOAD.FIELDS),
          errorElement: csvErrorElement,
          path: csvPaths.children.fields.basePath,
          element: <CsvUploadPage heading="Field Information Upload" />,
          children: [
            {
              path: csvPaths.children.fields.children.fileSelect,
              element: <FieldsFileSelect />,
            },
            {
              path: `${csvPaths.children.fields.children.progress}/:jobId`,
              element: <CsvUploadProgress />,
            },
            {
              path: `${csvPaths.children.fields.children.results}/:jobId`,
              element: <FieldsUploadResults />,
            },
            {
              path: `${csvPaths.children.fields.children.resultsNoErrors}/:jobId`,
              element: <FieldsUploadResultsNoErrors />,
            },

            {
              path: `${csvPaths.children.fields.children.failed}/:jobId`,
              element: <FieldsCatastrophicErrorState />,
            },
            {
              index: true,
              element: (
                <Navigate
                  to={csvPaths.children.fields.children.fileSelect}
                  replace={true}
                />
              ),
            },
            {
              path: "*",
              element: (
                <Navigate
                  to={csvPaths.children.fields.children.fileSelect}
                  replace={true}
                />
              ),
            },
          ],
        },
        {
          loader: featureFlagLoaderCheck(CSV_UPLOAD.ACTUAL_TONS),
          errorElement: csvErrorElement,
          path: csvPaths.children.actualTons.basePath,
          element: <CsvUploadPage heading="Actual Tons Upload" />,
          children: [
            {
              path: csvPaths.children.actualTons.children.fileSelect,
              element: <ActualTonsFileSelect />,
            },
            {
              path: `${csvPaths.children.actualTons.children.progress}/:jobId`,
              element: <CsvUploadProgress />,
            },
            {
              path: `${csvPaths.children.actualTons.children.results}/:jobId`,
              element: <ActualTonsResultsWithErrors />,
            },
            {
              path: `${csvPaths.children.actualTons.children.resultsNoErrors}/:jobId`,
              element: <ActualTonsResultsNoErrors />,
            },
            {
              path: `${csvPaths.children.fields.children.failed}/:jobId`,
              element: <ActualTonsCatastrophicErrorState />,
            },
            {
              index: true,
              element: (
                <Navigate
                  to={csvPaths.children.actualTons.children.fileSelect}
                  replace={true}
                />
              ),
            },
            {
              path: "*",
              element: (
                <Navigate
                  to={csvPaths.children.actualTons.children.fileSelect}
                  replace={true}
                />
              ),
            },
          ],
        },
        {
          loader: featureFlagLoaderCheck(CSV_UPLOAD.FARM_DOCUMENTS),
          errorElement: csvErrorElement,
          path: csvPaths.children.farmDocuments.basePath,
          element: <CsvUploadPage heading="Document Link Upload" />,
          children: [
            {
              path: csvPaths.children.farmDocuments.children.fileSelect,
              element: <FarmDocumentsFileSelect />,
            },
            {
              path: `${csvPaths.children.farmDocuments.children.progress}/:jobId`,
              element: <FarmDocumentsCsvUploadProgress />,
            },
            {
              path: `${csvPaths.children.farmDocuments.children.failed}/:jobId`,
              element: <FarmDocumentsCatastrophicErrorState />,
            },
            {
              index: true,
              element: (
                <Navigate
                  to={csvPaths.children.farmDocuments.children.fileSelect}
                  replace={true}
                />
              ),
            },
            {
              path: "*",
              element: (
                <Navigate
                  to={csvPaths.children.farmDocuments.children.fileSelect}
                  replace={true}
                />
              ),
            },
          ],
        },
        {
          index: true,
          element: <RedirectToDashboard />,
        },
        {
          path: "*",
          element: <RedirectToDashboard />,
        },
      ],
    },
  ],
};

export default csvRoutes;
