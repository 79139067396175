import { ReactElement } from "react";
import { HStack, Stack, Text, Tooltip } from "@chakra-ui/react";
import { formatDate, parseDate } from "utils/dateUtils";

const DateOfContractWithContractTypeColumn = ({
  contractType,
  dateOfContract,
}: {
  contractType: string;
  dateOfContract: string;
}): ReactElement => (
  <HStack spacing={3}>
    <Stack justify="space-around" spacing={0}>
      <Tooltip hasArrow label={dateOfContract}>
        <Text variant={"truncated"} textStyle="caption">
          {dateOfContract ? formatDate(parseDate(dateOfContract)) : ""}
        </Text>
      </Tooltip>
      {contractType && <Text fontWeight="bold">{contractType}</Text>}
    </Stack>
  </HStack>
);

export default DateOfContractWithContractTypeColumn;
