import { ReactElement, useCallback } from "react";
import { Navigate, useSearchParams } from "react-router-dom";

import { Center, Spinner } from "@chakra-ui/react";
import { SubmitHandler } from "react-hook-form";

import { AveragePricingContractRequest } from "api";
import useSelectedFarmId from "hooks/useSelectedFarmId";
import useCustomToast from "hooks/useCustomToast";
import useCreateAveragePricingContract from "hooks/pricing-option/useCreateAveragePricingContract";
import useUpdateAveragePricingContract from "hooks/pricing-option/useUpdateAveragePricingContract";
import dashboardPaths from "routes/dashboard/dashboardPaths";
import AveragePricingForm from "../AveragePricingForm";
import useIsAveragePricingFormLoaded from "./useIsAveragePricingFormLoaded";
import useFarmsRegionDetails from "hooks/farm/useFarmsRegionDetails";

export const AveragePricingFormWrapper = ({
  id,
  onClose = () => {
    return;
  },
}: {
  id?: string;
  onClose?: () => void;
}): ReactElement => {
  const [farmId] = useSelectedFarmId();
  const [searchParams] = useSearchParams();

  const {
    isLoading,
    data: { cropYearDetail, cropYearSummary, pricingData },
  } = useIsAveragePricingFormLoaded(id);

  const { mutateAsync: createAsync } = useCreateAveragePricingContract(
    cropYearDetail?.id,
    farmId
  );

  const { mutateAsync: updateAsync } = useUpdateAveragePricingContract(
    id,
    cropYearDetail?.id,
    farmId
  );

  const { regionDetails, isLoading: regionDetailsIsLoading } =
    useFarmsRegionDetails(farmId);

  const { onSuccessToast, onErrorToast } = useCustomToast();

  const onSave: SubmitHandler<AveragePricingContractRequest> = useCallback(
    async (data) => {
      await (id ? updateAsync : createAsync)(data)
        .then(() => {
          onSuccessToast({
            message: `Average pricing contract ${
              id ? "updated" : "created"
            } successfully`,
          });
          onClose();
        })
        .catch(() => {
          onErrorToast({
            message: `Failed to ${
              id ? "update" : "create"
            } average pricing contract`,
          });
        });
    },
    [createAsync, id, onClose, onErrorToast, onSuccessToast, updateAsync]
  );

  if (isLoading || regionDetailsIsLoading) {
    return (
      <Center h="100%">
        <Spinner />
      </Center>
    );
  }

  if (
    !regionDetails ||
    !cropYearDetail ||
    !cropYearSummary ||
    (id && !pricingData)
  ) {
    return (
      <Navigate
        to={`/${dashboardPaths.basePath}/${dashboardPaths.children.pricing}?${searchParams}`}
      />
    );
  }

  return (
    <AveragePricingForm
      cropYearDetail={cropYearDetail}
      remainingPercent={
        100 -
        (cropYearSummary.percentageOfTonsPriced ?? 0) +
        (pricingData?.percentageToSell ?? 0) // Add the current value, as you can reallocate the current values as well as any remaining
      }
      onCancel={onClose}
      onSave={onSave}
      pricingData={pricingData}
      regionDetails={regionDetails}
    />
  );
};

export default AveragePricingFormWrapper;
