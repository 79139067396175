import { ReactElement } from "react";
import * as yup from "yup";

import {
  MIN_PASSWORD_LENGTH,
  REGEX_PASSWORD_CRITERIA,
} from "constants/registrationForm";
import PasswordPopover from "components/PasswordPopover";
import { PasswordInput, FormInputs } from "components/inputs";

export const changePasswordSchema = yup
  .object({
    password: yup
      .string()
      .required("Password is required")
      .min(MIN_PASSWORD_LENGTH, "Password must be at least 12 characters long")
      .matches(
        REGEX_PASSWORD_CRITERIA.CONTAINS_LOWERCASE_LETTER,
        "Password must contain lowercase letter"
      )
      .matches(
        REGEX_PASSWORD_CRITERIA.CONTAINS_UPPERCASE_LETTER,
        "Password must contain uppercase letter"
      )
      .matches(
        REGEX_PASSWORD_CRITERIA.CONTAINS_NUMBER,
        "Password must contain a number"
      )
      .matches(
        REGEX_PASSWORD_CRITERIA.CONTAINS_SPECIAL_CHARACTER,
        "Password must contain a special character"
      ),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password")], "Passwords don't match")
      .required("Confirm password is required"),
  })
  .required();

const ChangePasswordForm = (): ReactElement => (
  <FormInputs
    inputs={[
      {
        id: "password",
        label: "Password",
        component: PasswordPopover,
        isRequired: true,
      },
      {
        id: "confirmPassword",
        label: "Confirm Password",
        component: PasswordInput,
        isRequired: true,
      },
    ]}
  />
);

export default ChangePasswordForm;
