/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { CropYearRegionDto } from "./crop-year-region-dto";

/**
 *
 * @export
 * @interface CropYearDetailResponse
 */
export interface CropYearDetailResponse {
  /**
   *
   * @type {string}
   * @memberof CropYearDetailResponse
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof CropYearDetailResponse
   */
  year: number;
  /**
   *
   * @type {string}
   * @memberof CropYearDetailResponse
   */
  timeOfYear: CropYearDetailResponseTimeOfYearEnum;
  /**
   *
   * @type {number}
   * @memberof CropYearDetailResponse
   */
  distanceAdjustmentRate: number;
  /**
   *
   * @type {string}
   * @memberof CropYearDetailResponse
   */
  defermentDateStart: string;
  /**
   *
   * @type {string}
   * @memberof CropYearDetailResponse
   */
  firstPaymentDateWithoutDeferral: string;
  /**
   *
   * @type {string}
   * @memberof CropYearDetailResponse
   */
  averagePricingLockInDate: string;
  /**
   *
   * @type {string}
   * @memberof CropYearDetailResponse
   */
  delayedPricingFinalDate: string;
  /**
   *
   * @type {string}
   * @memberof CropYearDetailResponse
   */
  storageFeeStartDate: string;
  /**
   *
   * @type {number}
   * @memberof CropYearDetailResponse
   */
  storageFeeRate: number;
  /**
   *
   * @type {string}
   * @memberof CropYearDetailResponse
   */
  growerLetterName: string;
  /**
   *
   * @type {string}
   * @memberof CropYearDetailResponse
   */
  growerLetterLink: string;
  /**
   *
   * @type {Array<CropYearRegionDto>}
   * @memberof CropYearDetailResponse
   */
  regions: Array<CropYearRegionDto>;
}

/**
 * @export
 * @enum {string}
 */
export enum CropYearDetailResponseTimeOfYearEnum {
  PreHarvest = "PRE_HARVEST",
  PostHarvest = "POST_HARVEST",
}
