import { GuideListItemType } from "../ErrorGuideList";

export const actualTonsErrorGuideData: Array<GuideListItemType> = [
  {
    headingText: "Field ID",
    listItems: [
      {
        text: `Field ID is required and must be entered for all Fields in the .csv file.`,
      },
      {
        text: `Duplicate Field ID: there is more than one 
                  Field with this Field number in the Grower Portal.`,
      },
      {
        text: `Field IDs must be unique in the crop year.`,
      },
      {
        text: `Field ID must be 16-characters or less.`,
      },
      {
        text: `Field does not exist in the Grower Portal. Field must be added to the Grower Portal before uploading Actual Tons.`,
      },
    ],
  },
  {
    headingText: "Dairy Abbreviation",
    listItems: [
      {
        text: `The Dairy and Field ID combination does not match what is saved in the Grower Portal. 
        Verify that the Dairy site is assigned to the right Field on the Field Information page and in the .csv file.`,
      },
      {
        text: `Dairy abbreviation does not match the dairies mapped in the Grower Portal. Accepted dairy abbreviations are:`,
        listItems: [
          {
            text: "Lake Norden",
            listItems: [
              {
                text: "Clark: CLD",
              },
              {
                text: "Washington: WSD",
              },
              {
                text: "Garfield: GAD",
              },
            ],
          },
          {
            text: "Marshall",
            listItems: [
              {
                text: "Marshall: MAD",
              },
            ],
          },
          {
            text: "Red River",
            listItems: [
              {
                text: "Campbell: CAD",
              },
              {
                text: "North Ottawa: NOD",
              },
              {
                text: "Dollymount: DMD",
              },
            ],
          },
          {
            text: "Stevens",
            listItems: [
              {
                text: "West River: WRD",
              },
              {
                text: "Riverview: RVD",
              },
              {
                text: "District 45: D45",
              },
              {
                text: "Moore Calves: MOC",
              },
            ],
          },
          {
            text: "Swift",
            listItems: [
              {
                text: "Swenoda: SWD",
              },
              {
                text: "West Dublin: WDD",
              },
              {
                text: "Louriston: LOD",
              },
              {
                text: "East Dublin: EDD",
              },
              {
                text: "Meadow Star: MSD",
              },
              {
                text: "Hawk Creek Calves: HCC",
              },
            ],
          },
          {
            text: "WKN - Waukon",
            listItems: [
              {
                text: "Waukon: WAD",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    headingText: "Tons @ 65 %",
    listItems: [
      {
        text: "Tons must be greater than 0",
      },
      {
        text: "Tons must be 30,000 or less",
      },
      {
        text: "Tons must be 14-characters or less",
      },
    ],
  },
];
