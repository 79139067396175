import { ValidationError, CreateErrorOptions } from "yup";

export const dateAfterYear = (
  value: Date | undefined,
  minYear: number,
  fieldLabel: string,
  errCallback: (options: CreateErrorOptions) => ValidationError
) => {
  const minDate = new Date(minYear, 0, 1);
  if (value && value?.getTime() < minDate.getTime()) {
    return errCallback({
      message: `${fieldLabel} must be on or after ${minDate.toDateString()}`,
    });
  }

  return true;
};
export const dateWithinCropYear = (
  value: Date | undefined,
  year: number,
  fieldLabel: string,
  errCallback: (options: CreateErrorOptions) => ValidationError
) => {
  return value?.getUTCFullYear() !== year
    ? errCallback({
        message: `${fieldLabel} must be within the ${year} crop year`,
      })
    : true;
};
