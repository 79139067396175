/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosRequestConfig,
} from "axios";
import { Configuration } from "../configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "../base";
// @ts-ignore
import { ChangePasswordRequest } from "../models";
// @ts-ignore
import { DataAccessTokenResponse } from "../models";
// @ts-ignore
import { DataMeResponse } from "../models";
// @ts-ignore
import { ForgotPasswordRequest } from "../models";
// @ts-ignore
import { LoginRequest } from "../models";
// @ts-ignore
import { LogoutRequest } from "../models";
// @ts-ignore
import { RefreshTokenRequest } from "../models";
// @ts-ignore
import { ResetPasswordRequest } from "../models";
/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {ChangePasswordRequest} changePasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changePassword: async (
      changePasswordRequest: ChangePasswordRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'changePasswordRequest' is not null or undefined
      assertParamExists(
        "changePassword",
        "changePasswordRequest",
        changePasswordRequest
      );
      const localVarPath = `/auth/change-password`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        changePasswordRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ForgotPasswordRequest} forgotPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    forgotPassword: async (
      forgotPasswordRequest: ForgotPasswordRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'forgotPasswordRequest' is not null or undefined
      assertParamExists(
        "forgotPassword",
        "forgotPasswordRequest",
        forgotPasswordRequest
      );
      const localVarPath = `/auth/forgot-password`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        forgotPasswordRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {LoginRequest} loginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    login: async (
      loginRequest: LoginRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'loginRequest' is not null or undefined
      assertParamExists("login", "loginRequest", loginRequest);
      const localVarPath = `/auth/login`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        loginRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {LogoutRequest} logoutRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logout: async (
      logoutRequest: LogoutRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'logoutRequest' is not null or undefined
      assertParamExists("logout", "logoutRequest", logoutRequest);
      const localVarPath = `/auth/logout`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        logoutRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    me: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/auth/me`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {RefreshTokenRequest} refreshTokenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshToken: async (
      refreshTokenRequest: RefreshTokenRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'refreshTokenRequest' is not null or undefined
      assertParamExists(
        "refreshToken",
        "refreshTokenRequest",
        refreshTokenRequest
      );
      const localVarPath = `/auth/refresh-token`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        refreshTokenRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ResetPasswordRequest} resetPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetPassword: async (
      resetPasswordRequest: ResetPasswordRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'resetPasswordRequest' is not null or undefined
      assertParamExists(
        "resetPassword",
        "resetPasswordRequest",
        resetPasswordRequest
      );
      const localVarPath = `/auth/reset-password`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        resetPasswordRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validatePasswordResetToken: async (
      token: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      assertParamExists("validatePasswordResetToken", "token", token);
      const localVarPath = `/auth/reset-password`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (token !== undefined) {
        localVarQueryParameter["token"] = token;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {ChangePasswordRequest} changePasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async changePassword(
      changePasswordRequest: ChangePasswordRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.changePassword(
        changePasswordRequest,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {ForgotPasswordRequest} forgotPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async forgotPassword(
      forgotPasswordRequest: ForgotPasswordRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.forgotPassword(
        forgotPasswordRequest,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {LoginRequest} loginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async login(
      loginRequest: LoginRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataAccessTokenResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.login(
        loginRequest,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {LogoutRequest} logoutRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async logout(
      logoutRequest: LogoutRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.logout(
        logoutRequest,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async me(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataMeResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.me(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {RefreshTokenRequest} refreshTokenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async refreshToken(
      refreshTokenRequest: RefreshTokenRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataAccessTokenResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.refreshToken(
        refreshTokenRequest,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {ResetPasswordRequest} resetPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async resetPassword(
      resetPasswordRequest: ResetPasswordRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(
        resetPasswordRequest,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async validatePasswordResetToken(
      token: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.validatePasswordResetToken(
          token,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AuthApiFp(configuration);
  return {
    /**
     *
     * @param {ChangePasswordRequest} changePasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changePassword(
      changePasswordRequest: ChangePasswordRequest,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .changePassword(changePasswordRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {ForgotPasswordRequest} forgotPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    forgotPassword(
      forgotPasswordRequest: ForgotPasswordRequest,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .forgotPassword(forgotPasswordRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {LoginRequest} loginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    login(
      loginRequest: LoginRequest,
      options?: any
    ): AxiosPromise<DataAccessTokenResponse> {
      return localVarFp
        .login(loginRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {LogoutRequest} logoutRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logout(logoutRequest: LogoutRequest, options?: any): AxiosPromise<string> {
      return localVarFp
        .logout(logoutRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    me(options?: any): AxiosPromise<DataMeResponse> {
      return localVarFp.me(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {RefreshTokenRequest} refreshTokenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshToken(
      refreshTokenRequest: RefreshTokenRequest,
      options?: any
    ): AxiosPromise<DataAccessTokenResponse> {
      return localVarFp
        .refreshToken(refreshTokenRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {ResetPasswordRequest} resetPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetPassword(
      resetPasswordRequest: ResetPasswordRequest,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .resetPassword(resetPasswordRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validatePasswordResetToken(
      token: string,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .validatePasswordResetToken(token, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for changePassword operation in AuthApi.
 * @export
 * @interface AuthApiChangePasswordRequest
 */
export interface AuthApiChangePasswordRequest {
  /**
   *
   * @type {ChangePasswordRequest}
   * @memberof AuthApiChangePassword
   */
  readonly changePasswordRequest: ChangePasswordRequest;
}

/**
 * Request parameters for forgotPassword operation in AuthApi.
 * @export
 * @interface AuthApiForgotPasswordRequest
 */
export interface AuthApiForgotPasswordRequest {
  /**
   *
   * @type {ForgotPasswordRequest}
   * @memberof AuthApiForgotPassword
   */
  readonly forgotPasswordRequest: ForgotPasswordRequest;
}

/**
 * Request parameters for login operation in AuthApi.
 * @export
 * @interface AuthApiLoginRequest
 */
export interface AuthApiLoginRequest {
  /**
   *
   * @type {LoginRequest}
   * @memberof AuthApiLogin
   */
  readonly loginRequest: LoginRequest;
}

/**
 * Request parameters for logout operation in AuthApi.
 * @export
 * @interface AuthApiLogoutRequest
 */
export interface AuthApiLogoutRequest {
  /**
   *
   * @type {LogoutRequest}
   * @memberof AuthApiLogout
   */
  readonly logoutRequest: LogoutRequest;
}

/**
 * Request parameters for refreshToken operation in AuthApi.
 * @export
 * @interface AuthApiRefreshTokenRequest
 */
export interface AuthApiRefreshTokenRequest {
  /**
   *
   * @type {RefreshTokenRequest}
   * @memberof AuthApiRefreshToken
   */
  readonly refreshTokenRequest: RefreshTokenRequest;
}

/**
 * Request parameters for resetPassword operation in AuthApi.
 * @export
 * @interface AuthApiResetPasswordRequest
 */
export interface AuthApiResetPasswordRequest {
  /**
   *
   * @type {ResetPasswordRequest}
   * @memberof AuthApiResetPassword
   */
  readonly resetPasswordRequest: ResetPasswordRequest;
}

/**
 * Request parameters for validatePasswordResetToken operation in AuthApi.
 * @export
 * @interface AuthApiValidatePasswordResetTokenRequest
 */
export interface AuthApiValidatePasswordResetTokenRequest {
  /**
   *
   * @type {string}
   * @memberof AuthApiValidatePasswordResetToken
   */
  readonly token: string;
}

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
  /**
   *
   * @param {AuthApiChangePasswordRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public changePassword(
    requestParameters: AuthApiChangePasswordRequest,
    options?: AxiosRequestConfig
  ) {
    return AuthApiFp(this.configuration)
      .changePassword(requestParameters.changePasswordRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AuthApiForgotPasswordRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public forgotPassword(
    requestParameters: AuthApiForgotPasswordRequest,
    options?: AxiosRequestConfig
  ) {
    return AuthApiFp(this.configuration)
      .forgotPassword(requestParameters.forgotPasswordRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AuthApiLoginRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public login(
    requestParameters: AuthApiLoginRequest,
    options?: AxiosRequestConfig
  ) {
    return AuthApiFp(this.configuration)
      .login(requestParameters.loginRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AuthApiLogoutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public logout(
    requestParameters: AuthApiLogoutRequest,
    options?: AxiosRequestConfig
  ) {
    return AuthApiFp(this.configuration)
      .logout(requestParameters.logoutRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public me(options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .me(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AuthApiRefreshTokenRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public refreshToken(
    requestParameters: AuthApiRefreshTokenRequest,
    options?: AxiosRequestConfig
  ) {
    return AuthApiFp(this.configuration)
      .refreshToken(requestParameters.refreshTokenRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AuthApiResetPasswordRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public resetPassword(
    requestParameters: AuthApiResetPasswordRequest,
    options?: AxiosRequestConfig
  ) {
    return AuthApiFp(this.configuration)
      .resetPassword(requestParameters.resetPasswordRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AuthApiValidatePasswordResetTokenRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public validatePasswordResetToken(
    requestParameters: AuthApiValidatePasswordResetTokenRequest,
    options?: AxiosRequestConfig
  ) {
    return AuthApiFp(this.configuration)
      .validatePasswordResetToken(requestParameters.token, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
