import { ReactElement, useCallback } from "react";
import { Navigate, useParams, useSearchParams } from "react-router-dom";

import { useCropYearContext } from "contexts/CropYearContext";
import FieldDetailForm, {
  CreateFieldFormData,
  formDataToCreateFieldRequest,
} from "forms/field-detail";
import FormSkeletonLoadingState from "components/FormSkeletonLoadingState";
import dashboardPaths from "routes/dashboard/dashboardPaths";
import useGetFieldDetail from "hooks/field/useGetFieldDetail";
import useSelectedFarmId from "hooks/useSelectedFarmId";
import useUpdateField from "hooks/field/useUpdateField";
import useCustomToast from "hooks/useCustomToast";
import useNavigateWithQuery from "hooks/useNavigateWithQuery";
import useFarmsRegionDetails from "hooks/farm/useFarmsRegionDetails";
import { BaseApiError } from "services/apiHelpers";

const fieldsPath = `/${dashboardPaths.basePath}/${dashboardPaths.children.fields}`;

const FieldInformationPage = (): ReactElement => {
  const { id: fieldId } = useParams<{ id: string }>();
  const {
    cropYear,
    isLoading: cropYearIsLoading,
    isPreHarvest,
  } = useCropYearContext();
  const [searchParams] = useSearchParams();
  const navigate = useNavigateWithQuery();
  const [farmId] = useSelectedFarmId();
  const { mutateAsync: onUpdateFieldAsync } = useUpdateField(fieldId);
  const { onSuccessToast, onErrorToast } = useCustomToast();
  const { data: fieldDetail, isLoading: fieldIsLoading } = useGetFieldDetail(
    fieldId,
    farmId
  );
  const { regionDetails, isLoading: farmRegionIsLoading } =
    useFarmsRegionDetails(farmId);

  const navigateToFieldList = useCallback(
    () => navigate(fieldsPath),
    [navigate]
  );

  if (!(farmId || cropYear?.id)) {
    return <Navigate to={`${fieldsPath}?${searchParams}`} />;
  }

  if (fieldIsLoading || farmRegionIsLoading || cropYearIsLoading) {
    return <FormSkeletonLoadingState rowCount={6} />;
  }

  if (!fieldDetail || !regionDetails) {
    // TODO: Follow up on best error handling, if the farm or user fails to load
    return <Navigate to={`${fieldsPath}?${searchParams}`} />;
  }

  const handleSubmit = (data: CreateFieldFormData) => {
    const fieldData = formDataToCreateFieldRequest(data, fieldDetail?.splits);

    return onUpdateFieldAsync(fieldData)
      .then(() => {
        onSuccessToast({
          message: isPreHarvest
            ? "Field saved successfully"
            : "Successfully updated",
        });
        if (isPreHarvest) {
          navigateToFieldList();
        }
      })
      .catch((error: BaseApiError) => {
        onErrorToast({
          message: error.response?.data.error || "Failed to update field",
        });
        throw error;
      });
  };

  return (
    <FieldDetailForm
      data={fieldDetail}
      estimatedTonsPerAcre={regionDetails?.estimatedTonsPerAcre}
      onSubmit={handleSubmit}
    />
  );
};

export default FieldInformationPage;
