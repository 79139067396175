import { Avatar, AvatarProps, Center, CenterProps } from "@chakra-ui/react";

const ProfilePictureAvatar = ({
  name = "Riverview User",
  src,
  size = "4xl",
  ...rest
}: { name?: string; src?: string } & CenterProps &
  Pick<AvatarProps, "size">) => (
  <Center pb={10} {...rest}>
    <Avatar
      name={name}
      size={size}
      getInitials={(_name) => _name.slice(0, 1)}
      bg={src ? "grayFactor.0" : "brand.800"}
      color="white"
      src={src}
    />
  </Center>
);

export default ProfilePictureAvatar;
