import useSelectedFarmId from "./useSelectedFarmId";
import useFarmDetail from "./farm/farm-detail/useFarmDetail";

const useSelectedFarmDetail = () => {
  const [farmId] = useSelectedFarmId();

  return useFarmDetail(farmId);
};

export default useSelectedFarmDetail;
