import HaylageYearSelect from "../components/HaylageYearSelect";
import DocumentsList from "components/DocumentsList";
import FarmListPageLayout from "components/ProgramListPageLayout";
import useGetHaylageYearFarmDocuments from "hooks/api/haylage-year/useGetHaylageYearFarmDocuments";
import useFarmDetail from "hooks/farm/farm-detail/useFarmDetail";
import useSelectedFarmId from "hooks/useSelectedFarmId";
import useSelectedHaylageYearId from "hooks/useSelectedHaylageYearId";

const HaylageYearFarmDocumentList = () => {
  const [haylageYearId] = useSelectedHaylageYearId();
  const [farmId] = useSelectedFarmId();
  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useGetHaylageYearFarmDocuments(haylageYearId, farmId);

  return (
    <DocumentsList
      allowDeletion={false}
      farmId={farmId}
      pages={data?.pages}
      isLoading={isLoading}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
    />
  );
};

const HaylageYearFarmDocumentListPage = () => {
  const [selectedFarmId] = useSelectedFarmId();
  const { data, isLoading } = useFarmDetail(selectedFarmId);

  return (
    <FarmListPageLayout
      heading="Haylage"
      subHeading={data?.doingBusinessAs}
      pageTitle="Documents"
      displayAddFarmsButton={false}
      isLoading={isLoading}
      yearSelect={<HaylageYearSelect />}
    >
      <HaylageYearFarmDocumentList />
    </FarmListPageLayout>
  );
};

export default HaylageYearFarmDocumentListPage;
