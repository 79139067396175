import { HStack, Spacer, Text } from "@chakra-ui/react";

const PrimaryTextItem = ({
  label,
  value,
  valueSuffix,
}: {
  label: string;
  value: number | string;
  valueSuffix?: string;
}) => {
  return (
    <HStack>
      <Text fontSize="md" fontWeight="bold">
        {label}
      </Text>
      <Spacer />
      <Text>
        {value}
        {valueSuffix}
      </Text>
    </HStack>
  );
};

export default PrimaryTextItem;
