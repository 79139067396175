import { ReactElement } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@chakra-ui/react";
import { FormInputs, StandardInput, InviteRoleSelect } from "components/inputs";
import SubmitButton from "components/SubmitButton";
import useInviteNewUser from "hooks/user/useInviteNewUser";
import { InviteUserRequestRoleEnum } from "api";
import { extractResponseErrorMessage } from "services/apiHelpers";
import useCustomToast from "hooks/useCustomToast";
import useFormValidation from "hooks/useFormValidation";
import useSelectedFarmId from "hooks/useSelectedFarmId";

const INVITE_SUCCESS_MESSAGE = "Your invite has been sent.";
const INVITE_ERROR_MESSAGE = "Your invite failed to send.";

export const newUserInfoFormSchema = yup
  .object({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    emailAddress: yup
      .string()
      .email("Must be a valid email address")
      .required("Email is required"),
    role: yup
      .object()
      .shape({
        label: yup.string(),
        value: yup.string(),
      })
      .required("Role is required"),
  })
  .required();

const InviteNewUserForm = ({
  onClose,
}: {
  onClose: () => void;
}): ReactElement => {
  const methods = useForm<NewUserInfo>({
    resolver: yupResolver(newUserInfoFormSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      emailAddress: "",
      role: undefined,
    },
  });

  const {
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const { onSuccessToast, onErrorToast } = useCustomToast();

  const { onInviteNewUserAsync, isPending } = useInviteNewUser();

  const requiredFieldsNotFilled = useFormValidation(watch());

  const [farmId] = useSelectedFarmId();

  const displayErrorUnderEmail = (message: string) =>
    methods.setError("emailAddress", {
      type: "validate",
      message,
    });

  return (
    <FormProvider {...methods}>
      <Stack
        as="form"
        onSubmit={handleSubmit(async (data) => {
          await onInviteNewUserAsync({
            ...data,
            role: data.role.value as InviteUserRequestRoleEnum,
            farmId,
          })
            .then(() => {
              onSuccessToast({ message: INVITE_SUCCESS_MESSAGE });
              onClose();
            })
            .catch((error) => {
              if (error?.code && error.code === "ERR_NETWORK") {
                onErrorToast({ message: INVITE_ERROR_MESSAGE });

                return;
              }
              if (error.response?.data.validation) {
                if (error.response?.data.validation.emailAddress) {
                  displayErrorUnderEmail(
                    error.response?.data.validation.emailAddress
                  );
                }
              } else {
                // Error response doesn't have validation messages per field
                const errorMsg = extractResponseErrorMessage(error?.response);
                if (
                  errorMsg.indexOf("email") >= 0 ||
                  errorMsg.indexOf("multiple") >= 0 ||
                  errorMsg.indexOf("already") >= 0
                ) {
                  displayErrorUnderEmail(errorMsg);
                }
              }
            });
        })}
      >
        <FormInputs
          inputs={[
            {
              id: "firstName",
              label: "First Name",
              component: StandardInput,
              isRequired: true,
            },
            {
              id: "lastName",
              label: "Last Name",
              component: StandardInput,
              isRequired: true,
            },
            {
              id: "emailAddress",
              label: "Email",
              component: StandardInput,
              isRequired: true,
            },
          ]}
        />
        <InviteRoleSelect<NewUserInfo> id="role" />
        <SubmitButton
          submitDisabled={requiredFieldsNotFilled}
          isSubmitting={isPending || isSubmitting}
          buttonText="Invite"
        />
      </Stack>
    </FormProvider>
  );
};

export default InviteNewUserForm;
