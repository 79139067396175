import { PricingOptionStatusDtoStatusEnum } from "api";
import { CONTRACT_TYPE } from "constants/contractTypeEnum";

export const getStatusObject = (status: string, type?: string) => {
  switch (status) {
    case PricingOptionStatusDtoStatusEnum.Pending:
      return {
        value: PricingOptionStatusDtoStatusEnum.Pending.toLowerCase(),
        displayName:
          type === CONTRACT_TYPE.FORWARD_PRICING
            ? "FARM REVIEW"
            : PricingOptionStatusDtoStatusEnum.Pending,
      };

    case PricingOptionStatusDtoStatusEnum.Active:
      return {
        value: PricingOptionStatusDtoStatusEnum.Active.toLowerCase(),
        displayName: PricingOptionStatusDtoStatusEnum.Active,
      };

    case PricingOptionStatusDtoStatusEnum.Void:
      return {
        value: PricingOptionStatusDtoStatusEnum.Void.toLowerCase(),
        displayName: PricingOptionStatusDtoStatusEnum.Void,
      };

    default:
      throw new Error(`Unhandled status: ${status}`);
  }
};
