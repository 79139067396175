import { matchRoutes, useLocation } from "react-router-dom";
import { routes } from "routes";

// TODO: Maybe remove in favor of query string for path parameter
const useLastMatchedPathname = () => {
  const location = useLocation();
  const matchedRoutes = matchRoutes(routes, location);

  return matchedRoutes?.[matchedRoutes.length - 1].route.path;
};

export default useLastMatchedPathname;
