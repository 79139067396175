import { useCallback } from "react";
import {
  Button,
  Center,
  Spacer,
  Stack,
  StackDirection,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  CreateDailyPricingRequest,
  DailyPricingResponse,
  NewCropFuturesDto,
  RegionDto,
} from "api";
import ContentWrapper from "components/ContentWrapper";
import FormButtonGroup from "components/FormButtonGroup";
import { SelectOption } from "components/Select";
import SubmitButton from "components/SubmitButton";
import TextHeading from "components/TextHeading";
import WithSpinner from "components/WithSpinner";
import { dailyPricingFormSchema } from "forms/schemas/dailyPricingFormSchema";
import useConfirmationAlert from "hooks/alerts/useConfirmationAlert";
import useCreateDailyPricing from "hooks/daily-pricing/useCreateDailyPricing";
import useGetDailyPricing from "hooks/daily-pricing/useGetDailyPricing";
import useGetNewCropFutures from "hooks/futures/useGetNewCropFutures";
import useRegionOptions from "hooks/region/useRegionOptions";
import useCustomToast from "hooks/useCustomToast";
import useNavigateWithQuery from "hooks/useNavigateWithQuery";
import { FORM_CONTENT_PX, FormStack } from "layouts/FormLayout";
import { FormProvider, useForm } from "react-hook-form";
import dashboardPaths from "routes/dashboard/dashboardPaths";
import { formatDateWithTimeZone } from "utils/dateUtils";
import { formatNumberWithDecimals } from "utils/formatFunctions";
import { mobileStyleBreakpoint } from "utils/styleHelpers";
import NewCropFuturesSelector from "./NewCropFuturesSelector";
import DailyPricingFormInputs from "./components/DailyPricingInputs";

export type DailyPricingFields = {
  lastUpdated: string;
  dailyPricing: Array<{
    regionId: string;
    oldCropFutures: SelectOption | undefined;
    oldCropBasis: string | undefined;
    newCropBasis: string | undefined;
  }>;
  newCropFutures: SelectOption;
};

const transformDailyPricingData = (
  data: RemoveUndefined<DailyPricingFields>
): CreateDailyPricingRequest => ({
  regions: data.dailyPricing.map((regionPrice) => ({
    ...regionPrice,
    oldCropFuturesSymbol: regionPrice?.oldCropFutures?.value ?? "",
    oldCropBasis: Number(regionPrice.oldCropBasis ?? 0),
    newCropBasis: Number(regionPrice.newCropBasis ?? 0),
  })),
  newCropFuturesSymbol: data.newCropFutures.value ?? "",
});

const DAILY_PRICING_STACK_DIRECTION = mobileStyleBreakpoint<StackDirection>(
  "column",
  "row"
);

const DAILY_PRICING_HEADING_ALIGNMENT = mobileStyleBreakpoint("left", "center");

const DAILY_PRICING_HEADING_SPACING = mobileStyleBreakpoint(1, 4);

const DailyPricingPageForm = ({
  newCropFuturesData,
  dailyPricingData,
  regions,
}: {
  newCropFuturesData: NewCropFuturesDto | undefined;
  dailyPricingData: DailyPricingResponse | undefined;
  regions: RegionDto[] | undefined;
}) => {
  const navigate = useNavigateWithQuery();
  const { onSuccessToast, onErrorToast } = useCustomToast();
  const { mutateAsync } = useCreateDailyPricing();

  const lastUpdatedFormatted = dailyPricingData
    ? formatDateWithTimeZone.format(new Date(dailyPricingData.lastUpdated))
    : "-";

  const methods = useForm<DailyPricingFields>({
    resolver: yupResolver(dailyPricingFormSchema),
    defaultValues: {
      dailyPricing:
        dailyPricingData && dailyPricingData.pricing.length > 0
          ? dailyPricingData?.pricing.map((regionPrice) => ({
              regionId: regionPrice.regionId,
              oldCropFutures: {
                label: `${regionPrice.oldCropFuturesFriendlySymbol}`,
                secondLabel: `(${regionPrice.oldCropFuturesSymbol})`,
                value: regionPrice.oldCropFuturesSymbol,
              },
              oldCropBasis: formatNumberWithDecimals(regionPrice.oldCropBasis),
              newCropBasis: formatNumberWithDecimals(
                regionPrice.newCropBasis as number
              ),
            }))
          : regions?.map((region) => ({
              regionId: region.id,
              oldCropFutures: undefined,
              oldCropBasis: "",
              newCropBasis: "",
            })),
      newCropFutures: newCropFuturesData
        ? {
            label: `${newCropFuturesData.friendlySymbol}`,
            secondLabel: `(${newCropFuturesData.symbol})`,
            value: newCropFuturesData.symbol,
          }
        : undefined,
    },
  });

  const {
    watch,
    handleSubmit,
    formState: { isDirty, isSubmitting },
  } = methods;

  const {
    alert: confirmAlert,
    onOpen: onConfirm,
    onClose,
    isOpen: confirmAlertIsOpen,
  } = useConfirmationAlert({
    title: "Submit Daily Prices?",
    msg: `Once submitted, these prices will be used to calculate
          the Cash Price for new contracts. Do you wish to continue?`,
    confirmButtonText: `Yes`,
    onConfirmAsync: () => {
      const formData = watch();
      const data = transformDailyPricingData(formData);

      return mutateAsync(data)
        .then(() => {
          navigate(`/${dashboardPaths.basePath}`);
          onSuccessToast({
            message: "Submitted Daily Pricing successfully",
          });
        })
        .catch((err) => {
          if (err?.response?.data?.error === "Invalid futures symbols") {
            onErrorToast({
              message: `A futures month/year is no longer valid.
                        Please check all futures symbols are valid.`,
            });
          } else {
            onErrorToast({
              message: "Failed to create daily pricing",
            });
          }
          onClose();
        });
    },
  });

  const onCancel = useCallback(() => {
    navigate(`/${dashboardPaths.basePath}`);
  }, [navigate]);

  const dailyPricingStackDirection = useBreakpointValue(
    DAILY_PRICING_STACK_DIRECTION,
    { ssr: false }
  );

  return (
    <FormProvider {...methods}>
      <Center px={FORM_CONTENT_PX} w="100%">
        <ContentWrapper>
          <FormStack onSubmit={handleSubmit(onConfirm)}>
            {confirmAlert}
            <Stack direction={dailyPricingStackDirection} spacing={0}>
              <Stack
                direction={dailyPricingStackDirection}
                spacing={DAILY_PRICING_HEADING_SPACING}
                alignItems={DAILY_PRICING_HEADING_ALIGNMENT}
              >
                <TextHeading headingType="h3">Daily Pricing</TextHeading>
                <Text color="greyFactor.4" fontWeight="bold" fontSize="xs">
                  Last updated: {lastUpdatedFormatted}
                </Text>
              </Stack>
              <Spacer />
              <FormButtonGroup>
                <Button size="md" variant="outline" onClick={onCancel}>
                  Cancel
                </Button>
                <SubmitButton
                  submitDisabled={!isDirty || confirmAlertIsOpen}
                  isSubmitting={isSubmitting}
                  buttonText="Submit Pricing"
                />
              </FormButtonGroup>
            </Stack>
            <Stack spacing={7}>
              <Stack
                direction={{ base: "column", md: "row" }}
                justifyContent="space-between"
              >
                <TextHeading>Details</TextHeading>
                <NewCropFuturesSelector />
              </Stack>
              <DailyPricingFormInputs regions={regions} />
            </Stack>
          </FormStack>
        </ContentWrapper>
      </Center>
    </FormProvider>
  );
};
const DailyPricingPage = () => {
  const { data: newCropFuturesData, isLoading: isNewCropFuturesDataLoading } =
    useGetNewCropFutures();
  const { data: dailyPricingData, isLoading: isDailyPricingLoading } =
    useGetDailyPricing();
  const { data: regions, isLoading: isRegionsLoading } = useRegionOptions();

  return (
    <WithSpinner
      isLoading={
        isDailyPricingLoading || isRegionsLoading || isNewCropFuturesDataLoading
      }
    >
      <DailyPricingPageForm
        newCropFuturesData={newCropFuturesData}
        dailyPricingData={dailyPricingData}
        regions={regions}
      />
    </WithSpinner>
  );
};

export default DailyPricingPage;
