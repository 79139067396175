import { useState } from "react";
import { Outlet } from "react-router-dom";
import { Box, Center, Stack } from "@chakra-ui/react";
import { DESKTOP_HEADER_PADDING, PageHeader } from "components/page-components";
import { contentAreaWidthProps } from "components/ContentWrapper";

const CsvUploadPage = ({ heading }: { heading: string }) => {
  const [file, setFile] = useState<File>();

  return (
    <>
      <PageHeader heading={heading} px={DESKTOP_HEADER_PADDING} />
      <Box bg="white" flex={1} py={10}>
        <Center px={DESKTOP_HEADER_PADDING}>
          <Box {...contentAreaWidthProps}>
            <Stack spacing={10}>
              <Outlet context={[file, setFile]} />
            </Stack>
          </Box>
        </Center>
      </Box>
    </>
  );
};

export default CsvUploadPage;
