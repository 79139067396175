import { useMutation } from "@tanstack/react-query";

import { CreateDelayedPricingContractRequest } from "api";
import { usePricingOptionService } from "contexts/ServiceProvider";
import { MutationHookResult } from "hooks/hookTypeHelpers";
import PricingOptionService from "services/PricingOptionService";
import useDashboardRefetchQueries from "hooks/crop-year/useDashboardRefetchQueries";

type CreateDelayedPricingContractMutationResult = MutationHookResult<
  Awaited<ReturnType<PricingOptionService["createDelayedPricingContract"]>>,
  CreateDelayedPricingContractRequest
>;

export const CREATE_DELAYED_PRICING_CONTRACT_QUERY_KEY =
  "create-delayed-pricing-contract";

const useCreateDelayedPricingContract = (
  cropYearId: string | undefined,
  farmId: string | undefined
): CreateDelayedPricingContractMutationResult => {
  const pricingOptionService = usePricingOptionService();
  const { refetch } = useDashboardRefetchQueries();

  return useMutation({
    mutationFn: (delayedPricingContract) => {
      if (cropYearId && farmId) {
        return delayedPricingContract
          ? pricingOptionService.createDelayedPricingContract({
              cropYearId,
              farmId,
              delayedPricingContract,
            })
          : Promise.reject(
              "Must provide valid delayed pricing contract information"
            );
      } else {
        return Promise.reject("Missing crop year id and farm id");
      }
    },
    mutationKey: [
      CREATE_DELAYED_PRICING_CONTRACT_QUERY_KEY,
      { cropYearId, farmId },
    ],
    onSuccess: refetch,
  });
};

export default useCreateDelayedPricingContract;
