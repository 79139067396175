import { ChangeEventHandler } from "react";

import {
  Box,
  Button,
  Center,
  HStack,
  Input,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";

import UploadBox from "components/UploadBox";
import TextHeading from "components/TextHeading";
import { FileRequirementsProps } from "pages/csv-uploads/farm-documents/FarmDocumentsFileSelect/FarmDocumentsFileSelect";

const CsvUploadBox = ({
  fileRequirements,
  onChange,
  isLoading,
}: {
  fileRequirements: FileRequirementsProps;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  isLoading?: boolean;
}) => (
  <UploadBox h="unset" py="134px" bg="gray.50" borderColor="gray.200">
    <Center h="100%" flexDirection="column">
      <Stack spacing={2}>
        <Stack spacing={9} maxW="387px">
          <Box>
            <Input
              accept=".csv"
              type="file"
              id="csv-select"
              display="none"
              onChange={onChange}
            />
            <Button
              as="label"
              w="100%"
              htmlFor="csv-select"
              isLoading={isLoading}
              isDisabled={isLoading}
            >
              Browse Files
            </Button>
          </Box>
          <TextHeading headingType="h5" textAlign="center">
            CSV Upload Guide
          </TextHeading>
        </Stack>
        <UnorderedList pl={4} data-testid="file-requirements">
          {fileRequirements?.map(({ message, prefix }, index) => {
            return (
              <ListItem key={`requirement-${index}`} maxW="600px">
                {prefix ? (
                  <>
                    <HStack>
                      <Text fontWeight="bold">{prefix}</Text>
                      <Text>{message}</Text>
                    </HStack>
                  </>
                ) : (
                  message
                )}
              </ListItem>
            );
          })}
        </UnorderedList>
      </Stack>
    </Center>
  </UploadBox>
);

export default CsvUploadBox;
