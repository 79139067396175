import { ReactElement, SetStateAction, useCallback, useMemo } from "react";

import { SelectOption } from "components/Select";
import useCropYears from "hooks/crop-year/useCropYears";
import { useCropYearContext } from "contexts/CropYearContext";
import YearSelect from "components/YearSelect";

const CropYearSelect = (): ReactElement => {
  const { data, isLoading } = useCropYears();
  const { cropYear, setCropYear } = useCropYearContext();

  const options: SelectOption[] = useMemo(
    () =>
      data?.cropYears.map((cropYear) => ({
        label: `${cropYear.year}`,
        value: cropYear.id,
      })) ?? [],
    [data?.cropYears]
  );

  const onSelectOption = useCallback(
    (option: SetStateAction<SelectOption | undefined>) => {
      const nextCropYear = data?.cropYears.find(
        (_cropYear) =>
          _cropYear.id === (option as SelectOption | undefined)?.value
      );
      if (nextCropYear && setCropYear) {
        setCropYear(nextCropYear);
      }
    },
    [data?.cropYears, setCropYear]
  );

  return (
    <YearSelect
      options={options}
      yearId={cropYear?.id}
      name="cropYearSelect"
      isLoading={isLoading}
      setSelectedOption={onSelectOption}
    />
  );
};

export default CropYearSelect;
