import { useMemo } from "react";
import { VStack, Button, Text, HStack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { addDays } from "date-fns";
import { FormProvider, useForm } from "react-hook-form";
import { QueryObserverResult } from "@tanstack/react-query";

import {
  CreateDelayedPricingContractRequest,
  CropYearDetailResponse,
  DelayedContractCurrentPricingResponse,
} from "api";
import FuturesPriceTimeoutError from "components/FuturesPriceTimeoutError";
import { FormInputs, MoneyInput } from "components/inputs";
import DateInput from "components/inputs/DateInput";
import DecimalNumberInput from "components/inputs/DecimalNumberInput";
import { DEFER_PAYMENT_OPTION_NO } from "components/inputs/DeferPaymentSelectInput";
import { DeferPaymentInputs } from "components/inputs/DeferPaymentInputs";
import { LabelTextWithHelper } from "components/inputs/InputLabel";
import { SelectOption } from "components/Select";
import SubmitButton from "components/SubmitButton";
import { createDelayedPricingFormSchema } from "forms/delayed-pricing/delayedPricingFormSchema";
import useConfirmationAlert from "hooks/alerts/useConfirmationAlert";
import useFarmDetail from "hooks/farm/farm-detail/useFarmDetail";
import useCreateDelayedPricingContract from "hooks/pricing-option/useCreateDelayedPricingContract";
import useCustomToast from "hooks/useCustomToast";
import useFormValidation from "hooks/useFormValidation";
import useNavigateWithQuery from "hooks/useNavigateWithQuery";
import { useScrollIntoView } from "hooks/utility/useScrollIntoView";
import useSelectedFarmId from "hooks/useSelectedFarmId";
import DelayedPricingContractStats from "pages/dashboard/DelayedPricing/components/DelayedPricingStats/DelayedPricingStats";
import DisplayLabelValue from "components/PricingStats/DisplayLabelValue";
import dashboardPaths from "routes/dashboard/dashboardPaths";
import { BaseApiError } from "services/apiHelpers";
import { ISODateWithoutTime, parseIsoDate } from "utils/dateUtils";
import { defaultDeferPayStartDate } from "utils/defaultDeferPayStartDate";
import { formatNumberWithDecimals } from "utils/formatFunctions";
import { formatNumberWithDecimalsOrDisplayHyphen } from "utils/numberUtils";
import { getIsDeferPayment } from "utils/getIsDeferPayment";

export type DelayedPricingFormData = {
  tonsToSell: string;
  futuresPrice?: string;
  basis?: number;
  deferPayment: SelectOption;
  defermentDate: Date;
  dailyPricingId: string;
  dateOfContract?: Date | null;
  cashPrice?: number;
  isManuallyCreated: boolean;
};

const transformDelayedPricingData = (
  data: RemoveUndefined<DelayedPricingFormData>,
  deferPayment: boolean,
  futuresSymbol: string,
  isManuallyCreated: boolean
): CreateDelayedPricingContractRequest => ({
  dateOfContract: isManuallyCreated
    ? ISODateWithoutTime(data.dateOfContract)
    : undefined,
  cashPrice: data.cashPrice,
  tonsToSell: Number(data.tonsToSell ?? ""),
  futuresPrice: !isManuallyCreated ? Number(data.futuresPrice) : undefined,
  futuresSymbol,
  basis: !isManuallyCreated ? data.basis : undefined,
  deferPayment,
  defermentDate: deferPayment
    ? ISODateWithoutTime(data.defermentDate)
    : undefined,
  dailyPricingId: data.dailyPricingId,
  isManuallyCreated,
});

export const DIALOG_OFFSET = "19rem";

type formFields = Array<keyof DelayedPricingFormData>;

const baseFieldsToWatch: formFields = [
  "dailyPricingId",
  "deferPayment",
  "defermentDate",
  "tonsToSell",
];

const autoCreatedFieldsToWatch: formFields = [
  ...baseFieldsToWatch,
  "basis",
  "futuresPrice",
];

const manuallyCreatedFieldsToWatch: formFields = [
  ...baseFieldsToWatch,
  "dateOfContract",
];

const DelayedPricingForm = ({
  cropYearDetail,
  currentDelayedPricingData,
  navigateToFarmView,
  navigateToAllPricingView,
  isCurrentDelayedPricingStale,
  refetchCurrentDelayedPricing,
  isManuallyCreated = false,
}: {
  cropYearDetail: CropYearDetailResponse | undefined;
  currentDelayedPricingData: DelayedContractCurrentPricingResponse | undefined;
  navigateToFarmView: () => void;
  navigateToAllPricingView: () => void;
  isCurrentDelayedPricingStale: boolean;
  refetchCurrentDelayedPricing: () => Promise<
    QueryObserverResult<DelayedContractCurrentPricingResponse, BaseApiError>
  >;
  isManuallyCreated?: boolean;
}) => {
  const [selectedFarmId] = useSelectedFarmId();
  const { data: farmDetails } = useFarmDetail(selectedFarmId);
  const navigate = useNavigateWithQuery();
  const { onSuccessToast, onErrorToast } = useCustomToast();
  useScrollIntoView(isCurrentDelayedPricingStale, "#futures-timeout-error");

  const { mutateAsync } = useCreateDelayedPricingContract(
    cropYearDetail?.id,
    selectedFarmId
  );

  const tomorrowsDate = addDays(new Date(), 1);
  const displayDeferPayStartDate = defaultDeferPayStartDate({
    cropYearDefermentDate: parseIsoDate(
      cropYearDetail?.defermentDateStart as string
    ),
    tomorrowsDate,
  });

  const baseDefaultValues = {
    tonsToSell: "",
    deferPayment: DEFER_PAYMENT_OPTION_NO,
    defermentDate: displayDeferPayStartDate,
    dailyPricingId: currentDelayedPricingData?.dailyPricingId,
  };

  const autoCreatedDelayedPricingDefaultValues = {
    ...baseDefaultValues,
    futuresPrice: currentDelayedPricingData?.futuresPrice,
    basis: currentDelayedPricingData?.basis,
  };

  const manuallyCreatedDelayedPricingDefaultValues = {
    ...baseDefaultValues,
    dateOfContract: new Date(),
  };

  const methods = useForm<DelayedPricingFormData>({
    resolver: yupResolver(
      createDelayedPricingFormSchema(
        currentDelayedPricingData?.tonsAvailableToPrice,
        isManuallyCreated
      )
    ),
    defaultValues: isManuallyCreated
      ? manuallyCreatedDelayedPricingDefaultValues
      : autoCreatedDelayedPricingDefaultValues,
  });

  const {
    handleSubmit,
    watch,
    formState: { isDirty, isSubmitting },
  } = methods;

  const requiredFieldsNotFilled = useFormValidation(
    !isManuallyCreated
      ? watch(autoCreatedFieldsToWatch)
      : watch(manuallyCreatedFieldsToWatch)
  );

  const {
    alert: confirmAlert,
    onOpen: onConfirm,
    onClose,
    isOpen: confirmAlertIsOpen,
  } = useConfirmationAlert({
    title: "Are you sure you want to create this contract?",
    msg: "",
    confirmButtonText: `Yes`,
    secondaryButtonText: "Back",
    onConfirmAsync: () => {
      const formData = watch() as RemoveUndefined<DelayedPricingFormData>;

      const data = transformDelayedPricingData(
        formData,
        getIsDeferPayment(formData.deferPayment.value),
        currentDelayedPricingData?.futuresSymbol ?? "",
        isManuallyCreated
      );

      return mutateAsync(data)
        .then(() => {
          !isManuallyCreated
            ? navigate(`/${dashboardPaths.basePath}`)
            : navigate(
                `${dashboardPaths.basePath}/${dashboardPaths.children.pricing}`
              );
          onSuccessToast({
            title: "Successfully Saved",
            message: "Delayed pricing contract created successfully",
          });
        })
        .catch(() => {
          onErrorToast({
            message: `Failed to create delayed pricing contract`,
          });
          onClose();
        });
    },
    dialogOffset: DIALOG_OFFSET,
  });

  const tonsToSell = watch("tonsToSell");
  const calculatedTonsLeft =
    Number(currentDelayedPricingData?.tonsAvailableToPrice ?? 0) -
    Number(tonsToSell ?? 0);
  const dateOfContract = watch("dateOfContract");
  const cashPrice = watch("cashPrice");

  const submitButton = useMemo(
    () => (
      <SubmitButton
        submitDisabled={
          requiredFieldsNotFilled || !isDirty || confirmAlertIsOpen
        }
        isSubmitting={isSubmitting}
        buttonText="Submit"
        w="100%"
      />
    ),
    [confirmAlertIsOpen, isDirty, isSubmitting, requiredFieldsNotFilled]
  );

  const timedOutErrorBoxOrSubmitButton = useMemo(
    () =>
      isCurrentDelayedPricingStale ? (
        <FuturesPriceTimeoutError
          refetchCurrentPricing={refetchCurrentDelayedPricing}
        />
      ) : (
        submitButton
      ),
    [isCurrentDelayedPricingStale, refetchCurrentDelayedPricing, submitButton]
  );

  return (
    <VStack align="left" w="100%">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onConfirm)}>
          {confirmAlert}
          <VStack align="left" spacing={4}>
            <VStack align="left" spacing={4}>
              {isManuallyCreated ? (
                <FormInputs
                  inputs={[
                    {
                      id: "dateOfContract",
                      label: "Date of Contract",
                      component: DateInput,
                      shouldRegister: false,
                      isRequired: true,
                    },
                  ]}
                />
              ) : null}
              <DisplayLabelValue
                label="Tons Available to Price"
                value={formatNumberWithDecimalsOrDisplayHyphen(
                  currentDelayedPricingData?.tonsAvailableToPrice,
                  8
                )}
              />
              <VStack spacing={0} align="left" w="100%" pt={3}>
                <DecimalNumberInput
                  id="tonsToSell"
                  label="Tons To Sell"
                  isRequired
                  clearZeroValue={true}
                  defaultEmptyValueToZero={true}
                  maxDecimalNumbers={8}
                />
                <HStack justifyContent="space-between">
                  <Text color="grayFactor.4" fontSize="xs">
                    Tons left to price
                  </Text>
                  <Text
                    fontWeight="bold"
                    color="grayFactor.4"
                    maxW="70%"
                    isTruncated
                    title={formatNumberWithDecimals(calculatedTonsLeft, 8)}
                    fontSize="sm"
                  >
                    {formatNumberWithDecimals(calculatedTonsLeft, 8)}
                  </Text>
                </HStack>
              </VStack>
              <DeferPaymentInputs
                isDeferPayment={getIsDeferPayment(watch("deferPayment").value)}
                minDate={displayDeferPayStartDate}
              />
              {isManuallyCreated ? (
                <MoneyInput
                  id="cashPrice"
                  label={
                    <LabelTextWithHelper
                      label="Cash Price"
                      helper={`${farmDetails?.region?.name} Region`}
                    />
                  }
                  isRequired
                />
              ) : null}
            </VStack>
            <DelayedPricingContractStats
              currentDelayedPricingData={currentDelayedPricingData}
              tonsToSell={Number(tonsToSell ?? 0)}
              dateOfContract={dateOfContract}
              cashPrice={cashPrice}
              isManuallyCreated={isManuallyCreated}
            />
            <VStack w="100%">
              {isManuallyCreated
                ? submitButton
                : timedOutErrorBoxOrSubmitButton}
              <Button
                variant="outline"
                size="md"
                onClick={
                  !isManuallyCreated
                    ? navigateToFarmView
                    : navigateToAllPricingView
                }
                w="100%"
              >
                Cancel
              </Button>
            </VStack>
          </VStack>
        </form>
      </FormProvider>
    </VStack>
  );
};

export default DelayedPricingForm;
