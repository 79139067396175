import { SelectOption } from "components/Select";
import { formatDate } from "utils/dateUtils";
import { getIsDeferPayment } from "utils/getIsDeferPayment";
import * as yup from "yup";
import { selectOptionSchema } from "./selectOptionSchema";

export const baseForwardContractSchema = (
  defaultDefermentDate: Date,
  remainingPercentAvailable: number
) =>
  yup.object({
    percentageToSell: yup
      .number()
      .when({
        is: (value: number) => value > (remainingPercentAvailable ?? 0),
        then: (schema) =>
          schema.max(
            remainingPercentAvailable ?? 0,
            `${remainingPercentAvailable}% available to sell`
          ),
      })
      .transform((value) => (isNaN(value) ? undefined : value))
      .moreThan(0, "Percentage to sell must be greater than 0")
      .max(100, "Percentage to sell must be less than 100")
      .required("Percentage to sell is required"),
    deferPayment: selectOptionSchema,
    defermentDate: yup.date().when("deferPayment", {
      is: (value: SelectOption) => getIsDeferPayment(value.value),
      then: (schema) =>
        schema
          .required("Deferment Date is required")
          .test(
            "is-on-or-after-deferment-start",
            `Deferment date must be on or after ${formatDate(
              defaultDefermentDate
            )}`,
            (value) => !!value && new Date(value) >= defaultDefermentDate
          ),
    }),
  });
