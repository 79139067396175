import { Button, Spacer, Stack, Text } from "@chakra-ui/react";
import {
  CardHeader,
  CardBody,
  ResponsiveCard,
  CardFooter,
} from "components/Card";
import BackToLogin from "components/BackToLogin";
import { ReactElement } from "react";
import { DesktopOnly, MobileOnly } from "components/BreakpointVisibility";
import { useForgotPasswordForm } from "contexts/ForgotPasswordFormContext";
import { CARD_MIN_WIDTH } from "utils/styleHelpers";

const CheckEmailCard = (): ReactElement => {
  const { email, setEmail } = useForgotPasswordForm();

  return (
    <Stack>
      <ResponsiveCard minW={CARD_MIN_WIDTH}>
        <CardHeader>Check your email</CardHeader>
        <CardBody>
          <Stack w="100%" spacing={6}>
            <Stack spacing={0}>
              <Text>We sent a password reset link to</Text>
              <Text fontWeight="bold">{email}.</Text>
            </Stack>

            <Button
              variant="link"
              alignSelf="flex-end"
              color="brand.800"
              onClick={() => setEmail(null)}
            >
              Re-enter your email address
            </Button>
          </Stack>
        </CardBody>
        <MobileOnly>
          <CardFooter>
            <BackToLogin pt={0} />
          </CardFooter>
        </MobileOnly>
      </ResponsiveCard>
      <DesktopOnly>
        <BackToLogin />
      </DesktopOnly>
      <Spacer />
    </Stack>
  );
};

export default CheckEmailCard;
