import { Text, Tooltip } from "@chakra-ui/react";

import {
  NameWithPhoneColumn,
  UserStatusColumn,
} from "components/table-columns";
import { ColumnProps, TableData } from "components/SortableTable";
import { UserListSortColumns } from "services/UserService";
import { UserListItem } from "hooks/user/useUsersList";

export const adminUserListTableDataMapper = (
  data: UserListItem[]
): TableData[] =>
  data.map(
    ({
      id,
      name,
      phoneNumber,
      profilePicture,
      roles,
      email,
      status,
      farms,
    }) => {
      const farm = (farms?.length ?? 0) > 1 ? "Multiple" : farms?.[0]?.farmName;

      return {
        id,
        name: (
          <NameWithPhoneColumn
            name={name}
            phoneNumber={phoneNumber}
            avatarImageSrc={profilePicture?.src}
          />
        ),
        farm: (
          <Tooltip hasArrow label={farm}>
            <Text variant="truncated">{farm ?? ""}</Text>
          </Tooltip>
        ),
        role: roles?.[0]?.role.displayName ?? "",
        email: (
          <Tooltip hasArrow label={email}>
            <Text variant="truncated">{email ?? ""}</Text>
          </Tooltip>
        ),
        status: <UserStatusColumn status={status} />,
      };
    }
  );

type ColumnList = ColumnProps<UserListSortColumns>[];

export const baseAdminUsersListColumns: ColumnList = [
  { heading: "Name", id: "name", sortKey: "firstName" },
  { heading: "Status", id: "status", sortKey: "status" },
];

export const desktopOnlyAdminUsersListColumns: ColumnList = [
  { heading: "Farm", id: "farm" },
  { heading: "Role", id: "role" },
  { heading: "Email", id: "email", sortKey: "email" },
];

export const desktopAdminUsersListColumns: ColumnList = [
  baseAdminUsersListColumns[0],
  ...desktopOnlyAdminUsersListColumns,
  baseAdminUsersListColumns[1],
];
