import { formatNumberWithDecimals } from "./formatFunctions";

export const exceedsMaxDecimalDigits = (
  value: string,
  maxDigits: number
): boolean => {
  const splitValue = value.split(".");

  return (
    value.includes(".") &&
    (splitValue.length > 2 || splitValue[1]?.length > maxDigits)
  );
};

export const formatNumberWithDecimalsOrDisplayHyphen = (
  value: number | undefined,
  decimalPlaces = 2
) =>
  value === undefined ? "-" : formatNumberWithDecimals(value, decimalPlaces);
