const haylagePaths = {
  basePath: "haylage",
  children: {
    allFarms: "all-farms",
    farmDocuments: "documents",
    addFarms: "add-farms",
  },
};

export default haylagePaths;
