import { PropsWithChildren, ReactElement, useEffect } from "react";

import { Center, Spinner } from "@chakra-ui/react";

import useGetHaylageYears from "hooks/api/haylage-year/useGetHaylageYears";
import useAccessToken from "hooks/auth/useAccessToken";
import useSelectedHaylageYearId from "hooks/useSelectedHaylageYearId";

const HaylageYearProvider = ({ children }: PropsWithChildren): ReactElement => {
  const { accessToken } = useAccessToken();
  const [haylageYearId, setHaylageYearId] = useSelectedHaylageYearId();

  const { data, isLoading } = useGetHaylageYears(!!accessToken);
  useEffect(() => {
    const haylageYears = data?.haylageYears;
    if (
      (!haylageYearId ||
        (haylageYearId &&
          !haylageYears?.find(
            (haylageYear) => haylageYear.id === haylageYearId
          ))) &&
      haylageYears?.[0]
    ) {
      setHaylageYearId(haylageYears[0].id);
    }
  }, [haylageYearId, data?.haylageYears, setHaylageYearId]);

  if (isLoading) {
    return (
      <Center minH="100vh">
        <Spinner />
      </Center>
    );
  }

  return <>{children}</>;
};

export default HaylageYearProvider;
