import { Tab } from "@chakra-ui/react";
import { PropsWithChildren } from "react";

const StyledTab = ({
  children,
  isDisabled,
}: { isDisabled?: boolean } & PropsWithChildren) => (
  <Tab
    fontWeight="bold"
    padding="4px 0px 9px 0px"
    isDisabled={isDisabled}
    w="194px"
  >
    {children}
  </Tab>
);

export const DetailsTab = () => <StyledTab>Farm Details</StyledTab>;

export const DocumentsTab = ({ isDisabled }: { isDisabled: boolean }) => (
  <StyledTab isDisabled={isDisabled}>Documents</StyledTab>
);
