import { Divider } from "@chakra-ui/react";
import { ReactElement } from "react";

import { PAGE_HEADER_PX } from "components/page-components";
import DocumentsList from "components/DocumentsList";
import useSilageYearFarmDocuments from "hooks/crop-year/useSilageYearFarmDocuments";
import useSelectedCropYearId from "hooks/useSelectedCropYearId";
import useSelectedFarmId from "hooks/useSelectedFarmId";
import {
  SilageDashboardTabHeader,
  SilageDashboardTabStack,
} from "layouts/SilageDashboardTabLayout";
import { mobileStyleBreakpoint } from "utils/styleHelpers";

const LIST_PX = mobileStyleBreakpoint(6, 0);

const DocumentsListTab = (): ReactElement => {
  const [farmId] = useSelectedFarmId();
  const [cropYearId] = useSelectedCropYearId();

  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useSilageYearFarmDocuments(cropYearId, farmId);

  return (
    <SilageDashboardTabStack>
      <SilageDashboardTabHeader heading="Documents" px={PAGE_HEADER_PX} />

      <SilageDashboardTabStack px={LIST_PX}>
        <Divider borderBottomWidth="2px" />

        <DocumentsList
          farmId={farmId}
          pages={data?.pages}
          isLoading={isLoading}
          isFetchingNextPage={isFetchingNextPage}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
        />
      </SilageDashboardTabStack>
    </SilageDashboardTabStack>
  );
};

export default DocumentsListTab;
