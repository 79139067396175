import { PropsWithChildren, useMemo } from "react";

import {
  Badge,
  HStack,
  Spacer,
  StackProps,
  Text,
  VStack,
} from "@chakra-ui/react";

import TextHeading, { TextHeadingProps } from "components/TextHeading";
import { getStatusObject } from "utils/getStatusObject";
import { mobileStyleBreakpoint } from "utils/styleHelpers";

export const PAGE_HEADER_MAX_WIDTH = mobileStyleBreakpoint("342px", "unset");

const FarmInfoPageHeader = ({
  headingType = "h3",
  farmName,
  displayStatusBadge,
  status,
  children,
  ...rest
}: PropsWithChildren<
  StackProps & {
    headingType?: TextHeadingProps["headingType"];
    farmName: string;
    status: string;
    displayStatusBadge: boolean | undefined;
  }
>) => {
  const statusBadge = useMemo(() => {
    return (
      displayStatusBadge && (
        <Badge variant={getStatusObject(status).value} size="100px">
          {getStatusObject(status).displayName}
        </Badge>
      )
    );
  }, [displayStatusBadge, status]);

  return (
    <HStack flexWrap="wrap" spacing={0} {...rest}>
      <VStack display="inline" justifyContent="start">
        <HStack spacing={4}>
          <TextHeading headingType={headingType}>Farm Information</TextHeading>
          {statusBadge}
        </HStack>
        <Text
          maxW={PAGE_HEADER_MAX_WIDTH}
          fontSize="lg"
          fontWeight="bold"
          color="grayFactor.4"
        >
          {farmName}
        </Text>
      </VStack>
      <Spacer />
      {children}
    </HStack>
  );
};

export default FarmInfoPageHeader;
