import { ComponentStyleConfig } from "@chakra-ui/react";
import textStyles from "../textStyles";

const Input: ComponentStyleConfig = {
  baseStyle: {
    field: {
      _placeholder: {
        ...textStyles["caption"],
      },
    },
  },
  defaultProps: {
    focusBorderColor: "brand.800",
    errorBorderColor: "errorRed",
  },
  variants: {
    outline: {
      field: {
        ...textStyles["caption"],
      },
    },
  },
};

export default Input;
