import { ReactElement } from "react";
import { Divider, Stack, Text } from "@chakra-ui/react";

import {
  FormInputs,
  StandardInput,
  DairySelectInput,
  EstimatedYieldInput,
  TotalAcresInput,
} from "components/inputs";
import TextHeading from "components/TextHeading";
import ActualYieldInput from "components/inputs/ActualYieldInput";
import DistanceToSiteInput from "components/inputs/DistanceToSiteInput";
import { useCropYearContext } from "contexts/CropYearContext";
import useUserRoleFlags from "hooks/auth/useUserRoleFlags";

export const FieldDetailInputs = ({
  isDisabled,
}: {
  isDisabled: boolean;
}): ReactElement => {
  const { isEmployee, isEmployeeAdmin } = useUserRoleFlags();
  const { isPreHarvest } = useCropYearContext();

  const displayPreHarvestYieldReportingSectionForRiverviewUser =
    isEmployee && isPreHarvest;

  const displayPostHarvestYieldReportingSection = !isPreHarvest;

  return (
    <Stack>
      <FormInputs
        inputs={[
          {
            id: "fieldNumber",
            label: "Field Number",
            component: StandardInput,
            isDisabled: !isEmployee || isDisabled,
            isRequired: true,
          },
          {
            id: "totalAcres",
            label: "Total acres (before split)",
            component: TotalAcresInput,
            isDisabled: !isEmployee || isDisabled,
            type: "number",
            step: 0.01,
            isRequired: true,
          },
          {
            id: "dairySite",
            label: "Dairy Site",
            component: DairySelectInput,
            shouldRegister: false,
            isDisabled: !isEmployee || isDisabled,
          },
          {
            id: "legalDescription",
            label: "Field legal description",
            component: StandardInput,
            isDisabled: !isEmployee || isDisabled,
          },
          {
            id: "distanceToSite",
            label: "Distance to nearest site (mi)",
            component: DistanceToSiteInput,
            isDisabled: isPreHarvest ? !isEmployee : !isEmployeeAdmin,
            type: "number",
            step: 0.01,
          },
        ]}
      />
      <Text fontSize="xs" color="steelGray">
        Distance (mi) will default to zero if left empty during switch to
        post-harvest.
      </Text>
      <FormInputs
        inputs={[
          {
            id: "commonName",
            label: "Field Common Name",
            component: StandardInput,
            isDisabled: !isEmployee || isDisabled,
          },
        ]}
      />
      {displayPreHarvestYieldReportingSectionForRiverviewUser && (
        <>
          <Divider pt={2} />
          <TextHeading as="h4" pt={2} pb={6}>
            Yield reporting
          </TextHeading>
          <FormInputs
            inputs={[
              {
                id: "estimatedYieldPerAcre",
                label: "Estimated tons per acre",
                component: EstimatedYieldInput,
                isRequired: true,
                isDisabled: true,
              },
            ]}
          />
          <ActualYieldInput
            id={"actualYield"}
            label={"Actual yield (tons)"}
            isDisabled={isDisabled}
          />
        </>
      )}
      {displayPostHarvestYieldReportingSection && (
        <>
          <Divider pt={2} />
          <TextHeading as="h4" pt={2} pb={6}>
            Yield reporting
          </TextHeading>
          <FormInputs
            inputs={[
              {
                id: "tonsPerAcre",
                label: "Tons per acre",
                component: TotalAcresInput,
                isDisabled: true,
              },
            ]}
          />
          <ActualYieldInput
            id={"actualYield"}
            label={"Actual yield (tons)"}
            isDisabled={!isEmployeeAdmin}
            isRequired={!isPreHarvest}
          />
          <Text color="greyFactor.3" fontSize="xs">
            Total actual tons for the field
          </Text>
        </>
      )}
    </Stack>
  );
};
