import { useCallback, useMemo } from "react";
import WithSpinner from "components/WithSpinner";
import {
  CSV_FILE_SIZE_REQUIREMENT,
  CSV_FILE_TYPE_REQUIREMENT,
} from "constants/csvRequirements";
import useGetFarmDocumentsHeaders from "hooks/csv-upload/useGetFarmDocumentsHeaders";
import useUploadFarmDocumentsCsv from "hooks/csv-upload/useUploadFarmDocumentsCsv";
import CsvFileSelect from "pages/csv-uploads/CsvFileSelect";
import adminPaths from "routes/admin/adminPaths";

export type FileRequirementsProps = {
  prefix?: string;
  message: string;
}[];

const CSV_UPLOAD_NOTIFICATION_TO_FARM_REMINDER_PREFIX = "Reminder:";
const CSV_UPLOAD_NOTIFICATION_TO_FARM_REMINDER =
  "Each link that you upload will send a notification to the farm.";

export const farmDocumentLinkCsvFileRequirements: FileRequirementsProps = [
  { message: CSV_FILE_TYPE_REQUIREMENT },
  { message: CSV_FILE_SIZE_REQUIREMENT },
  {
    prefix: CSV_UPLOAD_NOTIFICATION_TO_FARM_REMINDER_PREFIX,
    message: CSV_UPLOAD_NOTIFICATION_TO_FARM_REMINDER,
  },
];

const FarmDocumentsFileSelect = () => {
  const { data, isLoading: isFarmCsvRequiredHeadersLoading } =
    useGetFarmDocumentsHeaders();
  const { mutateAsync, isPending: isLoading } = useUploadFarmDocumentsCsv();
  const onUploadCsvFile = useCallback(
    (file: File) => mutateAsync({ csvFile: file }),
    [mutateAsync]
  );

  const farmDocumentsUploadCsvFileRequirements: FileRequirementsProps =
    useMemo(() => {
      const farmDocumentsCsvRequiredColumns = `File must contain, in order: ${data
        ?.slice(0, data?.length - 1)
        .join(", ")}, and ${data?.[data?.length - 1]}`;

      return [
        farmDocumentLinkCsvFileRequirements[0],
        farmDocumentLinkCsvFileRequirements[1],
        { message: farmDocumentsCsvRequiredColumns },
        farmDocumentLinkCsvFileRequirements[2],
      ];
    }, [data]);

  return (
    <WithSpinner
      isLoading={isFarmCsvRequiredHeadersLoading}
      centerProps={{ minH: "600px" }}
    >
      <CsvFileSelect
        fileRequirements={farmDocumentsUploadCsvFileRequirements}
        linkText="Back to Farms"
        linkTo={`/${adminPaths.basePath}/${adminPaths.children.farms}`}
        totalSteps={2}
        isLoading={isLoading}
        onUploadCsvFile={onUploadCsvFile}
      />
    </WithSpinner>
  );
};

export default FarmDocumentsFileSelect;
