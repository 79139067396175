import { ReactElement, useCallback } from "react";
import { Navigate, useSearchParams } from "react-router-dom";

import FormSkeletonLoadingState from "components/FormSkeletonLoadingState";
import { useCropYearContext } from "contexts/CropYearContext";
import FieldDetailForm, {
  CreateFieldFormData,
  formDataToCreateFieldRequest,
} from "forms/field-detail";
import useCreateField from "hooks/field/useCreateField";
import useCustomToast from "hooks/useCustomToast";
import useSelectedFarmId from "hooks/useSelectedFarmId";
import useNavigateWithQuery from "hooks/useNavigateWithQuery";
import useFarmsRegionDetails from "hooks/farm/useFarmsRegionDetails";
import dashboardPaths from "routes/dashboard/dashboardPaths";
import { BaseApiError } from "services/apiHelpers";

const fieldsPath = `/${dashboardPaths.basePath}/${dashboardPaths.children.fields}`;

const NewFieldInformationPage = (): ReactElement => {
  const { cropYear, isLoading: cropYearIsLoading } = useCropYearContext();
  const [searchParams] = useSearchParams();
  const navigate = useNavigateWithQuery();
  const [farmId] = useSelectedFarmId();
  const { mutateAsync: onCreateFieldAsync } = useCreateField();
  const { onSuccessToast, onErrorToast } = useCustomToast();
  const { regionDetails, isLoading: farmRegionIsLoading } =
    useFarmsRegionDetails(farmId);

  const navigateToFieldList = useCallback(
    () => navigate(fieldsPath),
    [navigate]
  );

  if (farmRegionIsLoading || cropYearIsLoading) {
    return <FormSkeletonLoadingState rowCount={6} />;
  }

  if (!(farmId || cropYear?.id || regionDetails)) {
    return <Navigate to={`${fieldsPath}?${searchParams}`} />;
  }

  const handleSubmit = (data: CreateFieldFormData) => {
    const fieldData = formDataToCreateFieldRequest(data);

    return onCreateFieldAsync(fieldData)
      .then(() => {
        onSuccessToast({ message: "Field created successfully" });
        navigateToFieldList();
      })
      .catch((error: BaseApiError) => {
        onErrorToast({
          message: error.response?.data.error || "Failed to create field",
        });
        throw error;
      });
  };

  return (
    <FieldDetailForm
      onSubmit={handleSubmit}
      estimatedTonsPerAcre={regionDetails?.estimatedTonsPerAcre}
    />
  );
};

export default NewFieldInformationPage;
