import { useMemo, useRef } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Stack,
  Text,
  UseDisclosureProps,
  Spacer,
} from "@chakra-ui/react";
import TextErrorMessage from "components/TextErrorMessage";
import TextHeading from "components/TextHeading";
import { Link } from "react-router-dom";
import csvPaths from "routes/csv-uploads/csvPaths";
import { CARD_MAX_WIDTH } from "utils/styleHelpers";

const ErrorModal = ({
  isOpen = false,
  onClose = () => {
    return;
  },
  rowErrors,
}: {
  rowErrors?: {
    [key: string]: string[];
  };
} & UseDisclosureProps) => {
  const headerRef = useRef(null);
  const displayErrors = useMemo(
    () =>
      Object.entries(rowErrors ?? []).map((rowError) => {
        return (
          <Stack key={rowError[0]} p={4} spacing={1}>
            <Text key={`${rowError[0]}+${rowError[1]}`} fontWeight="bold">
              Row {rowError[0]}
            </Text>
            {rowError[1].map((element) => (
              <TextErrorMessage key={element}>{element}</TextErrorMessage>
            ))}
          </Stack>
        );
      }),
    [rowErrors]
  );

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        scrollBehavior="outside"
        initialFocusRef={headerRef}
        closeOnEsc={false}
      >
        <ModalOverlay />
        <ModalContent maxW={CARD_MAX_WIDTH}>
          <ModalHeader>
            <TextHeading headingType="h3">Upload Failed</TextHeading>
          </ModalHeader>
          <ModalBody pt={1}>
            <Stack w="100%">
              <Text ref={headerRef} tabIndex={-1}>
                You will need to correct the following errors in your file,
                export another .csv file, and re-try the upload.
              </Text>
              {displayErrors}
              <Spacer pt={1} />
              <Button
                as={Link}
                to={`${csvPaths.children.farmDocuments.children.fileSelect}`}
                onClick={onClose}
                variant="outline"
              >
                Close
              </Button>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
export default ErrorModal;
