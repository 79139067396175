import { ComponentStyleConfig } from "@chakra-ui/react";
import textStyles from "../textStyles";

const Avatar: ComponentStyleConfig = {
  sizes: {
    md: {
      container: {
        width: "40px",
        height: "40px",
      },
      label: {
        ...textStyles.h5,
      },
    },
    "4xl": {
      container: {
        width: "208px",
        height: "208px",
      },
      label: {
        ...textStyles.h1,
      },
    },
  },
};

export default Avatar;
