import { ReactElement, useCallback } from "react";
import { IoTrash } from "react-icons/io5";
import { Button, Icon, useDisclosure } from "@chakra-ui/react";

import DeleteConfirmationModal from "components/DeleteConfirmationModal";
import useDeleteField from "hooks/field/useDeleteField";
import useCustomToast from "hooks/useCustomToast";
import useNavigateWithQuery from "hooks/useNavigateWithQuery";
import dashboardPaths from "routes/dashboard/dashboardPaths";

const DeleteFieldButton = ({
  fieldId,
  hasMultipleSplits,
}: {
  fieldId: string;
  hasMultipleSplits: boolean;
}): ReactElement => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { onSuccessToast, onErrorToast } = useCustomToast();
  const { mutateAsync: deleteField } = useDeleteField();
  const navigate = useNavigateWithQuery();

  const navigateToFieldList = useCallback(
    () =>
      navigate(`/${dashboardPaths.basePath}/${dashboardPaths.children.fields}`),
    [navigate]
  );

  const onDelete = () => {
    deleteField(fieldId)
      .then(() => {
        onSuccessToast({ message: "Field deleted successfully" });
        navigateToFieldList();
      })
      .catch(() => {
        onErrorToast({ message: "Failed to delete field" });
      });
  };

  const headingText = hasMultipleSplits
    ? "Delete Field with splits"
    : "Are you sure you want to delete this field";

  const bodyText = hasMultipleSplits
    ? "This will delete the field for all farms involved in the split. Do you wish to continue?"
    : "This field will be removed for this year. Do you wish to continue?";

  return (
    <>
      <Button
        aria-label="Delete field"
        leftIcon={<Icon as={IoTrash} />}
        variant="link"
        colorScheme={"error"}
        onClick={onOpen}
      >
        Delete field
      </Button>
      <DeleteConfirmationModal
        isOpen={isOpen}
        onClose={onClose}
        onDelete={onDelete}
        headingText={headingText}
        bodyText={bodyText}
      />
    </>
  );
};

export default DeleteFieldButton;
