import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useBreakpointValue } from "@chakra-ui/react";

import FarmListTable from "components/FarmListTable/FarmListTable";
import {
  baseListColumns,
  desktopListColumns,
  farmListColumnsWithSecondarySort,
  farmListDefaultSort,
  FarmsSortColumns,
  listTableDataMapper as haylageListTableDataMapper,
} from "components/FarmListTable/listTableDataMapper";
import { TableData } from "components/SortableTable";
import useGetHaylageYearFarms from "hooks/api/haylage-year/useGetHaylageYearFarms";
import { FARM_ID_QUERY_STRING_KEY } from "hooks/useSelectedFarmId";
import useSelectedHaylageYearId, {
  HAYLAGE_YEAR_ID_QUERY_STRING_KEY,
} from "hooks/useSelectedHaylageYearId";
import useSort, { addSecondarySort } from "hooks/useSort";
import haylagePaths from "routes/haylage/haylagePaths";
import { mobileStyleBreakpoint } from "utils/styleHelpers";

const haylageListTableColumns = mobileStyleBreakpoint(
  baseListColumns,
  desktopListColumns,
  "md"
);

const HaylageFarmListTable = () => {
  const columns = useBreakpointValue(haylageListTableColumns);
  const navigate = useNavigate();
  const [selectedHaylageYearId] = useSelectedHaylageYearId();

  const useSortProps = useSort<FarmsSortColumns>(farmListDefaultSort);
  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useGetHaylageYearFarms(selectedHaylageYearId, {
      sort: addSecondarySort(
        useSortProps.sort,
        farmListColumnsWithSecondarySort
      ),
    });

  const farms = useMemo(
    () => data?.pages?.map(({ haylageYearFarms: farms }) => farms).flat() ?? [],
    [data]
  );
  const tableData = useMemo(() => haylageListTableDataMapper(farms), [farms]);

  const onClickRow = useCallback(
    async ({ farmId }: TableData) => {
      navigate(
        `/${haylagePaths.basePath}/${haylagePaths.children.farmDocuments}?${FARM_ID_QUERY_STRING_KEY}=${farmId}&${HAYLAGE_YEAR_ID_QUERY_STRING_KEY}=${selectedHaylageYearId}`
      );
    },
    [navigate, selectedHaylageYearId]
  );

  return (
    <FarmListTable
      programType="Haylage"
      isLoading={isLoading}
      columns={columns}
      tableData={tableData}
      defaultSort={farmListDefaultSort}
      onClickRow={onClickRow}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      {...useSortProps}
    />
  );
};

export default HaylageFarmListTable;
