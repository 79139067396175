import { ReactElement, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Checkbox, HStack, Link, VStack } from "@chakra-ui/react";

import { extractResponseErrorMessage } from "services/apiHelpers";
import { useRegistrationForm } from "contexts/RegistrationFormContext";
import useCreateUser from "hooks/user/useCreateUser";
import { userRegistrationErrorStates } from "pages/user-registration/userRegistrationErrorStates";
import userRegistrationPaths from "routes/user-registration/userRegistrationPaths";
import authPaths from "routes/auth/authPaths";
import RedirectToErrorPage from "components/redirects/RedirectToErrorPage";

const TermsOfUseForm = (): ReactElement => {
  const [isChecked, setIsChecked] = useState(false);
  const { basicUserInfo, phoneNumber, inviteToken } = useRegistrationForm();
  const {
    onSubmitAsync,
    isPending: isLoading,
    isError,
    error,
  } = useCreateUser();
  const navigate = useNavigate();

  if (!isLoading && isError) {
    const errorMsg = extractResponseErrorMessage(error?.response);
    const errorState =
      Object.values(userRegistrationErrorStates).find((_errorState) =>
        _errorState.match?.test(errorMsg)
      ) ?? userRegistrationErrorStates.SERVER_DOWN;

    return (
      <RedirectToErrorPage
        errorState={errorState}
        relativeErrorPath={userRegistrationPaths.children.error}
      />
    );
  }

  return (
    <VStack spacing={9} align="flex-start" w="100%">
      <HStack>
        <Checkbox
          isChecked={isChecked}
          onChange={() => setIsChecked(!isChecked)}
        >
          I accept
        </Checkbox>
        <Link href="/terms-of-use" target="_blank" color="brand.800">
          Terms of Use
        </Link>
      </HStack>
      <Button
        w="100%"
        isDisabled={!isChecked}
        onClick={() => {
          onSubmitAsync({
            firstName: basicUserInfo.firstName ?? "",
            lastName: basicUserInfo.lastName ?? "",
            password: basicUserInfo.password ?? "",
            confirmPassword: basicUserInfo.confirmPassword ?? "",
            phoneNumber: phoneNumber ?? "",
            inviteToken: inviteToken ?? "",
          }).then((data) => {
            const queryString = new URLSearchParams({
              email: data?.email ?? "",
            });
            navigate(`/${authPaths.basePath}?${queryString.toString()}`);
          });
        }}
        isLoading={isLoading}
      >
        Accept &amp; Create
      </Button>
    </VStack>
  );
};

export default TermsOfUseForm;
