import { ReactElement } from "react";

import { Button, ButtonProps, useDisclosure } from "@chakra-ui/react";
import ConfirmationAlert from "components/ConfirmationAlert";
import { GarbageCanIcon } from "icons";
import useRemoveUsersFromFarm from "hooks/farm/useRemoveUsersFromFarm";
import useCustomToast from "hooks/useCustomToast";

const RemoveFarmAccessButton = ({
  buttonProps,
  clearSelectedIds,
  farmId,
  selectedIds,
}: {
  buttonProps: ButtonProps;
  clearSelectedIds: () => void;
  farmId?: string;
  selectedIds: string[];
}): ReactElement => {
  const { isOpen, onClose, onToggle } = useDisclosure();

  const { mutateAsync: onRemoveUsersAsync } = useRemoveUsersFromFarm();

  const { onSuccessToast, onErrorToast } = useCustomToast();

  const onConfirmDelete = () => {
    onRemoveUsersAsync({ farmId, userIds: selectedIds })
      .then(() => {
        clearSelectedIds();
        onSuccessToast({
          message: "User(s) Removed",
        });
        onClose();
      })
      .catch(() => {
        onErrorToast({ message: "Failed to Remove User(s)" });
        onClose();
      });
  };

  return (
    <>
      <Button
        variant="tableButton"
        onClick={onToggle}
        leftIcon={<GarbageCanIcon />}
        iconSpacing="3px"
        {...buttonProps}
      >
        Remove Farm Access
      </Button>
      <ConfirmationAlert
        isOpen={isOpen}
        onConfirm={onConfirmDelete}
        onClose={onClose}
        title="Remove Farm Access"
        msg="This will remove the selected user(s) access to this Farm. Do you want to continue?"
        confirmButtonText="Remove"
        confirmButtonVariant="destructive"
        secondaryButtonText="Cancel"
      />
    </>
  );
};

export default RemoveFarmAccessButton;
