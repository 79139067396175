import { ReactElement, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ScaleFade,
  Stack,
  Text,
} from "@chakra-ui/react";

import TextHeading from "components/TextHeading";
import { CARD_MAX_WIDTH } from "utils/styleHelpers";
import useTriggerPostHarvest from "hooks/crop-year/useTriggerPostHarvest";
import useCustomToast from "hooks/useCustomToast";

export const confirmationMsg =
  "This change will be applied to all farms for this year. No additional changes should be made until the switch is completed. Do you wish to continue?";
export const errorMsg =
  "Unable to submit your request due to a server error. If the problem persists, contact Riverview.";

const ModalPrimaryContent = ({
  onClose,
  onConfirm,
}: {
  onClose: () => void;
  onConfirm: () => void;
}) => (
  <Stack w="100%">
    <Text pb={4}>{confirmationMsg}</Text>
    <Button onClick={onConfirm} w="100%" variant="primary">
      Yes
    </Button>
    <Button onClick={onClose} w="100%" variant="outline">
      Cancel
    </Button>
  </Stack>
);

const ModalErrorContent = ({ onClose }: { onClose: () => void }) => (
  <ScaleFade initialScale={0.9} in={true}>
    <Stack w="100%">
      <Text pb={4}>{errorMsg}</Text>
      <Button variant="primary" onClick={onClose} aria-label="confirmation-btn">
        Close
      </Button>
    </Stack>
  </ScaleFade>
);

const PostHarvestConfirmationModal = ({
  cropYearId,
  isOpen,
  onCancel,
  onClose,
}: {
  cropYearId?: string;
  isOpen: boolean;
  onCancel: () => void;
  onClose: () => void;
}): ReactElement => {
  const [error, setError] = useState(false);
  const { mutateAsync: setCropYearToPostHarvestAsync } =
    useTriggerPostHarvest(cropYearId);
  const { onSuccessToast } = useCustomToast();
  const headerText = !error ? "Change to Post-Harvest" : "Server Error";

  const toggleError = () => {
    setError((error) => !error);
  };
  const onConfirm = () => {
    setCropYearToPostHarvestAsync()
      .then(() => {
        onSuccessToast({
          message: "Successfully set crop year to post harvest",
        });
        onClose();
      })
      .catch(() => {
        toggleError();
      });
  };
  const onCloseErrorModal = () => {
    toggleError();
    onCancel();
  };

  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <ModalOverlay />
      <ModalContent maxW={CARD_MAX_WIDTH}>
        <ModalHeader>
          <TextHeading headingType="h3">{headerText}</TextHeading>
        </ModalHeader>
        <ModalBody>
          {error ? (
            <ModalErrorContent onClose={onCloseErrorModal} />
          ) : (
            <ModalPrimaryContent onClose={onCancel} onConfirm={onConfirm} />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PostHarvestConfirmationModal;
