import { ReactElement } from "react";

import {
  Badge,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useBreakpointValue,
} from "@chakra-ui/react";

import TextHeading from "components/TextHeading";
import { AveragePricingFormWrapper } from "forms/pricing/average-pricing";
import { CARD_MAX_WIDTH, desktopOnly } from "utils/styleHelpers";

export const AveragePricingHeader = () => (
  <>
    <Badge variant={"active"} mb={4}>
      Active
    </Badge>
    <TextHeading pb={4}>Average Pricing</TextHeading>
  </>
);

const EditAveragePricingModal = ({
  pricingOptionId,
  isOpen,
  onClose,
}: {
  pricingOptionId: string;
  isOpen: boolean;
  onClose: () => void;
}): ReactElement => {
  const isCentered = useBreakpointValue(desktopOnly);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered={isCentered}>
      <ModalOverlay />
      <ModalContent maxW={CARD_MAX_WIDTH}>
        <ModalHeader>
          <AveragePricingHeader />
        </ModalHeader>
        <ModalBody>
          <AveragePricingFormWrapper onClose={onClose} id={pricingOptionId} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EditAveragePricingModal;
