import { FuturesApi, FuturesPriceDto, NewCropFuturesDto } from "api";
import { extractResponseData } from "./apiHelpers";
import { constructApiControllerInstance } from "./customApiConfig";

class FuturesService {
  futuresController!: FuturesApi;
  constructor() {
    this.futuresController = constructApiControllerInstance(FuturesApi);
  }

  async getFutures(): Promise<FuturesPriceDto[]> {
    const response = await this.futuresController.getFutures();

    return extractResponseData(response);
  }

  async getAvailableNewCropFutures(): Promise<FuturesPriceDto[]> {
    const response = await this.futuresController.getAvailableNewCropFutures();

    return extractResponseData(response);
  }

  async getNewCropFutures(): Promise<NewCropFuturesDto> {
    const response = await this.futuresController.getNewCropFutures();

    return extractResponseData(response);
  }
}

export default FuturesService;
