import { ReactElement } from "react";

import { Text } from "@chakra-ui/react";

import FormInputs from "./FormInputs";
import DateInput from "./DateInput";
import InputLabel from "./InputLabel";
import { formatMonthAndDate } from "utils/dateUtils";

const DeferPaymentDateInput = ({
  deferPayment,
  defaultDefermentDate,
  firstPaymentDateWithoutReferral,
}: {
  deferPayment: boolean;
  defaultDefermentDate?: Date;
  firstPaymentDateWithoutReferral?: Date;
}): ReactElement =>
  deferPayment ? (
    <FormInputs
      inputs={[
        {
          id: "paymentDate",
          label: "Deferment Date",
          component: DateInput,
          shouldRegister: false,
          isRequired: true,
          datePickerProps: { minDate: defaultDefermentDate },
        },
      ]}
    />
  ) : (
    <>
      <InputLabel label="Payment Date" isRequired={true} />
      <Text>
        {`Estimated payment date on
            ${
              firstPaymentDateWithoutReferral
                ? formatMonthAndDate(firstPaymentDateWithoutReferral)
                : ""
            }.`}
      </Text>
    </>
  );
export default DeferPaymentDateInput;
