import { useRef } from "react";

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogProps,
  Button,
  useBreakpointValue,
} from "@chakra-ui/react";

import { CARD_MAX_WIDTH, mobileStyleBreakpoint } from "utils/styleHelpers";
import TextHeading from "components/TextHeading";

const ALERT_DIALOG_IS_CENTERED = mobileStyleBreakpoint(false, true);

const InfoAlert = ({
  isOpen = false,
  onClose = () => {
    return;
  },
  msg = "Ask admin for help",
  title = "Information",
  ...rest
}: {
  isOpen?: boolean;
  onClose?: () => void;
  msg?: string | JSX.Element;
  title?: string;
} & Omit<AlertDialogProps, "leastDestructiveRef" | "onClose" | "children">) => {
  const cancelRef = useRef(null);
  const alertDialogIsCentered = useBreakpointValue(ALERT_DIALOG_IS_CENTERED);

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
      isCentered={alertDialogIsCentered}
      {...rest}
    >
      <AlertDialogOverlay />
      <AlertDialogContent maxW={CARD_MAX_WIDTH}>
        <AlertDialogHeader>
          <TextHeading headingType="h3">{title}</TextHeading>
        </AlertDialogHeader>
        <AlertDialogBody>{msg}</AlertDialogBody>
        <AlertDialogFooter px={0}>
          <Button onClick={onClose} ref={cancelRef} w="100%">
            Close
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default InfoAlert;
