import * as yup from "yup";

export const dailyPricingFormSchema = yup.object({
  dailyPricing: yup.array(
    yup.object({
      oldCropFutures: yup.object().required("Old Crop Futures is required"),
      oldCropBasis: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .min(-2.0, "Old Crop Basis must be $-2 or greater")
        .max(2, "Old Crop Basis must be $2 or less")
        .required("Old Crop Basis is required"),
      newCropBasis: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .min(-2.0, "New Crop Basis must be $-2 or greater")
        .max(2, "New Crop Basis must be $2 or less")
        .required("New Crop Basis is required"),
    })
  ),
});
