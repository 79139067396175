import * as yup from "yup";

import { todaysDateTimeSetToStartOfDay } from "constants/schemaValidation";
import {
  dateAfterYear,
  dateWithinCropYear,
} from "forms/schemas/tests/dateTests";
import { isUSDFormat } from "forms/schemas/tests/moneyInputTest";
import { regionDetailSchema } from "./RegionDetails";

export const cropYearDetailFormSchema = () =>
  yup
    .object({
      distanceAdjRate: yup
        .number()
        .typeError("Distance adjustment rate must be a number")
        .test("is-USD-format", function (value) {
          return isUSDFormat(value, "Distance adjustment", this.createError);
        })
        .moreThan(0, "Distance adjustment rate must be more than 0")
        .max(10, "Distance adjustment rate must be 10 or less")
        .required("Distance adjustment rate is required"),
      year: yup.number().min(2000).max(3000).required(),
      defermentDateStart: yup
        .date()
        .typeError("Deferment date start is required")
        .test("deferred_start_date_following_year", function (value) {
          return dateAfterYear(
            value,
            this.parent.year + 1,
            "Deferment date start",
            this.createError
          );
        })
        .required(),
      firstPaymentDateWithoutDeferral: yup
        .date()
        .typeError("First payment date without deferral is required")
        .test(
          "first_payment_date_without_deferral_following_year",
          function (value) {
            return dateWithinCropYear(
              value,
              this.parent.year,
              "First payment date without deferral",
              this.createError
            );
          }
        )
        .required(),
      averagePricingLockInDate: yup
        .date()
        .typeError("Average pricing lock-in day is required")
        .test("average_pricing_lockin_day_following_year", function (value) {
          return dateWithinCropYear(
            value,
            this.parent.year,
            "Average pricing lock-in day",
            this.createError
          );
        })
        .required(),
      delayedPricingFinalDate: yup
        .date()
        .min(
          todaysDateTimeSetToStartOfDay(),
          "Delayed pricing final date must be on or after today's date"
        )
        .required("Delayed pricing final date is required"),
      growerLetterName: yup
        .string()
        .max(100, "Grower letter name must be 100 characters or less")
        .required("Grower letter name is required"),
      growerLetterLink: yup
        .string()
        .url()
        .required("Grower letter link is required"),
      regionDetails: yup.array(regionDetailSchema),
      storageFeeStartDate: yup
        .date()
        .min(
          todaysDateTimeSetToStartOfDay(),
          "Storage fee start date must be on or after today's date"
        )
        .required("Storage fee start date is required"),
      storageFeeRate: yup
        .number()
        .typeError("Storage fee rate is required")
        .test("is-USD-format", function (value) {
          return isUSDFormat(value, "Storage fee rate", this.createError);
        })
        .min(0, "Storage fee rate must be 0.00 or greater")
        .lessThan(1, "Storage fee rate must be less than 1.00")
        .required(),
    })
    .required();
