import { ReactElement, useCallback } from "react";

import {
  Center,
  HStack,
  Icon,
  MenuItem,
  Skeleton,
  Text,
  VisuallyHidden,
} from "@chakra-ui/react";
import { IoEllipsisVerticalSharp } from "react-icons/io5";

import { AddIcon, EventNoteIcon } from "icons";
import Menu from "components/Menu";
import useInfoAlert from "hooks/alerts/useInfoAlert";
import useCropYears from "hooks/crop-year/useCropYears";
import useCropYearDetail from "hooks/crop-year/useCropYearDetails";
import useNavigateWithQuery from "hooks/useNavigateWithQuery";
import dashboardPaths from "routes/dashboard/dashboardPaths";
import { cropYearIsInPreHarvest } from "utils/cropYearUtils";

const CropYearMenu = (): ReactElement | null => {
  const navigate = useNavigateWithQuery();
  const { alert, onOpen } = useInfoAlert({
    title: "Unable to Start New Crop Year",
  });
  const { data: cropYearsData, isLoading: cropYearsIsLoading } = useCropYears();

  const { data: activeCropYear, isLoading: isActiveCropYearLoading } =
    useCropYearDetail(cropYearsData?.cropYears?.[0]?.id);
  const handleStartNewCropYear = useCallback(() => {
    cropYearIsInPreHarvest(activeCropYear?.timeOfYear)
      ? onOpen({
          msg: "A new crop year cannot be started until the current crop year is switched to Post-Harvest.",
        })
      : navigate(`./${dashboardPaths.children.newCropYear}`);
  }, [activeCropYear, navigate, onOpen]);

  return (
    <>
      {alert}
      <Menu
        button={
          <Center>
            <Icon as={IoEllipsisVerticalSharp} />
          </Center>
        }
        menuStyles={{ variant: "cropYear" }}
        menuListStyles={{ maxH: "240px", overflowY: "auto" }}
      >
        {isActiveCropYearLoading ? (
          <Skeleton>
            <MenuItem>
              <VisuallyHidden>Loading...</VisuallyHidden>
            </MenuItem>
          </Skeleton>
        ) : (
          <MenuItem
            onClick={handleStartNewCropYear}
            aria-label="start crop year menu item"
          >
            <HStack spacing="10px">
              <Icon as={AddIcon} boxSize="20px" />
              <Text>Start New Crop Year</Text>
            </HStack>
          </MenuItem>
        )}
        {cropYearsIsLoading
          ? [1, 2].map((n) => (
              <Skeleton key={n} h={10}>
                <MenuItem>
                  <VisuallyHidden>Loading...</VisuallyHidden>
                </MenuItem>
              </Skeleton>
            ))
          : cropYearsData?.cropYears.map((cropYear) => (
              <MenuItem
                key={cropYear.id}
                onClick={() =>
                  navigate(
                    `./${dashboardPaths.children.cropYear}/${cropYear.id}`
                  )
                }
              >
                <HStack spacing="10px">
                  <Icon as={EventNoteIcon} boxSize="20px" />
                  <Text>{cropYear.year} Details</Text>
                </HStack>
              </MenuItem>
            ))}
      </Menu>
    </>
  );
};

export default CropYearMenu;
