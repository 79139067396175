import { useNavigate } from "react-router-dom";

import { Button, Center, Spinner, Stack, Text } from "@chakra-ui/react";
import ContentWrapper from "components/ContentWrapper";
import EmptyState from "components/EmptyState";
import { PageHeader } from "components/page-components";
import RedirectToDashboard from "components/redirects/RedirectToDashboard";
import { useUserInfo } from "contexts/UserInfoProvider";
import useSelectedFarmDetail from "hooks/useSelectedFarmDetail";
import { FORM_CONTENT_PX } from "layouts/FormLayout";
import farmPaths from "routes/farm/farmPaths";
import { isFarmPending } from "utils/farmStatusUtils";

const NoFarms = () => {
  const { farms, isEmployee } = useUserInfo();
  const { data: selectedFarm, isLoading: selectedFarmIsLoading } =
    useSelectedFarmDetail();
  const navigate = useNavigate();

  if (selectedFarmIsLoading) {
    return (
      <Center h="100%">
        <Spinner />
      </Center>
    );
  }

  const isSelectedFarmPending =
    selectedFarm && isFarmPending(selectedFarm.status.value);
  if (isEmployee || (farms.length > 0 && !isSelectedFarmPending)) {
    return <RedirectToDashboard />;
  }

  return (
    <Center w="100%" px={FORM_CONTENT_PX}>
      <ContentWrapper>
        <Stack>
          {selectedFarm && (
            <PageHeader heading={selectedFarm?.doingBusinessAs} />
          )}
          <Center>
            <EmptyState maxW="420px">
              {isSelectedFarmPending ? (
                <Text color="steelGray">
                  This farm is pending approval. Farm validation could take up
                  to 48 hours. You will be notified when your farm has been
                  approved.
                </Text>
              ) : (
                <Stack spacing={6} align="center">
                  <Text color="steelGray">
                    You haven&apos;t added any farms yet. Select
                    <br />
                    &quot;Add New&quot; to add your first farm.
                  </Text>
                  <Button
                    size="md"
                    onClick={() =>
                      navigate(
                        `/${farmPaths.basePath}/${farmPaths.children.new}`
                      )
                    }
                  >
                    Add New Farm Business
                  </Button>
                </Stack>
              )}
            </EmptyState>
          </Center>
        </Stack>
      </ContentWrapper>
    </Center>
  );
};

export default NoFarms;
