import {
  Center,
  chakra,
  HStack,
  Spacer,
  Stack,
  useMediaQuery,
} from "@chakra-ui/react";

import LogoLarge from "assets/logoLarge.svg";
import LogoLight from "assets/logoLight.svg";
import { RiverviewLogo } from "components/Logo";
import { mobileStyleBreakpoint } from "utils/styleHelpers";
import LoginCard from "./LoginCard";

const LOGO_POSITIONING = mobileStyleBreakpoint(6, 12);

const AuthLayout = () => {
  const [displayRightPanel] = useMediaQuery("(min-width: 1100px)");

  return (
    <HStack spacing={0} bgColor="white">
      <Stack flex={1} h="100vh" justify="center" align="center">
        <RiverviewLogo
          h="50px"
          position="absolute"
          left={LOGO_POSITIONING}
          top={12}
        />
        <LoginCard />
      </Stack>
      {displayRightPanel ? (
        <Stack
          position="relative"
          h="100vh"
          flex={1}
          bg="linear-gradient(321.57deg, #3A5D35 0%, #407D41 100%)"
        >
          <chakra.img
            src={LogoLarge}
            opacity={0.05}
            position="absolute"
            h="100%"
          />
          <Spacer flex={3} />
          <Center>
            <chakra.img src={LogoLight} />
          </Center>
          <Spacer />
        </Stack>
      ) : null}
    </HStack>
  );
};

export default AuthLayout;
