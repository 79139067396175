import { HStack, Text, ButtonProps } from "@chakra-ui/react";
import { FarmsIcon } from "icons";
import { ReactElement } from "react";
import adminPaths from "routes/admin/adminPaths";
import NavigationItem from "./NavigationItem";

export const FarmsNavigationItem = (props: ButtonProps): ReactElement => (
  <NavigationItem
    route={`/${adminPaths.basePath}/${adminPaths.children.farms}`}
    {...props}
  >
    <HStack spacing={2} w="100%">
      <FarmsIcon color="inherit" />
      <Text color="inherit">Farms</Text>
    </HStack>
  </NavigationItem>
);
