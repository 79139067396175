import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { useQueryClient } from "@tanstack/react-query";

import {
  AddressDtoStateEnum,
  CreateFarmRequestStateOfOrganizationEnum,
  UpdateFarmRequest,
  UpdateFarmRequestStateOfOrganizationEnum,
} from "api";
import { BaseApiError, extractResponseErrorMessage } from "services/apiHelpers";
import { SERVER_ERROR_MSG, SERVER_ERROR_TITLE } from "constants/messages";
import { extractNumbers } from "utils/stringUtils";
import adminPaths from "routes/admin/adminPaths";
import dashboardPaths from "routes/dashboard/dashboardPaths";
import { GET_FARMS_QUERY_KEY } from "hooks/farm/useFarmsList";
import useCustomToast from "hooks/useCustomToast";
import useRejectFarm from "hooks/farm/farm-detail/useRejectFarm";
import useCreateFarm from "hooks/farm/farm-detail/useCreateFarm";
import useEditFarm from "hooks/farm/farm-detail/useEditFarm";
import { GET_USER_INFO_QUERY_KEY } from "hooks/auth/useGetUserInfo";
import { FARM_ID_QUERY_STRING_KEY } from "hooks/useSelectedFarmId";
import useUserRoleFlags from "hooks/auth/useUserRoleFlags";
import useInfoAlert from "hooks/alerts/useInfoAlert";
import { FarmDetailFields } from "../FarmFormContext";
import { useUserInfo } from "contexts/UserInfoProvider";
import farmPaths from "routes/farm/farmPaths";

const getErrorMessage = (error: BaseApiError): string | undefined => {
  if (error?.code && error.code === "ERR_NETWORK") {
    return SERVER_ERROR_MSG;
  } else if (error?.message) {
    return error.message;
  } else if (error?.response) {
    return extractResponseErrorMessage(error?.response);
  }
};

const transformToCreateFarmRequest = (data: FarmDetailFields) => {
  if (data.state && data.orgState) {
    return {
      email: data.email,
      phoneNumber: extractNumbers(data.phoneNumber),
      doingBusinessAs: data.doingBusinessAs,
      address: {
        address: data.streetAddress,
        state: data.state.value as AddressDtoStateEnum,
        city: data.city,
        zipCode: data.zip,
      },
      lienHolders: data.lienHolders.map(({ value }) => ({
        name: value,
      })),
      stateOfOrganization: data.orgState
        .value as CreateFarmRequestStateOfOrganizationEnum,
    };
  }

  return undefined;
};

const transformToUpdateFarmRequest = (
  data: FarmDetailFields
): UpdateFarmRequest | undefined => {
  if (
    data.state &&
    data.orgState &&
    data.region?.value &&
    data.mainContact?.value
  ) {
    return {
      regionId: data.region?.value,
      contactId: data.mainContact?.value,
      email: data.email,
      phoneNumber: extractNumbers(data.phoneNumber),
      doingBusinessAs: data.doingBusinessAs,
      address: {
        address: data.streetAddress,
        state: data.state.value as AddressDtoStateEnum,
        city: data.city,
        zipCode: data.zip,
      },
      lienHolders: data.lienHolders.map(({ value }) => ({
        name: value,
      })),
      stateOfOrganization: data.orgState
        .value as UpdateFarmRequestStateOfOrganizationEnum,
    };
  }

  return undefined;
};

const useFarmMutations = (farmInfo?: { farmId?: string }) => {
  const farmId = farmInfo?.farmId;
  const { onSuccessToast, onErrorToast } = useCustomToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { isEmployee } = useUserRoleFlags();
  const userInfo = useUserInfo();

  const onSuccessRedirect = useCallback(
    async ({
      successMessage,
      refetchKey,
      navigateTo,
    }: {
      successMessage?: string;
      refetchKey: string[];
      navigateTo: string;
    }) => {
      successMessage && onSuccessToast({ message: successMessage });
      await queryClient.refetchQueries({ queryKey: refetchKey });
      navigate(navigateTo);
    },
    [navigate, onSuccessToast, queryClient]
  );

  const redirectToFarmsList = useCallback(
    (successMessage?: string) =>
      onSuccessRedirect({
        successMessage,
        refetchKey: [GET_FARMS_QUERY_KEY],
        navigateTo: `/${adminPaths.basePath}/${adminPaths.children.farms}`,
      }),
    [onSuccessRedirect]
  );

  const redirectToFarmInfoPendingApproval = useCallback(
    (farmId: string, successMessage?: string) =>
      onSuccessRedirect({
        successMessage,
        refetchKey: [GET_USER_INFO_QUERY_KEY],
        navigateTo: `/${farmPaths.basePath}/${farmPaths.children.approve}/${farmId}?${FARM_ID_QUERY_STRING_KEY}=${farmId}`,
      }),
    [onSuccessRedirect]
  );

  const onCancel = useCallback(() => {
    const farmAdminPath =
      userInfo.farms.length === 0
        ? `/${dashboardPaths.basePath}/${dashboardPaths.children.noFarms}`
        : `/${dashboardPaths.basePath}?${FARM_ID_QUERY_STRING_KEY}=${userInfo.farms[0].farmId}`;

    const path = !isEmployee
      ? farmAdminPath
      : `/${adminPaths.basePath}/${adminPaths.children.farms}`;
    navigate(path);
  }, [isEmployee, navigate, userInfo.farms]);

  const { alert, onOpen: onOpenErrorAlert } = useInfoAlert({
    title: SERVER_ERROR_TITLE,
  });
  const onError = useCallback(
    (error: BaseApiError) => {
      onOpenErrorAlert({
        msg: getErrorMessage(error),
      });
    },
    [onOpenErrorAlert]
  );

  const { onRejectAsync } = useRejectFarm();
  const onReject = useCallback(
    () =>
      farmId
        ? onRejectAsync({ farmId })
            .then(() => redirectToFarmsList("Farm Successfully Rejected"))
            .catch(() => {
              onErrorToast({ message: "Failed to Reject Farm" });
            })
        : Promise.resolve(),
    [farmId, onRejectAsync, redirectToFarmsList, onErrorToast]
  );

  const { onEditAsync } = useEditFarm(farmId, !isEmployee);
  const onSave = useCallback(
    (data: FarmDetailFields) => {
      const farmData = transformToUpdateFarmRequest(data);

      return farmData
        ? onEditAsync(farmData)
            .then(({ id: farmId }) =>
              isEmployee
                ? redirectToFarmsList("Farm Successfully Updated")
                : redirectToFarmInfoPendingApproval(
                    farmId,
                    "Farm Successfully Updated"
                  )
            )
            .catch(onError)
        : Promise.reject("Missing farm information");
    },
    [
      onEditAsync,
      onError,
      isEmployee,
      redirectToFarmsList,
      redirectToFarmInfoPendingApproval,
    ]
  );

  const { onSubmitAsync: onCreateFarmAsync } = useCreateFarm();
  const onCreate = useCallback(
    (data: FarmDetailFields) => {
      const farmData = transformToCreateFarmRequest(data);

      return farmData
        ? onCreateFarmAsync(farmData)
            .then(({ id: newFarmId }) =>
              redirectToFarmInfoPendingApproval(
                newFarmId,
                "New Farm Request Sent"
              )
            )
            .catch(onError)
        : Promise.reject("Missing farm information");
    },
    [onCreateFarmAsync, onError, redirectToFarmInfoPendingApproval]
  );

  return {
    onReject,
    onSave,
    onApprove: onSave,
    onCreate,
    onCancel,
    alert,
  };
};

export default useFarmMutations;
