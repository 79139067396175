import useSelectedFarmId from "hooks/useSelectedFarmId";
import { useCropYearContext } from "contexts/CropYearContext";
import useFarmCropYearSummary from "hooks/crop-year/useFarmCropYearSummary";
import useGetPricingOptionDetails from "hooks/pricing-option/useGetPricingOptionDetails";

const useIsAveragePricingFormLoaded = (id?: string) => {
  const [farmId] = useSelectedFarmId();
  const { cropYearDetail, isLoading } = useCropYearContext();
  const { data: cropYearSummaryData, isPending: cropYearSummaryIsLoading } =
    useFarmCropYearSummary(cropYearDetail?.id, farmId);
  const { data: pricingData, isLoading: pricingDataIsLoading } =
    useGetPricingOptionDetails(id, farmId);

  return {
    isLoading: isLoading || cropYearSummaryIsLoading || pricingDataIsLoading,
    data: {
      cropYearDetail: cropYearDetail,
      cropYearSummary: cropYearSummaryData,
      pricingData: pricingData,
    },
  };
};

export default useIsAveragePricingFormLoaded;
