import { ReactElement } from "react";
import { HStack, Stack, Text, Tooltip } from "@chakra-ui/react";

const FarmNameWithDairyColumn = ({
  dairies,
  farmName,
}: {
  dairies?: string[];
  farmName: string;
}): ReactElement => (
  <HStack spacing={3}>
    <Stack justify="space-around" spacing={0}>
      <Tooltip hasArrow label={farmName}>
        <Text variant={"truncated"} textStyle="caption" fontWeight="bold">
          {farmName}
        </Text>
      </Tooltip>
      {dairies &&
        (dairies.length > 1 ? (
          <Text textStyle="caption" color="steelGray">
            Multiple
          </Text>
        ) : (
          <Text textStyle="caption" color="steelGray">
            {dairies}
          </Text>
        ))}
    </Stack>
  </HStack>
);

export default FarmNameWithDairyColumn;
