import { RoleListResponse } from "api/models/role-list-response";
import { useUserService } from "contexts/ServiceProvider";
import { useQuery } from "@tanstack/react-query";
import { BaseApiError } from "services/apiHelpers";

export const ROLES_FOR_USER_QUERY_KEY = "get-roles-for-user";
export const ROLES_QUERY_KEY = "get-roles";

export const useGetRolesForUser = (userId?: string, farmId?: string) => {
  const userService = useUserService();

  return useQuery<RoleListResponse, BaseApiError>({
    queryKey: [ROLES_FOR_USER_QUERY_KEY, { id: userId, farmId }],
    queryFn: () =>
      userId
        ? userService.getRolesForUser(userId, farmId)
        : Promise.reject("Missing user id"),
    enabled: !!userId,
    retry: 1,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};

const useGetRoles = (farmId?: string) => {
  const userService = useUserService();

  return useQuery<RoleListResponse, BaseApiError>({
    queryKey: [ROLES_QUERY_KEY, { farmId }],
    queryFn: () => userService.getRoles({ farmId }),
    retry: 1,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};

export default useGetRoles;
