import { RegionApi, RegionDto } from "api";
import { extractResponseData } from "./apiHelpers";
import { constructApiControllerInstance } from "./customApiConfig";

class RegionService {
  regionController!: RegionApi;
  constructor() {
    this.regionController = constructApiControllerInstance(RegionApi);
  }

  getRegions(): Promise<RegionDto[]> {
    return this.regionController.getAllRegions().then(extractResponseData);
  }
}

export default RegionService;
