import { useCallback, useMemo } from "react";
import {
  Center,
  Stack,
  HStack,
  Badge,
  Divider,
  Text,
  VStack,
  Button,
  Box,
  Card,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { addDays } from "date-fns";
import { useForm, FormProvider } from "react-hook-form";

import VoidContractWaterMark, {
  PricingInfoRecordStampSection,
} from "./components";
import VoidContractAlertModal from "./components/VoidContractAlertModal/VoidContractAlertModal";
import { MarkAsReviewedInput } from "./inputs";
import {
  PricingOptionResponse,
  CropYearDetailResponse,
  FarmDetailResponse,
  PricingOptionStatusDtoStatusEnum,
} from "api";
import { DesktopOnly, MobileOnly } from "components/BreakpointVisibility";
import ContentWrapper from "components/ContentWrapper";
import {
  DEFER_PAYMENT_OPTION_YES,
  DEFER_PAYMENT_OPTION_NO,
} from "components/inputs/DeferPaymentSelectInput";
import { DeferPaymentInputs } from "components/inputs/DeferPaymentInputs";
import FormButtonGroup from "components/FormButtonGroup";
import { PageHeader } from "components/page-components";
import { SelectOption } from "components/Select";
import SubmitButton from "components/SubmitButton";
import SummaryStatCard from "components/SummaryStatCard";
import {
  FieldsStatIcon,
  FieldsStatIconDisabledState,
} from "components/SummaryStatCard/summaryStats/SummaryStatIcons";
import { useCropYearContext } from "contexts/CropYearContext";
import { editDelayedContractSchema } from "forms/schemas";
import useUserRoleFlags from "hooks/auth/useUserRoleFlags";
import useCustomToast from "hooks/useCustomToast";
import useNavigateWithQuery from "hooks/useNavigateWithQuery";
import useUpdateDelayedPricingContract from "hooks/pricing-option/useUpdateDelayedPricingContract";
import { FORM_CONTENT_PX, FormStack } from "layouts/FormLayout";
import dashboardPaths from "routes/dashboard/dashboardPaths";
import {
  CashPrice,
  BasePrice,
  DistanceAdjustment,
  StorageFees,
  Multiplier,
  NetPrice,
  NetTotal,
} from "components/PricingStats/PricingStats";
import DisplayLabelValue from "components/PricingStats/DisplayLabelValue";
import {
  parseDate,
  formatDate,
  ISODateWithoutTime,
  parseIsoDate,
} from "utils/dateUtils";
import { defaultDeferPayStartDate } from "utils/defaultDeferPayStartDate";
import { formatNumberWithDecimals } from "utils/formatFunctions";
import { getStatusObject } from "utils/getStatusObject";
import { mobileStyleBreakpoint } from "utils/styleHelpers";
import { getIsDeferPayment } from "utils/getIsDeferPayment";

export type EditDelayedPricingFormData = {
  deferPayment: SelectOption;
  defermentDate: Date;
  reviewed: boolean;
  voided: boolean;
};

const formDataStackStyle = {
  align: "left",
  maxWidth: { base: "100%", md: "40%" },
};

const PAGE_HEADER_MAX_HEIGHT = mobileStyleBreakpoint("unset", "54px");

export const getTextColorForSummaryStatCard = (
  voidStateTextColor: string,
  voidedContract: boolean
) => {
  return !voidedContract ? voidStateTextColor : "greyFactor.4";
};

const VoidedContractStamp = () => (
  <Text color="errorRed" fontSize="5xl" fontWeight="bold" maxH="56px">
    VOID
  </Text>
);

const EditDelayedPricingForm = ({
  pricingOption,
  cropYear,
  farm,
}: {
  pricingOption: PricingOptionResponse;
  cropYear: CropYearDetailResponse;
  farm: FarmDetailResponse;
}) => {
  const { cropYearDetail } = useCropYearContext();
  const { isEmployeeAdmin } = useUserRoleFlags();
  const navigate = useNavigateWithQuery();
  const { onSuccessToast, onErrorToast } = useCustomToast();
  const { mutateAsync } = useUpdateDelayedPricingContract(
    pricingOption?.id,
    cropYear?.id,
    farm?.id
  );

  const statusObject = getStatusObject(pricingOption.status.status);

  const tomorrowsDate = addDays(new Date(), 1);
  const completedReview =
    pricingOption?.status.status === PricingOptionStatusDtoStatusEnum.Active;
  const voidedContract =
    pricingOption?.status.status === PricingOptionStatusDtoStatusEnum.Void;

  const methods = useForm<EditDelayedPricingFormData>({
    resolver: yupResolver(editDelayedContractSchema),
    defaultValues: {
      deferPayment: pricingOption?.deferPayment
        ? DEFER_PAYMENT_OPTION_YES
        : DEFER_PAYMENT_OPTION_NO,
      defermentDate: pricingOption?.defermentDate
        ? parseDate(pricingOption?.defermentDate)
        : tomorrowsDate,
      reviewed: completedReview,
      voided: voidedContract,
    },
  });
  const {
    handleSubmit,
    watch,
    formState: { isDirty, isSubmitting },
  } = methods;
  const navigateToDashBoardPricingView = useCallback(
    () =>
      navigate(
        `/${dashboardPaths.basePath}/${dashboardPaths.children.pricing}`
      ),
    [navigate]
  );
  const onSubmit = useCallback(
    (data: EditDelayedPricingFormData) => {
      const deferPayment = getIsDeferPayment(data.deferPayment.value);

      return mutateAsync({
        ...data,
        deferPayment,
        defermentDate: deferPayment
          ? ISODateWithoutTime(data.defermentDate)
          : undefined,
      })
        .then(() => {
          onSuccessToast({
            message: "Delayed pricing contract updated successfully",
          });
        })
        .catch(() => {
          onErrorToast({
            message: `Failed to update delayed pricing contract`,
          });
        });
    },
    [mutateAsync, onErrorToast, onSuccessToast]
  );

  const isDeferPayment = getIsDeferPayment(watch("deferPayment").value);

  const displayButtonGroup = useMemo(() => {
    if (isEmployeeAdmin) {
      return (
        <FormButtonGroup
          spacing={3}
          direction="row"
          justifyContent={{ base: "space-between", md: "flex-start" }}
        >
          <MarkAsReviewedInput completedReview={completedReview} />
          <SubmitButton
            submitDisabled={!isDirty || isSubmitting}
            isSubmitting={isSubmitting}
            buttonText="Update"
            width="152px"
          />
        </FormButtonGroup>
      );
    }
  }, [completedReview, isDirty, isEmployeeAdmin, isSubmitting]);

  const displayComments = useMemo(() => {
    return (
      pricingOption?.comments && (
        <Stack spacing={4}>
          <Text textStyle="h6">Comments</Text>
          <Card
            bg="gray.100"
            borderRadius={8}
            color="grayFactor.4"
            minH="98px"
            px={6}
            py={3}
          >
            {pricingOption?.comments}
          </Card>
        </Stack>
      )
    );
  }, [pricingOption?.comments]);

  return (
    <Center
      w="100%"
      px={FORM_CONTENT_PX}
      position="relative"
      overflow="visible"
    >
      <ContentWrapper>
        <FormProvider {...methods}>
          <FormStack onSubmit={handleSubmit(onSubmit)}>
            <MobileOnly>{voidedContract && <VoidedContractStamp />}</MobileOnly>
            <PageHeader
              heading="Pricing Information"
              maxH={PAGE_HEADER_MAX_HEIGHT}
            >
              {!voidedContract ? (
                displayButtonGroup
              ) : (
                <DesktopOnly>
                  <VoidedContractStamp />
                </DesktopOnly>
              )}
            </PageHeader>
            <Text color="grayFactor.4" fontSize="lg" fontWeight="bold">
              {farm?.doingBusinessAs}
            </Text>
            <Stack
              align="start"
              direction={{ base: "column", md: "row" }}
              justify={{ base: "flex-start", md: "space-between" }}
            >
              <HStack>
                <Text fontSize="2xl" fontWeight="bold">
                  Delayed Pricing
                </Text>
                <Badge variant={statusObject?.value} mb={4}>
                  {statusObject?.displayName}
                </Badge>
              </HStack>
              <DesktopOnly>
                <Stack align="end" direction="column" spacing={7}>
                  <PricingInfoRecordStampSection
                    pricingOption={pricingOption}
                    createdAtLabel="Date of Contract:"
                    voidedAtLabel="Contract Voided:"
                    voidedContract={voidedContract}
                  />
                  <Box w="100%" textAlign="right">
                    <VoidContractAlertModal
                      isEmployeeAdmin={isEmployeeAdmin}
                      voidedContract={voidedContract}
                      pricingOption={pricingOption}
                      cropYear={cropYear}
                      farm={farm}
                      completedReview={completedReview}
                    />
                  </Box>
                </Stack>
              </DesktopOnly>
            </Stack>
            <SummaryStatCard
              icon={
                !voidedContract ? (
                  <FieldsStatIcon />
                ) : (
                  <FieldsStatIconDisabledState />
                )
              }
              label="Tons to sell"
              value={pricingOption?.totalTons ?? 0}
              formatter={() =>
                formatNumberWithDecimals(pricingOption?.totalTons ?? 0, 8)
              }
              maxW="342px"
              statNumberColor={getTextColorForSummaryStatCard(
                "charcoal",
                voidedContract
              )}
              statLabelColor={getTextColorForSummaryStatCard(
                "steelGray",
                voidedContract
              )}
            />
            <MobileOnly>
              <PricingInfoRecordStampSection
                pricingOption={pricingOption}
                createdAtLabel="Date of Contract:"
                voidedAtLabel="Contract Voided:"
                voidedContract={voidedContract}
              />
            </MobileOnly>
            <Divider />
            <VStack {...formDataStackStyle} spacing={8}>
              {isEmployeeAdmin && !voidedContract ? (
                <DeferPaymentInputs
                  isDeferPayment={isDeferPayment}
                  minDate={defaultDeferPayStartDate({
                    cropYearDefermentDate: parseIsoDate(
                      cropYearDetail?.defermentDateStart as string
                    ),
                    tomorrowsDate,
                  })}
                />
              ) : (
                <>
                  <DisplayLabelValue
                    label="Defer Payment"
                    value={
                      isDeferPayment
                        ? DEFER_PAYMENT_OPTION_YES.label
                        : DEFER_PAYMENT_OPTION_NO.label
                    }
                  />
                  <DisplayLabelValue
                    label="Deferment Date"
                    value={
                      pricingOption?.defermentDate
                        ? formatDate(parseDate(pricingOption.defermentDate))
                        : "-"
                    }
                  />
                </>
              )}
              <CashPrice
                cashPrice={pricingOption?.cashPrice}
                futuresFriendlySymbol={pricingOption?.futuresFriendlySymbol}
                futuresPrice={pricingOption?.futuresPrice}
                basis={pricingOption?.basis}
                isManuallyCreated={pricingOption?.isManuallyCreated}
              />
              <Multiplier multiplier={pricingOption?.multiplier} />
              <BasePrice basePrice={pricingOption?.basePrice} />
            </VStack>
            <Divider />
            <VStack {...formDataStackStyle} spacing={8}>
              <DistanceAdjustment
                distanceAdjustment={pricingOption?.distanceAdjustment}
              />
              <StorageFees
                storageFees={pricingOption?.storageFeeAdjustment}
                storageFeeRate={cropYear?.storageFeeRate}
                storageFeeStartDate={cropYear?.storageFeeStartDate}
              />
              <NetPrice netPrice={pricingOption?.contractPrice} />
            </VStack>
            <Divider />
            <HStack
              maxWidth={{ base: "100%", md: "40%" }}
              justify="space-between"
            >
              <Text fontWeight="bold" color="charcoal">
                Net Total
              </Text>
              <NetTotal netTotal={pricingOption?.contractTotal} />
            </HStack>
            <DesktopOnly>{displayComments}</DesktopOnly>
            <MobileOnly>
              <Stack>
                {displayComments}
                <Button
                  variant="outline"
                  size="md"
                  onClick={navigateToDashBoardPricingView}
                  w="100%"
                >
                  Close
                </Button>
              </Stack>
            </MobileOnly>
          </FormStack>
        </FormProvider>
        <DesktopOnly>{voidedContract && <VoidContractWaterMark />}</DesktopOnly>
      </ContentWrapper>
    </Center>
  );
};

export default EditDelayedPricingForm;
