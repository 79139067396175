import { ButtonProps, HStack, Text } from "@chakra-ui/react";
import { useNavigationRoute } from "hooks/useNavigationRoute";
import { DocumentIcon } from "icons";
import { ReactElement } from "react";
import NavigationItem from "./NavigationItem";

export const HaylageNavigationItem = (props: ButtonProps): ReactElement => {
  const { haylageRoute } = useNavigationRoute();

  return (
    <NavigationItem route={haylageRoute} {...props}>
      <HStack spacing={2} w="100%">
        <DocumentIcon color="inherit" />
        <Text color="inherit">Haylage</Text>
      </HStack>
    </NavigationItem>
  );
};
