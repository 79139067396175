import { forwardRef, ReactElement } from "react";
import StandardInput, { StandardInputProps } from "./StandardInput";

const ZipInput = forwardRef<HTMLInputElement, StandardInputProps>(
  (props, ref): ReactElement => (
    <StandardInput
      ref={ref}
      {...props}
      type="text"
      pattern="\d*"
      maxLength={5}
    />
  )
);

ZipInput.displayName = "ZipInput";

export default ZipInput;
