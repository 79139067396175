import { ReactElement, useRef, useState } from "react";

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogProps,
  Button,
  Spinner,
  Stack,
} from "@chakra-ui/react";

import { CARD_MAX_WIDTH } from "utils/styleHelpers";
import TextHeading from "components/TextHeading";

export const DIALOG_SCROLL_ID = "dialog-scroll-id";

export type ConfirmationAlertProps = {
  msg?: string | ReactElement;
  title?: string;
  onConfirm?: () => void;
  onConfirmAsync?: () => Promise<void>;
  confirmButtonText?: string;
  confirmButtonVariant?: string;
  secondaryButtonText?: string;
  body?: ReactElement;
  dialogOffset?: string;
} & Pick<AlertDialogProps, "variant">;

const ConfirmationAlert = ({
  isOpen = false,
  onClose = () => {
    return;
  },
  onConfirm,
  onConfirmAsync,
  msg = "Ask admin for help",
  body,
  title = "Information",
  confirmButtonText = "Ok",
  confirmButtonVariant = "primary",
  secondaryButtonText = "Cancel",
  dialogOffset = "0",
  ...rest
}: {
  isOpen?: boolean;
  onClose?: () => void;
} & ConfirmationAlertProps &
  Omit<AlertDialogProps, "leastDestructiveRef" | "onClose" | "children">) => {
  const cancelRef = useRef(null);
  const [confirmDisabled, setConfirmDisabled] = useState(false);

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
      {...rest}
    >
      <AlertDialogOverlay />
      <AlertDialogContent
        data-scroll-id={DIALOG_SCROLL_ID}
        maxW={CARD_MAX_WIDTH}
        marginLeft={{ base: 0, lg: dialogOffset }}
      >
        <AlertDialogHeader>
          <TextHeading headingType="h3">{title}</TextHeading>
        </AlertDialogHeader>
        <AlertDialogBody overflowY={"auto"}>
          {msg}
          {body}
        </AlertDialogBody>
        <AlertDialogFooter px={0}>
          <Stack w="100%">
            <Button
              onClick={() => {
                if (onConfirmAsync) {
                  setConfirmDisabled(true);
                  onConfirmAsync().finally(() => setConfirmDisabled(false));
                } else if (onConfirm) {
                  onConfirm();
                }
              }}
              w="100%"
              variant={confirmButtonVariant}
              isDisabled={confirmDisabled}
              isLoading={confirmDisabled}
              spinner={
                <Spinner
                  color={confirmButtonVariant}
                  size="lg"
                  borderWidth="3px"
                  borderBottomColor="grayFactor.0"
                  borderLeftColor="grayFactor.0"
                />
              }
            >
              {confirmButtonText}
            </Button>
            <Button
              onClick={onClose}
              ref={cancelRef}
              w="100%"
              variant="outline"
            >
              {secondaryButtonText}
            </Button>
          </Stack>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default ConfirmationAlert;
