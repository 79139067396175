import { useCropYearContext } from "contexts/CropYearContext";
import useSelectedFarmId from "hooks/useSelectedFarmId";
import FarmSummaryCards from "./FarmSummaryCards";
import AllFarmsSummaryCards from "./AllFarmsSummaryCards";

export const baseStyle = {
  minW: "241px",
  flex: 1,
  value: 100,
};

const SummaryCards = () => {
  const { cropYear } = useCropYearContext();
  const [farmId] = useSelectedFarmId();

  return farmId ? (
    <FarmSummaryCards cropYearId={cropYear?.id} farmId={farmId} />
  ) : (
    <AllFarmsSummaryCards cropYearId={cropYear?.id} />
  );
};

export default SummaryCards;
