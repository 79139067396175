import * as yup from "yup";

const minZipCode = 1;
const maxZipCode = 99999;

export const lengthError = "Zip must be 5 digits";
export const minValueError = `Zip must be greater than or equal to 0000${minZipCode}`;
export const maxValueError = `Zip must be less than or equal to ${maxZipCode}`;
export const requiredError = "Zip is required";

// Ensure it is a string of min length 5
export const zipCodeSchema = yup
  .string()
  .length(5, lengthError)
  .test({
    name: "minValue",
    message: minValueError,
    test: (value) => value !== undefined && parseInt(value) >= minZipCode,
  })
  .test({
    name: "maxValue",
    message: maxValueError,
    test: (value) => value !== undefined && parseInt(value) <= maxZipCode,
  })
  .typeError(requiredError)
  .required(requiredError);
