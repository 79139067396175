/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface FarmStatusDto
 */
export interface FarmStatusDto {
  /**
   *
   * @type {string}
   * @memberof FarmStatusDto
   */
  value: FarmStatusDtoValueEnum;
  /**
   *
   * @type {string}
   * @memberof FarmStatusDto
   */
  displayName: string;
}

/**
 * @export
 * @enum {string}
 */
export enum FarmStatusDtoValueEnum {
  NeedsReview = "NEEDS_REVIEW",
  Active = "ACTIVE",
  Inactive = "INACTIVE",
}
