import { ComponentStyleConfig } from "@chakra-ui/react";

const Stat: ComponentStyleConfig = {
  baseStyle: {
    label: {
      textStyle: "body",
      marginBottom: 1,
      color: "steelGray",
    },
    number: {
      textStyle: "h3",
      fontWeight: "bold",
      whiteSpace: "nowrap",
      color: "charcoal",
    },
  },
  variants: {
    rightAlign: {
      label: {
        textAlign: "right",
      },
      number: {
        textAlign: "right",
      },
    },
    horizontal: {
      container: {
        dl: {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        },
      },
      label: {
        marginBottom: 0,
        marginRight: 4,
      },
    },
  },
  sizes: {
    sm: {},
    lg: {
      number: { textStyle: "h2" },
    },
  },
  defaultProps: {
    size: "sm",
  },
};

export default Stat;
