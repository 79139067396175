import { useDisclosure, Text } from "@chakra-ui/react";

import MonthlyAveragePricingModal from "./MonthlyAveragePricingModal";

const MonthlyAveragePricingButton = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Text onClick={onOpen}>Add Monthly Average Prices</Text>
      {isOpen && (
        <MonthlyAveragePricingModal isOpen={isOpen} onClose={onClose} />
      )}
    </>
  );
};

export default MonthlyAveragePricingButton;
