import { Center } from "@chakra-ui/react";

import useNavigateWithQuery from "hooks/useNavigateWithQuery";
import { CardHeader, ResponsiveCard } from "components/Card";
import { CARD_MAX_WIDTH } from "utils/styleHelpers";
import PhoneNumberForm from "forms/user-registration/PhoneNumberForm";
import { useRegistrationForm } from "contexts/RegistrationFormContext";
import { RedirectToBasicInfoPath } from "routes/user-registration";
import userRegistrationPaths from "routes/user-registration/userRegistrationPaths";

const PhoneNumberFormCard = () => {
  const { basicInfoComplete } = useRegistrationForm();

  const navigateWithQuery = useNavigateWithQuery();

  if (!basicInfoComplete) {
    return <RedirectToBasicInfoPath />;
  }

  return (
    <Center>
      <ResponsiveCard maxW={CARD_MAX_WIDTH}>
        <CardHeader>What&rsquo;s a good phone number to reach you?</CardHeader>
        <PhoneNumberForm
          onSubmitSuccess={() => {
            navigateWithQuery(
              `../${userRegistrationPaths.children.profilePicture}`
            );
          }}
        />
      </ResponsiveCard>
    </Center>
  );
};

export default PhoneNumberFormCard;
