const userRegistrationPaths = {
  basePath: "user-registration",
  children: {
    basicUserInfoPath: "basic-user-info",
    phoneNumberPath: "phone-number",
    profilePicture: "profile-picture",
    termsAndConditions: "terms-and-conditions",
    error: "error",
  },
};

export default userRegistrationPaths;
