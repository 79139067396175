import { ReactElement } from "react";
import HaylageFarmListTable from "./HaylageFarmListTable";
import ProgramListPageLayout from "components/ProgramListPageLayout/ProgramListPageLayout";
import useSelectedHaylageYearId, {
  HAYLAGE_YEAR_ID_QUERY_STRING_KEY,
} from "hooks/useSelectedHaylageYearId";
import haylagePaths from "routes/haylage";
import HaylageYearSelect from "./components/HaylageYearSelect";

const HaylageFarmListPage = (): ReactElement => {
  const [selectedHaylageYearId] = useSelectedHaylageYearId();

  return (
    <ProgramListPageLayout
      heading="Haylage"
      pageTitle="All Farms"
      path={`/${haylagePaths.basePath}/${haylagePaths.children.addFarms}?${HAYLAGE_YEAR_ID_QUERY_STRING_KEY}=${selectedHaylageYearId}`}
      yearSelect={<HaylageYearSelect />}
    >
      <HaylageFarmListTable />
    </ProgramListPageLayout>
  );
};

export default HaylageFarmListPage;
