import { useState } from "react";

export enum SortDirection {
  ASC = "asc",
  DESC = "desc",
  NEUTRAL = "neutral",
}

export type UseSortReturnType<SortKeys> = {
  sort: Sort<SortKeys>;
  setSort: (sort: Sort<SortKeys>) => void;
  toggleSort: (key: SortKeys) => void;
};

export const DEFAULT_SORT_DIRECTION = SortDirection.ASC;

export type Sort<SortKeys> = { key: SortKeys; direction: SortDirection };

export type ColumnsWithSecondarySort<T extends string> = {
  [key in T]?: Sort<T>;
};

export type ColumnsWithTertiarySort<T extends string> = {
  [key in T]?: Sort<T>;
};

export const addSecondarySort = <T extends string>(
  primarySort: Sort<T>,
  columnsWithSecondarySort: ColumnsWithSecondarySort<T>
): Sort<T>[] => {
  return columnsWithSecondarySort[primarySort.key]
    ? [primarySort, columnsWithSecondarySort[primarySort.key] as Sort<T>]
    : [primarySort];
};

export const addMultiSort = <T extends string>(
  primarySort: Sort<T>,
  columnsWithSecondarySort: ColumnsWithSecondarySort<T>,
  columnsWithTertiarySort: ColumnsWithTertiarySort<T>
): Sort<T>[] => {
  return columnsWithSecondarySort[primarySort.key] &&
    columnsWithTertiarySort[primarySort.key]
    ? [
        primarySort,
        columnsWithSecondarySort[primarySort.key] as Sort<T>,
        columnsWithTertiarySort[primarySort.key] as Sort<T>,
      ]
    : [primarySort];
};

const useSort = <SortKeys>(
  initialSort: Sort<SortKeys>
): UseSortReturnType<SortKeys> => {
  const [sort, setSort] = useState<Sort<SortKeys>>(initialSort);

  return {
    sort,
    setSort,
    toggleSort: (key) =>
      setSort((currentSort) =>
        currentSort.key === key
          ? {
              ...currentSort,
              direction:
                currentSort.direction === SortDirection.ASC
                  ? SortDirection.DESC
                  : SortDirection.ASC,
            }
          : { key, direction: DEFAULT_SORT_DIRECTION }
      ),
  };
};

export default useSort;
