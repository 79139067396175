import {
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";

import {
  AveragePricingDto,
  MonthlyAverageResponseDtoMonthEnum,
  SetMonthlyAveragePriceRequest,
} from "api";
import FormSkeletonLoadingState from "components/FormSkeletonLoadingState";
import { MoneyInput } from "components/inputs";
import { SelectOption, SingleSelect } from "components/Select";
import SubmitButton from "components/SubmitButton";
import TextHeading from "components/TextHeading";
import useGetMonthlyAverageListByMonth from "hooks/crop-year/useGetMonthlyAverageListByMonth";
import { useCropYearContext } from "contexts/CropYearContext";
import useCustomToast from "hooks/useCustomToast";
import useRegionOptions from "hooks/region/useRegionOptions";
import useSetMonthlyAverage from "hooks/crop-year/useSetMonthlyAverage";
import { formatNumberWithDecimals } from "utils/formatFunctions";
import { CARD_MAX_WIDTH } from "utils/styleHelpers";

export const monthOptions: Array<SelectOption> = [
  { label: "September", value: MonthlyAverageResponseDtoMonthEnum.September },
  { label: "October", value: MonthlyAverageResponseDtoMonthEnum.October },
  { label: "November", value: MonthlyAverageResponseDtoMonthEnum.November },
  { label: "December", value: MonthlyAverageResponseDtoMonthEnum.December },
  { label: "January", value: MonthlyAverageResponseDtoMonthEnum.January },
  { label: "February", value: MonthlyAverageResponseDtoMonthEnum.February },
  { label: "March", value: MonthlyAverageResponseDtoMonthEnum.March },
  { label: "April", value: MonthlyAverageResponseDtoMonthEnum.April },
  { label: "May", value: MonthlyAverageResponseDtoMonthEnum.May },
  { label: "June", value: MonthlyAverageResponseDtoMonthEnum.June },
  { label: "July", value: MonthlyAverageResponseDtoMonthEnum.July },
  { label: "August", value: MonthlyAverageResponseDtoMonthEnum.August },
];

export type MonthlyAveragePricingFormData = {
  averagePrices: Array<{
    regionId: string;
    cashPrice: string | undefined;
  }>;
};

const monthlyAveragePricingSchema = yup.object({
  averagePrices: yup.array(
    yup.object({
      regionId: yup.string(),
      cashPrice: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .moreThan(0, "Price must be greater than 0")
        .max(99, "Price must be less than 99")
        .required("Average Price is required"),
    })
  ),
});

const transformMonthlyAveragePricingData = (
  data: MonthlyAveragePricingFormData
): SetMonthlyAveragePriceRequest => ({
  averagePrices: data?.averagePrices.map((region) => ({
    regionId: region.regionId,
    cashPrice: Number(region.cashPrice),
  })),
});

export const MONTHLY_PRICE_FIELD_NAME = "averagePrices";

const MonthlyAveragePricingForm = ({
  cropYearId,
  month,
  onClose,
  regions,
}: {
  cropYearId?: string;
  month?: MonthlyAverageResponseDtoMonthEnum;
  onClose: () => void;
  regions?: Array<AveragePricingDto>;
}) => {
  const methods = useForm<MonthlyAveragePricingFormData>({
    resolver: yupResolver(monthlyAveragePricingSchema),
    defaultValues: {
      averagePrices: regions?.map((region) => ({
        regionId: region.region.id,
        cashPrice:
          region.price === 0 ? "" : formatNumberWithDecimals(region.price),
      })),
    },
  });
  const {
    control,
    handleSubmit,
    formState: { isDirty, isSubmitting },
    watch,
  } = methods;
  const { fields } = useFieldArray({
    control: control,
    name: MONTHLY_PRICE_FIELD_NAME,
  });
  const { onSuccessToast, onErrorToast } = useCustomToast();
  const { mutateAsync: onSaveAsync, isPending: saveMonthlyAverageIsLoading } =
    useSetMonthlyAverage(cropYearId, month);
  const { data: regionOptions, isLoading: isRegionsLoading } =
    useRegionOptions();
  const disableSubmit = regions?.every((region) => region.price > 0);

  const onSubmit = async () => {
    const formData = watch() as MonthlyAveragePricingFormData;
    const data = transformMonthlyAveragePricingData(formData);
    try {
      await onSaveAsync(data);
      onSuccessToast({ message: "Successfully set monthly average" });
      onClose();
    } catch {
      onErrorToast({ message: "Failed to set monthly average" });
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          <Stack spacing={2}>
            {isRegionsLoading ? (
              <FormSkeletonLoadingState rowCount={8} />
            ) : (
              fields.map((field, index) => {
                const fieldId = `${MONTHLY_PRICE_FIELD_NAME}.${index}.cashPrice`;
                const region = regionOptions?.find(
                  (region) => region.id === field.regionId
                );

                return (
                  <MoneyInput
                    key={fieldId}
                    id={fieldId}
                    label={region?.name || ""}
                    isRequired={true}
                    isDisabled={disableSubmit}
                  />
                );
              })
            )}
          </Stack>
          <Stack>
            <SubmitButton
              h={12}
              submitDisabled={disableSubmit || !isDirty}
              isSubmitting={isSubmitting || saveMonthlyAverageIsLoading}
              buttonText="Save"
              w="100%"
            />
            <Button onClick={onClose} w="100%" variant="outline">
              Cancel
            </Button>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
};

const MonthlyAveragePricingModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const [month, setMonth] = useState<SelectOption | undefined>(monthOptions[0]);
  const { cropYearDetail } = useCropYearContext();
  const { data, isLoading } = useGetMonthlyAverageListByMonth(
    cropYearDetail?.id,
    month?.value
  );
  const isTextDisplayed = data?.regions?.every((region) => region.price > 0)
    ? "none"
    : "inline";

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        onKeyDown={(e) => e.stopPropagation()}
        maxW={CARD_MAX_WIDTH}
      >
        <ModalHeader>
          <TextHeading textStyle="h3" mb="2">
            Average Pricing
          </TextHeading>
        </ModalHeader>
        <ModalBody>
          <Stack spacing={6}>
            <Text fontSize="12px">
              Selecting save will update all of the average pricing contracts
              for the selected month.
            </Text>
            <Stack spacing={2}>
              <Text size="md" fontWeight="bold">
                Month
              </Text>
              <SingleSelect
                options={monthOptions}
                setSelectedOption={setMonth}
                selectedOption={month}
                aria-label={"Month Select"}
              />
            </Stack>
            <Divider pt={2} />
            <Text
              display={isTextDisplayed}
              fontSize="14px"
              fontWeight="bold"
              color="infoGreen"
            >
              For each region, add price before multiplier.
            </Text>
            {isLoading && !data ? (
              <FormSkeletonLoadingState rowCount={8} />
            ) : (
              <MonthlyAveragePricingForm
                month={data?.month}
                cropYearId={cropYearDetail?.id}
                regions={data?.regions}
                onClose={onClose}
              />
            )}
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default MonthlyAveragePricingModal;
