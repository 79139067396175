import { ReactElement, useMemo } from "react";
import {
  Link as RLink,
  Navigate,
  Outlet,
  useSearchParams,
} from "react-router-dom";

import {
  Box,
  Button,
  Center,
  HStack,
  Link,
  Spinner,
  Stack,
  TabList,
  Tabs,
} from "@chakra-ui/react";

import {
  DESKTOP_HEADER_PADDING,
  MOBILE_HEADER_PADDING,
  PageHeader,
} from "components/page-components";
import ContentWrapper, {
  CONTENT_AREA_PADDING,
  contentAreaWidthProps,
} from "components/ContentWrapper";
import { DesktopOnly } from "components/BreakpointVisibility";
import { DAILY_PRICING, DASHBOARD_TABS } from "constants/featureFlags";
import { mobileStyleBreakpoint } from "utils/styleHelpers";
import useLastMatchedPathname from "hooks/useLastMatchedPathname";
import useSelectedFarmId from "hooks/useSelectedFarmId";
import useNavigateWithQuery from "hooks/useNavigateWithQuery";
import useSelectedFarmDetail from "hooks/useSelectedFarmDetail";
import useUserRoleFlags from "hooks/auth/useUserRoleFlags";
import { useHasFeatureFlag } from "hooks/useFeatureFlags";
import dashboardPaths from "routes/dashboard/dashboardPaths";
import { DocumentIcon } from "icons";
import { useCropYearContext } from "contexts/CropYearContext";
import { useUserInfo } from "contexts/UserInfoProvider";
import CropYearMenu from "./components/CropYearMenu";
import NotificationHandler from "components/NotificationHandler";
import SummaryCards from "./components/SummaryCards";
import {
  AllFarmsTab,
  AllFieldsTab,
  AllPricingTab,
  DocumentsTab,
  FieldsTab,
  PricingTab,
} from "./DashboardTabs";
import CropYearSelect from "components/CropYearSelect/CropYearSelect";
import WithSpinner from "components/WithSpinner";
import useDefaultSelectedFarm from "hooks/farm/useDefaultSelectedFarm";
import farmPaths from "routes/farm/farmPaths";

const TOP_CONTENT_PX = mobileStyleBreakpoint(
  MOBILE_HEADER_PADDING,
  DESKTOP_HEADER_PADDING
);
const BOTTOM_CONTENT_PX = mobileStyleBreakpoint(0, DESKTOP_HEADER_PADDING);

const SilageDashboard = (): ReactElement => {
  /*
   Return the path the user is on based on location
   */
  const matchedPath = useLastMatchedPathname();
  const { isEmployee, farms } = useUserInfo();
  const { isEmployeeAdmin, isEmployeeOnly, isFarmAdmin } = useUserRoleFlags();
  const [searchParams] = useSearchParams();
  const { data: selectedFarm, isLoading: selectedFarmIsLoading } =
    useSelectedFarmDetail();
  const [selectedFarmId] = useSelectedFarmId();
  useDefaultSelectedFarm();

  const {
    cropYear,
    cropYearDetail,
    isPreHarvest,
    isLoading: cropYearContextIsLoading,
  } = useCropYearContext();

  const hasAllFields = useHasFeatureFlag(DASHBOARD_TABS.ALL_FIELDS);
  const hasDailyPricingFeature = useHasFeatureFlag(
    DAILY_PRICING.DAILY_PRICING_BUTTON
  );

  /*
  Tabs available on the dashboard for each role
   */

  const tabPaths = useMemo(() => {
    if (isEmployeeAdmin) {
      if (selectedFarmId) {
        return [
          dashboardPaths.children.pricing,
          dashboardPaths.children.fields,
          dashboardPaths.children.documents,
        ];
      } else {
        return hasAllFields
          ? [
              dashboardPaths.children.pricing,
              dashboardPaths.children.farms,
              dashboardPaths.children.allFields,
            ]
          : [dashboardPaths.children.pricing, dashboardPaths.children.farms];
      }
    }
    if (isEmployeeOnly) {
      if (selectedFarmId) {
        return [
          dashboardPaths.children.fields,
          dashboardPaths.children.documents,
        ];
      } else {
        return hasAllFields
          ? [dashboardPaths.children.farms, dashboardPaths.children.allFields]
          : [dashboardPaths.children.farms];
      }
    }
    if (isFarmAdmin) {
      return [
        dashboardPaths.children.pricing,
        dashboardPaths.children.fields,
        dashboardPaths.children.documents,
      ];
    }

    return [];
  }, [
    hasAllFields,
    isEmployeeAdmin,
    isEmployeeOnly,
    isFarmAdmin,
    selectedFarmId,
  ]);

  /*
   Return the index of the path the user is currently on or -1
   */
  const tabIndex = tabPaths.indexOf(matchedPath ?? "");
  const navigate = useNavigateWithQuery();
  const handleTabsChange = (index: number) => {
    navigate(`./${tabPaths[index]}`);
  };

  if (selectedFarmIsLoading) {
    return (
      <Center h="100%">
        <Spinner />
      </Center>
    );
  }

  if (!isEmployee && farms.length === 0) {
    return (
      /*
      dashboard/new
       */
      <Navigate to={`./${dashboardPaths.children.noFarms}?${searchParams}`} />
    );
  }

  if (!isEmployee && selectedFarm?.cropYears?.length === 0) {
    return (
      /*
      farm/edit
       */
      <Navigate
        to={`/${farmPaths.basePath}/${selectedFarmId}/${farmPaths.children.edit}?${searchParams}`}
      />
    );
  }

  /*
   If the users location wasn't matched with the available routes
   */
  if (tabIndex === -1) {
    return <Navigate to={`./${tabPaths[0]}?${searchParams}`} replace={true} />;
  }

  const displayDailyPricingButton = hasDailyPricingFeature && !selectedFarmId;

  return (
    <>
      {isFarmAdmin && isPreHarvest && (
        <NotificationHandler selectedFarmId={selectedFarmId} />
      )}

      <Stack spacing={0} h="100%">
        <Center
          pt={CONTENT_AREA_PADDING}
          px={TOP_CONTENT_PX}
          bg="dashboardBannerBackground"
          borderBottom="1px solid"
          borderColor="greyFactor.1.5"
        >
          <Stack
            spacing={{
              base: 6,
              md: 8,
            }}
            {...contentAreaWidthProps}
          >
            <Box>
              <PageHeader
                heading="Silage"
                subHeading={selectedFarm?.doingBusinessAs}
              >
                {cropYearDetail?.growerLetterLink && (
                  <Center>
                    <DesktopOnly>
                      <Button
                        as={Link}
                        isExternal
                        href={cropYearDetail?.growerLetterLink}
                        variant="link"
                        color={"brand.800"}
                        aria-label={"download"}
                        leftIcon={<DocumentIcon color={"brand.800"} />}
                      >
                        {cropYearDetail?.growerLetterName ??
                          `${cropYear?.year} Grower Letter`}
                      </Button>
                    </DesktopOnly>
                  </Center>
                )}
              </PageHeader>
            </Box>
            <HStack spacing={2}>
              <CropYearSelect />
              {isEmployeeAdmin && (
                <>
                  {displayDailyPricingButton && (
                    <Button
                      variant="primary"
                      as={RLink}
                      size="md"
                      to={`/${dashboardPaths.basePath}/${dashboardPaths.children.dailyPricing}?${searchParams}`}
                    >
                      Daily Pricing
                    </Button>
                  )}
                  <CropYearMenu />
                </>
              )}
            </HStack>
            <HStack
              pb={{
                base: 6,
                md: 8,
              }}
              overflowX={"auto"}
            >
              <SummaryCards />
            </HStack>
            <Tabs
              index={tabIndex}
              onChange={handleTabsChange}
              mt={"0 !important"}
            >
              <TabList borderBottom="none">
                {isEmployeeAdmin && (
                  <>
                    {selectedFarmId ? (
                      <>
                        <PricingTab />
                        <FieldsTab />
                        <DocumentsTab />
                      </>
                    ) : (
                      <>
                        <AllPricingTab />
                        <AllFarmsTab />
                        {hasAllFields && <AllFieldsTab />}
                      </>
                    )}
                  </>
                )}
                {isEmployeeOnly &&
                  (selectedFarmId ? (
                    <>
                      <FieldsTab />
                      <DocumentsTab />
                    </>
                  ) : (
                    <>
                      <AllFarmsTab />
                      {hasAllFields && <AllFieldsTab />}
                    </>
                  ))}
                {isFarmAdmin && (
                  <>
                    <PricingTab />
                    <FieldsTab />
                    <DocumentsTab />
                  </>
                )}
              </TabList>
            </Tabs>
          </Stack>
        </Center>
        <Center px={BOTTOM_CONTENT_PX} w="100%">
          <ContentWrapper pt={8}>
            <WithSpinner
              isLoading={!!cropYearContextIsLoading}
              centerProps={{ minH: "50%", h: "unset" }}
            >
              <Outlet
                context={{
                  cropYearId: cropYear?.id,
                }}
              />
            </WithSpinner>
          </ContentWrapper>
        </Center>
      </Stack>
    </>
  );
};

export default SilageDashboard;
