const cropYearBase = "crop-year";
const delayedPricingBase = "delayed-pricing";
const forwardPricingBase = "forward-pricing";

const dashboardPaths = {
  basePath: "dashboard",
  children: {
    noFarms: "new",
    cropYear: `${cropYearBase}`,
    newCropYear: `${cropYearBase}/new`,
    farms: "farms",
    fields: "fields",
    documents: "documents",
    allFields: "all-fields",
    pricing: "pricing",
    dailyPricing: "daily-pricing",
    delayedPricing: delayedPricingBase,
    newDelayedPricing: `${delayedPricingBase}/new`,
    newManualDelayedPricing: `manual-${delayedPricingBase}/new`,
    forwardPricing: forwardPricingBase,
    newForwardPricing: `${forwardPricingBase}/new`,
    newManualForwardPricing: `manual-${forwardPricingBase}/new`,
    reviewForwardPricing: `${forwardPricingBase}/review`,
  },
};

export default dashboardPaths;
