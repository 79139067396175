import { useMutation, useQueryClient } from "@tanstack/react-query";

import { MutationHookResult, MutationHook } from "../hookTypeHelpers";
import CropYearService from "services/CropYearService";
import { USE_CROP_YEAR_FARMS_QUERY_KEY } from "./useCropYearFarms";
import { GET_CROP_YEAR_ADDABLE_FARMS_QUERY_KEY } from "./useAddableFarms";
import { GET_FIELD_ADDABLE_FARMS_QUERY_KEY } from "../field/useGetFieldAddableFarms";

type AssignFarmToCropYearResult = MutationHookResult<
  Awaited<ReturnType<CropYearService["assignFarmToCropYear"]>>,
  { farmId: string; cropYearId?: string }
>;

const ASSIGN_FARM_TO_CROP_YEAR_QUERY_KEY = "assign-farm-to-crop-year";

const useAssignFarmToCropYearMutation = (): AssignFarmToCropYearResult => {
  const cropYearService = new CropYearService();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [ASSIGN_FARM_TO_CROP_YEAR_QUERY_KEY],
    mutationFn: ({ cropYearId, farmId }) =>
      cropYearId
        ? cropYearService.assignFarmToCropYear({ id: cropYearId, farmId })
        : Promise.reject("Must have crop year id"),
    onSuccess: () =>
      Promise.all([
        queryClient.refetchQueries({
          queryKey: [GET_CROP_YEAR_ADDABLE_FARMS_QUERY_KEY],
        }),
        queryClient.refetchQueries({
          queryKey: [USE_CROP_YEAR_FARMS_QUERY_KEY],
        }),
        queryClient.refetchQueries({
          queryKey: [GET_FIELD_ADDABLE_FARMS_QUERY_KEY],
        }),
      ]),
  });
};

const useAssignFarmToCropYear = (): MutationHook<
  AssignFarmToCropYearResult,
  "onSubmit"
> => {
  const {
    mutate: onSubmit,
    mutateAsync: onSubmitAsync,
    ...rest
  } = useAssignFarmToCropYearMutation();

  return { onSubmit, onSubmitAsync, ...rest };
};

export default useAssignFarmToCropYear;
