import { ComponentStyleConfig } from "@chakra-ui/react";

const Spinner: ComponentStyleConfig = {
  baseStyle: {
    color: "brand.800",
    borderWidth: "4px",
    borderBottomColor: "grayFactor.1.5",
    borderLeftColor: "grayFactor.1.5",
  },
  defaultProps: {
    size: "xl",
  },
};

export default Spinner;
