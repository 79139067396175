import { Stack, Text, Spacer, useBreakpointValue } from "@chakra-ui/react";
import BackToLogin from "components/BackToLogin";
import { DesktopOnly, MobileOnly } from "components/BreakpointVisibility";
import {
  ResponsiveCard,
  CardHeader,
  CardBody,
  CardFooter,
} from "components/Card";
import ForgotPasswordForm from "forms/forgot-password/ForgotPasswordForm";
import { ReactElement } from "react";
import { desktopOnly, CARD_MIN_WIDTH } from "utils/styleHelpers";

const EnterEmailCard = (): ReactElement => {
  const displayYour = useBreakpointValue(desktopOnly);

  return (
    <Stack>
      <ResponsiveCard minW={CARD_MIN_WIDTH}>
        <CardHeader>
          {`Forgot ${displayYour ? "Your " : ""}Password`}
        </CardHeader>
        <CardBody>
          <Stack w="100%" spacing={6}>
            <Text>Please enter your email address.</Text>
            <ForgotPasswordForm />
          </Stack>
        </CardBody>
        <MobileOnly>
          <CardFooter>
            <BackToLogin pt={0} />
          </CardFooter>
        </MobileOnly>
      </ResponsiveCard>
      <DesktopOnly>
        <BackToLogin />
      </DesktopOnly>
      <Spacer />
    </Stack>
  );
};
export default EnterEmailCard;
