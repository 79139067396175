/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface UserStatusDto
 */
export interface UserStatusDto {
  /**
   *
   * @type {string}
   * @memberof UserStatusDto
   */
  value: UserStatusDtoValueEnum;
  /**
   *
   * @type {string}
   * @memberof UserStatusDto
   */
  displayName: string;
}

/**
 * @export
 * @enum {string}
 */
export enum UserStatusDtoValueEnum {
  Pending = "PENDING",
  Active = "ACTIVE",
  Inactive = "INACTIVE",
}
