import { useMutation } from "@tanstack/react-query";

import { useCropYearService } from "contexts/ServiceProvider";
import useDashboardRefetchQueries from "./useDashboardRefetchQueries";

const DELETE_CROP_YEAR_FARMS_MUTATION_KEY = "delete-crop-year-farms";

const useDeleteFarmsFromCropYear = () => {
  const cropYearService = useCropYearService();
  const { refetch } = useDashboardRefetchQueries();

  return useMutation({
    mutationKey: [DELETE_CROP_YEAR_FARMS_MUTATION_KEY],
    mutationFn: ({
      cropYearId,
      farmIds,
    }: {
      cropYearId?: string;
      farmIds: Array<string>;
    }) => {
      if (!cropYearId) {
        throw new Error("Missing cropYearId");
      }
      if (farmIds.length === 0) {
        throw new Error("Missing farmIds");
      }

      return cropYearService.deleteFarmsFromCropYear(cropYearId, farmIds);
    },
    onSuccess: refetch,
  });
};

export default useDeleteFarmsFromCropYear;
