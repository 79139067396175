import { useMutation } from "@tanstack/react-query";

import { AveragePricingContractRequest } from "api";
import { usePricingOptionService } from "contexts/ServiceProvider";
import { MutationHookResult } from "hooks/hookTypeHelpers";
import PricingOptionService from "services/PricingOptionService";
import useDashboardRefetchQueries from "hooks/crop-year/useDashboardRefetchQueries";

type CreateAveragePricingContractMutationResult = MutationHookResult<
  Awaited<ReturnType<PricingOptionService["createAveragePricingContract"]>>,
  AveragePricingContractRequest
>;

export const CREATE_AVERAGE_PRICING_CONTRACT_QUERY_KEY =
  "create-average-pricing-contract";

const useCreateAveragePricingContract = (
  cropYearId: string | undefined,
  farmId: string | undefined
): CreateAveragePricingContractMutationResult => {
  const pricingOptionService = usePricingOptionService();
  const { refetch } = useDashboardRefetchQueries();

  return useMutation({
    mutationFn: (averagePricingContract) => {
      if (cropYearId && farmId) {
        return averagePricingContract
          ? pricingOptionService.createAveragePricingContract({
              cropYearId,
              farmId,
              averagePricingContract,
            })
          : Promise.reject(
              "Must provide valid average pricing contract information"
            );
      } else {
        return Promise.reject("Missing crop year id and farm id");
      }
    },
    mutationKey: [CREATE_AVERAGE_PRICING_CONTRACT_QUERY_KEY],
    onSuccess: refetch,
  });
};

export default useCreateAveragePricingContract;
