import { Text } from "@chakra-ui/react";
import { ColumnProps, TableData } from "components/SortableTable";
import { FieldsListSortColumns } from "services/FieldService";
import { AllFieldListItem } from "hooks/field/useAllFieldsList";
import { fieldListItemMapper } from "pages/dashboard/tabs/FieldsListTab/FieldsListTable";

export const allFieldsListTableColumns = ({
  data,
  isPreHarvest,
  isEmployee,
}: {
  data: AllFieldListItem[];
  isPreHarvest?: boolean;
  isEmployee?: boolean;
}): TableData[] =>
  data.map(({ farms, region, ...rest }) => {
    const farm = (farms?.length ?? 0) > 1 ? "Multiple" : farms;

    return {
      ...fieldListItemMapper({ ...rest, isPreHarvest, isEmployee }),
      farm: <Text>{farm ?? ""}</Text>,
      region: <Text>{region ?? ""}</Text>,
    };
  });

type ColumnList = ColumnProps<FieldsListSortColumns>[];

export const baseFieldsListColumns: ColumnList = [
  { heading: "Field#", id: "fieldNumber", sortKey: "fieldNumber" },
  { heading: "Total Acres", id: "totalAcres", sortKey: "totalAcres" },
];

const baseDesktopAllFieldsListColumns: ColumnList = [
  { heading: "Dairy", id: "dairy", sortKey: "dairy.name" },
  {
    heading: "Legal Description",
    id: "legalDescription",
    sortKey: "legalDescription",
  },
  { heading: "Farm", id: "farm" },
  {
    heading: "Region",
    id: "region",
    sortKey: "splits.cropYearFarm.farm.region.name",
  },
];
export const desktopRVAllFieldsListColumnsPreHarvest: ColumnList = [
  ...baseFieldsListColumns,
  ...baseDesktopAllFieldsListColumns,
];
export const desktopRVAllFieldsListColumnsPostHarvest: ColumnList = [
  ...baseFieldsListColumns,
  { heading: "Tons per Acre", id: "tonsPerAcre", sortKey: "tonsPerAcre" },
  ...baseDesktopAllFieldsListColumns,
];
