import { ReactElement, useEffect, useMemo, useState } from "react";

import { Center, Spinner, Stack } from "@chakra-ui/react";

import {
  allPricingBaseColumns,
  postHarvestAllPricingColumns,
  preHarvestAllPricingColumns,
  riverviewPricingListTableColumns,
} from "../pricingTableColumnDefinitions";
import useUserRoleFlags from "hooks/auth/useUserRoleFlags";
import useCropYearPricingOptions from "hooks/pricing-option/useCropYearPricingOptions";
import useSort, {
  DEFAULT_SORT_DIRECTION,
  Sort,
  addSecondarySort,
  ColumnsWithSecondarySort,
  addMultiSort,
  ColumnsWithTertiarySort,
  SortDirection,
} from "hooks/useSort";
import { DesktopOnly } from "components/BreakpointVisibility";
import FetchMoreWhenInView from "components/FetchMoreWhenInView";
import { TABLE_MARGIN_TOP } from "components/SortableTable";
import { useCropYearContext } from "contexts/CropYearContext";
import SilageDashboardTabLayout from "layouts/SilageDashboardTabLayout";
import DashboardListTable from "pages/dashboard/components/DashboardListTable";
import { AllPricingMenu } from "../PricingMenu";
import { CropYearPricingOptionsSortColumns } from "services/PricingOptionService";
import { mobileStyleBreakpoint } from "utils/styleHelpers";

const preHarvestTableColumns = mobileStyleBreakpoint(
  allPricingBaseColumns,
  preHarvestAllPricingColumns,
  "md"
);

const postHarvestTableColumns = mobileStyleBreakpoint(
  allPricingBaseColumns,
  postHarvestAllPricingColumns,
  "md"
);

const defaultSort: Sort<CropYearPricingOptionsSortColumns> = {
  key: "status",
  direction: DEFAULT_SORT_DIRECTION,
};

const columnsWithSecondarySortPreHarvest: ColumnsWithSecondarySort<CropYearPricingOptionsSortColumns> =
  {
    region: {
      key: "farm",
      direction: DEFAULT_SORT_DIRECTION,
    },
    farm: {
      key: "type",
      direction: DEFAULT_SORT_DIRECTION,
    },
    status: {
      key: "farm",
      direction: DEFAULT_SORT_DIRECTION,
    },
    type: {
      key: "farm",
      direction: DEFAULT_SORT_DIRECTION,
    },
    dateOfContract: {
      key: "farm",
      direction: DEFAULT_SORT_DIRECTION,
    },
  };

const columnsWithSecondarySortPostHarvest: ColumnsWithSecondarySort<CropYearPricingOptionsSortColumns> =
  {
    dateOfContract: {
      key: "farm",
      direction: DEFAULT_SORT_DIRECTION,
    },
    farm: {
      key: "dateOfContract",
      direction: SortDirection.DESC,
    },
    status: { key: "farm", direction: DEFAULT_SORT_DIRECTION },
  };

const columnsWithTertiarySort: ColumnsWithTertiarySort<CropYearPricingOptionsSortColumns> =
  {
    status: {
      key: "dateOfContract",
      direction: SortDirection.DESC,
    },
  };

const RiverviewDashboardAllPricingListTab = (): ReactElement => {
  const [searchTerm, setSearchTerm] = useState("");
  const {
    isLoading: cropYearLoading,
    cropYear,
    isPreHarvest,
  } = useCropYearContext();

  const { sort, toggleSort, setSort } =
    useSort<CropYearPricingOptionsSortColumns>(defaultSort);
  const { isEmployeeAdmin } = useUserRoleFlags();
  const {
    data,
    isLoading,
    isFetchingNextPage: isFetchingNextCropYearPricingPage,
    fetchNextPage: fetchNextCropYearPricingPage,
    hasNextPage: hasNextCropYearPricingPage,
  } = useCropYearPricingOptions({
    cropYearId: cropYear?.id,
    queryParams: {
      search: searchTerm,
      sort: isPreHarvest
        ? addSecondarySort(sort, columnsWithSecondarySortPreHarvest)
        : addMultiSort(
            sort,
            columnsWithSecondarySortPostHarvest,
            columnsWithTertiarySort
          ),
    },
  });

  const cropYearPricingData = useMemo(
    () => data?.pages?.flatMap((response) => response.data) ?? [],
    [data?.pages]
  );

  const tableData = useMemo(
    () =>
      riverviewPricingListTableColumns({
        data: cropYearPricingData,
        isPreHarvest,
      }),
    [cropYearPricingData, isPreHarvest]
  );

  useEffect(() => {
    setSort(defaultSort);
  }, [setSort, searchTerm, cropYear?.id]);

  const showPricingButton = !isPreHarvest && isEmployeeAdmin;

  return (
    <SilageDashboardTabLayout
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      heading={"All Pricing"}
      addButton={
        showPricingButton ? (
          <DesktopOnly>
            <AllPricingMenu />
          </DesktopOnly>
        ) : undefined
      }
    >
      {isLoading || cropYearLoading ? (
        <Center h="100%">
          <Spinner />
        </Center>
      ) : (
        <Stack spacing={TABLE_MARGIN_TOP} w="100%">
          <DashboardListTable
            data={tableData}
            sort={sort}
            onClickRow={() => {
              return;
            }}
            toggleSort={toggleSort}
            columns={
              isPreHarvest ? preHarvestTableColumns : postHarvestTableColumns
            }
            emptyTableMsg={
              searchTerm.length === 0
                ? "No pricing options."
                : "No Results Found"
            }
          />
          {tableData.length > 0 && (
            <FetchMoreWhenInView
              fetchNextPage={fetchNextCropYearPricingPage}
              hasNextPage={hasNextCropYearPricingPage}
              isFetchingNextPage={isFetchingNextCropYearPricingPage}
              key={`${data?.pages.length}`}
            />
          )}
        </Stack>
      )}
    </SilageDashboardTabLayout>
  );
};

export default RiverviewDashboardAllPricingListTab;
