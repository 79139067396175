import { ReactElement, useMemo } from "react";

import {
  Path,
  useController,
  useFormContext,
  FieldValues,
} from "react-hook-form";

import Select from "components/Select";
import useFarmUsersList from "hooks/user/useFarmUsersList";
import InputWrapper, { InputWrapperProps } from "./InputWrapper";
import useUserRoleFlags from "../../hooks/auth/useUserRoleFlags";

const FarmUsersSelectLabeledInput = <T extends FieldValues>({
  id,
  farmId,
  ...rest
}: {
  id: Path<T>;
  farmId: string | undefined;
} & InputWrapperProps): ReactElement => {
  const { isEmployeeOnly } = useUserRoleFlags();
  const {
    data,
    isLoading,
    isError,
    error: farmUsersListError,
  } = useFarmUsersList(farmId, !isEmployeeOnly);
  const { control, getValues } = useFormContext<T>();

  const {
    field,
    fieldState: { error },
  } = useController<T>({
    name: id,
    control: control,
  });

  const userOptions = useMemo(
    () =>
      isEmployeeOnly
        ? [getValues(id)]
        : data?.users.map((user) => ({
            label: user.name,
            value: user.id,
          })) ?? [],
    [data, getValues, id, isEmployeeOnly]
  );

  return (
    <InputWrapper
      isRequired
      isDisabled={isLoading}
      isInvalid={!!error}
      errorMsg={error?.message}
      {...rest}
    >
      <Select
        options={userOptions}
        {...field}
        isLoading={isLoading}
        isError={isError}
        errorMsg={farmUsersListError?.message}
      />
    </InputWrapper>
  );
};

export default FarmUsersSelectLabeledInput;
