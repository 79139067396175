import { ReactNode, useState } from "react";

import { useDisclosure } from "@chakra-ui/react";

import InfoAlert from "components/InfoAlert/InfoAlert";

export type AlertProps = {
  title?: string;
  msg?: string | JSX.Element;
};

const useInfoAlert = ({
  msg,
  title,
}: AlertProps): {
  alert: ReactNode;
  isOpen: boolean;
  onOpen: (props: AlertProps) => void;
  onClose: () => void;
} => {
  const [alertMsg, setAlertMsg] = useState(msg);
  const [alertTitle, setAlertTitle] = useState(title);
  const { isOpen, onOpen, onClose } = useDisclosure();

  return {
    alert: (
      <InfoAlert
        isOpen={isOpen}
        onClose={onClose}
        msg={alertMsg}
        title={alertTitle}
      />
    ),
    isOpen: isOpen,
    onOpen: ({ msg: _msg, title: _title }: AlertProps) => {
      if (_msg) {
        setAlertMsg(_msg);
      }
      if (_title) {
        setAlertTitle(_title);
      }
      onOpen();
    },
    onClose,
  };
};

export default useInfoAlert;
