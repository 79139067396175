import { Checkbox } from "@chakra-ui/react";
import { Controller, useFormContext } from "react-hook-form";

import { EditDelayedPricingFormData } from "../EditDelayedPricingForm";

const MarkAsReviewed = ({ completedReview }: { completedReview: boolean }) => {
  const { control } = useFormContext<EditDelayedPricingFormData>();

  return (
    <Controller
      control={control}
      name="reviewed"
      render={({ field: { onChange, value, ref } }) => (
        <Checkbox
          variant="form"
          onChange={onChange}
          ref={ref}
          isChecked={value}
          disabled={completedReview}
          iconColor="white"
        >
          {completedReview ? "Reviewed" : "Mark as Reviewed"}
        </Checkbox>
      )}
    />
  );
};

export default MarkAsReviewed;
