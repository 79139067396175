import { setAuthHeader } from "hooks/auth/useAuthTokens";
import { clearRefreshToken, storeAuthFailure } from "./authLocalStorage";

export const clearAuthData = (showToast = false) => {
  if (showToast) {
    storeAuthFailure();
  }
  clearRefreshToken();
  setAuthHeader();
};
