import { useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DelayedPricingForm } from "forms/delayed-pricing";
import useGetDelayedPricingLockedInData from "hooks/pricing-option/useGetDelayedPricingLockedInData";
import useSelectedCropYearId, {
  CROP_YEAR_ID_QUERY_STRING_KEY,
} from "hooks/useSelectedCropYearId";
import useSelectedFarmId from "hooks/useSelectedFarmId";
import dashboardPaths from "routes/dashboard/dashboardPaths";
import PricingContractPageLayout from "pages/pricing/PricingContractPageLayout/PricingContractPageLayout";
import useCropYearDetail from "hooks/crop-year/useCropYearDetails";
import useFuturesPriceError from "hooks/futures/useFuturesPriceError";

const DelayedPricingPage = ({
  isManuallyCreated = false,
}: {
  isManuallyCreated?: boolean;
}) => {
  const [selectedCropYearId] = useSelectedCropYearId();
  const [selectedFarmId] = useSelectedFarmId();
  const [searchParams] = useSearchParams();
  const { data: cropYearDetail, isLoading: isCropYearDetailLoading } =
    useCropYearDetail(selectedCropYearId);
  const navigate = useNavigate();
  const navigateToFarmView = useCallback(
    () =>
      navigate(
        `/${dashboardPaths.basePath}/${dashboardPaths.children.pricing}?${searchParams}`
      ),
    [navigate, searchParams]
  );
  const navigateToAllPricingView = useCallback(
    () =>
      navigate(
        `/${dashboardPaths.basePath}/${dashboardPaths.children.pricing}?${CROP_YEAR_ID_QUERY_STRING_KEY}=${selectedCropYearId}`
      ),
    [navigate, selectedCropYearId]
  );

  const {
    data: currentDelayedPricingData,
    isLoading: isCurrentDelayedPricingDataLoading,
    isRefetching: currentDelayedPricingDataIsRefetching,
    isStale: isCurrentDelayedPricingStale,
    refetch: refetchCurrentDelayedPricing,
    isError,
  } = useGetDelayedPricingLockedInData(selectedCropYearId, selectedFarmId);
  useFuturesPriceError(isError);

  return (
    <PricingContractPageLayout
      contractHeader="Delayed Pricing"
      isLoading={
        isCurrentDelayedPricingDataLoading ||
        isCropYearDetailLoading ||
        currentDelayedPricingDataIsRefetching
      }
      loadingStateText={"Fetching Prices..."}
    >
      <DelayedPricingForm
        cropYearDetail={cropYearDetail}
        currentDelayedPricingData={currentDelayedPricingData}
        navigateToFarmView={navigateToFarmView}
        navigateToAllPricingView={navigateToAllPricingView}
        isCurrentDelayedPricingStale={isCurrentDelayedPricingStale}
        refetchCurrentDelayedPricing={refetchCurrentDelayedPricing}
        isManuallyCreated={isManuallyCreated}
      />
    </PricingContractPageLayout>
  );
};

export default DelayedPricingPage;
