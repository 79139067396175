import { Button, Text } from "@chakra-ui/react";

import { useRegistrationForm } from "contexts/RegistrationFormContext";
import ProfilePictureAvatar from "components/ProfilePictureAvatar";
import { CardBody, CardHeader } from "components/Card";
import ProfilePictureCard, {
  ProfilePictureCardFooter,
} from "./ProfilePictureCard";
import BackToPhoneNumberLink from "../components/BackToPhoneNumberLink";
import GoToTermsButton from "../components/GoToTermsButton";

const NoProfilePictureCard = ({
  onShowUpload,
}: {
  onShowUpload: () => void;
}) => {
  const {
    basicUserInfo: { firstName, lastName },
    imagePreviewUrl,
  } = useRegistrationForm();

  return (
    <ProfilePictureCard>
      <ProfilePictureAvatar
        name={`${firstName} ${lastName}`}
        src={imagePreviewUrl}
      />
      <CardHeader>Profile Picture</CardHeader>
      <CardBody>
        <Text>
          Add a profile picture or continue with your initial. Accepted file
          types are .jpg or .png.
        </Text>
      </CardBody>
      <ProfilePictureCardFooter>
        <Button variant="primary" onClick={onShowUpload}>
          Add profile picture
        </Button>
        <GoToTermsButton variant="outline">Skip</GoToTermsButton>
        <BackToPhoneNumberLink />
      </ProfilePictureCardFooter>
    </ProfilePictureCard>
  );
};

export default NoProfilePictureCard;
