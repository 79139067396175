import { PropsWithChildren } from "react";
import { Text, TextProps } from "@chakra-ui/react";
import { fontFamilies } from "../theme/foundations/typography";

export type TextHeadingProps = TextProps & {
  headingType?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
};

const TextHeading = ({
  headingType = "h4",
  children,
  ...rest
}: PropsWithChildren<TextHeadingProps>) => (
  <Text as={headingType} fontFamily={fontFamilies.heading} {...rest}>
    {children}
  </Text>
);

export default TextHeading;
