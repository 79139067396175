import { useQuery } from "@tanstack/react-query";

import { FieldResponse } from "api";
import { useFieldService } from "contexts/ServiceProvider";
import { BaseApiError } from "services/apiHelpers";

export const GET_FIELD_DETAIL_QUERY_KEY = "get-field-detail";

const useGetFieldDetail = (fieldId?: string, farmId?: string) => {
  const fieldService = useFieldService();

  return useQuery<FieldResponse | undefined, BaseApiError>({
    queryKey: [GET_FIELD_DETAIL_QUERY_KEY, { id: fieldId, farmId }],
    queryFn: () =>
      fieldId && farmId
        ? fieldService.getFieldDetail(fieldId, farmId)
        : Promise.reject("Missing field ID or farm ID"),
    enabled: !!fieldId && !!farmId,
  });
};

export default useGetFieldDetail;
