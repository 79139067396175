import {
  HaylageYearApi,
  FarmListDto,
  FarmDocumentDto,
  HaylageYearApiAssignFarmsToHaylageYearRequest,
  HaylageYearFarmResponse,
  HaylageYearApiListHaylageYearsRequest,
  HaylageYearApiGetHaylageYearRequest,
} from "api";
import { HaylageYearResponse } from "api/models/haylage-year-response";
import {
  extractResponseData,
  extractResponseDataWithMeta,
  PaginationMeta,
  processResponse,
  QueryParams,
  transformQueryParamsForRequest,
} from "./apiHelpers";
import { constructApiControllerInstance } from "./customApiConfig";

export type GetHaylageYearResponse = {
  data: HaylageYearResponse[];
  meta: PaginationMeta;
};

export type GetHaylageYearFarmsResponse = {
  data: HaylageYearFarmResponse[];
  meta: PaginationMeta;
};
export type GetHaylageYearAddableFarmsResponse = {
  data: HaylageYearFarmResponse[];
  meta: PaginationMeta;
};

export type GetHaylageYearFarmDocumentsResponse = {
  data: FarmDocumentDto[];
  meta: PaginationMeta;
};

export type HaylageAddableFarmsSortColumns = "doingBusinessAs" | "region.name";

class HaylageYearService {
  haylageYearController!: HaylageYearApi;
  constructor() {
    this.haylageYearController = constructApiControllerInstance(HaylageYearApi);
  }

  async getHaylageYears(
    request: HaylageYearApiListHaylageYearsRequest
  ): Promise<GetHaylageYearResponse> {
    const response = await this.haylageYearController.listHaylageYears(request);

    return extractResponseDataWithMeta(response);
  }

  async getHaylageYear(
    request: HaylageYearApiGetHaylageYearRequest
  ): Promise<HaylageYearResponse> {
    return this.haylageYearController
      .getHaylageYear(request)
      .then(extractResponseData);
  }

  async getHaylageYearFarms(
    id: string,
    queryParams?: QueryParams
  ): Promise<GetHaylageYearFarmsResponse> {
    const response = await this.haylageYearController.listHaylageYearFarms({
      id,
      ...transformQueryParamsForRequest(queryParams),
    });

    return extractResponseDataWithMeta(response);
  }

  async getAddableFarmsForHaylageYear(
    id: string,
    queryParams?: QueryParams
  ): Promise<FarmListDto[]> {
    const response = await this.haylageYearController.listAddableFarms({
      id,
      ...transformQueryParamsForRequest(queryParams),
    });

    return extractResponseData(response);
  }

  async getHaylageYearFarmDocuments(
    haylageYearId: string,
    farmId: string,
    queryParams?: QueryParams
  ): Promise<GetHaylageYearFarmDocumentsResponse> {
    const response =
      await this.haylageYearController.getHaylageYearFarmDocumentsPage({
        id: haylageYearId,
        farmId,
        farmId2: farmId,
        ...transformQueryParamsForRequest(queryParams),
      });

    return extractResponseDataWithMeta(response);
  }

  async assignFarmsToHaylageYear(
    request: HaylageYearApiAssignFarmsToHaylageYearRequest
  ): Promise<string> {
    return await this.haylageYearController
      .assignFarmsToHaylageYear(request)
      .then(processResponse);
  }
}

export default HaylageYearService;
