import ProgramListPageLayout from "components/ProgramListPageLayout";
import useGetManureYearFarmDocuments from "hooks/api/manure-year/useGetManureYearFarmDocuments";
import useFarmDetail from "hooks/farm/farm-detail/useFarmDetail";
import useSelectedFarmId from "hooks/useSelectedFarmId";
import useSelectedManureYearId from "hooks/useSelectedManureYearId";
import DocumentsList from "components/DocumentsList";
import ManureYearSelect from "components/ManureYearSelect";

const ManureYearFarmDocumentList = () => {
  const [manureYearId] = useSelectedManureYearId();
  const [farmId] = useSelectedFarmId();
  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useGetManureYearFarmDocuments(manureYearId, farmId);

  return (
    <DocumentsList
      allowDeletion={false}
      farmId={farmId}
      pages={data?.pages}
      isLoading={isLoading}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
    />
  );
};

const ManureYearFarmDocumentListPage = () => {
  const [selectedFarmId] = useSelectedFarmId();
  const { data, isLoading } = useFarmDetail(selectedFarmId);

  return (
    <ProgramListPageLayout
      heading="Manure"
      subHeading={data?.doingBusinessAs}
      pageTitle="Documents"
      displayAddFarmsButton={false}
      yearSelect={<ManureYearSelect />}
      isLoading={isLoading}
    >
      <ManureYearFarmDocumentList />
    </ProgramListPageLayout>
  );
};

export default ManureYearFarmDocumentListPage;
