import { Box, Center, Spinner, Stack } from "@chakra-ui/react";
import { useMemo } from "react";

import DeleteFarmDocumentsButton from "./DeleteFarmDocuments";
import DocumentListItem from "components/DocumentListItem";
import EmptyState from "components/EmptyState";
import FetchMoreWhenInView from "components/FetchMoreWhenInView";
import { TABLE_MARGIN_TOP } from "components/SortableTable";
import useUserRoleFlags from "hooks/auth/useUserRoleFlags";
import useSelectionIds from "hooks/useSelectionIds";
import { FarmDocumentPage } from "types/common/farmDocumentPage";

const DocumentsList = ({
  allowDeletion = true,
  farmId,
  pages,
  isLoading,
  isFetchingNextPage,
  fetchNextPage,
  hasNextPage,
}: {
  allowDeletion?: boolean;
  farmId?: string;
  pages?: FarmDocumentPage[];
  isLoading: boolean;
  isFetchingNextPage: boolean;
  fetchNextPage: () => void;
  hasNextPage?: boolean;
}) => {
  const { isEmployeeAdmin } = useUserRoleFlags();

  const documents = useMemo(
    () => pages?.map(({ data }) => data).flat() ?? [],
    [pages]
  );

  const { selectedIds, onToggleId, clearSelectedIds } = useSelectionIds(
    documents?.map((document) => document.id)
  );

  return (
    <>
      {isLoading ? (
        <Center h="100%">
          <Spinner />
        </Center>
      ) : (
        <Stack spacing={TABLE_MARGIN_TOP}>
          <Stack spacing={1}>
            {allowDeletion && isEmployeeAdmin && documents.length > 0 && (
              <Box w="100%" justifyContent="flex-start" pb={2} mb={1}>
                <DeleteFarmDocumentsButton
                  documentIds={selectedIds}
                  farmId={farmId}
                  onSuccess={clearSelectedIds}
                />
              </Box>
            )}
            {documents?.map(({ id, linkDisplayName, linkToDocument }) => {
              return (
                <DocumentListItem
                  key={id}
                  documentId={id}
                  linkDisplayName={linkDisplayName}
                  linkToDocument={linkToDocument}
                  selectRow={isEmployeeAdmin && allowDeletion}
                  onSelectRow={onToggleId}
                />
              );
            })}
          </Stack>
          {documents?.length === 0 && (
            <EmptyState>
              No Documents have been uploaded yet from Riverview.
            </EmptyState>
          )}
          {documents && documents.length > 0 && (
            <FetchMoreWhenInView
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
              key={`${pages?.length}`}
            />
          )}
        </Stack>
      )}
    </>
  );
};

export default DocumentsList;
