import { ChangeEvent, useCallback } from "react";
import { Text } from "@chakra-ui/react";
import StandardInput from "../StandardInput";
import { formatNumberWithDecimals } from "utils/formatFunctions";
import { exceedsMaxDecimalDigits } from "utils/numberUtils";
import { useFormContext } from "react-hook-form";
import { preventNonNumericCharacters } from "utils/inputUtils";

interface PercentageInputProps {
  id: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const PercentageAddon = () => (
  <Text color="steelGray" mr="8px">
    %
  </Text>
);

const PercentageInput = ({ id, value, onChange }: PercentageInputProps) => {
  const { setValue } = useFormContext();
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const valueString = e.target.value;

    if (exceedsMaxDecimalDigits(valueString, 2)) {
      return;
    }
    onChange(e);
  };

  const handleBlur = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const formatToTwoDecimals = formatNumberWithDecimals(
        isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber,
        2,
        false
      );
      setValue(id, formatToTwoDecimals);
    },
    [id, setValue]
  );

  return (
    <StandardInput
      hideAddonBackground={true}
      rightAddon={<PercentageAddon />}
      step="0.01"
      type="number"
      value={value}
      onChange={handleInputChange}
      onKeyDown={preventNonNumericCharacters}
      onBlur={handleBlur}
    />
  );
};

export default PercentageInput;
