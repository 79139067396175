import { ChakraTheme } from "@chakra-ui/react";

const fonts = {
  heading: `Montserrat -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
  body: `Open Sans -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
  mono: `SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace`,
};

export { fonts as fontFamilies };

const fontSizes = {
  tiny: "0.625rem", // 10 - unused
  xs: "0.75rem", // 12
  sm: "0.875rem", // 14
  md: "1rem", // 16
  lg: "1.125rem", // 18
  xl: "1.25rem", // 20
  "2xl": "1.5rem", // 24
  "3xl": "2rem", // 32
  "4xl": "2.25rem", // 36 - unused
  "5xl": "3rem", // 48
  "6xl": "3.75rem", // 60 - unused
  "7xl": "4.5rem", // 72
};

const lineHeights = {
  normal: "normal", // - unused
  none: 1, // - unused
  shorter: 1.25, // - unused
  short: 1.375, // - unused
  base: 1.5, // - unused
  tall: 1.625, // - unused
  taller: "2", // - unused
  "3": ".75rem", // 12 - unused
  "4": "1rem", // 16
  "4.5": "1.125rem", // 18
  "5.25": "1.31rem", // 21
  "6": "1.5rem", // 24
  "6.5": "1.625rem", // 26
  "7": "1.75rem", // 28
  "8": "2rem", // 32
  "10": "2.5rem", // 40
  "14": "3.5rem", // 56
  "20": "5rem", // 80
};

const letterSpacings = {
  tightest: "-0.02rem", // -0.32px
  tighter: "-0.01rem", // -0.16px
  tight: "-0.005rem", // -0.8px
  normal: "0",
  wide: "0.005rem", // 0.8px
  wider: "0.01rem", // 0.16px
  widest: "0.02rem", // 0.32px
};

const fontWeights = {
  hairline: 100,
  thin: 200,
  light: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  extrabold: 800,
  black: 900,
};

const typography: Partial<ChakraTheme> = {
  fonts,
  fontSizes,
  lineHeights,
  letterSpacings,
  fontWeights,
};

export default typography;
