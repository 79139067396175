import { PropsWithChildren, useMemo } from "react";
import { Navigate, useParams } from "react-router-dom";

import { CropYearRegionDto } from "api";
import FormSkeletonLoadingState from "components/FormSkeletonLoadingState";
import dashboardPaths from "routes/dashboard/dashboardPaths";
import useCropYearDetail from "hooks/crop-year/useCropYearDetails";
import useNextCropYear from "hooks/crop-year/useNextCropYear";
import CropYearFormContext from "./CropYearFormContext";

const CropYearDataWrapper = ({ children }: PropsWithChildren) => {
  const { id } = useParams<{ id: string }>();

  const { data, isLoading } = useCropYearDetail(id);
  const { data: nextYearData, isLoading: nextYearIsLoading } =
    useNextCropYear(id);

  const nextYearRegions: CropYearRegionDto[] | undefined = useMemo(
    () =>
      nextYearData?.regions.map((region) => ({
        regionId: region.id,
        estimatedTonsPerAcre: region.estimatedTonsPerAcre,
        multiplier: region.multiplier,
      })),
    [nextYearData?.regions]
  );

  if (isLoading || nextYearIsLoading) {
    return <FormSkeletonLoadingState rowCount={8} />;
  }

  if ((id && !data) || (!id && !nextYearData)) {
    return <Navigate to={`${dashboardPaths.basePath}`} />;
  }

  return (
    <CropYearFormContext
      data={{
        ...data,
        year: data?.year ?? nextYearData?.year,
        regions: data?.regions ?? nextYearRegions,
      }}
    >
      {children}
    </CropYearFormContext>
  );
};
export default CropYearDataWrapper;
