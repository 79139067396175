/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosRequestConfig,
} from "axios";
import { Configuration } from "../configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "../base";
// @ts-ignore
import { CreateCropYearRequest } from "../models";
// @ts-ignore
import { DataAveragePricingDto } from "../models";
// @ts-ignore
import { DataCropYearDetailResponse } from "../models";
// @ts-ignore
import { DataCropYearFarmSummaryDto } from "../models";
// @ts-ignore
import { DataCropYearRegionResponse } from "../models";
// @ts-ignore
import { DataCropYearSummaryDto } from "../models";
// @ts-ignore
import { DataListMinimumFarmListDto } from "../models";
// @ts-ignore
import { DataMonthlyAverageResponseDto } from "../models";
// @ts-ignore
import { DataNextCropYearDetailResponse } from "../models";
// @ts-ignore
import { PageResponseCropYearFarmDto } from "../models";
// @ts-ignore
import { PageResponseCropYearListDto } from "../models";
// @ts-ignore
import { PageResponseFarmDocumentDto } from "../models";
// @ts-ignore
import { RemoveFarmsFromCropYearRequest } from "../models";
// @ts-ignore
import { SetMonthlyAveragePriceRequest } from "../models";
// @ts-ignore
import { UpdateCropYearRequest } from "../models";
/**
 * CropYearApi - axios parameter creator
 * @export
 */
export const CropYearApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {string} id
     * @param {string} farmId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    assignFarmToCropYear: async (
      id: string,
      farmId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("assignFarmToCropYear", "id", id);
      // verify required parameter 'farmId' is not null or undefined
      assertParamExists("assignFarmToCropYear", "farmId", farmId);
      const localVarPath = `/crop-year/{id}/farm/{farm-id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"farm-id"}}`, encodeURIComponent(String(farmId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CreateCropYearRequest} createCropYearRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCropYear: async (
      createCropYearRequest: CreateCropYearRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createCropYearRequest' is not null or undefined
      assertParamExists(
        "createCropYear",
        "createCropYearRequest",
        createCropYearRequest
      );
      const localVarPath = `/crop-year`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createCropYearRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCropYearDetail: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getCropYearDetail", "id", id);
      const localVarPath = `/crop-year/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {string} regionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCropYearRegion: async (
      id: string,
      regionId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getCropYearRegion", "id", id);
      // verify required parameter 'regionId' is not null or undefined
      assertParamExists("getCropYearRegion", "regionId", regionId);
      const localVarPath = `/crop-year/{id}/region/{region-id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"region-id"}}`, encodeURIComponent(String(regionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCropYearSummary: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getCropYearSummary", "id", id);
      const localVarPath = `/crop-year/{id}/summary`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} farmId
     * @param {string} id
     * @param {string} pathFarmId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFarmSummary: async (
      farmId: string,
      id: string,
      pathFarmId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'farmId' is not null or undefined
      assertParamExists("getFarmSummary", "farmId", farmId);
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getFarmSummary", "id", id);
      // verify required parameter 'pathFarmId' is not null or undefined
      assertParamExists("getFarmSummary", "pathFarmId", pathFarmId);
      const localVarPath = `/crop-year/{id}/farm/{path-farm-id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"path-farm-id"}}`, encodeURIComponent(String(pathFarmId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (farmId !== undefined && farmId !== null) {
        localVarHeaderParameter["farm-id"] = String(farmId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFarmsNotAssignedToCropYear: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getFarmsNotAssignedToCropYear", "id", id);
      const localVarPath = `/crop-year/{id}/addable-farms`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {string} month
     * @param {string} regionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMonthlyAverage: async (
      id: string,
      month: string,
      regionId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getMonthlyAverage", "id", id);
      // verify required parameter 'month' is not null or undefined
      assertParamExists("getMonthlyAverage", "month", month);
      // verify required parameter 'regionId' is not null or undefined
      assertParamExists("getMonthlyAverage", "regionId", regionId);
      const localVarPath =
        `/crop-year/{id}/monthly-average/month/{month}/region/{region-id}`
          .replace(`{${"id"}}`, encodeURIComponent(String(id)))
          .replace(`{${"month"}}`, encodeURIComponent(String(month)))
          .replace(`{${"region-id"}}`, encodeURIComponent(String(regionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {string} month
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMonthlyAverageListByMonth: async (
      id: string,
      month: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getMonthlyAverageListByMonth", "id", id);
      // verify required parameter 'month' is not null or undefined
      assertParamExists("getMonthlyAverageListByMonth", "month", month);
      const localVarPath = `/crop-year/{id}/monthly-average/month/{month}`
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"month"}}`, encodeURIComponent(String(month)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNextCropYearDetails: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/crop-years/next`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {string} [search]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listCropYearFarms: async (
      id: string,
      search?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("listCropYearFarms", "id", id);
      const localVarPath = `/crop-year/{id}/farm`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (search !== undefined) {
        localVarQueryParameter["search"] = search;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sort) {
        localVarQueryParameter["sort"] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listCropYears: async (
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/crop-years`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sort) {
        localVarQueryParameter["sort"] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {string} farmId
     * @param {string} [farmId2]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listSilageYearFarmDocuments: async (
      id: string,
      farmId: string,
      farmId2?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("listSilageYearFarmDocuments", "id", id);
      // verify required parameter 'farmId' is not null or undefined
      assertParamExists("listSilageYearFarmDocuments", "farmId", farmId);
      const localVarPath = `/crop-year/{id}/farm/{farm-id}/documents`
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"farm-id"}}`, encodeURIComponent(String(farmId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sort) {
        localVarQueryParameter["sort"] = sort;
      }

      if (farmId2 !== undefined && farmId2 !== null) {
        localVarHeaderParameter["farm-id"] = String(farmId2);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {RemoveFarmsFromCropYearRequest} removeFarmsFromCropYearRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeMultipleFarmsFromCropYear: async (
      id: string,
      removeFarmsFromCropYearRequest: RemoveFarmsFromCropYearRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("removeMultipleFarmsFromCropYear", "id", id);
      // verify required parameter 'removeFarmsFromCropYearRequest' is not null or undefined
      assertParamExists(
        "removeMultipleFarmsFromCropYear",
        "removeFarmsFromCropYearRequest",
        removeFarmsFromCropYearRequest
      );
      const localVarPath = `/crop-year/{id}/farm`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        removeFarmsFromCropYearRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {string} month
     * @param {SetMonthlyAveragePriceRequest} setMonthlyAveragePriceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setMonthlyAverage: async (
      id: string,
      month: string,
      setMonthlyAveragePriceRequest: SetMonthlyAveragePriceRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("setMonthlyAverage", "id", id);
      // verify required parameter 'month' is not null or undefined
      assertParamExists("setMonthlyAverage", "month", month);
      // verify required parameter 'setMonthlyAveragePriceRequest' is not null or undefined
      assertParamExists(
        "setMonthlyAverage",
        "setMonthlyAveragePriceRequest",
        setMonthlyAveragePriceRequest
      );
      const localVarPath = `/crop-year/{id}/monthly-average/month/{month}`
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"month"}}`, encodeURIComponent(String(month)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        setMonthlyAveragePriceRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    triggerPostHarvest: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("triggerPostHarvest", "id", id);
      const localVarPath = `/crop-year/{id}/post-harvest`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {UpdateCropYearRequest} updateCropYearRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCropYear: async (
      id: string,
      updateCropYearRequest: UpdateCropYearRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("updateCropYear", "id", id);
      // verify required parameter 'updateCropYearRequest' is not null or undefined
      assertParamExists(
        "updateCropYear",
        "updateCropYearRequest",
        updateCropYearRequest
      );
      const localVarPath = `/crop-year/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateCropYearRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CropYearApi - functional programming interface
 * @export
 */
export const CropYearApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CropYearApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} id
     * @param {string} farmId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async assignFarmToCropYear(
      id: string,
      farmId: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.assignFarmToCropYear(
          id,
          farmId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {CreateCropYearRequest} createCropYearRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createCropYear(
      createCropYearRequest: CreateCropYearRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataCropYearDetailResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createCropYear(
        createCropYearRequest,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCropYearDetail(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataCropYearDetailResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getCropYearDetail(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {string} regionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCropYearRegion(
      id: string,
      regionId: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataCropYearRegionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getCropYearRegion(
          id,
          regionId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCropYearSummary(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataCropYearSummaryDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getCropYearSummary(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} farmId
     * @param {string} id
     * @param {string} pathFarmId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFarmSummary(
      farmId: string,
      id: string,
      pathFarmId: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataCropYearFarmSummaryDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFarmSummary(
        farmId,
        id,
        pathFarmId,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFarmsNotAssignedToCropYear(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataListMinimumFarmListDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getFarmsNotAssignedToCropYear(
          id,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {string} month
     * @param {string} regionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMonthlyAverage(
      id: string,
      month: string,
      regionId: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataAveragePricingDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getMonthlyAverage(
          id,
          month,
          regionId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {string} month
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMonthlyAverageListByMonth(
      id: string,
      month: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataMonthlyAverageResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getMonthlyAverageListByMonth(
          id,
          month,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNextCropYearDetails(
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataNextCropYearDetailResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getNextCropYearDetails(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {string} [search]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listCropYearFarms(
      id: string,
      search?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PageResponseCropYearFarmDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listCropYearFarms(
          id,
          search,
          page,
          size,
          sort,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listCropYears(
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PageResponseCropYearListDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.listCropYears(
        page,
        size,
        sort,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {string} farmId
     * @param {string} [farmId2]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listSilageYearFarmDocuments(
      id: string,
      farmId: string,
      farmId2?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PageResponseFarmDocumentDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listSilageYearFarmDocuments(
          id,
          farmId,
          farmId2,
          page,
          size,
          sort,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {RemoveFarmsFromCropYearRequest} removeFarmsFromCropYearRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async removeMultipleFarmsFromCropYear(
      id: string,
      removeFarmsFromCropYearRequest: RemoveFarmsFromCropYearRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.removeMultipleFarmsFromCropYear(
          id,
          removeFarmsFromCropYearRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {string} month
     * @param {SetMonthlyAveragePriceRequest} setMonthlyAveragePriceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setMonthlyAverage(
      id: string,
      month: string,
      setMonthlyAveragePriceRequest: SetMonthlyAveragePriceRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataMonthlyAverageResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.setMonthlyAverage(
          id,
          month,
          setMonthlyAveragePriceRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async triggerPostHarvest(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.triggerPostHarvest(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {UpdateCropYearRequest} updateCropYearRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateCropYear(
      id: string,
      updateCropYearRequest: UpdateCropYearRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataCropYearDetailResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateCropYear(
        id,
        updateCropYearRequest,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * CropYearApi - factory interface
 * @export
 */
export const CropYearApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = CropYearApiFp(configuration);
  return {
    /**
     *
     * @param {string} id
     * @param {string} farmId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    assignFarmToCropYear(
      id: string,
      farmId: string,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .assignFarmToCropYear(id, farmId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {CreateCropYearRequest} createCropYearRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCropYear(
      createCropYearRequest: CreateCropYearRequest,
      options?: any
    ): AxiosPromise<DataCropYearDetailResponse> {
      return localVarFp
        .createCropYear(createCropYearRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCropYearDetail(
      id: string,
      options?: any
    ): AxiosPromise<DataCropYearDetailResponse> {
      return localVarFp
        .getCropYearDetail(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {string} regionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCropYearRegion(
      id: string,
      regionId: string,
      options?: any
    ): AxiosPromise<DataCropYearRegionResponse> {
      return localVarFp
        .getCropYearRegion(id, regionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCropYearSummary(
      id: string,
      options?: any
    ): AxiosPromise<DataCropYearSummaryDto> {
      return localVarFp
        .getCropYearSummary(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} farmId
     * @param {string} id
     * @param {string} pathFarmId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFarmSummary(
      farmId: string,
      id: string,
      pathFarmId: string,
      options?: any
    ): AxiosPromise<DataCropYearFarmSummaryDto> {
      return localVarFp
        .getFarmSummary(farmId, id, pathFarmId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFarmsNotAssignedToCropYear(
      id: string,
      options?: any
    ): AxiosPromise<DataListMinimumFarmListDto> {
      return localVarFp
        .getFarmsNotAssignedToCropYear(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {string} month
     * @param {string} regionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMonthlyAverage(
      id: string,
      month: string,
      regionId: string,
      options?: any
    ): AxiosPromise<DataAveragePricingDto> {
      return localVarFp
        .getMonthlyAverage(id, month, regionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {string} month
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMonthlyAverageListByMonth(
      id: string,
      month: string,
      options?: any
    ): AxiosPromise<DataMonthlyAverageResponseDto> {
      return localVarFp
        .getMonthlyAverageListByMonth(id, month, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNextCropYearDetails(
      options?: any
    ): AxiosPromise<DataNextCropYearDetailResponse> {
      return localVarFp
        .getNextCropYearDetails(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {string} [search]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listCropYearFarms(
      id: string,
      search?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any
    ): AxiosPromise<PageResponseCropYearFarmDto> {
      return localVarFp
        .listCropYearFarms(id, search, page, size, sort, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listCropYears(
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any
    ): AxiosPromise<PageResponseCropYearListDto> {
      return localVarFp
        .listCropYears(page, size, sort, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {string} farmId
     * @param {string} [farmId2]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listSilageYearFarmDocuments(
      id: string,
      farmId: string,
      farmId2?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any
    ): AxiosPromise<PageResponseFarmDocumentDto> {
      return localVarFp
        .listSilageYearFarmDocuments(
          id,
          farmId,
          farmId2,
          page,
          size,
          sort,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {RemoveFarmsFromCropYearRequest} removeFarmsFromCropYearRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeMultipleFarmsFromCropYear(
      id: string,
      removeFarmsFromCropYearRequest: RemoveFarmsFromCropYearRequest,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .removeMultipleFarmsFromCropYear(
          id,
          removeFarmsFromCropYearRequest,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {string} month
     * @param {SetMonthlyAveragePriceRequest} setMonthlyAveragePriceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setMonthlyAverage(
      id: string,
      month: string,
      setMonthlyAveragePriceRequest: SetMonthlyAveragePriceRequest,
      options?: any
    ): AxiosPromise<DataMonthlyAverageResponseDto> {
      return localVarFp
        .setMonthlyAverage(id, month, setMonthlyAveragePriceRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    triggerPostHarvest(id: string, options?: any): AxiosPromise<string> {
      return localVarFp
        .triggerPostHarvest(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {UpdateCropYearRequest} updateCropYearRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCropYear(
      id: string,
      updateCropYearRequest: UpdateCropYearRequest,
      options?: any
    ): AxiosPromise<DataCropYearDetailResponse> {
      return localVarFp
        .updateCropYear(id, updateCropYearRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for assignFarmToCropYear operation in CropYearApi.
 * @export
 * @interface CropYearApiAssignFarmToCropYearRequest
 */
export interface CropYearApiAssignFarmToCropYearRequest {
  /**
   *
   * @type {string}
   * @memberof CropYearApiAssignFarmToCropYear
   */
  readonly id: string;

  /**
   *
   * @type {string}
   * @memberof CropYearApiAssignFarmToCropYear
   */
  readonly farmId: string;
}

/**
 * Request parameters for createCropYear operation in CropYearApi.
 * @export
 * @interface CropYearApiCreateCropYearRequest
 */
export interface CropYearApiCreateCropYearRequest {
  /**
   *
   * @type {CreateCropYearRequest}
   * @memberof CropYearApiCreateCropYear
   */
  readonly createCropYearRequest: CreateCropYearRequest;
}

/**
 * Request parameters for getCropYearDetail operation in CropYearApi.
 * @export
 * @interface CropYearApiGetCropYearDetailRequest
 */
export interface CropYearApiGetCropYearDetailRequest {
  /**
   *
   * @type {string}
   * @memberof CropYearApiGetCropYearDetail
   */
  readonly id: string;
}

/**
 * Request parameters for getCropYearRegion operation in CropYearApi.
 * @export
 * @interface CropYearApiGetCropYearRegionRequest
 */
export interface CropYearApiGetCropYearRegionRequest {
  /**
   *
   * @type {string}
   * @memberof CropYearApiGetCropYearRegion
   */
  readonly id: string;

  /**
   *
   * @type {string}
   * @memberof CropYearApiGetCropYearRegion
   */
  readonly regionId: string;
}

/**
 * Request parameters for getCropYearSummary operation in CropYearApi.
 * @export
 * @interface CropYearApiGetCropYearSummaryRequest
 */
export interface CropYearApiGetCropYearSummaryRequest {
  /**
   *
   * @type {string}
   * @memberof CropYearApiGetCropYearSummary
   */
  readonly id: string;
}

/**
 * Request parameters for getFarmSummary operation in CropYearApi.
 * @export
 * @interface CropYearApiGetFarmSummaryRequest
 */
export interface CropYearApiGetFarmSummaryRequest {
  /**
   *
   * @type {string}
   * @memberof CropYearApiGetFarmSummary
   */
  readonly farmId: string;

  /**
   *
   * @type {string}
   * @memberof CropYearApiGetFarmSummary
   */
  readonly id: string;

  /**
   *
   * @type {string}
   * @memberof CropYearApiGetFarmSummary
   */
  readonly pathFarmId: string;
}

/**
 * Request parameters for getFarmsNotAssignedToCropYear operation in CropYearApi.
 * @export
 * @interface CropYearApiGetFarmsNotAssignedToCropYearRequest
 */
export interface CropYearApiGetFarmsNotAssignedToCropYearRequest {
  /**
   *
   * @type {string}
   * @memberof CropYearApiGetFarmsNotAssignedToCropYear
   */
  readonly id: string;
}

/**
 * Request parameters for getMonthlyAverage operation in CropYearApi.
 * @export
 * @interface CropYearApiGetMonthlyAverageRequest
 */
export interface CropYearApiGetMonthlyAverageRequest {
  /**
   *
   * @type {string}
   * @memberof CropYearApiGetMonthlyAverage
   */
  readonly id: string;

  /**
   *
   * @type {string}
   * @memberof CropYearApiGetMonthlyAverage
   */
  readonly month: string;

  /**
   *
   * @type {string}
   * @memberof CropYearApiGetMonthlyAverage
   */
  readonly regionId: string;
}

/**
 * Request parameters for getMonthlyAverageListByMonth operation in CropYearApi.
 * @export
 * @interface CropYearApiGetMonthlyAverageListByMonthRequest
 */
export interface CropYearApiGetMonthlyAverageListByMonthRequest {
  /**
   *
   * @type {string}
   * @memberof CropYearApiGetMonthlyAverageListByMonth
   */
  readonly id: string;

  /**
   *
   * @type {string}
   * @memberof CropYearApiGetMonthlyAverageListByMonth
   */
  readonly month: string;
}

/**
 * Request parameters for listCropYearFarms operation in CropYearApi.
 * @export
 * @interface CropYearApiListCropYearFarmsRequest
 */
export interface CropYearApiListCropYearFarmsRequest {
  /**
   *
   * @type {string}
   * @memberof CropYearApiListCropYearFarms
   */
  readonly id: string;

  /**
   *
   * @type {string}
   * @memberof CropYearApiListCropYearFarms
   */
  readonly search?: string;

  /**
   * Zero-based page index (0..N)
   * @type {number}
   * @memberof CropYearApiListCropYearFarms
   */
  readonly page?: number;

  /**
   * The size of the page to be returned
   * @type {number}
   * @memberof CropYearApiListCropYearFarms
   */
  readonly size?: number;

  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @type {Array<string>}
   * @memberof CropYearApiListCropYearFarms
   */
  readonly sort?: Array<string>;
}

/**
 * Request parameters for listCropYears operation in CropYearApi.
 * @export
 * @interface CropYearApiListCropYearsRequest
 */
export interface CropYearApiListCropYearsRequest {
  /**
   * Zero-based page index (0..N)
   * @type {number}
   * @memberof CropYearApiListCropYears
   */
  readonly page?: number;

  /**
   * The size of the page to be returned
   * @type {number}
   * @memberof CropYearApiListCropYears
   */
  readonly size?: number;

  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @type {Array<string>}
   * @memberof CropYearApiListCropYears
   */
  readonly sort?: Array<string>;
}

/**
 * Request parameters for listSilageYearFarmDocuments operation in CropYearApi.
 * @export
 * @interface CropYearApiListSilageYearFarmDocumentsRequest
 */
export interface CropYearApiListSilageYearFarmDocumentsRequest {
  /**
   *
   * @type {string}
   * @memberof CropYearApiListSilageYearFarmDocuments
   */
  readonly id: string;

  /**
   *
   * @type {string}
   * @memberof CropYearApiListSilageYearFarmDocuments
   */
  readonly farmId: string;

  /**
   *
   * @type {string}
   * @memberof CropYearApiListSilageYearFarmDocuments
   */
  readonly farmId2?: string;

  /**
   * Zero-based page index (0..N)
   * @type {number}
   * @memberof CropYearApiListSilageYearFarmDocuments
   */
  readonly page?: number;

  /**
   * The size of the page to be returned
   * @type {number}
   * @memberof CropYearApiListSilageYearFarmDocuments
   */
  readonly size?: number;

  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @type {Array<string>}
   * @memberof CropYearApiListSilageYearFarmDocuments
   */
  readonly sort?: Array<string>;
}

/**
 * Request parameters for removeMultipleFarmsFromCropYear operation in CropYearApi.
 * @export
 * @interface CropYearApiRemoveMultipleFarmsFromCropYearRequest
 */
export interface CropYearApiRemoveMultipleFarmsFromCropYearRequest {
  /**
   *
   * @type {string}
   * @memberof CropYearApiRemoveMultipleFarmsFromCropYear
   */
  readonly id: string;

  /**
   *
   * @type {RemoveFarmsFromCropYearRequest}
   * @memberof CropYearApiRemoveMultipleFarmsFromCropYear
   */
  readonly removeFarmsFromCropYearRequest: RemoveFarmsFromCropYearRequest;
}

/**
 * Request parameters for setMonthlyAverage operation in CropYearApi.
 * @export
 * @interface CropYearApiSetMonthlyAverageRequest
 */
export interface CropYearApiSetMonthlyAverageRequest {
  /**
   *
   * @type {string}
   * @memberof CropYearApiSetMonthlyAverage
   */
  readonly id: string;

  /**
   *
   * @type {string}
   * @memberof CropYearApiSetMonthlyAverage
   */
  readonly month: string;

  /**
   *
   * @type {SetMonthlyAveragePriceRequest}
   * @memberof CropYearApiSetMonthlyAverage
   */
  readonly setMonthlyAveragePriceRequest: SetMonthlyAveragePriceRequest;
}

/**
 * Request parameters for triggerPostHarvest operation in CropYearApi.
 * @export
 * @interface CropYearApiTriggerPostHarvestRequest
 */
export interface CropYearApiTriggerPostHarvestRequest {
  /**
   *
   * @type {string}
   * @memberof CropYearApiTriggerPostHarvest
   */
  readonly id: string;
}

/**
 * Request parameters for updateCropYear operation in CropYearApi.
 * @export
 * @interface CropYearApiUpdateCropYearRequest
 */
export interface CropYearApiUpdateCropYearRequest {
  /**
   *
   * @type {string}
   * @memberof CropYearApiUpdateCropYear
   */
  readonly id: string;

  /**
   *
   * @type {UpdateCropYearRequest}
   * @memberof CropYearApiUpdateCropYear
   */
  readonly updateCropYearRequest: UpdateCropYearRequest;
}

/**
 * CropYearApi - object-oriented interface
 * @export
 * @class CropYearApi
 * @extends {BaseAPI}
 */
export class CropYearApi extends BaseAPI {
  /**
   *
   * @param {CropYearApiAssignFarmToCropYearRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CropYearApi
   */
  public assignFarmToCropYear(
    requestParameters: CropYearApiAssignFarmToCropYearRequest,
    options?: AxiosRequestConfig
  ) {
    return CropYearApiFp(this.configuration)
      .assignFarmToCropYear(
        requestParameters.id,
        requestParameters.farmId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CropYearApiCreateCropYearRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CropYearApi
   */
  public createCropYear(
    requestParameters: CropYearApiCreateCropYearRequest,
    options?: AxiosRequestConfig
  ) {
    return CropYearApiFp(this.configuration)
      .createCropYear(requestParameters.createCropYearRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CropYearApiGetCropYearDetailRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CropYearApi
   */
  public getCropYearDetail(
    requestParameters: CropYearApiGetCropYearDetailRequest,
    options?: AxiosRequestConfig
  ) {
    return CropYearApiFp(this.configuration)
      .getCropYearDetail(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CropYearApiGetCropYearRegionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CropYearApi
   */
  public getCropYearRegion(
    requestParameters: CropYearApiGetCropYearRegionRequest,
    options?: AxiosRequestConfig
  ) {
    return CropYearApiFp(this.configuration)
      .getCropYearRegion(
        requestParameters.id,
        requestParameters.regionId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CropYearApiGetCropYearSummaryRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CropYearApi
   */
  public getCropYearSummary(
    requestParameters: CropYearApiGetCropYearSummaryRequest,
    options?: AxiosRequestConfig
  ) {
    return CropYearApiFp(this.configuration)
      .getCropYearSummary(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CropYearApiGetFarmSummaryRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CropYearApi
   */
  public getFarmSummary(
    requestParameters: CropYearApiGetFarmSummaryRequest,
    options?: AxiosRequestConfig
  ) {
    return CropYearApiFp(this.configuration)
      .getFarmSummary(
        requestParameters.farmId,
        requestParameters.id,
        requestParameters.pathFarmId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CropYearApiGetFarmsNotAssignedToCropYearRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CropYearApi
   */
  public getFarmsNotAssignedToCropYear(
    requestParameters: CropYearApiGetFarmsNotAssignedToCropYearRequest,
    options?: AxiosRequestConfig
  ) {
    return CropYearApiFp(this.configuration)
      .getFarmsNotAssignedToCropYear(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CropYearApiGetMonthlyAverageRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CropYearApi
   */
  public getMonthlyAverage(
    requestParameters: CropYearApiGetMonthlyAverageRequest,
    options?: AxiosRequestConfig
  ) {
    return CropYearApiFp(this.configuration)
      .getMonthlyAverage(
        requestParameters.id,
        requestParameters.month,
        requestParameters.regionId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CropYearApiGetMonthlyAverageListByMonthRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CropYearApi
   */
  public getMonthlyAverageListByMonth(
    requestParameters: CropYearApiGetMonthlyAverageListByMonthRequest,
    options?: AxiosRequestConfig
  ) {
    return CropYearApiFp(this.configuration)
      .getMonthlyAverageListByMonth(
        requestParameters.id,
        requestParameters.month,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CropYearApi
   */
  public getNextCropYearDetails(options?: AxiosRequestConfig) {
    return CropYearApiFp(this.configuration)
      .getNextCropYearDetails(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CropYearApiListCropYearFarmsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CropYearApi
   */
  public listCropYearFarms(
    requestParameters: CropYearApiListCropYearFarmsRequest,
    options?: AxiosRequestConfig
  ) {
    return CropYearApiFp(this.configuration)
      .listCropYearFarms(
        requestParameters.id,
        requestParameters.search,
        requestParameters.page,
        requestParameters.size,
        requestParameters.sort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CropYearApiListCropYearsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CropYearApi
   */
  public listCropYears(
    requestParameters: CropYearApiListCropYearsRequest = {},
    options?: AxiosRequestConfig
  ) {
    return CropYearApiFp(this.configuration)
      .listCropYears(
        requestParameters.page,
        requestParameters.size,
        requestParameters.sort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CropYearApiListSilageYearFarmDocumentsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CropYearApi
   */
  public listSilageYearFarmDocuments(
    requestParameters: CropYearApiListSilageYearFarmDocumentsRequest,
    options?: AxiosRequestConfig
  ) {
    return CropYearApiFp(this.configuration)
      .listSilageYearFarmDocuments(
        requestParameters.id,
        requestParameters.farmId,
        requestParameters.farmId2,
        requestParameters.page,
        requestParameters.size,
        requestParameters.sort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CropYearApiRemoveMultipleFarmsFromCropYearRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CropYearApi
   */
  public removeMultipleFarmsFromCropYear(
    requestParameters: CropYearApiRemoveMultipleFarmsFromCropYearRequest,
    options?: AxiosRequestConfig
  ) {
    return CropYearApiFp(this.configuration)
      .removeMultipleFarmsFromCropYear(
        requestParameters.id,
        requestParameters.removeFarmsFromCropYearRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CropYearApiSetMonthlyAverageRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CropYearApi
   */
  public setMonthlyAverage(
    requestParameters: CropYearApiSetMonthlyAverageRequest,
    options?: AxiosRequestConfig
  ) {
    return CropYearApiFp(this.configuration)
      .setMonthlyAverage(
        requestParameters.id,
        requestParameters.month,
        requestParameters.setMonthlyAveragePriceRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CropYearApiTriggerPostHarvestRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CropYearApi
   */
  public triggerPostHarvest(
    requestParameters: CropYearApiTriggerPostHarvestRequest,
    options?: AxiosRequestConfig
  ) {
    return CropYearApiFp(this.configuration)
      .triggerPostHarvest(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CropYearApiUpdateCropYearRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CropYearApi
   */
  public updateCropYear(
    requestParameters: CropYearApiUpdateCropYearRequest,
    options?: AxiosRequestConfig
  ) {
    return CropYearApiFp(this.configuration)
      .updateCropYear(
        requestParameters.id,
        requestParameters.updateCropYearRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
