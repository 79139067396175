import { Link as RLink, useLocation } from "react-router-dom";
import { Link, Text, Center } from "@chakra-ui/react";
import { CardBody, CardFooter, CardHeader, ResponsiveCard } from "../Card";

type ServerErrorProps = {
  msg?: string;
  title?: string;
  linkTo?: string;
  linkText?: string;
};

export const defaultErrorCardState: ErrorState = {
  match: undefined,
  msg: "Something went wrong",
  title: "An error occurred.",
  linkText: "Back to home",
  linkTo: "/",
};

const ErrorCard = ({ msg, title, linkTo, linkText }: ServerErrorProps) => {
  const { state } = useLocation();
  const {
    msg: locationMsg,
    title: locationTitle,
    linkText: locationLinkText,
    linkTo: locationLinkTo,
  } = (state as ServerErrorProps) ?? {};
  const headerText = locationTitle ?? title ?? defaultErrorCardState.title;
  const bodyMsg = locationMsg ?? msg ?? defaultErrorCardState.msg;
  const _linkText =
    locationLinkText ?? linkText ?? defaultErrorCardState.linkText;
  const _linkTo =
    locationLinkTo ?? linkTo ?? defaultErrorCardState.linkTo ?? "/";

  const displayLink = locationLinkTo !== null;

  return (
    <ResponsiveCard maxW="534px">
      <CardHeader mb={!!bodyMsg ? 4 : 0}>{headerText}</CardHeader>
      {!!bodyMsg && (
        <CardBody>
          <Text>{bodyMsg}</Text>
        </CardBody>
      )}
      <CardFooter mt={6}>
        <Center flex={1}>
          {displayLink && (
            <Link as={RLink} to={_linkTo}>
              <Text fontWeight="bold" color="brand.800">
                {_linkText}
              </Text>
            </Link>
          )}
        </Center>
      </CardFooter>
    </ResponsiveCard>
  );
};

export default ErrorCard;
