import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNotificationService } from "contexts/ServiceProvider";
import { GET_NOTIFICATIONS_LIST_QUERY_KEY } from "./useGetNotificationList";

const DELETE_NOTIFICATION_MUTATION_KEY = "delete-notification";

const useDeleteNotification = () => {
  const notificationService = useNotificationService();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [DELETE_NOTIFICATION_MUTATION_KEY],
    mutationFn: (notificationId: string | undefined) =>
      notificationId
        ? notificationService.deleteNotification(notificationId)
        : Promise.reject(new Error("Missing notificationId")),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [GET_NOTIFICATIONS_LIST_QUERY_KEY],
      });
    },
  });
};

export default useDeleteNotification;
