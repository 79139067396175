import { Center, Text } from "@chakra-ui/react";

import userRegistrationPaths from "routes/user-registration/userRegistrationPaths";
import LinkWithQuery from "components/LinkWithQuery";

const BackToPhoneNumberLink = () => (
  <Center pt={6}>
    <LinkWithQuery to={`../${userRegistrationPaths.children.phoneNumberPath}`}>
      <Text fontWeight="bold" color="brand.800">
        Back to phone number
      </Text>
    </LinkWithQuery>
  </Center>
);

export default BackToPhoneNumberLink;
