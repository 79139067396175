import {
  Box,
  Checkbox,
  HStack,
  Link,
  Spacer,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";

import { DesktopOnly, MobileOnly } from "components/BreakpointVisibility";
import { ArrowRight, DocumentIcon } from "icons";

const DocumentListItem = ({
  linkDisplayName,
  linkToDocument,
  selectRow = false,
  onSelectRow,
  documentId,
}: {
  linkDisplayName: string;
  linkToDocument: string;
  selectRow?: boolean;
  onSelectRow?: (id?: string) => void;
  documentId?: string;
}) => {
  return (
    <Box
      key={linkDisplayName}
      _hover={{ bg: "greyFactor.1.5" }}
      backgroundColor="white"
      borderRadius="8px"
      border="1px solid #E7EEF5"
      pt={6}
      pr="21px"
      pb="21px"
      pl={6}
    >
      <Stack>
        <HStack spacing={4}>
          {selectRow ? (
            <Checkbox onChange={() => onSelectRow?.(documentId)} />
          ) : (
            <DocumentIcon data-testid="document-icon" />
          )}
          <Text color="charcoal" fontWeight="semibold" minW="60px">
            Link to:
          </Text>
          <Link href={linkToDocument} target="_blank" w="100%">
            <HStack w="100%">
              <DesktopOnly>
                <Tooltip hasArrow label={linkDisplayName}>
                  <Text
                    color="brand.800"
                    decoration="underline"
                    maxW="100%"
                    variant="truncated"
                    overflowWrap="break-word"
                  >
                    {linkDisplayName}
                  </Text>
                </Tooltip>
              </DesktopOnly>
              <Spacer />
              <ArrowRight fontSize={6} />
            </HStack>
          </Link>
        </HStack>
        <MobileOnly>
          <Link href={linkToDocument} target="_blank" w="100%">
            <HStack w="100%">
              <Text
                color="brand.800"
                decoration="underline"
                maxW="100%"
                overflowWrap="break-word"
              >
                {linkDisplayName}
              </Text>
            </HStack>
          </Link>
        </MobileOnly>
      </Stack>
    </Box>
  );
};

export default DocumentListItem;
