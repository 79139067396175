import { forwardRef } from "react";
import {
  Input,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  InputProps,
  InputRightAddon,
  InputRightElement,
  InputGroupProps,
} from "@chakra-ui/react";

export type StandardInputProps = InputProps & {
  leftAddon?: JSX.Element | string;
  rightAddon?: JSX.Element;
  hideAddonBackground?: boolean;
  inputGroupProps?: InputGroupProps;
};

// Introducing this as chakra does not provide the ability to define this in the theme with element prop
const inputAddonOpacity = (isDisabled: boolean | undefined) =>
  isDisabled ? 0.4 : 1;

const StandardInput = forwardRef<HTMLInputElement, StandardInputProps>(
  (
    {
      isRequired,
      leftAddon,
      rightAddon,
      hideAddonBackground,
      inputGroupProps,
      ...rest
    },
    ref
  ) => (
    <InputGroup {...inputGroupProps}>
      {leftAddon &&
        (hideAddonBackground ? (
          <InputLeftElement
            zIndex={2}
            opacity={inputAddonOpacity(rest.isDisabled)}
          >
            {leftAddon}
          </InputLeftElement>
        ) : (
          <InputLeftAddon>{leftAddon}</InputLeftAddon>
        ))}
      <Input
        bg="white"
        {...rest}
        ref={ref}
        aria-required={isRequired ?? undefined}
        paddingLeft={leftAddon && hideAddonBackground ? 7 : 5}
      />
      {rightAddon &&
        (hideAddonBackground ? (
          <InputRightElement opacity={inputAddonOpacity(rest.isDisabled)}>
            {rightAddon}
          </InputRightElement>
        ) : (
          <InputRightAddon>{rightAddon}</InputRightAddon>
        ))}
    </InputGroup>
  )
);

StandardInput.displayName = "StandardInput";

export default StandardInput;
