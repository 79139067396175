import { Stack, Text } from "@chakra-ui/react";

import InputLabel from "components/inputs/InputLabel";
import { formatDate } from "utils/dateUtils";

const PaymentDate = ({
  firstPaymentDateWithoutDeferral,
}: {
  firstPaymentDateWithoutDeferral: Date;
}) => (
  <Stack spacing={0}>
    <InputLabel label="Payment Date" />
    <Text>
      Estimated payment date on {formatDate(firstPaymentDateWithoutDeferral)}.
    </Text>
  </Stack>
);

export default PaymentDate;
