import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useFarmService } from "contexts/ServiceProvider";
import { MutationHook, MutationHookResult } from "hooks/hookTypeHelpers";
import FarmService from "services/FarmService";
import { GET_FARM_INFO_QUERY_KEY } from "./useFarmDetail";
import { GET_FARMS_QUERY_KEY } from "../useFarmsList";

type AcknowledgeFarmMutationResult = MutationHookResult<
  Awaited<ReturnType<FarmService["acknowledgeFarm"]>>,
  { farmId?: string }
>;

const useAcknowledgeFarmMutation = (): AcknowledgeFarmMutationResult => {
  const farmService = useFarmService();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["acknowledge-farm"],
    mutationFn: ({ farmId }) => {
      return farmId
        ? farmService.acknowledgeFarm(farmId)
        : Promise.reject("Cannot acknowledge a farm without an ID");
    },
    onSuccess: async (_, variables) => {
      await queryClient.refetchQueries({
        queryKey: [GET_FARM_INFO_QUERY_KEY, { id: variables.farmId }],
      });
      await queryClient.refetchQueries({
        queryKey: [GET_FARMS_QUERY_KEY],
      });
    },
  });
};

const useAcknowledgeFarm = (): MutationHook<
  AcknowledgeFarmMutationResult,
  "onAcknowledge"
> => {
  const {
    mutate: onAcknowledge,
    mutateAsync: onAcknowledgeAsync,
    ...rest
  } = useAcknowledgeFarmMutation();

  return { onAcknowledge, onAcknowledgeAsync, ...rest };
};

export default useAcknowledgeFarm;
