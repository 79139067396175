import { useQuery } from "@tanstack/react-query";

import { MinimumUserDto } from "api";
import { useUserService } from "contexts/ServiceProvider";
import {
  BaseApiError,
  transformProfilePictureResponse,
} from "services/apiHelpers";

type GetFarmUsersListResponse = { users: FarmUserListItem[] };

export type FarmUserListItem = {
  id: string;
  name: string;
  phoneNumber?: string;
  profilePicture?: ProfilePicture;
};

export const GET_FARM_USERS_QUERY_KEY = "get-farm-users";

export const transformFarmUserListResponse = ({
  id,
  profilePicture,
  firstName,
  lastName,
  phoneNumber,
}: MinimumUserDto): FarmUserListItem => {
  return {
    id: id,
    name: `${firstName} ${lastName}`,
    phoneNumber,
    profilePicture: transformProfilePictureResponse(profilePicture),
  };
};

const useFarmUsersList = (farmId?: string, enabled = true) => {
  const userService = useUserService();

  return useQuery<GetFarmUsersListResponse, BaseApiError>({
    queryKey: [GET_FARM_USERS_QUERY_KEY, { id: farmId }],
    queryFn: () =>
      farmId
        ? userService.getFarmAdminUsers(farmId).then(({ data }) => ({
            users: data.map(transformFarmUserListResponse),
          }))
        : Promise.reject("Must have farm ID"),
    enabled: !!farmId && enabled,
  });
};

export default useFarmUsersList;
