import { useQuery } from "@tanstack/react-query";

import { useAuthService } from "contexts/ServiceProvider";
import AuthService from "services/AuthService";
import { customAxiosInstance } from "services/customApiConfig";
import { BaseApiError } from "services/apiHelpers";
import { getRefreshToken, storeRefreshToken } from "utils/authLocalStorage";
import { clearAuthData } from "utils/authUtils";
import { useEffect } from "react";

export const AUTH_TOKENS_QUERY_KEY = "auth-tokens";

type GetRefreshTokenResult = Awaited<ReturnType<AuthService["refreshToken"]>>;

export const setAuthHeader = (token?: string) => {
  if (token) {
    customAxiosInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${token}`;
  } else {
    delete customAxiosInstance.defaults.headers.common.Authorization;
  }
};

const useAuthTokens = () => {
  const authService = useAuthService();
  const refreshToken = getRefreshToken();

  const onAuthCleanup = () => {
    clearAuthData(true);
    window.location.reload();
  };

  const query = useQuery<GetRefreshTokenResult, BaseApiError>({
    // We don't want to put the token in the query key for security reasons
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [AUTH_TOKENS_QUERY_KEY],
    queryFn: () =>
      refreshToken
        ? authService.refreshToken(refreshToken)
        : Promise.reject("Unable to authenticate"),
    enabled: !!refreshToken,
    retry: 0,
    refetchOnReconnect: true,
    refetchOnMount: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (query.data) {
      const { refreshToken: _refreshToken, accessToken } = query.data;
      if (!_refreshToken) {
        return onAuthCleanup();
      } else {
        storeRefreshToken(_refreshToken);
        setAuthHeader(accessToken);
      }
    }

    if (query.error) {
      const errorStatus = query.error?.response?.status ?? 0;
      if (errorStatus >= 400 && errorStatus < 500) {
        return onAuthCleanup();
      }
    }
  }, [query.data, query.error, query.isSuccess]);

  return query;
};

export default useAuthTokens;
