import * as yup from "yup";
import { optionalSelectOptionSchema } from "forms/schemas";

export const estimatedTonsSchema = yup
  .number()
  .typeError("Estimated tons per acre is required")
  .min(0, "Estimated tons per acre must be 0 or more")
  .max(100, "Estimated tons per acre must be less than or equal to 100")
  .required("Estimated tons per acre is required");

const actualYieldSchema = yup
  .number()
  .transform((value) => (isNaN(value) ? undefined : value))
  .min(0, "Actual yield (tons) must be 0 or more")
  .max(30000, "Actual yield (tons) must be less than or equal to 30,000")
  .test(
    "is-decimal",
    "Actual yield (tons) must not have more than 30 digits after the decimal point",
    (val) => (val !== undefined ? /^\d+(\.\d{0,30})?$/.test(`${val}`) : true)
  );

export const createFieldDetailFormSchema = (isPreHarvest?: boolean) =>
  yup.object({
    fieldNumber: yup
      .string()
      .max(16, "Field number must be 16 characters or less")
      .required("Field number is required"),
    totalAcres: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .moreThan(0, "Total acres (before split) must be greater than 0")
      .lessThan(10000, "Total acres (before split) must be less than 10,000")
      .test(
        "is-decimal",
        "Total acres must not have more than 2 digits after the decimal point",
        (val) => (val !== undefined ? /^\d+(\.\d{0,2})?$/.test(`${val}`) : true)
      )
      .required("Total acres (before split) is required"),
    dairySite: optionalSelectOptionSchema,
    legalDescription: yup
      .string()
      .max(100, "Legal description must be 100 characters or less")
      .nullable(),
    distanceToSite: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .min(0, "Distance to nearest site (mi) must be 0 or more")
      .max(100, "Distance to nearest site (mi) must be 100 or less"),
    commonName: yup
      .string()
      .max(255, "Field Common Name must be 255 characters or less"),
    estimatedYieldPerAcre: estimatedTonsSchema,
    actualYield: isPreHarvest
      ? actualYieldSchema.nullable()
      : actualYieldSchema.required("Actual yield (tons) is required"),
  });
