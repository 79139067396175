import { useCallback } from "react";
import { MenuItem, HStack, Text } from "@chakra-ui/react";
import {
  CropYearDetailResponse,
  CropYearDetailResponseTimeOfYearEnum,
} from "api";
import useNavigateWithQuery from "hooks/useNavigateWithQuery";
import dashboardPaths from "routes/dashboard/dashboardPaths";

const ForwardPricingMenuItem = ({
  cropYearDetail,
}: {
  cropYearDetail?: CropYearDetailResponse;
}) => {
  const navigate = useNavigateWithQuery();

  const navigateToForwardPricingPage = useCallback(
    () =>
      navigate(
        `/${dashboardPaths.basePath}/${dashboardPaths.children.newForwardPricing}`
      ),
    [navigate]
  );

  const isPreHarvest =
    cropYearDetail?.timeOfYear ===
    CropYearDetailResponseTimeOfYearEnum.PreHarvest;

  return (
    <MenuItem
      aria-label="forward-pricing-menu-item"
      isDisabled={!isPreHarvest}
      onClick={navigateToForwardPricingPage}
    >
      <HStack justifyContent="space-between" w="100%" spacing={4}>
        <Text fontWeight="normal">Forward Pricing</Text>
        <Text fontSize="xs">Available for Pre-Harvest</Text>
      </HStack>
    </MenuItem>
  );
};

export default ForwardPricingMenuItem;
