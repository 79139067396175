/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosRequestConfig,
} from "axios";
import { Configuration } from "../configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "../base";
// @ts-ignore
import { CreateDailyPricingRequest } from "../models";
// @ts-ignore
import { DataDailyPricingResponse } from "../models";
/**
 * DailyPricingApi - axios parameter creator
 * @export
 */
export const DailyPricingApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {CreateDailyPricingRequest} createDailyPricingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDailyPricing: async (
      createDailyPricingRequest: CreateDailyPricingRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createDailyPricingRequest' is not null or undefined
      assertParamExists(
        "createDailyPricing",
        "createDailyPricingRequest",
        createDailyPricingRequest
      );
      const localVarPath = `/daily-pricing`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createDailyPricingRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDailyPricing: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/daily-pricing`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DailyPricingApi - functional programming interface
 * @export
 */
export const DailyPricingApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    DailyPricingApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {CreateDailyPricingRequest} createDailyPricingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createDailyPricing(
      createDailyPricingRequest: CreateDailyPricingRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataDailyPricingResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createDailyPricing(
          createDailyPricingRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDailyPricing(
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataDailyPricingResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDailyPricing(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * DailyPricingApi - factory interface
 * @export
 */
export const DailyPricingApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = DailyPricingApiFp(configuration);
  return {
    /**
     *
     * @param {CreateDailyPricingRequest} createDailyPricingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDailyPricing(
      createDailyPricingRequest: CreateDailyPricingRequest,
      options?: any
    ): AxiosPromise<DataDailyPricingResponse> {
      return localVarFp
        .createDailyPricing(createDailyPricingRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDailyPricing(options?: any): AxiosPromise<DataDailyPricingResponse> {
      return localVarFp
        .getDailyPricing(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createDailyPricing operation in DailyPricingApi.
 * @export
 * @interface DailyPricingApiCreateDailyPricingRequest
 */
export interface DailyPricingApiCreateDailyPricingRequest {
  /**
   *
   * @type {CreateDailyPricingRequest}
   * @memberof DailyPricingApiCreateDailyPricing
   */
  readonly createDailyPricingRequest: CreateDailyPricingRequest;
}

/**
 * DailyPricingApi - object-oriented interface
 * @export
 * @class DailyPricingApi
 * @extends {BaseAPI}
 */
export class DailyPricingApi extends BaseAPI {
  /**
   *
   * @param {DailyPricingApiCreateDailyPricingRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DailyPricingApi
   */
  public createDailyPricing(
    requestParameters: DailyPricingApiCreateDailyPricingRequest,
    options?: AxiosRequestConfig
  ) {
    return DailyPricingApiFp(this.configuration)
      .createDailyPricing(requestParameters.createDailyPricingRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DailyPricingApi
   */
  public getDailyPricing(options?: AxiosRequestConfig) {
    return DailyPricingApiFp(this.configuration)
      .getDailyPricing(options)
      .then((request) => request(this.axios, this.basePath));
  }
}
