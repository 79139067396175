import { ReactElement, useCallback } from "react";

import { AxiosError, AxiosResponse } from "axios";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import {
  Box,
  Button,
  Divider,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";

import { CreateFieldRequest, FieldResponse, FieldSplitRequestDto } from "api";
import { PageHeader } from "components/page-components";
import SubmitButton from "components/SubmitButton";
import { SelectOption } from "components/Select";
import FormButtonGroup from "components/FormButtonGroup";
import WithSpinner from "components/WithSpinner";
import { useCropYearContext } from "contexts/CropYearContext";
import useConfirmationAlert from "hooks/alerts/useConfirmationAlert";
import useInfoAlert from "hooks/alerts/useInfoAlert";
import useFarmDetail from "hooks/farm/farm-detail/useFarmDetail";
import DeleteFieldButton from "forms/field-detail/DeleteFieldModal";
import useNavigateWithQuery from "hooks/useNavigateWithQuery";
import useSelectedFarmId from "hooks/useSelectedFarmId";
import { FormStack } from "layouts/FormLayout";
import dashboardPaths from "routes/dashboard/dashboardPaths";
import { FieldDetailInputs } from "./FieldDetailInputs";
import { createFieldDetailFormSchema } from "./fieldDetailFormSchema";
import SplitsSummary from "./SplitsSummary/SplitsSummary";
import useUserRoleFlags from "hooks/auth/useUserRoleFlags";
import { extractResponseErrorMessage } from "services/apiHelpers";
import { mobileStyleBreakpoint } from "utils/styleHelpers";

export const FIELD_UPDATE_ERROR_MESSAGE = `Farms associated to this field have existing delayed pricing contracts
that are affected by this update. You must void contracts for the following 
farm(s) before editing field information:`;

export type FieldDetailFields = {
  fieldNumber: string | undefined;
  totalAcres: number | undefined;
  dairySite: SelectOption | undefined;
  legalDescription: string | undefined;
  distanceToSite: number | undefined;
  commonName?: string;
  actualYield: number | undefined;
  estimatedYieldPerAcre: number;
  tonsPerAcre?: number;
};

export type CreateFieldFormData = FieldDetailFields & {
  cropYearId: string;
  farmId: string;
};

export const formDataToCreateFieldRequest = (
  formData: CreateFieldFormData,
  splits?: FieldSplitRequestDto[] | undefined
): CreateFieldRequest => ({
  cropYearId: formData.cropYearId,
  fieldNumber: formData.fieldNumber ?? "",
  totalAcres: formData.totalAcres ?? 0,
  dairyId: formData.dairySite?.value,
  legalDescription: formData.legalDescription
    ? formData.legalDescription
    : undefined,
  commonName: formData.commonName ? formData.commonName : undefined,
  distanceToSite: formData.distanceToSite,
  actualYield: formData.actualYield ?? undefined,
  estimatedYieldPerAcre: formData.estimatedYieldPerAcre,
  splits: splits ?? [
    {
      farmId: formData.farmId,
      split: 100,
    },
  ],
});

const FORM_BUTTON_GROUP_PADDING_TOP = mobileStyleBreakpoint(5, 0);

export const getAffectedContractsErrorMessage = (
  affectedFarmsList: Array<string>
) => (
  <Stack spacing={6} mt={6}>
    <Text>{FIELD_UPDATE_ERROR_MESSAGE}</Text>
    <Stack pb={8} pl={2}>
      <UnorderedList spacing={4}>
        {affectedFarmsList.map((farm: string) => (
          <ListItem key={farm} color="charcoal" fontWeight="bold">
            {farm}
          </ListItem>
        ))}
      </UnorderedList>
    </Stack>
  </Stack>
);

const FieldDetailForm = ({
  data,
  estimatedTonsPerAcre,
  onSubmit = () => {
    return;
  },
}: {
  data?: FieldResponse;
  onSubmit?: SubmitHandler<CreateFieldFormData>;
  estimatedTonsPerAcre?: number;
}): ReactElement => {
  const { alert, onOpen } = useInfoAlert({
    title: "Unable to Update Field Information",
  });
  const { isEmployee, isEmployeeAdmin } = useUserRoleFlags();

  const {
    cropYear,
    isPreHarvest,
    isLoading: cropYearIsLoading,
  } = useCropYearContext();
  const [farmId] = useSelectedFarmId();
  const { data: farmDetails } = useFarmDetail(farmId);

  const methods = useForm<FieldDetailFields>({
    resolver: yupResolver(createFieldDetailFormSchema(isPreHarvest)),
    defaultValues: {
      fieldNumber: data?.fieldNumber,
      totalAcres: data?.totalAcres,
      dairySite: data?.dairy
        ? {
            value: data?.dairy.id,
            label: data?.dairy.name,
          }
        : undefined,
      legalDescription: data?.legalDescription
        ? data.legalDescription
        : undefined,
      distanceToSite: data?.distanceToSite,
      commonName: data?.commonName,
      actualYield: data?.actualYield,
      estimatedYieldPerAcre: estimatedTonsPerAcre,
      tonsPerAcre: data?.tonsPerAcre,
    },
  });

  const {
    formState: { isSubmitting, isDirty },
    handleSubmit,
    setError,
    watch,
  } = methods;
  const navigate = useNavigateWithQuery();
  const requiredFields = watch([
    "estimatedYieldPerAcre",
    "fieldNumber",
    "totalAcres",
  ]);

  const requiredFieldsNotFilled = requiredFields.some(
    (field) => field?.toString().length === 0 || field === undefined
  );

  const navigateToFieldList = useCallback(
    () =>
      navigate(`/${dashboardPaths.basePath}/${dashboardPaths.children.fields}`),
    [navigate]
  );

  const submitHandler = useCallback(
    async (formData: FieldDetailFields) => {
      if (farmId && cropYear?.id) {
        await onSubmit({ ...formData, cropYearId: cropYear.id, farmId }).catch(
          (
            error: AxiosError<
              Record<"validation" | "error" | "affectedFarms", any> | undefined
            >
          ) => {
            if (error.response?.status === 409) {
              const affectedFarmsList = error.response?.data?.affectedFarms;
              const affectedContractsErrorMessage =
                getAffectedContractsErrorMessage(affectedFarmsList);

              onOpen({
                msg: affectedContractsErrorMessage,
              });
            }
            if (
              (error.response?.data?.validation as Record<string, string>)
                ?.fieldNumber
            ) {
              setError("fieldNumber", {
                type: "validate",
                message: error.response?.data?.validation?.fieldNumber,
              });
            } else {
              // Error response doesn't have validation messages per field
              const errorMsg = extractResponseErrorMessage(
                error.response as AxiosResponse<{ error: string }>
              );
              if (
                errorMsg.indexOf("field") >= 0 ||
                errorMsg.indexOf("number") >= 0 ||
                errorMsg.indexOf("already") >= 0
              ) {
                setError("fieldNumber", {
                  type: "validate",
                  message: errorMsg,
                });
              }
            }
          }
        );
        // TODO: handle onSubmit then() to do navigation, when we add the inline error handling (from the server's response) to the catch()
        // TODO: remove navigation from NewFieldInformationPage and FieldInformationPage's onSubmit then()
      }
    },
    [cropYear?.id, farmId, onOpen, onSubmit, setError]
  );

  const {
    alert: confirmPostHarvestUpdateAlert,
    onOpen: onConfirmPostHarvestUpdate,
    onClose,
  } = useConfirmationAlert({
    title: "Update Field Info?",
    msg: "Update will re-calculate contracts related to this field. Are you sure you want to save?",
    confirmButtonText: "Save",
    onConfirmAsync: async () => {
      const formData = watch();
      await submitHandler(formData);
      onClose();
    },
  });

  return (
    <>
      {alert}
      {confirmPostHarvestUpdateAlert}
      <WithSpinner isLoading={!!cropYearIsLoading}>
        <FormProvider {...methods}>
          <FormStack
            onSubmit={handleSubmit(
              isPreHarvest ? submitHandler : onConfirmPostHarvestUpdate
            )}
          >
            <PageHeader
              heading="Field Information"
              subHeading={farmDetails?.doingBusinessAs}
            >
              {isEmployee && (
                <FormButtonGroup
                  pt={FORM_BUTTON_GROUP_PADDING_TOP}
                  display="flex"
                >
                  <Button
                    variant="outline"
                    size="md"
                    onClick={navigateToFieldList}
                  >
                    Cancel
                  </Button>
                  <SubmitButton
                    submitDisabled={requiredFieldsNotFilled || !isDirty}
                    isSubmitting={isSubmitting}
                    buttonText="Save"
                  />
                </FormButtonGroup>
              )}
            </PageHeader>
            {data && (
              <SplitsSummary
                field={data}
                isDisabled={!isEmployeeAdmin && !isPreHarvest}
              />
            )}
            <FieldDetailInputs isDisabled={!isPreHarvest} />
            <Divider />
            {isEmployee && isPreHarvest && data?.id && (
              <Box>
                <DeleteFieldButton
                  fieldId={data?.id}
                  hasMultipleSplits={data?.splits?.length > 1}
                />
              </Box>
            )}
          </FormStack>
        </FormProvider>
      </WithSpinner>
    </>
  );
};

export default FieldDetailForm;
