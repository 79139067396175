import { ReactElement } from "react";

import {
  HStack,
  Spacer,
  Stat,
  StatLabel,
  StatNumber,
  Stack,
  StackDirection,
  useBreakpointValue,
  Box,
  Tooltip,
} from "@chakra-ui/react";

import Card, { CardBody, CardProps } from "components/Card";
import { addDigitSeparators } from "utils/formatFunctions";
import { mobileStyleBreakpoint } from "utils/styleHelpers";

export type SummaryStatProps = {
  value: number;
  label: string;
  icon: ReactElement | ((value: number) => ReactElement);
  formatter?: (value: number) => string | ReactElement;
  button?: ReactElement;
  extraStatContent?: ReactElement;
  statNumberColor?: string;
  statLabelColor?: string;
};

export type SummaryStatVariant = Omit<SummaryStatProps, "value">;

const STAT_STACK_DIRECTION = mobileStyleBreakpoint<StackDirection>(
  "column",
  "row"
);
export const SUMMARY_STAT_CARD_TEXT_STYLE = mobileStyleBreakpoint("h4", "h3");

const SummaryStatCard = ({
  label,
  value,
  icon,
  formatter = addDigitSeparators,
  button,
  extraStatContent,
  statNumberColor,
  statLabelColor,
  ...rest
}: SummaryStatProps & CardProps) => {
  const statStackDirection = useBreakpointValue(STAT_STACK_DIRECTION);

  return (
    <Card p={6} boxShadow="medium" minW="310px" {...rest} flex={1}>
      <CardBody h="100%" px={2} display="flex" alignItems="center">
        <Stack
          w="100%"
          direction={!button ? "row" : statStackDirection}
          alignItems="center"
        >
          <HStack spacing={4}>
            <Box>{typeof icon === "function" ? icon(value) : icon}</Box>
            <Stat>
              <Tooltip label={formatter(value)} hasArrow placement="top">
                <StatNumber
                  maxW="220px"
                  minH={10}
                  color={statNumberColor}
                  isTruncated
                  textStyle={SUMMARY_STAT_CARD_TEXT_STYLE}
                >
                  {formatter(value)}
                </StatNumber>
              </Tooltip>

              <StatLabel whiteSpace="nowrap" color={statLabelColor}>
                {label}
              </StatLabel>
            </Stat>
            {extraStatContent}
          </HStack>
          <Spacer />
          {button}
        </Stack>
      </CardBody>
    </Card>
  );
};

export default SummaryStatCard;
