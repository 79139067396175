import { Navigate, RouteObject } from "react-router-dom";

import ErrorCard from "components/ErrorCard";
import ParentProvider from "pages/forgot-password/ParentProvider";
import ForgotPasswordCard from "pages/forgot-password/ForgotPasswordCard";
import ResetPasswordCard from "pages/forgot-password/ResetPasswordCard";
import forgotPasswordPaths from "./forgotPasswordPaths";

export const forgotPasswordRoutes: RouteObject = {
  path: forgotPasswordPaths.basePath,
  element: <ParentProvider />,
  children: [
    {
      path: forgotPasswordPaths.children.forgotPassword,
      element: <ForgotPasswordCard />,
    },
    {
      path: forgotPasswordPaths.children.resetPassword,
      element: <ResetPasswordCard />,
    },
    {
      path: forgotPasswordPaths.children.error,
      element: <ErrorCard />,
    },
    {
      index: true,
      element: (
        <Navigate
          to={forgotPasswordPaths.children.forgotPassword}
          replace={true}
        />
      ),
    },
  ],
};

export default forgotPasswordRoutes;
