import { ReactElement, useState } from "react";

import { useDisclosure } from "@chakra-ui/react";

import DeleteButton from "components/DeleteButton";
import useDeleteFarmsFromCropYear from "hooks/crop-year/useDeleteFarmsFromCropYear";
import useCustomToast from "hooks/useCustomToast";
import { CropYearListItem } from "hooks/crop-year/useCropYears";
import DeleteFarmsModal from "./DeleteFarmsModal";

const DeleteFarmsButton = ({
  cropYear,
  farmIds,
  isDisabled,
  onSuccess,
}: {
  cropYear?: CropYearListItem;
  farmIds: Array<string>;
  isDisabled: boolean;
  onSuccess(): void;
}): ReactElement => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { onSuccessToast, onErrorToast } = useCustomToast();
  const { mutateAsync: deleteFarmsAsync } = useDeleteFarmsFromCropYear();
  const [errorFieldList, setErrorFieldList] = useState<Array<string>>([]);

  const onDelete = () => {
    if (farmIds.length > 0) {
      deleteFarmsAsync({ cropYearId: cropYear?.id, farmIds })
        .then(() => {
          onSuccess();
          onClose();
          onSuccessToast({ message: "Farm(s) deleted successfully" });
        })
        .catch((error) => {
          const { response } = error;
          const fieldList = response?.data?.validation
            ? Object.keys(response.data.validation)
            : [];
          if (response.status === 400 && fieldList?.length > 0) {
            setErrorFieldList(fieldList);
          } else {
            onErrorToast({ message: "Failed to delete farm(s)" });
            onClose();
          }
        });
    }
  };
  const onCloseErrorModal = () => {
    setErrorFieldList([]);
    onClose();
  };

  return (
    <>
      <DeleteButton onClick={onOpen} isDisabled={isDisabled} />
      <DeleteFarmsModal
        isOpen={isOpen}
        onClose={onClose}
        onDelete={onDelete}
        fieldList={errorFieldList}
        onCloseErrorModal={onCloseErrorModal}
      />
    </>
  );
};

export default DeleteFarmsButton;
