export const REGEX_PASSWORD_CRITERIA = {
  CONTAINS_LOWERCASE_LETTER: /[a-z]/,
  CONTAINS_UPPERCASE_LETTER: /[A-Z]/,
  CONTAINS_NUMBER: /\d/,
  CONTAINS_SPECIAL_CHARACTER: /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/,
};

export const MIN_PASSWORD_LENGTH = 12;

export const MAX_IMAGE_FILE_SIZE_MB = 16;
