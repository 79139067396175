import { PropsWithChildren } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

import { FarmDetailResponse } from "api";
import { SelectOption } from "components/Select";
import {
  employeeFarmDetailFormSchema,
  farmDetailFormSchema,
} from "forms/farm-detail";
import { formatPhoneNumber } from "utils/formatFunctions";
import {
  organizationStateSelectOptions,
  stateSelectOptions,
} from "constants/stateSelectOptions";
import { LIEN_HOLDERS_FIELD_NAME } from "forms/farm-detail/LienHolderFields";
import { useUserInfo } from "contexts/UserInfoProvider";
import { EMPTY_PHONE } from "components/inputs/PhoneInput";

export type FarmDetailFields = {
  region: SelectOption | undefined;
  entityId: number | undefined;
  doingBusinessAs: string;
  mainContact: SelectOption | undefined;
  phoneNumber: string;
  streetAddress: string;
  city: string;
  state: SelectOption | undefined;
  zip: string;
  email: string;
  orgState: SelectOption | undefined;
  lienHolders: { id: string | undefined; value: string }[];
};

export const verifyAllRequiredFields = (
  values: FarmDetailFields,
  excludeRegion = false
) =>
  Object.entries(values).every(([fieldName, value]) => {
    switch (fieldName) {
      case "region":
        return excludeRegion ? true : !!value;
      case "entityId":
        return true;
      case LIEN_HOLDERS_FIELD_NAME:
        return Array.isArray(value) && value.some(({ value }) => !!value);
      case "phoneNumber":
        return (
          value !== EMPTY_PHONE && typeof value === "string" && value.length > 0
        );
      default:
        return !!value;
    }
  });

const FarmFormContext = ({
  children,
  farmData,
}: PropsWithChildren<{
  farmData?: FarmDetailResponse;
}>) => {
  const { isEmployee, ...user } = useUserInfo();
  const methods = useForm<FarmDetailFields>({
    resolver: yupResolver(
      isEmployee ? employeeFarmDetailFormSchema : farmDetailFormSchema
    ),
    defaultValues: {
      region: farmData?.region
        ? { value: farmData.region.id, label: farmData.region.name }
        : undefined,
      entityId: farmData?.entityId,
      doingBusinessAs: farmData?.doingBusinessAs,
      phoneNumber: formatPhoneNumber(farmData?.farmPrimaryPhoneNumber),
      mainContact: farmData?.mainContact
        ? {
            value: farmData?.mainContact.id,
            label: `${farmData?.mainContact.firstName} ${farmData?.mainContact.lastName}`,
          }
        : {
            value: user?.id,
            label: `${user?.firstName} ${user?.lastName}`,
          },
      email: farmData?.primaryEmailAddress ?? user?.email,
      streetAddress: farmData?.mailingAddress.address,
      city: farmData?.mailingAddress.city,
      state: farmData?.mailingAddress.state
        ? stateSelectOptions.find(
            ({ value }) => value === farmData.mailingAddress.state
          )
        : undefined,
      zip: farmData?.mailingAddress.zipCode,
      orgState: farmData?.stateOfOrganization
        ? organizationStateSelectOptions.find(
            ({ value }) => value === farmData.stateOfOrganization
          )
        : undefined,
      lienHolders: farmData?.lienHolders.map((lienHolder) => ({
        value: lienHolder.name,
        id: lienHolder.id,
      })) ?? [{ value: "" }],
    },
  });

  return <FormProvider {...methods}>{children}</FormProvider>;
};

export default FarmFormContext;
