import { PropsWithChildren, ReactElement } from "react";
import { Outlet } from "react-router-dom";

import { SubmitHandler } from "react-hook-form";
import { Center, HStack, Stack } from "@chakra-ui/react";

import { DesktopOnly } from "components/BreakpointVisibility";
import ContentWrapper from "components/ContentWrapper";
import SiteHeader from "components/SiteHeader";
import NavigationContainer from "components/navigation/NavigationContainer";
import {
  DESKTOP_HEADER_PADDING,
  HEADING_SPACING,
  MOBILE_HEADER_PADDING,
} from "components/page-components";
import { mobileStyleBreakpoint } from "utils/styleHelpers";

export const FORM_CONTENT_PX = mobileStyleBreakpoint(
  MOBILE_HEADER_PADDING,
  DESKTOP_HEADER_PADDING
);

const FormLayout = (): ReactElement => (
  <Stack h="100vh" overflow="hidden" spacing={0}>
    <SiteHeader />
    <HStack flex={1} spacing={0} align="stretch">
      <DesktopOnly>
        <NavigationContainer />
      </DesktopOnly>
      <Center px={FORM_CONTENT_PX} w="100%" overflow="hidden">
        <ContentWrapper>
          <Outlet />
        </ContentWrapper>
      </Center>
    </HStack>
  </Stack>
);

export default FormLayout;

export const FormStack = ({
  children,
  ...props
}: PropsWithChildren<{ onSubmit?: SubmitHandler<any> }>) => (
  <form {...props}>
    <Stack spacing={HEADING_SPACING} pb={10}>
      {children}
    </Stack>
  </form>
);
