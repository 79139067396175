/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface InviteUserRequest
 */
export interface InviteUserRequest {
  /**
   *
   * @type {string}
   * @memberof InviteUserRequest
   */
  emailAddress: string;
  /**
   *
   * @type {string}
   * @memberof InviteUserRequest
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof InviteUserRequest
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof InviteUserRequest
   */
  role: InviteUserRequestRoleEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum InviteUserRequestRoleEnum {
  RiverviewAdmin = "RIVERVIEW_ADMIN",
  RiverviewEmployee = "RIVERVIEW_EMPLOYEE",
  FarmAdmin = "FARM_ADMIN",
  FarmViewer = "FARM_VIEWER",
}
