import { ComponentStyleConfig } from "@chakra-ui/react";

const Modal: ComponentStyleConfig = {
  baseStyle: {
    dialog: {
      py: "4.5rem",
      px: {
        base: 6,
        sm: "72px",
      },
      borderRadius: 16,
    },
    header: {
      pt: 0,
      pb: 4,
      px: 0,
    },
    body: {
      px: 0,
      py: 0,
    },
  },
};

export default Modal;
