import {
  createContext,
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from "react";

type ForgotPasswordFormContextValue = {
  email: string | null;
  setEmail: Dispatch<SetStateAction<string | null>>;
};

const ForgotPasswordFormContext = createContext<ForgotPasswordFormContextValue>(
  {
    email: null,
    setEmail: () => {
      return;
    },
  }
);

const ForgotPasswordFormProvider = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const [email, setEmail] = useState<string | null>(null);

  const value = useMemo<ForgotPasswordFormContextValue>(
    () => ({
      email,
      setEmail,
    }),
    [email]
  );

  return (
    <ForgotPasswordFormContext.Provider value={value}>
      {children}
    </ForgotPasswordFormContext.Provider>
  );
};

export default ForgotPasswordFormProvider;

export const useForgotPasswordForm = (): ForgotPasswordFormContextValue => {
  return useContext(ForgotPasswordFormContext);
};
