import { Box, Stack } from "@chakra-ui/react";
import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";

import {
  EditAveragePricingModal,
  PostHarvestEditAveragePricingModal,
} from "../AveragePricingModal";
import DeletePricingOptionModal from "../DeletePricingOptionModal";
import { FarmPricingMenu } from "../PricingMenu";
import {
  individualFarmBaseColumns,
  postHarvestIndividualFarmColumns,
  preHarvestIndividualFarmColumns,
  pricingListTableColumns,
} from "../pricingTableColumnDefinitions";
import {
  PricingOptionDto,
  PricingOptionResponse,
  PricingOptionStatusDtoStatusEnum,
} from "api";
import FetchMoreWhenInView from "components/FetchMoreWhenInView";
import { PAGE_HEADER_PX } from "components/page-components";
import { TABLE_MARGIN_TOP } from "components/SortableTable";
import WithSpinner from "components/WithSpinner";
import { useCropYearContext } from "contexts/CropYearContext";
import { ADD_PRICING } from "constants/featureFlags";
import useUserRoleFlags from "hooks/auth/useUserRoleFlags";
import useFarmCropYearPricingOptions from "hooks/pricing-option/useFarmCropYearPricingOptions";
import { useHasFeatureFlag } from "hooks/useFeatureFlags";
import useNavigateWithQuery from "hooks/useNavigateWithQuery";
import useSelectedFarmId from "hooks/useSelectedFarmId";
import useSelectionIds from "hooks/useSelectionIds";
import useSort, {
  addSecondarySort,
  ColumnsWithSecondarySort,
  DEFAULT_SORT_DIRECTION,
  Sort,
  SortDirection,
} from "hooks/useSort";
import SilageDashboardTabLayout from "layouts/SilageDashboardTabLayout";
import DashboardListTable from "pages/dashboard/components/DashboardListTable";
import dashboardPaths from "routes/dashboard/dashboardPaths";
import { CropYearPricingOptionsSortColumns } from "services/PricingOptionService";
import {
  isAveragePricingContract,
  isDelayedPricingContract,
  isForwardContract,
} from "utils/contractType";
import { mobileStyleBreakpoint } from "utils/styleHelpers";

const preHarvestDefaultSort: Sort<CropYearPricingOptionsSortColumns> = {
  key: "status",
  direction: DEFAULT_SORT_DIRECTION,
};
const postHarvestDefaultSort: Sort<CropYearPricingOptionsSortColumns> = {
  key: "dateOfContract",
  direction: SortDirection.DESC,
};

type SelectablePricingOption = Pick<
  PricingOptionResponse,
  "id" | "status" | "type"
>;

const columnsWithSecondarySort: ColumnsWithSecondarySort<CropYearPricingOptionsSortColumns> =
  {
    status: {
      key: "type",
      direction: DEFAULT_SORT_DIRECTION,
    },
    dateOfContract: {
      key: "type",
      direction: DEFAULT_SORT_DIRECTION,
    },
  };

const IndividualFarmPricingListTab = (): ReactElement => {
  const { isEmployee, isEmployeeAdmin, isFarmAdmin } = useUserRoleFlags();
  const hasAddPricingFeature = useHasFeatureFlag(
    ADD_PRICING.ADD_PRICING_BUTTON
  );
  const navigateWithQuery = useNavigateWithQuery();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedPricingOption, setSelectedPricingOption] = useState<
    SelectablePricingOption | undefined
  >();
  const [farmId] = useSelectedFarmId();
  const {
    isLoading: cropYearLoading,
    cropYear,
    isPreHarvest,
  } = useCropYearContext();

  const navigateToPricingContract = useCallback(
    (pricingPath: string, pricingOptionId: string) =>
      navigateWithQuery(
        `/${dashboardPaths.basePath}/${pricingPath}/${pricingOptionId}`
      ),
    [navigateWithQuery]
  );

  const navigateToPricingOption = (
    pricingOption: SelectablePricingOption
  ): void => {
    if (isForwardContract(pricingOption.type)) {
      // take to new flow if an active forward contract
      if (
        pricingOption.status.status ===
          PricingOptionStatusDtoStatusEnum.Active ||
        (pricingOption.status.status ===
          PricingOptionStatusDtoStatusEnum.Pending &&
          isEmployee)
      ) {
        navigateToPricingContract(
          dashboardPaths.children.forwardPricing,
          pricingOption.id
        );
      } else {
        // navigate to review page if contract is pending and user is farm admin
        isFarmAdmin &&
          navigateWithQuery(
            `/${dashboardPaths.basePath}/${dashboardPaths.children.reviewForwardPricing}/${pricingOption.id}`
          );
      }
    } else if (isDelayedPricingContract(pricingOption.type)) {
      navigateToPricingContract(
        dashboardPaths.children.delayedPricing,
        pricingOption.id
      );
    }
  };

  const defaultSort = isPreHarvest
    ? preHarvestDefaultSort
    : postHarvestDefaultSort;

  const { sort, toggleSort, setSort } =
    useSort<CropYearPricingOptionsSortColumns>(defaultSort);

  const {
    data: farmPricing,
    isLoading: farmPricingLoading,
    isFetchingNextPage: isFetchingNextFarmPricingPage,
    fetchNextPage: fetchNextFarmPricingPage,
    hasNextPage: hasNextFarmPricingPage,
  } = useFarmCropYearPricingOptions({
    cropYearId: cropYear?.id,
    farmId,
    queryParams: {
      search: searchTerm,
      sort: addSecondarySort(sort, columnsWithSecondarySort),
    },
  });
  const tableColumns = mobileStyleBreakpoint(
    individualFarmBaseColumns,
    isPreHarvest
      ? preHarvestIndividualFarmColumns
      : postHarvestIndividualFarmColumns,
    "md"
  );

  const farmPricingData = useMemo(
    () => farmPricing?.pages?.flatMap((response) => response.data) ?? [],
    [farmPricing?.pages]
  );

  const tableData = useMemo(
    () =>
      pricingListTableColumns({
        data: farmPricingData,
        isFarmAdmin,
        isPreHarvest,
      }),
    [farmPricingData, isFarmAdmin, isPreHarvest]
  );

  const { selectedIds, onToggleId, clearSelectedIds } = useSelectionIds(
    tableData.map((pricingOption) => pricingOption.id)
  );

  const selectedPricingOptions: PricingOptionDto[] = useMemo(
    () =>
      farmPricingData.filter((pricingOption) =>
        selectedIds.includes(pricingOption.id)
      ),
    [farmPricingData, selectedIds]
  );

  const disableSelectRow = isFarmAdmin && !isPreHarvest;

  const disableDeleteButton = useMemo(() => {
    return !selectedPricingOptions.every(
      (pricingOption) =>
        (isForwardContract(pricingOption.type) &&
          isEmployeeAdmin &&
          pricingOption.status.status !==
            PricingOptionStatusDtoStatusEnum.Active) ||
        (isAveragePricingContract(pricingOption.type) && isFarmAdmin)
    );
  }, [isEmployeeAdmin, isFarmAdmin, selectedPricingOptions]);

  useEffect(() => {
    setSort(defaultSort);
  }, [setSort, searchTerm, cropYear?.id, defaultSort]);

  const handleOnClickRow = (id: string): void => {
    const _selectedPricingOption = farmPricing?.pages
      ?.flatMap((page) => page.data)
      .find((pricingOption) => pricingOption.id === id);
    _selectedPricingOption &&
      setSelectedPricingOption({
        id: _selectedPricingOption.id,
        type: _selectedPricingOption.type,
        status: _selectedPricingOption.status,
      });

    if (_selectedPricingOption) {
      navigateToPricingOption(_selectedPricingOption);
    }
  };

  const resetSelectedPricingOption = () => setSelectedPricingOption(undefined);

  const showAveragePricingContractModal =
    selectedPricingOption &&
    isAveragePricingContract(selectedPricingOption.type);

  const showPreHavestAveragePricingModal =
    showAveragePricingContractModal && isPreHarvest;

  const showPostHarvestAveragePricingModal =
    showAveragePricingContractModal && !isPreHarvest;

  const showDeleteButton = isPreHarvest || isEmployeeAdmin;

  return (
    <>
      {showPreHavestAveragePricingModal && (
        <EditAveragePricingModal
          pricingOptionId={selectedPricingOption.id}
          isOpen={showPreHavestAveragePricingModal}
          onClose={resetSelectedPricingOption}
        />
      )}
      {showPostHarvestAveragePricingModal && (
        <PostHarvestEditAveragePricingModal
          pricingOptionId={selectedPricingOption.id}
          isOpen={showPostHarvestAveragePricingModal}
          onClose={resetSelectedPricingOption}
        />
      )}
      <SilageDashboardTabLayout
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        heading={"Pricing Options"}
        addButton={
          hasAddPricingFeature ? (
            <FarmPricingMenu openAveragePricingContract={handleOnClickRow} />
          ) : null
        }
      >
        <WithSpinner isLoading={cropYearLoading || farmPricingLoading}>
          <Stack spacing={TABLE_MARGIN_TOP} w="100%">
            <Box ml={PAGE_HEADER_PX}>
              {showDeleteButton && (
                <DeletePricingOptionModal
                  isDisabled={selectedIds.length === 0 || disableDeleteButton}
                  pricingOptionsData={selectedPricingOptions}
                  onSuccess={clearSelectedIds}
                  cropYearId={cropYear?.id}
                  farmId={farmId}
                />
              )}
            </Box>
            <DashboardListTable
              data={tableData}
              sort={sort}
              onClickRow={handleOnClickRow}
              toggleSort={toggleSort}
              columns={tableColumns}
              emptyTableMsg={
                searchTerm.length === 0
                  ? "No pricing options."
                  : "No Results Found"
              }
              onSelectRow={disableSelectRow ? undefined : onToggleId}
              selectedRows={selectedIds}
            />
            {tableData.length > 0 && (
              <FetchMoreWhenInView
                fetchNextPage={fetchNextFarmPricingPage}
                hasNextPage={hasNextFarmPricingPage}
                isFetchingNextPage={isFetchingNextFarmPricingPage}
                key={`${farmPricing?.pages.length}`}
              />
            )}
          </Stack>
        </WithSpinner>
      </SilageDashboardTabLayout>
    </>
  );
};

export default IndividualFarmPricingListTab;
