import {
  Checkbox,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { ReactElement } from "react";
import SortArrow from "components/SortArrow";
import { Sort, SortDirection } from "hooks/useSort";
import { mobileStyleBreakpoint } from "utils/styleHelpers";

export type TableData<T extends Record<string, any> = Record<string, any>> = T;
export type ColumnProps<SortKeys = never> = Record<string, any> & {
  heading: string;
  sortKey?: SortKeys;
  id: string;
};

export const TABLE_MARGIN_TOP = mobileStyleBreakpoint(4, 8);

export const headingToKey = (heading: string) => `column-${heading}`;

const SortableTable = <SortKeys,>({
  columns = [],
  data = [],
  onClickRow,
  selectedRows = [],
  onSelectRow,
  onSelectAllRows,
  sort,
  toggleSort = () => {
    return;
  },
}: {
  columns?: ColumnProps<SortKeys>[];
  data?: TableData[];
  onClickRow?: (rowData: TableData) => void;
  sort?: Sort<SortKeys>;
  toggleSort?: (sortKey: SortKeys) => void;
  selectedRows?: string[];
  onSelectRow?: (id?: string) => void;
  onSelectAllRows?: () => void;
}): ReactElement => {
  return (
    <TableContainer w="100%">
      <Table>
        {columns.length > 0 && (
          <Thead>
            <Tr>
              {onSelectRow && (
                <Th pl={7} pr={1} w="48px">
                  {onSelectAllRows && (
                    <Checkbox
                      onChange={onSelectAllRows}
                      isChecked={
                        selectedRows &&
                        data &&
                        selectedRows.length === data.length
                      }
                    />
                  )}
                </Th>
              )}
              {columns.map(({ heading, sortKey }, key) => (
                <Th
                  key={headingToKey(heading ?? key)}
                  w={1 / columns.length}
                  data-testid={headingToKey(heading ?? key)}
                >
                  <HStack spacing={0}>
                    <Text textStyle="label">{heading ?? ""}</Text>
                    {!!sortKey && (
                      <SortArrow
                        direction={
                          sort?.key === sortKey
                            ? sort.direction
                            : SortDirection.NEUTRAL
                        }
                        onClick={() => toggleSort(sortKey)}
                      />
                    )}
                  </HStack>
                </Th>
              ))}
            </Tr>
          </Thead>
        )}
        <Tbody>
          {data?.map((rowData, rowKey) => {
            const selected = !!selectedRows.find(
              (selectedRow) => selectedRow === rowData.id
            );

            return (
              <Tr
                key={`row-${rowKey}`}
                bg={selected ? "greyFactor.0" : undefined}
                onClick={() => onClickRow?.(rowData)}
              >
                {onSelectRow && (
                  <Td
                    pl={7}
                    pr={1}
                    w="48px"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Checkbox
                      onChange={() => onSelectRow?.(rowData.id)}
                      isChecked={selected}
                    />
                  </Td>
                )}
                {columns.length > 0
                  ? columns.map(({ id }) => (
                      <Td key={`row-${rowKey}-${id}`}>{rowData[id]}</Td>
                    ))
                  : Object.entries(rowData).map(([cellKey, value]) => (
                      <Td key={`row-${rowKey}-${cellKey}`}>{value}</Td>
                    ))}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default SortableTable;
