import { Navigate, useOutletContext, useParams } from "react-router-dom";
import { Center, Stack, Text } from "@chakra-ui/react";

import { CSVUploadJobResponseStatusEnum } from "api";
import UploadBox from "components/UploadBox";
import { CsvStep, UploadProgressSpinner } from "components/CsvUpload";
import useCsvUploadJob from "hooks/csv-upload/useCsvUploadJob";
import { csvChildPaths } from "routes/csv-uploads/csvPaths";

const CsvUploadProgress = () => {
  const [, setFile] =
    useOutletContext<[File, (file: File | undefined) => void]>();
  const { jobId } = useParams();
  const { data, isPending } = useCsvUploadJob(jobId, (data) => {
    if (data?.status === CSVUploadJobResponseStatusEnum.Success) {
      setFile(undefined);
    }
  });
  const isComplete = data?.status === CSVUploadJobResponseStatusEnum.Success;
  const isFailed = data?.status === CSVUploadJobResponseStatusEnum.Failed;
  const jobInProgress =
    isPending || data?.status === CSVUploadJobResponseStatusEnum.InProgress;
  if (isComplete) {
    return (
      <Navigate
        to={`../${
          (data?.itemsWithErrors ?? 0) > 0
            ? csvChildPaths.results
            : csvChildPaths.resultsNoErrors
        }/${jobId}`}
      />
    );
  }
  if (isFailed) {
    return <Navigate to={`../${csvChildPaths.failed}/${jobId}`} />;
  }

  return (
    <>
      <CsvStep
        currentStep={2}
        totalSteps={3}
        description="Checking for errors"
        backButtonHidden={true}
      />
      <UploadBox h="unset" py="134px" bg="gray.50" borderColor="gray.200">
        <Center>
          <Stack alignItems="center">
            <Text fontWeight="bold" color="steelGray">
              Uploading...
            </Text>
            <UploadProgressSpinner isLoading={jobInProgress} />
            <Text fontWeight="bold">
              Please wait while we check for errors in your data.
            </Text>
          </Stack>
        </Center>
      </UploadBox>
    </>
  );
};

export default CsvUploadProgress;
