import { ChakraTheme } from "@chakra-ui/react";
import { grey } from "./colors";

// rga(45, 55, 72) = #2D3748 = grey.700
const grey15 = `${grey["700"]}26`; // Alpha 15% = #26

const shadows: ChakraTheme["shadows"] = {
  xsmall: `0 2px 3px ${grey15}`,
  small: `0 3px 4px ${grey15}`,
  medium: `0 6px 12px ${grey15}`,
  large: `0 16px 20px ${grey15}`,
  xlarge: `0 16px 32px ${grey15}`,
};

export default shadows;
