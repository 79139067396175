import { parse, parseISO, format } from "date-fns";

export const formatDateWithTimeZone = new Intl.DateTimeFormat("en-US", {
  day: "numeric",
  hour: "numeric",
  hour12: true,
  minute: "2-digit",
  month: "long",
  timeZone: "America/Chicago",
  timeZoneName: "short",
  year: "numeric",
});

export const formatDate = (date: Date, _format = "MMM d, yyyy"): string =>
  format(date, _format);

export const ISODateWithoutTime = (date: Date): string =>
  formatDate(date, "yyyy-MM-dd");

export const formatMonthAndDate = (date: Date): string =>
  formatDate(date, "MMMM d");

export const parseDate = (dateString: string, _format = "yyyy-MM-dd"): Date =>
  parse(dateString, _format, new Date());

export const parseIsoDate = (dateString: string): Date => parseISO(dateString);
