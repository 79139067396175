import { PropsWithChildren } from "react";
import {
  isRouteErrorResponse,
  useRouteError,
  Link as RLink,
} from "react-router-dom";
import { Button, Center, Stack, Text } from "@chakra-ui/react";
import { ErrorStateIcon, PageNotFound } from "icons";

type ErrorBoundaryProps = {
  buttonText?: string;
  buttonLink?: string;
};

const ErrorBoundaryContainer = ({
  children,
  buttonText,
  buttonLink,
}: PropsWithChildren<ErrorBoundaryProps>) => (
  <Center h="100%">
    <Stack spacing={8} minH="400px" align="center" justify="center">
      {children}
      {buttonText && buttonLink && (
        <Button as={RLink} variant="secondary" to={buttonLink} size="md">
          {buttonText}
        </Button>
      )}
    </Stack>
  </Center>
);

const RouteErrorBoundary = ({ buttonText, buttonLink }: ErrorBoundaryProps) => {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return (
        <ErrorBoundaryContainer buttonText={buttonText} buttonLink={buttonLink}>
          <PageNotFound />
          <Text color="steelGrey" fontWeight="bold" fontSize="5xl">
            Page Not Found
          </Text>
          <Text color="steelGrey">Sorry, Page cannot be found</Text>
        </ErrorBoundaryContainer>
      );
    }

    if (error.status === 401) {
      return (
        <ErrorBoundaryContainer>
          <Text color="steelGrey">You aren&apos;t authorized to see this</Text>
        </ErrorBoundaryContainer>
      );
    }

    if (error.status >= 500) {
      return (
        <ErrorBoundaryContainer buttonText={buttonText} buttonLink={buttonLink}>
          <ErrorStateIcon />
          <Text color="steelGrey">Couldn&apos;t connect to server</Text>
        </ErrorBoundaryContainer>
      );
    }

    if (error.status >= 400 && error.status <= 499) {
      return (
        <ErrorBoundaryContainer buttonText={buttonText} buttonLink={buttonLink}>
          <ErrorStateIcon />
          <Text color="steelGrey" maxW="350px" textAlign="center">
            An error occured while processing your upload. Please try again
          </Text>
        </ErrorBoundaryContainer>
      );
    }
  }

  throw error;
};

export default RouteErrorBoundary;
