import { ReactElement, useEffect, useMemo } from "react";
import { Divider, TabList, Tabs } from "@chakra-ui/react";
import { DetailsTab, DocumentsTab } from "./FarmInfoTabs/FarmInfoTabs";
import useLastMatchedPathname from "hooks/useLastMatchedPathname";
import useNavigateWithQuery from "hooks/useNavigateWithQuery";
import farmPaths from "routes/farm/farmPaths";

type FarmInfoTabGroupProps = {
  disableDocuments?: boolean;
  tabIndex?: number;
  handleTabsChange?: (index: number) => void;
};

const FarmInfoTabGroup = ({
  disableDocuments = false,
  tabIndex,
  handleTabsChange,
  ...rest
}: FarmInfoTabGroupProps): ReactElement => {
  return (
    <Tabs {...rest} index={tabIndex} onChange={handleTabsChange}>
      <TabList borderBottom="none">
        <DetailsTab />
        <DocumentsTab isDisabled={disableDocuments} />
      </TabList>
      <Divider></Divider>
    </Tabs>
  );
};

export default FarmInfoTabGroup;

export const useFarmInfoTabs = (farmId: string | undefined) => {
  const tabPaths = useMemo(
    () => [farmPaths.children.edit, farmPaths.children.documents],
    []
  );

  const matchedPath = useLastMatchedPathname();

  const tabIndex = tabPaths.indexOf(matchedPath ?? "");
  const navigate = useNavigateWithQuery();

  const handleTabsChange = (index: number) => {
    navigate(`./${tabPaths[index]}`);
  };

  useEffect(() => {
    if (tabIndex === -1) {
      navigate(`${farmId}/${tabPaths[0]}`);
    }
  }, [tabIndex, navigate, farmId, tabPaths]);

  return {
    tabIndex,
    handleTabsChange,
  };
};
