import { ChangeEvent } from "react";
import { Box, chakra, Input, Stack, Text } from "@chakra-ui/react";

import { UploadIcon } from "icons";
import UploadBox from "components/UploadBox";

const ImageUploadBox = ({
  onChange,
  previewUrl,
}: {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  previewUrl: string | undefined;
}) =>
  previewUrl ? (
    <Box borderRadius="14px" overflow="hidden">
      <chakra.img src={previewUrl} objectFit="contain" h="208px" w="100%" />
    </Box>
  ) : (
    <UploadBox>
      <Input
        accept="image/*"
        type="file"
        id="profile-picture-select"
        display="none"
        onChange={onChange}
      />
      <label htmlFor="profile-picture-select">
        <Stack
          align="center"
          justify="center"
          h="100%"
          w="100%"
          _hover={{ cursor: "pointer" }}
        >
          <UploadIcon boxSize="24px" />
          <Text color="brand.800" fontWeight="bold">
            Upload Image
          </Text>
        </Stack>
      </label>
    </UploadBox>
  );

export default ImageUploadBox;
