import { ReactElement } from "react";

import DecimalNumberInput, {
  DecimalNumberInputProps,
} from "../DecimalNumberInput";
import { TonsAddon } from "../EstimatedYieldInput";

export const ActualYieldInput = (
  props: DecimalNumberInputProps
): ReactElement => {
  return (
    <DecimalNumberInput
      {...props}
      rightAddon={<TonsAddon />}
      maxDecimalNumbers={30}
      maxLength={35}
    />
  );
};

export default ActualYieldInput;
