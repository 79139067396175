import { SelectOption } from "components/Select";
import * as yup from "yup";
import { getIsDeferPayment } from "utils/getIsDeferPayment";

export const createDelayedPricingFormSchema = (
  tonsAvailableToSell: number | undefined,
  isManuallyCreated: boolean
) => {
  const baseDelayedPricingSchema = {
    tonsToSell: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required("Tons to Sell is required")
      .moreThan(0, "The number cannot be 0 or less")
      .max(tonsAvailableToSell ?? 0, "Exceeds available tons to sell"),
    deferPayment: yup.object().required("Defer payment is required"),
    defermentDate: yup.date().when("deferPayment", {
      is: (deferPayment: SelectOption) => getIsDeferPayment(deferPayment.value),
      then: (schema) => schema.required("Defer payment date is required"),
    }),
  };

  if (isManuallyCreated) {
    return yup.object({
      ...baseDelayedPricingSchema,
      dateOfContract: yup.date().required("Date of Contract is required"),
      cashPrice: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .min(0.01, "Cash Price must be greater than 0")
        .max(99.99, "Cash Price must be less than 100")
        .required("Cash Price is required"),
    });
  }

  return yup.object({
    ...baseDelayedPricingSchema,
  });
};
