import { RouteObject } from "react-router";

import haylagePaths from "./haylagePaths";
import ProtectedRoute from "components/route-wrappers/ProtectedRoute";
import HaylageYearProvider from "contexts/HaylageYearProvider";
import TableLayout from "layouts/TableLayout";
import AddHaylageFarmsPage from "pages/haylage/AddFarmsToHaylageYear/AddHaylageFarmsPage";
import HaylageYearFarmDocumentListPage from "pages/haylage/farm-documents/HaylageYearFarmDocumentListPage";
import HaylageFarmListPage from "pages/haylage/HaylageFarmListPage";

const haylageRoutes: RouteObject = {
  path: haylagePaths.basePath,
  element: (
    <ProtectedRoute>
      <HaylageYearProvider>
        <TableLayout />
      </HaylageYearProvider>
    </ProtectedRoute>
  ),
  children: [
    {
      path: haylagePaths.children.allFarms,
      element: <HaylageFarmListPage />,
    },
    {
      path: haylagePaths.children.addFarms,
      element: <AddHaylageFarmsPage />,
    },
    {
      path: haylagePaths.children.farmDocuments,
      element: <HaylageYearFarmDocumentListPage />,
    },
  ],
};

export default haylageRoutes;
