import { useBreakpointValue } from "@chakra-ui/react";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import FarmListTable from "components/FarmListTable/FarmListTable";
import {
  baseListColumns,
  desktopListColumns,
  farmListColumnsWithSecondarySort,
  farmListDefaultSort,
  FarmsSortColumns,
  listTableDataMapper as manureListTableDataMapper,
} from "components/FarmListTable/listTableDataMapper";
import { TableData } from "components/SortableTable";
import useGetManureYearFarms from "hooks/api/manure-year/useGetManureYearFarms";
import useSelectedManureYearId, {
  MANURE_YEAR_ID_QUERY_STRING_KEY,
} from "hooks/useSelectedManureYearId";
import { FARM_ID_QUERY_STRING_KEY } from "hooks/useSelectedFarmId";
import useSort, { addSecondarySort } from "hooks/useSort";
import manurePaths from "routes/manure/manurePaths";
import { mobileStyleBreakpoint } from "utils/styleHelpers";

const manureListTableColumns = mobileStyleBreakpoint(
  baseListColumns,
  desktopListColumns,
  "md"
);

const ManureFarmListTable = () => {
  const columns = useBreakpointValue(manureListTableColumns);
  const navigate = useNavigate();
  const [selectedManureYearId] = useSelectedManureYearId();
  const useSortProps = useSort<FarmsSortColumns>(farmListDefaultSort);
  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useGetManureYearFarms(selectedManureYearId, {
      sort: addSecondarySort(
        useSortProps.sort,
        farmListColumnsWithSecondarySort
      ),
    });

  const farms = useMemo(
    () => data?.pages?.map(({ manureYearFarms: farms }) => farms).flat() ?? [],
    [data]
  );

  const tableData = useMemo(() => manureListTableDataMapper(farms), [farms]);

  const onClickRow = useCallback(
    async ({ farmId }: TableData) => {
      navigate(
        `/${manurePaths.basePath}/${manurePaths.children.farmDocuments}?${FARM_ID_QUERY_STRING_KEY}=${farmId}&${MANURE_YEAR_ID_QUERY_STRING_KEY}=${selectedManureYearId}`
      );
    },
    [navigate, selectedManureYearId]
  );

  return (
    <FarmListTable
      programType="Manure"
      isLoading={isLoading}
      columns={columns}
      tableData={tableData}
      defaultSort={farmListDefaultSort}
      onClickRow={onClickRow}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      {...useSortProps}
    />
  );
};

export default ManureFarmListTable;
