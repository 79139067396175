import { Badge } from "@chakra-ui/react";

import { UserListItem } from "hooks/user/useUsersList";
import { FarmListItem } from "hooks/farm/useFarmsList";

export const PENDING_STATUS = { value: "PENDING", displayName: "Pending" };

const StatusColumn = ({
  status,
}: {
  status:
    | UserListItem["status"]
    | FarmListItem["status"]
    | typeof PENDING_STATUS;
}) => <Badge variant={status.value.toLowerCase()}>{status.displayName}</Badge>;

export default StatusColumn;
