import { useQuery } from "@tanstack/react-query";

import { CropYearFarmSummaryDto } from "api";
import { useCropYearService } from "contexts/ServiceProvider";
import { BaseApiError } from "services/apiHelpers";

export const GET_FARM_CROP_YEAR_SUMMARY_QUERY_KEY = "farm-crop-year-summary";

const useFarmCropYearSummary = (cropYearId?: string, farmId?: string) => {
  const cropYearService = useCropYearService();

  return useQuery<CropYearFarmSummaryDto | undefined, BaseApiError>({
    queryKey: [GET_FARM_CROP_YEAR_SUMMARY_QUERY_KEY, { cropYearId, farmId }],
    queryFn: () =>
      cropYearId && farmId
        ? cropYearService.getFarmCropYearSummary(cropYearId, farmId)
        : Promise.reject("Missing crop year id and farm id"),
    enabled: !!farmId && !!cropYearId,
  });
};

export default useFarmCropYearSummary;
