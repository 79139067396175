import React from "react";

import { PricingInfoRecordStamp } from "components/PricingInfoRecordStamp/PricingInfoRecordStamp";

const ContractCreatedRecordStamp = ({
  createdBy,
  createdAt,
}: {
  createdBy?: string;
  createdAt: string;
}) => (
  <PricingInfoRecordStamp
    initiatedBy={createdBy}
    dateInitiated={createdAt}
    label="Contract Created :"
    labelTextColor="greyFactor.4"
    valueTextColor="charcoal"
  />
);

export default ContractCreatedRecordStamp;
