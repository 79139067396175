import { PropsWithChildren, ReactElement } from "react";

import {
  Box,
  Center,
  ThemingProps,
  useRadio,
  UseRadioProps,
  useStyleConfig,
} from "@chakra-ui/react";

export type RadioButtonProps = ThemingProps & UseRadioProps;

const RadioButton = ({
  variant,
  children,
  isChecked,
  isDisabled,
  ...rest
}: PropsWithChildren<RadioButtonProps>): ReactElement => {
  const styles = useStyleConfig("RadioButton", { variant });
  const { getInputProps, getCheckboxProps } = useRadio({
    isChecked,
    isDisabled,
    ...rest,
  });

  return (
    <Box as="label">
      <input {...getInputProps({})} />
      <Center __css={styles} {...getCheckboxProps()} {...rest}>
        {children}
      </Center>
    </Box>
  );
};

export default RadioButton;
