import { useMutation } from "@tanstack/react-query";

import { useUserService } from "contexts/ServiceProvider";
import UserService from "services/UserService";
import { MutationHookResult, MutationHook } from "../hookTypeHelpers";

type ResendInvitesMutationResult = MutationHookResult<
  Awaited<ReturnType<UserService["resendInvites"]>>,
  {
    userIds: string[];
    farmId?: string;
  }
>;

const useResendInvitesMutation = (): ResendInvitesMutationResult => {
  const userService = useUserService();

  return useMutation({
    mutationKey: ["resend-invites"],
    mutationFn: ({ userIds, farmId }) =>
      userService.resendInvites(userIds, farmId),
  });
};

const useResendInvites = (): MutationHook<
  ResendInvitesMutationResult,
  "onResendInvites"
> => {
  const {
    mutate: onResendInvites,
    mutateAsync: onResendInvitesAsync,
    ...rest
  } = useResendInvitesMutation();

  return { onResendInvites, onResendInvitesAsync, ...rest };
};

export default useResendInvites;
