/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface PricingOptionStatusDto
 */
export interface PricingOptionStatusDto {
  /**
   *
   * @type {string}
   * @memberof PricingOptionStatusDto
   */
  status: PricingOptionStatusDtoStatusEnum;
  /**
   *
   * @type {string}
   * @memberof PricingOptionStatusDto
   */
  displayName: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PricingOptionStatusDtoStatusEnum {
  Pending = "PENDING",
  Active = "ACTIVE",
  Void = "VOID",
}
