import { ReactElement, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import IndividualFarmPricingListTab from "./IndividualFarmPricingListTab";
import RiverviewDashboardAllPricingListTab from "./RiverviewDashboardAllPricingListTab/RiverviewDashboardAllPricingListTab";

const PricingListTab = (): ReactElement => {
  const [searchParams] = useSearchParams();

  const isRiverviewDashboard = useMemo(
    () => searchParams.get("farmId") === null,
    [searchParams]
  );

  return isRiverviewDashboard ? (
    <RiverviewDashboardAllPricingListTab />
  ) : (
    <IndividualFarmPricingListTab />
  );
};

export default PricingListTab;
