import { HStack, Text } from "@chakra-ui/react";
import { AlertCircle } from "icons";

export type TextErrorMessageProps = {
  children: string;
};

const TextErrorMessage = ({ children }: TextErrorMessageProps) => (
  <HStack spacing={2}>
    <AlertCircle color="errorRed" />
    <Text textStyle="label" color="errorRed">
      {children}
    </Text>
  </HStack>
);

export default TextErrorMessage;
