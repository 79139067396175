import { Button } from "@chakra-ui/react";

import { useRegistrationForm } from "contexts/RegistrationFormContext";
import ProfilePictureAvatar from "components/ProfilePictureAvatar";
import { CardHeader } from "components/Card";
import ProfilePictureCard, {
  ProfilePictureCardFooter,
} from "./ProfilePictureCard";
import BackToPhoneNumberLink from "../components/BackToPhoneNumberLink";
import GoToTermsButton from "../components/GoToTermsButton";

const ConfirmProfilePictureCard = ({
  onShowUpload,
}: {
  onShowUpload: () => void;
}) => {
  const { imagePreviewUrl } = useRegistrationForm();

  return (
    <ProfilePictureCard>
      <ProfilePictureAvatar src={imagePreviewUrl} />
      <CardHeader>Looks Good!</CardHeader>
      <ProfilePictureCardFooter>
        <GoToTermsButton variant="primary">Continue</GoToTermsButton>
        <Button variant="outline" onClick={onShowUpload}>
          Change profile picture
        </Button>
        <BackToPhoneNumberLink />
      </ProfilePictureCardFooter>
    </ProfilePictureCard>
  );
};

export default ConfirmProfilePictureCard;
