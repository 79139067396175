import { ComponentStyleConfig } from "@chakra-ui/react";

const Alert: ComponentStyleConfig = {
  sizes: {
    xs: {
      container: {
        fontSize: "xs",
        padding: 0,
      },
    },
    sm: {
      container: {
        fontSize: "sm",
        padding: 1,
      },
    },
    lg: {
      container: {
        fontSize: "lg",
      },
    },
  },
  defaultProps: {
    size: undefined,
  },
};

export default Alert;
