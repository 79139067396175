import { Navigate, useSearchParams } from "react-router-dom";
import userRegistrationPaths from "./userRegistrationPaths";

export const RedirectToPhoneNumberPath = () => {
  const [searchParams] = useSearchParams();

  return (
    <Navigate
      to={`./${userRegistrationPaths.children.phoneNumberPath}?${searchParams}`}
      replace={true}
    />
  );
};
