import { PropsWithChildren } from "react";
import { Box, BoxProps } from "@chakra-ui/react";

const UploadBox = ({ children, ...rest }: PropsWithChildren<BoxProps>) => (
  <Box
    border="1px dashed"
    borderColor="brand.800"
    bg="brand.50"
    borderRadius="14px"
    h="208px"
    {...rest}
  >
    {children}
  </Box>
);

export default UploadBox;
