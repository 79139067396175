import { Center, Stack, useBreakpointValue } from "@chakra-ui/react";
import SortableTable, {
  ColumnProps,
  TABLE_MARGIN_TOP,
} from "components/SortableTable";
import TextHeading from "components/TextHeading";
import { Sort } from "hooks/useSort";
import useNavigateWithQuery from "hooks/useNavigateWithQuery";
import dashboardPaths from "routes/dashboard/dashboardPaths";

const DashboardListTable = ({
  columns: _columns,
  data,
  sort,
  toggleSort,
  emptyTableMsg,
  routeToBasePath = dashboardPaths.basePath,
  selectedRows,
  onSelectRow,
  onClickRow,
}: {
  columns: Record<string, ColumnProps<any>[]>;
  data: Record<string, any>[];
  sort: Sort<any>;
  toggleSort: any;
  emptyTableMsg?: string;
  routeToBasePath?: string;
  selectedRows?: string[];
  onSelectRow?: (id?: string) => void;
  onClickRow?: (id: string) => void;
}) => {
  const columns = useBreakpointValue(_columns);
  const navigate = useNavigateWithQuery();

  return (
    <Stack spacing={TABLE_MARGIN_TOP} w="100%">
      <SortableTable
        columns={columns}
        data={data}
        onClickRow={({ id }) =>
          onClickRow ? onClickRow(id) : navigate(`/${routeToBasePath}/${id}`)
        }
        sort={sort}
        toggleSort={toggleSort}
        selectedRows={selectedRows}
        onSelectRow={onSelectRow}
      />
      {data.length === 0 && (
        <Center>
          <TextHeading headingType="h5">{emptyTableMsg}</TextHeading>
        </Center>
      )}
    </Stack>
  );
};

export default DashboardListTable;
