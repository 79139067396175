import { RoleDtoValueEnum } from "api";
import { useUserInfo } from "contexts/UserInfoProvider";

const { RiverviewAdmin, FarmAdmin, FarmViewer } = RoleDtoValueEnum;
const useUserRoleFlags = () => {
  const userInfo = useUserInfo();
  if (!userInfo) {
    throw new Error("User info not loaded");
  }
  const {
    isEmployee,
    role: { value: roleValue },
  } = userInfo;

  return {
    isEmployee,
    isEmployeeOnly: isEmployee && roleValue !== RiverviewAdmin,
    isEmployeeAdmin: roleValue === RiverviewAdmin,
    isAdmin: roleValue === RiverviewAdmin || roleValue === FarmAdmin,
    isFarmer: roleValue === FarmAdmin || roleValue === FarmViewer,
    isFarmAdmin: roleValue === FarmAdmin,
  };
};

export default useUserRoleFlags;
