import { ReactElement } from "react";

import { Center, Text } from "@chakra-ui/react";

import { useRegistrationForm } from "contexts/RegistrationFormContext";
import {
  CardBody,
  CardFooter,
  CardHeader,
  ResponsiveCard,
} from "components/Card";
import LinkWithQuery from "components/LinkWithQuery";
import TermsOfUseForm from "forms/user-registration/TermsOfUseForm";
import { RedirectToPhoneNumberPath } from "routes/user-registration/RedirectToPhoneNumberPath";
import userRegistrationPaths from "routes/user-registration/userRegistrationPaths";
import { CARD_MAX_WIDTH, mobileStyleBreakpoint } from "utils/styleHelpers";

const CARD_MARGIN = mobileStyleBreakpoint(0, 10);
const CARD_WIDTH = mobileStyleBreakpoint("346px", "534px");

const TermsOfUseCard = (): ReactElement => {
  const { basicInfoComplete, phoneNumber } = useRegistrationForm();

  if (!(basicInfoComplete && phoneNumber)) {
    return <RedirectToPhoneNumberPath />;
  }

  return (
    <Center my={CARD_MARGIN}>
      <ResponsiveCard maxW={CARD_MAX_WIDTH} w={CARD_WIDTH}>
        <CardHeader>Terms of Use</CardHeader>
        <CardBody>
          <TermsOfUseForm />
        </CardBody>
        <CardFooter mt={8}>
          <Center w="100%">
            <LinkWithQuery
              to={`../${userRegistrationPaths.children.profilePicture}`}
            >
              <Text fontWeight="bold" color="brand.800">
                Back
              </Text>
            </LinkWithQuery>
          </Center>
        </CardFooter>
      </ResponsiveCard>
    </Center>
  );
};

export default TermsOfUseCard;
