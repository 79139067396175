import { ReactDatePickerCustomHeaderProps } from "react-datepicker";
import { HStack, IconButton } from "@chakra-ui/react";
import { IoChevronBackOutline, IoChevronForwardOutline } from "react-icons/io5";

import TextHeading from "components/TextHeading";

const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const CalendarHeader = ({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}: ReactDatePickerCustomHeaderProps) => (
  <HStack
    spacing={2}
    justify="space-around"
    pb={4}
    pt={1}
    px={2}
    bgColor="white"
  >
    <IconButton
      aria-label="leftButton"
      icon={<IoChevronBackOutline />}
      variant="datePicker"
      onClick={decreaseMonth}
      disabled={prevMonthButtonDisabled}
    />
    <TextHeading headingType="h5">{`${
      MONTHS[date.getMonth()]
    } ${date.getFullYear()}`}</TextHeading>
    <IconButton
      aria-label="rightButton"
      icon={<IoChevronForwardOutline />}
      variant="datePicker"
      onClick={increaseMonth}
      disabled={nextMonthButtonDisabled}
    />
  </HStack>
);

export default CalendarHeader;
