import { skipToken, useInfiniteQuery } from "@tanstack/react-query";

import { ManureYearFarmDto } from "api";
import { useManureYearService } from "contexts/ServiceProvider";
import { FarmsSortColumns } from "components/FarmListTable/listTableDataMapper";
import { getNextPage, PaginationMeta, QueryParams } from "services/apiHelpers";

export type GetManureYearFarmsResponse = {
  manureYearFarms: ManureYearFarmDto[];
  meta: PaginationMeta;
};

export const MANURE_YEAR_FARMS_QUERY_KEY = "list-manure-year-farms";

const useGetManureYearFarms = (
  manureYearId?: string,
  queryParams?: Omit<QueryParams<FarmsSortColumns>, "page">
) => {
  const manureYearService = useManureYearService();

  return useInfiniteQuery({
    queryKey: [MANURE_YEAR_FARMS_QUERY_KEY, manureYearId, queryParams],
    queryFn: manureYearId
      ? ({ pageParam = 0 }) =>
          manureYearService
            .getManureYearFarms(manureYearId, {
              ...queryParams,
              page: pageParam,
              size: 10,
            })
            .then(({ data, meta }) => ({
              manureYearFarms: data,
              meta,
            }))
      : skipToken,
    initialPageParam: 0,
    getNextPageParam: (lastPage) => getNextPage(lastPage),
  });
};

export default useGetManureYearFarms;
