import { Dispatch, SetStateAction } from "react";
import {
  Box,
  Divider,
  ScaleFade,
  Skeleton,
  Stack,
  Text,
} from "@chakra-ui/react";
import { PricingOptionResponse, MonthlyAverageListResponseDto } from "api";
import SummaryStatCard from "components/SummaryStatCard";
import { SilosStatIcon } from "components/SummaryStatCard/summaryStats/SummaryStatIcons";
import MonthlyAverageRowItem from "../MonthlyAverageRowItem";
import PrimaryTextItem from "../PrimaryTextItem";
import TotalPrice from "../TotalPrice";
import { formatNumberWithDecimals } from "utils/formatFunctions";

const PostHarvestAveragePricingModalContent = ({
  data,
  isLoading,
  setSelectedMonth,
}: {
  data: PricingOptionResponse | undefined;
  isLoading: boolean;
  setSelectedMonth: Dispatch<
    SetStateAction<MonthlyAverageListResponseDto | undefined>
  >;
}) => {
  const hasMonthlyAverages =
    data?.monthlyAverageContracts && data?.monthlyAverageContracts.length > 0;

  return (
    <ScaleFade initialScale={0.9} in={true}>
      <Skeleton isLoaded={!isLoading} w="100%" mb={8}>
        <SummaryStatCard
          // {...baseStyle}
          icon={<SilosStatIcon />}
          label="Total tons on average pricing"
          value={data?.totalTons ?? 0}
          formatter={formatNumberWithDecimals}
        />
      </Skeleton>
      <Stack spacing={4}>
        <PrimaryTextItem
          label="Agreed Percentage To Sell"
          value={data?.percentageToSell ?? 0}
          valueSuffix="%"
        />
        <Divider />
        <Stack spacing={4}>
          <Text fontSize="md" fontWeight="bold">
            Monthly averages
          </Text>
          <Skeleton isLoaded={!isLoading} pb={!hasMonthlyAverages ? 6 : 2}>
            {hasMonthlyAverages ? (
              <Stack spacing={2}>
                {data?.monthlyAverageContracts.map((contract, index) => (
                  <MonthlyAverageRowItem
                    key={contract.month}
                    index={index}
                    monthAverage={contract}
                    onClick={() => setSelectedMonth(contract)}
                  />
                ))}
              </Stack>
            ) : (
              <Text fontSize="md">
                Monthly averages are not yet available. Each month&apos;s
                average will display when Riverview enters the pricing for that
                month.
              </Text>
            )}
          </Skeleton>
          <Divider />
        </Stack>
        <Box mt={12}>
          <TotalPrice label="Current Total" value={data?.contractTotal ?? 0} />
        </Box>
      </Stack>
    </ScaleFade>
  );
};

export default PostHarvestAveragePricingModalContent;
