import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { PricingOptionResponse } from "api";
import { usePricingOptionService } from "contexts/ServiceProvider";
import { BaseApiError } from "services/apiHelpers";

export const GET_EXISTING_AVERAGE_PRICING_QUERY_KEY =
  "get-existing-average-pricing";

const useGetExistingAveragePricing = (cropYearId?: string, farmId?: string) => {
  const pricingOptionService = usePricingOptionService();

  return useQuery<PricingOptionResponse | undefined, BaseApiError>({
    queryKey: [GET_EXISTING_AVERAGE_PRICING_QUERY_KEY, { cropYearId, farmId }],
    queryFn: () =>
      cropYearId && farmId
        ? pricingOptionService
            .getExistingAveragePricing({
              cropYearId,
              farmId,
            })
            .catch((e) => {
              if ((e as AxiosError).response?.status === 404) {
                // No existing average pricing
                return undefined;
              }
              throw e;
            })
        : Promise.reject("Missing crop year ID or farm ID"),
    enabled: !!cropYearId && !!farmId,
  });
};

export default useGetExistingAveragePricing;
