import { ReactElement, useMemo } from "react";

import { HStack, Skeleton } from "@chakra-ui/react";

import BaseSummaryCards from "../BaseSummaryCards";
import SummaryStatCard, {
  percentOfTonsPriced,
  unpricedTons,
  harvestedTons,
} from "components/SummaryStatCard";
import useCropYearDetail from "hooks/crop-year/useCropYearDetails";
import useFarmCropYearSummary from "hooks/crop-year/useFarmCropYearSummary";
import { baseStyle } from "../SummaryCards";
import { cropYearIsInPreHarvest } from "utils/cropYearUtils";
import { formatNumberWithDecimals } from "utils/formatFunctions";

const FarmSummaryCards = ({
  cropYearId,
  farmId,
}: {
  cropYearId?: string;
  farmId?: string;
}): ReactElement => {
  const {
    data: farmCropYearSummaryData,
    isLoading: farmCropYearSummaryIsLoading,
  } = useFarmCropYearSummary(cropYearId, farmId);
  const { data: cropYearDetail, isLoading: cropYearDetailIsLoding } =
    useCropYearDetail(cropYearId);

  const isPreHarvest = useMemo(
    () => cropYearIsInPreHarvest(cropYearDetail?.timeOfYear),
    [cropYearDetail?.timeOfYear]
  );

  const dataIsLoading =
    !cropYearId || cropYearDetailIsLoding || farmCropYearSummaryIsLoading;

  return (
    <HStack
      w={"100%"}
      spacing={6}
      justifyContent={"space-between"}
      flexWrap={"nowrap"}
    >
      <BaseSummaryCards
        isLoading={dataIsLoading}
        _totalAcres={farmCropYearSummaryData?.totalAcres}
        _totalFields={farmCropYearSummaryData?.totalFields}
      />
      {isPreHarvest && (
        <Skeleton isLoaded={!dataIsLoading} w="100%">
          <SummaryStatCard
            {...baseStyle}
            {...percentOfTonsPriced}
            value={farmCropYearSummaryData?.percentageOfTonsPriced ?? 0}
          />
        </Skeleton>
      )}
      {!isPreHarvest && (
        <Skeleton isLoaded={!dataIsLoading} w="100%">
          <SummaryStatCard
            {...baseStyle}
            {...harvestedTons}
            formatter={formatNumberWithDecimals}
            value={farmCropYearSummaryData?.harvestedTons ?? 0}
          />
        </Skeleton>
      )}
      {!isPreHarvest && (
        <Skeleton isLoaded={!dataIsLoading} w="100%">
          <SummaryStatCard
            {...baseStyle}
            {...unpricedTons}
            formatter={() =>
              formatNumberWithDecimals(
                farmCropYearSummaryData?.unpricedTons ?? 0,
                8
              )
            }
            value={farmCropYearSummaryData?.unpricedTons ?? 0}
          />
        </Skeleton>
      )}
    </HStack>
  );
};

export default FarmSummaryCards;
