import useFarmDetail from "hooks/farm/farm-detail/useFarmDetail";
import { useCropYearContext } from "contexts/CropYearContext";

const useFarmsRegionDetails = (farmId: string | undefined) => {
  const { cropYearDetail, isLoading: cropYearIsLoading } = useCropYearContext();
  const { data: farm, isLoading } = useFarmDetail(farmId);

  // Get the farm's region and estimated yield from that region
  const regionDetails = cropYearDetail?.regions.find(
    (region) => region.regionId === farm?.region?.id
  );

  return { regionDetails, isLoading: isLoading || cropYearIsLoading === true };
};

export default useFarmsRegionDetails;
