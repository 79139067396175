import { useCallback, useMemo } from "react";
import { HStack, MenuItem, Text, useDisclosure } from "@chakra-ui/react";
import { CropYearDetailResponse } from "api";
import useSelectedFarmId from "hooks/useSelectedFarmId";
import { formatDate, parseDate } from "utils/dateUtils";
import AddAveragePricingModal from "../../AveragePricingModal";
import useGetExistingAveragePricing from "hooks/pricing-option/useGetExistingAveragePricing";

const AveragePricingMenuItem = ({
  cropYearDetail,
  openAveragePricingContract,
}: {
  cropYearDetail?: CropYearDetailResponse;
  openAveragePricingContract: (id: string) => void;
}) => {
  const isPostHarvest = cropYearDetail?.timeOfYear === "POST_HARVEST";
  const [farmId] = useSelectedFarmId();
  const { data: existingAveragePricing } = useGetExistingAveragePricing(
    cropYearDetail?.id,
    farmId
  );
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onClickAndContractExists = useCallback(() => {
    if (existingAveragePricing?.id) {
      openAveragePricingContract(existingAveragePricing.id);
    }
  }, [existingAveragePricing?.id, openAveragePricingContract]);

  const convertAveragePricingLockInStringToDate = useMemo(() => {
    return cropYearDetail
      ? parseDate(cropYearDetail?.averagePricingLockInDate)
      : undefined;
  }, [cropYearDetail]);

  const isAveragePricingUnavailable = useMemo(() => {
    return (
      isPostHarvest ||
      (convertAveragePricingLockInStringToDate &&
        convertAveragePricingLockInStringToDate <= new Date())
    );
  }, [convertAveragePricingLockInStringToDate, isPostHarvest]);

  const formattedAveragePricingFinalDate =
    convertAveragePricingLockInStringToDate
      ? formatDate(convertAveragePricingLockInStringToDate, "MM/d/yy")
      : "";

  return (
    <MenuItem
      isDisabled={isAveragePricingUnavailable}
      onClick={existingAveragePricing ? onClickAndContractExists : onOpen}
      aria-label="average-pricing-menu-item"
    >
      <HStack justifyContent="space-between" w="100%">
        <HStack spacing={4}>
          <Text fontWeight="normal">Average Pricing</Text>

          <AddAveragePricingModal isOpen={isOpen} onClose={onClose} />

          <Text fontSize="xs">
            Available until {formattedAveragePricingFinalDate}
          </Text>
        </HStack>
      </HStack>
    </MenuItem>
  );
};

export default AveragePricingMenuItem;
