import { Fragment, PropsWithChildren } from "react";
import { VStack, UnorderedList, ListItem } from "@chakra-ui/react";
import TextHeading from "components/TextHeading";

export type ListItemType = {
  listItems?: Array<{
    text: string;
    listItems?: Array<{ text: string }>;
  }>;
  text: string;
};

export type GuideListItemType = {
  headingText: string;
  listItems: Array<ListItemType>;
};

const StyledUnorderedList = ({ children }: PropsWithChildren) => {
  return <UnorderedList pl={6}>{children}</UnorderedList>;
};

const ErrorGuideListSection = ({
  children,
  headingText,
}: { headingText: string } & PropsWithChildren) => {
  return (
    <VStack align="left">
      <TextHeading headingType="h5">{headingText}</TextHeading>
      {children}
    </VStack>
  );
};

const ErrorGuideList = ({
  errorGuideListData,
}: {
  errorGuideListData: GuideListItemType[];
}) => {
  return (
    <>
      {errorGuideListData.map(({ headingText = "", listItems = [] }) => (
        <ErrorGuideListSection headingText={headingText} key={headingText}>
          <StyledUnorderedList>
            {listItems.map(({ text = "", listItems = [] }, index) => {
              return (
                <Fragment key={`first-set-list-items-${index}`}>
                  <ListItem>{text}</ListItem>
                  {listItems?.map(
                    ({ text = "", listItems = [] }, index: number) => (
                      <StyledUnorderedList
                        key={`second-set-list-items-${index}`}
                      >
                        <ListItem>{text}</ListItem>
                        {listItems?.map(({ text }, index) => {
                          return (
                            <StyledUnorderedList
                              key={`third-set-list-items-${index}`}
                            >
                              <ListItem>{text}</ListItem>
                            </StyledUnorderedList>
                          );
                        })}
                      </StyledUnorderedList>
                    )
                  )}
                </Fragment>
              );
            })}
          </StyledUnorderedList>
        </ErrorGuideListSection>
      ))}
    </>
  );
};

export default ErrorGuideList;
