import { NavigateOptions, useSearchParams } from "react-router-dom";
import { useCallback } from "react";

type SetQueryStringValueFn = (value: string, options?: NavigateOptions) => void;

const useSearchParam = (
  queryKey: string
): [string | undefined, SetQueryStringValueFn] => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setSearchParam: SetQueryStringValueFn = useCallback(
    (value, options) => {
      const nextSearchParams = new URLSearchParams(searchParams);
      nextSearchParams.set(queryKey, value);
      setSearchParams(nextSearchParams, options);
    },
    [queryKey, searchParams, setSearchParams]
  );

  return [searchParams.get(queryKey) ?? undefined, setSearchParam];
};

export default useSearchParam;
