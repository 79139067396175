import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useManureYearService } from "contexts/ServiceProvider";
import { GET_MANURE_YEAR_ADDABLE_FARMS_QUERY_KEY } from "hooks/api/manure-year/useGetAddableFarmsForManureYear";
import { MANURE_YEAR_FARMS_QUERY_KEY } from "hooks/api/manure-year/useGetManureYearFarms";

const ASSIGN_FARM_TO_MANURE_YEAR_MUTATION_KEY = "assign-farm-to-manure-year";

const useAssignFarmToManureYear = (manureYearId?: string) => {
  const manureYearService = useManureYearService();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [ASSIGN_FARM_TO_MANURE_YEAR_MUTATION_KEY, { manureYearId }],
    mutationFn: (farmIds: Array<string>) =>
      manureYearId && farmIds.length > 0
        ? manureYearService.assignFarmToManureYear({
            id: manureYearId,
            requestBody: farmIds,
          })
        : Promise.reject(new Error("Must have manureYearId and farmIds")),
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: [MANURE_YEAR_FARMS_QUERY_KEY],
        }),
        queryClient.invalidateQueries({
          queryKey: [GET_MANURE_YEAR_ADDABLE_FARMS_QUERY_KEY],
        }),
      ]),
  });
};

export default useAssignFarmToManureYear;
