import { ReactElement } from "react";
import { useForgotPasswordForm } from "contexts/ForgotPasswordFormContext";
import CheckEmailCard from "components/CheckEmailCard";
import EnterEmailCard from "components/EnterEmailCard";

const ForgotPasswordCard = (): ReactElement => {
  const { email } = useForgotPasswordForm();

  return email ? <CheckEmailCard /> : <EnterEmailCard />;
};

export default ForgotPasswordCard;
