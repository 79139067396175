import { Center, CenterProps, Text } from "@chakra-ui/react";
import { ReactElement } from "react";
import { Link } from "react-router-dom";
import authPaths from "routes/auth/authPaths";
import { mobileStyleBreakpoint } from "utils/styleHelpers";

const BACK_TO_LOGIN_PADDING_TOP = mobileStyleBreakpoint(4, 8);

const BackToLogin = (props: CenterProps): ReactElement => (
  <Center pt={BACK_TO_LOGIN_PADDING_TOP} w="100%" {...props}>
    <Link to={`/${authPaths.basePath}`}>
      <Text fontWeight="bold" color="brand.800">
        Back to Login
      </Text>
    </Link>
  </Center>
);

export default BackToLogin;
