/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosRequestConfig,
} from "axios";
import { Configuration } from "../configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "../base";
// @ts-ignore
import { CreateUserRequest } from "../models";
// @ts-ignore
import { DataListMinimumUserDto } from "../models";
// @ts-ignore
import { DataRoleListResponse } from "../models";
// @ts-ignore
import { DataUserInviteResponse } from "../models";
// @ts-ignore
import { DataUserResponse } from "../models";
// @ts-ignore
import { InviteUserRequest } from "../models";
// @ts-ignore
import { PageResponseUserResponse } from "../models";
// @ts-ignore
import { ResendUserInvitesRequest } from "../models";
// @ts-ignore
import { UpdateUserAccountDetailsRequest } from "../models";
// @ts-ignore
import { UpdateUserRequest } from "../models";
// @ts-ignore
import { UpdateUserStatusRequest } from "../models";
/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {string} [farmId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    assignableRoles: async (
      farmId?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/user/assignable-roles`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (farmId !== undefined && farmId !== null) {
        localVarHeaderParameter["farm-id"] = String(farmId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {string} [farmId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    assignableRolesForUser: async (
      id: string,
      farmId?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("assignableRolesForUser", "id", id);
      const localVarPath = `/user/{id}/assignable-roles`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (farmId !== undefined && farmId !== null) {
        localVarHeaderParameter["farm-id"] = String(farmId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CreateUserRequest} createUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUser: async (
      createUserRequest: CreateUserRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createUserRequest' is not null or undefined
      assertParamExists("createUser", "createUserRequest", createUserRequest);
      const localVarPath = `/user`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createUserRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {string} [farmId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFarmAdminUsers: async (
      id: string,
      farmId?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getFarmAdminUsers", "id", id);
      const localVarPath = `/users/farm/{id}/admin-users`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (farmId !== undefined && farmId !== null) {
        localVarHeaderParameter["farm-id"] = String(farmId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {string} [farmId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUser: async (
      id: string,
      farmId?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getUser", "id", id);
      const localVarPath = `/user/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (farmId !== undefined && farmId !== null) {
        localVarHeaderParameter["farm-id"] = String(farmId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserInviteDetails: async (
      token: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      assertParamExists("getUserInviteDetails", "token", token);
      const localVarPath = `/user/invite`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (token !== undefined) {
        localVarQueryParameter["token"] = token;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [farmId]
     * @param {string} [search]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsers: async (
      farmId?: string,
      search?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (search !== undefined) {
        localVarQueryParameter["search"] = search;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sort) {
        localVarQueryParameter["sort"] = sort;
      }

      if (farmId !== undefined && farmId !== null) {
        localVarHeaderParameter["farm-id"] = String(farmId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {InviteUserRequest} inviteUserRequest
     * @param {string} [farmId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    invite: async (
      inviteUserRequest: InviteUserRequest,
      farmId?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'inviteUserRequest' is not null or undefined
      assertParamExists("invite", "inviteUserRequest", inviteUserRequest);
      const localVarPath = `/user/invite`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (farmId !== undefined && farmId !== null) {
        localVarHeaderParameter["farm-id"] = String(farmId);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        inviteUserRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ResendUserInvitesRequest} resendUserInvitesRequest
     * @param {string} [farmId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resendInvites: async (
      resendUserInvitesRequest: ResendUserInvitesRequest,
      farmId?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'resendUserInvitesRequest' is not null or undefined
      assertParamExists(
        "resendInvites",
        "resendUserInvitesRequest",
        resendUserInvitesRequest
      );
      const localVarPath = `/user/invite/resend`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (farmId !== undefined && farmId !== null) {
        localVarHeaderParameter["farm-id"] = String(farmId);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        resendUserInvitesRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UpdateUserAccountDetailsRequest} updateUserAccountDetailsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccountSettings: async (
      updateUserAccountDetailsRequest: UpdateUserAccountDetailsRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateUserAccountDetailsRequest' is not null or undefined
      assertParamExists(
        "updateAccountSettings",
        "updateUserAccountDetailsRequest",
        updateUserAccountDetailsRequest
      );
      const localVarPath = `/user/account-settings`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateUserAccountDetailsRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {UpdateUserRequest} updateUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUser: async (
      id: string,
      updateUserRequest: UpdateUserRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("updateUser", "id", id);
      // verify required parameter 'updateUserRequest' is not null or undefined
      assertParamExists("updateUser", "updateUserRequest", updateUserRequest);
      const localVarPath = `/user/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateUserRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UpdateUserStatusRequest} updateUserStatusRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserStatus: async (
      updateUserStatusRequest: UpdateUserStatusRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateUserStatusRequest' is not null or undefined
      assertParamExists(
        "updateUserStatus",
        "updateUserStatusRequest",
        updateUserStatusRequest
      );
      const localVarPath = `/user/status`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateUserStatusRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} [farmId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async assignableRoles(
      farmId?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataRoleListResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.assignableRoles(
        farmId,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {string} [farmId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async assignableRolesForUser(
      id: string,
      farmId?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataRoleListResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.assignableRolesForUser(
          id,
          farmId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {CreateUserRequest} createUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createUser(
      createUserRequest: CreateUserRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataUserResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(
        createUserRequest,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {string} [farmId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFarmAdminUsers(
      id: string,
      farmId?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataListMinimumUserDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getFarmAdminUsers(id, farmId, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {string} [farmId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUser(
      id: string,
      farmId?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataUserResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(
        id,
        farmId,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserInviteDetails(
      token: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataUserInviteResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getUserInviteDetails(token, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} [farmId]
     * @param {string} [search]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUsers(
      farmId?: string,
      search?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PageResponseUserResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(
        farmId,
        search,
        page,
        size,
        sort,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {InviteUserRequest} inviteUserRequest
     * @param {string} [farmId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async invite(
      inviteUserRequest: InviteUserRequest,
      farmId?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.invite(
        inviteUserRequest,
        farmId,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {ResendUserInvitesRequest} resendUserInvitesRequest
     * @param {string} [farmId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async resendInvites(
      resendUserInvitesRequest: ResendUserInvitesRequest,
      farmId?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.resendInvites(
        resendUserInvitesRequest,
        farmId,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {UpdateUserAccountDetailsRequest} updateUserAccountDetailsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateAccountSettings(
      updateUserAccountDetailsRequest: UpdateUserAccountDetailsRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataUserResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateAccountSettings(
          updateUserAccountDetailsRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {UpdateUserRequest} updateUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateUser(
      id: string,
      updateUserRequest: UpdateUserRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataUserResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(
        id,
        updateUserRequest,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {UpdateUserStatusRequest} updateUserStatusRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateUserStatus(
      updateUserStatusRequest: UpdateUserStatusRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateUserStatus(
          updateUserStatusRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = UserApiFp(configuration);
  return {
    /**
     *
     * @param {string} [farmId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    assignableRoles(
      farmId?: string,
      options?: any
    ): AxiosPromise<DataRoleListResponse> {
      return localVarFp
        .assignableRoles(farmId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {string} [farmId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    assignableRolesForUser(
      id: string,
      farmId?: string,
      options?: any
    ): AxiosPromise<DataRoleListResponse> {
      return localVarFp
        .assignableRolesForUser(id, farmId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {CreateUserRequest} createUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUser(
      createUserRequest: CreateUserRequest,
      options?: any
    ): AxiosPromise<DataUserResponse> {
      return localVarFp
        .createUser(createUserRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {string} [farmId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFarmAdminUsers(
      id: string,
      farmId?: string,
      options?: any
    ): AxiosPromise<DataListMinimumUserDto> {
      return localVarFp
        .getFarmAdminUsers(id, farmId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {string} [farmId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUser(
      id: string,
      farmId?: string,
      options?: any
    ): AxiosPromise<DataUserResponse> {
      return localVarFp
        .getUser(id, farmId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserInviteDetails(
      token: string,
      options?: any
    ): AxiosPromise<DataUserInviteResponse> {
      return localVarFp
        .getUserInviteDetails(token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [farmId]
     * @param {string} [search]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsers(
      farmId?: string,
      search?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any
    ): AxiosPromise<PageResponseUserResponse> {
      return localVarFp
        .getUsers(farmId, search, page, size, sort, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {InviteUserRequest} inviteUserRequest
     * @param {string} [farmId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    invite(
      inviteUserRequest: InviteUserRequest,
      farmId?: string,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .invite(inviteUserRequest, farmId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {ResendUserInvitesRequest} resendUserInvitesRequest
     * @param {string} [farmId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resendInvites(
      resendUserInvitesRequest: ResendUserInvitesRequest,
      farmId?: string,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .resendInvites(resendUserInvitesRequest, farmId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {UpdateUserAccountDetailsRequest} updateUserAccountDetailsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccountSettings(
      updateUserAccountDetailsRequest: UpdateUserAccountDetailsRequest,
      options?: any
    ): AxiosPromise<DataUserResponse> {
      return localVarFp
        .updateAccountSettings(updateUserAccountDetailsRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {UpdateUserRequest} updateUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUser(
      id: string,
      updateUserRequest: UpdateUserRequest,
      options?: any
    ): AxiosPromise<DataUserResponse> {
      return localVarFp
        .updateUser(id, updateUserRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {UpdateUserStatusRequest} updateUserStatusRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserStatus(
      updateUserStatusRequest: UpdateUserStatusRequest,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .updateUserStatus(updateUserStatusRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for assignableRoles operation in UserApi.
 * @export
 * @interface UserApiAssignableRolesRequest
 */
export interface UserApiAssignableRolesRequest {
  /**
   *
   * @type {string}
   * @memberof UserApiAssignableRoles
   */
  readonly farmId?: string;
}

/**
 * Request parameters for assignableRolesForUser operation in UserApi.
 * @export
 * @interface UserApiAssignableRolesForUserRequest
 */
export interface UserApiAssignableRolesForUserRequest {
  /**
   *
   * @type {string}
   * @memberof UserApiAssignableRolesForUser
   */
  readonly id: string;

  /**
   *
   * @type {string}
   * @memberof UserApiAssignableRolesForUser
   */
  readonly farmId?: string;
}

/**
 * Request parameters for createUser operation in UserApi.
 * @export
 * @interface UserApiCreateUserRequest
 */
export interface UserApiCreateUserRequest {
  /**
   *
   * @type {CreateUserRequest}
   * @memberof UserApiCreateUser
   */
  readonly createUserRequest: CreateUserRequest;
}

/**
 * Request parameters for getFarmAdminUsers operation in UserApi.
 * @export
 * @interface UserApiGetFarmAdminUsersRequest
 */
export interface UserApiGetFarmAdminUsersRequest {
  /**
   *
   * @type {string}
   * @memberof UserApiGetFarmAdminUsers
   */
  readonly id: string;

  /**
   *
   * @type {string}
   * @memberof UserApiGetFarmAdminUsers
   */
  readonly farmId?: string;
}

/**
 * Request parameters for getUser operation in UserApi.
 * @export
 * @interface UserApiGetUserRequest
 */
export interface UserApiGetUserRequest {
  /**
   *
   * @type {string}
   * @memberof UserApiGetUser
   */
  readonly id: string;

  /**
   *
   * @type {string}
   * @memberof UserApiGetUser
   */
  readonly farmId?: string;
}

/**
 * Request parameters for getUserInviteDetails operation in UserApi.
 * @export
 * @interface UserApiGetUserInviteDetailsRequest
 */
export interface UserApiGetUserInviteDetailsRequest {
  /**
   *
   * @type {string}
   * @memberof UserApiGetUserInviteDetails
   */
  readonly token: string;
}

/**
 * Request parameters for getUsers operation in UserApi.
 * @export
 * @interface UserApiGetUsersRequest
 */
export interface UserApiGetUsersRequest {
  /**
   *
   * @type {string}
   * @memberof UserApiGetUsers
   */
  readonly farmId?: string;

  /**
   *
   * @type {string}
   * @memberof UserApiGetUsers
   */
  readonly search?: string;

  /**
   * Zero-based page index (0..N)
   * @type {number}
   * @memberof UserApiGetUsers
   */
  readonly page?: number;

  /**
   * The size of the page to be returned
   * @type {number}
   * @memberof UserApiGetUsers
   */
  readonly size?: number;

  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @type {Array<string>}
   * @memberof UserApiGetUsers
   */
  readonly sort?: Array<string>;
}

/**
 * Request parameters for invite operation in UserApi.
 * @export
 * @interface UserApiInviteRequest
 */
export interface UserApiInviteRequest {
  /**
   *
   * @type {InviteUserRequest}
   * @memberof UserApiInvite
   */
  readonly inviteUserRequest: InviteUserRequest;

  /**
   *
   * @type {string}
   * @memberof UserApiInvite
   */
  readonly farmId?: string;
}

/**
 * Request parameters for resendInvites operation in UserApi.
 * @export
 * @interface UserApiResendInvitesRequest
 */
export interface UserApiResendInvitesRequest {
  /**
   *
   * @type {ResendUserInvitesRequest}
   * @memberof UserApiResendInvites
   */
  readonly resendUserInvitesRequest: ResendUserInvitesRequest;

  /**
   *
   * @type {string}
   * @memberof UserApiResendInvites
   */
  readonly farmId?: string;
}

/**
 * Request parameters for updateAccountSettings operation in UserApi.
 * @export
 * @interface UserApiUpdateAccountSettingsRequest
 */
export interface UserApiUpdateAccountSettingsRequest {
  /**
   *
   * @type {UpdateUserAccountDetailsRequest}
   * @memberof UserApiUpdateAccountSettings
   */
  readonly updateUserAccountDetailsRequest: UpdateUserAccountDetailsRequest;
}

/**
 * Request parameters for updateUser operation in UserApi.
 * @export
 * @interface UserApiUpdateUserRequest
 */
export interface UserApiUpdateUserRequest {
  /**
   *
   * @type {string}
   * @memberof UserApiUpdateUser
   */
  readonly id: string;

  /**
   *
   * @type {UpdateUserRequest}
   * @memberof UserApiUpdateUser
   */
  readonly updateUserRequest: UpdateUserRequest;
}

/**
 * Request parameters for updateUserStatus operation in UserApi.
 * @export
 * @interface UserApiUpdateUserStatusRequest
 */
export interface UserApiUpdateUserStatusRequest {
  /**
   *
   * @type {UpdateUserStatusRequest}
   * @memberof UserApiUpdateUserStatus
   */
  readonly updateUserStatusRequest: UpdateUserStatusRequest;
}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
  /**
   *
   * @param {UserApiAssignableRolesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public assignableRoles(
    requestParameters: UserApiAssignableRolesRequest = {},
    options?: AxiosRequestConfig
  ) {
    return UserApiFp(this.configuration)
      .assignableRoles(requestParameters.farmId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UserApiAssignableRolesForUserRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public assignableRolesForUser(
    requestParameters: UserApiAssignableRolesForUserRequest,
    options?: AxiosRequestConfig
  ) {
    return UserApiFp(this.configuration)
      .assignableRolesForUser(
        requestParameters.id,
        requestParameters.farmId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UserApiCreateUserRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public createUser(
    requestParameters: UserApiCreateUserRequest,
    options?: AxiosRequestConfig
  ) {
    return UserApiFp(this.configuration)
      .createUser(requestParameters.createUserRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UserApiGetFarmAdminUsersRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public getFarmAdminUsers(
    requestParameters: UserApiGetFarmAdminUsersRequest,
    options?: AxiosRequestConfig
  ) {
    return UserApiFp(this.configuration)
      .getFarmAdminUsers(
        requestParameters.id,
        requestParameters.farmId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UserApiGetUserRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public getUser(
    requestParameters: UserApiGetUserRequest,
    options?: AxiosRequestConfig
  ) {
    return UserApiFp(this.configuration)
      .getUser(requestParameters.id, requestParameters.farmId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UserApiGetUserInviteDetailsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public getUserInviteDetails(
    requestParameters: UserApiGetUserInviteDetailsRequest,
    options?: AxiosRequestConfig
  ) {
    return UserApiFp(this.configuration)
      .getUserInviteDetails(requestParameters.token, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UserApiGetUsersRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public getUsers(
    requestParameters: UserApiGetUsersRequest = {},
    options?: AxiosRequestConfig
  ) {
    return UserApiFp(this.configuration)
      .getUsers(
        requestParameters.farmId,
        requestParameters.search,
        requestParameters.page,
        requestParameters.size,
        requestParameters.sort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UserApiInviteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public invite(
    requestParameters: UserApiInviteRequest,
    options?: AxiosRequestConfig
  ) {
    return UserApiFp(this.configuration)
      .invite(
        requestParameters.inviteUserRequest,
        requestParameters.farmId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UserApiResendInvitesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public resendInvites(
    requestParameters: UserApiResendInvitesRequest,
    options?: AxiosRequestConfig
  ) {
    return UserApiFp(this.configuration)
      .resendInvites(
        requestParameters.resendUserInvitesRequest,
        requestParameters.farmId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UserApiUpdateAccountSettingsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public updateAccountSettings(
    requestParameters: UserApiUpdateAccountSettingsRequest,
    options?: AxiosRequestConfig
  ) {
    return UserApiFp(this.configuration)
      .updateAccountSettings(
        requestParameters.updateUserAccountDetailsRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UserApiUpdateUserRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public updateUser(
    requestParameters: UserApiUpdateUserRequest,
    options?: AxiosRequestConfig
  ) {
    return UserApiFp(this.configuration)
      .updateUser(
        requestParameters.id,
        requestParameters.updateUserRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UserApiUpdateUserStatusRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public updateUserStatus(
    requestParameters: UserApiUpdateUserStatusRequest,
    options?: AxiosRequestConfig
  ) {
    return UserApiFp(this.configuration)
      .updateUserStatus(requestParameters.updateUserStatusRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
