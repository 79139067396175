import { useMutation } from "@tanstack/react-query";

import { usePricingOptionService } from "contexts/ServiceProvider";
import useDashboardRefetchQueries from "hooks/crop-year/useDashboardRefetchQueries";

const DELETE_AVERAGE_PRICING_CONTRACT_MUTATION_KEY =
  "delete-average-pricing-contract";

const useDeleteAveragePricingContract = ({
  cropYearId,
  farmId,
}: {
  cropYearId?: string;
  farmId?: string;
}) => {
  const pricingOptionService = usePricingOptionService();
  const { refetch } = useDashboardRefetchQueries();

  return useMutation({
    mutationKey: [DELETE_AVERAGE_PRICING_CONTRACT_MUTATION_KEY],
    mutationFn: ({
      averagePricingContractId,
    }: {
      averagePricingContractId: string;
    }) => {
      if (!cropYearId) {
        throw new Error("Missing cropYearId");
      }
      if (!farmId) {
        throw new Error("Missing farmId");
      }

      return pricingOptionService.deleteAveragePricingContract({
        cropYearId,
        farmId,
        averagePricingContractId,
      });
    },
    onSuccess: () => refetch(),
  });
};

export default useDeleteAveragePricingContract;
