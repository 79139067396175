import { ReactElement } from "react";
import { Navigate, Location } from "react-router-dom";

import authPaths from "routes/auth/authPaths";
import { getAuthFailure } from "utils/authLocalStorage";

export const LOGIN_REDIRECT_QUERY_STRING_KEY = "redirectTo";

const RedirectToLogin = ({
  redirectTo,
}: {
  redirectTo?: Location;
}): ReactElement => {
  const authFailure = getAuthFailure();
  const queryString = new URLSearchParams(redirectTo?.search ?? "");
  redirectTo &&
    queryString.set(LOGIN_REDIRECT_QUERY_STRING_KEY, redirectTo.pathname);
  const queryParams = authFailure === undefined ? `?${queryString}` : "";

  return (
    <Navigate
      to={`/${authPaths.basePath}${queryParams}`}
      state={redirectTo?.state}
    />
  );
};

export default RedirectToLogin;
