import { Text } from "@chakra-ui/react";

import { MonthlyAverageContractMinResponseDto, PricingOptionDto } from "api";
import { ColumnProps, TableData } from "components/SortableTable";
import StatusColumn from "components/table-columns/StatusColumn";
import { FarmCropYearPricingOptionsSortColumns } from "services/PricingOptionService";
import {
  isAveragePricingContract,
  isDelayedPricingContract,
} from "utils/contractType";
import { formatDate, parseDate } from "utils/dateUtils";
import { formatNumberWithDecimals, formatUSD } from "utils/formatFunctions";
import { getStatusObjectForPricingOption } from "utils/getStatusObjectForPricingOption";

export type ColumnList = ColumnProps<FarmCropYearPricingOptionsSortColumns>[];

export const priceColumn = ({
  price,
  monthlyPrices,
  type,
  unitOfMeasure,
}: {
  price?: number;
  monthlyPrices: MonthlyAverageContractMinResponseDto[];
  type: string;
  unitOfMeasure?: string;
}) => {
  const averagePricingContractPrice = monthlyPrices.length ? "Multiple" : "";
  const forwardContractPrice = price
    ? `${formatUSD(price)}/${unitOfMeasure}`
    : "";

  return isAveragePricingContract(type)
    ? averagePricingContractPrice
    : forwardContractPrice;
};

export const pricingListTableColumns = ({
  data,
  isFarmAdmin = false,
  isPreHarvest = true,
}: {
  data: PricingOptionDto[];
  isFarmAdmin: boolean;
  isPreHarvest?: boolean;
}): TableData[] =>
  data.map(
    ({
      id,
      type,
      strictType,
      status,
      percentage,
      basePrice,
      cashPrice,
      dateOfContract,
      deferred,
      tons,
      monthlyAverageContractPrices,
    }) => {
      const baseColumns = {
        id,
        type: <Text fontWeight="bold">{type}</Text>,
        cashPrice: priceColumn({
          price: cashPrice,
          monthlyPrices: monthlyAverageContractPrices,
          type,
          unitOfMeasure: "bu",
        }),
        status: (
          <StatusColumn
            status={getStatusObjectForPricingOption(
              status.status,
              isFarmAdmin,
              strictType
            )}
          />
        ),
        basePrice: priceColumn({
          price: basePrice,
          monthlyPrices: monthlyAverageContractPrices,
          type,
          unitOfMeasure: "ton",
        }),
        dateOfContract: dateOfContract
          ? formatDate(parseDate(dateOfContract))
          : "",
        deferred:
          deferred !== "No" ? formatDate(parseDate(deferred)) : deferred,
      };

      if (isPreHarvest) {
        return {
          ...baseColumns,
          percentage: (
            <Text fontWeight="bold">{percentage ? `${percentage}%` : ""}</Text>
          ),
        };
      } else {
        return {
          ...baseColumns,
          tons: (
            <Text>
              {isDelayedPricingContract(type)
                ? formatNumberWithDecimals(tons ?? 0, 8)
                : formatNumberWithDecimals(tons ?? 0)}
            </Text>
          ),
        };
      }
    }
  );

export const individualFarmBaseColumns: ColumnList = [
  { heading: "Cash Price", id: "cashPrice", sortKey: "cashPrice" },
  {
    heading: "Date of Contract",
    id: "dateOfContract",
    sortKey: "dateOfContract",
  },
];

const baseDesktopColumns: ColumnList = [
  { heading: "Type", id: "type", sortKey: "type" },
  { heading: "Base Price", id: "basePrice", sortKey: "basePrice" },
  {
    heading: "Deferred",
    id: "deferred",
    sortKey: "defermentDate",
  },
  {
    heading: "Status",
    id: "status",
    sortKey: "status",
  },
];

const preHarvestIndividualFarmBaseDesktopColumns: ColumnList = [
  baseDesktopColumns[0],
  { heading: "Percentage", id: "percentage", sortKey: "percentageToSell" },
  baseDesktopColumns[1],
  baseDesktopColumns[2],
  baseDesktopColumns[3],
];

const postHarvestIndividualFarmBaseDesktopColumns: ColumnList = [
  baseDesktopColumns[0],
  { heading: "Tons", id: "tons", sortKey: "tons" },
  baseDesktopColumns[1],
  baseDesktopColumns[2],
  baseDesktopColumns[3],
];

export const preHarvestIndividualFarmColumns: ColumnList = [
  preHarvestIndividualFarmBaseDesktopColumns[0],
  preHarvestIndividualFarmBaseDesktopColumns[1],
  individualFarmBaseColumns[0],
  preHarvestIndividualFarmBaseDesktopColumns[2],
  individualFarmBaseColumns[1],
  preHarvestIndividualFarmBaseDesktopColumns[3],
  preHarvestIndividualFarmBaseDesktopColumns[4],
];

export const postHarvestIndividualFarmColumns: ColumnList = [
  postHarvestIndividualFarmBaseDesktopColumns[0],
  postHarvestIndividualFarmBaseDesktopColumns[1],
  individualFarmBaseColumns[0],
  postHarvestIndividualFarmBaseDesktopColumns[2],
  postHarvestIndividualFarmBaseDesktopColumns[3],
  individualFarmBaseColumns[1],
  postHarvestIndividualFarmBaseDesktopColumns[4],
];
