import { Button } from "@chakra-ui/react";

const VoidContractButton = ({
  isEmployeeAdmin,
  voidedContract,
  onOpen,
}: {
  isEmployeeAdmin: boolean;
  voidedContract: boolean;
  onOpen: () => void;
}) => {
  if (isEmployeeAdmin && !voidedContract) {
    return (
      <Button
        color="errorRed"
        colorScheme="error"
        variant="outline"
        onClick={onOpen}
      >
        VOID CONTRACT
      </Button>
    );
  }

  return null;
};

export default VoidContractButton;
