import { SystemStyleObjectRecord } from "@chakra-ui/react";

const baseHeadingStyle = {
  fontWeight: "bold",
  letterSpacing: "tightest",
};

export const desktopHeadings: SystemStyleObjectRecord = {
  h1: {
    ...baseHeadingStyle,
    fontSize: "7xl",
    lineHeight: 20,
  },
  h2: {
    ...baseHeadingStyle,
    fontSize: "5xl",
    lineHeight: 14,
  },
  h3: {
    ...baseHeadingStyle,
    fontSize: "3xl",
    lineHeight: 10,
  },
  h4: {
    ...baseHeadingStyle,
    fontSize: "2xl",
    lineHeight: 8,
  },
  h5: {
    ...baseHeadingStyle,
    fontSize: "xl",
    lineHeight: 7,
  },
  h6: {
    ...baseHeadingStyle,
    fontSize: "lg",
    lineHeight: "6.5",
  },
};

const mobileHeadings: SystemStyleObjectRecord = {
  mobileH1: desktopHeadings.h2,
  mobileH2: desktopHeadings.h3,
  mobileH3: desktopHeadings.h4,
  mobileH4: desktopHeadings.h5,
  mobileH5: desktopHeadings.h6,
  mobileH6: {
    ...baseHeadingStyle,
    fontSize: "md",
    lineHeight: 5,
  },
};

const small = {
  fontSize: "sm",
  lineHeight: "5.25",
};

const textStyles: SystemStyleObjectRecord = {
  ...mobileHeadings,
  ...desktopHeadings,
  subtitle: desktopHeadings.h5,
  body: {
    fontSize: "md",
    lineHeight: 6,
  },
  small,
  caption: small,
  label: {
    fontSize: "xs",
    lineHeight: "5.25",
  },
  button: {
    fontSize: "md",
    fontWeight: "semibold",
    lineHeight: 4,
    letterSpacing: "tighter",
  },
  link: {
    fontSize: "md",
    lineHeight: 6,
    textDecoration: "underline",
    color: "link",
  },
};

export default textStyles;
