import { Text, Tooltip } from "@chakra-ui/react";

import { HaylageYearFarmResponse, ManureYearFarmDto } from "api";
import { ColumnProps, TableData } from "components/SortableTable";
import { NameWithPhoneColumn } from "components/table-columns";
import {
  Sort,
  DEFAULT_SORT_DIRECTION,
  ColumnsWithSecondarySort,
} from "hooks/useSort";

export type FarmsSortColumns =
  | "farm.doingBusinessAs"
  | "farm.externalEntityId"
  | "farm.region.name";

export const farmListDefaultSort: Sort<FarmsSortColumns> = {
  key: "farm.region.name",
  direction: DEFAULT_SORT_DIRECTION,
};

export const farmListColumnsWithSecondarySort: ColumnsWithSecondarySort<FarmsSortColumns> =
  {
    "farm.region.name": {
      key: "farm.doingBusinessAs",
      direction: DEFAULT_SORT_DIRECTION,
    },
  };

export const listTableDataMapper = (
  data: HaylageYearFarmResponse[] | ManureYearFarmDto[]
): TableData[] =>
  data.map(
    ({
      id,
      farm: {
        id: farmId,
        doingBusinessAs: farmName,
        phoneNumber: farmPhoneNumber,
      },
      region,
      entityId,
    }) => ({
      id,
      farmId,
      farm: (
        <NameWithPhoneColumn
          name={farmName}
          phoneNumber={farmPhoneNumber}
          showAvatar={false}
        />
      ),
      entityId: (
        <Tooltip hasArrow label={entityId ?? ""}>
          <Text fontWeight="bold" variant="truncated">
            {entityId}
          </Text>
        </Tooltip>
      ),
      region: (
        <Tooltip hasArrow label={region?.name}>
          <Text variant={"truncated"}>{region?.name ?? ""}</Text>
        </Tooltip>
      ),
    })
  );

type ColumnList = ColumnProps<FarmsSortColumns>[];

export const baseListColumns: ColumnList = [
  { heading: "Farm", id: "farm", sortKey: "farm.doingBusinessAs" },
  { heading: "Region", id: "region", sortKey: "farm.region.name" },
];

export const desktopOnlyListColumns: ColumnList = [
  { heading: "Entity Id", id: "entityId", sortKey: "farm.externalEntityId" },
];

export const desktopListColumns: ColumnList = [
  baseListColumns[0],
  desktopOnlyListColumns[0],
  baseListColumns[1],
];
