import { PricingOptionStatusDtoStatusEnum, PricingOptionType } from "api";

const { Pending, Void, Active } = PricingOptionStatusDtoStatusEnum;

export const getStatusObjectForPricingOption = (
  status: PricingOptionStatusDtoStatusEnum,
  isFarmer: boolean,
  type: PricingOptionType
) => {
  switch (status) {
    case Pending:
      if (type === PricingOptionType.ForwardContract) {
        return isFarmer
          ? { value: "needs_review", displayName: "NEEDS REVIEW" }
          : {
              value: Pending.toLowerCase(),
              displayName: "FARM REVIEW",
            };
      } else {
        return {
          value: Pending.toLowerCase(),
          displayName: Pending,
        };
      }

    case Void:
      return {
        value: Void.toLowerCase(),
        displayName: Void,
      };

    case Active:
      return {
        value: Active.toLowerCase(),
        displayName: Active,
      };

    default:
      throw new Error(`Unknown status: ${status}`);
  }
};
