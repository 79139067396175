import { Stack } from "@chakra-ui/react";

import { FormInputs, MoneyInput, StandardInput } from "components/inputs";
import DateInput from "components/inputs/DateInput";
import TextHeading from "components/TextHeading";
import { HEADING_SPACING } from "components/page-components";
import RegionDetails from "./RegionDetails";

const getDatePickerProps = () => ({
  minDate: new Date(),
});

const CropYearDetailInputs = ({
  isDisabled = false,
  disableCreationOnlyFields = false,
}: {
  isDisabled?: boolean;
  disableCreationOnlyFields?: boolean;
}) => {
  const datePickerProps = getDatePickerProps();

  return (
    <Stack spacing={HEADING_SPACING}>
      <Stack spacing={2}>
        <MoneyInput
          id={"distanceAdjRate"}
          label={"Distance adjustment rate"}
          isRequired
          isDisabled={isDisabled}
        />
        <MoneyInput
          id={"storageFeeRate"}
          label={"Storage fee rate"}
          isRequired
          isDisabled={isDisabled}
        />
        <FormInputs
          inputs={[
            {
              id: "defermentDateStart",
              label: "Deferment date start",
              isRequired: true,
              component: DateInput,
              shouldRegister: false,
              isDisabled: isDisabled || disableCreationOnlyFields,
            },
            {
              id: "storageFeeStartDate",
              label: "Storage fee start date",
              isRequired: true,
              component: DateInput,
              shouldRegister: false,
              isDisabled,
              datePickerProps,
            },
            {
              id: "firstPaymentDateWithoutDeferral",
              label: "First payment date without deferral",
              isRequired: true,
              component: DateInput,
              shouldRegister: false,
              isDisabled,
            },
            {
              id: "averagePricingLockInDate",
              label: "Average pricing lock-in day",
              isRequired: true,
              component: DateInput,
              shouldRegister: false,
              isDisabled,
            },
            {
              id: "delayedPricingFinalDate",
              label: "Delayed pricing final date",
              isRequired: true,
              component: DateInput,
              shouldRegister: false,
              isDisabled,
              datePickerProps,
            },
            {
              id: "growerLetterName",
              label: "Grower letter name",
              isRequired: true,
              component: StandardInput,
              isDisabled,
            },
            {
              id: "growerLetterLink",
              label: "Grower letter link",
              isRequired: true,
              component: StandardInput,
              isDisabled,
            },
          ]}
        />
      </Stack>
      <TextHeading headingType="h4">Region Details</TextHeading>
      <RegionDetails
        isDisabled={isDisabled}
        disableCreationOnlyFields={disableCreationOnlyFields}
      />
    </Stack>
  );
};

export default CropYearDetailInputs;
