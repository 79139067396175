import { useQuery } from "@tanstack/react-query";

import { CSVUploadJobResponse, CSVUploadJobResponseStatusEnum } from "api";
import { useCsvService } from "contexts/ServiceProvider";
import useRefetchFieldQueries from "hooks/field/useRefetchFieldQueries";
import { useEffect } from "react";
import { BaseApiError } from "services/apiHelpers";

const CSV_JOB_POLLING_INTERVAL_MS = 3000;

const useCsvUploadJob = (
  jobId: string | undefined,
  onSuccess?: (data: CSVUploadJobResponse) => void
) => {
  const csvService = useCsvService();
  const { refetch: refetchFields } = useRefetchFieldQueries();

  const query = useQuery<CSVUploadJobResponse, BaseApiError>({
    queryKey: ["csv-upload-job", { jobId }],
    queryFn: () =>
      jobId ? csvService.getJob(jobId) : Promise.reject("Missing job ID."),
    enabled: !!jobId,
    refetchInterval: (query) =>
      query?.state.data?.status === CSVUploadJobResponseStatusEnum.InProgress
        ? CSV_JOB_POLLING_INTERVAL_MS
        : false,
  });

  useEffect(() => {
    if (
      query.isSuccess &&
      query.data?.status === CSVUploadJobResponseStatusEnum.Success
    ) {
      refetchFields();
      onSuccess?.(query.data);
    }
  }, [onSuccess, query.data, query.isSuccess, refetchFields]);

  return query;
};

export default useCsvUploadJob;
