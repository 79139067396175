import {
  AveragePricingContractRequest,
  CreateDelayedPricingContractRequest,
  CreateForwardContractRequest,
  CropYearPricingOptionDto,
  DelayedContractCurrentPricingResponse,
  DelayedContractDetailsResponse,
  ForwardContractCurrentPricingResponse,
  PricingOptionApi,
  PricingOptionApiGetCropYearPricingOptionsListRequest,
  PricingOptionApiGetFarmPricingOptionsListRequest,
  PricingOptionDto,
  PricingOptionResponse,
  UpdateContractDefermentRequest,
  UpdateDelayedContractRequest,
  UpdatePendingStandardForwardContractRequest,
} from "api";
import {
  extractResponseData,
  extractResponseDataWithMeta,
  PaginationMeta,
  processResponse,
  QueryParams,
  transformQueryParamsForRequest,
} from "./apiHelpers";
import { constructApiControllerInstance } from "./customApiConfig";

export type CropYearPricingOptionsSortColumns =
  | "cashPrice"
  | "contractTotal"
  | "basePrice"
  | "farm"
  | "region"
  | "dateOfContract"
  | "defermentDate"
  | "percentageToSell"
  | "status"
  | "type"
  | "tons";

export type FarmCropYearPricingOptionsSortColumns = Omit<
  CropYearPricingOptionsSortColumns,
  "farm" | "region"
>;

export type GetCropYearPricingOptions = {
  data: CropYearPricingOptionDto[];
  meta: PaginationMeta;
};
export type GetFarmCropYearPricingOptions = {
  data: PricingOptionDto[];
  meta: PaginationMeta;
};

class PricingOptionService {
  pricingOptionController!: PricingOptionApi;
  constructor() {
    this.pricingOptionController =
      constructApiControllerInstance(PricingOptionApi);
  }

  getCropYearPricingOptions({
    cropYearId,
    queryParams,
  }: {
    cropYearId: string;
    queryParams?: QueryParams<CropYearPricingOptionsSortColumns>;
  }): Promise<GetCropYearPricingOptions> {
    return this.pricingOptionController
      .getCropYearPricingOptionsList({
        cropYearId,
        ...transformQueryParamsForRequest(queryParams),
      } as PricingOptionApiGetCropYearPricingOptionsListRequest)
      .then(extractResponseDataWithMeta);
  }

  getPricingOptionDetails({
    pricingOptionId,
    farmId,
  }: {
    pricingOptionId: string;
    farmId: string;
  }): Promise<PricingOptionResponse> {
    return this.pricingOptionController
      .getPricingOptionDetails({
        id: pricingOptionId,
        farmId: farmId,
      })
      .then(extractResponseData);
  }

  getExistingAveragePricing({
    cropYearId,
    farmId,
  }: {
    cropYearId: string;
    farmId: string;
  }): Promise<PricingOptionResponse> {
    return this.pricingOptionController
      .getAveragePricingContractForFarm({
        cropYearId,
        farmId,
      })
      .then(extractResponseData);
  }

  getFarmCropYearPricingOptions({
    cropYearId,
    farmId,
    queryParams,
  }: {
    cropYearId: string;
    farmId: string;
    queryParams?: QueryParams<FarmCropYearPricingOptionsSortColumns>;
  }): Promise<GetFarmCropYearPricingOptions> {
    return this.pricingOptionController
      .getFarmPricingOptionsList({
        cropYearId,
        farmId,
        ...transformQueryParamsForRequest(queryParams),
      } as PricingOptionApiGetFarmPricingOptionsListRequest)
      .then(extractResponseDataWithMeta);
  }

  getLockedInDelayedPricingContract({
    cropYearId,
    farmId,
  }: {
    cropYearId: string;
    farmId: string;
  }): Promise<DelayedContractCurrentPricingResponse> {
    return this.pricingOptionController
      .getCurrentPricesForDelayedPricing({ cropYearId, farmId })
      .then(extractResponseData);
  }

  getDelayedPricingDetailsCalculated({
    cropYearId,
    farmId,
    futuresPrice,
    basis,
    tons,
    dateOfContract,
    cashPrice,
    isManuallyCreated,
  }: {
    cropYearId: string;
    farmId: string;
    futuresPrice?: number;
    basis: number;
    tons: number;
    dateOfContract: string | undefined;
    cashPrice: number | undefined;
    isManuallyCreated: boolean;
  }): Promise<DelayedContractDetailsResponse> {
    return this.pricingOptionController
      .calculateDelayedPricingDetails({
        cropYearId,
        farmId,
        futuresPrice,
        basis,
        tons,
        dateOfContract,
        cashPrice,
        isManuallyCreated,
      })
      .then(extractResponseData);
  }

  createDelayedPricingContract({
    cropYearId,
    farmId,
    delayedPricingContract,
  }: {
    cropYearId: string;
    farmId: string;
    delayedPricingContract: CreateDelayedPricingContractRequest;
  }): Promise<PricingOptionResponse> {
    return this.pricingOptionController
      .createDelayedPricingContract({
        cropYearId,
        farmId,
        createDelayedPricingContractRequest: delayedPricingContract,
      })
      .then(extractResponseData);
  }

  updateDelayedPricingContract({
    pricingOptionId,
    cropYearId,
    farmId,
    updateDelayedContractRequest,
  }: {
    pricingOptionId: string;
    cropYearId: string;
    farmId: string;
    updateDelayedContractRequest: UpdateDelayedContractRequest;
  }): Promise<PricingOptionResponse> {
    return this.pricingOptionController
      .updateDelayedPricingContract({
        id: pricingOptionId,
        cropYearId,
        farmId,
        updateDelayedContractRequest,
      })
      .then(extractResponseData);
  }

  createAveragePricingContract({
    cropYearId,
    farmId,
    averagePricingContract,
  }: {
    cropYearId: string;
    farmId: string;
    averagePricingContract: AveragePricingContractRequest;
  }): Promise<PricingOptionResponse> {
    return this.pricingOptionController
      .createAveragePricingContract({
        cropYearId,
        farmId,
        averagePricingContractRequest: averagePricingContract,
      })
      .then(extractResponseData);
  }

  updateAveragePricingContract({
    averagePricingContractId,
    cropYearId,
    farmId,
    averagePricingContract,
  }: {
    averagePricingContractId: string;
    cropYearId: string;
    farmId: string;
    averagePricingContract: AveragePricingContractRequest;
  }): Promise<string> {
    return this.pricingOptionController
      .updateAveragePricingContract({
        id: averagePricingContractId,
        cropYearId,
        farmId,
        averagePricingContractRequest: averagePricingContract,
      })
      .then(processResponse);
  }

  getLockedInForwardPricingContract({
    cropYearId,
    farmId,
  }: {
    cropYearId: string;
    farmId: string;
  }): Promise<ForwardContractCurrentPricingResponse> {
    return this.pricingOptionController
      .getCurrentPricesForForwardPricing({ cropYearId, farmId })
      .then(extractResponseData);
  }

  createForwardContract({
    cropYearId,
    farmId,
    forwardContract,
  }: {
    cropYearId: string;
    farmId: string;
    forwardContract: CreateForwardContractRequest;
  }): Promise<PricingOptionResponse> {
    return this.pricingOptionController
      .createForwardContract({
        cropYearId,
        farmId,
        createForwardContractRequest: forwardContract,
      })
      .then(extractResponseData);
  }

  updateForwardContact({
    contractId,
    cropYearId,
    farmId,
    forwardContract,
  }: {
    contractId: string;
    cropYearId: string;
    farmId: string;
    forwardContract: CreateForwardContractRequest;
  }): Promise<PricingOptionResponse> {
    return this.pricingOptionController
      .updateForwardContract({
        id: contractId,
        cropYearId,
        farmId,
        createForwardContractRequest: forwardContract,
      })
      .then(extractResponseData);
  }

  updatePendingStandardForwardContract({
    contractId,
    cropYearId,
    farmId,
    updateRequest,
  }: {
    contractId: string;
    cropYearId: string;
    farmId: string;
    updateRequest: UpdatePendingStandardForwardContractRequest;
  }): Promise<PricingOptionResponse> {
    return this.pricingOptionController
      .updatePendingStandardForwardContract({
        id: contractId,
        cropYearId,
        farmId,
        updatePendingStandardForwardContractRequest: updateRequest,
      })
      .then(extractResponseData);
  }

  updateForwardContactDeferment({
    contractId,
    cropYearId,
    farmId,
    forwardContractDeferment,
  }: {
    contractId: string;
    cropYearId: string;
    farmId: string;
    forwardContractDeferment: UpdateContractDefermentRequest;
  }): Promise<string> {
    return this.pricingOptionController
      .updateForwardContractDeferment({
        id: contractId,
        cropYearId,
        farmId,
        updateContractDefermentRequest: forwardContractDeferment,
      })
      .then(processResponse);
  }

  deleteFarmCropYearPricingOptions({
    cropYearId,
    pricingOptionIds,
  }: {
    cropYearId: string;
    pricingOptionIds: string[];
  }): Promise<string> {
    return this.pricingOptionController
      .deleteForwardContracts({
        cropYearId,
        deleteForwardContractsRequest: { pricingOptionIds },
      })
      .then(processResponse);
  }

  approveForwardContract({
    cropYearId,
    forwardContractId,
    farmId,
  }: {
    cropYearId: string;
    forwardContractId: string;
    farmId: string;
  }): Promise<string> {
    return this.pricingOptionController
      .approveForwardContract({
        cropYearId,
        forwardContractId,
        farmId,
      })
      .then(processResponse);
  }

  deleteAveragePricingContract({
    cropYearId,
    farmId,
    averagePricingContractId,
  }: {
    cropYearId: string;
    farmId: string;
    averagePricingContractId: string;
  }): Promise<string> {
    return this.pricingOptionController
      .deleteAveragePricingContract({
        cropYearId,
        farmId,
        id: averagePricingContractId,
      })
      .then(processResponse);
  }

  downloadPricingData({ cropYearId }: { cropYearId: string }): Promise<Blob> {
    return this.pricingOptionController
      .downloadPricingDataExport({
        cropYearId,
      })
      .then((response) => new Blob([response.data], { type: "text/csv" }));
  }
}

export default PricingOptionService;
