import { Box, Text } from "@chakra-ui/react";

const VoidContractWaterMark = () => (
  <Box
    position="sticky"
    transform="translate(20%, -200%) rotate(-10deg)"
    pointerEvents="none"
    zIndex="1"
    maxW="1000px"
  >
    <Text color="errorRed" fontWeight="bold" fontSize="230px" opacity="28%">
      VOID
    </Text>
  </Box>
);

export default VoidContractWaterMark;
