import { PropsWithChildren, ReactElement } from "react";
import {
  Link as RLink,
  LinkProps as RLinkProps,
  useSearchParams,
} from "react-router-dom";
import { Link, LinkProps } from "@chakra-ui/react";

const LinkWithQuery = ({
  to,
  children,
  ...rest
}: PropsWithChildren<LinkProps & RLinkProps>): ReactElement => {
  const [searchParams] = useSearchParams();

  return (
    <Link as={RLink} to={`${to}?${searchParams}`} {...rest}>
      {children}
    </Link>
  );
};

export default LinkWithQuery;
