import { useEffect } from "react";
import { useUserInfo } from "contexts/UserInfoProvider";
import useSelectedFarmId from "hooks/useSelectedFarmId";

export const FARM_ID_QUERY_STRING_KEY = "farmId";

const useDefaultSelectedFarm = () => {
  const { isEmployee, farms } = useUserInfo();
  const [farmId, setFarmId] = useSelectedFarmId();

  useEffect(() => {
    if (!isEmployee && !farmId && farms[0]?.farmId) {
      setFarmId(farms[0].farmId);
    }
  }, [farmId, farms, isEmployee, setFarmId]);
};

export default useDefaultSelectedFarm;
