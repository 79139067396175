import { useQuery } from "@tanstack/react-query";

import { DailyPricingResponse } from "api";
import { useDailyPricingService } from "contexts/ServiceProvider";
import { BaseApiError } from "services/apiHelpers";

export const GET_DAILY_PRICING_QUERY_KEY = "get-daily-pricing";

const useGetDailyPricing = () => {
  const dailyPricingService = useDailyPricingService();

  return useQuery<DailyPricingResponse, BaseApiError>({
    queryKey: [GET_DAILY_PRICING_QUERY_KEY],
    queryFn: () => dailyPricingService.getDailyPricing(),
  });
};

export default useGetDailyPricing;
