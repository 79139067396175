import { ReactElement, useMemo } from "react";

import { Path, useController, useFormContext } from "react-hook-form";
import { FieldValues } from "react-hook-form/dist/types/fields";

import Select from "components/Select";
import useSelectedFarmId from "hooks/useSelectedFarmId";
import useGetDairies from "hooks/dairy/useGetDaries";
import useFarmDetail from "hooks/farm/farm-detail/useFarmDetail";
import InputWrapper, { InputWrapperProps } from "../InputWrapper";
import { SelectInputProps } from "../SelectInput";

const DairySelectInput = <T extends FieldValues>({
  id,
  isDisabled,
  placeholder,
  ...rest
}: SelectInputProps & InputWrapperProps): ReactElement => {
  const [farmId] = useSelectedFarmId();
  const { data: farmDetails, isLoading: farmDetailsLoading } =
    useFarmDetail(farmId);
  const {
    data: dairiesData,
    isLoading: dairiesLoading,
    error: dairiesError,
  } = useGetDairies(!isDisabled ? farmDetails?.region?.id : undefined);
  const { control } = useFormContext<T>();

  const {
    field,
    fieldState: { error },
  } = useController<T>({
    name: id as Path<T>,
    control: control,
  });

  const dairyOptions = useMemo(
    () =>
      dairiesData?.map((dairy) => ({
        label: dairy.name,
        value: dairy.id,
      })) ?? [],
    [dairiesData]
  );

  const isLoading = farmDetailsLoading || dairiesLoading;

  return (
    <InputWrapper
      {...rest}
      isDisabled={isLoading || isDisabled}
      isInvalid={!!error}
      errorMsg={error?.message}
    >
      <Select
        options={dairyOptions}
        {...field}
        placeholder={placeholder}
        isLoading={isLoading}
        errorMsg={dairiesError?.message}
      />
    </InputWrapper>
  );
};

export default DairySelectInput;
