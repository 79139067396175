import { ReactNode } from "react";
import { Button, Icon } from "@chakra-ui/react";
import { IoCaretDownOutline } from "react-icons/io5";
import Menu from "components/Menu";

const PricingMenu = ({
  buttonLabel,
  children,
  menuButtonMinWidth,
  menuListWidth,
}: {
  buttonLabel: string;
  children?: ReactNode;
  menuButtonMinWidth: string;
  menuListWidth: string;
}) => {
  return (
    <Menu
      button={buttonLabel}
      menuButtonProps={{
        justifyContent: "left",
        rightIcon: <Icon as={IoCaretDownOutline} color="white" boxSize={3} />,
        as: Button,
        minWidth: menuButtonMinWidth,
        size: "md",
        textAlign: "left",
        w: "100%",
      }}
      menuStyles={{
        placement: "bottom-end",
        offset: [0, 8],
      }}
      menuListStyles={{ w: menuListWidth }}
    >
      {children}
    </Menu>
  );
};

export default PricingMenu;
