import { Box } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import DocumentsList from "components/DocumentsList";
import useFarmDocumentList from "hooks/farm/useFarmDocumentList";

const FarmDocumentsTabContent = () => {
  const { id: farmId } = useParams();

  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useFarmDocumentList(farmId);

  return (
    <Box p={2}>
      <DocumentsList
        farmId={farmId}
        pages={data?.pages}
        isLoading={isLoading}
        isFetchingNextPage={isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
      />
    </Box>
  );
};

export default FarmDocumentsTabContent;
