import React from "react";

import { PricingOptionAuditLogDto } from "api";
import { PricingInfoRecordStamp } from "components/PricingInfoRecordStamp/PricingInfoRecordStamp";

const AgreedByRecordStamp = ({
  auditItem,
}: {
  auditItem: PricingOptionAuditLogDto;
}) => (
  <PricingInfoRecordStamp
    initiatedBy={`${auditItem.firstName} ${auditItem.lastName}`}
    dateInitiated={auditItem.lastUpdated}
    label="Agreed By :"
    labelTextColor="greyFactor.4"
    valueTextColor="charcoal"
  />
);

export default AgreedByRecordStamp;
