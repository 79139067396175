/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosRequestConfig,
} from "axios";
import { Configuration } from "../configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "../base";
// @ts-ignore
import { DataListFarmListDto } from "../models";
// @ts-ignore
import { DataManureYearResponse } from "../models";
// @ts-ignore
import { PageResponseFarmDocumentDto } from "../models";
// @ts-ignore
import { PageResponseManureYearFarmDto } from "../models";
// @ts-ignore
import { PageResponseManureYearResponse } from "../models";
/**
 * ManureYearApi - axios parameter creator
 * @export
 */
export const ManureYearApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {string} id
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    assignFarmsToManureYear: async (
      id: string,
      requestBody: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("assignFarmsToManureYear", "id", id);
      // verify required parameter 'requestBody' is not null or undefined
      assertParamExists("assignFarmsToManureYear", "requestBody", requestBody);
      const localVarPath = `/manure-year/{id}/farm`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        requestBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getManureYear: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getManureYear", "id", id);
      const localVarPath = `/manure-year/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {string} farmId
     * @param {string} [farmId2]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getManureYearFarmDocumentsPage: async (
      id: string,
      farmId: string,
      farmId2?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getManureYearFarmDocumentsPage", "id", id);
      // verify required parameter 'farmId' is not null or undefined
      assertParamExists("getManureYearFarmDocumentsPage", "farmId", farmId);
      const localVarPath = `/manure-year/{id}/farm/{farm-id}/documents`
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"farm-id"}}`, encodeURIComponent(String(farmId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sort) {
        localVarQueryParameter["sort"] = sort;
      }

      if (farmId2 !== undefined && farmId2 !== null) {
        localVarHeaderParameter["farm-id"] = String(farmId2);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listFarmsNotAssignedToManureYear: async (
      id: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("listFarmsNotAssignedToManureYear", "id", id);
      const localVarPath = `/manure-year/{id}/addable-farms`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sort) {
        localVarQueryParameter["sort"] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listManureYearFarms: async (
      id: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("listManureYearFarms", "id", id);
      const localVarPath = `/manure-year/{id}/farm`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sort) {
        localVarQueryParameter["sort"] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listManureYears: async (
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/manure-years`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sort) {
        localVarQueryParameter["sort"] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ManureYearApi - functional programming interface
 * @export
 */
export const ManureYearApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    ManureYearApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} id
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async assignFarmsToManureYear(
      id: string,
      requestBody: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.assignFarmsToManureYear(
          id,
          requestBody,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getManureYear(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataManureYearResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getManureYear(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {string} farmId
     * @param {string} [farmId2]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getManureYearFarmDocumentsPage(
      id: string,
      farmId: string,
      farmId2?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PageResponseFarmDocumentDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getManureYearFarmDocumentsPage(
          id,
          farmId,
          farmId2,
          page,
          size,
          sort,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listFarmsNotAssignedToManureYear(
      id: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataListFarmListDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listFarmsNotAssignedToManureYear(
          id,
          page,
          size,
          sort,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listManureYearFarms(
      id: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PageResponseManureYearFarmDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listManureYearFarms(
          id,
          page,
          size,
          sort,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listManureYears(
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PageResponseManureYearResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.listManureYears(
        page,
        size,
        sort,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * ManureYearApi - factory interface
 * @export
 */
export const ManureYearApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ManureYearApiFp(configuration);
  return {
    /**
     *
     * @param {string} id
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    assignFarmsToManureYear(
      id: string,
      requestBody: Array<string>,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .assignFarmsToManureYear(id, requestBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getManureYear(
      id: string,
      options?: any
    ): AxiosPromise<DataManureYearResponse> {
      return localVarFp
        .getManureYear(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {string} farmId
     * @param {string} [farmId2]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getManureYearFarmDocumentsPage(
      id: string,
      farmId: string,
      farmId2?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any
    ): AxiosPromise<PageResponseFarmDocumentDto> {
      return localVarFp
        .getManureYearFarmDocumentsPage(
          id,
          farmId,
          farmId2,
          page,
          size,
          sort,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listFarmsNotAssignedToManureYear(
      id: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any
    ): AxiosPromise<DataListFarmListDto> {
      return localVarFp
        .listFarmsNotAssignedToManureYear(id, page, size, sort, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listManureYearFarms(
      id: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any
    ): AxiosPromise<PageResponseManureYearFarmDto> {
      return localVarFp
        .listManureYearFarms(id, page, size, sort, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listManureYears(
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any
    ): AxiosPromise<PageResponseManureYearResponse> {
      return localVarFp
        .listManureYears(page, size, sort, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for assignFarmsToManureYear operation in ManureYearApi.
 * @export
 * @interface ManureYearApiAssignFarmsToManureYearRequest
 */
export interface ManureYearApiAssignFarmsToManureYearRequest {
  /**
   *
   * @type {string}
   * @memberof ManureYearApiAssignFarmsToManureYear
   */
  readonly id: string;

  /**
   *
   * @type {Array<string>}
   * @memberof ManureYearApiAssignFarmsToManureYear
   */
  readonly requestBody: Array<string>;
}

/**
 * Request parameters for getManureYear operation in ManureYearApi.
 * @export
 * @interface ManureYearApiGetManureYearRequest
 */
export interface ManureYearApiGetManureYearRequest {
  /**
   *
   * @type {string}
   * @memberof ManureYearApiGetManureYear
   */
  readonly id: string;
}

/**
 * Request parameters for getManureYearFarmDocumentsPage operation in ManureYearApi.
 * @export
 * @interface ManureYearApiGetManureYearFarmDocumentsPageRequest
 */
export interface ManureYearApiGetManureYearFarmDocumentsPageRequest {
  /**
   *
   * @type {string}
   * @memberof ManureYearApiGetManureYearFarmDocumentsPage
   */
  readonly id: string;

  /**
   *
   * @type {string}
   * @memberof ManureYearApiGetManureYearFarmDocumentsPage
   */
  readonly farmId: string;

  /**
   *
   * @type {string}
   * @memberof ManureYearApiGetManureYearFarmDocumentsPage
   */
  readonly farmId2?: string;

  /**
   * Zero-based page index (0..N)
   * @type {number}
   * @memberof ManureYearApiGetManureYearFarmDocumentsPage
   */
  readonly page?: number;

  /**
   * The size of the page to be returned
   * @type {number}
   * @memberof ManureYearApiGetManureYearFarmDocumentsPage
   */
  readonly size?: number;

  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @type {Array<string>}
   * @memberof ManureYearApiGetManureYearFarmDocumentsPage
   */
  readonly sort?: Array<string>;
}

/**
 * Request parameters for listFarmsNotAssignedToManureYear operation in ManureYearApi.
 * @export
 * @interface ManureYearApiListFarmsNotAssignedToManureYearRequest
 */
export interface ManureYearApiListFarmsNotAssignedToManureYearRequest {
  /**
   *
   * @type {string}
   * @memberof ManureYearApiListFarmsNotAssignedToManureYear
   */
  readonly id: string;

  /**
   * Zero-based page index (0..N)
   * @type {number}
   * @memberof ManureYearApiListFarmsNotAssignedToManureYear
   */
  readonly page?: number;

  /**
   * The size of the page to be returned
   * @type {number}
   * @memberof ManureYearApiListFarmsNotAssignedToManureYear
   */
  readonly size?: number;

  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @type {Array<string>}
   * @memberof ManureYearApiListFarmsNotAssignedToManureYear
   */
  readonly sort?: Array<string>;
}

/**
 * Request parameters for listManureYearFarms operation in ManureYearApi.
 * @export
 * @interface ManureYearApiListManureYearFarmsRequest
 */
export interface ManureYearApiListManureYearFarmsRequest {
  /**
   *
   * @type {string}
   * @memberof ManureYearApiListManureYearFarms
   */
  readonly id: string;

  /**
   * Zero-based page index (0..N)
   * @type {number}
   * @memberof ManureYearApiListManureYearFarms
   */
  readonly page?: number;

  /**
   * The size of the page to be returned
   * @type {number}
   * @memberof ManureYearApiListManureYearFarms
   */
  readonly size?: number;

  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @type {Array<string>}
   * @memberof ManureYearApiListManureYearFarms
   */
  readonly sort?: Array<string>;
}

/**
 * Request parameters for listManureYears operation in ManureYearApi.
 * @export
 * @interface ManureYearApiListManureYearsRequest
 */
export interface ManureYearApiListManureYearsRequest {
  /**
   * Zero-based page index (0..N)
   * @type {number}
   * @memberof ManureYearApiListManureYears
   */
  readonly page?: number;

  /**
   * The size of the page to be returned
   * @type {number}
   * @memberof ManureYearApiListManureYears
   */
  readonly size?: number;

  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @type {Array<string>}
   * @memberof ManureYearApiListManureYears
   */
  readonly sort?: Array<string>;
}

/**
 * ManureYearApi - object-oriented interface
 * @export
 * @class ManureYearApi
 * @extends {BaseAPI}
 */
export class ManureYearApi extends BaseAPI {
  /**
   *
   * @param {ManureYearApiAssignFarmsToManureYearRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ManureYearApi
   */
  public assignFarmsToManureYear(
    requestParameters: ManureYearApiAssignFarmsToManureYearRequest,
    options?: AxiosRequestConfig
  ) {
    return ManureYearApiFp(this.configuration)
      .assignFarmsToManureYear(
        requestParameters.id,
        requestParameters.requestBody,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ManureYearApiGetManureYearRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ManureYearApi
   */
  public getManureYear(
    requestParameters: ManureYearApiGetManureYearRequest,
    options?: AxiosRequestConfig
  ) {
    return ManureYearApiFp(this.configuration)
      .getManureYear(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ManureYearApiGetManureYearFarmDocumentsPageRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ManureYearApi
   */
  public getManureYearFarmDocumentsPage(
    requestParameters: ManureYearApiGetManureYearFarmDocumentsPageRequest,
    options?: AxiosRequestConfig
  ) {
    return ManureYearApiFp(this.configuration)
      .getManureYearFarmDocumentsPage(
        requestParameters.id,
        requestParameters.farmId,
        requestParameters.farmId2,
        requestParameters.page,
        requestParameters.size,
        requestParameters.sort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ManureYearApiListFarmsNotAssignedToManureYearRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ManureYearApi
   */
  public listFarmsNotAssignedToManureYear(
    requestParameters: ManureYearApiListFarmsNotAssignedToManureYearRequest,
    options?: AxiosRequestConfig
  ) {
    return ManureYearApiFp(this.configuration)
      .listFarmsNotAssignedToManureYear(
        requestParameters.id,
        requestParameters.page,
        requestParameters.size,
        requestParameters.sort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ManureYearApiListManureYearFarmsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ManureYearApi
   */
  public listManureYearFarms(
    requestParameters: ManureYearApiListManureYearFarmsRequest,
    options?: AxiosRequestConfig
  ) {
    return ManureYearApiFp(this.configuration)
      .listManureYearFarms(
        requestParameters.id,
        requestParameters.page,
        requestParameters.size,
        requestParameters.sort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ManureYearApiListManureYearsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ManureYearApi
   */
  public listManureYears(
    requestParameters: ManureYearApiListManureYearsRequest = {},
    options?: AxiosRequestConfig
  ) {
    return ManureYearApiFp(this.configuration)
      .listManureYears(
        requestParameters.page,
        requestParameters.size,
        requestParameters.sort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
