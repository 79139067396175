import {
  Box,
  Card,
  HStack,
  Spinner,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";

import useGetAvailableNewCropFutures from "hooks/futures/useGetAvailableNewCropFutures";
import FuturesInput from "../components/FuturesInput";
import { DesktopOnly } from "components/BreakpointVisibility";
import { mobileOnly } from "utils/styleHelpers";

const NewCropFuturesSelector = () => {
  const {
    data: availableNewCropFuturesData,
    isLoading,
    isError,
  } = useGetAvailableNewCropFutures();
  const isMobile = useBreakpointValue(mobileOnly);

  return (
    <Card bg="grayFactor.1.5" borderRadius={8} py={1} px={4}>
      <HStack justifyContent="center">
        <DesktopOnly>
          <Box w="148px">
            <Text color="charcoal" fontWeight="bold">
              New Crop Futures:
            </Text>
          </Box>
        </DesktopOnly>
        {isLoading ? (
          <Spinner size="sm" />
        ) : (
          <Box pt={2} w={isMobile ? "100%" : "212px"}>
            <FuturesInput
              data-testid="new-crop-futures-input"
              props={{
                id: "newCropFutures",
                placeholder: "Select Futures",
                label: isMobile ? "New Crop Futures" : undefined,
                isRequired: false,
              }}
              data={availableNewCropFuturesData}
              isLoading={isLoading}
              isError={isError}
            />
          </Box>
        )}
      </HStack>
    </Card>
  );
};

export default NewCropFuturesSelector;
