import * as yup from "yup";

export const selectOptionSchema = yup.object({
  value: yup.string().required(),
  label: yup.string().required(),
});

export const optionalSelectOptionSchema = yup.object({
  value: yup.string(),
  label: yup.string(),
});
