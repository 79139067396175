import { PropsWithChildren } from "react";

import {
  HStack,
  Spacer,
  Stack,
  StackProps,
  Text,
  VStack,
} from "@chakra-ui/react";

import { mobileStyleBreakpoint } from "utils/styleHelpers";
import TextHeading, { TextHeadingProps } from "components/TextHeading";

export const DESKTOP_HEADER_PADDING = 20;
export const MOBILE_HEADER_PADDING = 6;
export const HEADING_SPACING = mobileStyleBreakpoint(4, 8);
export const PAGE_HEADER_MAX_WIDTH = mobileStyleBreakpoint("342px", "unset");

const PageHeader = ({
  heading,
  headingType = "h3",
  subHeading,
  children,
  ...rest
}: PropsWithChildren<
  StackProps & {
    heading: string;
    headingType?: TextHeadingProps["headingType"];
    subHeading?: string;
  }
>) => (
  <HStack flexWrap="wrap" spacing={0} {...rest}>
    <VStack display="inline" justifyContent="start">
      <TextHeading headingType={headingType}>{heading}</TextHeading>
      {subHeading && (
        <Text maxW={PAGE_HEADER_MAX_WIDTH} textStyle="h6" color="grayFactor.4">
          {subHeading}
        </Text>
      )}
    </VStack>
    <Spacer />
    {children}
  </HStack>
);

export const PAGE_HEADER_PX = mobileStyleBreakpoint(MOBILE_HEADER_PADDING, 0);

export const PageHeaderStack = ({
  children,
  ...rest
}: PropsWithChildren & StackProps) => (
  <Stack spacing={HEADING_SPACING} px={PAGE_HEADER_PX} {...rest}>
    {children}
  </Stack>
);

export default PageHeader;
