import { forwardRef } from "react";
import { Text } from "@chakra-ui/react";

import StandardInput, { StandardInputProps } from "../StandardInput";
import { onlyWholeNumbers } from "utils/inputUtils";

export const TonsAddon = () => (
  <Text color="steelGray" mr="8px">
    tons
  </Text>
);
const EstimatedYieldInput = forwardRef<HTMLInputElement, StandardInputProps>(
  (props, ref) => {
    return (
      <StandardInput
        {...props}
        type="number"
        hideAddonBackground={true}
        rightAddon={<TonsAddon />}
        onWheel={(e) => e.currentTarget.blur()}
        step="1"
        onKeyDown={onlyWholeNumbers}
        ref={ref}
      />
    );
  }
);

EstimatedYieldInput.displayName = "EstimatedYieldInput";

export default EstimatedYieldInput;
