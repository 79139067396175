import { ReactElement, useEffect, useMemo, useState } from "react";

import { Button, Center, Spinner, Stack } from "@chakra-ui/react";

import FetchMoreWhenInView from "components/FetchMoreWhenInView";
import { TABLE_MARGIN_TOP } from "components/SortableTable";
import { useCropYearContext } from "contexts/CropYearContext";
import {
  baseFieldsListColumns,
  desktopRVFieldsListColumnsPreHarvest,
  desktopFarmAdminFieldsListColumnsPostHarvest,
  fieldsListTableColumns,
  desktopRVFieldsListColumnsPostHarvest,
  desktopFarmAdminFieldsListColumnsPreHarvest,
} from "./FieldsListTable";
import useFieldsList from "hooks/field/useFieldsList";
import useSort, { DEFAULT_SORT_DIRECTION, Sort } from "hooks/useSort";
import useUserRoleFlags from "hooks/auth/useUserRoleFlags";
import useSelectedFarmId from "hooks/useSelectedFarmId";
import useNavigateWithQuery from "hooks/useNavigateWithQuery";
import SilageDashboardTabLayout from "layouts/SilageDashboardTabLayout";
import DashboardListTable from "pages/dashboard/components/DashboardListTable";
import fieldPaths from "routes/field/fieldPaths";
import { FieldsListSortColumns } from "services/FieldService";
import { mobileStyleBreakpoint } from "utils/styleHelpers";

const defaultSort: Sort<FieldsListSortColumns> = {
  key: "fieldNumber",
  direction: DEFAULT_SORT_DIRECTION,
};

const FieldsListTab = (): ReactElement => {
  const {
    cropYear,
    isPreHarvest,
    isLoading: cropYearIsLoading,
  } = useCropYearContext();
  const { isEmployee } = useUserRoleFlags();
  const [farmId] = useSelectedFarmId();
  const [searchTerm, setSearchTerm] = useState("");
  const { sort, toggleSort, setSort } =
    useSort<FieldsListSortColumns>(defaultSort);

  const riverviewEmployeeFieldListView = isPreHarvest
    ? desktopRVFieldsListColumnsPreHarvest
    : desktopRVFieldsListColumnsPostHarvest;

  const farmAdminFieldListCondition = isPreHarvest
    ? desktopFarmAdminFieldsListColumnsPreHarvest
    : desktopFarmAdminFieldsListColumnsPostHarvest;

  const adminFarmsListTableColumns = useMemo(
    () =>
      mobileStyleBreakpoint(
        baseFieldsListColumns,
        isEmployee
          ? riverviewEmployeeFieldListView
          : farmAdminFieldListCondition,
        "md"
      ),
    [farmAdminFieldListCondition, isEmployee, riverviewEmployeeFieldListView]
  );

  const {
    data,
    isLoading: fieldListIsLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useFieldsList({
    cropYearId: cropYear?.id,
    queryParams: {
      search: searchTerm,
      sort: [sort],
    },
    farmId,
  });
  const navigate = useNavigateWithQuery();

  const fieldsListData = useMemo(
    () => data?.pages?.flatMap((response) => response.fields) ?? [],
    [data?.pages]
  );

  const tableData = useMemo(
    () =>
      fieldsListTableColumns({
        data: fieldsListData,
        isPreHarvest,
        isEmployee,
      }),
    [fieldsListData, isEmployee, isPreHarvest]
  );

  useEffect(() => {
    setSort(defaultSort);
  }, [setSort, searchTerm]);

  return (
    <SilageDashboardTabLayout
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      heading="Fields"
      addButton={
        isPreHarvest && isEmployee ? (
          <Button
            w="100%"
            h={10}
            onClick={() => {
              navigate(`/${fieldPaths.basePath}/${fieldPaths.children.new}`);
            }}
          >
            New Field
          </Button>
        ) : undefined
      }
    >
      {fieldListIsLoading || cropYearIsLoading ? (
        <Center h="100%">
          <Spinner />
        </Center>
      ) : (
        <Stack spacing={TABLE_MARGIN_TOP} w="100%">
          <DashboardListTable
            data={tableData}
            sort={sort}
            toggleSort={toggleSort}
            columns={adminFarmsListTableColumns}
            emptyTableMsg={
              searchTerm.length === 0
                ? "No fields added yet."
                : "No Results Found"
            }
            routeToBasePath={`${fieldPaths.basePath}`}
          />
          {tableData.length > 0 && (
            <FetchMoreWhenInView
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
              key={`${data?.pages.length}`}
            />
          )}
        </Stack>
      )}
    </SilageDashboardTabLayout>
  );
};

export default FieldsListTab;
