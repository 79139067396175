import { ReactElement, SetStateAction } from "react";

import { SelectOption, SingleSelect } from "components/Select";
import { selectCustomStyles } from "components/Select/Select";

const OPTION_HEIGHT = 40;
const OPTIONS_TO_SHOW = 5;
export const SELECT_MAX_HEIGHT = OPTION_HEIGHT * OPTIONS_TO_SHOW;

type YearSelectProps = {
  options: SelectOption[];
  yearId: string | undefined;
  name: string;
  isLoading: boolean;
  setSelectedOption: (option: SetStateAction<SelectOption | undefined>) => void;
};

const YearSelect = ({
  options,
  yearId,
  ...props
}: YearSelectProps): ReactElement => {
  return (
    <SingleSelect
      selectedOption={options.find((_option) => _option.value === yearId)}
      options={options}
      {...props}
      maxMenuHeight={SELECT_MAX_HEIGHT}
      isSearchable={false}
      chakraStyles={{
        ...selectCustomStyles,
        container: (provider, state) => ({
          ...provider,
          ...selectCustomStyles?.container?.(provider, state),
          borderColor: "brand.800",
          bg: "brand.50",
        }),
        dropdownIndicator: (provider, state) => ({
          ...provider,
          ...selectCustomStyles?.dropdownIndicator?.(provider, state),
          bgColor: "unset",
        }),
        placeholder: (provider, state) => ({
          ...provider,
          ...selectCustomStyles?.placeholder?.(provider, state),
          color: "brand.800",
          fontWeight: "bold",
        }),
        valueContainer: (provider, state) => ({
          ...provider,
          ...selectCustomStyles?.valueContainer?.(provider, state),
          color: "brand.800",
          bgColor: "unset",
          fontWeight: "bold",
        }),
      }}
    />
  );
};

export default YearSelect;
