import { RouteObject } from "react-router";

import usersPaths from "./usersPaths";

import RedirectToDashboard from "components/redirects/RedirectToDashboard";
import ProtectedRoute from "components/route-wrappers/ProtectedRoute";

import TableLayout from "layouts/TableLayout";
import UsersListPage from "pages/admin/users/UsersListPage";

const usersRoutes: RouteObject = {
  path: usersPaths.basePath,
  element: (
    <ProtectedRoute>
      <TableLayout />
    </ProtectedRoute>
  ),
  children: [
    {
      index: true,
      element: <UsersListPage />,
    },
    {
      path: "*",
      element: <RedirectToDashboard />,
    },
  ],
};

export default usersRoutes;
