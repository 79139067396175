import { ReactElement } from "react";

import { Button } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import FarmDetailFormInputs from "forms/farm-detail";
import { PageHeader } from "components/page-components";
import FormButtonGroup from "components/FormButtonGroup";
import SubmitButton from "components/SubmitButton";
import { FormStack } from "layouts/FormLayout";
import useFarmMutations from "./components/useFarmMutations";
import FarmFormContext, {
  FarmDetailFields,
  verifyAllRequiredFields,
} from "./FarmFormContext";
import FarmInfoTabGroup from "./components/FarmInfoTabGroup";

const AddFarmForm = (): ReactElement => {
  const {
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useFormContext<FarmDetailFields>();
  const requiredFieldsNotFilled = verifyAllRequiredFields(watch(), true);
  const { onCreate, onCancel, alert } = useFarmMutations();

  return (
    <>
      <FormStack onSubmit={handleSubmit(onCreate)}>
        <PageHeader heading="Farm Information">
          <FormButtonGroup>
            <Button size="md" variant="outline" onClick={onCancel}>
              Cancel
            </Button>
            <SubmitButton
              submitDisabled={!requiredFieldsNotFilled}
              isSubmitting={isSubmitting}
              buttonText="Submit New Farm"
            />
          </FormButtonGroup>
        </PageHeader>
        <FarmInfoTabGroup disableDocuments={true} />
        <FarmDetailFormInputs disableContactFields={true} />
      </FormStack>
      {alert}
    </>
  );
};

const AddFarmPage = () => (
  <FarmFormContext>
    <AddFarmForm />
  </FarmFormContext>
);

export default AddFarmPage;
