import { forwardRef } from "react";

import { Button, useBoolean } from "@chakra-ui/react";

import { EyeClosed, EyeOpen } from "icons";
import StandardInput, { StandardInputProps } from "./StandardInput";

const PasswordInput = forwardRef<HTMLInputElement, StandardInputProps>(
  (props, ref) => {
    const [showPassword, setShowPassword] = useBoolean();

    return (
      <StandardInput
        type={showPassword ? "text" : "password"}
        placeholder="Password"
        hideAddonBackground={true}
        rightAddon={
          <Button onClick={setShowPassword.toggle} variant="link">
            {showPassword ? <EyeClosed /> : <EyeOpen />}
          </Button>
        }
        {...props}
        ref={ref}
      />
    );
  }
);
PasswordInput.displayName = "PasswordInput";

export default PasswordInput;
