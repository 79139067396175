import { Text } from "@chakra-ui/react";

import { FormInputs, StandardInput } from "components/inputs";
import { onlyWholeNumbers } from "utils/inputUtils";

const PercentageToSellInput = () => (
  <FormInputs
    inputs={[
      {
        id: "percentageToSell",
        label: "Percentage to Sell",
        component: StandardInput,
        onKeyDown: onlyWholeNumbers,
        isRequired: true,
        maxW: "100%",
        hideAddonBackground: true,
        rightAddon: (
          <Text color="charcoal" mr="8px">
            %
          </Text>
        ),
      },
    ]}
  />
);

export default PercentageToSellInput;
