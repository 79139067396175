import { ComponentStyleConfig, cssVar } from "@chakra-ui/react";

const $arrowBg = cssVar("popper-arrow-bg");

const Tooltip: ComponentStyleConfig = {
  variants: {
    inverted: {
      bg: "white",
      color: "black",
      [$arrowBg.variable]: "white",
    },
  },
  defaultProps: {
    variant: "inverted",
  },
};

export default Tooltip;
