import { useSearchParams } from "react-router-dom";

import userRegistrationPaths from "routes/user-registration/userRegistrationPaths";
import BackButton from "components/BackButton/BackButton";

const BackToBasicInfoLink = ({ callback }: { callback: () => void }) => {
  const [searchParams] = useSearchParams();

  return (
    <BackButton
      route={`${userRegistrationPaths.children.basicUserInfoPath}?${searchParams}`}
      callback={callback}
      variant="link"
      color="brand.800"
    />
  );
};

export default BackToBasicInfoLink;
