import { ReactElement } from "react";

import { Skeleton } from "@chakra-ui/react";

import SummaryStatCard, {
  totalAcres,
  totalFields,
} from "components/SummaryStatCard";
import { baseStyle } from "./SummaryCards";

const BaseSummaryCards = ({
  isLoading,
  _totalAcres,
  _totalFields,
}: {
  isLoading: boolean;
  _totalAcres?: number;
  _totalFields?: number;
}): ReactElement => (
  <>
    <Skeleton isLoaded={!isLoading} w="100%">
      <SummaryStatCard
        {...baseStyle}
        {...totalAcres}
        value={_totalAcres ?? 0}
      />
    </Skeleton>
    <Skeleton isLoaded={!isLoading} w="100%">
      <SummaryStatCard
        {...baseStyle}
        {...totalFields}
        value={_totalFields ?? 0}
      />
    </Skeleton>
  </>
);

export default BaseSummaryCards;
