import { ErrorColumnProps } from "components/CsvUpload/ErrorTable/ErrorTable";
import { ResultsWithErrors } from "pages/csv-uploads";
import { actualTonsErrorGuideData } from "pages/csv-uploads/error-guide";

const errorTableColumns: ErrorColumnProps[] = [
  {
    id: "fieldId",
    heading: "Field ID",
    csvHeading: "Field ID",
  },
  {
    id: "dairy",
    heading: "Dairy Abbreviation",
    csvHeading: "Dairy Abbreviation",
  },
  {
    id: "tons",
    heading: "Tons @ 65 %",
    csvHeading: "Tons @ 65 %",
  },
];

const ActualTonsResultsWithErrors = () => (
  <ResultsWithErrors
    errorTableColumns={errorTableColumns}
    additionalErrorMessage="You will need to correct the errors in your harvest file/spreadsheet, and re-try the upload."
    errorGuideList={actualTonsErrorGuideData}
  />
);

export default ActualTonsResultsWithErrors;
